import {
  ExcalidrawElement,
  NonDeletedExcalidrawElement,
  NonDeleted,
  ExcalidrawTextElement,
  ElementsMapOrArray,
  SceneElementsMap,
  NonDeletedSceneElementsMap,
  ElementsMap,
} from "../element/types";
import { isNonDeletedElement, updateTextElement } from "../element";
import { LinearElementEditor } from "../element/linearElementEditor";
import { getSelectedElements } from "./selection";
import { AppState } from "../types";
import { Assert, SameType } from "../utility-types";
import { randomInteger } from "../random";
import { toBrandedType } from "../utils";

import { ExcalidrawMilestoneElement } from "src/excalidraw/extensions/element/types";
// CHANGED:ADD 2022-10-26 #14
import { TaskElementEditor } from "../extensions/element/taskElementEditor"; // from extensions
// CHANGED:ADD 2022-12-13 #289
import { MilestoneElementEditor } from "../extensions/element/milestoneElementEditor"; // from extensions
// CHANGED:ADD 2022-11-2 #64
import { LinkElementEditor } from "../extensions/element/linkElementEditor"; // from extensions
import { isTaskElement } from "src/excalidraw/extensions/element/typeChecks"; // CHANGED:ADD 2022-12-22 #365
import { getContainerElement } from "src/excalidraw/element/textElement"; // CHANGED:ADD 2022-12-22 #365
import { isTextElement } from "../element"; // CHANGED:ADD 2022-12-22 #365

type ElementIdKey = InstanceType<typeof LinearElementEditor>["elementId"];
type TaskElementIdKey = InstanceType<typeof TaskElementEditor>["elementId"]; // CHANGED:ADD 2022-10-26 #14
type MilestoneElementIdKey = InstanceType<typeof MilestoneElementEditor>["elementId"]; // CHANGED:ADD 2022-12-13 #289
type LinkElementIdKey = InstanceType<typeof LinkElementEditor>["elementId"]; // CHANGED:ADD 2022-11-2 #64
type ElementKey =
  | ExcalidrawElement
  | ElementIdKey
  | TaskElementIdKey // CHANGED:ADD 2022-10-26 #14
  | MilestoneElementIdKey // CHANGED:ADD 2022-12-13 #289
  | LinkElementIdKey; // CHANGED:ADD 2022-11-2 #64

type SceneStateCallback = () => void;
type SceneStateCallbackRemover = () => void;

type SelectionHash = string & { __brand: "selectionHash" };

const getNonDeletedElements = <T extends ExcalidrawElement>(
  allElements: readonly T[],
) => {
  const elementsMap = new Map() as NonDeletedSceneElementsMap;
  const elements: T[] = [];
  for (const element of allElements) {
    if (!element.isDeleted) {
      elements.push(element as NonDeleted<T>);
      elementsMap.set(element.id, element as NonDeletedExcalidrawElement);
    }
  }
  return { elementsMap, elements };
};

const hashSelectionOpts = (
  opts: Parameters<InstanceType<typeof Scene>["getSelectedElements"]>[0],
) => {
  const keys = ["includeBoundTextElement"] as const;

  type HashableKeys = Omit<typeof opts, "selectedElementIds" | "elements">;

  // just to ensure we're hashing all expected keys
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type _ = Assert<
    SameType<
      Required<HashableKeys>,
      Pick<Required<HashableKeys>, typeof keys[number]>
    >
  >;

  let hash = "";
  for (const key of keys) {
    hash += `${key}:${opts[key] ? "1" : "0"}`;
  }
  return hash as SelectionHash;
};

// ideally this would be a branded type but it'd be insanely hard to work with
// in our codebase
export type ExcalidrawElementsIncludingDeleted = readonly ExcalidrawElement[];

const isIdKey = (elementKey: ElementKey): elementKey is 
  | ElementIdKey
  | TaskElementIdKey // CHANGED:ADD 2022-10-26 #14
  | MilestoneElementIdKey // CHANGED:ADD 2022-12-13 #289
  | LinkElementIdKey => {
  // CHANGED:ADD 2022-11-2 #64
  if (typeof elementKey === "string") {
    return true;
  }
  return false;
};

class Scene {
  // ---------------------------------------------------------------------------
  // static methods/props
  // ---------------------------------------------------------------------------

  private static sceneMapByElement = new WeakMap<ExcalidrawElement, Scene>();
  private static sceneMapById = new Map<string, Scene>();

  static mapElementToScene(elementKey: ElementKey, scene: Scene) {
    if (isIdKey(elementKey)) {
      // for cases where we don't have access to the element object
      // (e.g. restore serialized appState with id references)
      this.sceneMapById.set(elementKey, scene);
    } else {
      this.sceneMapByElement.set(elementKey, scene);
      // if mapping element objects, also cache the id string when later
      // looking up by id alone
      this.sceneMapById.set(elementKey.id, scene);
    }
  }

  static getScene(elementKey: ElementKey): Scene | null {
    if (isIdKey(elementKey)) {
      return this.sceneMapById.get(elementKey) || null;
    }
    return this.sceneMapByElement.get(elementKey) || null;
  }

  // ---------------------------------------------------------------------------
  // instance methods/props
  // ---------------------------------------------------------------------------

  private callbacks: Set<SceneStateCallback> = new Set();

  private nonDeletedElements: readonly NonDeletedExcalidrawElement[] = [];
  private nonDeletedElementsMap = toBrandedType<NonDeletedSceneElementsMap>(
    new Map(),
  );
  private elements: readonly ExcalidrawElement[] = [];
  private elementsMap = toBrandedType<SceneElementsMap>(new Map());
  private selectedElementsCache: {
    selectedElementIds: AppState["selectedElementIds"] | null;
    elements: readonly NonDeletedExcalidrawElement[] | null;
    cache: Map<SelectionHash, NonDeletedExcalidrawElement[]>;
  } = {
      selectedElementIds: null,
      elements: null,
      cache: new Map(),
    };
  private versionNonce: number | undefined;

  getElementsMapIncludingDeleted() {
    return this.elementsMap;
  }

  getNonDeletedElementsMap() {
    return this.nonDeletedElementsMap;
  }

  getElementsIncludingDeleted() {
    return this.elements;
  }

  getNonDeletedElements(): readonly NonDeletedExcalidrawElement[] {
    return this.nonDeletedElements;
  }

  getSelectedElements(opts: {
    // NOTE can be ommitted by making Scene constructor require App instance
    selectedElementIds: AppState["selectedElementIds"];
    /**
     * for specific cases where you need to use elements not from current
     * scene state. This in effect will likely result in cache-miss, and
     * the cache won't be updated in this case.
     */
    elements?: ElementsMapOrArray;
    // selection-related options
    includeBoundTextElement?: boolean;
  }): NonDeleted<ExcalidrawElement>[] {
    const hash = hashSelectionOpts(opts);

    const elements = opts?.elements || this.nonDeletedElements;
    if (
      this.selectedElementsCache.elements === elements &&
      this.selectedElementsCache.selectedElementIds === opts.selectedElementIds
    ) {
      const cached = this.selectedElementsCache.cache.get(hash);
      if (cached) {
        return cached;
      }
    } else if (opts?.elements == null) {
      // if we're operating on latest scene elements and the cache is not
      //  storing the latest elements, clear the cache
      this.selectedElementsCache.cache.clear();
    }

    const selectedElements = getSelectedElements(
      elements,
      { selectedElementIds: opts.selectedElementIds },
      opts,
    );

    // cache only if we're not using custom elements
    if (opts?.elements == null) {
      this.selectedElementsCache.selectedElementIds = opts.selectedElementIds;
      this.selectedElementsCache.elements = this.nonDeletedElements;
      this.selectedElementsCache.cache.set(hash, selectedElements);
    }

    return selectedElements;
  }

  getElement<T extends ExcalidrawElement>(id: T["id"]): T | null {
    return (this.elementsMap.get(id) as T | undefined) || null;
  }

  getVersionNonce() {
    return this.versionNonce;
  }

  getNonDeletedElement(
    id: ExcalidrawElement["id"],
  ): NonDeleted<ExcalidrawElement> | null {
    const element = this.getElement(id);
    if (element && isNonDeletedElement(element)) {
      return element;
    }
    return null;
  }

  /**
   * A utility method to help with updating all scene elements, with the added
   * performance optimization of not renewing the array if no change is made.
   *
   * Maps all current excalidraw elements, invoking the callback for each
   * element. The callback should either return a new mapped element, or the
   * original element if no changes are made. If no changes are made to any
   * element, this results in a no-op. Otherwise, the newly mapped elements
   * are set as the next scene's elements.
   *
   * @returns whether a change was made
   */
  mapElements(
    iteratee: (element: ExcalidrawElement) => ExcalidrawElement,
  ): boolean {
    let didChange = false;
    const newElements = this.elements.map((element) => {
      const nextElement = iteratee(element);
      if (nextElement !== element) {
        didChange = true;
      }
      return nextElement;
    });
    if (didChange) {
      this.replaceAllElements(newElements);
    }
    return didChange;
  }

  replaceAllElements(nextElements: ElementsMapOrArray, mapElementIds = true) {
    const elements =
      // ts doesn't like `Array.isArray` of `instanceof Map`
      nextElements instanceof Array
        ? nextElements
        : Array.from(nextElements.values());
    this.elements = elements
      .slice()
      .sort((a, b) => {
        // CHANGED:ADD 2023/08/24 #937
        if (isTaskElement(a) && isTaskElement(b)) {
          return a.endDate.valueOf() > b.endDate.valueOf() ? -1 : 1;
        }
        // CHANGED:ADD 2023-3-11 #690
        if (a.priority !== b.priority) {
          return a.priority - b.priority;
        }

        return 0;
      });
    this.elementsMap.clear();
    this.elements.forEach((element) => {
      this.elementsMap.set(element.id, element);
      Scene.mapElementToScene(element, this);
    });
    const nonDeletedElements = getNonDeletedElements(this.elements);
    this.nonDeletedElements = nonDeletedElements.elements;
    this.nonDeletedElementsMap = nonDeletedElements.elementsMap;
    this.informMutation();
  }

  // CHANGED:ADD 2023-1-24 #492
  updateBoundTextElement(
    boundTextElement: ExcalidrawTextElement,
    text: string,
    originalText: string,
    isDeleted: boolean,
  ) {
    const elementsMap = this.getElementsMapIncludingDeleted();

    this.replaceAllElements([
      ...this.getElementsIncludingDeleted().map((_element) => {
        if (_element.id === boundTextElement.id && isTextElement(_element)) {
          return updateTextElement(
            _element,
            getContainerElement(_element, elementsMap),
            elementsMap,
            {
              text,
              isDeleted,
              originalText,
            },
          );
        }
        return _element;
      }),
    ]);
  }

  // CHANGED:ADD 2023-1-24 #492
  replaceBoundTextElement(
    boundTextElement: ExcalidrawTextElement,
    replaceElement: ExcalidrawTextElement,
  ) {
    const elementsMap = this.getElementsMapIncludingDeleted();

    this.replaceAllElements([
      ...this.getElementsIncludingDeleted().map((_element) => {
        if (_element.id === boundTextElement.id && isTextElement(_element)) {
          return updateTextElement(
            _element,
            getContainerElement(_element, elementsMap),
            elementsMap,
            {
              ...replaceElement,
            }
          );
        }
        return _element;
      }),
    ]);
  }

  informMutation() {
    this.versionNonce = randomInteger();

    for (const callback of Array.from(this.callbacks)) {
      callback();
    }
  }

  addCallback(cb: SceneStateCallback): SceneStateCallbackRemover {
    if (this.callbacks.has(cb)) {
      throw new Error();
    }

    this.callbacks.add(cb);

    return () => {
      if (!this.callbacks.has(cb)) {
        throw new Error();
      }
      this.callbacks.delete(cb);
    };
  }

  destroy() {
    this.nonDeletedElements = [];
    this.elements = [];
    this.elementsMap.clear();
    this.selectedElementsCache.selectedElementIds = null;
    this.selectedElementsCache.elements = null;
    this.selectedElementsCache.cache.clear();

    Scene.sceneMapById.forEach((scene, elementKey) => {
      if (scene === this) {
        Scene.sceneMapById.delete(elementKey);
      }
    });

    // done not for memory leaks, but to guard against possible late fires
    // (I guess?)
    this.callbacks.clear();
  }

  // CHANGED:ADD 2023-2-10 #634
  jobBackgroundElements: readonly ExcalidrawElement[] = [];
  GenerateJobBackgroundElements(Elements: readonly ExcalidrawElement[]) {
    this.jobBackgroundElements = Elements;
  }

  // CHANGED:ADD 2022-12-5 #250
  jobPanelElements: readonly ExcalidrawElement[] = [];
  GenerateJobPanelElements(Elements: readonly ExcalidrawElement[]) {
    this.jobPanelElements = Elements;
  }

  // CHANGED:ADD 2023-03-01 #726
  jobLineElements: readonly ExcalidrawElement[] = [];
  GenerateJobLineElements(Elements: readonly ExcalidrawElement[]) {
    this.jobLineElements = Elements;
  }

  // CHANGED:ADD 2023-2-11 #671
  milestoneLineElements: readonly ExcalidrawElement[] = [];
  GenerateMilestoneLineElements(Elements: readonly ExcalidrawElement[]) {
    this.milestoneLineElements = Elements;
  }
  //CHANGED:ADD 2023-01-12 #391
  updateBackgroundHeight(jobHeight: number) {

    // CHANGED:ADD 2023-2-11 #671
    this.jobBackgroundElements = this.jobBackgroundElements.map(
      (element) => {
        return {
          ...element,
          height: jobHeight,
        };
      },
    );

    this.milestoneLineElements = this.milestoneLineElements.map(
      (element) => {
        return {
          ...element,
          height: jobHeight,
        };
      },
    );
  }
  // CHANGED:ADD 2023-2-11 #671
  updateMilestoneLine(milestoneElement: ExcalidrawMilestoneElement) {
    this.milestoneLineElements = this.milestoneLineElements.map((element) => {
      if (
        element.boundElements &&
        element.boundElements[0].id === milestoneElement.id
      ) {
        return {
          ...element,
          x: milestoneElement.x + milestoneElement.width / 2,
        };
      }

      return element;
    });
  }

  insertElementAtIndex(element: ExcalidrawElement, index: number) {
    if (!Number.isFinite(index) || index < 0) {
      throw new Error(
        "insertElementAtIndex can only be called with index >= 0",
      );
    }
    const nextElements = [
      ...this.elements.slice(0, index),
      element,
      ...this.elements.slice(index),
    ];
    this.replaceAllElements(nextElements);
  }

  getElementIndex(elementId: string) {
    return this.elements.findIndex((element) => element.id === elementId);
  }

  getContainerElement = (
    element:
      | (ExcalidrawElement & {
        containerId: ExcalidrawElement["id"] | null;
      })
      | null,
  ) => {
    if (!element) {
      return null;
    }
    if (element.containerId) {
      return this.getElement(element.containerId) || null;
    }
    return null;
  };

  // CHANGED:ADD 2022-12-02 #229
  getRenderingElements(
    _elements?: readonly NonDeletedExcalidrawElement[],
  ) {
    /**
     * The layer hierarchy
     * background < job panel < job line < milestone line < job background < elements < job < calendar < calendar text < fixed
     */
    const elements = _elements ? _elements : this.getNonDeletedElements();

    let renderingElements: NonDeletedExcalidrawElement[] = [];
    renderingElements = renderingElements.concat(this.jobPanelElements);
    renderingElements = renderingElements.concat(this.jobLineElements); // CHANGED:ADD 2023-03-01 #726
    // CHANGED:REMOVE 2023/08/25 #932
    // renderingElements = renderingElements.concat(this.milestoneLineElements); // CHANGED:ADD 2023-2-11 #671
    renderingElements = renderingElements.concat(this.jobBackgroundElements); // CHANGED:ADD 2023-2-10 #638
    renderingElements = renderingElements.concat(elements);

    return renderingElements.sort((a, b) => a.priority - b.priority);
  }
}

export default Scene;
