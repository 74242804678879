import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { t } from "src/excalidraw/i18n";
import { useStore } from "src/conpath/hooks/useStore";
import {
  useDevice,
  useExcalidrawSetAppState,
} from "src/excalidraw/components/App";
import { useOnClickOutside } from "src/excalidraw/extensions/hooks/useOutsideClick";
import { trackEvent } from "src/excalidraw/analytics";
import { AppState } from "src/excalidraw/types";
import { ExcalidrawCommentElement, ExcalidrawCommentableElement } from "../element/types";

import { Sidebar } from "src/excalidraw/components/Sidebar/Sidebar";
import ProjectModel from "src/conpath/models/ProjectModel";

import "./CommentsSidebarPanel.scss";
import CommentList from "src/conpath/components/sidebar/CommentList";
import { ActionManager } from "src/excalidraw/actions/manager";
import { actionSendComment, CommentActionValue } from "../actions/actionComment";

interface Props {
  appState: AppState;
  actionManager: ActionManager,
  commentElement?: ExcalidrawCommentElement;
  commentableElement: ExcalidrawCommentableElement;
};

export const CommentsSidebarPanel: React.FC<Props> = observer(({
  appState,
  actionManager,
  commentElement,
  commentableElement
}) => {

  const setAppState = useExcalidrawSetAppState();
  const device = useDevice();
  const { userStore, organizationStore } = useStore();

  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [project, setProject] = useState<ProjectModel|null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const users = selectedOrganization?.users || [];

  useEffect(() => {
    const getComments = async () => {
      if (selectedOrganization && commentableElement) {
        const selectedProject = selectedOrganization.
        projects.find((project) => project.id === appState.projectId);

        const result = await selectedProject?.getCommentsByElementId(commentableElement.id, users);
        if (result && result.error) setAppState({ errorMessage: result.error });
        
        setProject(selectedProject!);
      }
    }

    getComments();

    return () => {
      project?.clearComments();
      setProject(null);
    }
  }, [commentableElement]);

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ openSidebar: null });
  }, [setAppState]);

  useOnClickOutside(
    ref,
    useCallback(
      (event) => {
        // If click on the library icon, do nothing so that LibraryButton
        // can toggle library menu
        if ((event.target as Element).closest(".ToolIcon__library")) {
          return;
        }
        if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
          closeLibrary();
        }
      },
      [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar],
    ),
  );

  const backToThreadList = () => {

    setAppState((prev) => ({
      selectedElementIds: _.omit(prev.selectedElementIds, commentableElement.id),
      openSidebar: "threadList",
    }));
  };

  const commentSubmitCallback = (commentId: string) => {
    actionManager.executeAction(actionSendComment, "api", {
      type: "CREATE",
      commentableElement: commentableElement,
      commentId: commentId,
    } as CommentActionValue);
  };

  const commentDeleteCallback = () => {
    actionManager.executeAction(actionSendComment, "api", {
      type: "DELETE",
      commentableElement: commentableElement,
    } as CommentActionValue);
  };

  return (
    <Sidebar
      __isInternal
      // necessary to remount when switching between internal
      // and custom (host app) sidebar, so that the `props.onClose`
      // is colled correctly
      key="comment"
      className="layer-ui__comment-sidebar"
      initialDockedState={appState.isSidebarDocked}
      onDock={(docked) => {
        trackEvent(
          "library",
          `toggleLibraryDock (${docked ? "dock" : "undock"})`,
          `sidebar (${device.isMobile ? "mobile" : "desktop"})`,
        );
      }}
      ref={ref}
    >
      <Sidebar.Header className="layer-ui__comment-header">
        <div className="header-content-wrapper">
          <button 
            className="header-label"
            onClick={backToThreadList}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 320 512">
              <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" /> 
            </svg>
            <p>{t("labels.comment.backToAllThreads")}</p>
          </button>
        </div>
      </Sidebar.Header>
      { selectedOrganization && loginUser && project && (
        <div className="layer-ui__comment-list-body">
          <CommentList 
            isTaskClosed={commentableElement.isClosed}
            isCanvas={true}
            stickyThread={true}
            commentElementId={commentElement?.id}
            commentableElementId={commentableElement.id}
            project={project}
            loginUser={loginUser}
            selectedOrganization={selectedOrganization}
            setErrorText={(error) => {
              setAppState({ errorMessage: error });
            }}
            updateCallback={commentSubmitCallback}
            deleteCallback={commentDeleteCallback}
          />
        </div>
      )}
    </Sidebar>
  );
});