import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import jwt_decode from "jwt-decode";

//mobx
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

//interfaces
import { SignInForm } from "src/conpath/interfaces/AuthForm";
import { InvitationBody } from "src/conpath/interfaces/Invitation";

//styles
import "./Login.scss";
import Colors from "src/conpath/constants/Colors";
import { Paths } from "src/conpath/constants/Routes";
//constants
import { SessionKeys } from "src/conpath/constants/SessionKeys";

const Login: React.FC = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [state, setState] = useState<SignInForm>({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [isInvited, setIsInvited] = useState<boolean>(false);

  useEffect(() => {
    // Check if invited
    const token = sessionStorage.getItem(SessionKeys.InvitationToken);
    if (token) {
      const decoded = jwt_decode(token) as InvitationBody;
      setState(prev => ({ ...prev, email: decoded.email }));
      setIsInvited(true);
    }
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setState((prev) => ({
        ...prev, 
        [name]: name === "email" ? value.replace(/\s/g, "") : value
      }));
    }
    setErrorText("");
  };

  const onSubmit = async () => {
    if (!userStore) return;
    setIsLoading(true);
    const result = await userStore.signIn(state, isInvited);

    setIsLoading(false);
    if (result.error) {
      setErrorText(result.error);
      return;
    }

    if (isInvited) {
      navigate(Paths.invite + Paths.accept, {
        state: {
          userId: result.userId!,
          skipDeletingAccount: true,
        },
        replace: true,
      });
    } else {
      navigate(Paths.dashboard, { replace: true });
    }
  };

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    // Check for the `Enter` key
    if (e.key === "Enter") {
      onSubmit(); // Call your onSubmit function
    }
  };

  const onForgotPasswordTextPressed = () => {
    navigate(Paths.resetPassword);
  };

  return (
    <div className="input__body">
      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
      <div className="input__card topSpacer">
        <h2 className="input__title">コンパスにログインする</h2>
        <div className="error-text-wrapper">
          <p>{errorText}</p>
        </div>
        <div className="input__wrapper minSpacer">
          <p className="input__label">メールアドレス</p>
          <input
            value={state.email}
            onChange={onChange}
            onKeyDown={onKeyPress}
            disabled={isInvited}
            name="email"
            placeholder="メールアドレス"
          />
          <p className="input__label">パスワード</p>
          <input
            value={state.password}
            onChange={onChange}
            onKeyDown={onKeyPress}
            type="password"
            name="password"
            placeholder="パスワード"
          />
          {!isLoading && (
            <p
              className="input__label clickable"
              onClick={onForgotPasswordTextPressed}
            >
              パスワードをお忘れの場合はこちら。
            </p>
          )}
        </div>
        {isLoading ? (
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="32"
              visible={true}
            />
          </div>
        ) : (
          <div className="input__buttonWrapper">
            {/* CHANGED:UPDATE 2023/07/24 #860 ベータ版では無効にしておく */}
            {/* <button
              onClick={() => navigate(Paths.signup)}
              className="text-button">
              新規登録
            </button> */}
            <button onClick={onSubmit} className="button main">
              ログイン
            </button>
          </div>
        )}
      </div>
      {/* <div className="input__card topSpacer">
        <p>アカウントをお持ちでない方</p>
        <button
          onClick={() => navigate(Paths.signup)}
          // disabled={true}
          style={{ color: "gray" }}
          className="text-button minTopSpacer"
        >
          新規登録はこちら
        </button>
      </div> */}
    </div>
  );
});

export default Login;
