import { action, computed, makeObservable, observable, runInAction } from "mobx";
import _ from "lodash";
import LZString from "lz-string";
import type { Socket } from "socket.io-client";
import dayjs from "dayjs";

//types and interface
import Project, { ProjectResponse } from "src/conpath/interfaces/Project";
import { ElementsMap, ExcalidrawElement, FileId } from "src/excalidraw/element/types";
import { OrganizationUserDocumentFields, OrganizationUserResponse, ProjectUserRoles  } from "src/conpath/interfaces/User";
import { ProjectTeamRoles } from "src/conpath/interfaces/Team";
import Comment, { CommentResponse, UploadedFile } from "src/conpath/interfaces/Comment";
import { ProjectResources, ResourceDocumentFields, ResourceResponse } from "../interfaces/Resource";
import { Tags } from "../interfaces/Tag";
import { ElementInfoValue } from "../interfaces/Project";
import { Layer, LayerDocumentFields, LayerResponse } from "../interfaces/Layer";
import Milestone, { MilestoneDocumentFields } from "../interfaces/Milestone";
import Task, { TaskResponse } from "../interfaces/Task";
import InternalError from "../interfaces/InternalError";
import { FirebaseHttpsRequests } from "../constants/FirebaseHttpsRequests";
import { DeleteOrRestoreCommentsParam, ThreadUpdateType, UpdatingThreadParam } from "../interfaces/HttpRequests";
import { CommentDocumentFields } from "../interfaces/Comment";

//constants
import { DEFAULT_PROJECT_COLOR } from "../constants/Colors";

//firebase
import { db, DocumentRefType, functions, storage } from "src/configs/firebase";
import {
  Timestamp,
  CollectionReference,
  collection,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  writeBatch,
  where,
  orderBy,
  limit,
  DocumentData,
  setDoc
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import {
  httpsCallable,
  HttpsCallableResult
} from "firebase/functions";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import { getSceneVersion } from "src/excalidraw/element";
import { dateToFirebaseTime, firebaseTimeToDate } from "src/utils/timeUtils";

//models
import TaskModel from "./TaskModel";
import TeamModel from "./TeamModel";
import MilestoneModel from "./MilestoneModel";
import CommentModel from "./CommentModel";
import OrganizationUserModel from "./OrganizationUserModel";
import LayerModel from "./LayerModel";

//excalidraw
import Portal from "src/excalidraw/excalidraw-app/collab/Portal";
import { getSyncableElements, SyncableExcalidrawElement } from "src/excalidraw/excalidraw-app/data";
import { AppState, BinaryFileData, BinaryFileMetadata, DataURL } from "src/excalidraw/types";
import { reconcileElements } from "src/excalidraw/excalidraw-app/collab/reconciliation";
import { restoreElements } from "src/excalidraw/data/restore";
import { FILE_CACHE_MAX_AGE_SEC } from "src/excalidraw/excalidraw-app/app_constants";
import { FirebaseStorage } from "../constants/FirebaseStorage";
import { MIME_TYPES } from "src/excalidraw/constants";
import { decompressData } from "src/excalidraw/data/encode";
import { ProjectRole } from "../constants/Role";
import { isCommentElement, isLinkElement, isMilestoneElement, isTaskElement } from "src/excalidraw/extensions/element/typeChecks";
import { getBoundTextElement, getContainerElement } from "src/excalidraw/element/textElement";
import { isBoundToContainer, isTextElement } from "src/excalidraw/element/typeChecks";

// class FirebaseSceneVersionCache {
//   private static cache = new WeakMap<SocketIOClient.Socket, number>();
//   static get = (socket: SocketIOClient.Socket) => {
//     return FirebaseSceneVersionCache.cache.get(socket);
//   };
//   static set = (
//     socket: SocketIOClient.Socket,
//     elements: readonly SyncableExcalidrawElement[],
//   ) => {
//     FirebaseSceneVersionCache.cache.set(socket, getSceneVersion(elements));
//   };
// }
class FirebaseElementVersionCache {
  private static cache = new Map<string, number>();
  static get = (id: string) => {
    return FirebaseElementVersionCache.cache.get(id);
  };
  static set = (
    element: ExcalidrawElement,
  ) => {
    FirebaseElementVersionCache.cache.set(element.id, element.version);
  };
}


class HoverElementInfoCache {
  private static cache = new Map<string, ElementInfoValue>();
  public static get = (elementId: string) => HoverElementInfoCache.cache.get(elementId);
  public static set = (elementId: string, value: ElementInfoValue) => HoverElementInfoCache.cache.set(elementId, value);
  public static destroy = () => HoverElementInfoCache.cache = new Map<string, ElementInfoValue>();
}

interface FirebaseStoredScene {
  sceneVersion: number;
  updatedAt: Timestamp;
};

export default class ProjectModel implements Project {

  @observable
  id: string;
  @observable
  name: string;
  @observable
  color: string;
  @observable
  address: string;
  @observable
  description: string;
  @observable
  tags: Tags;
  @observable
  startDate: Timestamp;
  @observable
  endDate: Timestamp;
  @observable
  sceneVersion: number;
  @observable
  holidays: string[];
  @observable
  jobsHeight: number;
  @observable
  backgroundColor: string;
  @observable
  roles: ProjectUserRoles;
  @observable
  teams: ProjectTeamRoles;
  @observable
  resources: ProjectResources;
  @observable
  isArchived: boolean;
  @observable
  archivedAt: Timestamp | null;
  @observable
  updatedAt: Timestamp;

  @observable
  comments: CommentModel[] = [];
  @observable
  threads: CommentModel[] = [];

  @observable
  threadSearchText: string = "";

  organizationId: string = "";

  todayTaskCount: number = 0;
  overdueTaskCount: number = 0;

  @observable.deep
  layers: LayerModel[] = [];

  private isHoverTaskInfoRequesting: boolean = false;

  constructor(project: ProjectResponse) {
    makeObservable(this);
    this.id = project.id;
    this.name = project.name;
    this.color = project.color || DEFAULT_PROJECT_COLOR;
    this.address = project.address;
    this.description = project.description || "";
    this.tags = project.tags || {};
    this.startDate = project.startDate;
    this.endDate = project.endDate;
    this.sceneVersion = project.sceneVersion;
    this.holidays = project.holidays;
    this.jobsHeight = project.jobsHeight;
    this.backgroundColor = project.backgroundColor;
    this.roles = project.roles || {};
    this.teams = project.teams || {};
    this.resources = project.resources || {};
    this.isArchived = project.isArchived;
    this.archivedAt = project.archivedAt;
    this.updatedAt = project.updatedAt || Timestamp.now();
  };

  //public

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  @action
  public setStartDate(startDate: Date) {
    this.startDate = dateToFirebaseTime(startDate);
  }

  @action
  public setEndDate(endDate: Date) {
    this.endDate = dateToFirebaseTime(endDate);
  }

  @action
  public setHolidays(holidays: string[]) {
    this.holidays = holidays;
  }

  @action
  public setJobsHeight(jobsHeight: number) {
    this.jobsHeight = jobsHeight;
  }

  @action
  public setBackgroundColor(backgroundColor: string) {
    this.backgroundColor = backgroundColor;
  }

  @action
  public setTodayTaskCount(count: number) {
    this.todayTaskCount = count;
  }

  @action
  public setOverdueTaskCount(count: number) {
    this.overdueTaskCount = count;
  }

  @action
  public addMembers() {

  }

  @action
  public addTeams() {

  }

  public getUserRole(userId: string = ""): ProjectRole | null {
    const key = Object.keys(this.roles).find((key) => key === userId) || "";
    return this.roles[key] || null;
  }

  public getTeamRole(userId: string = "", teams: TeamModel[]): ProjectRole | null {
    let projectTeamRole: ProjectRole = ProjectRole.viewer;
    if (teams) {
      Object.keys(this.teams).forEach((key) => {
        if (teams.some((team) => key === team.id && team.userIds.includes(userId))) {
          projectTeamRole = Math.min(projectTeamRole, this.teams[key]) as ProjectRole;
        }
      });
    }

    return projectTeamRole;
  }

  public async saveElements(
    portal: Portal,
    elements: readonly SyncableExcalidrawElement[],
    elementsMap: ElementsMap,
    appState: AppState,
  ) {
    const { roomId, roomKey, socket } = portal;
    if (
      // bail if no room exists as there's nothing we can do at this point
      !roomId ||
      !roomKey ||
      !socket ||
      this.isSavedToFirebase(portal, elements) ||
      appState.selectedLinkElement || // CHANGED:ADD 2023/08/31 #969
      isLinkElement(appState.editingElement) // CHANGED:ADD 2023/08/31 #969
    ) {
      return false;
    }

    const projectRef = this.getProjectDocumentRef();
    if (_.isEmpty(projectRef)) return;

    const updatedTasks: TaskModel[] = [];
    const updatedMilestones: MilestoneModel[] = [];
    const updatingThreads: UpdatingThreadParam[] = [];
    elements.forEach((el) => {
      const cache = FirebaseElementVersionCache.get(el.id)
      if (isTaskElement(el) && (!cache || cache < el.version)) {
        const task: Task = {
          ...el,
          projectId: this.id,
          text: getBoundTextElement(el, elementsMap)?.originalText || ""
        };

        updatedTasks.push(new TaskModel(task));
      } else if (isMilestoneElement(el) && (!cache || cache < el.version)) {
        const milestone: Milestone = {
          ...el,
          projectId: this.id,
          text: getBoundTextElement(el, elementsMap)?.originalText || ""
        };

        updatedMilestones.push(new MilestoneModel(milestone));
      } else if (isCommentElement(el) && (!cache || cache < el.version)) {
        const thread: UpdatingThreadParam = { 
          id: el.id,
          type: el.isDeleted ? ThreadUpdateType.DELETE
                             : ThreadUpdateType.RESTORE,
          isDeleted: el.isDeleted,
        };
        updatingThreads.push(thread);
      } else if (isTextElement(el) && isBoundToContainer(el) && (!cache || cache < el.version)) {
        if (updatedTasks.findIndex((t) => t.id === el.containerId) === -1) {
          const container = getContainerElement(el, elementsMap);

          if (isTaskElement(container)) {
            const task: Task = {
              ...container,
              projectId: this.id,
              text: el.originalText || ""
            };

            updatedTasks.push(new TaskModel(task));
          } else if (isMilestoneElement(container)) {
            const milestone: Milestone = { ...container, projectId: this.id, text: el.originalText || "" };

            updatedMilestones.push(new MilestoneModel(milestone));
          }
        }
      }
    });

    const taskRef = this.getTaskCollectionRef();
    if (!_.isEmpty(taskRef)) {
      for (const chunkedTasks of _.chunk(updatedTasks, 500)) {
        const batch = writeBatch(db);
        chunkedTasks.forEach((task) => {
          if (task.isDeleted) {
            batch.delete(doc(taskRef, task.id));
          } else {
            batch.set(doc(taskRef, task.id), task.getUpdateTaskFields(), { merge: true });
          }
        });
        await batch.commit();
      }
    }

    const milestoneRef = this.getMilestoneCollectionRef();
    if (!_.isEmpty(milestoneRef)) {
      for (const chunkedMilestones of _.chunk(updatedMilestones, 500)) {
        const batch = writeBatch(db);
        chunkedMilestones.forEach((milestone) => {
          if (milestone.isDeleted) {
            batch.delete(doc(milestoneRef, milestone.id));
          } else {
            batch.set(doc(milestoneRef, milestone.id), milestone.getFields());
          }
        });
        await batch.commit();
      }
    }
  
    // 消去、復元フラグの更新を行う
    const commentRef = this.getCommentRef();
    if (!_.isEmpty(commentRef)) {
      const requests: Promise<HttpsCallableResult>[] = [];
      _.chunk(updatingThreads, 50).forEach((chunk) => {
        const threads: UpdatingThreadParam[] = [];
        chunk.forEach((c) => {
          if (c.id) threads.push({
            id: c.id,
            type: c.type,
            isDeleted: c.isDeleted,
          });
        });
        const params: DeleteOrRestoreCommentsParam  = {
          organizationId: this.organizationId,
          projectId: this.id,
          threads: threads,
        };
        const request = httpsCallable(functions, FirebaseHttpsRequests.enqueueHandleDeleteOrRestoreComments);
        requests.push(request(params));
      });

      // 消去処理はN+1なので非同期で行う
      if (requests.length) await Promise.all(requests);
    }

    // CHANGED:ADD #2023/07/23 #862
    const prefix = `${FirebaseStorage.projects}/${this.organizationId}`;

    const savedData = await runTransaction(db, async (transaction) => {
      // CHANGED:UPDATE 2023/08/03 #893
      // const snapshot = await transaction.get(projectRef);

      // if (!snapshot.exists()) {
      //   const sceneDocument = this.createFirebaseSceneDocument(
      //     elements,
      //     roomKey,
      //     appState, // CHANGED:ADD 2023/07/28 #864
      //   );

      //   transaction.update(projectRef, sceneDocument);

      //   // CHANGED:ADD #2023/07/23 #862
      //   // elementsをstorageに保存
      //   this.saveElementsToStorage(prefix, elements);

      //   return {
      //     elements,
      //     reconciledElements: null,
      //   };
      // }

      // // CHANGED:UDPATE #2023/07/23 #862
      // // const prevDocData = snapshot.data() as ProjectResponse;
      // // const prevElements = getSyncableElements(
      // //   this.decryptElements(prevDocData.elements),
      // // );
      // const prevData = await this.loadElementsToStorage(prefix);
      // const prevElements = getSyncableElements(prevData);

      // const reconciledElements = getSyncableElements(
      //   reconcileElements(elements, prevElements, appState),
      // );

      // const sceneDocument = this.createFirebaseSceneDocument(
      //   reconciledElements,
      //   roomKey,
      //   appState, // CHANGED:ADD 2023/07/28 #864
      // );

      // transaction.update(projectRef, sceneDocument);

      // // CHANGED:ADD #2023/07/23 #862
      // // elementsをstorageに保存
      // this.saveElementsToStorage(prefix, reconciledElements);

      // return {
      //   elements,
      //   reconciledElements,
      // };
      const sceneDocument = this.createFirebaseSceneDocument(elements, roomKey);

      transaction.update(projectRef, { ...sceneDocument });

      return {
        elements,
        reconciledElements: null,
      };
    });

    // CHANGED:ADD #2023/07/23 #862
    // elementsをstorageに保存
    this.saveElementsToStorage(prefix, elements);

    // FirebaseSceneVersionCache.set(socket, savedData.elements);
    this.setFirebaseVersionCache(elements);

    return { reconciledElements: savedData.reconciledElements };
  }

  public async loadElements(
    socket: Socket | null,
  ): Promise<readonly ExcalidrawElement[] | null> {
    if (_.isEmpty(this.organizationId)) return null;

    // CHANGED:UDPATE #2023/07/23 #862
    // const projectRef = this.getProjectRef();
    // if (_.isEmpty(projectRef)) return null;

    // const snapshot = await projectRef.get();
    // if (!snapshot.exists()) {
    //   return null;
    // }

    // const prevDocData = snapshot.data() as ProjectResponse;
    // const elements = getSyncableElements(
    //   this.decryptElements(prevDocData.elements),
    // );
    const prefix = `${FirebaseStorage.projects}/${this.organizationId}`;
    const prevData = await this.loadElementsToStorage(prefix);
    const elements = getSyncableElements(prevData);

    // if (socket) {
    //   FirebaseSceneVersionCache.set(socket, elements);
    // }
    this.setFirebaseVersionCache(elements);

    return restoreElements(elements, null);
  }

  public isSavedToFirebase(
    portal: Portal,
    elements: readonly ExcalidrawElement[],
  ): boolean {
    // CHANGED:UPDATE 2023/09/20 #1098
    // if (portal.socket && portal.roomId && portal.roomKey) {
    if (portal.socket && portal.roomId && portal.roomKey && elements.length > 0) {
      // const sceneVersion = getSceneVersion(elements);

      // return FirebaseSceneVersionCache.get(portal.socket) === sceneVersion;
      return elements.every((el) => {
        const cache = FirebaseElementVersionCache.get(el.id);
        return cache && cache === el.version;
      });
    }
    // if no room exists, consider the room saved so that we don't unnecessarily
    // prevent unload (there's nothing we could do at that point anyway)
    return true;
  };

  public async saveFilesToStorage({
    prefix,
    files,
  }: {
    prefix: string;
    files: { id: FileId; buffer: Uint8Array }[];
  }): Promise<{ savedFiles: Map<FileId, true>, erroredFiles: Map<FileId, true> }> {
    const erroredFiles = new Map<FileId, true>();
    const savedFiles = new Map<FileId, true>();

    await Promise.all(
      files.map(async ({ id, buffer }) => {
        try {
          await uploadBytesResumable(
            ref(storage, `${prefix}/${id}`),
            new Blob([buffer], {
              type: MIME_TYPES.binary,
            }),
            {
              cacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`,
            },
          );
          savedFiles.set(id, true);
        } catch (error: any) {
          erroredFiles.set(id, true);
        }
      }),
    );

    return { savedFiles, erroredFiles };
  }

  public async loadFilesFromStorage(
    prefix: string,
    decryptionKey: string,
    filesIds: readonly FileId[],
  ): Promise<{ loadedFiles: BinaryFileData[], erroredFiles: Map<FileId, true> }> {
    const loadedFiles: BinaryFileData[] = [];
    const erroredFiles = new Map<FileId, true>();

    await Promise.all(
      [...new Set(filesIds)].map(async (id) => {
        try {
          const fileRef = ref(storage, `${prefix}/${id}`);
          const url = await getDownloadURL(fileRef);
          const response = await fetch(url);

          if (!response.ok) {
            erroredFiles.set(id, true);
            console.error("Failed to fetch file:", response.status);
            return;
          }

          const arrayBuffer = await response.arrayBuffer();
          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;
          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } catch (error: any) {
          erroredFiles.set(id, true);
          console.error("Error loading file:", error);
        }
      }),
    );

    return { loadedFiles, erroredFiles };
  }

  // CHANGED:ADD #2023/07/23 #862
  public async saveElementsToStorage(
    prefix: string,
    elements: readonly SyncableExcalidrawElement[],
  ) {
    const compressedData = LZString.compressToBase64(JSON.stringify(elements));

    const fileRef = ref(storage, `${prefix}/${this.id}`);
    uploadString(fileRef, compressedData)
      .then((snapshot) => {
        // Upload completed successfully
      });
  }

  public async loadElementsToStorage(
    prefix: string,
  ): Promise<readonly ExcalidrawElement[]> {
    const fileRef = ref(storage, `${prefix}/${this.id}`);

    try {
      const url = await getDownloadURL(fileRef);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data from storage");
      }

      const data = await response.text();
      const json = JSON.parse(LZString.decompressFromBase64(data));
      return json as ExcalidrawElement[];
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case "storage/unauthorized":
          case "storage/unknown":
            throw new Error("Unauthorized or unknown error occurred");
          default:
            throw error; // Re-throw other errors
        }
      }
      throw new Error("An unknown error occurred");
    }
  }

  public async updateProject(project: Project) {
    const projectRef = this.getProjectDocumentRef();
    if (_.isEmpty(projectRef)) return;

    await runTransaction(db, async (transaction) => {
      transaction.update(projectRef, { ...project });
    });

    runInAction(() => {
      this.startDate = project.startDate;
      this.endDate = project.endDate;
      this.holidays = project.holidays;
      this.jobsHeight = project.jobsHeight;
      this.backgroundColor = project.backgroundColor;
    });
  }

  public async saveTeams(teams: ProjectTeamRoles) {
    try {
      const projectRef = this.getProjectDocumentRef();
      if (_.isEmpty(projectRef)) return;

      // Updating project document
      const projectUpdatingField: Partial<ProjectResponse> = {
        teams: teams,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(projectRef, projectUpdatingField);
      });

      runInAction(() => {
        this.teams = teams;
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "チームの更新に失敗しました。" };
    }
  }

  @action
  public async updateIsArchived(isArchived: boolean): Promise<InternalError> {

    try {
      const projectRef = this.getProjectDocumentRef();
      if (_.isEmpty(projectRef)) {
        throw new Error("No project collection ref found.");
      }

      // Updating project document
      const projectUpdatingField: Partial<ProjectResponse> = {
        isArchived: isArchived,
        archivedAt: isArchived ? dateToFirebaseTime(new Date()) : null,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(projectRef, projectUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "アーカイブ状態の更新に失敗しました。" };
    }
  }

  public async delete() {
    const projectRef = this.getProjectDocumentRef();
    if (_.isEmpty(projectRef) || _.isEmpty(this.organizationId)) return;

    // collectionからprojectドキュメントを削除
    await deleteDoc(projectRef);
    // await this.deleteCollection(this.getTaskRef()!.path); // taskの削除はtaskのサブコレクション（checklistsなど）と一緒にcloud function内で行う。delete-task-checklist-document.tsx
    await this.deleteMilestoneCollection(this.getMilestoneCollectionRef()!.path);
    await this.deleteCollection(this.getLayerCollectionRef()!.path);
    
    // storageから削除
    const prefix = `${FirebaseStorage.projects}/${this.organizationId}`;

    async function deleteFile(id: string) {
      const fileRef = ref(storage, `${prefix}/${id}`);
      await deleteObject(fileRef);
      console.log(`${fileRef.name} deleted`);
    }

    await deleteFile(this.id);
  }

  public setFirebaseVersionCache(elements: readonly ExcalidrawElement[]) {
    elements.forEach((el) => FirebaseElementVersionCache.set(el));
  }

  @action
  public updateProjectSceneVersion(sceneVersion: number) {
    runInAction(() => {
      this.sceneVersion = sceneVersion;
    });
  }

  public async deleteTask(taskId: string) {
    const taskRef = this.getTaskCollectionRef();
    try {
      if (!taskRef) {
        throw new Error("[Error] Failed to get taskRef");
      }
      const taskDocumentRef = doc(taskRef, taskId);
      deleteDoc(taskDocumentRef);
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
    }
  }

  public async getCommentById(commentId: string): Promise<Comment|null> {
    const commentRef = this.getCommentRef();
    try {
      if (!commentRef) {
        throw new Error("[Error] Failed to get commentRef");
      }
      const commentDocumentRef = doc(commentRef, commentId);
      const documentSnap = await getDoc(commentDocumentRef);
      if (!documentSnap.exists) {
        throw new Error(`[Error] Comment: ${commentId} not found.`);
      }
      const data = documentSnap.data() as CommentResponse;
      return {
        ...data,
        createdAt: firebaseTimeToDate(data.createdAt),
        updatedAt: data.updatedAt ? firebaseTimeToDate(data.updatedAt) : null,
      } as Comment;
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return null;
    }
  }

  /**
   * CommentableElement に追加されたCommentElementを全て取得する関数
   * @param commentElementId commentable element: (taskelement, ) のID
   * @returns 
   */
  @action
  public async getCommentsByElementId(commentElementId: string, users: OrganizationUserModel[]): Promise<InternalError> {
    const commentRef = this.getCommentRef();
    try {
      if (!commentRef) {
        throw new Error("[Error] Failed to get commentRef");
      };

      const commentsQuery = query(commentRef,
        where(CommentDocumentFields.commentElementId, "==", commentElementId),
        where(CommentDocumentFields.isDeleted, "==", false),
        orderBy(CommentDocumentFields.createdAt, "desc")
      );
      const commentSnaps = await getDocs(commentsQuery);
      
      const comments: CommentModel[] = [];
      commentSnaps.forEach((doc) => {
        if (doc.exists()) {
          const c = doc.data() as CommentResponse;
          const comment = new CommentModel({
            ...c,
            createdAt: firebaseTimeToDate(c.createdAt),
            updatedAt: c.updatedAt ? firebaseTimeToDate(c.updatedAt) : null,
            lastReplayedAt: c.lastReplayedAt ? firebaseTimeToDate(c.lastReplayedAt) : null,
            uploadedFiles: c.uploadedFiles.map((file) => {
              return {
                ...file,
                hasUploaded: true,
              } as UploadedFile;
            })
          } as Comment);
          const user = users.find((u) => u.id === c.createdBy);

          comment.setOrganizationId(this.organizationId);
          if (user) comment.setUserInfo(user.username, user.profileImageUrl || "");
          comments.push(comment);
        }
      });

      runInAction(() => {
        this.comments = comments;
      });

      return {}
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return { error: error.message };
    }
  }
  
  @action
  public clearComments() {
    runInAction(() => {
      this.comments = [];
    })
  }

  public async getThreadByCommentableElementId(commentableElementId: string, users: OrganizationUserModel[]): Promise<CommentModel|null> {
    const commentRef = this.getCommentRef();
    try {
      if (!commentRef) {
        throw new Error("[Error] Failed to get comment collection ref.");
      }
      const commentsQuery = query(commentRef,
        where(CommentDocumentFields.projectId, "==", this.id),
        where(CommentDocumentFields.rootCommentId, "==", null),
        where(CommentDocumentFields.commentElementId, "==", commentableElementId),
        where(CommentDocumentFields.isDeleteElementRequired, "==", false),
        limit(1),
      );
      const commentSnaps = await getDocs(commentsQuery);

      if (commentSnaps.empty) return null;

      const doc = commentSnaps.docs[0];
      if (!doc.exists) return null;

      const c = doc.data() as CommentResponse;
      const thread = new CommentModel({
        ...c,
        createdAt: firebaseTimeToDate(c.createdAt),
        updatedAt: c.updatedAt ? firebaseTimeToDate(c.updatedAt) : null,
        lastReplayedAt: c.lastReplayedAt ? firebaseTimeToDate(c.lastReplayedAt) : null,
        } as Comment);
      const user = users.find((u) => u.id === thread.createdBy);
      if (user) thread.setUserInfo(user.username, user.profileImageUrl || "");
      thread.setOrganizationId(this.organizationId);

      return thread;
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here.
      return null;
    }
  }

  /**
   * 
   * @param users 
   * @returns 
   */
  @action async getThreadsInProject(
    threadIds: string[],
    users: OrganizationUserModel[]
  ): Promise<{ error: string | null }> {
    try {
      const allThreads = await this.getAllThreadsIncludingDeletedByProjectId();
      const threadList = allThreads
        .filter((thread) => !thread.isDeleted || threadIds.includes(thread.id))
        .sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()); 

      const threads: CommentModel[] = [];
      threadList.forEach((thread) => {
        const threadModel = new CommentModel(thread);
        const user = users.find((u) => u.id === threadModel.createdBy);
        threadModel.setOrganizationId(this.organizationId);
        if (user) threadModel.setUserInfo(user.username, user.profileImageUrl || "");
        threads.push(threadModel);
      });

      runInAction(() => {
        this.threads = threads;
      });

      return { error: null }
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return { error: "スレッドを取得できませんでした。" };
    }
  }

  @action
  public clearThreads() {
    runInAction(() => {
      this.threads = [];
    })
  }

  @action
  public setThreadSearchText(text: string) {
    runInAction(() => {
      this.threadSearchText = text;
    })
  }

  @computed get getComments() {
    return this.comments.slice().sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
  }

  @computed get getThreads() {
    return this.threads.slice()
      .filter((th) => this.threadSearchText.trim().length === 0 || th.text.includes(this.threadSearchText))
      .sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
  }

  public async getAllThreadsIncludingDeletedByProjectId(): Promise<Comment[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const commentRef = this.getCommentRef();
        if (!commentRef) throw new Error("[Error] Failed to get comment collection ref.");

        const commentsQuery = query(commentRef,
          where(CommentDocumentFields.projectId, "==", this.id),
          where(CommentDocumentFields.rootCommentId, "==", null)
        );
        const commentSnaps = await getDocs(commentsQuery);
                                
        const threads: Comment[] = [];
        commentSnaps.forEach((doc) => {
          if (doc.exists()) {
            const c = doc.data() as CommentResponse;
            const thread = {
              ...c,
              createdAt: firebaseTimeToDate(c.createdAt),
              updatedAt: c.updatedAt ? firebaseTimeToDate(c.updatedAt) : null,
              lastReplayedAt: c.lastReplayedAt ? firebaseTimeToDate(c.lastReplayedAt) : null,
              uploadedFiles: c.uploadedFiles.map((file) => {
                return {
                  ...file,
                  hasUploaded: true,
                } as UploadedFile;
              })
              } as Comment;
            threads.push(thread);
          }
        });
        resolve(threads);
      } catch (err) {
        const error = err as { message?: string };
        reject("[Error] getThreadsByProjectId: " + error.message);
      }
    });
  }

  public async getHoveredTaskResources(taskId: string): Promise<Pick<ElementInfoValue, "assignedUsernames" | "resources"> | null> {
    const cachedInfo = HoverElementInfoCache.get(taskId);
    // if cache exists and if cache is not stale (< 10 minutes)
    if (cachedInfo && dayjs().diff(dayjs(cachedInfo.lastRetrievedAt), "minutes") < 10) {
      return { ...cachedInfo! }
    }

    try {
      if (this.isHoverTaskInfoRequesting) {
        throw new Error("Already requesting");
      }

      this.isHoverTaskInfoRequesting = true;
      const taskCollectionRef = this.getTaskCollectionRef();
      const resourceRef = this.getResourceRef();
      const organizationUserRef = this.getOrganizationUserRef()
      if (!taskCollectionRef || !resourceRef || !organizationUserRef) {
        throw new Error("Failed to get collection ref.");
      }
      const taskDocumentRef = doc(taskCollectionRef, taskId);
      const docSnap = await getDoc(taskDocumentRef);
      if (!docSnap.exists()) {
        throw new Error("Task does not exist.");
      }

      const task = docSnap.data() as TaskResponse;
      
      const resources: string[] = [];
      const assignedUsernames: string[] = [];

      if (task.assignResourceIds && task.assignResourceIds.length) {
        const resourceQuery = query(resourceRef,
          where(ResourceDocumentFields.id, "in", task.assignResourceIds),
        );
        const resourceSnaps = await getDocs(resourceQuery);
        if (!resourceSnaps.empty) {
          resourceSnaps.forEach((document) => {
            if (document.exists()) {
              const resource = document.data() as ResourceResponse;
              resources.push(resource.name);
            }
          });
        }
      }

      if (task.assignUserIds && task.assignUserIds.length) {
        const assignedUserQuery = query(organizationUserRef,
          where(OrganizationUserDocumentFields.id, "in", task.assignUserIds),
        );
        const assignedUserSnaps = await getDocs(assignedUserQuery);
        if (!assignedUserSnaps.empty) {
          assignedUserSnaps.forEach((document) => {
            if (document.exists()) {
              const user = document.data() as OrganizationUserResponse;
              assignedUsernames.push(user.username);
            }
          });
        }
      }

      HoverElementInfoCache.set(taskId, {
        resources,
        assignedUsernames,
        lastRetrievedAt: new Date()
      });

      return { resources, assignedUsernames };
    } catch (err) {
      const error = err as { message?: string };
      console.log("[Error] getThreadsByProjectId: " + error.message);
      return null;
    } finally {
      this.isHoverTaskInfoRequesting = false;
    }
  }

  /**
   * レイヤー取得関数
   * @returns 
   */
  @action
  public async getLayers(): Promise<InternalError> {
    const layerRef = this.getLayerCollectionRef();
    try {
      if (!layerRef) {
        throw new Error("[Error] Failed to get layerRef");
      };

      const layersQuery = query(layerRef,
        orderBy(LayerDocumentFields.index, "asc")
      );

      const layerSnaps = await getDocs(layersQuery);

      const layers: LayerModel[] = [];
      layerSnaps.forEach((layer) => {
        const layerModel = new LayerModel(layer.data() as LayerResponse);
        layerModel.setOrganizationId(this.organizationId);
        layerModel.setProjectId(this.id);
        layers.push(layerModel);
      });

      runInAction(() => {
        this.layers = layers;
      });

      return {}
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return { error: error.message };
    }
  }

  @action
  public clearLayers() {
    runInAction(() => {
      this.layers = [];
    })
  }

  @action
  public async insertLayer(data: LayerResponse): Promise<{ id?: string, error?: string }> {
    try {
      const layerRef = this.getLayerCollectionRef();
      if (!layerRef) {
        throw new Error("[Error] Failed to get layerRef");
      };

      const layerDoc = doc(layerRef);
      data.id = layerDoc.id;
      await setDoc(layerDoc, data);

      const layerModel = new LayerModel(data);
      layerModel.setOrganizationId(this.organizationId);
      layerModel.setProjectId(this.id);

      runInAction(() => {
        this.layers = [
          ...this.layers,
          layerModel,
        ];
      });
      return { id: data.id }
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return { error: error.message };
    }
  }

  @action
  public async insertLayers(layers: Layer[]): Promise<void> {
    try {
      const layerRef = this.getLayerCollectionRef();
      if (!layerRef) {
        throw new Error("[Error] Failed to get layerRef");
      };

      const batch = writeBatch(db);
      layers.forEach((layer) => {
        const layerDoc = doc(layerRef);
        layer.id = layerDoc.id;
        batch.set(layerDoc, layer);
      });
      await batch.commit();
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
    }
  }

  @action
  public async updateLayers(): Promise<void> {
    try {
      const layerRef = this.getLayerCollectionRef();
      if (!layerRef) {
        throw new Error("[Error] Failed to get layerRef");
      };

      const batch = writeBatch(db);
      this.layers.forEach((layer) => {
        batch.update(doc(layerRef, layer.id), { ...layer.getFields() });
      });
      await batch.commit();
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
    }
  }

  //private

  private getProjectDocumentRef(): DocumentRefType | null {
    if (!this.id || !this.organizationId) return null;

    return doc(db,
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.projects.this,
      this.id,
    );
  }

  private getTaskCollectionRef(): CollectionReference | null {
    if (!this.id || !this.organizationId) return null;

    return collection(db,
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.tasks.this,
    );
  }

  private getMilestoneCollectionRef(): CollectionReference | null {
    if (!this.id || !this.organizationId) return null;

    return collection(db,
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.milestones.this,
    );
  }

  private getCommentRef(): CollectionReference | null {
    if (!this.id || !this.organizationId) return null;

    return collection(db, 
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.comments.this
    );
  }

  private getResourceRef(): CollectionReference | null {
    if (!this.id || !this.organizationId) return null;

    return collection(db, 
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.resources
    );
  }

  private getOrganizationUserRef(): CollectionReference | null {
    if (!this.id || !this.organizationId) return null;

    return collection(db, 
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.users
    );
  }

  /**
   * /organizations/{organizationId}/projects/{projectId}/layers/
   * @returns layer collection ref
   */
  private getLayerCollectionRef(): CollectionReference<DocumentData> | null {
    const projectDocumentRef = this.getProjectDocumentRef();
    if (!projectDocumentRef) return null;

    return collection(projectDocumentRef, FirestoreCollections.organizations.projects.layers.this);
  }

  private createFirebaseSceneDocument(
    elements: readonly SyncableExcalidrawElement[],
    roomKey: string,
  ) {
    const sceneVersion = getSceneVersion(elements);
    return {
      sceneVersion,
      updatedAt: dateToFirebaseTime(),
    } as FirebaseStoredScene;
  }

  /**
   * 必要であればこの関数に暗号化をデコードする実装を追加する
   * @param elementsInStr
   * @returns
   */
  private decryptElements(elementsInStr: string): readonly ExcalidrawElement[] {
    return JSON.parse(elementsInStr);
  }

  private async deleteMilestoneCollection(collectionPath: string, batchSize: number = 500) {
    const collectionRef = collection(db, collectionPath);

    const snapshot = await getDocs(
      query(collectionRef, where(MilestoneDocumentFields.projectId, "==", this.id))
    );
    const size = snapshot.size;
    if (size === 0) {
      // When there are no documents left, we are done
      return;
    }

    // Delete documents in a batch
    for (const docs of _.chunk(snapshot.docs, batchSize)) {
      const batch = writeBatch(db);
      docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    }
  }

  private async deleteCollection(collectionPath: string, batchSize: number = 500) {
    const collectionRef = collection(db, collectionPath);

    const snapshot = await getDocs(collectionRef);
    const size = snapshot.size;
    if (size === 0) {
      // When there are no documents left, we are done
      return;
    }

    // Delete documents in a batch
    for (const docs of _.chunk(snapshot.docs, batchSize)) {
      const batch = writeBatch(db);
      docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    }
  }
};
