import React, { useMemo } from "react";
import { MentionsInput, Mention } from "react-mentions";

import Colors from "../constants/Colors";

export interface Props {
  disabled: boolean;
  value: string;
  items: { id: string; display: string }[];
  mentions: string[];
  placeholder: string;
  maxHeight?: string;
  noBorder?: boolean;
  onChange: (value: string) => void;
  onMentioned: (item: string) => void;
}

const CommentInput: React.FC<Props> = (props: Props) => {
  const { items, value, disabled, mentions, placeholder, maxHeight, noBorder, onChange, onMentioned } = props;

  const style = noBorder ? styleWithoutBorder : styles;
  style.control = maxHeight ? { ...style.control, maxHeight } : { ...style.control };

  const data = useMemo(() => {
    return items.filter((item) => !mentions.includes(item.id))
  }, [items, mentions]);

  return (
    <div style={{ width: '100%' }}>
      <MentionsInput 
        className="comment-input"
        disabled={disabled}
        value={value} 
        onChange={(e) => onChange(e.target.value)}
        placeholder={disabled ? "" : placeholder}
        a11ySuggestionsListLabel="Suggested mentions"
        style={disabled ? style.disabled : style}
        >
        <Mention
          displayTransform={(_, display) => `@${display}`}
          trigger="@"
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className="user">{highlightedDisplay}</div>
          )}
          data={data}
          onAdd={(item) => onMentioned(String(item))}
        />
        <Mention
          displayTransform={(_, display) => `@${display}`}
          trigger="＠"
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className="user">{highlightedDisplay}</div>
          )}
          data={data}
          onAdd={(item) => onMentioned(String(item))}
        />
      </MentionsInput>
    </div>
  )
};

const styles = {
  disabled: {
    width: '90%',
    control: {
      backgroundColor: 'transparent',
      fontSize: 14,
      fontWeight: 'normal',
      border: 'none',
      minHeight: 24,
    },
  
    '&multiLine': {
      control: {
        border: 'none'
      },
      highlighter: {
        padding: 1,
        border: 'none'
      },
      input: {
        padding: 1,
        border: 'none',
        whiteSpace: 'pre-wrap',
      },
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: 230,
  
      highlighter: {
        padding: 1,
        border: 'none'
      },
      input: {
        padding: 1,
        border: '2px inset',
        whiteSpace: 'pre-wrap',
      },
    },
  },

  control: {
    width: '100%',
    maxWidth: '350px',
    minHeight:'42px',
    maxHeight: '130px',
    border: '1px solid #C9C9C9',
    borderRadius: '4px',
    transition: 'box-shadow 0.3s ease',
    padding: '0 8px',
    color: 'var(--popup-text-color)',
    backgroundColor: 'white',
    whiteSpace: 'pre-wrap',
  },

  '&multiLine': {
    control: {
      fontSize: '14px',
    },
    highlighter: {
      border: '1px solid transparent',
    },
    input: {
      maxWidth: '350px',
      border: '1px solid silver',
      outline: 'none',
    },
  },

  '&singleLine': {
    control: {
      display: 'inline-block',
    },
    highlighter: {
      padding: '1px',
      border: '2px inset transparent',
    },
    input: {
      maxWidth: '350px',
      paddingLeft: '10px',
    },
  },

  suggestions: {
    list: {
      minWidth: '200px',
      zIndex: '20px',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      fontSize: '10px'
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      '&focused': {
        backgroundColor: Colors.primary,
        color: 'white',
      },
    },
  },
}

const styleWithoutBorder = {
  disabled: {
    width: '80%',
    control: {
      backgroundColor: 'transparent',
      fontSize: 14,
      fontWeight: 'normal',
      border: 'none',
      minHeight: 24,
    },
  
    '&multiLine': {
      control: {
        border: 'none'
      },
      highlighter: {
        padding: 1,
        border: 'none'
      },
      input: {
        padding: 1,
        border: 'none',
        whiteSpace: 'pre-wrap',
      },
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: 230,
  
      highlighter: {
        padding: 1,
        border: 'none'
      },
      input: {
        padding: 1,
        whiteSpace: 'pre-wrap',
      },
    },
  },

  control: {
    width: '100%',
    minHeight:'120px',
    maxHeight: '200px',
    color: 'var(--popup-text-color)',
    backgroundColor: 'white',
    whiteSpace: 'pre-wrap',
  },

  '&multiLine': {
    control: {
      fontSize: '14px',
      outline: 'none',
      border: 'none'
    },
    highlighter: {
      outline: 'none',
      border: 'none',
    },
    input: {
      outline: 'none',
      border: 'none',
      padding: '8px',
    },
  },

  '&singleLine': {
    control: {
      fontSize: '14px',
      display: 'inline-block',
      outline: 'none',
      border: 'none'
    },
    highlighter: {
      outline: 'none',
      border: 'none'
    },
    input: {
      outline: 'none',
      border: 'none',
      padding: '8px',
    },
  },

  suggestions: {
    list: {
      minWidth: '200px',
      zIndex: '20px',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      fontSize: '10px'
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      '&focused': {
        backgroundColor: Colors.primary,
        color: 'white',
      },
    },
  },
}

export default CommentInput;