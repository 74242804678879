import { GanttChartTask } from "src/conpath/interfaces/Task";
import { GanttChartMilestone } from "src/conpath/interfaces/Milestone";

class Roadmap {
  private tasks: GanttChartTask[];
  private discovered: Set<string>;

  constructor(tasks: GanttChartTask[]) {
    this.tasks = tasks;
    this.discovered = new Set();
  }

  DFS = (
    task: GanttChartTask,
    direction: "left" | "both",
    group: GanttChartTask[],
  ) => {
    this.discovered.add(task.id);

    const dependencies =
      direction === "left"
        ? task.prevDependencies
        : [
          ...task.nextDependencies || [],
          ...task.prevDependencies || [],
        ];

    // Marks this node as visited and goes on to the nodes
    // that are dependent on this node, the edge is node ----> u
    dependencies?.forEach((e) => {
      if (!this.discovered.has(e)) {
        const index = this.tasks.findIndex((task) => task.id === e);
        if (index !== -1) {
          const task = this.tasks[index];
          this.DFS(task, direction, group);
        }
      }
    });
    // All dependencies are resolved for this node, we can now add
    // This to the stack.
    group.push(task);
  };

  getDependentGroups = (): GanttChartTask[][] => {
    const groups: GanttChartTask[][] = [];
    this.discovered = new Set();

    this.tasks.forEach((node) => {
      if (!this.discovered.has(node.id)) {
        const group: GanttChartTask[] = [];
        this.DFS(node, "both", group);
        groups.push(group);
      }
    });

    return groups;
  };

  getDependentMilestoneGroup = (milestone: GanttChartMilestone): GanttChartTask[] => {
    this.discovered = new Set();
    const group: GanttChartTask[] = [];

    milestone.prevDependencies?.forEach((e) => {
      const node = this.tasks.find((task) => task.id === e);
      if (node) {
        if (!this.discovered.has(node.id)) {
          this.DFS(node, "left", group);
        }
      }
    })

    return group;
  };
}

export default Roadmap;
