import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import "./SelectMultipleTaskLayer.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import ProjectModel from "src/conpath/models/ProjectModel";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck, FaMinus } from "react-icons/fa";
import { t } from "src/excalidraw/i18n";

interface SelectingLayer {
  id: string;
  index: number;
  name: string;
}
const hasLayerCheck = ({
  list,
  targetLayer,
}: {
  list: SelectingLayer[];
  targetLayer: SelectingLayer;
}): boolean => {
  return list.some((layer) => layer.id === targetLayer.id);
};

const SelectMultipleTaskLayer = observer(
  (props: {
    tasks: TaskModel[];
    organization: OrganizationModel | null;
    project: ProjectModel | null;
    onSaveLayers: () => void;
  }) => {
    const { tasks, organization, project, onSaveLayers } = props;

    const [assignedLayers, setAssignedLayers] = useState<SelectingLayer[]>([]);
    const [indeterminateLayers, setIndeterminateLayers] = useState<SelectingLayer[]>([]);
    const [projectLayers, setProjectLayers] = useState<SelectingLayer[]>(
      [],
    );

    // 全レイヤー
    useEffect(() => {
      const layers = project?.layers.filter((layer) => !layer.isDeleted)
        .map((layer) => {
          return {
            id: layer.id,
            index: layer.index,
            name: layer.name,
          } as SelectingLayer;
        }) || [];

      setProjectLayers(layers);
    }, [project]);

    useEffect(() => {
      const isAssigned = (layer: SelectingLayer): boolean => {
        return tasks.every((task) => task.layer === layer.index);
      };
      setAssignedLayers(projectLayers.filter((layer) => isAssigned(layer)));

      const isIndeterminate = (layer: SelectingLayer): boolean => {
        return tasks.some((task) => task.layer === layer.index);
      };
      setIndeterminateLayers(projectLayers.filter((layer) => isIndeterminate(layer)));
    }, [projectLayers, tasks]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionLayerList
            projectLayers={projectLayers}
            assignedLayers={assignedLayers}
            indeterminateLayers={indeterminateLayers}
            tasks={tasks}
            onSaveLayers={onSaveLayers}
          />
        </div>
      </>
    );
  },
);

const SelectionLayerList = (props: {
  tasks: TaskModel[];
  projectLayers: SelectingLayer[];
  assignedLayers: SelectingLayer[];
  indeterminateLayers: SelectingLayer[];
  onSaveLayers: () => void;
}) => {
  const { projectLayers, assignedLayers, indeterminateLayers, tasks, onSaveLayers } = props;
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);

  // セレクトスクロール量 設定 
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  useEffect(() => {
    const tableWrapper = document.querySelector('.table-wrapper') as HTMLElement;

    if (tableWrapper) {
      setScrollHeight(tableWrapper.offsetHeight - 30);
    }
  }, []);

  const List = React.useMemo(() => {
    const onSave = (targetLayer: SelectingLayer) => {
      tasks.forEach((task) => {
        task.setLayer(targetLayer.index);
      });
    }

    const onSelectLayerButtonPressed = () => {
      setIsOpenAssignList(!isOpenAssignList);
    };

    if (tasks.length === 0) {
      return (
        <label className="task-content__label !mb-0">
          {t("labels.editTask.layer")}
        </label>
      )
    }

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSelectLayerButtonPressed();
            onSaveLayers();
          }
          return (
            <>
              <Menu.Button
                className="flex items-center group/assign-button cursor-pointer"
                as="div"
                onClick={() => {
                  onSelectLayerButtonPressed();
                }}
              >
                <label className="task-content__label !mb-0 cursor-pointer group-hover/assign-button:text-primary-color">
                  {t("labels.editTask.layer")}
                </label>
                <GoGear
                  className="ml-2 cursor-pointer text-black group-hover/assign-button:text-primary-color"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items style={{ maxHeight: scrollHeight, overflow: "scroll" }} className="z-[1] absolute right-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {projectLayers.length > 0 && (
                    <>
                      <div className="p-2 text-xs">
                        {t("labels.editTask.layer")}
                      </div>
                      {projectLayers.map((layer) => {
                        const isAssigned = hasLayerCheck({
                          list: assignedLayers,
                          targetLayer: layer,
                        });
                        const isIndeterminate = hasLayerCheck({
                          list: indeterminateLayers,
                          targetLayer: layer,
                        });
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-layer flex items-center hover:bg-primary-color transition ease-out duration-100"
                            onClick={() => onSave(layer)}
                          >
                            {isAssigned
                              ? <FaCheck
                                className={"text-black group-hover/select-layer:text-white mr-2"}
                              />
                              : <FaMinus
                                className={`${isIndeterminate
                                  ? "text-black group-hover/select-layer:text-white"
                                  : "text-transparent"
                                  } mr-2`}
                              />
                            }
                            <span className="group-hover/select-layer:text-white">
                              {layer.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    projectLayers,
    assignedLayers,
    indeterminateLayers,
  ]);

  return List;
};

export default SelectMultipleTaskLayer;
