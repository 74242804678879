import { LoginUserProfileForm, LogingUserEmailForm, LogingUserPasswordForm } from "src/conpath/interfaces/LoginUser";
import { isEmailValid } from "./validations";
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import _ from "lodash";
import dayjs from "dayjs";

export default class LoginUserValidation {

  extraDuration: number = 1 * 24 * 60 * 60;

  private presenceCheckFailed(form: LoginUserProfileForm): boolean {
    return (_.isEmpty(form.username.trim()))
  }

  private phoneNumberNoneProblem(phoneNumber: string) {
    const parsedPhoneNumber = parsePhoneNumber('+81' + phoneNumber, "JP");
    if (parsedPhoneNumber) {
      return parsedPhoneNumber.isValid();
    }
    return false;
  }

  private formatTel(phoneNumber: string) {
    return new AsYouType('JP').input(phoneNumber);
  }

  private phoneNumberCheckFailed(form: LoginUserProfileForm): boolean {

    const phoneNumber = form.phoneNumber;
    if (!this.phoneNumberNoneProblem(phoneNumber)) return true;
    
    const formattedPhoneNumber = this.formatTel(phoneNumber);
    const isHyphen = RegExp('-');
    if (!isHyphen.test(formattedPhoneNumber)) return true;

    return false;
  }

  public validateLoginUserProfileInput(form: LoginUserProfileForm): string|null {
    
    if (this.presenceCheckFailed(form)) return "未記入の欄があります。プロフィールを記入してください。";
    // if (this.phoneNumberCheckFailed(form)) return "入力された電話番号は有効ではありません。";
    
    return null;
  }

  public validateLoginUserEmailInput(currentEmail: string, form: LogingUserEmailForm): string | null {
    const newEmail = form.newEmail.trim();
    const confirmEmail = form.confirmEmail.trim();

    if (_.isEmpty(newEmail) || _.isEmpty(confirmEmail))
      return "未記入の欄があります。メールアドレスを入力してください。";

    if (!isEmailValid(newEmail) || !isEmailValid(confirmEmail))
      return "入力されたメールアドレスは有効ではありません。";

    if (newEmail !== confirmEmail)
      return "メールアドレスが一致しません。";

    if (currentEmail === newEmail)
      return "現在のメールアドレスと同じです。"

    return null;
  }

  public validateLoginUserPasswordInput(form: LogingUserPasswordForm): string | null {
    const currentPassword = form.currentPassword.trim();
    const newPassword = form.newPassword.trim();
    const confirmPassword = form.confirmPassword.trim();

    if (_.isEmpty(currentPassword) ||
      _.isEmpty(newPassword) ||
      _.isEmpty(confirmPassword))
      return "未記入の欄があります。パスワードを入力してください。";

    if (newPassword.length < 6)
      return "パスワードは6桁以上にしてください。";

    if (newPassword !== confirmPassword)
      return "パスワードが一致しません。";

    return null;
  }
}