import { register } from "./register";
import { getSelectedElements } from "src/excalidraw/scene";
import { getNonDeletedElements, isTextElement } from "../element";
import { deepCopyElement } from "../element/newElement";
import { randomId } from "src/excalidraw/random";
import { t } from "src/excalidraw/i18n";
import { isCommentElement, isJobElement, isLinkElement } from "src/excalidraw/extensions/element/typeChecks";

export const actionAddToLibrary = register({
  name: "addToLibrary",
  trackEvent: { category: "element" },
  // CHANGED:ADD 2023-2-8 #608
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements(appState);
    return !selectedElements.some((element) => isJobElement(element));
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });
    if (selectedElements.some((element) => element.type === "image")) {
      return {
        commitToHistory: false,
        appState: {
          ...appState,
          errorMessage: "Support for adding images to the library coming soon!",
        },
      };
    }

    // CHANGED:ADD 2023-2-20 #730
    const selectedElementIds = new Set(
      selectedElements.map((element) => element.id),
    );
    const filteredElementIds: Set<string> = new Set();
    selectedElements.forEach((element) => {
      if (
        (isLinkElement(element) &&
          (!element.startBinding ||
            !element.endBinding ||
            !selectedElementIds.has(element.startBinding.elementId) ||
            !selectedElementIds.has(element.endBinding.elementId))) ||
        isJobElement(element) ||
        isCommentElement(element) // CHANGED:ADD 2023-12-20 #1138
      ) {
        filteredElementIds.add(element.id);
      }
    });

    const addLibElements = selectedElements.filter(
      (element) =>
        !filteredElementIds.has(element.id) &&
        !(
          isTextElement(element) &&
          element.containerId &&
          filteredElementIds.has(element.containerId)
        ),
    );

    if (addLibElements.length === 0) {
      return {
        commitToHistory: false,
      };
    }

    return app.library
      .getLatestLibrary()
      .then((items) => {
        return app.library.setLibrary([
          {
            id: randomId(),
            status: "unpublished",
            elements: addLibElements.map(deepCopyElement),
            created: Date.now(),
          },
          ...items,
        ]);
      })
      .then(() => {
        return {
          commitToHistory: false,
          appState: {
            ...appState,
            toast: { message: t("toast.addedToLibrary") },
          },
        };
      })
      .catch((error) => {
        return {
          commitToHistory: false,
          appState: {
            ...appState,
            errorMessage: error.message,
          },
        };
      });
  },
  contextItemLabel: "labels.addToLibrary",
});
