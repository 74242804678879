import { action, observable, runInAction, makeObservable } from "mobx";
import { nanoid } from "nanoid";

//types and interface
import LoginUser, { LoginUserDocumentFields, LoginUserProfileForm, LogingUserEmailForm, LogingUserPasswordForm } from "src/conpath/interfaces/LoginUser";
import { WorkType } from "src/conpath/constants/WorkType";
import { OrganizationRole } from "../constants/Role";
import { AppState } from "src/excalidraw/types";

//firebase
import { auth, db, storage } from "src/configs/firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {
  DocumentData,
  DocumentReference,
  Query,
  collectionGroup,
  doc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import { getFirebaseStorageErrorText } from "src/conpath/constants/FirebaseErrors";

//models
import OrganizationModel from "./OrganizationModel";
import { OrganizationUserState } from "../constants/OrganizationUserState";
import InternalError from "../interfaces/InternalError";

//helpers
import LoginUserValidation from "../helpers/validations/LoginUserValidation";
import { FirebaseStorage } from "../constants/FirebaseStorage";

export default class LoginUserModel extends LoginUserValidation implements LoginUser {

  @observable
  id!: string;
  @observable
  username!: string;
  @observable
  profileImageUrl!: string;
  @observable
  email!: string;
  @observable
  phoneNumber!: string;
  @observable
  organizationRole?: OrganizationRole;
  @observable
  workType!: WorkType[];
  @observable
  selectedOrganizationId?: string;
  @observable
  appState!: Partial<AppState>;
  @observable
  projectSortSelected!: number;
  @observable
  isSuperUser?: boolean;

  @observable
  isNetworking: boolean = false;

  constructor(user: LoginUser) {
    super();
    makeObservable(this);

    this.setUserInfo(user);
  }

  @action
  public async updateUserProfile(form: LoginUserProfileForm): Promise<InternalError> {

    const validationError = this.validateLoginUserProfileInput(form);
    if (validationError) {
      return { error: validationError };
    }

    try {

      const userCollectionParams: Partial<LoginUser> = {
        username: form.username,
        phoneNumber: form.phoneNumber,
      };

      // プロフィール画像のアップデート
      if (form.profileImage?.file) {
        const result = await this.uploadUserProfileImage(form.profileImage.file);
        userCollectionParams["profileImageUrl"] = result.imageUrl;
      }

      const userSubCollectionParams = { ...userCollectionParams };
      delete (userSubCollectionParams["phoneNumber"]);

      const userCollectionGroup = this.getUserCollectionGroup();
      const querySnapshot = await getDocs(
        query(userCollectionGroup, where(LoginUserDocumentFields.id, "==", this.id))
      );

      await runTransaction(db, async (transaction): Promise<void> => {

        querySnapshot.docs.forEach((snapshot) => {
          if (snapshot.exists()) {
            const docId = snapshot.data().docId;
            if (docId) {
              // organization / id / users subscollection
              transaction.update(snapshot.ref, userSubCollectionParams);
            } else {
              // user collection
              transaction.update(snapshot.ref, userCollectionParams);
            }
          }
        });
      })
        .catch((error) => {
          console.log("[Error] Failed in user profile transaction update. ", error.message);
          //sentry here.

          return { error: "プロフィールの更新に失敗しました。" }
        });

      return { error: null };
    } catch (err) {
      const error = err as { message: string };
      console.log("[Error] Failed to save user profile. ", error.message);
      // Sentry here.
      return { error: error.message };
    }
  }

  @action
  public async updateEmail(form: LogingUserEmailForm): Promise<InternalError> {

    const validationError = this.validateLoginUserEmailInput(this.email, form);
    if (validationError) {
      return { error: validationError };
    }

    try {
      const userCollectionGroup = this.getUserCollectionGroup();      
      const userCollectionParams: Partial<LoginUser> = {
        email: form.newEmail,
      };
      const userSubCollectionParams = { ...userCollectionParams };

      await updateEmail(auth.currentUser!, form.newEmail)
        .then(() => getDocs(query(userCollectionGroup, where(LoginUserDocumentFields.id, "==", this.id))))
        .then((querySnapshot) =>
          runTransaction(db, async (transaction): Promise<void> => {
            querySnapshot.docs.forEach((snapshot) => {
              if (snapshot.exists()) {
                const docId = snapshot.data().docId;
                if (docId) {
                  // organization / id / users subscollection
                  transaction.update(snapshot.ref, userSubCollectionParams);
                } else {
                  // user collection
                  transaction.update(snapshot.ref, userCollectionParams);
                }
              }
            });
          })
        )
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              throw new Error("そのメールアドレスは既に登録されています。");
            default:
              throw new Error("メールアドレスの更新に失敗しました。");
          }
        });

      return { error: null };
    } catch (err) {
      const error = err as { message: string };
      console.log("[Error] Failed to save email. ", error.message);
      // Sentry here.
      return { error: error.message };
    }
  }

  @action
  public async updatePassword(form: LogingUserPasswordForm): Promise<InternalError> {

    const validationError = this.validateLoginUserPasswordInput(form);
    if (validationError) {
      return { error: validationError };
    }

    try {
      const credential = EmailAuthProvider.credential(
        this.email,
        form.currentPassword,
      );

      await reauthenticateWithCredential(auth.currentUser!, credential)
        .then(() => {
          updatePassword(auth.currentUser!, form.newPassword)
            .then(() => {
            })
            .catch(() => {
              throw new Error("パスワードの更新に失敗しました。");
            });
        })
        .catch(() => {
          throw new Error("現在のパスワードが正しくありません。");
        });

      return { error: null };
    } catch (err) {
      const error = err as { message: string };
      console.log("[Error] Failed to save password. ", error.message);
      // Sentry here.
      return { error: error.message };
    }
  }

  /**
   * ログインユーザーに組織の役割(role)をセット
   * @param organization 
   * @returns 
   */
  @action
  public setOrganizationRole(organization: OrganizationModel) {

    const user = organization.findUserById(this.id);

    const newRole = !user && this.isSuperUser
      ? OrganizationRole.owner
      : user?.state === OrganizationUserState.joined
        ? user.role
        : this.organizationRole;

    runInAction(() => {
      this.organizationRole = newRole;
    });
  }

  public isOrganizationOwner(): boolean {
    return this.organizationRole === OrganizationRole.owner;
  }

  public isOrganizationMember(): boolean {
    return this.organizationRole === OrganizationRole.member;
  }

  public isOrganizationGuest(): boolean {
    return this.organizationRole === OrganizationRole.guest;
  }

  public setSelectedOrganizationId(organizationId: string) {
    runInAction(() => {
      this.selectedOrganizationId = organizationId;
    })
  }

  @action
  public setAppState(appState: Partial<AppState>) {
    runInAction(() => {
      this.appState = appState;
    })
  }

  @action
  public setProjectSortSelected(projectSortSelected: number) {
    runInAction(() => {
      this.projectSortSelected = projectSortSelected;
    })
  }

  public getFields(): LoginUser {
    return {
      id: this.id,
      username: this.username,
      profileImageUrl: this.profileImageUrl,
      email: this.email,
      phoneNumber: this.phoneNumber,
      organizationRole: this.organizationRole,
      workType: this.workType,
      selectedOrganizationId: this.selectedOrganizationId,
      appState: this.appState,
      projectSortSelected: this.projectSortSelected,
    };
  }

  @action
  public setUserInfo(user: LoginUser) {
    this.id = user.id;
    this.username = user.username;
    this.profileImageUrl = user.profileImageUrl;
    this.email = user.email;
    this.phoneNumber = user.phoneNumber;
    this.organizationRole = user.organizationRole;
    this.workType = user.workType;
    this.selectedOrganizationId = user.selectedOrganizationId;
    this.appState = user.appState || {};
    this.projectSortSelected = user.projectSortSelected || 0;

    this.isSuperUser = user.isSuperUser;
  }


  // private


  private async uploadUserProfileImage(imageFile: File): Promise<{ imageUrl: string }> {
    return new Promise((resolve) => {
      const prefix = `${FirebaseStorage.users}/${this.id}`;
      const fileRef = ref(storage, `${prefix}/${nanoid()}`)
      const uploadTask = uploadBytesResumable(fileRef, imageFile, {
        contentType: "image/jpeg"
      });

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        const errorMessage = getFirebaseStorageErrorText(error.code);
        console.log(`[Error] failed to upload user profile image ${error.code}: ${errorMessage}`);
        throw new Error(errorMessage);
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          console.log("[Log] File available at: ", downloadUrl);
          resolve({
            imageUrl: downloadUrl
          });
        })
      })

    })
  }

  private getLoginUserDocumentRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.users.this, this.id);
  }

  private getUserCollectionGroup(): Query<DocumentData> {
    return collectionGroup(db, FirestoreCollections.users.this);
  }
};