
import { register } from "src/excalidraw/actions/register";
import { isTextElement } from "src/excalidraw/element";
import { isCommentElement, isJobElement, isLinkElement } from "src/excalidraw/extensions/element/typeChecks";
import AddLibrary from "../components/AddLibrary";
import { isImageElement } from "src/excalidraw/element/typeChecks";

export const actionToggleAddLibrary = register({
  name: "toggleAddLibrary",
  contextItemLabel: "labels.addToLibrary",
  trackEvent: { category: "menu" },
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements(appState);
    return !selectedElements.some((element) => isJobElement(element));
  },
  perform: (_elements, appState, _, { focusContainer }) => {
    if (appState.openDialog === "addLibrary") {
      focusContainer();
    }
    return {
      appState: {
        ...appState,
        openDialog: appState.openDialog === "addLibrary" ? null : "addLibrary",
      },
      commitToHistory: false,
    };
  },
});

export const actionAddToLibraryEx = register({
  name: "addLibrary",
  trackEvent: { category: "element" },
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements(appState);
    return !selectedElements.some((element) => isJobElement(element));
  },
  perform: (elements, appState) => {
    return {
      commitToHistory: false,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });

    const selectedElementIds = new Set(
      selectedElements.map((element) => element.id),
    );
    const filteredElementIds: Set<string> = new Set();
    selectedElements.forEach((element) => {
      if (
        (isLinkElement(element) &&
          (!element.startBinding ||
            !element.endBinding ||
            !selectedElementIds.has(element.startBinding.elementId) ||
            !selectedElementIds.has(element.endBinding.elementId))) ||
        isJobElement(element)
      ) {
        filteredElementIds.add(element.id);
      }
    });

    const addLibElements = selectedElements
      .filter((el) => !isImageElement(el) && !isCommentElement(el))
      .filter(
        (element) =>
          !filteredElementIds.has(element.id) &&
          !(
            isTextElement(element) &&
            element.containerId &&
            filteredElementIds.has(element.containerId)
          ),
      );

    if (addLibElements.length === 0) {
      return null;
    }
    return (
      <AddLibrary
        elements={addLibElements}
        appState={appState}
        app={app}
      />
    );
  },
});
