import { t } from "../../i18n";
import "./LayerSettingsDialog.scss";
import { AppState } from "src/excalidraw/types";
import { Dialog } from "src/excalidraw/components/Dialog";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useStore } from "src/conpath/hooks/useStore";
import ProjectModel from "@/src/conpath/models/ProjectModel";
import { LayerResponse } from "@/src/conpath/interfaces/Layer";
import { dateToFirebaseTime } from "src/utils/timeUtils";
import { observer } from "mobx-react-lite";
import { ShapeCache } from "../../scene/ShapeCache";
import { NonDeletedExcalidrawElement } from "../../element/types";
import { isNodeElement } from "../element/typeChecks";

// Icon
import {
  FaPen,
  FaEllipsisH,
  FaRegEye,
  FaRegEyeSlash,
  FaPlus,
  FaLayerGroup,
} from "react-icons/fa";

interface LayerItem {
  id: string;
  index: number;
  name: string;
  visible: boolean;
  isSelected: boolean;
}

const LayerSettingsDialog = ({
  elements,
  appState,
  setAppState,
}: {
  elements: readonly NonDeletedExcalidrawElement[];
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [layerItems, setLayerItems] = useState<LayerItem[]>([]);
  const [project, setProject] = useState<ProjectModel | null>(null);

  const [showMenu, setShowMenu] = useState<{ [key: string]: boolean }>({});
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  const toggleMenu = (layerId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setShowMenu((prevState) => ({
      ...prevState,
      [layerId]: !prevState[layerId],
    }));
  };

  const toggleEditing = (layerId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setIsEditing((prevState) => ({
      ...prevState,
      [layerId]: !prevState[layerId],
    }));
  };

  const handleKeyDown = (
    layerId: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      setIsEditing((prevState) => ({
        ...prevState,
        [layerId]: false, // Enterキーで編集モードを終了
      }));
    }
  };

  const handleLayerSelect = (layerItem: LayerItem) => {
    if (!layerItem.isSelected) {
      setSelectLayer(layerItem);
    }
  };

  // 外をクリックした際にメニューや編集モードを閉じる
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        if (
          Object.values(showMenu).some((isOpen) => isOpen) ||
          Object.values(isEditing).some((isOpen) => isOpen)
        ) {
          setShowMenu({});
          setIsEditing({});
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu, isEditing]);

  // 編集を開始した際に該当のテキストボックスにフォーカスを設定し、全てのテキストを選択
  useEffect(() => {
    Object.keys(isEditing).forEach((layerId) => {
      if (isEditing[layerId] && inputRefs.current[layerId]) {
        const inputElement = inputRefs.current[layerId];
        inputElement?.focus();
        inputElement?.select();
      }
    });
  }, [isEditing]);

  const handleClose = useCallback(() => {
    setAppState({ openDialog: null });
  }, [setAppState]);

  // プロジェクトとレイヤーの取得
  const fetchProjectAndLayers = useCallback(async () => {
    if (!selectedOrganization) return;

    const selectedProject = selectedOrganization.projects.find(
      (project) => project.id === appState.projectId,
    );
    if (!selectedProject) return;

    await selectedProject.getLayers();
    setProject(selectedProject);
  }, [selectedOrganization, appState.projectId]);

  // レイヤーアイテムの作成
  const createLayerItems = useCallback(async () => {
    if (!project) return;

    if (project.layers.length === 0) {
      const newName = `レイヤー 1`;
      const layer: LayerResponse = {
        id: "",
        index: 0,
        name: newName,
        selectedUsers: { [loginUser?.id || ""]: true },
        visibleUsers: { [loginUser?.id || ""]: true },
        isDeleted: false,
        createdBy: loginUser?.id || "",
        createdAt: dateToFirebaseTime(new Date()),
      };

      const result = await project.insertLayer(layer);

      if (result.id) {
        const newLayerItem: LayerItem = {
          id: result.id,
          index: 0,
          name: newName,
          visible: true,
          isSelected: true, // 初期レイヤーなので選択済みに設定
        };
        setLayerItems([newLayerItem]);
      }
    } else {
      const _layerItems = project.layers
        .filter((layer) => !layer.isDeleted)
        .map((layer) => ({
          id: layer.id,
          index: layer.index,
          name: layer.name,
          visible: !!layer.visibleUsers[loginUser?.id || ""],
          isSelected: !!layer.selectedUsers[loginUser?.id || ""],
        }));

      if (!_layerItems.some((item) => item.isSelected)) {
        _layerItems[0].isSelected = true;
      }

      _layerItems.forEach((item) => {
        if (appState.viewLayers.includes(item.index)){
          item.visible = true;  
        }
      });

      setLayerItems(_layerItems);
    }
  }, [project, loginUser?.id]);

  useEffect(() => {
    fetchProjectAndLayers();
  }, [fetchProjectAndLayers]);

  useEffect(() => {
    createLayerItems();
  }, [project?.layers, createLayerItems]);

  const addLayer = async () => {
    if (!project) return;

    const nextIndex = project.layers.length > 0
      ? Math.max(...project.layers.map((d) => d.index)) + 1
      : 0;
    const newName = `レイヤー ${project.layers.filter((d) => !d.isDeleted).length + 1}`;

    const layer: LayerResponse = {
      id: "",
      index: nextIndex,
      name: newName,
      selectedUsers: {},
      visibleUsers: { [loginUser?.id || ""]: true },
      isDeleted: false,
      createdBy: loginUser?.id || "",
      createdAt: dateToFirebaseTime(new Date()),
    };

    const result = await project.insertLayer(layer);

    if (result.id) {
      setLayerItems((items) => [
        ...items,
        {
          id: result.id!,
          index: nextIndex,
          name: newName,
          visible: true,
          isSelected: false,
        },
      ]);

      // 新しく追加されたレイヤーを編集モードにする
      setIsEditing((prevState) => ({
        ...prevState,
        [result.id!]: true,
      }));
    }

    setAppState({ viewLayers: [...appState.viewLayers, layer.index] });
  };

  const toggleLayerVisibility = async (layerItem: LayerItem) => {
    if (!loginUser) return;

    const updatedItems = layerItems.map((item) =>
      item.id === layerItem.id ? { ...item, visible: !layerItem.visible } : item
    );
    setLayerItems(updatedItems);

    const layerModel = project?.layers.find((layer) => layer.id === layerItem.id);
    layerModel?.setVisibleUsers(loginUser.id, !layerItem.visible);
    await layerModel?.updateLayer();

    setAppState({
      viewLayers: updatedItems.filter((item) => item.visible).map((item) => item.index),
    });

    elements.forEach((element) => {
      if (element.layer === layerItem.index) {
        ShapeCache.delete(element);
      }
    });
  };

  const deleteLayer = async (layerItem: LayerItem, event: React.MouseEvent) => {
    event.stopPropagation();
    if (layerItem.isSelected) {
      setAppState({ errorMessage: t("alerts.cannotDeleteSelectedLayer") });
      return;
    }

    if (elements.some((el) => !el.isDeleted && isNodeElement(el) && el.layer === layerItem.index)) {
      setAppState({ errorMessage: t("alerts.cannotDeleteLayer") });
      return;
    }

    setLayerItems((items) => items.filter((item) => item.id !== layerItem.id));

    const layerModel = project?.layers.find((layer) => layer.id === layerItem.id);
    layerModel?.setIsDeleted(true);
    await layerModel?.updateLayer();

    setAppState({
      viewLayers: appState.viewLayers.filter((layer) => layer !== layerItem.index),
    });
  };

  const renameLayer = async (layerItem: LayerItem, newName: string) => {
    setLayerItems((items) =>
      items.map((item) =>
        item.id === layerItem.id ? { ...item, name: newName } : item,
      ),
    );

    const layerModel = project?.layers.find((layer) => layer.id === layerItem.id);
    layerModel?.setName(newName);
    await layerModel?.updateLayer();
  };

  const setSelectLayer = async (layerItem: LayerItem) => {
    if (!loginUser) return;

    const updatedItems = layerItems.map((item) => ({
      ...item,
      isSelected: item.id === layerItem.id,
    }));
    setLayerItems(updatedItems);

    project?.layers.forEach((layer) => {
      layer.setSelectedUsers(loginUser.id, layer.id === layerItem.id);
    });
    await project?.updateLayers();

    setAppState({
      selectedLayer: updatedItems.find((layer) => layer.isSelected)?.index || 0,
    });
  };

  return (
    <Dialog
      onCloseRequest={handleClose}
      title="レイヤー選択"
      className="LayerSettingsDialog"
      small
    >
      <div ref={dialogRef} className="layer-settings-container">
        <ul className="layer-list">
          {layerItems.map((layerItem) => (
            <li
              key={layerItem.id}
              className={`layer-item ${layerItem.isSelected ? "selected" : ""}`}
              onClick={() => handleLayerSelect(layerItem)}
            >
              {layerItem.isSelected && (
                <FaLayerGroup className="layer-group-icon" />
              )}
              <button
                className="eye-button"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleLayerVisibility(layerItem);
                }}
              >
                {layerItem.visible ? <FaRegEye /> : <FaRegEyeSlash />}
              </button>

              {isEditing[layerItem.id] ? (
                <input
                  type="text"
                  ref={(el) => (inputRefs.current[layerItem.id] = el)}
                  value={layerItem.name}
                  className="layer-input"
                  onClick={(event) => event.stopPropagation()}
                  onChange={(e) => renameLayer(layerItem, e.target.value)}
                  onKeyDown={(event) => handleKeyDown(layerItem.id, event)}
                />
              ) : (
                <span className="layer-name">{layerItem.name}</span>
              )}

              <div className="layer-item-right">
                <button
                  className="pen-button"
                  onClick={(event) => toggleEditing(layerItem.id, event)}
                >
                  <FaPen />
                </button>

                <div className="menu-container">
                  <button
                    className="ellipsis-button"
                    onClick={(event) => toggleMenu(layerItem.id, event)}
                  >
                    <FaEllipsisH />
                  </button>
                  {showMenu[layerItem.id] && (
                    <div className="menu">
                      <button
                        onClick={(event) => deleteLayer(layerItem, event)}
                      >
                        削除
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="add-layer-container">
        <button onClick={addLayer} className="add-layer-button">
          <FaPlus />
        </button>
      </div>
    </Dialog>
  );
};

export default observer(LayerSettingsDialog);
