import { useEffect, useRef, useState } from "react";
import OpenColor from "open-color";

import { Dialog } from "src/excalidraw/components/Dialog";
import { t } from "src/excalidraw/i18n";

import { AppClassProperties, AppState } from "src/excalidraw/types";
import { exportToCanvas } from "src/excalidraw/packages/utils";
import DialogActionButton from "src/excalidraw/components/DialogActionButton";

import "./AddLibrary.scss";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import { randomId } from "src/excalidraw/random";
import { ExcalidrawElement, NonDeleted } from "src/excalidraw/element/types";
import { deepCopyElement } from "src/excalidraw/element/newElement";
import { isTaskElement } from "../element/typeChecks";
import { getTaskChildren } from "../library";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import { dateToFirebaseTime } from "src/utils/timeUtils";
import { LibraryResponse } from "src/conpath/interfaces/Library";
import Checkbox from "src/conpath/components/CheckBox";

interface AddLibraryDataParams {
  name: string;
  category: string;
  addAssignUsers: boolean;
  addAssignResources: boolean;
}

const RenderPreviewElements = ({
  elements,
  appState,
}: {
  elements: NonDeleted<ExcalidrawElement>[];
  appState: AppState;
}) => {
  const svgRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = svgRef.current;
    if (!node) {
      return;
    }
    (async () => {
      exportToCanvas({
        elements,
        appState: {
          ...appState,
          viewBackgroundColor: OpenColor.white,
          exportBackground: true,
        },
        exportPadding: 20,
        backgroundHTML: false,
        files: null,
      }).then((canvas) => {
        node.replaceChildren(canvas);
      });
    })();
  }, [elements, appState]);

  return (
    <div className="render-library-item">
      <div ref={svgRef} className="render-library-item__canvas" />
    </div>
  );
};

const AddLibrary = observer(({
  elements,
  appState,
  app,
}: {
  elements: NonDeleted<ExcalidrawElement>[];
  appState: AppState;
  app: AppClassProperties;
}) => {
  const setAppState = useExcalidrawSetAppState();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [libraryData, setLibraryData] = useState<AddLibraryDataParams>({
    name: "",
    category: "",
    addAssignUsers: false,
    addAssignResources: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInputChange = (event: any) => {
    setLibraryData({
      ...libraryData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const taskIds = elements
      .filter((el) => isTaskElement(el))
      .map((el) => el.id);

    const result = await getTaskChildren(
      taskIds,
      appState,
      libraryData.addAssignUsers,
      libraryData.addAssignResources
    );

    const library: LibraryResponse = {
      id: randomId(),
      name: libraryData.name || "",
      category: libraryData.category || "",
      contentText: JSON.stringify({
        elements: elements.map(deepCopyElement),
        taskChildren: result,
      }),
      bookmarkedUsers: {},
      createdBy: loginUser?.id || "",
      createdAt: dateToFirebaseTime(new Date()),
    };

    await selectedOrganization?.upsertLibrary(library)
      .then(() => {
        setAppState({
          openDialog: null,
          selectedElementIds: {},
          selectedGroupIds: {},
          toast: { message: t("toast.addedToLibrary") },
        });
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };

  const onClose = () => {
    setAppState({ openDialog: null });
  };

  return (
    <Dialog
      onCloseRequest={onClose}
      title={t("addLibrary.title")}
      className="add-library"
      small={true}
    >
      <form onSubmit={onSubmit}>
        <div className="add-library__fields">
          <div className="add-library__field">
            <label>
              <p>{t("addLibrary.libraryName")}</p>
              <span aria-hidden="true" className="required">
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              required
              value={libraryData.name}
              onChange={onInputChange}
              placeholder={t("addLibrary.placeholder.libraryName")}
            />
          </div>
          <div className="add-library__field">
            <label>
              <p>{t("addLibrary.category")}</p>
              <span aria-hidden="true" className="required">
                *
              </span>
            </label>
            <input
              type="text"
              name="category"
              required
              value={libraryData.category}
              onChange={onInputChange}
              placeholder={t("addLibrary.placeholder.category")}
            />
          </div>
          <div className="add-library__checkbox-wrapper">
            <p>・オプション</p>
            <div className="add-library__checkbox-container">
              <label>
                <Checkbox
                  props={{
                    checked: libraryData.addAssignUsers,
                    onClick: () =>
                      setLibraryData({
                        ...libraryData,
                        addAssignUsers: !libraryData.addAssignUsers,
                      }),
                    sx: {
                      color: "#DDDDDD",
                      backgroundImage: "linear-gradient(to right, white, white)",
                      borderRadius: 0,
                      height: 18,
                      width: 18,
                    },
                  }}
                />
                <p>{t("addLibrary.addAssignUsers")}</p>
              </label>
              <label>
                <Checkbox
                  props={{
                    checked: libraryData.addAssignResources,
                    onClick: () =>
                      setLibraryData({
                        ...libraryData,
                        addAssignResources: !libraryData.addAssignResources,
                      }),
                    sx: {
                      color: "#DDDDDD",
                      backgroundImage: "linear-gradient(to right, white, white)",
                      borderRadius: 0,
                      height: 18,
                      width: 18,
                    },
                  }}
                />
                <p>{t("addLibrary.addAssignResources")}</p>
              </label>
            </div>
          </div>
        </div>
        <RenderPreviewElements
          elements={elements}
          appState={appState}
        />
        <div className="add-library__buttons">
          <DialogActionButton
            label={t("buttons.cancel")}
            onClick={onClose}
            data-testid="cancel-clear-canvas-button"
          />
          <DialogActionButton
            type="submit"
            label={t("addLibrary.submit")}
            actionType="primary"
            isLoading={isSubmitting}
            className="font-bold"
          />
        </div>
      </form>
    </Dialog>
  );
});

export default AddLibrary;
