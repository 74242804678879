import MUICheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: { main: "#46AADF" },
  },
});
const Checkbox = ({
  props,
  theme = defaultTheme,
}: {
  props: CheckboxProps;
  theme?: Theme;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <MUICheckbox
        {...props}
      />
    </ThemeProvider>
  );
};
export default Checkbox;
