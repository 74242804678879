import { ExcalidrawElement, ExcalidrawTextElement } from "../../element/types";
import { AppState } from "src/excalidraw/types";
import {
  ExcalidrawJobElement,
  ExcalidrawTaskElement,
  ExcalidrawLinkElement,
  ExcalidrawBindableElementEx,
  ExcalidrawMilestoneElement,
  ExcalidrawGraphElement,
  ExcalidrawNodeElement,
  ExcalidrawCommentableElement,
  ExcalidrawCommentElement
} from "./types"; // from extensions

export const isJobRelatedElement = (
  element: ExcalidrawElement | null
): element is ExcalidrawJobElement | ExcalidrawTextElement => {
  return isJobElement(element) || isJobTextElement(element);
};

export const isJobElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawJobElement => {
  return element != null && element.type === "job";
};

export const isJobTextElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawTextElement => {
  return element != null && element.type === "job-text";
};

export const isOriginTextElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawTextElement => {
  return element != null && element.type === "text";
};

/**
 * コメントをつけられるエレメント
 * @param element 
 * @returns 
 */
export const isCommentableElement = (element: ExcalidrawElement | null): element is ExcalidrawCommentableElement => {
  return element != null && 
    isTaskElement(element);
}

// CHANGED:ADD 2023-12-23 #1138
export const isCommentElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawCommentElement => {
  return element != null && element.type === "comment";
};

export const isTaskElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawTaskElement => {
  return element != null && isTaskElementType(element.type);
};

// CHANGED:ADD 2022-12-7 #157
export const isMilestoneElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawMilestoneElement => {
  return element != null && isMilestoneElementType(element.type);
};

export const isLinkElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawLinkElement => {
  return element != null && isLinkElementType(element.type);
};

// CHANGED:ADD 2022-12-1 #195
export const isGraphElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawGraphElement => {
  return (
    element != null &&
    (element.type === "task" ||
      element.type === "milestone" || // CHANGED:ADD 2022-12-7 #157
      element.type === "link")
  );
};

// CHANGED:ADD 2022-12-1 #195
export const isNodeElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawNodeElement => {
  return (
    element != null &&
    (element.type === "task" ||
      element.type === "milestone") // CHANGED:ADD 2022-12-7 #157
  );
};

export const isBindingElementEx = (
  element?: ExcalidrawElement | null,
  includeLocked = true,
): element is ExcalidrawLinkElement => {
  return (
    element != null &&
    (!element.locked || includeLocked === true) &&
    isBindingElementTypeEx(element.type)
  );
};

export const isBindingElementTypeEx = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return elementType === "link";
};

export const isBindableElementEx = (
  element?: ExcalidrawElement | null,
  includeLocked = true,
): element is ExcalidrawBindableElementEx => {
  return (
    element != null &&
    (!element.locked || includeLocked === true) &&
    (element.type === "task" ||
      element.type === "milestone") // CHANGED:ADD 2022-12-7 #157
  );
};

// CHANGED:ADD 2023-1-28 #540
export const hasBoundTextElementEx = (
  element: ExcalidrawElement | null,
): element is ExcalidrawBindableElementEx => {
  return (
    isBindableElementEx(element) &&
    !!element.boundElements?.some(({ type }) => type === "text")
  );
};

export const isTaskElementType = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return elementType === "task";
};

// CHANGED:ADD 2022-12-7 #157
export const isMilestoneElementType = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return elementType === "milestone";
};

export const isLinkElementType = (
  elementType: AppState["activeTool"]["type"],
): boolean => {
  return elementType === "link";
};
