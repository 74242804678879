import { AppState } from "src/excalidraw/types";
import { DEFAULT_LINK_SIZE } from "../../renderer/renderElement";
import { rotate } from "../../math";
import { MIME_TYPES } from "src/excalidraw/constants";
import { Bounds } from "../../element/bounds";

export const EXTERNAL_LOCK_IMG = document.createElement("img");
EXTERNAL_LOCK_IMG.src = `data:${MIME_TYPES.svg}, ${encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
  <path d="M5 11m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
  <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
  <path d="M8 11v-4a4 4 0 0 1 8 0v4"></path>
</svg>`,
)}`;

export const getLockHandleFromCoords = (
  [x1, y1, x2, y2]: Bounds,
  angle: number,
  appState: Pick<AppState, "zoom">,
): [x: number, y: number, width: number, height: number] => {
  const size = DEFAULT_LINK_SIZE;
  const lockWidth = size / appState.zoom.value;
  const lockHeight = size / appState.zoom.value;
  const lockMarginY = size / appState.zoom.value;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;
  const centeringOffset = (size - 8) / (2 * appState.zoom.value);
  const dashedLineMargin = 4 / appState.zoom.value;

  // Same as `ne` resize handle
  const x = x1 + dashedLineMargin - centeringOffset;
  const y = y1 - dashedLineMargin - lockMarginY + centeringOffset;

  const [rotatedX, rotatedY] = rotate(
    x + lockWidth / 2,
    y + lockHeight / 2,
    centerX,
    centerY,
    angle,
  );
  return [
    rotatedX - lockWidth / 2,
    rotatedY - lockHeight / 2,
    lockWidth,
    lockHeight,
  ];
};
