import { useState } from "react";
import { t } from "src/excalidraw/i18n";
import "./DatePicker.scss";
import { Calendar, DateObject } from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker";
// import "react-multi-date-picker/styles/colors/teal.css";
import gregorian_ja from "../locale/gregorian_ja";
import ConfirmDialogEx from "./ConfirmDialogEx";
import { AppState } from "src/excalidraw/types";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";

const DatePicker = ({
  appState,
  updateData,
}: {
  appState: AppState;
  updateData: (formData?: string[]) => void;
}) => {
  const setAppState = useExcalidrawSetAppState();
  const [dates, setDates] = useState<Value>(appState.holidays);
  const currentDate = new Date();
  const onClose = () => {
    setAppState({ openDialog: null });
  };

  const isWeekend = (date: Date): boolean => {
    const dayOfWeek = date.getDay();
    // 0は日曜日、6は土曜日
    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  const setWeekends = () => {
    const startDate = new Date(appState.projectStartDate);
    const endDate = new Date(appState.projectEndDate);
    const currentDate = new Date(startDate);
    const _dates: string[] = [];

    while (currentDate <= endDate) {
      if (isWeekend(currentDate)) {
        _dates.push(currentDate.toISOString());
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDates(_dates);
  }

  return (
    <>
      <ConfirmDialogEx
        onConfirm={() => {
          updateData(
            Array.from(dates as DateObject[])
              .sort()
              .map((v) => v.toString()),
          );
          onClose();
        }}
        onCancel={onClose}
        title={t("datePickerDialog.title")}
        className={"DatePicker"}
      >
        <div className="DatePicker__header">
          <button
            className="DatePicker__btn"
            onClick={setWeekends}
          >
            {t("datePickerDialog.weekends")}
          </button>
        </div>
        <Calendar
          className=""
          locale={gregorian_ja}
          fullYear
          multiple
          shadow={false}
          currentDate={
            new DateObject({
              year: currentDate.getFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getDate(),
            })
          }
          minDate={new Date(appState.projectStartDate)}
          maxDate={new Date(appState.projectEndDate)}
          value={dates}
          onChange={setDates}
        />
      </ConfirmDialogEx>
    </>
  );
};

export default DatePicker;
