import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import _ from "lodash";
import copy from "copy-to-clipboard";
import { toast } from 'react-toastify';
import { observer } from "mobx-react-lite";
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
// models
import CommentModel from "src/conpath/models/CommentModel";
import LoginUserModel from "src/conpath/models/LoginUserModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import ProjectModel from "src/conpath/models/ProjectModel";
// components
import CommentInput from "../CommentInput";
import FilePreviews from "../FilePreviews";
import { RotatingLines } from "react-loader-spinner";
import { ClipIcon, FileSelectIcon, MenuIcon, PenIcon, TrashIcon, SendMessageIcon, CircularCheckedIcon, DashedCheckedIcon } from "src/excalidraw/components/icons";
import { Flip, ToastContainer } from 'react-toastify';
// statics
import Colors from "src/conpath/constants/Colors";
import "./CommentList.scss";
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import { OrganizationRole } from "src/conpath/constants/Role";
// utils
import { DATE_FORMAT, formatDate } from "src/utils/dateUtils";
import { Paths } from "src/conpath/constants/Routes";
import { t } from "src/excalidraw/i18n";
//interfaces
import { FileType } from "src/conpath/interfaces/Comment";


interface Props {
  isTaskClosed?: boolean;
  isCanvas?: boolean;
  stickyThread?: boolean;
  commentElementId?: string;
  commentableElementId: string;
  project: ProjectModel;
  loginUser: LoginUserModel;
  selectedOrganization: OrganizationModel;
  setErrorText: (errorText: string) => void;
  updateCallback?: (commentId: string) => void;
  deleteCallback?: () => void;
};
const CommentList: React.FC<Props> = ({
  isTaskClosed,
  isCanvas,
  stickyThread,
  commentElementId,
  commentableElementId,
  project,
  loginUser,
  selectedOrganization,
  setErrorText,
  updateCallback,
  deleteCallback
}: Props) => {

  const [newComment, setNewComment] = useState<CommentModel>(new CommentModel({
    id: "",
    text: "",
    projectId: project.id,
    rootCommentId: commentElementId || null,
    commentElementId: commentableElementId,
    mentioningTo: [],
    uploadedFiles: [],
    createdBy: "",
    createdAt: new Date(),
    updatedAt: null,
    isDeleted: false,
    isDeleteElementRequired: false,
    replayCount: 0,
    lastReplayedAt: null,
  }));
  const [updatingComment, setUpdatingComment] = useState<CommentModel|null>(null);
  const [menuOpenedCommentId, setMenuOpenedCommentId] = useState<string|null>(null);
  const [isDeleteReload, setIsDeleteReload] = useState<boolean>(false);

  const { inputRef, isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: {
      [FileType["image/jpeg"]]: ['.jpeg', '.png'],
      [FileType["application/pdf"]]: ['.pdf'],
    },
    onDrop: async (acceptedFiles: File[], rejectedFile: FileRejection[], event: DropEvent) => {
      if (!acceptedFiles) return;
      if (!newComment.organizationId) {
        newComment.setOrganizationId(project.organizationId);
      }
      const result = await newComment.uploadFiles(acceptedFiles);
      if (result.errors.length) {
        result.errors.forEach((error) => {
          setErrorText(error);
        });
        return;
      }
    },
    onError: (err: Error) => {
      console.log(err.message);
      // Sentry here
      setErrorText("ファイルのアップロードに失敗しました。");
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileRejection) => {
        setErrorText(`${fileRejection.file.name}のアップロードに失敗しました。`);
      });
    },
    noClick: true,
    noKeyboard: true,
    noDrag: !(isCanvas && stickyThread),
  });
  
  // const fileUploadRef = useRef<null|HTMLInputElement>(null);
  const listTailRef = React.createRef<HTMLLIElement>();

  let rootCommentId: string | null = commentElementId || null;

  const users = selectedOrganization.users || [];
  const commentsIncludingThread = project.getComments;

  const comments = useMemo(() => {
    if (commentsIncludingThread) {
      return stickyThread ? commentsIncludingThread.filter((comment) => comment.rootCommentId) : commentsIncludingThread;
    }
    return [];
  }, [commentsIncludingThread]);

  const thread = useMemo(() => {
    return commentsIncludingThread && stickyThread ? commentsIncludingThread.find((comment) => comment.rootCommentId === null) : null;
  }, [commentsIncludingThread]);

  useEffect(() => {

    return () => {
      // Storage clean up
      if (!newComment.id) {
        newComment.setAllFilesToDelete();
        newComment.deleteAllFilesFromStorage();
      }
    }
  }, []);

  useEffect(() => {
    listTailRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [comments]);

  // /**
  //  * 全てのコメントが消去された時にCommentElementをcanvasから消去する処理
  //  */
  useEffect(() => {
    if (isDeleteReload && commentsIncludingThread?.length === 0) {
      setIsDeleteReload(false);
      deleteCallback?.();
    }
  }, [commentsIncludingThread, isDeleteReload]);


  const onChangeComment = useCallback((value: string, type: "UPDATE" | "CREATE") => {
    if (type === "CREATE") {
      newComment.updateText(value);
    } else {
      updatingComment?.updateText(value);
    }
  }, [newComment, updatingComment]);

  const onSubmitButtonPressed = async () => {
    newComment.setAuthor(loginUser.id);
    newComment.setOrganizationId(project.organizationId);
    const result = await newComment.create({ projectName: project.name, organizationName: selectedOrganization.name });
    if (result.error) {
      setErrorText(result.error);
      return;
    }
    if (!rootCommentId) {
      rootCommentId = result.commentId!;
    }

    setNewComment(new CommentModel({
      id: "",
      text: "",
      projectId: project.id,
      rootCommentId: rootCommentId,
      commentElementId: commentableElementId,
      mentioningTo: [],
      uploadedFiles: [],
      createdBy: "",
      createdAt: new Date(),
      updatedAt: null,
      isDeleted: false,
      isDeleteElementRequired: false,
      replayCount: 0,
      lastReplayedAt: null,
    }));

    await project.getCommentsByElementId(commentableElementId, users);

    updateCallback?.(result.commentId!);
  };

  /**
   * すでにあるコメントを更新する処理
   * 親のコメントは既に登録済なので、
   * @returns 
   */
  const onUpdateButtonPressed = async () => {
    if (!updatingComment) return;

    const result = await updatingComment.update({projectName: project.name, organizationName: selectedOrganization.name });

    if (result.error) {
      setErrorText(result.error);
      return;
    }

    setUpdatingComment(null);
    await project.getCommentsByElementId(commentableElementId, users);
  }

  const onDeleteButtonPressed = async (comment: CommentModel) => {
    const result = await comment.delete();
    if (result.error) {
      setErrorText(result.error);
      return;
    }

    await project.getCommentsByElementId(commentableElementId, users);
    setMenuOpenedCommentId(null);
    setIsDeleteReload(true);
  }


  const copyLinkToClipboard = (comment: CommentModel) => {
    let url = window.location.origin;
    if (isCanvas) {
      url += window.location.pathname;
    } else {
      url += `${Paths.projects}/${project.id}`;
    }
    const isCopy = copy(url + `#comment=${comment.id}`);
    if (isCopy) {
      toast("コメントのリンクをコピーしました。");
    }
    setMenuOpenedCommentId(null);
  }

  const CommentCard: React.FC<{ comment: CommentModel, isThread?: boolean }> = observer(({comment, isThread}) => {
    const className = isThread ? "thread-body" : 
                      stickyThread ? "comment-body__sticky-thread" : "comment-body";
    return (
      <li className={className}>
        <FilePreviews
          isEditing={false}
          comment={comment}
          loginUser={loginUser}
        />
        <CommentInput 
          disabled={true}
          value={comment.text}
          items={[]}
          mentions={[]}
          placeholder={"コメントを入力/＠メンション"} 
          onChange={() => {}}
          onMentioned={() => {}} /> 
        <div className="extra-information">
          <div className="profile-wrapper">
            <div className="image-wrapper">
              {
                comment.profileImageUrl ? 
                (
                  <img  src={comment.profileImageUrl} className="profile-image" />
                ) :
                (
                  <div className="profile-image--no-image">
                    {comment.username.charAt(0) || ""}
                  </div>
                )
              }
            </div>
            <p className="name-label">
              {comment.username || ""}
            </p>
            <p className="time-label">
              {comment.createdAt ? formatDate(comment.createdAt, DATE_FORMAT.COMMENT) : ""}
            </p>
          </div>
          <div className="icon-wrapper">
            { isThread && (
              <div className={isTaskClosed ? "icon--closed" : "icon" }>
                { isTaskClosed ?
                  (CircularCheckedIcon) : (DashedCheckedIcon)
                }
              </div>
            )}
            { comment.createdBy === loginUser.id && (
              <button
                onClick={() => setMenuOpenedCommentId(comment.id)}
                >
                {MenuIcon}
              </button> 
            )}
          </div>
          { menuOpenedCommentId === comment.id && (
              <div className="popup-menu">
                {
                  comment.isDeleting ?
                  (
                    <div className="spinner-wrapper">
                      <RotatingLines
                        strokeColor={Colors.primary}
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="32"
                        visible={true}
                      />
                    </div>
                  ) :
                  (
                    <div className="popup-menu__section">
                      <div
                        className="item"
                        onClick={(event) => {
                          event.stopPropagation(); 
                          const newComment = new CommentModel(comment.getFields());
                          newComment.setOrganizationId(selectedOrganization.id);
                          setUpdatingComment(newComment);
                        }}
                      >
                        <div className="icon">{PenIcon}</div>
                        <p>編集</p>
                      </div>
                      <div
                        className="item"
                        onClick={(event) => {
                          event.stopPropagation();
                          copyLinkToClipboard(comment);
                        }}
                      >
                        <div className="icon">{ClipIcon}</div>
                        <p>リンクをコピー</p>
                      </div>
                      <div
                        className="item-delete"
                        onClick={(event) => {
                          event.stopPropagation(); 
                          onDeleteButtonPressed(comment);
                        }}
                        >
                        <div className="icon">{TrashIcon}</div>
                        <p>消去</p>
                      </div>
                    </div>
                  )
                }
              </div>
          )}
        </div>
      </li>
    )
  });

  const CommentCardEdit: React.FC<{updatingComment: CommentModel}> = observer(({updatingComment}) => {

    return (
      <li className="comment-container">
        <div className="input-container">
          <FilePreviews
            isEditing={true}
            comment={updatingComment}
            loginUser={loginUser}
          />
          <CommentInput 
            disabled={false}
            value={updatingComment.text}
            items={selectedOrganization
              .getJoinedUserExceptMe(loginUser)
              .filter((user) => project.roles[user.id])
              .map((user) => {
              return {
                id: user.id,
                display: user.username
              }
            }) || []}
            mentions={updatingComment.mentioningTo}
            placeholder={"コメントを入力/＠メンション"} 
            onChange={(text) => onChangeComment(text, "UPDATE")}
            onMentioned={(mention) => updatingComment.addMention(mention)} /> 
        </div>
        <div className="edit-comment-button-wrapper">
        {
          updatingComment.isUpdating ? 
          (
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="32"
              visible={true}
            />
          ) :
          (
            <>
              <button 
                onClick={() => {
                  setUpdatingComment(null);
                }}
                className="cancel-button">
                キャンセル
              </button>
              <button 
                disabled={updatingComment.text.trim().length === 0}
                onClick={onUpdateButtonPressed}
                className="update-button">
                更新する
              </button>
            </>
          )
          }
        </div>
    </li>
    )
  });

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={TOAST_DEFAULT_DURATION}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div 
        {...getRootProps()}
        onClick={() => {
          if (menuOpenedCommentId) {
            setMenuOpenedCommentId(null);
          }
        }}
        className="task-comment-list">
        { isDragActive ?
          <div className="task-comment-file-drop-wrapper">
            <div className="task-comment-file-drop-wrapper__placeholder">
              <p>{t("hints.dragAndDropToUploadFiles")}</p>
            </div>
          </div>
        :
          <>
            <ul className={stickyThread ? 
              "task-comment-list__body sticky-thread" : 
              "task-comment-list__body"}>
              { thread && (
                updatingComment && thread.id === updatingComment.id ?
                <CommentCardEdit updatingComment={updatingComment} /> // コメント編集欄
                :
                <CommentCard comment={thread} isThread={true} />
              )}
              { comments && comments.map((comment) => {
                  if (updatingComment && comment.id === updatingComment.id) {
                    return <CommentCardEdit updatingComment={updatingComment} /> // コメント編集欄
                  }

                  return ( // コメント表示欄
                    <CommentCard comment={comment} />
                  )
                })
              }
              <li key={"dummy-comment"} ref={listTailRef}></li>
            </ul>
            { !updatingComment && newComment && ( // 新規コメント入力欄
              <div className="task-comment-list__comment-input-section">
                <div className="input-container">
                  <FilePreviews
                    isEditing={true}
                    comment={newComment}
                    loginUser={loginUser}
                  />
                  <CommentInput 
                    maxHeight={"200px"}
                    noBorder={true}
                    disabled={false}
                    value={newComment.text}
                    items={selectedOrganization
                      .getJoinedUserExceptMe(loginUser)
                      .filter((user) =>
                        project.roles[user.id] ||
                        (project?.teams && Object.keys(project.teams).some((key) =>
                          selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
                        ))
                      ).map((user) => {
                        return {
                          id: user.id,
                          display: user.username
                        }
                      }) || []}
                    mentions={newComment.mentioningTo}
                    placeholder={"コメントを入力/＠メンション"} 
                    onChange={(value) => onChangeComment(value, "CREATE")}
                    onMentioned={(mention) => newComment.addMention(mention)}
                  />
                </div>
                <div className="button-container">
                  { newComment.isCreating ? 
                    (
                      <RotatingLines
                          strokeColor={Colors.primary}
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="32"
                          visible={true}
                        />
                    ) :
                    (
                      <>
                        <input multiple={true} type='file' hidden {...getInputProps()} />
                        <button 
                          className="button-container__icon"
                          onClick={() => inputRef.current?.click()}>
                          {FileSelectIcon}
                        </button>
                        <button 
                          disabled={ 
                            newComment.text.trim().length === 0 ||
                            (newComment.uploadedFiles.length > 0 &&
                            newComment.uploadedFiles.some((file, index, list) => !_.isEmpty(file.hasUploaded))
                          )}
                          className="button-container__submit-button"
                          onClick={onSubmitButtonPressed}>
                          <label>{SendMessageIcon}</label>
                          <p style={{ fontSize: "14px" }}>投稿</p>
                        </button>                 
                      </>
                    )
                  }
                </div>
              </div>
            )}
          </>
        }
    </div>
  </>
  );
};

export default observer(CommentList);