import { RoughSVG } from "roughjs/bin/svg";
import oc from "open-color";

import {
  InteractiveCanvasAppState,
  StaticCanvasAppState,
  BinaryFiles,
  Point,
  Zoom,
  AppState,
} from "../types";
import {
  ExcalidrawElement,
  NonDeletedExcalidrawElement,
  ExcalidrawLinearElement,
  NonDeleted,
  GroupId,
  ExcalidrawBindableElement,
  ElementsMap,
} from "../element/types";
// CHANGED:ADD 2022-10-28 #14
import {
  ExcalidrawTaskElement,
  ExcalidrawLinkElement, // CHANGED:ADD 2022-11-2 #64
  ExcalidrawBindableElementEx,
} from "../extensions/element/types"; // from extensions
import {
  getElementAbsoluteCoords,
  // OMIT_SIDES_FOR_MULTIPLE_ELEMENTS, // CHANGED:REMOVE 2022-11-9 #101
  // getTransformHandlesFromCoords, // CHANGED:REMOVE 2022-11-9 #101
  getTransformHandles,
  getCommonBounds,
} from "../element";

import { roundRect } from "./roundRect";
import {
  InteractiveCanvasRenderConfig,
  InteractiveSceneRenderConfig,
  RenderableElementsMap,
  SVGRenderConfig,
  StaticCanvasRenderConfig,
  StaticSceneRenderConfig,
} from "../scene/types";
import {
  getScrollBars,
  SCROLLBAR_COLOR,
  SCROLLBAR_WIDTH,
} from "../scene/scrollbars";

import {
  renderElement,
  renderElementToSvg,
  renderSelectionElement,
} from "./renderElement";
import { getClientColor } from "../clients";
import { LinearElementEditor } from "../element/linearElementEditor";
// CHANGED:ADD 2022-10-28 #14
import { TaskElementEditor } from "../extensions/element/taskElementEditor"; // from extensinos
// CHANGED:ADD 2022-11-2 #64
import { LinkElementEditor } from "../extensions/element/linkElementEditor"; // from extensinos
import {
  isSelectedViaGroup,
  getSelectedGroupIds,
  getElementsInGroup,
  selectGroupsFromGivenElements,
} from "../groups";
import { maxBindingGap } from "../element/collision";
// CHANGED:ADD 2022-10-28 #14
import { maxBindingGapEx } from "../extensions/element/collision"; // from extensinos
import { SuggestedBinding, SuggestedPointBinding } from "../element/binding";
// CHANGED:ADD 2022-10-28 #14
import {
  SuggestedBindingEx,
  SuggestedPointBindingEx,
} from "../extensions/element/binding"; // from extensinos
import {
  shouldShowBoundingBox,
  TransformHandles,
  TransformHandleType,
} from "../element/transformHandles";
import {
  arrayToMap,
  supportsEmoji,
  throttleRAF,
} from "../utils";
import { UserIdleState } from "../types";
import { POINTER_DIRECTION, THEME_FILTER } from "../constants";
import {
  EXTERNAL_LINK_IMG,
} from "../components/hyperlink/Hyperlink";
import { isLinearElement } from "../element/typeChecks";
import {
  isJobElement, // CHANGED:ADD 2022-11-18 #175
  isTaskElement, // CHANGED:ADD 2022-10-28 #14
  isLinkElement, // CHANGED:ADD 2022-11-2 #64
  isGraphElement,
} from "../extensions/element/typeChecks"; // from extensinos
import { EXTERNAL_LOCK_IMG, getLockHandleFromCoords } from "src/excalidraw/extensions/element/Lock";
import { rotate } from "../math";
import { getLinkHandleFromCoords } from "../components/hyperlink/helpers";

const hasEmojiSupport = supportsEmoji();
export const DEFAULT_SPACING = 2;

const strokeRectWithRotation = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  cx: number,
  cy: number,
  angle: number,
  fill: boolean = false,
) => {
  context.save();
  context.translate(cx, cy);
  context.rotate(angle);
  if (fill) {
    context.fillRect(x - cx, y - cy, width, height);
  }
  context.strokeRect(x - cx, y - cy, width, height);
  context.restore();
};

const strokeDiamondWithRotation = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  cx: number,
  cy: number,
  angle: number,
) => {
  context.save();
  context.translate(cx, cy);
  context.rotate(angle);
  context.beginPath();
  context.moveTo(0, height / 2);
  context.lineTo(width / 2, 0);
  context.lineTo(0, -height / 2);
  context.lineTo(-width / 2, 0);
  context.closePath();
  context.stroke();
  context.restore();
};

const strokeEllipseWithRotation = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  cx: number,
  cy: number,
  angle: number,
) => {
  context.beginPath();
  context.ellipse(cx, cy, width / 2, height / 2, angle, 0, Math.PI * 2);
  context.stroke();
};

const fillCircle = (
  context: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  radius: number,
  stroke = true,
) => {
  context.beginPath();
  context.arc(cx, cy, radius, 0, Math.PI * 2);
  context.fill();
  if (stroke) {
    context.stroke();
  }
};

// CHANGED:ADD 2022-11-21 #125
const fillMove = (
  context: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  radius: number,
  stroke = true,
  pointerDirection: ExcalidrawLinkElement["pointerDirection"],
) => {
  // CHANGED:UPDATE 2022-12-12 #188

  const strokeStyle = "#4F55A3";
  const angleFillStyle = "#A599F0";
  const rectFillStyle = "#FFFFFFEA";


  if(pointerDirection == POINTER_DIRECTION.HORIZONTAL) {
    // Drawing a triangle on the left
    context.beginPath();
    context.moveTo(cx - 9, cy);
    context.lineTo(cx - 4, cy + 4);
    context.lineTo(cx - 4, cy - 4);
    context.strokeStyle = strokeStyle;
    context.fillStyle = angleFillStyle;
    context.closePath();
    context.stroke();
    context.fill();
  
    // Drawing a triangle on the right
    context.beginPath();
    context.moveTo(cx + 9, cy);
    context.lineTo(cx + 4, cy - 4);
    context.lineTo(cx + 4, cy + 4);
    context.strokeStyle = strokeStyle;
    context.fillStyle = angleFillStyle;
    context.closePath();
    context.stroke();
    context.fill();
  
    // Drawing a triangle on the rectangle
    context.beginPath();
    context.strokeStyle = strokeStyle;
    context.rect(cx - 2, cy - 5, 4, 10);
    context.fillStyle = rectFillStyle;
    context.fill();
    if (stroke) {
      context.stroke();
    }
  } else if (pointerDirection == POINTER_DIRECTION.VERTICAL){
    // Drawing a triangle on the top
    context.beginPath();
    context.moveTo(cx, cy - 9);
    context.lineTo(cx + 4, cy - 4);
    context.lineTo(cx - 4, cy - 4);
    context.strokeStyle = strokeStyle;
    context.fillStyle = angleFillStyle;
    context.closePath();
    context.stroke();
    context.fill();
  
    // Drawing a triangle on the right
    context.beginPath();
    context.moveTo(cx, cy + 9);
    context.lineTo(cx - 4, cy + 4);
    context.lineTo(cx + 4, cy + 4);
    context.strokeStyle = strokeStyle;
    context.fillStyle = angleFillStyle;
    context.closePath();
    context.stroke();
    context.fill();
  
    // Drawing a triangle on the rectangle
    context.beginPath();
    context.strokeStyle = strokeStyle;
    context.rect(cx - 5, cy - 2, 10, 4);
    context.fillStyle = rectFillStyle;
    context.fill();
    if (stroke) {
      context.stroke();
    }
  }
};

const strokeGrid = (
  context: CanvasRenderingContext2D,
  gridSize: number,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
) => {
  context.save();
  context.strokeStyle = "rgba(0,0,0,0.1)";
  context.beginPath();
  for (let x = offsetX; x < offsetX + width + gridSize * 2; x += gridSize) {
    context.moveTo(x, offsetY - gridSize);
    context.lineTo(x, offsetY + height + gridSize * 2);
  }
  for (let y = offsetY; y < offsetY + height + gridSize * 2; y += gridSize) {
    context.moveTo(offsetX - gridSize, y);
    context.lineTo(offsetX + width + gridSize * 2, y);
  }
  context.stroke();
  context.restore();
};

const renderSingleLinearPoint = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  point: Point,
  radius: number,
  isSelected: boolean,
  isPhantomPoint = false,
) => {
  context.strokeStyle = "#5e5ad8";
  context.setLineDash([]);
  context.fillStyle = "rgba(255, 255, 255, 0.9)";
  if (isSelected) {
    context.fillStyle = "rgba(134, 131, 226, 0.9)";
  } else if (isPhantomPoint) {
    context.fillStyle = "rgba(177, 151, 252, 0.7)";
  }

  fillCircle(
    context,
    point[0],
    point[1],
    radius / appState.zoom.value,
    !isPhantomPoint,
  );
};

// CHANGED:ADD 2022-10-28 #14
const renderSingleTaskPoint = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  point: Point,
  radius: number,
  isSelected: boolean,
  isPhantomPoint = false,
) => {
  context.strokeStyle = "#5e5ad8";
  context.setLineDash([]);
  context.fillStyle = "rgba(255, 255, 255, 0.9)";
  if (isSelected) {
    context.fillStyle = "rgba(134, 131, 226, 0.9)";
  } else if (isPhantomPoint) {
    context.fillStyle = "rgba(177, 151, 252, 0.7)";
  }

  fillCircle(
    context,
    point[0],
    point[1],
    radius / appState.zoom.value,
    !isPhantomPoint,
  );
};

// CHANGED:ADD 2022-11-2 #64
const renderSingleLinkPoint = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  point: Point,
  radius: number,
  isSelected: boolean,
  isPhantomPoint = false,
) => {
  context.strokeStyle = "#5e5ad8";
  context.setLineDash([]);
  context.fillStyle = "rgba(255, 255, 255, 0.9)";
  if (isSelected) {
    context.fillStyle = "rgba(134, 131, 226, 0.9)";
  } else if (isPhantomPoint) {
    context.fillStyle = "rgba(177, 151, 252, 0.7)";
  }

  fillCircle(
    context,
    point[0],
    point[1],
    radius / appState.zoom.value,
    !isPhantomPoint,
  );
};

// CHANGED:ADD 2022-11-21 #125
const renderCornerLinkPoint = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  point: Point,
  radius: number,
  isSelected: boolean,
  pointerDirection: ExcalidrawLinkElement["pointerDirection"],
  isPhantomPoint = false,
) => {
  context.strokeStyle = "#5e5ad8";
  context.setLineDash([]);
  context.fillStyle = "rgba(255, 255, 255, 0.9)";
  if (isSelected) {
    context.fillStyle = "rgba(134, 131, 226, 0.9)";
  } else if (isPhantomPoint) {
    context.fillStyle = "rgba(177, 151, 252, 0.7)";
  }

  fillMove(
    context,
    point[0],
    point[1],
    radius / appState.zoom.value,
    !isPhantomPoint,
    pointerDirection,
  );
};

const renderLinearPointHandles = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  element: NonDeleted<ExcalidrawLinearElement>,
  elementsMap: RenderableElementsMap,
) => {
  if (!appState.selectedLinearElement) {
    return;
  }
  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  context.lineWidth = 1 / appState.zoom.value;
  const points = LinearElementEditor.getPointsGlobalCoordinates(element, elementsMap);

  const { POINT_HANDLE_SIZE } = LinearElementEditor;
  const radius = appState.editingLinearElement
    ? POINT_HANDLE_SIZE
    : POINT_HANDLE_SIZE / 2;
  points.forEach((point, idx) => {
    const isSelected =
      !!appState.editingLinearElement?.selectedPointsIndices?.includes(idx);

    renderSingleLinearPoint(context, appState, point, radius, isSelected);
  });

  //Rendering segment mid points
  const midPoints = LinearElementEditor.getEditorMidPoints(
    element,
    elementsMap,
    appState,
  ).filter((midPoint) => midPoint !== null) as Point[];

  midPoints.forEach((segmentMidPoint) => {
    if (
      appState?.selectedLinearElement?.segmentMidPointHoveredCoords &&
      LinearElementEditor.arePointsEqual(
        segmentMidPoint,
        appState.selectedLinearElement.segmentMidPointHoveredCoords,
      )
    ) {
      // The order of renderingSingleLinearPoint and highLight points is different
      // inside vs outside editor as hover states are different,
      // in editor when hovered the original point is not visible as hover state fully covers it whereas outside the
      // editor original point is visible and hover state is just an outer circle.
      if (appState.editingLinearElement) {
        renderSingleLinearPoint(
          context,
          appState,
          segmentMidPoint,
          radius,
          false,
        );
        highlightPoint(segmentMidPoint, context, appState);
      } else {
        highlightPoint(segmentMidPoint, context, appState);
        renderSingleLinearPoint(
          context,
          appState,
          segmentMidPoint,
          radius,
          false,
        );
      }
    } else if (appState.editingLinearElement || points.length === 2) {
      renderSingleLinearPoint(
        context,
        appState,
        segmentMidPoint,
        POINT_HANDLE_SIZE / 2,
        false,
        true,
      );
    }
  });

  context.restore();
};

// CHANGED:ADD 2022-10-28 #14
const renderTaskPointHandles = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  element: NonDeleted<ExcalidrawTaskElement>,
  elementsMap: RenderableElementsMap,
) => {
  if (!appState.selectedTaskElement) {
    return;
  }
  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  context.lineWidth = 1 / appState.zoom.value;
  const points = TaskElementEditor.getPointsGlobalCoordinates(element, elementsMap);

  const { POINT_HANDLE_SIZE } = TaskElementEditor;
  const radius = POINT_HANDLE_SIZE / 2;
  points.forEach((point, idx) => {
    const isSelected = false;

    renderSingleTaskPoint(
      context,
      appState,
      point,
      radius,
      isSelected,
    );
  });

  context.restore();
};

// CHANGED:ADD 2022-11-2 #64
const renderLinkPointHandles = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  element: NonDeleted<ExcalidrawLinkElement>,
  elementsMap: RenderableElementsMap,
) => {
  if (!appState.selectedLinkElement) {
    return;
  }
  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  context.lineWidth = 1 / appState.zoom.value;
  const points = LinkElementEditor.getPointsGlobalCoordinates(
    element,
    elementsMap,
  );

  const { POINT_HANDLE_SIZE } = LinkElementEditor;
  const radius = POINT_HANDLE_SIZE / 2;
  points.forEach((point, idx) => {
    const isSelected = false;

    // CHANGED:UPDATE 2022-11-21 #125
    // renderSingleLinkPoint(
    //   context,
    //   appState,
    //   renderConfig,
    //   point,
    //   radius,
    //   isSelected,
    // );
    if (idx === 0 || points.length - 1 === idx) {
      renderSingleLinkPoint(
        context,
        appState,
        point,
        radius,
        isSelected,
      );
    } else {
      renderCornerLinkPoint(
        context,
        appState,
        point,
        radius,
        isSelected,
        element.pointerDirection,
      );
    }
  });

  context.restore();
};

const highlightPoint = (
  point: Point,
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
) => {
  context.fillStyle = "rgba(105, 101, 219, 0.4)";

  fillCircle(
    context,
    point[0],
    point[1],
    LinearElementEditor.POINT_HANDLE_SIZE / appState.zoom.value,
    false,
  );
};
// CHANGED:ADD 2022-10-28 #14
const highlightTaskPoint = (
  point: Point,
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
) => {
  context.fillStyle = "rgba(105, 101, 219, 0.4)";

  fillCircle(
    context,
    point[0],
    point[1],
    TaskElementEditor.POINT_HANDLE_SIZE / appState.zoom.value,
    false,
  );
};
// CHANGED:ADD 2022-11-2 #64
const highlightLinkPoint = (
  point: Point,
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
) => {
  context.fillStyle = "rgba(105, 101, 219, 0.4)";

  fillCircle(
    context,
    point[0],
    point[1],
    LinkElementEditor.POINT_HANDLE_SIZE / appState.zoom.value,
    false,
  );
};
const renderLinearElementPointHighlight = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  elementsMap: ElementsMap,
) => {
  const { elementId, hoverPointIndex } = appState.selectedLinearElement!;
  if (
    appState.editingLinearElement?.selectedPointsIndices?.includes(
      hoverPointIndex,
    )
  ) {
    return;
  }
  const element = LinearElementEditor.getElement(elementId, elementsMap);

  if (!element) {
    return;
  }
  const point = LinearElementEditor.getPointAtIndexGlobalCoordinates(
    element,
    hoverPointIndex,
    elementsMap,
  );
  context.save();
  context.translate(appState.scrollX, appState.scrollY);

  highlightPoint(point, context, appState);
  context.restore();
};

// CHANGED:ADD 2022-10-28 #14
const renderTaskElementPointHighlight = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  elementsMap: ElementsMap,
) => {
  const { elementId, hoverPointIndex } = appState.selectedTaskElement!;
  const element = TaskElementEditor.getElement(elementId);
  if (!element) {
    return;
  }
  const point = TaskElementEditor.getPointAtIndexGlobalCoordinates(
    element,
    hoverPointIndex,
    elementsMap,
  );
  context.save();
  context.translate(appState.scrollX, appState.scrollY);

  highlightTaskPoint(point, context, appState);
  context.restore();
};

// CHANGED:ADD 2022-11-2 #64
const renderLinkElementPointHighlight = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  elementsMap: ElementsMap,
) => {
  const { elementId, hoverPointIndex } = appState.selectedLinkElement!;
  const element = LinkElementEditor.getElement(elementId);
  if (!element) {
    return;
  }
  const point = LinkElementEditor.getPointAtIndexGlobalCoordinates(
    element,
    hoverPointIndex,
    elementsMap,
  );
  context.save();
  context.translate(appState.scrollX, appState.scrollY);

  highlightLinkPoint(point, context, appState);
  context.restore();
};

const getNormalizedCanvasDimensions = (
  canvas: HTMLCanvasElement,
  scale: number,
): [number, number] => {
  // When doing calculations based on canvas width we should used normalized one
  return [canvas.width / scale, canvas.height / scale];
};

const bootstrapCanvas = ({
  canvas,
  scale,
  normalizedWidth,
  normalizedHeight,
  theme,
  isExporting,
  viewBackgroundColor,
}: {
  canvas: HTMLCanvasElement;
  scale: number;
  normalizedWidth: number;
  normalizedHeight: number;
  theme?: AppState["theme"];
  isExporting?: StaticCanvasRenderConfig["isExporting"];
  viewBackgroundColor?: StaticCanvasAppState["viewBackgroundColor"];
}): CanvasRenderingContext2D => {
  const context = canvas.getContext("2d")!;

  context.setTransform(1, 0, 0, 1, 0, 0);
  context.scale(scale, scale);

  if (isExporting && theme === "dark") {
    context.filter = THEME_FILTER;
  }

  // Paint background
  if (isExporting) {
    return context
  };
  if (typeof viewBackgroundColor === "string") {
    const hasTransparence =
      viewBackgroundColor === "transparent" ||
      viewBackgroundColor.length === 5 || // #RGBA
      viewBackgroundColor.length === 9 || // #RRGGBBA
      /(hsla|rgba)\(/.test(viewBackgroundColor);
    if (hasTransparence) {
      context.clearRect(0, 0, normalizedWidth, normalizedHeight);
    }
    context.save();
    context.fillStyle = viewBackgroundColor;
    context.fillRect(0, 0, normalizedWidth, normalizedHeight);
    context.restore();
  } else {
    context.clearRect(0, 0, normalizedWidth, normalizedHeight);
  }

  return context;
};

const _renderInteractiveScene = ({
  canvas,
  elementsMap,
  visibleElements,
  selectedElements,
  scale,
  appState,
  renderConfig,
}: InteractiveSceneRenderConfig) => {
  if (canvas === null) {
    return { atLeastOneVisibleElement: false, elementsMap };
  }

  const [normalizedWidth, normalizedHeight] = getNormalizedCanvasDimensions(
    canvas,
    scale,
  );

  const context = bootstrapCanvas({
    canvas,
    scale,
    normalizedWidth,
    normalizedHeight,
  });

  // Apply zoom
  context.save();
  context.scale(appState.zoom.value, appState.zoom.value);

  let editingLinearElement: NonDeleted<ExcalidrawLinearElement> | undefined =
    undefined;

  visibleElements.forEach((element) => {
    // Getting the element using LinearElementEditor during collab mismatches version - being one head of visible elements due to
    // ShapeCache returns empty hence making sure that we get the
    // correct element from visible elements
    if (appState.editingLinearElement?.elementId === element.id) {
      if (element) {
        editingLinearElement = element as NonDeleted<ExcalidrawLinearElement>;
      }
    }
  });

  if (editingLinearElement) {
    renderLinearPointHandles(
      context,
      appState,
      editingLinearElement,
      elementsMap,
    );
  }

  // Paint selection element
  if (appState.selectionElement) {
    try {
      renderSelectionElement(appState.selectionElement, context, appState);
    } catch (error: any) {
      console.error(error);
    }
  }

  if (appState.isBindingEnabled) {
    appState.suggestedBindings
      .filter((binding) => binding != null)
      .forEach((suggestedBinding) => {
        renderBindingHighlight(
          context,
          appState,
          suggestedBinding!,
          elementsMap,
        );
      });
  }

  // Getting the element using LinearElementEditor during collab mismatches version - being one head of visible elements due to
  // ShapeCache returns empty hence making sure that we get the
  // correct element from visible elements
  if (
    selectedElements.length === 1 &&
    appState.editingLinearElement?.elementId === selectedElements[0].id
  ) {
    renderLinearPointHandles(
      context,
      appState,
      selectedElements[0] as NonDeleted<ExcalidrawLinearElement>,
      elementsMap,
    );
  }
  // CHANGED:ADD 2022-10-28 #14
  if (appState.isBindingEnabledEx) {
    appState.suggestedBindingsEx
      .filter((binding) => binding != null)
      .forEach((suggestedBindings) => {
        renderBindingHighlightEx(
          context,
          appState,
          suggestedBindings!,
          elementsMap,
        );
      });
  }

  if (
    appState.selectedLinearElement &&
    appState.selectedLinearElement.hoverPointIndex >= 0
  ) {
    renderLinearElementPointHighlight(context, appState, elementsMap);
  }
  // CHANGED:ADD 2022-10-28 #14
  if (
    appState.selectedTaskElement &&
    appState.selectedTaskElement.hoverPointIndex >= 0
  ) {
    renderTaskElementPointHighlight(context, appState, elementsMap);
  }
  // CHANGED:ADD 2022-11-2 #64
  if (
    appState.selectedLinkElement &&
    appState.selectedLinkElement.hoverPointIndex >= 0
  ) {
    renderLinkElementPointHighlight(context, appState, elementsMap);
  }
  // Paint selected elements
  if (!appState.multiElement && !appState.editingLinearElement) {
    const showBoundingBox = shouldShowBoundingBox(selectedElements, appState);

    const isSingleLinearElementSelected =
      selectedElements.length === 1 && isLinearElement(selectedElements[0]);
    // render selected linear element points
    if (
      isSingleLinearElementSelected &&
      appState.selectedLinearElement?.elementId === selectedElements[0].id &&
      !selectedElements[0].locked
    ) {
      renderLinearPointHandles(
        context,
        appState,
        selectedElements[0] as ExcalidrawLinearElement,
        elementsMap,
      );
    }
    // CHANGED:ADD 2022-10-28 #14
    const isSingleTaskElementSelected =
      selectedElements.length === 1 && isTaskElement(selectedElements[0]);
    // render selected task element points
    if (
      isSingleTaskElementSelected &&
      appState.selectedTaskElement?.elementId === selectedElements[0].id &&
      !selectedElements[0].locked
    ) {
      renderTaskPointHandles(
        context,
        appState,
        selectedElements[0] as ExcalidrawTaskElement,
        elementsMap,
      );
    }
    // CHANGED:ADD 2022-11-2 #64
    const isSingleLinkElementSelected =
      selectedElements.length === 1 && isLinkElement(selectedElements[0]);
    // render selected link element points
    if (
      isSingleLinkElementSelected &&
      appState.selectedLinkElement?.elementId === selectedElements[0].id &&
      !selectedElements[0].locked
    ) {
      renderLinkPointHandles(
        context,
        appState,
        selectedElements[0] as ExcalidrawLinkElement,
        elementsMap,
      );
    }
    // CHANGE:ADD 2023/02/08 #562
    const _scrollX =
      selectedElements.length === 1 && isJobElement(selectedElements[0])
        ? 0
        : appState.scrollX;
    const selectionColor = renderConfig.selectionColor || oc.black;

    if (showBoundingBox) {
      // Optimisation for finding quickly relevant element ids
      const locallySelectedIds = arrayToMap(selectedElements);

      const selections: {
        angle: number;
        elementX1: number;
        elementY1: number;
        elementX2: number;
        elementY2: number;
        selectionColors: string[];
        dashed?: boolean;
        cx: number;
        cy: number;
      }[] = [];

      for (const element of elementsMap.values()) {
        const selectionColors = [];
        // local user
        if (
          locallySelectedIds.has(element.id) &&
          !isSelectedViaGroup(appState, element)
        ) {
          selectionColors.push(selectionColor);
        }
        // remote users
        if (renderConfig.remoteSelectedElementIds[element.id]) {
          selectionColors.push(
            ...renderConfig.remoteSelectedElementIds[element.id].map(
              (socketId: string) => {
                const background = getClientColor(socketId);
                return background;
              },
            ),
          );
        }

        if (selectionColors.length) {
          // CHANGED:UPDATED 2023-01-22 #391
          let [elementX1, elementY1, elementX2, elementY2, cx, cy] =
            getElementAbsoluteCoords(element, elementsMap, true);
          // CHANGED:ADD 2023-01-22 #391
          if (isJobElement(element)) {
            elementX1 -= appState.scrollX;
            elementX2 -= appState.scrollX;
          }
          selections.push({
            angle: (element.angle || 0),
            elementX1,
            elementY1,
            elementX2,
            elementY2,
            selectionColors,
            dashed: !!renderConfig.remoteSelectedElementIds[element.id],
            cx,
            cy,
          });
        }
      }

      const addSelectionForGroupId = (groupId: GroupId) => {
        const groupElements = getElementsInGroup(elementsMap, groupId);
        const [elementX1, elementY1, elementX2, elementY2] =
          getCommonBounds(groupElements);
        selections.push({
          angle: 0,
          elementX1,
          elementX2,
          elementY1,
          elementY2,
          selectionColors: [oc.black],
          dashed: true,
          cx: elementX1 + (elementX2 - elementX1) / 2,
          cy: elementY1 + (elementY2 - elementY1) / 2,
        });
      };

      for (const groupId of getSelectedGroupIds(appState)) {
        // TODO: support multiplayer selected group IDs
        addSelectionForGroupId(groupId);
      }

      if (appState.editingGroupId) {
        addSelectionForGroupId(appState.editingGroupId);
      }

      selections.forEach((selection) =>
        renderSelectionBorder(context, appState, selection),
      );
    }
    // Paint resize transformHandles
    context.save();
    // CHANGE:UPDATE 2023/02/08 #562
    // context.translate(appState.scrollX, appState.scrollY);
    context.translate(_scrollX, appState.scrollY);

    if (selectedElements.length === 1) {
      context.fillStyle = oc.white;
      const transformHandles = getTransformHandles(
        selectedElements[0],
        appState.zoom,
        elementsMap,
        "mouse", // when we render we don't know which pointer type so use mouse
      );
      if (!appState.viewModeEnabled && showBoundingBox) {
        renderTransformHandles(
          context,
          renderConfig,
          appState,
          transformHandles,
          (selectedElements[0].angle || 0),
        );
      }
    } else if (selectedElements.length > 1 && !appState.isRotating) {
      const dashedLinePadding = (DEFAULT_SPACING * 2) / appState.zoom.value;
      context.fillStyle = oc.white;
      const [x1, y1, x2, y2] = getCommonBounds(selectedElements);
      const initialLineDash = context.getLineDash();
      context.setLineDash([2 / appState.zoom.value]);
      const lineWidth = context.lineWidth;
      context.lineWidth = 1 / appState.zoom.value;
      context.strokeStyle = selectionColor;
      strokeRectWithRotation(
        context,
        x1 - dashedLinePadding,
        y1 - dashedLinePadding,
        x2 - x1 + dashedLinePadding * 2,
        y2 - y1 + dashedLinePadding * 2,
        (x1 + x2) / 2,
        (y1 + y2) / 2,
        0,
      );
      context.lineWidth = lineWidth;
      context.setLineDash(initialLineDash);
      // CHANGED:REMOVE 2022-11-9 #101
      // const transformHandles = getTransformHandlesFromCoords(
      //   [x1, y1, x2, y2, (x1 + x2) / 2, (y1 + y2) / 2],
      //   0,
      //   appState.zoom,
      //   "mouse",
      //   OMIT_SIDES_FOR_MULTIPLE_ELEMENTS,
      // );
      // if (selectedElements.some((element) => !element.locked)) {
      //   renderTransformHandles(
      //     context,
      //     renderConfig,
      //     appState,
      //     transformHandles,
      //     0,
      //   );
      // }
    }
    context.restore();
  }

  // Reset zoom
  context.restore();

  // Paint remote pointers
  for (const clientId in renderConfig.remotePointerViewportCoords) {
    let { x, y } = renderConfig.remotePointerViewportCoords[clientId];

    x -= appState.offsetLeft;
    y -= appState.offsetTop;

    const width = 11;
    const height = 14;

    const isOutOfBounds =
      x < 0 ||
      x > normalizedWidth - width ||
      y < 0 ||
      y > normalizedHeight - height;

    x = Math.max(x, 0);
    x = Math.min(x, normalizedWidth - width);
    y = Math.max(y, 0);
    y = Math.min(y, normalizedHeight - height);

    const background = getClientColor(clientId);

    context.save();
    context.strokeStyle = background;
    context.fillStyle = background;

    const userState = renderConfig.remotePointerUserStates[clientId];
    const isInactive =
      isOutOfBounds ||
      userState === UserIdleState.IDLE ||
      userState === UserIdleState.AWAY;

    if (isInactive) {
      context.globalAlpha = 0.3;
    }

    if (
      renderConfig.remotePointerButton &&
      renderConfig.remotePointerButton[clientId] === "down"
    ) {
      context.beginPath();
      context.arc(x, y, 15, 0, 2 * Math.PI, false);
      context.lineWidth = 3;
      context.strokeStyle = "#ffffff88";
      context.stroke();
      context.closePath();

      context.beginPath();
      context.arc(x, y, 15, 0, 2 * Math.PI, false);
      context.lineWidth = 1;
      context.strokeStyle = background;
      context.stroke();
      context.closePath();
    }

    // Background (white outline) for arrow 
    // context.fillStyle = oc.white;  // CHANGED: UPDATE 2023/12/15 #1261 Arrow outline delete
    // context.strokeStyle = oc.white; 
    // context.lineWidth = 6;
    // context.lineJoin = "round";
    // context.beginPath();
    // context.moveTo(x, y);
    // context.lineTo(x + 0, y + 14);
    // context.lineTo(x + 4, y + 9);
    // context.lineTo(x + 11, y + 8);
    // context.closePath();
    // context.stroke();
    // context.fill();

    // Arrow
    context.fillStyle = background;
    context.strokeStyle = background;
    context.lineWidth = 1;
    context.lineJoin = "round";
    context.beginPath();
    if (isInactive) {
      context.moveTo(x - 1, y - 1);
      context.lineTo(x - 2, y + 15);
      context.lineTo(x + 4.5, y + 12);
      context.lineTo(x + 9, y + 13);
      context.closePath();
      context.fill();
    } else {
      context.moveTo(x, y);
      context.lineTo(x - 1.0, y + 16);
      context.lineTo(x + 4, y + 12);
      context.lineTo(x + 10, y + 12);
      context.closePath();
      context.fill();
      context.stroke();
    }

    const username = renderConfig.remotePointerUsernames[clientId] || "";

    if (!isOutOfBounds && username) {
      context.font = "300 13px sans-serif"; // font has to be set before context.measureText()

      const offsetX = x + width / 2;
      const offsetY = y + height + 5;
      const paddingHorizontal = 4;
      const paddingVertical = 3;
      const measure = context.measureText(username);
      const measureHeight =
        measure.actualBoundingBoxDescent + measure.actualBoundingBoxAscent;
      const finalHeight = Math.max(measureHeight, 12);

      const boxX = offsetX - 1;
      const boxY = offsetY - 1;
      const boxWidth = measure.width + 2 + paddingHorizontal * 2 + 2;
      const boxHeight = finalHeight + 2 + paddingVertical * 2 + 2;
      if (context.roundRect) {
        context.beginPath();
        context.roundRect(boxX, boxY, boxWidth, boxHeight, 3);
        context.fillStyle = background;
        context.fill();
        context.strokeStyle = background;
        context.stroke();
      } else {
        roundRect(context, boxX, boxY, boxWidth, boxHeight, 3);
      }
      context.fillStyle = oc.white;

      context.fillText(
        username,
        offsetX + paddingHorizontal + 1,
        offsetY +
        paddingVertical +
        measure.actualBoundingBoxAscent +
        Math.floor((finalHeight - measureHeight) / 2) +
        2,
      );
    }

    context.restore();
    context.closePath();
  }

  // Paint scrollbars
  let scrollBars;
  if (renderConfig.renderScrollbars) {
    scrollBars = getScrollBars(
      elementsMap,
      normalizedWidth,
      normalizedHeight,
      appState,
    );

    context.save();
    context.fillStyle = SCROLLBAR_COLOR;
    context.strokeStyle = "rgba(255,255,255,0.8)";
    [scrollBars.horizontal, scrollBars.vertical].forEach((scrollBar) => {
      if (scrollBar) {
        roundRect(
          context,
          scrollBar.x,
          scrollBar.y,
          scrollBar.width,
          scrollBar.height,
          SCROLLBAR_WIDTH / 2,
        );
      }
    });
    context.restore();
  }
  // CHANGED:UPDATE 2023-2-24 #744
  // return {
  //   scrollBars,
  //   atLeastOneVisibleElement: visibleElements.length > 0,
  //   elements,
  // };
  const visibleCurrentDate =
    appState.todayScrollX - appState.width / 2 <= appState.scrollX &&
    appState.todayScrollX - appState.width / 2 >=
    appState.scrollX - appState.width;
  return {
    scrollBars,
    atLeastOneVisibleElement: visibleCurrentDate,
    elementsMap,
  };
};

const _renderStaticScene = ({
  canvas,
  rc,
  elementsMap,
  allElementsMap,
  visibleElements,
  scale,
  appState,
  renderConfig,
}: StaticSceneRenderConfig) => {
  if (canvas === null) {
    return;
  }

  const { renderGrid = true, isExporting } = renderConfig;

  const [normalizedWidth, normalizedHeight] = getNormalizedCanvasDimensions(
    canvas,
    scale,
  );

  const context = bootstrapCanvas({
    canvas,
    scale,
    normalizedWidth,
    normalizedHeight,
    theme: appState.theme,
    isExporting,
    viewBackgroundColor: "transparent",
  });

  // Apply zoom
  context.scale(appState.zoom.value, appState.zoom.value);

  // Grid
  if (renderGrid && appState.gridSize) {
    strokeGrid(
      context,
      appState.gridSize,
      appState.scrollX,
      appState.scrollY,
      normalizedWidth / appState.zoom.value,
      normalizedHeight / appState.zoom.value,
    );
  }

  // Paint visible elements
  visibleElements
    .forEach((element) => {
      try {
        renderElement(
          element,
          elementsMap,
          allElementsMap,
          rc,
          context,
          renderConfig,
          appState
        );

        if (!isExporting) {
          renderLinkIcon(element, context, appState, elementsMap);
          renderLockIcon(element, context, appState, elementsMap); // CHANGED:ADD 2023-2-9 #578
        }
      } catch (error: any) {
        console.error(error);
      }
    });
};

/** throttled to animation framerate */
const renderInteractiveSceneThrottled = throttleRAF(
  (config: InteractiveSceneRenderConfig) => {
    const ret = _renderInteractiveScene(config);
    config.callback?.(ret);
  },
  { trailing: true },
);

/**
 * Interactive scene is the ui-canvas where we render boundinb boxes, selections
 * and other ui stuff.
 */
export const renderInteractiveScene = <
  U extends typeof _renderInteractiveScene,
  T extends boolean = false,
>(
  renderConfig: InteractiveSceneRenderConfig,
  throttle?: T,
): T extends true ? void : ReturnType<U> => {
  if (throttle) {
    renderInteractiveSceneThrottled(renderConfig);
    return undefined as T extends true ? void : ReturnType<U>;
  }
  const ret = _renderInteractiveScene(renderConfig);
  renderConfig.callback(ret);
  return ret as T extends true ? void : ReturnType<U>;
};

/** throttled to animation framerate */
const renderStaticSceneThrottled = throttleRAF(
  (config: StaticSceneRenderConfig) => {
    _renderStaticScene(config);
  },
  { trailing: true },
);

/**
 * Static scene is the non-ui canvas where we render elements.
 */
export const renderStaticScene = (
  renderConfig: StaticSceneRenderConfig,
  throttle?: boolean,
) => {
  if (throttle) {
    renderStaticSceneThrottled(renderConfig);
    return;
  }

  _renderStaticScene(renderConfig);
};

export const cancelRender = () => {
  renderInteractiveSceneThrottled.cancel();
  renderStaticSceneThrottled.cancel();
};

const renderTransformHandles = (
  context: CanvasRenderingContext2D,
  renderConfig: InteractiveCanvasRenderConfig,
  appState: InteractiveCanvasAppState,
  transformHandles: TransformHandles,
  angle: number,
): void => {
  Object.keys(transformHandles).forEach((key) => {
    const transformHandle = transformHandles[key as TransformHandleType];
    if (transformHandle !== undefined) {
      const [x, y, width, height] = transformHandle;

      context.save();
      context.lineWidth = 1 / appState.zoom.value;
      if (renderConfig.selectionColor) {
        context.strokeStyle = renderConfig.selectionColor;
      }
      if (key === "rotation") {
        fillCircle(context, x + width / 2, y + height / 2, width / 2);
        // prefer round corners if roundRect API is available
      } else if (context.roundRect) {
        context.beginPath();
        context.roundRect(x, y, width, height, 2 / appState.zoom.value);
        context.fill();
        context.stroke();
      } else {
        strokeRectWithRotation(
          context,
          x,
          y,
          width,
          height,
          x + width / 2,
          y + height / 2,
          angle,
          true, // fill before stroke
        );
      }
      context.restore();
    }
  });
};

const renderSelectionBorder = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  elementProperties: {
    angle: number;
    elementX1: number;
    elementY1: number;
    elementX2: number;
    elementY2: number;
    selectionColors: string[];
    dashed?: boolean;
    cx: number;
    cy: number;
  },
  padding = DEFAULT_SPACING * 2,
) => {
  const {
    angle,
    elementX1,
    elementY1,
    elementX2,
    elementY2,
    selectionColors,
    cx,
    cy,
    dashed,
  } = elementProperties;
  const elementWidth = elementX2 - elementX1;
  const elementHeight = elementY2 - elementY1;

  const linePadding = padding / appState.zoom.value;
  const lineWidth = 8 / appState.zoom.value;
  const spaceWidth = 4 / appState.zoom.value;

  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  context.lineWidth = 1 / appState.zoom.value;

  const count = selectionColors.length;
  for (let index = 0; index < count; ++index) {
    context.strokeStyle = selectionColors[index];
    if (dashed) {
      context.setLineDash([
        lineWidth,
        spaceWidth + (lineWidth + spaceWidth) * (count - 1),
      ]);
    }
    context.lineDashOffset = (lineWidth + spaceWidth) * index;
    strokeRectWithRotation(
      context,
      elementX1 - linePadding,
      elementY1 - linePadding,
      elementWidth + linePadding * 2,
      elementHeight + linePadding * 2,
      cx,
      cy,
      angle,
    );
  }
  context.restore();
};

const renderBindingHighlight = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  suggestedBinding: SuggestedBinding,
  elementsMap: ElementsMap,
) => {
  const renderHighlight = Array.isArray(suggestedBinding)
    ? renderBindingHighlightForSuggestedPointBinding
    : renderBindingHighlightForBindableElement;

  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  renderHighlight(context, suggestedBinding as any, elementsMap);

  context.restore();
};

// CHANGED:ADD 2022-10-28 #14
const renderBindingHighlightEx = (
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
  suggestedBinding: SuggestedBindingEx,
  elementsMap: ElementsMap,
) => {
  const renderHighlight = Array.isArray(suggestedBinding)
    ? renderBindingHighlightForSuggestedPointBindingEx
    : renderBindingHighlightForBindableElementEx;

  context.save();
  context.translate(appState.scrollX, appState.scrollY);
  renderHighlight(context, suggestedBinding as any, elementsMap);

  context.restore();
};

const renderBindingHighlightForBindableElement = (
  context: CanvasRenderingContext2D,
  element: ExcalidrawBindableElement,
  elementsMap: ElementsMap,
) => {
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
  const width = x2 - x1;
  const height = y2 - y1;
  const threshold = maxBindingGap(element, width, height);

  // So that we don't overlap the element itself
  const strokeOffset = 4;
  context.strokeStyle = "rgba(0,0,0,.05)";
  context.lineWidth = threshold - strokeOffset;
  const padding = strokeOffset / 2 + threshold / 2;

  switch (element.type) {
    case "rectangle":
    case "job-text":
    case "text":
    case "image":
      strokeRectWithRotation(
        context,
        x1 - padding,
        y1 - padding,
        width + padding * 2,
        height + padding * 2,
        x1 + width / 2,
        y1 + height / 2,
        (element.angle || 0),
      );
      break;
    case "diamond":
      const side = Math.hypot(width, height);
      const wPadding = (padding * side) / height;
      const hPadding = (padding * side) / width;
      strokeDiamondWithRotation(
        context,
        width + wPadding * 2,
        height + hPadding * 2,
        x1 + width / 2,
        y1 + height / 2,
        (element.angle || 0),
      );
      break;
    case "ellipse":
      strokeEllipseWithRotation(
        context,
        width + padding * 2,
        height + padding * 2,
        x1 + width / 2,
        y1 + height / 2,
        (element.angle || 0),
      );
      break;
  }
};

// CHANGED:ADD 2022-10-28 #14
const renderBindingHighlightForBindableElementEx = (
  context: CanvasRenderingContext2D,
  element: ExcalidrawBindableElementEx,
  elementsMap: ElementsMap,
) => {
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
  const width = x2 - x1;
  const height = y2 - y1;
  const threshold = maxBindingGapEx(element, width, height);

  // So that we don't overlap the element itself
  // CHANGED:UPDATE 2022-11-9 #107
  context.strokeStyle = "rgba(0,0,0,0)";
  // CHANGED:UPDATE 2022-12-7 #157
  context.fillStyle = "rgba(0,0,0,.25)";

  switch (element.type) {
    case "task":
      fillCircle(context, x1, y1, threshold);
      fillCircle(context, x2, y1, threshold);
      break;
    // CHANGED:ADD 2022-12-7 #157
    case "milestone":
      const strokeOffset = 4;
      const padding = strokeOffset / 2 + threshold / 2;
      const side = Math.hypot(width, height);
      const wPadding = (padding * side) / height;
      const hPadding = (padding * side) / width;
      strokeDiamondWithRotation(
        context,
        width + wPadding * 2,
        height + hPadding * 2,
        x1 + width / 2,
        y1 + height / 2,
        (element.angle || 0),
      );
      break;
  }
};

const renderBindingHighlightForSuggestedPointBinding = (
  context: CanvasRenderingContext2D,
  suggestedBinding: SuggestedPointBinding,
  elementsMap: ElementsMap,
) => {
  const [element, startOrEnd, bindableElement] = suggestedBinding;

  const threshold = maxBindingGap(
    bindableElement,
    bindableElement.width,
    bindableElement.height,
  );

  context.strokeStyle = "rgba(0,0,0,0)";
  context.fillStyle = "rgba(0,0,0,.05)";

  const pointIndices =
    startOrEnd === "both" ? [0, -1] : startOrEnd === "start" ? [0] : [-1];
  pointIndices.forEach((index) => {
    const [x, y] = LinearElementEditor.getPointAtIndexGlobalCoordinates(
      element,
      index,
      elementsMap,
    );
    fillCircle(context, x, y, threshold);
  });
};

// CHANGED:UPDATE 2022-11-2 #64
const renderBindingHighlightForSuggestedPointBindingEx = (
  context: CanvasRenderingContext2D,
  suggestedBinding: SuggestedPointBindingEx,
  elementsMap: ElementsMap,
) => {
  const [element, startOrEnd, bindableElement] = suggestedBinding;

  const threshold = maxBindingGapEx(
    bindableElement,
    bindableElement.width,
    bindableElement.height,
  );

  context.strokeStyle = "rgba(0,0,0,0)";
  // CHANGED:UPDATE 2022-12-7 #157
  context.fillStyle = "rgba(0,0,0,.25)";

  const pointIndices =
    startOrEnd === "both" ? [0, -1] : startOrEnd === "start" ? [0] : [-1];
  pointIndices.forEach((index) => {
    const [x, y] = LinkElementEditor.getPointAtIndexGlobalCoordinates(
      element,
      index,
      elementsMap,
    );
    // CHANGED:UPDATE 2022-12-7 #157
    fillCircle(context, x, y, threshold + 4);
  });
};

let linkCanvasCache: any;
const renderLinkIcon = (
  element: NonDeletedExcalidrawElement,
  context: CanvasRenderingContext2D,
  appState: StaticCanvasAppState,
  elementsMap: ElementsMap,
) => {
  if (element.link && !appState.selectedElementIds[element.id]) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const [x, y, width, height] = getLinkHandleFromCoords(
      [x1, y1, x2, y2],
      (element.angle || 0),
      appState,
    );
    const centerX = x + width / 2;
    const centerY = y + height / 2;
    context.save();
    context.translate(appState.scrollX + centerX, appState.scrollY + centerY);
    context.rotate((element.angle || 0));

    if (!linkCanvasCache || linkCanvasCache.zoom !== appState.zoom.value) {
      linkCanvasCache = document.createElement("canvas");
      linkCanvasCache.zoom = appState.zoom.value;
      linkCanvasCache.width =
        width * window.devicePixelRatio * appState.zoom.value;
      linkCanvasCache.height =
        height * window.devicePixelRatio * appState.zoom.value;
      const linkCanvasCacheContext = linkCanvasCache.getContext("2d")!;
      linkCanvasCacheContext.scale(
        window.devicePixelRatio * appState.zoom.value,
        window.devicePixelRatio * appState.zoom.value,
      );
      linkCanvasCacheContext.fillStyle = "#fff";
      linkCanvasCacheContext.fillRect(0, 0, width, height);
      linkCanvasCacheContext.drawImage(EXTERNAL_LINK_IMG, 0, 0, width, height);
      linkCanvasCacheContext.restore();
      context.drawImage(
        linkCanvasCache,
        x - centerX,
        y - centerY,
        width,
        height,
      );
    } else {
      context.drawImage(
        linkCanvasCache,
        x - centerX,
        y - centerY,
        width,
        height,
      );
    }
    context.restore();
  }
};

// CHANGED:ADD 2023-2-9 #578
let lockCanvasCache: any;
const renderLockIcon = (
  element: NonDeletedExcalidrawElement,
  context: CanvasRenderingContext2D,
  appState: StaticCanvasAppState,
  elementsMap: ElementsMap,
) => {
  if (
    element.locked &&
    isGraphElement(element) &&
    !appState.selectedElementIds[element.id]
  ) {
    let [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    // CHANGED:ADD 2023-2-21 #590
    if (isLinkElement(element)) {
      const points = LinkElementEditor.getPointsGlobalCoordinates(element, elementsMap);
      if (points.length > 1) {
        y1 = points[1][0] - points[0][0] ? points[0][1] : points[2][1];
      }
    }
    const [x, y, width, height] = getLockHandleFromCoords(
      [x1, y1, x2, y2],
      (element.angle || 0),
      appState,
    );
    const centerX = x + width / 2;
    const centerY = y + height / 2;
    context.save();
    context.translate(appState.scrollX + centerX, appState.scrollY + centerY);
    context.rotate((element.angle || 0));

    if (!lockCanvasCache || lockCanvasCache.zoom !== appState.zoom.value) {
      lockCanvasCache = document.createElement("canvas");
      lockCanvasCache.zoom = appState.zoom.value;
      lockCanvasCache.width =
        width * window.devicePixelRatio * appState.zoom.value;
      lockCanvasCache.height =
        height * window.devicePixelRatio * appState.zoom.value;
      const lockCanvasCacheContext = lockCanvasCache.getContext("2d")!;
      lockCanvasCacheContext.scale(
        window.devicePixelRatio * appState.zoom.value,
        window.devicePixelRatio * appState.zoom.value,
      );
      lockCanvasCacheContext.fillStyle = "#fff";
      lockCanvasCacheContext.fillRect(0, 0, width, height);
      lockCanvasCacheContext.drawImage(EXTERNAL_LOCK_IMG, 0, 0, width, height);
      lockCanvasCacheContext.restore();
      context.drawImage(
        lockCanvasCache,
        x - centerX,
        y - centerY,
        width,
        height,
      );
    } else {
      context.drawImage(
        lockCanvasCache,
        x - centerX,
        y - centerY,
        width,
        height,
      );
    }
    context.restore();
  }
};

// This should be only called for exporting purposes
export const renderSceneToSvg = (
  elements: readonly NonDeletedExcalidrawElement[],
  elementsMap: RenderableElementsMap,
  rsvg: RoughSVG,
  svgRoot: SVGElement,
  files: BinaryFiles,
  renderConfig: SVGRenderConfig,
  {
    offsetX = 0,
    offsetY = 0,
    exportWithDarkMode = false,
  }: {
    offsetX?: number;
    offsetY?: number;
    exportWithDarkMode?: boolean;
  } = {},
) => {
  if (!svgRoot) {
    return;
  }
  // render elements
  elements.forEach((element, index) => {
    if (!element.isDeleted) {
      try {
        renderElementToSvg(
          element,
          elementsMap,
          rsvg,
          svgRoot,
          files,
          element.x + offsetX,
          element.y + offsetY,
          renderConfig,
        );
      } catch (error: any) {
        console.error(error);
      }
    }
  });
};
