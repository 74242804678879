import { Timestamp } from "firebase/firestore";

export function firebaseTimeToDate(time?: Timestamp) {
  if (time) return new Date(time.seconds * 1000);
  return new Date();
};

export function dateToFirebaseTime(time?: Date) {
  if (time) return Timestamp.fromDate(time);
  return Timestamp.now()
};
