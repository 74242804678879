import { ElementsMap, ExcalidrawElement, NonDeletedExcalidrawElement } from "src/excalidraw/element/types";
import { ExcalidrawJobElement, ExcalidrawLinkElement } from "./element/types";
import {
  isBindableElementEx,
  isJobElement,
  isJobTextElement,
  isLinkElement,
  isMilestoneElement,
  isNodeElement,
} from "./element/typeChecks";
import { mutateElement } from "../element/mutateElement";
import { getBoundTextElement } from "../element/textElement";
import { updateBoundElementsEx } from "./element/binding";
import { AppState } from "../types";
import Calendar from "./calendar";
import _ from "lodash";
import { getClosestGridY } from "src/excalidraw/element/resizeElements";

class Job {
  static getJobsHeight(rows: Array<number>, gridSize: number): number {
    return rows.reduce((prev, curr) => prev + curr, 0) * gridSize;
  }

  static getJobElementsHeight(
    jobElements: readonly ExcalidrawElement[],
  ): number {
    return jobElements.reduce((prev, curr) => prev + curr.height, 0);
  }

  static getJobElements(
    elements: readonly ExcalidrawElement[],
  ): ExcalidrawJobElement[] {
    return elements.filter(
      (element) => !element.isDeleted && isJobElement(element),
    ) as ExcalidrawJobElement[];
  }

  // CHANGED:ADD 2023/02/13 #633 #647
  static updateJobElements(
    elements: readonly NonDeletedExcalidrawElement[],
    elementsMap: ElementsMap,
    prevY: number,
    prevH: number,
    diffH: number,
    appState: AppState,
    calendar: Calendar,
  ): void {
    // const elements = scene.getNonDeletedElements();

    // update jobElement's height
    elements
      .filter((element) => isJobElement(element) && element.y > prevY)
      .forEach((element) => {
        mutateElement(element, { y: element.y + diffH });
        const boundTextElement = getBoundTextElement(element, elementsMap);
        if (boundTextElement) {
          mutateElement(boundTextElement, {
            y: boundTextElement.y + diffH,
          });
        }
      });

    // update element's axisY
    elements
      .filter((element) => {
        if (!isJobElement(element) && !isJobTextElement(element)) {
          const offsetY = isMilestoneElement(element) ? element.height / 2 : 0;
          return element.y + offsetY > prevY + prevH;
        }
        return false;
      })
      .forEach((element) => {
        mutateElement(element, {
          y: element.y + diffH,
        });
      });

    // update nodeElement's bound link
    (elements
      .filter((element) => {
        if (isLinkElement(element)) {
          return element.y + element.height > prevY + prevH;
        }
        return false;
      }) as ExcalidrawLinkElement[])
      .forEach((linkElement) => {
        if (linkElement.startBinding) {
          const startBindingElement = elementsMap.get(linkElement.startBinding.elementId);
          if (startBindingElement && isBindableElementEx(startBindingElement)) {
            updateBoundElementsEx(startBindingElement, elementsMap, appState, calendar);
          }
        }

        if (linkElement.endBinding) {
          const endBindingElement = elementsMap.get(linkElement.endBinding.elementId);
          if (endBindingElement && isBindableElementEx(endBindingElement)) {
            updateBoundElementsEx(endBindingElement, elementsMap, appState, calendar);
          }
        }
      });
  }

  static getApproximateJobHeightByTextHeight(jobTextHeight: number, gridSize: number): number {
    let height = gridSize;
    if (jobTextHeight < height) return height;
    
    while(jobTextHeight > height) {
      height += gridSize;
    }
    return height;
  }

  // CHANGED: ADD 2023-03-05 #740
  static getJobElementFromGivenGridY(
    gridY: number,
    elements: readonly NonDeletedExcalidrawElement[],
  ): ExcalidrawJobElement | undefined {
    const jobElements = elements
      .filter((element) => isJobElement(element) && !element.isDeleted && !element.isCompressed)
      .sort((a, b) => a.y - b.y) as ExcalidrawJobElement[];

    if (
      jobElements.length > 0 &&
      jobElements[0].y <= gridY &&
      gridY <= jobElements[0].y + jobElements[0].height
    ) {
      return jobElements[0];
    }

    return jobElements.find(
      (element) => (element.y < gridY && gridY <= element.y + element.height)
    );
  }

  // CHANGED: ADD 2023-03-15 #740
  static setJobId(
    element: ExcalidrawElement,
    elements: readonly NonDeletedExcalidrawElement[],
  ) {
    if (isNodeElement(element) && element.isVisible) {
      const job = this.getJobElementFromGivenGridY(element.y, elements);
      if (job) {
        mutateElement(element, {
          jobId: job.id,
          jobOffsetY: element.y - job.y,
        });
      }
    }
  }
  
  static checkCompressedJobElementExistsInGivenRange(
    elements: readonly NonDeletedExcalidrawElement[],
    range: { minY: number, maxY: number }
  ): boolean {
    const minY = getClosestGridY(range.minY);
    const maxY = getClosestGridY(range.maxY);
    const element = elements
                    .find((element) => isJobElement(element) &&
                      minY < element.y && element.y + element.height <= maxY && 
                      element.isCompressed
                    );
    return !_.isEmpty(element)
  }
}

export default Job;
