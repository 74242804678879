import React, { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Flip, ToastContainer, toast } from 'react-toastify';
//mobx
import { useStore } from "src/conpath/hooks/useStore";

//components
import { Button } from "src/conpath/components/Button";
import { RotatingLines } from 'react-loader-spinner';

//constants
import { SessionKeys } from "src/conpath/constants/SessionKeys";
import { Paths } from "src/conpath/constants/Routes";
import Colors from "src/conpath/constants/Colors";
import { AcceptInvitationRequestErrors } from "src/conpath/constants/errors/OrganizationUserRequestErrors";
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";

//interfaces
import { InvitationBody } from "src/conpath/interfaces/Invitation";

//styles
import "./InvitationAccept.scss";
import 'react-toastify/dist/ReactToastify.css';

interface InvitationState extends Pick<InvitationBody, "organizationId" | "organizationName" | "id" |"email"> {
  userId: string;
};

const ProcessSuccessText = {
  Accept: "組織に参加しました。",
  Decline: "組織への招待を辞退しました。",
  ToDashboard: "組織への招待を辞退しました。",
};

enum CardViewType {
  CHOICE,
  SEE_YOU,
  WELCOME,
};

const InvitationAccept: React.FC = () => {
  
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userStore } = useStore();

  const [invitationBody, setInvitationBody] = useState<InvitationState>({
    userId: "",
    email: "",
    organizationId: "",
    organizationName: "",
    id: "", // invitation id
  });
  const [errorText, setErrorText] = useState<string>("");
  const [successText, setSuccessText] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [cardView, setCardView] = useState<CardViewType>(CardViewType.CHOICE);

  const skipDeletingAccount = !!state?.skipDeletingAccount;
  
  useEffect(() => {
    // Check if invited
    const token = sessionStorage.getItem(SessionKeys.InvitationToken);
    const userId = state?.userId;
    
    if (token && userId) {
      const decoded = jwt_decode(token) as InvitationBody;
      setInvitationBody({
        organizationId: decoded.organizationId,
        organizationName: decoded.organizationName,
        id: decoded.id,
        userId: userId,
        email: decoded.email,
      });
    } else {
      // redirect to login page
      navigate(Paths.login, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
      if (errorText === AcceptInvitationRequestErrors.InvitationCanceled) {
        // 組織から招待のキャンセルがあった場合は招待ページに残っても意味ないので、ログインページに推移させる。
        setTimeout(() => {
          sessionStorage.removeItem(SessionKeys.InvitationToken);
          navigate(Paths.login, { replace: true });
        }, TOAST_DEFAULT_DURATION);
      }
    }

    if (successText) {
      toast.success(successText);
      setSuccessText("");
      sessionStorage.removeItem(SessionKeys.InvitationToken);
      setTimeout(async () => {
        let nextPath: Paths = Paths.login;
        if (successText === ProcessSuccessText.Accept) {
          await userStore.setUserFromAcceptInvitation(invitationBody.userId);
          nextPath = Paths.dashboard;
        } else if (successText === ProcessSuccessText.ToDashboard) {
          nextPath = Paths.dashboard;
        }
        navigate(nextPath, { replace: true });
      }, TOAST_DEFAULT_DURATION);
    }

  }, [errorText, successText])

  const onDeclineInvitationButtonPressed = useCallback(async () => {
    if (!userStore) return;

    setIsProcessing(true);
    const declineInvitationResult = await userStore.declineInvitation(
      invitationBody.organizationId,
      invitationBody.id,
      invitationBody.userId,
      invitationBody.email,
      skipDeletingAccount,
    );
    setIsProcessing(false);
    if (declineInvitationResult.error) {
      setErrorText(declineInvitationResult.error);
    } else {
      // navigate to login page
      const text = skipDeletingAccount ? ProcessSuccessText.ToDashboard : ProcessSuccessText.Decline;
      setSuccessText(text);
      setCardView(CardViewType.SEE_YOU);
    }
  }, [invitationBody, userStore, skipDeletingAccount]);

  const onJoinButtonPressed = useCallback(async () => {
    if (!userStore) return;

    setIsProcessing(true);
    const acceptInvitationResult = await userStore.acceptInvitation(
      invitationBody.organizationId,
      invitationBody.userId,
      invitationBody.id
    );
    setIsProcessing(false);
    if (acceptInvitationResult.error) {
      setErrorText(acceptInvitationResult.error);
    } else {
      // navigate to top page
      setSuccessText(ProcessSuccessText.Accept);
      setCardView(CardViewType.WELCOME);
    }
  }, [invitationBody, userStore]);

  const switchCardView = (): React.ReactNode => {
    
    switch (cardView) {
      case CardViewType.WELCOME:
        return (
          <WelcomeCard 
            organizationName={invitationBody.organizationName} />
        )
      case CardViewType.SEE_YOU:
        return <SeeYouAgainCard />
      case CardViewType.CHOICE: 
      default:
        return (
          <InvitationDecisionCard
            isProcessing={isProcessing}
            skipDeletingAccount={skipDeletingAccount}
            organizationName={invitationBody.organizationName}
            onDeclineInvitationButtonPressed={onDeclineInvitationButtonPressed}
            onJoinButtonPressed={onJoinButtonPressed}
            />
        )
    }
  }

  return (
    <div className="input__body">
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={TOAST_DEFAULT_DURATION}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />

      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
      {switchCardView()}
    </div>
  )
};

const InvitationDecisionCard = (props: {
  isProcessing: boolean,
  skipDeletingAccount: boolean,
  organizationName: string,
  onDeclineInvitationButtonPressed: () => Promise<void>,
  onJoinButtonPressed: () => Promise<void>,
}) => {

  return (
    <div className="input__card topSpacer">
      <h2 className="input__title">
        {`${props.organizationName}に参加しますか?`}
      </h2>
      {
        props.isProcessing ?
        <div 
          className="loading-wrapper">
          <RotatingLines
            strokeColor={Colors.primary}
            strokeWidth="5"
            animationDuration="0.75"
            width="32"
            visible={true}
          />
        </div>
        :
        <div className="accept-button-wrapper">
          <Button
            theme="cancel"
            onClick={props.onDeclineInvitationButtonPressed}>
            辞退する
          </Button>
          <Button 
            theme="secondary"
            onClick={props.onJoinButtonPressed}>
            参加する
          </Button>
        </div>
      }

      <div className="input__wrapper minSpacer">
        <p className="input__label">
          ※参加しないを押した場合は<br/>
          { props.skipDeletingAccount ? "組織へは参加せずダッシュボードへ移行します。" : "入力して頂いたユーザー情報を自動的に消去します。"}
        </p>
      </div>
    </div>
  )
}

const SeeYouAgainCard = () => {

  return (
    <div className="input__card topSpacer">
      <h2 className="input__title m-[12px]">
        またのConPathのご利用お待ちしております。
      </h2>
    </div>
  )
};

const WelcomeCard = (props: {
  organizationName: string
}) => {

  return (
    <div className="input__card topSpacer">
      <h2 className="input__title m-[12px]">
        {`${props.organizationName}へ参加しました！`}
      </h2>
    </div>
  )
}

export default InvitationAccept;