import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";

export default {
  primary: "#6DACDA",
  gray: "#7b7b7b",
  danger: "#DF4646",
};

export const BarColors = {
  PROJECT_PRIMARY: "#3568B7",
  MILESTONE_PRIMARY: "#46AADF",
  TASK_PRIMARY: "#92CEF0",
  TASK_SECONDARY: "#D2EDFC",
  OVERDUE: ColorsEx.lineColor.overdue,
  CLOSED: "#DDD",
  WHITE: "#FFF",
} as const;

export const CriticalPathColor = {
  primary: "#46AADF",
  secondary: "#DF4646",
} as const;

export type CriticalPathColor = typeof CriticalPathColor[keyof typeof CriticalPathColor];

export const CriticalPathColorMap: { [key in CriticalPathColor]: string } = {
  "#46AADF": "水色",
  "#DF4646": "赤色",
};

export const DEFAULT_PROJECT_COLOR = "#46AADF";

export const PROJECT_COLORS = [
  "#546E7A",
  "#6E4E45",
  "#DD559E",
  "#7D53C1",
  "#4F3E93",
  "#3D4DAA",
  "#316CB1",
  "#46AADF",
  "#00A371",
  "#78C654",
  "#CDDC39",
  "#F4DA50",
  "#F49C45",
  "#E8733E",
];
