import React, { useContext } from "react";
import { flushSync } from "react-dom";

import { RoughCanvas } from "roughjs/bin/canvas";
import rough from "roughjs/bin/rough";
import clsx from "clsx";
import { nanoid } from "nanoid";

import {
  // actionAddToLibrary, // CHANGED:REMOVE 2024-02-06 #1579
  actionBringForward,
  actionBringToFront,
  actionCopy,
  actionCopyAsPng,
  // actionCopyAsSvg,
  copyText,
  actionCopyStyles,
  actionCut,
  actionDeleteSelected,
  actionDuplicateSelection,
  actionFinalize,
  // actionFlipHorizontal, // CHANGED:REMOVE 2022-11-9 #109
  // actionFlipVertical, // CHANGED:REMOVE 2022-11-9 #109
  actionGroup,
  actionPasteStyles,
  actionSelectAll,
  actionSendBackward,
  actionSendToBack,
  actionToggleGridMode,
  actionToggleStats,
  actionToggleZenMode,
  actionUnbindText,
  actionBindText,
  actionUngroup,
  actionLink, // CHANGED:ADD 2024-2-7 #1591
  actionToggleLock,
  actionToggleClose, // CHANGED:ADD 2023-02-26 #739
  actionToggleLinearEditor,
  actionToggleEditTask,
  actionAlignLeftTask,
  actionInsertJobRowAbove, // CHANGED:ADD 2023-2-9 #588
  actionInsertJobRowBelow, // CHANGED:ADD 2023-2-9 #588
  actionToggleJobRowExpansion,
  actionSendComment, //CHANGED: ADD 2023-02-22 #1684
  actionToggleAddLibrary, // CHANGED:ADD 2024-02-06 #1579
  actionToggleOverdueTaskMode,
  actionCopyJobRow, // CHANGED:ADD 2024-5-22 #2047
  actionPasteJobRowAbove, // CHANGED:ADD 2024-5-22 #2047
  actionPasteJobRowBelow, // CHANGED:ADD 2024-5-22 #2047
} from "../actions";
import { createRedoAction, createUndoAction } from "../actions/actionHistory";
import { ActionManager } from "../actions/manager";
import { actions } from "../actions/register";
import { ActionResult } from "../actions/types";
import { trackEvent } from "../analytics";
import {
  getDefaultAppState,
  isEraserActive,
  isHandToolActive,
} from "../appState";
import { parseClipboard } from "../clipboard";
import {
  APP_NAME,
  CANVAS_HEADER_HEIGHT,
  CANVAS_MARGIN_BOTTOM,
  CANVAS_MARGIN_RIGHT,
  CURSOR_TYPE,
  DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
  DEFAULT_TASK_FONT_SIZE,
  DEFAULT_UI_OPTIONS,
  DEFAULT_VERTICAL_ALIGN,
  DRAGGING_THRESHOLD,
  ELEMENT_READY_TO_ERASE_OPACITY,
  ELEMENT_SHIFT_TRANSLATE_AMOUNT,
  ELEMENT_TRANSLATE_AMOUNT,
  ENV,
  EVENT,
  GRID_SIZE,
  IMAGE_RENDER_TIMEOUT,
  JOB_ELEMENTS_WIDTH,
  isAndroid,
  isBrave,
  LINE_CONFIRM_THRESHOLD,
  MAX_ALLOWED_FILE_BYTES,
  MIME_TYPES,
  MQ_MAX_HEIGHT_LANDSCAPE,
  MQ_MAX_WIDTH_LANDSCAPE,
  MQ_MAX_WIDTH_PORTRAIT,
  MQ_RIGHT_SIDEBAR_MIN_WIDTH,
  MQ_SM_MAX_WIDTH,
  POINTER_BUTTON,
  PRIORITY,
  ROUNDNESS,
  SCROLL_TIMEOUT,
  TAP_TWICE_TIMEOUT,
  TASK_TO_LINK_GEN_POSITION, // CHANGED:ADD 2022-11-8 #99
  TEXT_TO_CENTER_SNAP_THRESHOLD,
  THEME,
  TOUCH_CTX_MENU_TIMEOUT,
  VERTICAL_ALIGN,
  ZOOM_STEP,
  WARNING_TOAST_DEFAULT_DURATION,
  POINTER_EVENTS, // CHANGED:ADD 2023-03-28 #708-2
  TOOL_BAR_WIDTH, // CHANGED:ADD 2024-01-25 #1540
  HEADER_HEIGHT, // CHANGED:ADD 2024-01-25 #1540
  LIBRARY_SIDEBAR_WIDTH,
  EXPORT_IMAGE_TYPES,
  COMMENT_OFFSET_X,
  COMMENT_OFFSET_Y,
  CONTEXT_MENU_ITEM_HEIGHT,
  TEXT_DIRECTION,
  BOUNDTEXT_OFFSET_X_HORIZONTAL,
  BOUNDTEXT_OFFSET_X_VERTICAL,
  BOUNDTEXT_OFFSET_Y,
  DRAG_LINK_AUTO_SCROLL,
  DRAG_TASK_AUTO_SCROLL,
  POINTER_DIRECTION,
} from "../constants";
import { exportCanvas, loadFromBlob } from "src/excalidraw/data/";
import Library, { distributeLibraryItemsOnSquareGrid } from "src/excalidraw/data/library";
import { RestoredDataState, restore, restoreElements } from "src/excalidraw/data/restore";
import {
  dragNewElement,
  dragSelectedElements,
  duplicateElement,
  getCommonBounds,
  getCursorForResizingElement,
  getDragOffsetXY,
  getElementWithTransformHandleType,
  getNormalizedDimensions,
  getResizeArrowDirection,
  getResizeOffsetXY,
  getLockedLinearCursorAlignSize,
  getTransformHandleTypeFromCoords,
  hitTest,
  isHittingElementBoundingBoxWithoutHittingElement,
  isInvisiblySmallElement,
  isNonDeletedElement,
  isTextElement,
  newElement,
  newLinearElement,
  newTextElement,
  newImageElement,
  textWysiwyg,
  transformElements,
  updateTextElement,
  redrawTextBoundingBox,
  getElementAbsoluteCoords,
} from "../element";
// CHANGED:ADD 2022-10-28 #14
import {
  getResizeArrowDirectionEx,
  newTaskElement,
  newLinkElement, // CHANGED:ADD 2022-11-2 #64
  newMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  newTextElementEx, // CHANGED:ADD 2022-12-13 #313
} from "../extensions/element"; // from extensions
import {
  bindOrUnbindLinearElement,
  bindOrUnbindSelectedElements,
  fixBindingsAfterDeletion,
  fixBindingsAfterDuplication,
  getEligibleElementsForBinding,
  getHoveredElementForBinding,
  isBindingEnabled,
  isLinearElementSimpleAndAlreadyBound,
  maybeBindLinearElement,
  shouldEnableBindingForPointerEvent,
  unbindLinearElements,
  updateBoundElements,
} from "../element/binding";
// CHANGED:ADD 2022-10-28 #14
import {
  bindOrUnbindLinkElement, // CHANGED:UPDATE 2022-11-2 #64
  fixBindingsAfterDeletionEx,
  fixBindingsAfterDuplicationEx,
  getEligibleElementsForBindingEx,
  getHoveredElementForBindingEx,
  isBindingEnabledEx,
  isLinkElementSimpleAndAlreadyBound, // CHANGED:UPDATE 2022-11-2 #64
  maybeBindLinkElement, // CHANGED:UPDATE 2022-11-2 #64
  updateBoundElementsEx,
  SuggestedPointBindingEx, // CHANGED:ADD 2022-11-11 #116
} from "../extensions/element/binding"; // from extensions
import { LinearElementEditor } from "../element/linearElementEditor";
// CHANGED:ADD 2022-10-28 #14
import { TaskElementEditor } from "../extensions/element/taskElementEditor"; // from extensions
// CHANGED:ADD 2022-11-2 #64
import { LinkElementEditor } from "../extensions/element/linkElementEditor"; // from extensions
import { mutateElement, newElementWith } from "../element/mutateElement";
import {
  deepCopyElement,
  duplicateElements,
  newFreeDrawElement,
} from "../element/newElement";
import {
  hasBoundTextElement,
  isArrowElement,
  isBindingElement,
  isBindingElementType,
  isBoundToContainer,
  isImageElement,
  isInitializedImageElement,
  isLinearElement,
  isLinearElementType,
  isTextBindableContainer,
  isUsingAdaptiveRadius,
} from "../element/typeChecks";
// CHANGED:ADD 2022-10-28 #14
import {
  isBindingElementEx,
  isBindingElementTypeEx,
  isTaskElement,
  isLinkElement, // CHANGED:ADD 2022-11-2 #64
  isLinkElementType, // CHANGED:ADD 2022-11-2 #64
  isGraphElement, // CHANGED:ADD 2022-12-1 #195
  isMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  isNodeElement, // CHANGED:ADD 2022-12-12 #296
  isBindableElementEx,
  isJobElement,
  isJobTextElement,
  isCommentableElement,
  isCommentElement, // CHANGED:ADD 2022-12-14 #128
} from "../extensions/element/typeChecks"; // from extensions
import {
  ExcalidrawBindableElement,
  ExcalidrawElement,
  ExcalidrawFreeDrawElement,
  ExcalidrawGenericElement,
  ExcalidrawLinearElement,
  ExcalidrawTextElement,
  NonDeleted,
  InitializedExcalidrawImageElement,
  ExcalidrawImageElement,
  FileId,
  NonDeletedExcalidrawElement,
  ExcalidrawTextContainer,
  ExcalidrawTextElementWithContainer,
  ElementsMap,
} from "../element/types";
// CHANGED:ADD 2022-10-28 #14
import {
  ExcalidrawBindableElementEx,
  ExcalidrawTaskElement,
  ExcalidrawLinkElement, // CHANGED:ADD 2022-11-2 #64
  ExcalidrawMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  ExcalidrawGraphElement,
  ExcalidrawJobElement,
} from "../extensions/element/types"; // from extensions
import { getCenter, getDistance } from "../gesture";
import {
  editGroupForSelectedElement,
  getElementsInGroup,
  getSelectedGroupIdForElement,
  getSelectedGroupIds,
  isElementInGroup,
  isSelectedViaGroup,
  selectGroupsForSelectedElements,
} from "../groups";
import History from "../history";
import { defaultLang, getLanguage, languages, setLanguage, t } from "../i18n";
import {
  CODES,
  shouldResizeFromCenter,
  shouldMaintainAspectRatio,
  shouldRotateWithDiscreteAngle,
  isArrowKey,
  KEYS,
} from "../keys";
import {
  distance2d,
  getCornerRadius,
  getGridPoint,
  isPathALoop,
} from "../math";
// CHANGED:ADD 2022-12-7 #157
import { getGridPointEx } from "../extensions/math"; // from extensions
import {
  calculateScrollCenter,
  getElementsAtPosition,
  getElementsWithinSelection,
  getNormalizedZoom,
  getSelectedElements,
  hasBackground,
  isOverScrollBars,
  isSomeElementSelected,
} from "../scene";
import Scene from "../scene/Scene";
import { RenderInteractiveSceneCallback, ScrollBars } from "../scene/types";
import { getStateForZoom } from "../scene/zoom";
import { findShapeByKey } from "../shapes";
import {
  AppClassProperties,
  AppProps,
  AppState,
  BinaryFileData,
  DataURL,
  ExcalidrawImperativeAPI,
  BinaryFiles,
  Gesture,
  GestureEvent,
  LibraryItems,
  PointerDownState,
  SceneData,
  Device,
  Point,
  ToolType,
  CollaboratorPointer,
  ElementsPendingErasure,
  ExcalidrawChecklist,
  ExcalidrawTags,
  ExcalidrawAssignResources,
  ExcalidrawAssignUsers,
  TaskChildren,
} from "../types";
import {
  debounce,
  distance,
  getFontString,
  getNearestScrollableContainer,
  isInputLike,
  isToolIcon,
  isWritableElement,
  resetCursor,
  resolvablePromise,
  sceneCoordsToViewportCoords,
  setCursor,
  setCursorForShape,
  tupleToCoors,
  viewportCoordsToSceneCoords,
  withBatchedUpdates,
  wrapEvent,
  withBatchedUpdatesThrottled,
  updateObject,
  setEraserCursor,
  updateActiveTool,
  getShortcutKey,
  isTransparent,
  easeToValuesRAF,
  getClosestBoundaryIfExceeds, // UPDATED: ADD 2023-04-12 #507
  easeOut,
  arrayToMap,
  muteFSAbortError,
  isTestEnv,
} from "../utils";
import {
  ContextMenu,
  ContextMenuItems,
  CONTEXT_MENU_SEPARATOR,
  ContextMenuItem,
} from "./ContextMenu";
import LayerUI from "./LayerUI";
import JobLayerUI from "src/excalidraw/extensions/components/JobLayerUI";
import FixedLayerUI from "src/excalidraw/extensions/components/FixedLayerUI";
import { Toast } from "./Toast";
import { actionToggleViewMode } from "../actions/actionToggleViewMode";
import {
  dataURLToFile,
  generateIdFromFile,
  getDataURL,
  getFileFromEvent,
  isImageFileHandle,
  isSupportedImageFile,
  loadSceneOrLibraryFromBlob,
  normalizeFile,
  parseLibraryJSON,
  resizeImageFile,
  SVGStringToFile,
} from "src/excalidraw/data/blob";
import {
  getInitializedImageElements,
  loadHTMLImageElement,
  normalizeSVG,
  updateImageCache as _updateImageCache,
} from "../element/image";
import throttle from "lodash.throttle";
import { fileOpen, FileSystemHandle } from "src/excalidraw/data/filesystem";
import {
  bindTextToShapeAfterDuplication,
  getApproxMinLineHeight,
  getApproxMinLineWidth,
  getBoundTextElement,
  getContainerCenter,
  getContainerElement,
  getDefaultLineHeight,
  getLineHeightInPx,
  isMeasureTextSupported,
  isValidTextContainer,
} from "../element/textElement";
import { getContainerCenterEx } from "src/excalidraw/extensions/element/textElement"; //CHANGED:ADD 2022/12/08 #225
import { isHittingElementNotConsideringBoundingBox } from "../element/collision";
// CHANGED:ADD 2022-11-21 #164
import Calendar from "../extensions/calendar"; // from extensions
import {
  normalizeLink,
  showHyperlinkTooltip,
  hideHyperlinkToolip,
  Hyperlink,
  isLocalLink,
} from "./hyperlink/Hyperlink";
import { shouldShowBoundingBox } from "../element/transformHandles";
import { Fonts } from "../scene/Fonts";
import { actionPaste } from "../actions/actionClipboard";
import {
  actionToggleHandTool,
  zoomToFitElements,
} from "../actions/actionCanvas";
import { jotaiStore } from "../jotai";
import { activeConfirmDialogAtom } from "./ActiveConfirmDialog";
import { actionWrapTextInContainer } from "../actions/actionBoundText";
import BraveMeasureTextError from "./BraveMeasureTextError";
import { ValueOf } from "../utility-types";
import { StaticCanvas, InteractiveCanvas } from "./canvases";
import { Renderer } from "../scene/Renderer";
import { ShapeCache } from "../scene/ShapeCache";

// CHANGED:ADD 2022-11-15 #134
import Scroll from "src/excalidraw/extensions/scene/scroll"; // from extensions
// CHANGED:UPDATE 2022-12-18 #347
import { getMovableSelectedElements } from "../extensions/element/dragElements"; // from extensions
// CHANGED:ADD 2022-11-30 #214
import CriticalPath from "../extensions/criticalPath"; // from extensions
import Job from "src/excalidraw/extensions/job"; // from extensions
import Milestone from "src/excalidraw/extensions/milestone"; // from extensions
import { generateBackgroundElements } from "src/excalidraw/extensions/app/data/background";
import { EditTaskBtn } from "../extensions/components/EditTaskElement"; // CHANGED:ADD 2023/02/06 #518
import {
  generateJobLineElements,
  generateJobPanelElements,
} from "src/excalidraw/extensions/app/data/job"; // CHANGED:ADD 2023/02/03 #562
import { generateMilestoneLineElements } from "src/excalidraw/extensions/app/data/milestone";
import { resetPriority } from "src/excalidraw/extensions/element/priority";
import {
  actionToggleEmphasizedMode,
  actionToggleTransparentMode,
} from "src/excalidraw/extensions/actions/actionToggleEmphasizedMode"; // CHANGED:ADD 2023-3-10 #763
import {
  actionToggleCriticalPathMode,
} from "src/excalidraw/extensions/actions/actionToggleCriticalPathMode"; // CHANGED:ADD 2024-3-9 #1753
import { isPointHittingJobAccordionIcon } from "src/excalidraw/extensions/element/JobAccordion";
import { ProjectRole } from "src/conpath/constants/Role";
import ColorsEx from "../extensions/constants/ColorsEx";
import BackgroundHTML from "./canvases/BackgroundHTML";
import { LaserTrails } from "../laser-trails";
import { AnimationFrameHandler } from "../animation-frame-handler";
import { SVGLayer } from "./SVGLayer";
import { AnimatedTrail } from "../animated-trail";
import ProjectModel from "src/conpath/models/ProjectModel";
import { runPointerHandlerOnCanvas } from "../extensions/shared/utils/canvasUtils";
import { makeNextSelectedElementIds } from "../scene/selection";
import { savePasteLibraryElements } from "../extensions/library"; // CHANGED:ADD 2024/02/06 #1579
import { actionChangeLinkPointerDirection } from "../extensions/actions/actionProperties";
import { getLinkHandleFromCoords, isPointHittingLink } from "./hyperlink/helpers";
import { hideTooltip, showTooltip } from "./Tooltip";
import { arrayToStringLabel } from "src/utils/stringUtils";

const deviceContextInitialValue = {
  isSmScreen: false,
  isMobile: false,
  isTouchScreen: false,
  canDeviceFitSidebar: false,
};
const DeviceContext = React.createContext<Device>(deviceContextInitialValue);
DeviceContext.displayName = "DeviceContext";

export const ExcalidrawContainerContext = React.createContext<{
  container: HTMLDivElement | null;
  id: string | null;
}>({ container: null, id: null });
ExcalidrawContainerContext.displayName = "ExcalidrawContainerContext";

const ExcalidrawElementsContext = React.createContext<
  readonly NonDeletedExcalidrawElement[]
>([]);
ExcalidrawElementsContext.displayName = "ExcalidrawElementsContext";

const ExcalidrawAppStateContext = React.createContext<AppState>({
  ...getDefaultAppState(),
  width: 0,
  height: 0,
  offsetLeft: 0,
  offsetTop: 0,
});
ExcalidrawAppStateContext.displayName = "ExcalidrawAppStateContext";

const ExcalidrawSetAppStateContext = React.createContext<
  React.Component<any, AppState>["setState"]
>(() => {
  console.warn("unitialized ExcalidrawSetAppStateContext context!");
});
ExcalidrawSetAppStateContext.displayName = "ExcalidrawSetAppStateContext";

const ExcalidrawActionManagerContext = React.createContext<ActionManager>(
  null!,
);
ExcalidrawActionManagerContext.displayName = "ExcalidrawActionManagerContext";

export const useDevice = () => useContext<Device>(DeviceContext);
export const useExcalidrawContainer = () =>
  useContext(ExcalidrawContainerContext);
export const useExcalidrawElements = () =>
  useContext(ExcalidrawElementsContext);
export const useExcalidrawAppState = () =>
  useContext(ExcalidrawAppStateContext);
export const useExcalidrawSetAppState = () =>
  useContext(ExcalidrawSetAppStateContext);
export const useExcalidrawActionManager = () =>
  useContext(ExcalidrawActionManagerContext);

let didTapTwice: boolean = false;
let tappedTwiceTimer = 0;
let cursorX = 0;
let cursorY = 0;
let isHoldingSpace: boolean = false;
let isPanning: boolean = false;
let isDraggingScrollBar: boolean = false;
let currentScrollBars: ScrollBars = { horizontal: null, vertical: null };
let touchTimeout = 0;
let invalidateContextMenu = false;

let IS_PLAIN_PASTE = false;
let IS_PLAIN_PASTE_TIMER = 0;
let PLAIN_PASTE_TOAST_SHOWN = false;

let ticking = false;

let lastPointerUp: ((event: any) => void) | null = null;
const gesture: Gesture = {
  pointers: new Map(),
  lastCenter: null,
  initialDistance: null,
  initialScale: null,
};

class App extends React.Component<AppProps, AppState> {
  canvas: AppClassProperties["canvas"];
  interactiveCanvas: AppClassProperties["interactiveCanvas"] = null;
  rc: RoughCanvas;
  unmounted: boolean = false;
  actionManager: ActionManager;
  device: Device = deviceContextInitialValue;
  detachIsMobileMqHandler?: () => void;

  selectedProject: ProjectModel | undefined;

  private excalidrawContainerRef = React.createRef<HTMLDivElement>();

  public static defaultProps: Partial<AppProps> = {
    // needed for tests to pass since we directly render App in many tests
    UIOptions: DEFAULT_UI_OPTIONS,
  };

  public scene: Scene;
  public renderer: Renderer;
  private fonts: Fonts;
  private resizeObserver: ResizeObserver | undefined;
  private nearestScrollableContainer: HTMLElement | Document | undefined;
  public library: AppClassProperties["library"];
  public libraryItemsFromStorage: LibraryItems | undefined;
  private id: string;
  private history: History;
  private calendar: Calendar; // CHANGED:ADD 2022-11-21 #164
  private scroll: Scroll; //CHANGED:ADD 2022-11-31 #177
  private excalidrawContainerValue: {
    container: HTMLDivElement | null;
    id: string;
  };

  public files: BinaryFiles = {};
  public imageCache: AppClassProperties["imageCache"] = new Map();

  private elementsPendingErasure: ElementsPendingErasure = new Set();

  hitLinkElement?: NonDeletedExcalidrawElement;
  isHittingJobAccordionIcon?: boolean; // CHANGED:ADD 2023-03-29 #790
  lastPointerDownEvent: React.PointerEvent<HTMLElement> | null = null;
  lastPointerUpEvent: React.PointerEvent<HTMLElement> | PointerEvent | null =
    null;
  lastPointerMoveEvent: PointerEvent | null = null;
  lastScenePointer: { x: number; y: number } | null = null;

  animationFrameHandler = new AnimationFrameHandler();

  laserTrails = new LaserTrails(this.animationFrameHandler, this);
  eraserTrail = new AnimatedTrail(this.animationFrameHandler, this, {
    streamline: 0.2,
    size: 5,
    keepHead: true,
    sizeMapping: (c) => {
      const DECAY_TIME = 200;
      const DECAY_LENGTH = 10;
      const t = Math.max(0, 1 - (performance.now() - c.pressure) / DECAY_TIME);
      const l =
        (DECAY_LENGTH -
          Math.min(DECAY_LENGTH, c.totalLength - c.currentIndex)) /
        DECAY_LENGTH;

      return Math.min(easeOut(l), easeOut(t));
    },
    fill: () =>
      this.state.theme === THEME.LIGHT
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(255, 255, 255, 0.2)",
  });

  constructor(props: AppProps) {
    super(props);
    const defaultAppState = getDefaultAppState();
    const {
      excalidrawRef,
      viewModeEnabled = false,
      zenModeEnabled = false,
      gridModeEnabled = false,
      theme = defaultAppState.theme,
      name = defaultAppState.name,
      selectedProject
    } = props;
    this.state = {
      ...defaultAppState,
      theme,
      isLoading: true,
      ...this.getCanvasOffsets(),
      viewModeEnabled,
      zenModeEnabled,
      gridSize: gridModeEnabled ? GRID_SIZE : null,
      name,
      width: window.innerWidth,
      height: window.innerHeight,
      showHyperlinkPopup: false,
      isSidebarDocked: false,
      ...props.initialAppState,
    };

    this.id = nanoid();

    this.selectedProject = selectedProject; // CHANGED:ADD 2024-06-30 #2084

    this.library = new Library(this);
    this.scene = new Scene();

    this.canvas = document.createElement("canvas");
    this.rc = rough.canvas(this.canvas);
    this.renderer = new Renderer(this.scene);

    if (excalidrawRef) {
      const readyPromise =
        ("current" in excalidrawRef && excalidrawRef.current?.readyPromise) ||
        resolvablePromise<ExcalidrawImperativeAPI>();

      const api: ExcalidrawImperativeAPI = {
        ready: true,
        readyPromise,
        updateScene: this.updateScene,
        updateLibrary: this.library.updateLibrary,
        addFiles: this.addFiles,
        resetScene: this.resetScene,
        getSceneElementsIncludingDeleted: this.getSceneElementsIncludingDeleted,
        getSceneElementsMapIncludingDeleted: this.getSceneElementsMapIncludingDeleted,
        history: {
          clear: this.resetHistory,
        },
        scrollToContent: this.scrollToContent,
        getSceneElements: this.getSceneElements,
        getAppState: () => this.state,
        getFiles: () => this.files,
        refresh: this.refresh,
        setToast: this.setToast,
        id: this.id,
        setActiveTool: this.setActiveTool,
        setCursor: this.setCursor,
        resetCursor: this.resetCursor,
        toggleMenu: this.toggleMenu,
      } as const;
      if (typeof excalidrawRef === "function") {
        excalidrawRef(api);
      } else {
        excalidrawRef.current = api;
      }
      readyPromise.resolve(api);
    }

    this.excalidrawContainerValue = {
      container: this.excalidrawContainerRef.current,
      id: this.id,
    };

    this.fonts = new Fonts({
      scene: this.scene,
      onSceneUpdated: this.onSceneUpdated,
    });
    this.history = new History();

    // CHANGED:ADD 2022-11-21 #164
    this.calendar = new Calendar(
      this.state.gridSize,
      this.state.projectStartDate,
      this.state.holidays,
    );

    // CHANGED:ADD 2022-11-30 #177
    this.scroll = new Scroll({
      limitX:
        this.state.calendarWidth -
        ((window.innerWidth - CANVAS_MARGIN_RIGHT) / this.state.zoom.value - JOB_ELEMENTS_WIDTH),
      limitY:
        this.state.jobsHeight -
        (window.innerHeight - CANVAS_MARGIN_BOTTOM) / this.state.zoom.value,
    });
    this.actionManager = new ActionManager(
      this.syncActionResult,
      () => this.state,
      () => this.scene.getElementsIncludingDeleted(),
      this,
    );
    this.actionManager.registerAll(actions);

    this.actionManager.registerAction(createUndoAction(this.history));
    this.actionManager.registerAction(createRedoAction(this.history));
  }

  // CHANGED:ADDED 2023/03/27 #708
  private renderEditTaskButton(
    selectedElement: ExcalidrawElement[],
    actionManager: ActionManager,
    appState: AppState,
    elementsMap: ElementsMap,
  ) {
    if (
      selectedElement.length === 1 &&
      isTaskElement(selectedElement[0]) &&
      !appState.contextMenu
    ) {
      return (
        <EditTaskBtn
          element={selectedElement[0]}
          actionManager={actionManager}
          appState={appState}
          elementsMap={elementsMap}
        />
      );
    }
    return null;
  }

  // CHANGED:ADDED 2023/03/27 #708
  private renderHyperlink(
    selectedElement: ExcalidrawElement[],
    allElementsMap: ElementsMap,
  ) {
    if (
      selectedElement.length === 1 &&
      !this.state.contextMenu &&
      this.state.showHyperlinkPopup
    ) {
      return (
        <Hyperlink
          key={selectedElement[0].id}
          element={selectedElement[0]}
          elementsMap={allElementsMap}
          setAppState={this.setAppState}
          onLinkOpen={this.props.onLinkOpen}
        />
      );
    }
    return null;
  }

  public render() {
    const { renderTopRightUI, renderCustomStats } = this.props;
    const selectedElements = this.scene.getSelectedElements(this.state);

    const versionNonce = this.scene.getVersionNonce();
    const { elementsMap, visibleElements } =
      this.renderer.getRenderableElements({
        versionNonce,
        zoom: this.state.zoom,
        offsetLeft: this.state.offsetLeft,
        offsetTop: this.state.offsetTop,
        scrollX: this.state.scrollX,
        scrollY: this.state.scrollY,
        height: this.state.height,
        width: this.state.width,
        editingElement: this.state.editingElement,
        pendingImageElementId: this.state.pendingImageElementId,
        viewLayers: this.state.viewLayers, // CHANGED:ADD 2024-10-5 #2114
      });

    const allElementsMap = this.scene.getNonDeletedElementsMap();

    // CHANGED:ADDED 2023/03/27 #708
    const hyperLink = this.renderHyperlink(selectedElements, allElementsMap);

    // CHANGED:ADDED 2023/03/27 #708
    const editTaskButton = this.renderEditTaskButton(
      selectedElements,
      this.actionManager,
      this.state,
      allElementsMap,
    );

    const shouldBlockPointerEvents =
      !(
        this.state.editingElement && isLinearElement(this.state.editingElement)
      ) &&
      (this.state.selectionElement ||
        this.state.draggingElement ||
        this.state.resizingElement ||
        (this.state.activeTool.type === "laser" &&
          // technically we can just test on this once we make it more safe
          this.state.cursorButton === "down") ||
        (this.state.editingElement &&
          !isTextElement(this.state.editingElement)));

    return (
      <div
        className={clsx("conpath conpath-container", {
          "conpath--view-mode": this.state.viewModeEnabled,
          "conpath--mobile": this.device.isMobile,
        })}
        style={{
          ["--ui-pointerEvents" as any]: shouldBlockPointerEvents
            ? POINTER_EVENTS.disabled
            : POINTER_EVENTS.enabled,
          height: window.innerHeight - HEADER_HEIGHT, // CHANGED:ADD 2024/01/25 #1540
          width: window.innerWidth - TOOL_BAR_WIDTH, // CHANGED:ADD 2024/01/25 #1540
        }}
        ref={this.excalidrawContainerRef}
        onDrop={this.handleAppOnDrop}
        tabIndex={0}
        onKeyDown={
          this.props.handleKeyboardGlobally ? undefined : this.onKeyDown
        }
      >
        <ExcalidrawContainerContext.Provider
          value={this.excalidrawContainerValue}
        >
          <DeviceContext.Provider value={this.device}>
            <ExcalidrawSetAppStateContext.Provider value={this.setAppState}>
              <ExcalidrawAppStateContext.Provider value={this.state}>
                <ExcalidrawElementsContext.Provider
                  value={this.scene.getNonDeletedElements()}
                >
                  <ExcalidrawActionManagerContext.Provider
                    value={this.actionManager}
                  >
                    <FixedLayerUI
                      appState={this.state}
                      setAppState={this.setAppState}
                      actionManager={this.actionManager}
                      scene={this.scene}
                      calendar={this.calendar}
                      scroll={this.scroll}
                    />
                    <LayerUI
                      canvas={this.canvas}
                      appState={this.state}
                      files={this.files}
                      setAppState={this.setAppState}
                      actionManager={this.actionManager}
                      elements={this.scene.getNonDeletedElements()}
                      onLockToggle={this.toggleLock}
                      onPenModeToggle={this.togglePenMode}
                      onHandToolToggle={this.onHandToolToggle}
                      onInsertElements={(elements) =>
                        this.addElementsFromPasteOrLibrary({
                          elements,
                          position: "center",
                          files: null,
                        })
                      }
                      onExportImage={this.onExportImage}
                      langCode={getLanguage().code}
                      renderTopRightUI={renderTopRightUI}
                      renderCustomStats={renderCustomStats}
                      renderCustomSidebar={this.props.renderSidebar}
                      showExitZenModeBtn={
                        typeof this.props?.zenModeEnabled === "undefined" &&
                        this.state.zenModeEnabled
                      }
                      libraryReturnUrl={this.props.libraryReturnUrl}
                      UIOptions={this.props.UIOptions}
                      focusContainer={this.focusContainer}
                      library={this.library}
                      id={this.id}
                      renderWelcomeScreen={
                        false && // CHANGED:ADD 2022-12-13 #315
                        !this.state.isLoading &&
                        this.state.showWelcomeScreen &&
                        this.state.activeTool.type === "selection" &&
                        !this.scene.getElementsIncludingDeleted().length
                      }
                      onDeleteProject={this.props.onDeleteProject} // CHANGED:ADD 2023/09/13 #1023
                      app={this}
                      scene={this.scene}
                    >
                      {this.props.children}
                    </LayerUI>
                    <SVGLayer
                      trails={[this.laserTrails, this.eraserTrail]}
                    />
                    <JobLayerUI
                      appState={this.state}
                      setAppState={this.setAppState}
                      actionManager={this.actionManager}
                      scene={this.scene}
                      calendar={this.calendar}
                      scroll={this.scroll}
                    />
                    <div className="conpath-textEditorContainer" />
                    <div className="conpath-contextMenuContainer" />
                    {hyperLink} {/* CHANGED:ADDED 2023/03/27 #708 */}
                    {this.state.toast !== null && (
                      <Toast
                        message={this.state.toast.message}
                        onClose={() => this.setToast(null)}
                        duration={this.state.toast.duration}
                        closable={this.state.toast.closable}
                      />
                    )}
                    {this.state.contextMenu && (
                      <ContextMenu
                        items={this.state.contextMenu.items}
                        top={this.state.contextMenu.top}
                        left={this.state.contextMenu.left}
                        actionManager={this.actionManager}
                      />
                    )}
                    {/* CHANGED:ADD 2023/02/06 #518 CHANGED:UPDATED 2023/03/27 #708 */}
                    {editTaskButton}
                    <BackgroundHTML appState={this.state} />
                    <StaticCanvas
                      canvas={this.canvas}
                      rc={this.rc}
                      elementsMap={elementsMap}
                      allElementsMap={allElementsMap}
                      visibleElements={visibleElements}
                      versionNonce={versionNonce}
                      selectionNonce={this.state.selectionElement?.versionNonce}
                      scale={window.devicePixelRatio}
                      appState={this.state}
                      renderConfig={{
                        imageCache: this.imageCache,
                        isExporting: false,
                        renderGrid: false,
                        canvasBackgroundColor:
                          this.state.viewBackgroundColor,
                        criticalPathModeEnabled: this.state.criticalPathModeEnabled,
                        overdueTaskModeEnabled: this.state.overdueTaskModeEnabled,
                        criticalPathColor: this.state.criticalPathColor,
                        gridSize: this.state.gridSize,
                        holidays: this.state.holidays,
                      }}
                    />
                    <InteractiveCanvas
                      canvas={this.interactiveCanvas}
                      elementsMap={elementsMap}
                      visibleElements={visibleElements}
                      selectedElements={selectedElements}
                      versionNonce={versionNonce}
                      selectionNonce={this.state.selectionElement?.versionNonce}
                      scale={window.devicePixelRatio}
                      appState={this.state}
                      renderInteractiveSceneCallback={
                        this.renderInteractiveSceneCallback
                      }
                      handleCanvasRef={this.handleInteractiveCanvasRef}
                      onContextMenu={this.handleCanvasContextMenu}
                      onPointerMove={this.handleCanvasPointerMove}
                      onPointerUp={this.handleCanvasPointerUp}
                      onPointerCancel={this.removePointer}
                      onTouchMove={this.handleTouchMove}
                      onPointerDown={this.handleCanvasPointerDown}
                      onDoubleClick={this.handleCanvasDoubleClick}
                    />
                  </ExcalidrawActionManagerContext.Provider>
                </ExcalidrawElementsContext.Provider>
              </ExcalidrawAppStateContext.Provider>
            </ExcalidrawSetAppStateContext.Provider>
          </DeviceContext.Provider>
        </ExcalidrawContainerContext.Provider>
      </div>
    );
  }

  public focusContainer: AppClassProperties["focusContainer"] = () => {
    if (this.props.autoFocus) {
      this.excalidrawContainerRef.current?.focus();
    }
  };

  public getSceneElementsIncludingDeleted = () => {
    return this.scene.getElementsIncludingDeleted();
  };

  public getSceneElementsMapIncludingDeleted = () => {
    return this.scene.getElementsMapIncludingDeleted();
  };

  public getSceneElements = () => {
    return this.scene.getNonDeletedElements();
  };

  public onExportImage = async (
    type: keyof typeof EXPORT_IMAGE_TYPES,
    elements: readonly NonDeletedExcalidrawElement[],
  ) => {
    trackEvent("export", type, "ui");
    const fileHandle = await exportCanvas(
      type,
      elements,
      this.state,
      this.files,
      {
        exportBackground: this.state.exportBackground,
        name: this.state.name,
        viewBackgroundColor: this.state.viewBackgroundColor,
      },
    )
      .catch(muteFSAbortError)
      .catch((error) => {
        console.error(error);
        this.setState({ errorMessage: error.message });
      });

    if (
      this.state.exportEmbedScene &&
      fileHandle &&
      isImageFileHandle(fileHandle)
    ) {
      this.setState({ fileHandle });
    }
  };

  private syncActionResult = withBatchedUpdates(
    (actionResult: ActionResult) => {
      if (this.unmounted || actionResult === false) {
        return;
      }

      let editingElement: AppState["editingElement"] | null = null;
      if (actionResult.elements) {
        actionResult.elements.forEach((element) => {
          if (
            this.state.editingElement?.id === element.id &&
            this.state.editingElement !== element &&
            isNonDeletedElement(element)
          ) {
            editingElement = element;
          }
        });
        this.scene.replaceAllElements(actionResult.elements);
        if (actionResult.commitToHistory) {
          this.history.resumeRecording();
        }
      }

      // CHANGED:ADD 2023-2-10 #634
      if (actionResult.jobBackgroundElements) {
        this.scene.GenerateJobBackgroundElements(actionResult.jobBackgroundElements);
      }

      // CHHANGED:ADD 2022-12-5 #250
      if (actionResult.jobPanelElements) {
        this.scene.GenerateJobPanelElements(actionResult.jobPanelElements);
      }

      // CHANGED:ADD 2023-03-01 #726
      if (actionResult.jobLineElements) {
        this.scene.GenerateJobLineElements(actionResult.jobLineElements);
      }

      // CHANGED:ADD 2023-2-11 #671
      if (actionResult.milestoneLineElements) {
        this.scene.GenerateMilestoneLineElements(actionResult.milestoneLineElements);
      }

      // CHANGED:ADD 2023-2-10 #638
      if (actionResult.appState && actionResult.updatedJobElements) {
        const jobsHeight = actionResult.appState.jobsHeight;

        this.scene.updateBackgroundHeight(jobsHeight);

        const jobElements = Job.getJobElements(
          this.scene.getNonDeletedElements(),
        );

        this.scene.jobPanelElements = generateJobPanelElements(
          jobElements,
          this.state.calendarWidth,
        );

        this.scene.jobLineElements = generateJobLineElements(
          jobElements,
          this.state.calendarWidth,
        );
      }

      // CHANGED:ADD 2023-2-11 #671
      if (actionResult.appState && actionResult.updatedMilestoneElements) {
        const jobsHeight = actionResult.appState.jobsHeight;

        const milestoneElements = Milestone.getMilestoneElements(
          this.scene.getElementsIncludingDeleted(),
        );
        this.scene.milestoneLineElements = generateMilestoneLineElements(
          milestoneElements,
          jobsHeight
        );
      }

      // CHANGED:ADD 2023/02/14 #676
      if (actionResult.appState && actionResult.updatedProjectDate) {
        const prevBaseDate = new Date(this.calendar.baseDate);
        const baseDate = new Date(actionResult.appState.projectStartDate);

        const diffDay = (prevBaseDate.getTime() - baseDate.getTime()) / (1000 * 3600 * 24);
        if (diffDay !== 0) {
          this.calendar.baseDate = baseDate;

          const gridSize = this.state.gridSize ? this.state.gridSize : GRID_SIZE;
          const offsetX = diffDay * gridSize;

          this.scene.getElementsIncludingDeleted().forEach((element) => {
            if (isGraphElement(element)) {
              mutateElement(element, {
                x: element.x + offsetX,
              });
            }
          });
        }
        this.scene.informMutation();
      }

      // CHANGED:ADD 2023/02/14 #676
      if (actionResult.appState && actionResult.updatedProjectHoliday) {
        const holidays = actionResult.appState.holidays;

        if (
          JSON.stringify(holidays) !== JSON.stringify(this.calendar.hd)
        ) {
          this.calendar.hd = holidays;

          // CHANGED:ADD 2023-1-6 #404 
          this.scene.getElementsIncludingDeleted().forEach((element) => {
            // CHANGED:UPDATE 2023/01/20 #390
            if (isTaskElement(element) || isLinkElement(element)) {
              let newDuration = 0;
              if (isTaskElement(element)) {
                newDuration = this.calendar.getDuration(
                  element.startDate,
                  element.endDate,
                  element.holidays, // CHANGED:ADD 2023-1-20 #382
                );
              } else if (isLinkElement(element)) {
                newDuration = this.calendar.getDuration(
                  new Date(this.calendar.getPointDate(element.x)),
                  new Date(
                    this.calendar.getPointDate(element.x + element.width),
                  ),
                );
              }

              if (element.duration !== newDuration) {
                mutateElement(element, {
                  duration: newDuration,
                });

                // CHANGED:ADD 2024-04-15 #1917
                if (isLinkElement(element) && element.startBinding && element.endBinding) {
                  const startBindingElement =
                    this.scene.getNonDeletedElementsMap().get(element.startBinding.elementId);
                  const endBindingElement =
                    this.scene.getNonDeletedElementsMap().get(element.endBinding.elementId);
                  if (isBindableElementEx(startBindingElement) && isBindableElementEx(endBindingElement)) {
                    mutateElement(startBindingElement, {
                      freeFloats: (startBindingElement.freeFloats?.filter(
                        (float) => float.id !== endBindingElement.id) || [])
                        .concat({
                          id: element.endBinding.elementId,
                          type: endBindingElement.type,
                          duration: newDuration,
                        })
                    });
                  }
                }

                const boundTextElement = getBoundTextElement(
                  element,
                  this.scene.getNonDeletedElementsMap(),
                );
                if (boundTextElement && isLinkElement(element)) { // CHANGED:UPDATE 2023-08-22 #924
                  const text = element.duration > 0 ? `(${element.duration})` : "";

                  this.scene.updateBoundTextElement(
                    boundTextElement,
                    text,
                    boundTextElement.originalText,
                    boundTextElement.isDeleted,
                  );
                }
              }
            }
          });

          this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455
        }
      }

      // CHANGED:ADD 2023-1-27 #439
      if (actionResult.resetHistory) {
        this.resetHistory();
      }

      if (actionResult.files) {
        this.files = actionResult.replaceFiles
          ? actionResult.files
          : { ...this.files, ...actionResult.files };
        this.addNewImagesToImageCache();
      }

      if (actionResult.appState || editingElement || this.state.contextMenu) {
        if (actionResult.commitToHistory) {
          this.history.resumeRecording();
        }

        let viewModeEnabled = actionResult?.appState?.viewModeEnabled || false;
        let zenModeEnabled = actionResult?.appState?.zenModeEnabled || false;
        let gridSize = actionResult?.appState?.gridSize || null;
        const theme =
          actionResult?.appState?.theme || this.props.theme || THEME.LIGHT;
        let name = actionResult?.appState?.name ?? this.state.name;
        const errorMessage =
          actionResult?.appState?.errorMessage ?? this.state.errorMessage;
        if (typeof this.props.viewModeEnabled !== "undefined") {
          viewModeEnabled = this.props.viewModeEnabled;
        }

        if (typeof this.props.zenModeEnabled !== "undefined") {
          zenModeEnabled = this.props.zenModeEnabled;
        }

        if (typeof this.props.gridModeEnabled !== "undefined") {
          gridSize = this.props.gridModeEnabled ? GRID_SIZE : null;
        }

        if (typeof this.props.name !== "undefined") {
          name = this.props.name;
        }
        this.setState(
          (state) => {
            // using Object.assign instead of spread to fool TS 4.2.2+ into
            // regarding the resulting type as not containing undefined
            // (which the following expression will never contain)
            return Object.assign(actionResult.appState || {}, {
              // NOTE this will prevent opening context menu using an action
              // or programmatically from the host, so it will need to be
              // rewritten later
              contextMenu: null,
              editingElement:
                editingElement || actionResult.appState?.editingElement || null,
              viewModeEnabled,
              zenModeEnabled,
              gridSize,
              theme,
              name,
              errorMessage,
            });
          },
          () => {
            if (actionResult.syncHistory) {
              this.history.setCurrentState(
                this.state,
                this.scene.getElementsIncludingDeleted(),
              );
            }

            // CHANGED: ADD 2023-02-06 #560
            if (actionResult.addingNewJobElement) {
              const container = this.state.editingElement as ExcalidrawTextContainer;
              const midPoint = getContainerCenterEx(
                container,
                this.state,
                this.scene.getElementsMapIncludingDeleted(),
              );
              this.startTextEditing({
                sceneX: midPoint.x,
                sceneY: midPoint.y,
                container,
                isJobElementText: true,
              });
            }
          },
        );
      }
    },
  );

  // Lifecycle

  private onBlur = withBatchedUpdates(() => {
    isHoldingSpace = false;

    this.setState({
      isBindingEnabled: true,
      isBindingEnabledEx: true, // CHANGED:ADD 2022-10-28 #14
    });
  });

  private onUnload = () => {
    this.onBlur();
  };

  private disableEvent: EventListener = (event) => {
    event.preventDefault();
  };

  private resetHistory = () => {
    this.history.clear();
  };

  /**
   * Resets scene & history.
   * ! Do not use to clear scene user action !
   */
  private resetScene = withBatchedUpdates(
    (opts?: { resetLoadingState: boolean }) => {
      this.scene.replaceAllElements([]);
      this.setState((state) => ({
        ...getDefaultAppState(),
        isLoading: opts?.resetLoadingState ? false : state.isLoading,
        theme: this.state.theme,
      }));
      this.resetHistory();
    },
  );

  private initializeScene = async () => {
    if ("launchQueue" in window && "LaunchParams" in window) {
      (window as any).launchQueue.setConsumer(
        async (launchParams: { files: any[] }) => {
          if (!launchParams.files.length) {
            return;
          }
          const fileHandle = launchParams.files[0];
          const blob: Blob = await fileHandle.getFile();
          this.loadFileToCanvas(
            new File([blob], blob.name || "", { type: blob.type }),
            fileHandle,
          );
        },
      );
    }

    if (this.props.theme) {
      this.setState({ theme: this.props.theme });
    }
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }
    let initialData = null;
    try {
      initialData = (await this.props.initialData) || null;
      if (initialData?.libraryItems) {
        this.library
          .updateLibrary({
            libraryItems: initialData.libraryItems,
            merge: true,
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error: any) {
      console.error(error);
      initialData = {
        appState: {
          errorMessage:
            error.message ||
            "Encountered an error during importing or restoring scene data",
        },
      };
    }
    const scene = restore(initialData, null, null, { repairBindings: true });
    scene.appState = {
      ...scene.appState,
      theme: this.props.theme || scene.appState.theme,
      // we're falling back to current (pre-init) state when deciding
      // whether to open the library, to handle a case where we
      // update the state outside of initialData (e.g. when loading the app
      // with a library install link, which should auto-open the library)
      openSidebar: scene.appState?.openSidebar || this.state.openSidebar,
      activeTool:
        scene.appState.activeTool.type === "image"
          ? { ...scene.appState.activeTool, type: "selection" }
          : scene.appState.activeTool,
      isLoading: false,
      toast: this.state.toast,
    };
    if (initialData?.scrollToContent) {
      scene.appState = {
        ...scene.appState,
        ...calculateScrollCenter(scene.elements, {
          ...scene.appState,
          width: this.state.width,
          height: this.state.height,
          offsetTop: this.state.offsetTop,
          offsetLeft: this.state.offsetLeft,
        }),
      };
    }
    // FontFaceSet loadingdone event we listen on may not always fire
    // (looking at you Safari), so on init we manually load fonts for current
    // text elements on canvas, and rerender them once done. This also
    // seems faster even in browsers that do fire the loadingdone event.
    this.fonts.loadFontsForElements(scene.elements);

    //CHANGED: ADD 2023-1-12 #406
    const projectStartDate = new Date(scene.appState.projectStartDate);
    const projectEndDate = new Date(scene.appState.projectEndDate);
    const gridSize = scene.appState.gridSize ? scene.appState.gridSize : GRID_SIZE;

    // CHANGED: ADD 2023-01-21 #391
    const jobElements = Job.getJobElements(scene.elements);

    // CHANGED: ADD 2023-2-11 #671
    const milestoneElements = Milestone.getMilestoneElements(scene.elements);

    // CHANGED:ADD 2023-2-14 #704
    resetPriority(scene.elements);

    const {
      jobBackgroundElements,
      jobPanelElements,
      jobLineElements, // CHANGED:ADD 2023-03-01 #726
      jobsHeight,
      milestoneLineElements,
      calendarWidth,
    } = generateBackgroundElements(
      projectStartDate,
      projectEndDate,
      jobElements,
      milestoneElements,
      scene.appState.viewBackgroundColor,
      gridSize,
      scene.appState.holidays,
    );

    scene.jobBackgroundElements = jobBackgroundElements;
    scene.jobPanelElements = jobPanelElements;
    scene.jobLineElements = jobLineElements; // CHANGED:ADD 2023-03-01 #726
    scene.milestoneLineElements = milestoneLineElements;

    this.scroll.updateScrollLimit({
      newScrollLimitX:
        calendarWidth -
        ((window.innerWidth - CANVAS_MARGIN_RIGHT) / this.state.zoom.value - JOB_ELEMENTS_WIDTH),
      newScrollLimitY:
        jobsHeight -
        (window.innerHeight - CANVAS_MARGIN_BOTTOM) / this.state.zoom.value,
    });

    this.calendar.baseDate = projectStartDate; // CHANGED:ADD 2023-1-13 #419
    this.calendar.hd = scene.appState.holidays; // CHANGED:ADD 2023-1-6 #381

    const selectedElement =
      scene.elements.find((element) => {
        if (scene.appState.selectedElementIds[element.id]) {
          return true;
        }
      });

    // CHANGED:ADD 2023-1-13 #416 -> CHANGED: UPDATE 2023-01-21 #391
    const additionalFields: Partial<RestoredDataState["appState"]> = {
      ...Scroll.calculateScrollCenterEx({
        ...scene.appState,
        calendarWidth,
        jobsHeight,
        width: window.innerWidth,
        height: window.innerHeight,
        offsetTop: 0,
        offsetLeft: 0,
      }, selectedElement?.x, selectedElement?.y),
    };

    if (isTaskElement(selectedElement)) {
      additionalFields["selectedTaskElement"] = new TaskElementEditor(selectedElement, this.scene, this.state);
    } else if (isCommentElement(selectedElement)) {
      // additionalFields["openDialog"] = "listComments";
      additionalFields["openSidebar"] = "comments";
    } 

    scene.appState = {
      ...scene.appState,
      calendarWidth,
      jobsHeight,
      // CHANGED:ADD 2023/08/07 #900 -> CHANGED:EDIT 2024-01-25 #1138-4
      ...additionalFields,
    };

    // CHANGED:ADD 2024-01-25 #1138-4
    // Canvas のハンドラーを強制呼び出し
    if (this.interactiveCanvas && selectedElement) {
      runPointerHandlerOnCanvas(selectedElement, this.interactiveCanvas);
    }
    this.resetHistory();
    this.syncActionResult({
      ...scene,
      commitToHistory: true,
    });
  };

  private refreshDeviceState = (container: HTMLDivElement) => {
    const { width, height } = container.getBoundingClientRect();
    const sidebarBreakpoint =
      this.props.UIOptions.dockedSidebarBreakpoint != null
        ? this.props.UIOptions.dockedSidebarBreakpoint
        : MQ_RIGHT_SIDEBAR_MIN_WIDTH;
    this.device = updateObject(this.device, {
      isSmScreen: width < MQ_SM_MAX_WIDTH,
      isMobile:
        width < MQ_MAX_WIDTH_PORTRAIT ||
        (height < MQ_MAX_HEIGHT_LANDSCAPE && width < MQ_MAX_WIDTH_LANDSCAPE),
      canDeviceFitSidebar: width > sidebarBreakpoint,
    });
  };

  public async componentDidMount() {
    this.unmounted = false;
    this.excalidrawContainerValue.container =
      this.excalidrawContainerRef.current;

    if (import.meta.env.MODE === ENV.TEST || import.meta.env.DEV) {
      const setState = this.setState.bind(this);
      Object.defineProperties(window.h, {
        state: {
          configurable: true,
          get: () => {
            return this.state;
          },
        },
        setState: {
          configurable: true,
          value: (...args: Parameters<typeof setState>) => {
            return this.setState(...args);
          },
        },
        app: {
          configurable: true,
          value: this,
        },
        history: {
          configurable: true,
          value: this.history,
        },
      });
    }

    this.scene.addCallback(this.onSceneUpdated);
    this.addEventListeners();

    if (this.excalidrawContainerRef.current) {
      this.focusContainer();
    }

    if (
      this.excalidrawContainerRef.current &&
      // bounding rects don't work in tests so updating
      // the state on init would result in making the test enviro run
      // in mobile breakpoint (0 width/height), making everything fail
      !isTestEnv()
    ) {
      this.refreshDeviceState(this.excalidrawContainerRef.current);
    }

    if ("ResizeObserver" in window && this.excalidrawContainerRef?.current) {
      this.resizeObserver = new ResizeObserver(() => {
        // recompute device dimensions state
        // ---------------------------------------------------------------------
        this.refreshDeviceState(this.excalidrawContainerRef.current!);
        // refresh offsets
        // ---------------------------------------------------------------------
        this.updateDOMRect();
      });
      this.resizeObserver?.observe(this.excalidrawContainerRef.current);
    } else if (window.matchMedia) {
      const mdScreenQuery = window.matchMedia(
        `(max-width: ${MQ_MAX_WIDTH_PORTRAIT}px), (max-height: ${MQ_MAX_HEIGHT_LANDSCAPE}px) and (max-width: ${MQ_MAX_WIDTH_LANDSCAPE}px)`,
      );
      const smScreenQuery = window.matchMedia(
        `(max-width: ${MQ_SM_MAX_WIDTH}px)`,
      );
      const canDeviceFitSidebarMediaQuery = window.matchMedia(
        `(min-width: ${
          // NOTE this won't update if a different breakpoint is supplied
          // after mount
          this.props.UIOptions.dockedSidebarBreakpoint != null
            ? this.props.UIOptions.dockedSidebarBreakpoint
            : MQ_RIGHT_SIDEBAR_MIN_WIDTH
        }px)`,
      );
      const handler = () => {
        this.excalidrawContainerRef.current!.getBoundingClientRect();
        this.device = updateObject(this.device, {
          isSmScreen: smScreenQuery.matches,
          isMobile: mdScreenQuery.matches,
          canDeviceFitSidebar: canDeviceFitSidebarMediaQuery.matches,
        });
      };
      mdScreenQuery.addListener(handler);
      this.detachIsMobileMqHandler = () =>
        mdScreenQuery.removeListener(handler);
    }

    const searchParams = new URLSearchParams(window.location.search.slice(1));

    if (searchParams.has("web-share-target")) {
      // Obtain a file that was shared via the Web Share Target API.
      this.restoreFileFromShare();
    } else {
      this.updateDOMRect(this.initializeScene);
    }

    // note that this check seems to always pass in localhost
    if (isBrave() && !isMeasureTextSupported()) {
      this.setState({
        errorMessage: <BraveMeasureTextError />,
      });
    }
  }

  public componentWillUnmount() {
    this.renderer.destroy();
    this.scene = new Scene();
    this.renderer = new Renderer(this.scene);
    this.files = {};
    this.imageCache.clear();
    this.resizeObserver?.disconnect();
    this.unmounted = true;
    this.removeEventListeners();
    this.scene.destroy();
    this.laserTrails.stop();
    this.eraserTrail.stop();
    ShapeCache.destroy();
    clearTimeout(touchTimeout);
    selectGroupsForSelectedElements.clearCache();
    touchTimeout = 0;
  }

  private onResize = withBatchedUpdates(() => {
    // CHANGED:UPDATE 2022-12-02 #229
    // this.scene
    //   .getElementsIncludingDeleted()
    //   .forEach((element) => ShapeCache.delete(element));
    // this.setState({});
    this.scene.getRenderingElements().forEach((element) =>
      ShapeCache.delete(element),
    );

    // CHANGED:ADD 2023-1-24 #501
    this.scroll.updateScrollLimit({
      newScrollLimitX:
        this.state.calendarWidth -
        ((window.innerWidth - CANVAS_MARGIN_RIGHT) / this.state.zoom.value - JOB_ELEMENTS_WIDTH),
      newScrollLimitY:
        this.state.jobsHeight -
        (window.innerHeight - CANVAS_MARGIN_BOTTOM) / this.state.zoom.value,
    });
    this.setState({});
  });

  private removeEventListeners() {
    document.removeEventListener(EVENT.POINTER_UP, this.removePointer);
    document.removeEventListener(EVENT.COPY, this.onCopy);
    document.removeEventListener(EVENT.PASTE, this.pasteFromClipboard);
    document.removeEventListener(EVENT.CUT, this.onCut);
    this.excalidrawContainerRef.current?.removeEventListener(
      EVENT.WHEEL,
      this.onWheel,
    );
    this.nearestScrollableContainer?.removeEventListener(
      EVENT.SCROLL,
      this.onScroll,
    );
    document.removeEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    document.removeEventListener(
      EVENT.MOUSE_MOVE,
      this.updateCurrentCursorPosition,
      false,
    );
    document.removeEventListener(EVENT.KEYUP, this.onKeyUp);
    window.removeEventListener(EVENT.RESIZE, this.onResize, false);
    window.removeEventListener(EVENT.UNLOAD, this.onUnload, false);
    window.removeEventListener(EVENT.BLUR, this.onBlur, false);
    this.excalidrawContainerRef.current?.removeEventListener(
      EVENT.DRAG_OVER,
      this.disableEvent,
      false,
    );
    this.excalidrawContainerRef.current?.removeEventListener(
      EVENT.DROP,
      this.disableEvent,
      false,
    );

    document.removeEventListener(
      EVENT.GESTURE_START,
      this.onGestureStart as any,
      false,
    );
    document.removeEventListener(
      EVENT.GESTURE_CHANGE,
      this.onGestureChange as any,
      false,
    );
    document.removeEventListener(
      EVENT.GESTURE_END,
      this.onGestureEnd as any,
      false,
    );

    this.detachIsMobileMqHandler?.();
  }

  private addEventListeners() {
    this.removeEventListeners();
    document.addEventListener(EVENT.POINTER_UP, this.removePointer); // #3553
    document.addEventListener(EVENT.COPY, this.onCopy);
    this.excalidrawContainerRef.current?.addEventListener(
      EVENT.WHEEL,
      this.onWheel,
      { passive: false },
    );

    if (this.props.handleKeyboardGlobally) {
      document.addEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    }
    document.addEventListener(EVENT.KEYUP, this.onKeyUp, { passive: true });
    document.addEventListener(
      EVENT.MOUSE_MOVE,
      this.updateCurrentCursorPosition,
    );
    // rerender text elements on font load to fix #637 && #1553
    document.fonts?.addEventListener?.("loadingdone", (event) => {
      const loadedFontFaces = (event as FontFaceSetLoadEvent).fontfaces;
      this.fonts.onFontsLoaded(loadedFontFaces);
    });

    // Safari-only desktop pinch zoom
    document.addEventListener(
      EVENT.GESTURE_START,
      this.onGestureStart as any,
      false,
    );
    document.addEventListener(
      EVENT.GESTURE_CHANGE,
      this.onGestureChange as any,
      false,
    );
    document.addEventListener(
      EVENT.GESTURE_END,
      this.onGestureEnd as any,
      false,
    );
    window.addEventListener(EVENT.RESIZE, this.onResize, false); // CHANGED:UPDATE 2023-3-6 #738
    if (this.state.viewModeEnabled) {
      return;
    }

    document.addEventListener(EVENT.PASTE, this.pasteFromClipboard);
    document.addEventListener(EVENT.CUT, this.onCut);
    if (this.props.detectScroll) {
      this.nearestScrollableContainer = getNearestScrollableContainer(
        this.excalidrawContainerRef.current!,
      );
      this.nearestScrollableContainer.addEventListener(
        EVENT.SCROLL,
        this.onScroll,
      );
    }
    // window.addEventListener(EVENT.RESIZE, this.onResize, false);
    window.addEventListener(EVENT.UNLOAD, this.onUnload, false);
    window.addEventListener(EVENT.BLUR, this.onBlur, false);
    this.excalidrawContainerRef.current?.addEventListener(
      EVENT.DRAG_OVER,
      this.disableEvent,
      false,
    );
    this.excalidrawContainerRef.current?.addEventListener(
      EVENT.DROP,
      this.disableEvent,
      false,
    );
  }

  componentDidUpdate(prevProps: AppProps, prevState: AppState) {
    const elements = this.scene.getElementsIncludingDeleted();
    const elementsMap = this.scene.getElementsMapIncludingDeleted();
    const nonDeletedElementsMap = this.scene.getNonDeletedElementsMap();

    if (
      !this.state.showWelcomeScreen && !elements.length
    ) {
      this.setState({ showWelcomeScreen: true });
    }

    if (
      this.excalidrawContainerRef.current &&
      prevProps.UIOptions.dockedSidebarBreakpoint !==
        this.props.UIOptions.dockedSidebarBreakpoint
    ) {
      this.refreshDeviceState(this.excalidrawContainerRef.current);
    }

    if (
      prevState.scrollX !== this.state.scrollX ||
      prevState.scrollY !== this.state.scrollY
    ) {
      this.props?.onScrollChange?.(this.state.scrollX, this.state.scrollY);
    }

    if (
      Object.keys(this.state.selectedElementIds).length &&
      isEraserActive(this.state)
    ) {
      this.setState({
        activeTool: updateActiveTool(this.state, { type: "selection" }),
      });
    }
    if (
      this.state.activeTool.type === "eraser" &&
      prevState.theme !== this.state.theme
    ) {
      setEraserCursor(this.interactiveCanvas, this.state.theme);
    }
    // Hide hyperlink popup if shown when element type is not selection
    if (
      prevState.activeTool.type === "selection" &&
      this.state.activeTool.type !== "selection" &&
      this.state.showHyperlinkPopup
    ) {
      this.setState({ showHyperlinkPopup: false });
    }
    if (prevProps.langCode !== this.props.langCode) {
      this.updateLanguage();
    }

    if (isEraserActive(prevState) && !isEraserActive(this.state)) {
      this.eraserTrail.endPath();
    }

    if (prevProps.viewModeEnabled !== this.props.viewModeEnabled) {
      this.setState({ viewModeEnabled: !!this.props.viewModeEnabled });
    }

    if (prevState.viewModeEnabled !== this.state.viewModeEnabled) {
      this.addEventListeners();
      this.deselectElements();
    }

    if (prevProps.zenModeEnabled !== this.props.zenModeEnabled) {
      this.setState({ zenModeEnabled: !!this.props.zenModeEnabled });
    }

    if (prevProps.theme !== this.props.theme && this.props.theme) {
      this.setState({ theme: this.props.theme });
    }

    if (prevProps.gridModeEnabled !== this.props.gridModeEnabled) {
      this.setState({
        gridSize: this.props.gridModeEnabled ? GRID_SIZE : null,
      });
    }

    if (this.props.name && prevProps.name !== this.props.name) {
      this.setState({
        name: this.props.name,
      });
    }

    this.excalidrawContainerRef.current?.classList.toggle(
      "theme--dark",
      this.state.theme === "dark",
    );

    if (
      this.state.editingLinearElement &&
      !this.state.selectedElementIds[this.state.editingLinearElement.elementId]
    ) {
      // defer so that the commitToHistory flag isn't reset via current update
      setTimeout(() => {
        // execute only if the condition still holds when the deferred callback
        // executes (it can be scheduled multiple times depending on how
        // many times the component renders)
        this.state.editingLinearElement &&
          this.actionManager.executeAction(actionFinalize);
      });
    }

    if (
      this.state.selectedLinearElement &&
      !this.state.selectedElementIds[this.state.selectedLinearElement.elementId]
    ) {
      // To make sure `selectedLinearElement` is in sync with `selectedElementIds`, however this shouldn't be needed once
      // we have a single API to update `selectedElementIds`
      this.setState({ selectedLinearElement: null });
    }

    // CHANGED:ADD 2022-10-28 #14
    if (
      this.state.selectedTaskElement &&
      !this.state.selectedElementIds[this.state.selectedTaskElement.elementId]
    ) {
      // To make sure `selectedTaskElement` is in sync with `selectedElementIds`, however this shouldn't be needed once
      // we have a single API to update `selectedElementIds`
      this.setState({ selectedTaskElement: null });
    }

    // CHANGED:ADD 2022-11-2 #64
    if (
      this.state.selectedLinkElement &&
      !this.state.selectedElementIds[this.state.selectedLinkElement.elementId]
    ) {
      // To make sure `selectedLinkElement` is in sync with `selectedElementIds`, however this shouldn't be needed once
      // we have a single API to update `selectedElementIds`
      this.setState({ selectedLinkElement: null });
    }

    const { multiElement } = prevState;
    if (
      prevState.activeTool !== this.state.activeTool &&
      multiElement != null &&
      isBindingEnabled(this.state) &&
      isBindingElement(multiElement, false)
    ) {
      maybeBindLinearElement(
        multiElement,
        this.state,
        tupleToCoors(
          LinearElementEditor.getPointAtIndexGlobalCoordinates(
            multiElement,
            -1,
            nonDeletedElementsMap,
          ),
        ),
        this,
      );
    }
    this.history.record(this.state, elements);

    // Do not notify consumers if we're still loading the scene. Among other
    // potential issues, this fixes a case where the tab isn't focused during
    // init, which would trigger onChange with empty elements, which would then
    // override whatever is in localStorage currently.
    if (!this.state.isLoading) {
      this.props.onChange?.(
        this.scene.getElementsIncludingDeleted(),
        this.state,
        this.files,
      );
    }

    //CHANGED: UPDATE 2023-1-12 #406
    if (
      this.state.zoom.value !== prevState.zoom.value ||
      this.state.calendarWidth !== prevState.calendarWidth ||
      this.state.jobsHeight !== prevState.jobsHeight
    ) {
      this.scroll.updateScrollLimit({
        newScrollLimitX:
          this.state.calendarWidth -
          ((window.innerWidth - CANVAS_MARGIN_RIGHT) / this.state.zoom.value - JOB_ELEMENTS_WIDTH),
        newScrollLimitY:
          this.state.jobsHeight -
          (window.innerHeight - CANVAS_MARGIN_BOTTOM) / this.state.zoom.value,
      });
    }

    // CHANGED:ADD 2023-03-01 #738 モバイルでviewModeEnable = trueに。
    if ((this.device.isMobile || this.state.projectRole === ProjectRole.viewer) && !this.state.viewModeEnabled) {
      this.setState({ viewModeEnabled: true });
    } else if ((!this.device.isMobile && this.state.projectRole !== ProjectRole.viewer) && this.state.viewModeEnabled) {
      this.setState({ viewModeEnabled: false });
    }

    // CHANGED:ADD 2023-1-26 #510 > CHANGE:REMOVE 2023/02/14 #676
    // if (this.state.projectStartDate !== prevState.projectStartDate) {
    //   const prevBaseDate = new Date(this.calendar.baseDate);
    //   const baseDate = new Date(this.state.projectStartDate);

    //   const diffDay = (prevBaseDate.getTime() - baseDate.getTime()) / (1000 * 3600 * 24);
    //   if (diffDay !== 0) {
    //     this.calendar.baseDate = baseDate;

    //     const gridSize = this.state.gridSize ? this.state.gridSize : GRID_SIZE;
    //     const offsetX = diffDay * gridSize;

    //     this.scene.getElementsIncludingDeleted().forEach((element) => {
    //       if (isGraphElement(element)) {
    //         mutateElement(element, {
    //           x: element.x + offsetX,
    //         });
    //       }
    //     });
    //   }
    // }

    // CHANGED:ADD 2023-1-6 #381 > CHANGE:REMOVE 2023/02/14 #676
    // if (this.state.holidays !== prevState.holidays) {
    //   if (
    //     JSON.stringify(this.state.holidays) !== JSON.stringify(this.calendar.hd)
    //   ) {
    //     this.calendar.hd = this.state.holidays;

    //     // CHANGED:ADD 2023-1-6 #404 
    //     this.scene.getElementsIncludingDeleted().forEach((element) => {
    //       // CHANGED:UPDATE 2023/01/20 #390
    //       if (isTaskElement(element) || isLinkElement(element)) {
    //         let newDuration = 0;
    //         if (isTaskElement(element)) {
    //           newDuration = this.calendar.getDuration(
    //             element.startDate,
    //             element.endDate,
    //             element.holidays, // CHANGED:ADD 2023-1-20 #382
    //           );
    //         } else if (isLinkElement(element)) {
    //           newDuration = this.calendar.getDuration(
    //             new Date(this.calendar.getPointDate(element.x)),
    //             new Date(
    //               this.calendar.getPointDate(element.x + element.width),
    //             ),
    //           );
    //         }

    //         if (element.duration !== newDuration) {
    //           mutateElement(element, {
    //             duration: newDuration,
    //           });

    //           const boundTextElement = getBoundTextElement(element);
    //           if (boundTextElement) {
    //             const text = element.duration > 0
    //                 ? `${boundTextElement.originalText}(${element.duration})`
    //                 : boundTextElement.originalText;

    //             this.scene.updateBoundTextElement(
    //               boundTextElement,
    //               text,
    //               boundTextElement.originalText,
    //               boundTextElement.isDeleted,
    //             );
    //           }
    //         }
    //       }
    //     });

    //     this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455
    //   }
    // }
  }

  private renderInteractiveSceneCallback = ({
    atLeastOneVisibleElement,
    scrollBars,
    elementsMap,
  }: RenderInteractiveSceneCallback) => {
    if (scrollBars) {
      currentScrollBars = scrollBars;
    }

    // CHANGED:ADD 2023-2-24 #744
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const projectStartDate = new Date(this.state.projectStartDate);
    const projectEndDate = new Date(this.state.projectEndDate);

    const scrolledOutside = !this.state.isLoading
      // hide when editing text
      ? isTextElement(this.state.editingElement)
        ? false
        // CHANGED:UPDATE 2023-2-24 #744
        // : !atLeastOneVisibleElement && renderingElements.length > 0;
        : !atLeastOneVisibleElement && elementsMap.size > 0 &&
        projectStartDate.getTime() <= currentDate.getTime() &&
        projectEndDate.getTime() >= currentDate.getTime()
      : false;

    if (this.state.scrolledOutside !== scrolledOutside) {
      this.setState({ scrolledOutside });
    }

    this.scheduleImageRefresh();
  };

  private onScroll = debounce(() => {
    const { offsetTop, offsetLeft } = this.getCanvasOffsets();
    this.setState((state) => {
      if (state.offsetLeft === offsetLeft && state.offsetTop === offsetTop) {
        return null;
      }
      return { offsetTop, offsetLeft };
    });
  }, SCROLL_TIMEOUT);

  // Copy/paste

  private onCut = withBatchedUpdates((event: ClipboardEvent) => {
    const isExcalidrawActive = this.excalidrawContainerRef.current?.contains(
      document.activeElement,
    );
    if (!isExcalidrawActive || isWritableElement(event.target)) {
      return;
    }
    this.cutAll();
    event.preventDefault();
    event.stopPropagation();
  });

  private onCopy = withBatchedUpdates((event: ClipboardEvent) => {
    const isExcalidrawActive = this.excalidrawContainerRef.current?.contains(
      document.activeElement,
    );
    if (!isExcalidrawActive || isWritableElement(event.target)) {
      return;
    }
    this.copyAll();
    event.preventDefault();
    event.stopPropagation();
  });

  private cutAll = () => {
    this.actionManager.executeAction(actionCut, "keyboard");
  };

  private copyAll = () => {
    this.actionManager.executeAction(actionCopy, "keyboard");
  };

  private static resetTapTwice() {
    didTapTwice = false;
  }

  private onTouchStart = (event: TouchEvent) => {
    // fix for Apple Pencil Scribble
    // On Android, preventing the event would disable contextMenu on tap-hold
    if (!isAndroid) {
      event.preventDefault();
    }

    if (!didTapTwice) {
      didTapTwice = true;
      clearTimeout(tappedTwiceTimer);
      tappedTwiceTimer = window.setTimeout(
        App.resetTapTwice,
        TAP_TWICE_TIMEOUT,
      );
      return;
    }
    // insert text only if we tapped twice with a single finger
    // event.touches.length === 1 will also prevent inserting text when user's zooming
    if (didTapTwice && event.touches.length === 1) {
      const touch = event.touches[0];
      // @ts-ignore
      this.handleCanvasDoubleClick({
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      didTapTwice = false;
      clearTimeout(tappedTwiceTimer);
    }
    if (isAndroid) {
      event.preventDefault();
    }

    if (event.touches.length === 2) {
      this.setState({
        selectedElementIds: {},
      });
    }
  };

  private onTouchEnd = (event: TouchEvent) => {
    this.resetContextMenuTimer();
    if (event.touches.length > 0) {
      this.setState({
        previousSelectedElementIds: {},
        selectedElementIds: this.state.previousSelectedElementIds,
      });
    } else {
      gesture.pointers.clear();
    }
  };

  public pasteFromClipboard = withBatchedUpdates(
    async (event: ClipboardEvent | null) => {
      const isPlainPaste = !!(IS_PLAIN_PASTE && event);

      // #686
      const target = document.activeElement;
      const isExcalidrawActive =
        this.excalidrawContainerRef.current?.contains(target);
      if (event && !isExcalidrawActive) {
        return;
      }

      const elementUnderCursor = document.elementFromPoint(cursorX, cursorY);
      if (
        event &&
        (!(elementUnderCursor instanceof HTMLCanvasElement) ||
          isWritableElement(target))
      ) {
        return;
      }

      // must be called in the same frame (thus before any awaits) as the paste
      // event else some browsers (FF...) will clear the clipboardData
      // (something something security)
      let file = event?.clipboardData?.files[0];

      const data = await parseClipboard(event, isPlainPaste);

      if (!file && data.text && !isPlainPaste) {
        const string = data.text.trim();
        if (string.startsWith("<svg") && string.endsWith("</svg>")) {
          // ignore SVG validation/normalization which will be done during image
          // initialization
          file = SVGStringToFile(string);
        }
      }

      // prefer spreadsheet data over image file (MS Office/Libre Office)
      if (isSupportedImageFile(file) && !data.spreadsheet) {
        const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
          { clientX: cursorX, clientY: cursorY },
          this.state,
        );

        const imageElement = this.createImageElement({ sceneX, sceneY });
        this.insertImageElement(imageElement, file);
        this.initializeImageDimensions(imageElement);
        this.setState({ selectedElementIds: { [imageElement.id]: true } });

        return;
      }

      if (this.props.onPaste) {
        try {
          if ((await this.props.onPaste(data, event)) === false) {
            return;
          }
        } catch (error: any) {
          console.error(error);
        }
      }

      if (data.errorMessage) {
        this.setState({ errorMessage: data.errorMessage });
      } else if (data.spreadsheet && !isPlainPaste) {
        this.setState({
          pasteDialog: {
            data: data.spreadsheet,
            shown: true,
          },
        });
      } else if (data.elements) {
        // TODO remove formatting from elements if isPlainPaste
        this.addElementsFromPasteOrLibrary({
          elements: data.elements,
          files: data.files || null,
          position: "cursor",
          taskChildren: data.taskChildren, // CHANGED:ADD 2024-5-22 #2058
        });
      } else if (data.text) {
        this.addTextFromPaste(data.text, isPlainPaste);
      }
      this.setActiveTool({ type: "selection" });
      event?.preventDefault();
    },
  );

  addElementsFromPasteOrLibrary = async (opts: {
    elements: readonly ExcalidrawElement[];
    files: BinaryFiles | null;
    position: { clientX: number; clientY: number } | "cursor" | "center";
    taskChildren?: TaskChildren; // CHANGED:ADD 2024/02/06 #1579
  }) => {
    const elements = restoreElements(
      opts.elements.filter(
        (element) =>
          !(
            isLinkElement(element) &&
            (!element.startBinding || !element.endBinding)
          ) && !isJobElement(element) && !isCommentElement(element), // CHANGED:ADD 2023-2-8 #607 2023-12-25 #1138
      ),
      null);
    const [minX, minY, maxX, maxY] = getCommonBounds(elements);
    const height = distance(minY, maxY);
    const width = distance(minX, maxX);

    if (this.state.jobsHeight < height) {
      //ライブラリから配置するエレメントの高さがキャンバス以上の時
      this.setToast({
        message: t("toast.warning.placingTooLargeElement"),
        duration: 2000,
      });
      return;
    }

    const elementsCenterX = width / 2;
    const elementsCenterY = height / 2;

    const clientX =
      typeof opts.position === "object"
        ? opts.position.clientX
        : opts.position === "cursor"
        ? cursorX
        : this.state.width / 2 + this.state.offsetLeft;
    const clientY =
      typeof opts.position === "object"
        ? opts.position.clientY
        : opts.position === "cursor"
        ? cursorY
        : this.state.height / 2 + this.state.offsetTop;

    let { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      this.state,
    );

    const coord = getClosestBoundaryIfExceeds({
      point: { x, y },
      state: { ...this.state },
      offset: { x: elementsCenterX, y: elementsCenterY },
    });

    x = coord.x;
    y = coord.y;

    const dx = x - elementsCenterX;
    const dy = y - elementsCenterY;

    if (Job.checkCompressedJobElementExistsInGivenRange(this.scene.getNonDeletedElements(), { minY: dy, maxY: y + elementsCenterY })) {
      // 圧縮されたJobの上にエレメントの配置時
      this.setToast({
        message: t("toast.warning.placementOverCompressedJob"),
        duration: 2000,
      });
      return;
    }
    
    const groupIdMap = new Map();

    // CHANGED:UPDATE 2022-12-7 #157
    const [gridX, gridY] = elements.every((element) =>
      isMilestoneElement(element),
    )
      ? getGridPointEx(dx, dy, this.state.gridSize, true)
      : getGridPoint(dx, dy, this.state.gridSize);

    // CHANGED:UPDATE 2023-04-24 #815
    // const newElements = duplicateElements(
    //   elements.map((element) => {
    //     return newElementWith(element, {
    //       x: element.x + gridX - minX,
    //       y: element.y + gridY - minY,
    //     });
    //   }),
    // );

    // const nextElements = [
    //   ...this.scene.getElementsIncludingDeleted(),
    //   ...newElements,
    // ];

    const oldIdToDuplicatedId = new Map();
    const newElements = elements.map((element) => {
      //CHANGED:ADD 2022-11-30 #217
      if (isTaskElement(element) || isLinkElement(element)) {
        // CHANGED:UPDATE 2023/01/20 #390
        const startDate = this.calendar.getPointDate(element.x + gridX - minX);
        const endDate = this.calendar.getPointDate(
          element.x + gridX - minX + element.width,
        );
        // CHANGED:ADD 2023/01/23 #390
        let duration = 0;
        if (isTaskElement(element)) {
          duration = this.calendar.getDuration(
            startDate,
            endDate,
            element.holidays, // CHANGED:ADD 2023-1-20 #382
          );
        } else if (isLinkElement(element)) {
          duration = this.calendar.getDuration(startDate, endDate);
        }

        // CHANGED:ADD 2023/02/10 #610
        const [x, y] = getGridPoint(
          element.x + gridX - minX,
          element.y + gridY - minY,
          this.state.gridSize,
        );
        const newElement = duplicateElement(
          this.state.editingGroupId,
          groupIdMap,
          element,
          {
            x, // CHANGED:UPDATE 2023/02/10 #610
            y, // CHANGED:UPDATE 2023/02/10 #610
            startDate,
            endDate,
            duration, // CHANGED:ADD 2022-12-8 #267
            layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          },
        );
        oldIdToDuplicatedId.set(element.id, newElement.id);
        return newElement;
      }
      // CHANGED:ADD 2022-12-7 #157
      else if (isMilestoneElement(element)) {

        // CHANGED:ADD 2023/02/10 #610
        const [x, y] = getGridPointEx(
          element.x + gridX - minX,
          element.y + gridY - minY,
          this.state.gridSize,
          true,
        );
        const newElement = duplicateElement(
          this.state.editingGroupId,
          groupIdMap,
          element,
          {
            x, // CHANGED:UPDATE 2023/02/10 #610
            y, // CHANGED:UPDATE 2023/02/10 #610
            date: this.calendar.getPointDate(
              element.x + element.width / 2 + gridX - minX,
            ),
            layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          },
        );
        oldIdToDuplicatedId.set(element.id, newElement.id);
        return newElement;
      } else {
        const newElement = duplicateElement(
          this.state.editingGroupId,
          groupIdMap,
          element,
          {
            x: element.x + gridX - minX,
            y: element.y + gridY - minY,
            layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          },
        );
        oldIdToDuplicatedId.set(element.id, newElement.id);
        return newElement;
      }
    });
    bindTextToShapeAfterDuplication(newElements, elements, oldIdToDuplicatedId);
    const nextElements = [
      ...this.scene.getElementsIncludingDeleted(),
      ...newElements,
    ];
    fixBindingsAfterDuplication(nextElements, elements, oldIdToDuplicatedId);
    fixBindingsAfterDuplicationEx(nextElements, elements, oldIdToDuplicatedId); // CHANGED:ADD 2022-10-28 #14

    this.scene.replaceAllElements(nextElements);

    newElements.forEach((newElement) => {
      if (isTextElement(newElement) && isBoundToContainer(newElement)) {
        const container = getContainerElement(
          newElement,
          this.scene.getNonDeletedElementsMap(),
        );
        redrawTextBoundingBox(
          newElement,
          container,
          this.scene.getElementsMapIncludingDeleted(),
        );
      }
    });

    if (opts.files) {
      this.files = { ...this.files, ...opts.files };
    }

    // CHANGED:ADD 2023-2-11 #671
    const milestoneElements = Milestone.getMilestoneElements(newElements);
    const milestoneLineElements = generateMilestoneLineElements(
      milestoneElements,
      this.state.jobsHeight,
    );
    this.scene.GenerateMilestoneLineElements([
      ...this.scene.milestoneLineElements,
      ...milestoneLineElements,
    ]);

    // CHANGED:ADD 2024-02-09 #1579
    const newAssignUsers: ExcalidrawAssignUsers[] =
      opts.taskChildren?.assignUsers?.map((assignUser) => ({
        ...assignUser,
        taskId: oldIdToDuplicatedId.get(assignUser.taskId)
      })) || [];

    const newAssignResources: ExcalidrawAssignResources[] =
      opts.taskChildren?.assignResources?.map((assignResource) => ({
        ...assignResource,
        taskId: oldIdToDuplicatedId.get(assignResource.taskId)
      })) || [];

    const newTags: ExcalidrawTags[] =
      opts.taskChildren?.tags?.map((tag) => ({
        ...tag,
        taskId: oldIdToDuplicatedId.get(tag.taskId)
      })) || [];

    const newChecklists: ExcalidrawChecklist[] =
      opts.taskChildren?.checklists?.map((checklist) => ({
        ...checklist,
        taskId: oldIdToDuplicatedId.get(checklist.taskId)
      })) || [];

    await savePasteLibraryElements(
      newElements,
      arrayToMap(newElements),
      this.state,
      {
        assignUsers: newAssignUsers,
        assignResources: newAssignResources,
        tags: newTags,
        checklists: newChecklists,
      },
    );

    this.history.resumeRecording();
    this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455

    this.setState(
      {
        ...this.state,
        // keep sidebar (presumably the library) open if it's docked and
        // can fit.
        //
        // Note, we should close the sidebar only if we're dropping items
        // from library, not when pasting from clipboard. Alas.
        openSidebar:
          this.state.openSidebar &&
            this.device.canDeviceFitSidebar &&
            this.state.isSidebarDocked
            ? this.state.openSidebar
            : null,
        ...selectGroupsForSelectedElements(
          {
            editingGroupId: null,
            selectedElementIds: newElements.reduce(
              (acc: Record<ExcalidrawElement["id"], true>, element) => {
                if (!isBoundToContainer(element)) {
                  acc[element.id] = true;
                }
                return acc;
              },
              {},
            ),
          },
          this.scene.getNonDeletedElements(),
          this.state,
          this,
        ),
      },
      () => {
        if (opts.files) {
          this.addNewImagesToImageCache();
        }
      },
    );
    this.setActiveTool({ type: "selection" });
  };

  private addTextFromPaste(text: string, isPlainPaste = false) {
    const { x, y } = viewportCoordsToSceneCoords(
      { clientX: cursorX, clientY: cursorY },
      this.state,
    );

    const textElementProps = {
      x,
      y,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roundness: null,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      text,
      fontSize: this.state.currentItemFontSize,
      fontFamily: this.state.currentItemFontFamily,
      textAlign: this.state.currentItemTextAlign,
      verticalAlign: DEFAULT_VERTICAL_ALIGN,
      locked: false,
    };

    const LINE_GAP = 10;
    let currentY = y;

    const lines = isPlainPaste ? [text] : text.split("\n");
    const textElements = lines.reduce(
      (acc: ExcalidrawTextElement[], line, idx) => {
        const text = line.trim();

        const lineHeight = getDefaultLineHeight(textElementProps.fontFamily);
        if (text.length) {
          const element = newTextElement({
            ...textElementProps,
            x,
            y: currentY,
            text,
            lineHeight,
            priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
            layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          });
          acc.push(element);
          currentY += element.height + LINE_GAP;
        } else {
          const prevLine = lines[idx - 1]?.trim();
          // add paragraph only if previous line was not empty, IOW don't add
          // more than one empty line
          if (prevLine) {
            currentY +=
              getLineHeightInPx(textElementProps.fontSize, lineHeight) +
              LINE_GAP;
          }
        }

        return acc;
      },
      [],
    );

    if (textElements.length === 0) {
      return;
    }

    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      ...textElements,
    ]);

    this.setState({
      selectedElementIds: Object.fromEntries(
        textElements.map((el) => [el.id, true]),
      ),
    });

    if (
      !isPlainPaste &&
      textElements.length > 1 &&
      PLAIN_PASTE_TOAST_SHOWN === false &&
      !this.device.isMobile
    ) {
      this.setToast({
        message: t("toast.pasteAsSingleElement", {
          shortcut: getShortcutKey("CtrlOrCmd+Shift+V"),
        }),
        duration: 5000,
      });
      PLAIN_PASTE_TOAST_SHOWN = true;
    }

    this.history.resumeRecording();
  }

  setAppState: React.Component<any, AppState>["setState"] = (
    state,
    callback,
  ) => {
    this.setState(state, callback);
  };

  removePointer = (event: React.PointerEvent<HTMLElement> | PointerEvent) => {
    if (touchTimeout) {
      this.resetContextMenuTimer();
    }

    gesture.pointers.delete(event.pointerId);
  };

  toggleLock = (source: "keyboard" | "ui" = "ui") => {
    if (!this.state.activeTool.locked) {
      trackEvent(
        "toolbar",
        "toggleLock",
        `${source} (${this.device.isMobile ? "mobile" : "desktop"})`,
      );
    }
    this.setState((prevState) => {
      return {
        activeTool: {
          ...prevState.activeTool,
          ...updateActiveTool(
            this.state,
            prevState.activeTool.locked
              ? { type: "selection" }
              : prevState.activeTool,
          ),
          locked: !prevState.activeTool.locked,
        },
      };
    });
  };

  togglePenMode = (force: boolean | null) => {
    this.setState((prevState) => {
      return {
        penMode: force ?? !prevState.penMode,
        penDetected: true,
      };
    });
  };

  onHandToolToggle = () => {
    this.actionManager.executeAction(actionToggleHandTool);
  };

  /**
   * Zooms on canvas viewport center
   */
  zoomCanvas = (
    /** decimal fraction between 0.1 (10% zoom) and 30 (3000% zoom) */
    value: number,
  ) => {
    this.setState({
      ...getStateForZoom(
        {
          viewportX: this.state.width / 2 + this.state.offsetLeft,
          viewportY: this.state.height / 2 + this.state.offsetTop,
          nextZoom: getNormalizedZoom(value),
        },
        this.state,
      ),
    });
  };

  private cancelInProgresAnimation: (() => void) | null = null;

  scrollToContent = (
    target:
      | ExcalidrawElement
      | readonly ExcalidrawElement[] = this.scene.getNonDeletedElements(),
    opts?: { fitToContent?: boolean; animate?: boolean; duration?: number },
  ) => {
    this.cancelInProgresAnimation?.();

    // convert provided target into ExcalidrawElement[] if necessary
    const targets = Array.isArray(target) ? target : [target];

    let zoom = this.state.zoom;
    let scrollX = this.state.scrollX;
    let scrollY = this.state.scrollY;

    if (opts?.fitToContent) {
      // compute an appropriate viewport location (scroll X, Y) and zoom level
      // that fit the target elements on the scene
      const { appState } = zoomToFitElements(targets, this.state, false);
      zoom = appState.zoom;
      scrollX = appState.scrollX;
      scrollY = appState.scrollY;
    } else {
      // compute only the viewport location, without any zoom adjustment
      const scroll = calculateScrollCenter(targets, this.state);
      scrollX = scroll.scrollX;
      scrollY = scroll.scrollY;
    }

    // when animating, we use RequestAnimationFrame to prevent the animation
    // from slowing down other processes
    if (opts?.animate) {
      const origScrollX = this.state.scrollX;
      const origScrollY = this.state.scrollY;

      // zoom animation could become problematic on scenes with large number
      // of elements, setting it to its final value to improve user experience.
      //
      // using zoomCanvas() to zoom on current viewport center
      this.zoomCanvas(zoom.value);

      const cancel = easeToValuesRAF(
        [origScrollX, origScrollY],
        [scrollX, scrollY],
        (scrollX, scrollY) => this.setState({ scrollX, scrollY }),
        { duration: opts?.duration ?? 500 },
      );
      this.cancelInProgresAnimation = () => {
        cancel();
        this.cancelInProgresAnimation = null;
      };
    } else {
      this.setState({ scrollX, scrollY, zoom });
    }
  };

  /** use when changing scrollX/scrollY/zoom based on user interaction */
  private translateCanvas: React.Component<any, AppState>["setState"] = (
    state,
  ) => {
    this.cancelInProgresAnimation?.();
    this.setState(state);
  };

  setToast = (
    toast: {
      message: string;
      closable?: boolean;
      duration?: number;
    } | null,
  ) => {
    this.setState({ toast });
  };

  restoreFileFromShare = async () => {
    try {
      const webShareTargetCache = await caches.open("web-share-target");

      const response = await webShareTargetCache.match("shared-file");
      if (response) {
        const blob = await response.blob();
        const file = new File([blob], blob.name || "", { type: blob.type });
        this.loadFileToCanvas(file, null);
        await webShareTargetCache.delete("shared-file");
        window.history.replaceState(null, APP_NAME, window.location.pathname);
      }
    } catch (error: any) {
      this.setState({ errorMessage: error.message });
    }
  };

  /** adds supplied files to existing files in the appState */
  public addFiles: ExcalidrawImperativeAPI["addFiles"] = withBatchedUpdates(
    (files) => {
      const filesMap = files.reduce((acc, fileData) => {
        acc.set(fileData.id, fileData);
        return acc;
      }, new Map<FileId, BinaryFileData>());

      this.files = { ...this.files, ...Object.fromEntries(filesMap) };

      this.scene.getNonDeletedElements().forEach((element) => {
        if (
          isInitializedImageElement(element) &&
          filesMap.has(element.fileId)
        ) {
          this.imageCache.delete(element.fileId);
          ShapeCache.delete(element);
        }
      });
      this.scene.informMutation();

      this.addNewImagesToImageCache();
    },
  );

  public updateScene = withBatchedUpdates(
    <K extends keyof AppState>(sceneData: {
      elements?: SceneData["elements"];
      appState?: Pick<AppState, K> | null;
      collaborators?: SceneData["collaborators"];
      commitToHistory?: SceneData["commitToHistory"];
      replaceScene?: boolean; // CHANGED:ADD 2023-1-16 #425
    }) => {
      if (sceneData.commitToHistory) {
        this.history.resumeRecording();
      }

      if (sceneData.appState) {
        // CHANGED:ADD 2023-1-16 #425
        if (sceneData.replaceScene) {
          const appState: AppState = sceneData.appState as AppState;
          const projectStartDate = new Date(appState.projectStartDate);
          const projectEndDate = new Date(appState.projectEndDate);

          // CHANGED:ADD 2023-01-21 #391
          const jobElements = Job.getJobElements(sceneData.elements || []);

          // CHANGED: ADD 2023-2-11 #671
          const milestoneElements = Milestone.getMilestoneElements(
            sceneData.elements || [],
          );

          const {
            jobBackgroundElements,
            jobPanelElements,
            jobLineElements, // CHANGED:ADD 2023-03-01 #726
            jobsHeight,
            milestoneLineElements,
            calendarWidth,
          } = generateBackgroundElements(
            projectStartDate,
            projectEndDate,
            jobElements,
            milestoneElements,
            appState.viewBackgroundColor,
            appState.gridSize ? appState.gridSize : GRID_SIZE,
            appState.holidays,
          );

          this.scene.jobBackgroundElements = jobBackgroundElements;
          this.scene.jobPanelElements = jobPanelElements;
          this.scene.jobLineElements = jobLineElements; // CHANGED:ADD 2023-03-01 #726
          this.scene.milestoneLineElements = milestoneLineElements;

          this.scroll.updateScrollLimit({
            newScrollLimitX:
              calendarWidth -
              ((window.innerWidth - CANVAS_MARGIN_RIGHT) / appState.zoom.value - JOB_ELEMENTS_WIDTH),
            newScrollLimitY:
              jobsHeight -
              (window.innerHeight - CANVAS_MARGIN_BOTTOM) / appState.zoom.value,
          });

          this.calendar.baseDate = projectStartDate;
          this.calendar.hd = appState.holidays;

          sceneData.appState = {
            ...sceneData.appState,
            calendarWidth,
            jobsHeight,
          };
        }

        this.setState(sceneData.appState);
      }

      if (sceneData.elements) {
        this.scene.replaceAllElements(sceneData.elements);
      }

      if (sceneData.collaborators) {
        this.setState({ collaborators: sceneData.collaborators });
      }
    },
  );

  private onSceneUpdated = () => {
    this.setState({});
  };

  /**
   * @returns whether the menu was toggled on or off
   */
  public toggleMenu = (
    type: "library" | "customSidebar",
    force?: boolean,
  ): boolean => {
    if (type === "customSidebar" && !this.props.renderSidebar) {
      console.warn(
        `attempting to toggle "customSidebar", but no "props.renderSidebar" is defined`,
      );
      return false;
    }

    if (type === "library" || type === "customSidebar") {
      let nextValue;
      if (force === undefined) {
        nextValue = this.state.openSidebar === type ? null : type;
      } else {
        nextValue = force ? type : null;
      }
      this.setState({ openSidebar: nextValue });

      return !!nextValue;
    }

    return false;
  };

  private updateCurrentCursorPosition = withBatchedUpdates(
    (event: MouseEvent) => {
      cursorX = event.clientX;
      cursorY = event.clientY;
    },
  );

  // Input handling
  private onKeyDown = withBatchedUpdates(
    (event: React.KeyboardEvent | KeyboardEvent) => {
      // normalize `event.key` when CapsLock is pressed #2372
      if (
        "Proxy" in window &&
        ((!event.shiftKey && /^[A-Z]$/.test(event.key)) ||
          (event.shiftKey && /^[a-z]$/.test(event.key)))
      ) {
        event = new Proxy(event, {
          get(ev: any, prop) {
            const value = ev[prop];
            if (typeof value === "function") {
              // fix for Proxies hijacking `this`
              return value.bind(ev);
            }
            return prop === "key"
              ? // CapsLock inverts capitalization based on ShiftKey, so invert
                // it back
                event.shiftKey
                ? ev.key.toUpperCase()
                : ev.key.toLowerCase()
              : value;
          },
        });
      }

      // CHANGED:ADD 2024-04-04 #1889
      if (
        this.state.openDialog !== null ||
        (this.state.openSidebar !== null && this.state.openSidebar !== "customSidebar")
      ) {
        return;
      }

      if (event[KEYS.CTRL_OR_CMD] && event.key.toLowerCase() === KEYS.V) {
        IS_PLAIN_PASTE = event.shiftKey;
        clearTimeout(IS_PLAIN_PASTE_TIMER);
        // reset (100ms to be safe that we it runs after the ensuing
        // paste event). Though, technically unnecessary to reset since we
        // (re)set the flag before each paste event.
        IS_PLAIN_PASTE_TIMER = window.setTimeout(() => {
          IS_PLAIN_PASTE = false;
        }, 100);
      }

      // prevent browser zoom in input fields
      if (event[KEYS.CTRL_OR_CMD] && isWritableElement(event.target)) {
        if (event.code === CODES.MINUS || event.code === CODES.EQUAL) {
          event.preventDefault();
          return;
        }
      }

      // bail if
      if (
        // inside an input
        (isWritableElement(event.target) &&
          // unless pressing escape (finalize action)
          event.key !== KEYS.ESCAPE) ||
        // or unless using arrows (to move between buttons)
        (isArrowKey(event.key) && isInputLike(event.target))
      ) {
        return;
      }

      if (event.key === KEYS.QUESTION_MARK) {
        this.setState({
          openDialog: "help",
        });
        return;
      } else if (
        event.key.toLowerCase() === KEYS.E &&
        event.shiftKey &&
        event[KEYS.CTRL_OR_CMD]
      ) {
        this.setState({ openDialog: "imageExport" });
        return;
      } else if (
        event.key.toLowerCase() === KEYS.P &&
        event[KEYS.CTRL_OR_CMD]
      ) {
        event.preventDefault();
        this.setState({ openDialog: "printExport" });
        return;
      }

      if (event.key === KEYS.PAGE_UP || event.key === KEYS.PAGE_DOWN) {
        let offset =
          (event.shiftKey ? this.state.width : this.state.height) /
          this.state.zoom.value;
        if (event.key === KEYS.PAGE_DOWN) {
          offset = -offset;
        }
        if (event.shiftKey) {
          this.translateCanvas((state) => ({
            scrollX: state.scrollX + offset,
          }));
        } else {
          this.translateCanvas((state) => ({
            scrollY: state.scrollY + offset,
          }));
        }
      }

      if (this.actionManager.handleKeyDown(event)) {
        return;
      }

      if (this.state.viewModeEnabled) {
        return;
      }

      if (event[KEYS.CTRL_OR_CMD] && this.state.isBindingEnabled) {
        this.setState({ isBindingEnabled: false });
      }

      // CHANGED:REMOVE 2023-1-20 #450
      // if (event[KEYS.CTRL_OR_CMD] && this.state.isBindingEnabledEx) {
      //   this.setState({ isBindingEnabledEx: false });
      // }
      if (isArrowKey(event.key)) {
        const selectedElements = this.scene.getSelectedElements({
          selectedElementIds: this.state.selectedElementIds,
          includeBoundTextElement: true,
        }).filter((element) => !isLinkElement(element) && !isCommentElement(element)); // CHANGED:ADD 2022-11-26 #205 CHANGED:ADD 2024-01-25 #1138-3

        const step =
          (this.state.gridSize &&
            (event.shiftKey
              && !selectedElements.some((element) => isNodeElement(element)) // CHANGED:ADD 2022-12-12 #296
              ? ELEMENT_TRANSLATE_AMOUNT
              : this.state.gridSize)) ||
          (event.shiftKey
            ? ELEMENT_SHIFT_TRANSLATE_AMOUNT
            : ELEMENT_TRANSLATE_AMOUNT);

        let offsetX = 0;
        let offsetY = 0;

        if (event.key === KEYS.ARROW_LEFT) {
          offsetX = -step;
        } else if (event.key === KEYS.ARROW_RIGHT) {
          offsetX = step;
        } else if (event.key === KEYS.ARROW_UP) {
          offsetY = -step;
        } else if (event.key === KEYS.ARROW_DOWN) {
          offsetY = step;
        }

        // CHANGED:UPDATE 2022-12-19 #347
        const offset = getMovableSelectedElements(
          selectedElements,
          this.scene.getElementsMapIncludingDeleted(),
          { x: offsetX, y: offsetY },
          this.state,
          true, // CHANGED:ADD 2022-12-28 #397
        );

        // CHANGED:UPDATE 2022-11-25 #183
        // selectedElements.forEach((element) => {
        //   mutateElement(element, {
        //     x: element.x + offsetX,
        //     y: element.y + offsetY,
        //   });
        selectedElements.forEach((element) => {
          if (isTaskElement(element)) {
            const startDate = this.calendar.getPointDate(element.x + offset.x);
            const endDate = this.calendar.getPointDate(element.x + offset.x + element.width);
            // CHANGED:ADD 2022-12-8 #267
            const duration = this.calendar.getDuration(
              startDate,
              endDate,
              element.holidays, // CHANGED:ADD 2023-1-20 #382
            );

            // CHANGED:ADD 2023-03-21 #740
            let isMovableY = true;
            if (element.isVisible) {
              const job = Job.getJobElementFromGivenGridY(
                element.y + offset.y,
                this.scene.getNonDeletedElements(),
              );
              if (job === undefined || job.isCompressed) {
                isMovableY = false;
              }
            }

            // CHANGED:UPDATE 2023/08/30 #967
            // mutateElement(element, {
            //   x: element.x + offset.x,
            //   y: isMovableY ? element.y + offset.y : element.y,
            //   startDate,
            //   endDate,
            //   duration, // CHANGED:ADD 2022-12-8 #267
            // });
            this.scene.replaceAllElements([
              ...this.scene.getElementsIncludingDeleted().map((_element) => {
                if (_element.id === element.id && isTaskElement(_element)) {
                  return newElementWith(_element, {
                    x: element.x + offset.x,
                    y: isMovableY ? element.y + offset.y : element.y,
                    startDate,
                    endDate,
                    duration, // CHANGED:ADD 2022-12-8 #267
                  });
                  // CHANGED: ADD 2024-03-08 #1138, #1741
                } else if (isCommentElement(_element) && _element.commentElementId === element.id) {
                  return newElementWith(_element, {
                    x: element.x + element.width + offset.x + COMMENT_OFFSET_X,
                    y: isMovableY ? element.y + offset.y + COMMENT_OFFSET_Y : element.y + COMMENT_OFFSET_Y,
                  });
                }
                return _element;
              }),
            ]);

            // CHANGED:ADD 2023-2-14 #696
            const textElement = getBoundTextElement(
              element,
              this.scene.getNonDeletedElementsMap(),
            );
            if (textElement) {
              this.scene.updateBoundTextElement(
                textElement,
                textElement.originalText, // CHANGED:UPDATE 2023-08-22 #924
                textElement.originalText,
                textElement.isDeleted,
              );
            }
            // CHANGED:ADD 2022-12-7 #157
          } else if (isMilestoneElement(element)) {
            // CHANGED:ADD 2023-03-21 #740
            let isMovableY = true;
            if (element.isVisible) {
              const job = Job.getJobElementFromGivenGridY(
                element.y + offset.y + element.height / 2,
                this.scene.getNonDeletedElements(),
              );
              if (job === undefined || job.isCompressed) {
                isMovableY = false;
              }
            }

            mutateElement(element, {
              x: element.x + offset.x,
              y: isMovableY ? element.y + offset.y : element.y,
              date: this.calendar.getPointDate(
                element.x + element.width / 2 + offset.x,
              ),
            });

            // CHANGED:ADD 2023-2-11 #671
            this.scene.updateMilestoneLine(element);
          } else {
            mutateElement(element, {
              x: element.x + offset.x,
              y: element.y + offset.y,
            });
          }
          updateBoundElements(element, this.scene.getNonDeletedElementsMap(), {
            simultaneouslyUpdated: selectedElements,
          });

          // CHANGED:ADD 2022-10-28 #14
          updateBoundElementsEx(
            element,
            this.scene.getNonDeletedElementsMap(),
            this.state, //CHANGED:ADD 2022-11-01 #79
            this.calendar, // CHANGED:ADD 2022/01/19 #390
            { simultaneouslyUpdated: selectedElements },
          );
        });

        this.maybeSuggestBindingForAll(selectedElements);

        this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455

        event.preventDefault();
      } else if (event.key === KEYS.ENTER) {
        const selectedElements = this.scene.getSelectedElements(this.state);
        if (selectedElements.length === 1) {
          const selectedElement = selectedElements[0];
          if (event[KEYS.CTRL_OR_CMD]) {
            if (isLinearElement(selectedElement)) {
              if (
                !this.state.editingLinearElement ||
                this.state.editingLinearElement.elementId !==
                  selectedElements[0].id
              ) {
                this.history.resumeRecording();
                this.setState({
                  editingLinearElement: new LinearElementEditor(
                    selectedElement,
                  ),
                });
              }
            }
          } else if (
            isTextElement(selectedElement) ||
            isValidTextContainer(selectedElement)
          ) {
            let container;
            let isJobElementText: boolean = false;
            if (!isTextElement(selectedElement)) {
              container = selectedElement as ExcalidrawTextContainer;
            }
            //CHANGED:UPDATE 2022/12/08 #225
            let midPoint;
            if (isNodeElement(selectedElement)) {
              midPoint = getContainerCenterEx(
                selectedElement,
                this.state,
                this.scene.getNonDeletedElementsMap(),
              );
            } else if (isJobElement(selectedElement)) {
              //CHANGED:ADD 2023/01/24 #391 エンターキーを押してjobElementにテキスト入力するため処理
              midPoint = getContainerCenterEx(
                selectedElement,
                this.state,
                this.scene.getNonDeletedElementsMap(),
              );
              isJobElementText = true;
            } else {
              midPoint = getContainerCenter(
                selectedElement,
                this.state,
                this.scene.getNonDeletedElementsMap(),
              );
            }

            const sceneX = midPoint.x;
            const sceneY = midPoint.y;
            this.startTextEditing({
              sceneX,
              sceneY,
              container,
              isJobElementText,
            });
            event.preventDefault();
            return;
          }
        }
      } else if (
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey &&
        this.state.draggingElement === null
      ) {
        const shape = findShapeByKey(event.key);
        if (shape) {
          if (this.state.activeTool.type !== shape) {
            trackEvent(
              "toolbar",
              shape,
              `keyboard (${this.device.isMobile ? "mobile" : "desktop"})`,
            );
          }
          this.setActiveTool({ type: shape });
          event.stopPropagation();
        } else if (event.key === KEYS.Q) {
          this.toggleLock("keyboard");
          event.stopPropagation();
        }
      }
      if (event.key === KEYS.SPACE && gesture.pointers.size === 0) {
        isHoldingSpace = true;
        setCursor(this.interactiveCanvas, CURSOR_TYPE.GRAB);
        event.preventDefault();
      }

      if (
        (event.key === KEYS.G || event.key === KEYS.S) &&
        !event.altKey &&
        !event[KEYS.CTRL_OR_CMD]
      ) {
        const selectedElements = this.scene.getSelectedElements(this.state);
        if (
          this.state.activeTool.type === "selection" &&
          !selectedElements.length
        ) {
          return;
        }

        if (
          event.key === KEYS.G &&
          (hasBackground(this.state.activeTool.type) ||
            selectedElements.some((element) => hasBackground(element.type)))
        ) {
          this.setState({ openPopup: "backgroundColorPicker" });
          event.stopPropagation();
        }
        if (event.key === KEYS.S) {
          this.setState({ openPopup: "strokeColorPicker" });
          event.stopPropagation();
        }
      }

      if (event.key === KEYS.K && !event.altKey && !event[KEYS.CTRL_OR_CMD]) {
        if (this.state.activeTool.type === "laser") {
          this.setActiveTool({ type: "selection" });
        } else {
          this.setActiveTool({ type: "laser" });
        }
        return;
      }

      // CHANGED:REMOVE 2024-02-13 #1640
      // if (
      //   event[KEYS.CTRL_OR_CMD] &&
      //   (event.key === KEYS.BACKSPACE || event.key === KEYS.DELETE)
      // ) {
      //   jotaiStore.set(activeConfirmDialogAtom, "clearCanvas");
      // }
    },
  );

  private onWheel = withBatchedUpdates((event: WheelEvent) => {
    // prevent browser pinch zoom on DOM elements
    if (!(event.target instanceof HTMLCanvasElement) && event.ctrlKey) {
      event.preventDefault();
    }
  });

  private onKeyUp = withBatchedUpdates((event: KeyboardEvent) => {
    if (event.key === KEYS.SPACE) {
      if (this.state.viewModeEnabled) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.GRAB);
      } else if (this.state.activeTool.type === "selection") {
        resetCursor(this.interactiveCanvas);
      } else {
        setCursorForShape(this.interactiveCanvas, this.state);
        this.setState({
          selectedElementIds: {},
          selectedGroupIds: {},
          editingGroupId: null,
        });
      }
      isHoldingSpace = false;
    }
    if (!event[KEYS.CTRL_OR_CMD] && !this.state.isBindingEnabled) {
      this.setState({ isBindingEnabled: true });
    }
    // CHANGED:ADD 2022-10-28 #14
    if (!event[KEYS.CTRL_OR_CMD] && !this.state.isBindingEnabledEx) {
      this.setState({ isBindingEnabledEx: true });
    }
    if (isArrowKey(event.key)) {
      const selectedElements = this.scene.getSelectedElements(this.state);
      const elementsMap = this.scene.getNonDeletedElementsMap();
      isBindingEnabled(this.state)
        ? bindOrUnbindSelectedElements(selectedElements, this)
        : unbindLinearElements(selectedElements, elementsMap);
      this.setState({ suggestedBindings: [] });
      // CHANGED:REMOVE 2022-12-11 #282
      // isBindingEnabledEx(this.state)
      //   ? bindOrUnbindSelectedElementsEx(selectedElements)
      //   : unbindLinkElements(selectedElements);
      // this.setState({ suggestedBindingsEx: [] });
    }
  });

  setActiveTool = (
    tool: (
      | (
        | { type: Exclude<ToolType, "image"> }
        | {
          type: Extract<ToolType, "image">;
          insertOnCanvasDirectly?: boolean;
        }
      )
      | { type: "custom"; customType: string }
    ) & { locked?: boolean },
  ) => {
    const nextActiveTool = updateActiveTool(this.state, tool);
    if (nextActiveTool.type === "hand") {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.GRAB);
    } else if (!isHoldingSpace) {
      setCursorForShape(this.interactiveCanvas, this.state);
    }
    if (isToolIcon(document.activeElement)) {
      this.focusContainer();
    }
    if (!isLinearElementType(nextActiveTool.type)) {
      this.setState({ suggestedBindings: [] });
    }
    // CHANGED:ADD 2022-10-28 #14
    if (!isLinkElementType(nextActiveTool.type)) {
      this.setState({ suggestedBindingsEx: [] });
    }
    if (nextActiveTool.type === "image") {
      this.onImageAction();
    }
    if (nextActiveTool.type !== "selection") {
      this.setState({
        activeTool: nextActiveTool,
        selectedElementIds: {},
        selectedGroupIds: {},
        editingGroupId: null,
      });
    } else {
      this.setState({ activeTool: nextActiveTool });
    }
  };

  private setCursor = (cursor: string) => {
    setCursor(this.interactiveCanvas, cursor);
  };

  private resetCursor = () => {
    resetCursor(this.interactiveCanvas);
  };
  /**
   * returns whether user is making a gesture with >= 2 fingers (points)
   * on o touch screen (not on a trackpad). Currently only relates to Darwin
   * (iOS/iPadOS,MacOS), but may work on other devices in the future if
   * GestureEvent is standardized.
   */
  private isTouchScreenMultiTouchGesture = () => {
    // we don't want to deselect when using trackpad, and multi-point gestures
    // only work on touch screens, so checking for >= pointers means we're on a
    // touchscreen
    return gesture.pointers.size >= 2;
  };

  // fires only on Safari
  private onGestureStart = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();

    // we only want to deselect on touch screens because user may have selected
    // elements by mistake while zooming
    if (this.isTouchScreenMultiTouchGesture()) {
      this.setState({
        selectedElementIds: {},
      });
    }
    gesture.initialScale = this.state.zoom.value;
  });

  // fires only on Safari
  private onGestureChange = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();

    // onGestureChange only has zoom factor but not the center.
    // If we're on iPad or iPhone, then we recognize multi-touch and will
    // zoom in at the right location in the touchmove handler
    // (handleCanvasPointerMove).
    //
    // On Macbook trackpad, we don't have those events so will zoom in at the
    // current location instead.
    //
    // As such, bail from this handler on touch devices.
    if (this.isTouchScreenMultiTouchGesture()) {
      return;
    }

    const initialScale = gesture.initialScale;
    if (initialScale) {
      this.setState((state) => ({
        ...getStateForZoom(
          {
            viewportX: cursorX,
            viewportY: cursorY,
            nextZoom: getNormalizedZoom(initialScale * event.scale),
          },
          state,
        ),
      }));
    }
  });

  // fires only on Safari
  private onGestureEnd = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();
    // reselect elements only on touch screens (see onGestureStart)
    if (this.isTouchScreenMultiTouchGesture()) {
      this.setState({
        previousSelectedElementIds: {},
        selectedElementIds: this.state.previousSelectedElementIds,
      });
    }
    gesture.initialScale = null;
  });

  private handleTextWysiwyg(
    element: ExcalidrawTextElement,
    {
      isExistingElement = false,
    }: {
      isExistingElement?: boolean;
    },
  ) {
    const elementsMap = this.scene.getElementsMapIncludingDeleted();

    const updateElement = (
      text: string,
      originalText: string,
      isDeleted: boolean,
    ) => {
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted().map((_element) => {
          if (_element.id === element.id && isTextElement(_element)) {
            return updateTextElement(
              _element,
              getContainerElement(_element, elementsMap),
              elementsMap,
              {
                text,
                isDeleted,
                originalText,
              }
            );
          }
          return _element;
        }),
      ]);
    };

    textWysiwyg({
      id: element.id,
      canvas: this.canvas,
      getViewportCoords: (x, y) => {
        const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
          {
            sceneX: x,
            sceneY: y,
          },
          {
            ...this.state,
            scrollX: isJobTextElement(element) ? 0 : this.state.scrollX,
          },
        );
        return [
          viewportX - this.state.offsetLeft,
          viewportY - this.state.offsetTop,
        ];
      },
      onChange: withBatchedUpdates((text) => {
        const container = getContainerElement(
          element,
          this.scene.getElementsMapIncludingDeleted(),
        );
        const prevH = container?.height; // CHANGED:ADD 2023/02/13 #633 #647
        const prevY = container?.y; // CHANGED:ADD 2023/02/13 #633 #647
        let coordinate;
        if (container) {
          coordinate = getContainerCenter(
            container,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );
        }
        updateElement(text, text, false);
        if (isNonDeletedElement(element)) {
          // CHANGED:ADD 2023/02/13 #633 #647
          const newH = container?.height;
          if (isJobElement(container) && prevH && newH && prevH !== newH) {
            const diffH = newH - prevH;
            const jobsHeight = this.state.jobsHeight + diffH;
            this.setState({
              jobsHeight,
            });

            if(prevY) {
              const elements = this.scene.getNonDeletedElements();
              Job.updateJobElements(
                elements,
                this.scene.getNonDeletedElementsMap(),
                prevY,
                prevH,
                diffH,
                this.state,
                this.calendar,
              );
            }

            this.scene.updateBackgroundHeight(jobsHeight);

            const jobElements = Job.getJobElements(
              this.scene.getNonDeletedElements(),
            );
            
            this.scene.jobPanelElements = generateJobPanelElements(
              jobElements,
              this.state.calendarWidth,
            );

            // CHANGED:ADD 2023-03-01 #726
            this.scene.jobLineElements = generateJobLineElements(
              jobElements,
              this.state.calendarWidth,
            );
          }
          updateBoundElements(element, elementsMap);
          //CHANGED:UPDATE 2022-11-01 #79
          // updateBoundElementsEx(element); // CHANGED:ADD 2022-10-28 #14
          updateBoundElementsEx(element, elementsMap, this.state, this.calendar); // CHANGED:ADD 2022/01/19 #390
        }
      }),
      // CHANGED:UPDATE 2022-12-09 #924
      onSubmit: withBatchedUpdates(({ text, viaKeyboard, originalText }) => {
          // CHANGED:UPDATE 2023/12/7 #1297
          // const isDeleted = !text.trim();
          const isDeleted = !text.trim() && !isTaskElement(
            getContainerElement(
              element,
              this.scene.getNonDeletedElementsMap(),
            )
          );

          updateElement(originalText, originalText, isDeleted); // CHANGED:UPDATE 2022-12-09 #266

          // Select the created text element only if submitting via keyboard
          // (when submitting via click it should act as signal to deselect)
          if (isDeleted || !viaKeyboard) {
            if (isDeleted) {
              fixBindingsAfterDeletion(this.scene.getNonDeletedElements(), [element]);
              // CHANGED:ADD 2022-10-28 #14
              fixBindingsAfterDeletionEx(this.scene.getNonDeletedElements(), [element]);
            }
            if (!isDeleted || isExistingElement) {
              this.history.resumeRecording();
            }
            // CHANGED:ADD 2023-03-25 #777
            if (!isTaskElement(this.state.draggingElement)) {
              this.setState({
                draggingElement: null,
                editingElement: null,
              });
            }
            if (this.state.activeTool.locked) {
              setCursorForShape(this.canvas, this.state);
            }
            this.focusContainer();
            return;
          }

          // Select the created text element
          const elementIdToSelect = element.containerId || element.id;
          this.setState((prevState) => ({
            selectedElementIds: {
              ...prevState.selectedElementIds,
              [elementIdToSelect]: true,
            },
            draggingElement: null,
            editingElement: null,
          }));

          // Resume history recording
          this.history.resumeRecording();
        },
      ),
      element,
      excalidrawContainer: this.excalidrawContainerRef.current,
      app: this,
    });
    // deselect all other elements when inserting text
    this.deselectElements();

    // do an initial update to re-initialize element position since we were
    // modifying element's x/y for sake of editor (case: syncing to remote)
    updateElement(element.text, element.originalText, false);
  }

  private deselectElements() {
    this.setState({
      selectedElementIds: {},
      selectedGroupIds: {},
      editingGroupId: null,
    });
  }

  private getTextElementAtPosition(
    x: number,
    y: number,
  ): NonDeleted<ExcalidrawTextElement> | null {
    const element = this.getElementAtPosition(x, y, {
      includeBoundTextElement: true,
    });
    if (element && isTextElement(element) && !element.isDeleted) {
      return element;
    }
    return null;
  }

  private getElementAtPosition(
    x: number,
    y: number,
    opts?: {
      /** if true, returns the first selected element (with highest z-index)
        of all hit elements */
      preferSelected?: boolean;
      includeBoundTextElement?: boolean;
      includeLockedElements?: boolean;
    },
  ): NonDeleted<ExcalidrawElement> | null {
    const allHitElements = this.getElementsAtPosition(
      x,
      y,
      opts?.includeBoundTextElement,
      opts?.includeLockedElements,
    );
    if (allHitElements.length > 1) {
      if (opts?.preferSelected) {
        for (let index = allHitElements.length - 1; index > -1; index--) {
          if (this.state.selectedElementIds[allHitElements[index].id]) {
            return allHitElements[index];
          }
        }
      }
      const elementWithHighestZIndex =
        allHitElements[allHitElements.length - 1];
      // If we're hitting element with highest z-index only on its bounding box
      // while also hitting other element figure, the latter should be considered.
      return isHittingElementBoundingBoxWithoutHittingElement(
        elementWithHighestZIndex,
        this.state,
        x,
        y,
        this.scene.getNonDeletedElementsMap(),
      )
        ? allHitElements[allHitElements.length - 2]
        : elementWithHighestZIndex;
    }
    if (allHitElements.length === 1) {
      return allHitElements[0];
    }
    return null;
  }

  private getElementsAtPosition(
    x: number,
    y: number,
    includeBoundTextElement: boolean = false,
    includeLockedElements: boolean = false,
  ): NonDeleted<ExcalidrawElement>[] {
    const elements =
      includeBoundTextElement && includeLockedElements
        ? this.scene.getNonDeletedElements()
        : this.scene
            .getNonDeletedElements()
            .filter(
              (element) =>
                (includeLockedElements || !element.locked) &&
                (includeBoundTextElement ||
                  !(isTextElement(element) && element.containerId)),
            );

    return getElementsAtPosition(elements, (element) =>
      hitTest(
        element,
        this.state,
        x,
        y,
        this.scene.getNonDeletedElementsMap(),
      ),
    );
  }

  private getTextBindableContainerAtPosition(x: number, y: number) {
    const elements = this.scene.getNonDeletedElements();
    const selectedElements = this.scene.getSelectedElements(this.state);
    if (selectedElements.length === 1) {
      return isTextBindableContainer(selectedElements[0], false)
        ? selectedElements[0]
        : null;
    }
    let hitElement = null;
    // We need to to hit testing from front (end of the array) to back (beginning of the array)
    for (let index = elements.length - 1; index >= 0; --index) {
      if (elements[index].isDeleted) {
        continue;
      }
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(
        elements[index],
        this.scene.getNonDeletedElementsMap(),
      );
      if (
        (isArrowElement(elements[index]) || isGraphElement(elements[index])) && //CHANGED:UPDATE 2023/01/17 #390
        isHittingElementNotConsideringBoundingBox(
          elements[index],
          this.scene.getNonDeletedElementsMap(),
          this.state,
          [x, y],
        )
      ) {
        hitElement = elements[index];
        break;
      } else if (x1 < x && x < x2 && y1 < y && y < y2) {
        hitElement = elements[index];
        break;
      }
    }

    return isTextBindableContainer(hitElement, false) ? hitElement : null;
  };

  private startTextEditing = ({
    sceneX,
    sceneY,
    insertAtParentCenter = true,
    container,
    isJobElementText,
  }: {
    /** X position to insert text at */
    sceneX: number;
    /** Y position to insert text at */
    sceneY: number;
    /** whether to attempt to insert at element center if applicable */
    insertAtParentCenter?: boolean;
    container?: ExcalidrawTextContainer | null;
    isJobElementText?: boolean;
  }) => {
    // CHANGED:ADD 2023/01/20 #390
    if (isLinkElement(container)) {
      return;
    }
    // CHANGED:ADD 2023-03-15 #740
    if (isJobElement(container) && container.isCompressed) {
      return;
    }
    let shouldBindToContainer = false;

    let parentCenterPosition =
      insertAtParentCenter &&
      this.getTextWysiwygSnappedToCenterPosition(
        sceneX,
        sceneY,
        this.state,
        container,
      );
    if (container && parentCenterPosition) {
      shouldBindToContainer = true;
    }
    let existingTextElement: NonDeleted<ExcalidrawTextElement> | null = null;

    const selectedElements = this.scene.getSelectedElements(this.state);

    if (selectedElements.length === 1) {
      if (isTextElement(selectedElements[0])) {
        existingTextElement = selectedElements[0];
      } else if (container) {
        existingTextElement = getBoundTextElement(
          selectedElements[0],
          this.scene.getNonDeletedElementsMap(),
        );
      } else {
        existingTextElement = this.getTextElementAtPosition(sceneX, sceneY);
      }
    } else {
      existingTextElement = this.getTextElementAtPosition(sceneX, sceneY);
    }

    const fontFamily =
      existingTextElement?.fontFamily || this.state.currentItemFontFamily;

    const lineHeight =
      existingTextElement?.lineHeight || getDefaultLineHeight(fontFamily);
    const fontSize = this.state.currentItemFontSize;

    if (
      !existingTextElement &&
      shouldBindToContainer &&
      container &&
      !isArrowElement(container) &&
      !isGraphElement(container) //CHANGED:UPDATE 2023/01/17 #390
    ) {
      const fontString = {
        fontSize,
        fontFamily,
      };
      const minWidth = getApproxMinLineWidth(
        getFontString(fontString),
        lineHeight,
      );
      const minHeight = getApproxMinLineHeight(fontSize, lineHeight);
      let newHeight = Math.max(container.height, minHeight);// CHANGED:UPDATE 2023/02/14 #701
      // CHANGED:ADD 2023/02/14 #701
      if (isJobElement(container)) {
        [, newHeight] = getGridPoint(0, newHeight, this.state.gridSize);
      }
      const newWidth = Math.max(container.width, minWidth);
      mutateElement(container, { height: newHeight, width: newWidth });
      sceneX = container.x + newWidth / 2;
      sceneY = container.y + newHeight / 2;
      if (parentCenterPosition) {
        parentCenterPosition = this.getTextWysiwygSnappedToCenterPosition(
          sceneX,
          sceneY,
          this.state,
          container,
        );
      }
    }
    const element = existingTextElement
      ? existingTextElement
      : isNodeElement(container) // CHANGED:ADD 2022/12/08 #225
      ? newTextElementEx({
          x: parentCenterPosition
            ? parentCenterPosition.elementCenterX
            : sceneX,
          y: parentCenterPosition
            ? parentCenterPosition.elementCenterY
            : sceneY,
          text: "",
          strokeWidth: this.state.currentItemStrokeWidthTask,
          strokeColor: container.strokeColor, // CHANGED:UPDATE 2023/8/29 #961
          fontSize: this.state.currentItemFontSize, // CHANGED:UPDATE 2023/1/27 #532
          fontFamily: 2,
          textAlign: this.state.currentItemTextAlign, // CHANGED:UPDATE 2023/1/27 #532
          horizontalAlign: this.state.currentItemTextHorizontalAlign, // CHANGED:ADD 2024-03-27 #1881
          verticalAlign: this.state.currentItemTextVerticalAlign, // CHANGED:UPDATE 2024-03-27 #1881
          textBorderNone: this.state.currentItemTextBorderNone, // CHANGED:ADD 2024-03-27 #1790
          textBorderOpacity: this.state.currentItemTextBorderOpacity, // CHANGED:ADD 2024-03-27 #1779
          textDirection: this.state.currentItemTextDirection, // CHANGED:UPDATE 2023/1/27 #532
          containerId: container?.id,
          originalText: "",
          locked: false,
          priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
          layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
        })
      : newTextElement({
          x: parentCenterPosition
            ? parentCenterPosition.elementCenterX
            : sceneX,
          y: parentCenterPosition
            ? parentCenterPosition.elementCenterY
            : sceneY,
          strokeColor: isJobElementText
            ? ColorsEx.lineColor.black
            : this.state.currentItemStrokeColor,
          backgroundColor: this.state.currentItemBackgroundColor,
          fillStyle: this.state.currentItemFillStyle,
          strokeWidth: this.state.currentItemStrokeWidth,
          strokeStyle: this.state.currentItemStrokeStyle,
          roughness: this.state.currentItemRoughness,
          opacity: this.state.currentItemOpacity,
          text: "",
          fontSize,
          fontFamily,
          textAlign: parentCenterPosition
            ? "center"
            : this.state.currentItemTextAlign,
          verticalAlign: parentCenterPosition
            ? VERTICAL_ALIGN.MIDDLE
            : DEFAULT_VERTICAL_ALIGN,
          containerId: shouldBindToContainer ? container?.id : undefined,
          groupIds: container?.groupIds ?? [],
          lineHeight,
          isJobText: isJobElementText ? true : false, //undefinedもあるため
          locked: false,
          priority: isJobElementText ? PRIORITY["job-text"] : PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
          layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
        });

    if (!existingTextElement && shouldBindToContainer && container) {
      mutateElement(container, {
        boundElements: (container.boundElements || []).concat({
          type: "text",
          id: element.id,
        }),
      });
    }
    this.setState({ editingElement: element });

    if (!existingTextElement) {
      if (container && shouldBindToContainer) {
        const containerIndex = this.scene.getElementIndex(container.id);
        this.scene.insertElementAtIndex(element, containerIndex + 1);
      } else {
        this.scene.replaceAllElements([
          ...this.scene.getElementsIncludingDeleted(),
          element,
        ]);
      }
    }

    this.setState({
      editingElement: element,
    });

    this.handleTextWysiwyg(element, {
      isExistingElement: !!existingTextElement,
    });

    hideTooltip("GENERAL");
  };

  private handleCanvasDoubleClick = (
    event: React.MouseEvent<HTMLCanvasElement>,
  ) => {
    // case: double-clicking with arrow/line tool selected would both create
    // text and enter multiElement mode
    if (this.state.multiElement) {
      return;
    }
    // we should only be able to double click when mode is selection
    if (this.state.activeTool.type !== "selection") {
      return;
    }

    const selectedElements = this.scene.getSelectedElements(this.state);

    if (selectedElements.length === 1 && isLinearElement(selectedElements[0])) {
      if (
        event[KEYS.CTRL_OR_CMD] &&
        (!this.state.editingLinearElement ||
          this.state.editingLinearElement.elementId !== selectedElements[0].id)
      ) {
        this.history.resumeRecording();
        this.setState({
          editingLinearElement: new LinearElementEditor(
            selectedElements[0],
          ),
        });
        return;
      } else if (
        this.state.editingLinearElement &&
        this.state.editingLinearElement.elementId === selectedElements[0].id
      ) {
        return;
      }
    }

    if (selectedElements.length === 1 && isLinkElement(selectedElements[0])) {
      const scenePointer = viewportCoordsToSceneCoords(event, this.state);
      const { x: scenePointerX, y: scenePointerY } = scenePointer;

      let hoverPointIndex = -1;

      hoverPointIndex = LinkElementEditor.getPointIndexUnderCursor(
        selectedElements[0],
        this.scene.getNonDeletedElementsMap(),
        this.state.zoom,
        scenePointerX,
        scenePointerY,
      );

      if (hoverPointIndex === 1) {
        actionChangeLinkPointerDirection.perform(
          this.scene.getElementsIncludingDeleted(),
          this.state,
          selectedElements[0].pointerDirection === POINTER_DIRECTION.HORIZONTAL
            ? POINTER_DIRECTION.VERTICAL
            : POINTER_DIRECTION.HORIZONTAL,
        );
      }
      return;
    }

    resetCursor(this.interactiveCanvas);

    let { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
      event,
      this.state,
    );
    
    const selectedGroupIds = getSelectedGroupIds(this.state);

    if (selectedGroupIds.length > 0) {
      const hitElement = this.getElementAtPosition(sceneX, sceneY);

      const selectedGroupId =
        hitElement &&
        getSelectedGroupIdForElement(hitElement, this.state.selectedGroupIds);

      if (selectedGroupId) {
        this.setState((prevState) => ({
          ...prevState,
          ...selectGroupsForSelectedElements(
            {
              editingGroupId: selectedGroupId,
              selectedElementIds: { [hitElement!.id]: true },
            },
            this.scene.getNonDeletedElements(),
            prevState,
            this,
          ),
        }));
        return;
      }
    }

    resetCursor(this.interactiveCanvas);
    if (!event[KEYS.CTRL_OR_CMD] && !this.state.viewModeEnabled) {
      const _container = this.getTextBindableContainerAtPosition(sceneX, sceneY);
      const fixedContainer = this.getTextBindableContainerAtPosition(event.clientX, event.clientY);
      const container: ExcalidrawTextContainer | null = _container || fixedContainer;

      let isJobElementText: boolean = false;
      if (container) {
        if (isJobElement(container)) {
          //CHANGED:ADD 2023/01/24 #391 JobElementにダブルクリックでテキスト入力する処理
          const midPoint = getContainerCenterEx(
            container,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );
          isJobElementText = true;
          this.setState({
            selectedElementIds: { [container.id]: true }
          })

          sceneX = midPoint.x;
          sceneY = midPoint.y;
        } else if (isTaskElement(container)) {
          this.setState({ openDialog: "editTask" });
          return;
        } else if (isNodeElement(container)) {
          //CHANGED:ADD 2022/12/08 #225
          const midPoint = getContainerCenterEx(
            container,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );

          sceneX = midPoint.x;
          sceneY = midPoint.y;
        } else if (
          hasBoundTextElement(container) ||
          !isTransparent(container.backgroundColor) ||
          isHittingElementNotConsideringBoundingBox(
            container,
            this.scene.getNonDeletedElementsMap(),
            this.state,
            [
              sceneX,
              sceneY,
            ])
        ) {
          const midPoint = getContainerCenter(
            container,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );

          sceneX = midPoint.x;
          sceneY = midPoint.y;
        }
      }
      this.startTextEditing({
        sceneX,
        sceneY,
        insertAtParentCenter: !event.altKey,
        container,
        isJobElementText,
      });
    }
  };

  private getElementLinkAtPosition = (
    scenePointer: Readonly<{ x: number; y: number }>,
    hitElement: NonDeletedExcalidrawElement | null,
  ): ExcalidrawElement | undefined => {
    // Reversing so we traverse the elements in decreasing order
    // of z-index
    const elements = this.scene.getNonDeletedElements().slice().reverse();
    let hitElementIndex = Infinity;

    return elements.find((element, index) => {
      if (hitElement && element.id === hitElement.id) {
        hitElementIndex = index;
      }
      return (
        element.link &&
        index <= hitElementIndex &&
        isPointHittingLink(
          element,
          this.scene.getNonDeletedElementsMap(),
          this.state,
          [scenePointer.x, scenePointer.y],
          this.device.isMobile,
        )
      );
    });
  };

  // CHANGED:ADD 2023-03-29 #790
  private getElementJobAccordionAtPosition = (
    scenePointer: Readonly<{ x: number; y: number }>,
    hitElement: NonDeletedExcalidrawElement | null,
  ): boolean => {
    return (
      isJobElement(hitElement) &&
      isPointHittingJobAccordionIcon(
        hitElement,
        this.scene.getNonDeletedElementsMap(),
        this.state,
        [scenePointer.x + this.state.scrollX, scenePointer.y],
      )
    )
  };

  private redirectToLink = (
    event: React.PointerEvent<HTMLCanvasElement>,
    isTouchScreen: boolean,
  ) => {
    const draggedDistance = distance2d(
      this.lastPointerDownEvent!.clientX,
      this.lastPointerDownEvent!.clientY,
      this.lastPointerUpEvent!.clientX,
      this.lastPointerUpEvent!.clientY,
    );
    if (
      !this.hitLinkElement ||
      // For touch screen allow dragging threshold else strict check
      (isTouchScreen && draggedDistance > DRAGGING_THRESHOLD) ||
      (!isTouchScreen && draggedDistance !== 0)
    ) {
      return;
    }
    const lastPointerDownCoords = viewportCoordsToSceneCoords(
      this.lastPointerDownEvent!,
      this.state,
    );
    const lastPointerDownHittingLinkIcon = isPointHittingLink(
      this.hitLinkElement,
      this.scene.getNonDeletedElementsMap(),
      this.state,
      [lastPointerDownCoords.x, lastPointerDownCoords.y],
      this.device.isMobile,
    );
    const lastPointerUpCoords = viewportCoordsToSceneCoords(
      this.lastPointerUpEvent!,
      this.state,
    );
    const lastPointerUpHittingLinkIcon = isPointHittingLink(
      this.hitLinkElement,
      this.scene.getNonDeletedElementsMap(),
      this.state,
      [lastPointerUpCoords.x, lastPointerUpCoords.y],
      this.device.isMobile,
    );
    if (lastPointerDownHittingLinkIcon && lastPointerUpHittingLinkIcon) {
      const url = this.hitLinkElement.link;
      if (url) {
        let customEvent;
        if (this.props.onLinkOpen) {
          customEvent = wrapEvent(EVENT.EXCALIDRAW_LINK, event.nativeEvent);
          this.props.onLinkOpen(this.hitLinkElement, customEvent);
        }
        if (!customEvent?.defaultPrevented) {
          const target = isLocalLink(url) ? "_self" : "_blank";
          const newWindow = window.open(undefined, target);
          // https://mathiasbynens.github.io/rel-noopener/
          if (newWindow) {
            newWindow.opener = null;
            newWindow.location = normalizeLink(url);
          }
        }
      }
    }
  };

  private handleCanvasPointerMove = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ) => {
    this.savePointer(event.clientX, event.clientY, this.state.cursorButton);
    this.lastPointerMoveEvent = event.nativeEvent;

    if (gesture.pointers.has(event.pointerId)) {
      gesture.pointers.set(event.pointerId, {
        x: event.clientX,
        y: event.clientY,
      });
    }

    const initialScale = gesture.initialScale;
    if (
      gesture.pointers.size === 2 &&
      gesture.lastCenter &&
      initialScale &&
      gesture.initialDistance
    ) {
      const center = getCenter(gesture.pointers);
      const deltaX = center.x - gesture.lastCenter.x;
      const deltaY = center.y - gesture.lastCenter.y;
      gesture.lastCenter = center;

      const distance = getDistance(Array.from(gesture.pointers.values()));
      const scaleFactor =
        this.state.activeTool.type === "freedraw" && this.state.penMode
          ? 1
          : distance / gesture.initialDistance;

      const nextZoom = scaleFactor
        ? getNormalizedZoom(initialScale * scaleFactor)
        : this.state.zoom.value;

      this.setState((state) => {
        const zoomState = getStateForZoom(
          {
            viewportX: center.x,
            viewportY: center.y,
            nextZoom,
          },
          state,
        );

        this.translateCanvas({
          zoom: zoomState.zoom,
          // CHANGED:UPDATE 2022-11-15 #134
          // scrollX: zoomState.scrollX + deltaX / nextZoom,
          // scrollY: zoomState.scrollY + deltaY / nextZoom,
          scrollX: this.scroll.getOffsetScrollX(
            zoomState.scrollX + deltaX / nextZoom,
          ),
          scrollY: this.scroll.getOffsetScrollY(
            zoomState.scrollY + deltaY / nextZoom,
          ),
          shouldCacheIgnoreZoom: true,
        });
      });
      this.resetShouldCacheIgnoreZoomDebounced();
    } else {
      gesture.lastCenter =
        gesture.initialDistance =
        gesture.initialScale =
          null;
    }

    if (
      isHoldingSpace ||
      isPanning ||
      isDraggingScrollBar ||
      isHandToolActive(this.state)
    ) {
      return;
    }

    const isPointerOverScrollBars = isOverScrollBars(
      currentScrollBars,
      event.clientX - this.state.offsetLeft,
      event.clientY - this.state.offsetTop,
    );
    const isOverScrollBar = isPointerOverScrollBars.isOverEither;
    if (!this.state.draggingElement && !this.state.multiElement) {
      if (isOverScrollBar) {
        resetCursor(this.interactiveCanvas);
      } else {
        setCursorForShape(this.interactiveCanvas, this.state);
      }
    }

    const scenePointer = viewportCoordsToSceneCoords(event, this.state);
    const { x: scenePointerX, y: scenePointerY } = scenePointer;

    if (
      this.state.editingLinearElement &&
      !this.state.editingLinearElement.isDragging
    ) {
      const editingLinearElement = LinearElementEditor.handlePointerMove(
        event,
        scenePointerX,
        scenePointerY,
        this.state,
        this.scene.getNonDeletedElementsMap(),
      );

      if (
        editingLinearElement &&
        editingLinearElement !== this.state.editingLinearElement
      ) {
        // Since we are reading from previous state which is not possible with
        // automatic batching in React 18 hence using flush sync to synchronously
        // update the state. Check https://github.com/excalidraw/excalidraw/pull/5508 for more details.
        flushSync(() => {
          this.setState({
            editingLinearElement,
          });
        });
      }
      if (editingLinearElement?.lastUncommittedPoint != null) {
        this.maybeSuggestBindingAtCursor(scenePointer);
      } else {
        // causes stack overflow if not sync
        flushSync(() => {
          this.setState({ suggestedBindings: [] });
        });
      }
    }

    if (isBindingElementType(this.state.activeTool.type)) {
      // Hovering with a selected tool or creating new linear element via click
      // and point
      const { draggingElement } = this.state;
      if (isBindingElement(draggingElement, false)) {
        this.maybeSuggestBindingsForLinearElementAtCoords(
          draggingElement,
          [scenePointer],
          this.state.startBoundElement,
        );
      } else {
        this.maybeSuggestBindingAtCursor(scenePointer);
      }
    }

    // CHANGED:ADD 2022-10-28 #14
    if (isBindingElementTypeEx(this.state.activeTool.type)) {
      // Hovering with a selected tool or creating new link element via click
      // and point
      const { draggingElement } = this.state;
      if (isBindingElementEx(draggingElement, false)) {
        this.maybeSuggestBindingsForLinkElementAtCoords(
          draggingElement,
          [scenePointer],
          "end", // CHANGED:ADD 2022-11-11 #116
          this.state.startBoundElementEx,
        );
      } else {
        this.maybeSuggestBindingAtCursorEx(scenePointer);
      }
    }

    if (this.state.multiElement) {
      const { multiElement } = this.state;
      const { x: rx, y: ry } = multiElement;

      const { points, lastCommittedPoint } = multiElement;
      const lastPoint = points[points.length - 1];

      setCursorForShape(this.interactiveCanvas, this.state);

      if (lastPoint === lastCommittedPoint) {
        // if we haven't yet created a temp point and we're beyond commit-zone
        // threshold, add a point
        if (
          distance2d(
            scenePointerX - rx,
            scenePointerY - ry,
            lastPoint[0],
            lastPoint[1],
          ) >= LINE_CONFIRM_THRESHOLD
        ) {
          mutateElement(multiElement, {
            points: [...points, [scenePointerX - rx, scenePointerY - ry]],
          });
        } else {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
          // in this branch, we're inside the commit zone, and no uncommitted
          // point exists. Thus do nothing (don't add/remove points).
        }
      } else if (
        points.length > 2 &&
        lastCommittedPoint &&
        distance2d(
          scenePointerX - rx,
          scenePointerY - ry,
          lastCommittedPoint[0],
          lastCommittedPoint[1],
        ) < LINE_CONFIRM_THRESHOLD
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        mutateElement(multiElement, {
          points: points.slice(0, -1),
        });
      } else {
        const [gridX, gridY] = getGridPoint(
          scenePointerX,
          scenePointerY,
          this.state.gridSize,
        );

        const [lastCommittedX, lastCommittedY] =
          multiElement?.lastCommittedPoint ?? [0, 0];

        let dxFromLastCommitted = gridX - rx - lastCommittedX;
        let dyFromLastCommitted = gridY - ry - lastCommittedY;

        if (shouldRotateWithDiscreteAngle(event)) {
          ({ width: dxFromLastCommitted, height: dyFromLastCommitted } =
            getLockedLinearCursorAlignSize(
              // actual coordinate of the last committed point
              lastCommittedX + rx,
              lastCommittedY + ry,
              // cursor-grid coordinate
              gridX,
              gridY,
            ));
        }

        if (isPathALoop(points, this.state.zoom.value)) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        }
        // update last uncommitted point
        mutateElement(multiElement, {
          points: [
            ...points.slice(0, -1),
            [
              lastCommittedX + dxFromLastCommitted,
              lastCommittedY + dyFromLastCommitted,
            ],
          ],
        });
      }

      return;
    }

    const hasDeselectedButton = Boolean(event.buttons);
    if (
      hasDeselectedButton ||
      (this.state.activeTool.type !== "selection" &&
        this.state.activeTool.type !== "text" &&
        this.state.activeTool.type !== "eraser")
    ) {
      return;
    }

    const elements = this.scene.getNonDeletedElements();

    const selectedElements = this.scene.getSelectedElements(this.state);
    if (
      selectedElements.length === 1 &&
      !isOverScrollBar &&
      !this.state.editingLinearElement
    ) {
      // CHANGED:ADD 2023/02/09 #562
      let _scenePointerX = scenePointerX;
      if (isJobElement(selectedElements[0])) {
        const _scenePointer = viewportCoordsToSceneCoords(event, {
          ...this.state,
          scrollX: 0,
        });
        _scenePointerX = _scenePointer.x;
      }

      const elementWithTransformHandleType = getElementWithTransformHandleType(
        elements,
        this.state,
        _scenePointerX, // CHANGED:UPDATE 2023/02/09 #562
        scenePointerY,
        this.state.zoom,
        event.pointerType,
        this.scene.getNonDeletedElementsMap(),
      );
      if (
        elementWithTransformHandleType &&
        elementWithTransformHandleType.transformHandleType
      ) {
        setCursor(
          this.interactiveCanvas,
          getCursorForResizingElement(elementWithTransformHandleType),
        );
        return;
      }
    } else if (selectedElements.length > 1 && !isOverScrollBar) {
      // CHANGED:REMOVE 2022-11-9 #101
      // const transformHandleType = getTransformHandleTypeFromCoords(
      //   getCommonBounds(selectedElements),
      //   scenePointerX,
      //   scenePointerY,
      //   this.state.zoom,
      //   event.pointerType,
      // );
      // if (transformHandleType) {
      //   setCursor(
      //     this.interactiveCanvas,
      //     getCursorForResizingElement({
      //       transformHandleType,
      //     }),
      //   );
      //   return;
      // }
    }

    const hitElement = this.getElementAtPosition(
      scenePointer.x,
      scenePointer.y,
    );

    this.hitLinkElement = this.getElementLinkAtPosition(
      scenePointer,
      hitElement,
    );
    // CHANGED:ADD 2023-03-29 #790
    this.isHittingJobAccordionIcon = this.getElementJobAccordionAtPosition(
      scenePointer,
      hitElement,
    );
    if (isEraserActive(this.state)) {
      return;
    }

    // CHANGED: ADD 2024-07-01 #2084
    if (
      !this.hitLinkElement &&
      !this.state.editingElement &&
      isTaskElement(hitElement) &&
      this.selectedProject
    ) {
      this.selectedProject
        .getHoveredTaskResources(hitElement.id)
        .then((result) => {
          if (!result) return;
          const label = [];
          label.push(`関連リソース: ${result.resources.length ?
            arrayToStringLabel(result.resources, "個") :
            "無し"}`);
          label.push(`担当者: ${result.assignedUsernames.length ?
            arrayToStringLabel(result.assignedUsernames, "人") :
            "無し"}`);

          const { x, y } = sceneCoordsToViewportCoords(
            { sceneX: scenePointer.x, sceneY: scenePointer.y },
            this.state,
          );

          showTooltip({
            tooltipType: "GENERAL",
            element: hitElement,
            elementsMap: this.scene.getNonDeletedElementsMap(),
            coordinate: {
              x,
              y: y - 12
            },
            label: label.join(" \n") || "",
          });
        });
    } else {
      hideTooltip("GENERAL");
    }

    if (
      this.hitLinkElement &&
      !this.state.selectedElementIds[this.hitLinkElement.id]
    ) {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
      // CHANGED: REMOVE 2024-07-02 #2084
      // showHyperlinkTooltip(
      //   this.hitLinkElement,
      //   this.state,
      //   this.scene.getNonDeletedElementsMap(),
      // );
      // CHANGED: ADD 2024-07-02 #2084
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(
        this.hitLinkElement,
        this.scene.getNonDeletedElementsMap()
      );

      const [linkX, linkY, linkWidth, linkHeight] = getLinkHandleFromCoords(
        [x1, y1, x2, y2],
        (this.hitLinkElement.angle || 0),
        this.state,
      );
      const linkViewportCoords = sceneCoordsToViewportCoords(
        { sceneX: linkX, sceneY: linkY },
        this.state,
      );

      showTooltip({
        tooltipType: "HYPERLINK",
        element: this.hitLinkElement,
        elementsMap: this.scene.getNonDeletedElementsMap(),
        coordinate: {
          x: linkViewportCoords.x,
          y: linkViewportCoords.y,
        },
        label: this.hitLinkElement.link || "",
      });
    } else if (this.isHittingJobAccordionIcon) {
      // hideHyperlinkToolip(); // CHANGED: REMOVE 2024-07-02 #2084
      hideTooltip("HYPERLINK"); // CHANGED: ADD 2024-07-02 #2084

      setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER); 
    } else {
      // hideHyperlinkToolip(); // CHANGED: REMOVE 2024-07-02 #2084
      hideTooltip("HYPERLINK"); // CHANGED: ADD 2024-07-02 #2084
      
      if (
        hitElement &&
        hitElement.link &&
        this.state.selectedElementIds[hitElement.id] &&
        !this.state.contextMenu &&
        !this.state.showHyperlinkPopup
      ) {
        this.setState({ showHyperlinkPopup: "info" });
      } else if (this.state.activeTool.type === "text") {
        setCursor(
          this.interactiveCanvas,
          isTextElement(hitElement) ? CURSOR_TYPE.TEXT : CURSOR_TYPE.CROSSHAIR,
        );
      } else if (this.state.viewModeEnabled) {
        if (hitElement && isCommentElement(hitElement)) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        } else {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.GRAB);
        }
      } else if (isOverScrollBar) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
      } else if (this.state.selectedLinearElement) {
        this.handleHoverSelectedLinearElement(
          this.state.selectedLinearElement,
          scenePointerX,
          scenePointerY,
        );
        // CHANGED:ADD 2022-10-28 #14
      } else if (this.state.selectedTaskElement) {
        this.handleHoverSelectedTaskElement(
          this.state.selectedTaskElement,
          scenePointerX,
          scenePointerY,
        );
        // CHANGED:ADD 2022-11-2 #64
      } else if (this.state.selectedLinkElement) {
        this.handleHoverSelectedLinkElement(
          this.state.selectedLinkElement,
          scenePointerX,
          scenePointerY,
        );
      } else if (
        // if using cmd/ctrl, we're not dragging
        !event[KEYS.CTRL_OR_CMD]
      ) {
        if (
          (hitElement ||
            this.isHittingCommonBoundingBoxOfSelectedElements(
              scenePointer,
              selectedElements,
            )) &&
          !hitElement?.locked
        ) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
        }
      } else {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
      }
    }
  };

  private handleEraser = (
    event: PointerEvent,
    pointerDownState: PointerDownState,
    scenePointer: { x: number; y: number },
  ) => {
    this.eraserTrail.addPointToPath(scenePointer.x, scenePointer.y);

    const updateElementIds = (elements: ExcalidrawElement[]) => {
      elements.forEach((element) => {
        // CHANGED:UPDATE 2023-2-8 #606 CHANGED: UPDATE 2023-12-21 #1138
        if (element.locked || isJobElement(element) || isCommentElement(element)) {
          return;
        }

        idsToUpdate.push(element.id);
        if (event.altKey) {
          if (
            pointerDownState.elementIdsToErase[element.id] &&
            pointerDownState.elementIdsToErase[element.id].erase
          ) {
            pointerDownState.elementIdsToErase[element.id].erase = false;
          }
        } else if (!pointerDownState.elementIdsToErase[element.id]) {
          pointerDownState.elementIdsToErase[element.id] = {
            erase: true,
            opacity: (element.opacity || 100),
          };
        }
      });
    };

    const idsToUpdate: Array<string> = [];

    const distance = distance2d(
      pointerDownState.lastCoords.x,
      pointerDownState.lastCoords.y,
      scenePointer.x,
      scenePointer.y,
    );
    const threshold = 10 / this.state.zoom.value;
    const point = { ...pointerDownState.lastCoords };
    let samplingInterval = 0;
    while (samplingInterval <= distance) {
      const hitElements = this.getElementsAtPosition(point.x, point.y);
      updateElementIds(hitElements);

      // Exit since we reached current point
      if (samplingInterval === distance) {
        break;
      }

      // Calculate next point in the line at a distance of sampling interval
      samplingInterval = Math.min(samplingInterval + threshold, distance);

      const distanceRatio = samplingInterval / distance;
      const nextX =
        (1 - distanceRatio) * point.x + distanceRatio * scenePointer.x;
      const nextY =
        (1 - distanceRatio) * point.y + distanceRatio * scenePointer.y;
      point.x = nextX;
      point.y = nextY;
    }

    const elements = this.scene.getElementsIncludingDeleted().map((ele) => {
      const id =
        isBoundToContainer(ele) && idsToUpdate.includes(ele.containerId)
          ? ele.containerId
          : ele.id;
      if (idsToUpdate.includes(id)) {
        if (event.altKey) {
          if (
            pointerDownState.elementIdsToErase[id] &&
            pointerDownState.elementIdsToErase[id].erase === false
          ) {
            return newElementWith(ele, {
              opacity: pointerDownState.elementIdsToErase[id].opacity,
            });
          }
        } else {
          return newElementWith(ele, {
            opacity: ELEMENT_READY_TO_ERASE_OPACITY,
          });
        }
      }
      return ele;
    });

    this.scene.replaceAllElements(elements);

    pointerDownState.lastCoords.x = scenePointer.x;
    pointerDownState.lastCoords.y = scenePointer.y;
  };
  // set touch moving for mobile context menu
  private handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    invalidateContextMenu = true;
  };

  handleHoverSelectedLinearElement(
    linearElementEditor: LinearElementEditor,
    scenePointerX: number,
    scenePointerY: number,
  ) {
    const elementsMap = this.scene.getNonDeletedElementsMap();

    const element = LinearElementEditor.getElement(
      linearElementEditor.elementId,
      elementsMap,
    );
    const boundTextElement = getBoundTextElement(element, elementsMap);

    if (!element) {
      return;
    }
    if (this.state.selectedLinearElement) {
      let hoverPointIndex = -1;
      let segmentMidPointHoveredCoords = null;
      if (
        isHittingElementNotConsideringBoundingBox(
          element,
          elementsMap,
          this.state,
          [
            scenePointerX,
            scenePointerY,
          ])
      ) {
        hoverPointIndex = LinearElementEditor.getPointIndexUnderCursor(
          element,
          elementsMap,
          this.state.zoom,
          scenePointerX,
          scenePointerY,
        );
        segmentMidPointHoveredCoords =
          LinearElementEditor.getSegmentMidpointHitCoords(
            linearElementEditor,
            { x: scenePointerX, y: scenePointerY },
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );

        if (hoverPointIndex >= 0 || segmentMidPointHoveredCoords) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        } else {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
        }
      } else if (
        shouldShowBoundingBox([element], this.state) &&
        isHittingElementBoundingBoxWithoutHittingElement(
          element,
          this.state,
          scenePointerX,
          scenePointerY,
          elementsMap,
        )
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
      } else if (
        boundTextElement &&
        hitTest(
          boundTextElement,
          this.state,
          scenePointerX,
          scenePointerY,
          this.scene.getNonDeletedElementsMap(),
        )
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
      }

      if (
        this.state.selectedLinearElement.hoverPointIndex !== hoverPointIndex
      ) {
        this.setState({
          selectedLinearElement: {
            ...this.state.selectedLinearElement,
            hoverPointIndex,
          },
        });
      }

      if (
        !LinearElementEditor.arePointsEqual(
          this.state.selectedLinearElement.segmentMidPointHoveredCoords,
          segmentMidPointHoveredCoords,
        )
      ) {
        this.setState({
          selectedLinearElement: {
            ...this.state.selectedLinearElement,
            segmentMidPointHoveredCoords,
          },
        });
      }
    } else {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
    }
  }
  // CHANGED:ADD 2022-10-28 #14
  handleHoverSelectedTaskElement(
    taskElementEditor: TaskElementEditor,
    scenePointerX: number,
    scenePointerY: number,
  ) {
    const element = TaskElementEditor.getElement(taskElementEditor.elementId);
    const elementsMap = this.scene.getNonDeletedElementsMap();
    const boundTextElement = getBoundTextElement(element, elementsMap);

    if (!element) {
      return;
    }
    if (this.state.selectedTaskElement) {
      let hoverPointIndex = -1;
      if (
        isHittingElementNotConsideringBoundingBox(
          element,
          elementsMap,
          this.state,
          [
            scenePointerX,
            scenePointerY,
          ])
      ) {
        hoverPointIndex = TaskElementEditor.getPointIndexUnderCursor(
          element,
          elementsMap,
          this.state.zoom,
          scenePointerX,
          scenePointerY,
        );

        if (hoverPointIndex >= 0) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        } else {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
        }
      } else if (
        shouldShowBoundingBox([element], this.state) &&
        isHittingElementBoundingBoxWithoutHittingElement(
          element,
          this.state,
          scenePointerX,
          scenePointerY,
          elementsMap,
        )
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
      } else if (
        boundTextElement &&
        hitTest(
          boundTextElement,
          this.state,
          scenePointerX,
          scenePointerY,
          this.scene.getNonDeletedElementsMap(),
        )
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
      }

      if (this.state.selectedTaskElement.hoverPointIndex !== hoverPointIndex) {
        this.setState({
          selectedTaskElement: {
            ...this.state.selectedTaskElement,
            hoverPointIndex,
          },
        });
      }
    } else {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
    }
  }
  // CHANGED:ADD 2022-11-2 #64
  handleHoverSelectedLinkElement(
    linkElementEditor: LinkElementEditor,
    scenePointerX: number,
    scenePointerY: number,
  ) {
    const element = LinkElementEditor.getElement(linkElementEditor.elementId);
    const elementsMap = this.scene.getNonDeletedElementsMap();

    if (!element) {
      return;
    }
    if (this.state.selectedLinkElement) {
      let hoverPointIndex = -1;
      if (
        isHittingElementNotConsideringBoundingBox(
          element,
          elementsMap,
          this.state,
          [
            scenePointerX,
            scenePointerY,
          ])
      ) {
        hoverPointIndex = LinkElementEditor.getPointIndexUnderCursor(
          element,
          elementsMap,
          this.state.zoom,
          scenePointerX,
          scenePointerY,
        );

        // CHANGED:ADD 2022-12-12 #188
        if (isLinkElement(element) && hoverPointIndex === 1) {
          if(element.pointerDirection === POINTER_DIRECTION.HORIZONTAL) {
            setCursor(this.interactiveCanvas, CURSOR_TYPE.COL_RESIZE);
          } else {
            setCursor(this.interactiveCanvas, CURSOR_TYPE.ROW_RESIZE);
          }
        } else if (hoverPointIndex >= 0) {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
        } else {
          setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
        }
      } else if (
        shouldShowBoundingBox([element], this.state) &&
        isHittingElementBoundingBoxWithoutHittingElement(
          element,
          this.state,
          scenePointerX,
          scenePointerY,
          elementsMap,
        )
      ) {
        setCursor(this.interactiveCanvas, CURSOR_TYPE.MOVE);
      }

      if (this.state.selectedLinkElement.hoverPointIndex !== hoverPointIndex) {
        this.setState({
          selectedLinkElement: {
            ...this.state.selectedLinkElement,
            hoverPointIndex,
          },
        });
      }
    } else {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
    }
  }

  private handleCanvasPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ) => {
    // since contextMenu options are potentially evaluated on each render,
    // and an contextMenu action may depend on selection state, we must
    // close the contextMenu before we update the selection on pointerDown
    // (e.g. resetting selection)
    if (this.state.contextMenu) {
      this.setState({ contextMenu: null });
    }

    // if (this.state.openSidebar) {
    //   this.setState({ openSidebar: null });
    // }

    this.updateGestureOnPointerDown(event);

    // if dragging element is freedraw and another pointerdown event occurs
    // a second finger is on the screen
    // discard the freedraw element if it is very short because it is likely
    // just a spike, otherwise finalize the freedraw element when the second
    // finger is lifted
    if (
      event.pointerType === "touch" &&
      this.state.draggingElement &&
      this.state.draggingElement.type === "freedraw"
    ) {
      const element = this.state.draggingElement as ExcalidrawFreeDrawElement;
      this.updateScene({
        ...(element.points.length < 10
          ? {
              elements: this.scene
                .getElementsIncludingDeleted()
                .filter((el) => el.id !== element.id),
            }
          : {}),
        appState: {
          draggingElement: null,
          editingElement: null,
          startBoundElement: null,
          suggestedBindings: [],
          selectedElementIds: makeNextSelectedElementIds(
            Object.keys(this.state.selectedElementIds)
              .filter((key) => key !== element.id)
              .reduce((obj: { [id: string]: true }, key) => {
                obj[key] = this.state.selectedElementIds[key];
                return obj;
              }, {}),
            this.state,
          ),
        },
      });
      return;
    }

    // remove any active selection when we start to interact with canvas
    // (mainly, we care about removing selection outside the component which
    //  would prevent our copy handling otherwise)
    const selection = document.getSelection();
    if (selection?.anchorNode) {
      selection.removeAllRanges();
    }
    this.maybeOpenContextMenuAfterPointerDownOnTouchDevices(event);
    this.maybeCleanupAfterMissingPointerUp(event);

    //fires only once, if pen is detected, penMode is enabled
    //the user can disable this by toggling the penMode button
    if (!this.state.penDetected && event.pointerType === "pen") {
      this.setState((prevState) => {
        return {
          penMode: true,
          penDetected: true,
        };
      });
    }

    if (
      !this.device.isTouchScreen &&
      ["pen", "touch"].includes(event.pointerType)
    ) {
      this.device = updateObject(this.device, { isTouchScreen: true });
    }

    if (isPanning) {
      return;
    }

    this.lastPointerDownEvent = event;
    this.setState({
      lastPointerDownWith: event.pointerType,
      cursorButton: "down",
    });
    this.savePointer(event.clientX, event.clientY, "down");

    if (this.handleCanvasPanUsingWheelOrSpaceDrag(event)) {
      // CHANGED:ADD 2024-02-15 #1567
      if (this.state.viewModeEnabled) {
        // State for the duration of a pointer interaction, which starts with a
        // pointerDown event, ends with a pointerUp event (or another pointerDown)
        const pointerDownState = this.initialPointerDownState(event);

        pointerDownState.hit.element =
          pointerDownState.hit.element ??
          this.getElementAtPosition(
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          );

        const hitElement = pointerDownState.hit.element;
        if (hitElement) {
          this.setState({
            selectedElementIds: {
              [hitElement.id]: true,
            }
          });
        } else {
          this.setState({ selectedElementIds: {} });
        }

        const onPointerUp =
          this.onPointerUpFromPointerDownHandlerViewMode(pointerDownState);

        lastPointerUp = onPointerUp;

        window.addEventListener(EVENT.POINTER_UP, onPointerUp);
        pointerDownState.eventListeners.onUp = onPointerUp;
      }
      return;
    }

    // only handle left mouse button or touch
    if (
      event.button !== POINTER_BUTTON.MAIN &&
      event.button !== POINTER_BUTTON.TOUCH
    ) {
      return;
    }

    // don't select while panning
    if (gesture.pointers.size > 1) {
      return;
    }

    // State for the duration of a pointer interaction, which starts with a
    // pointerDown event, ends with a pointerUp event (or another pointerDown)
    const pointerDownState = this.initialPointerDownState(event);

    if (this.handleDraggingScrollBar(event, pointerDownState)) {
      return;
    }

    this.clearSelectionIfNotUsingSelection();
    this.updateBindingEnabledOnPointerMove(event);

    if (this.handleSelectionOnPointerDown(event, pointerDownState)) {
      return;
    }

    const allowOnPointerDown =
      !this.state.penMode ||
      event.pointerType !== "touch" ||
      this.state.activeTool.type === "selection" ||
      this.state.activeTool.type === "text" ||
      this.state.activeTool.type === "image";

    if (!allowOnPointerDown) {
      return;
    }

    // CHANGED:ADD 2022-12-18 #347
    if (
      this.state.activeTool.type === "task" ||
      this.state.activeTool.type === "milestone"
    ) {
      if (
        pointerDownState.origin.x < JOB_ELEMENTS_WIDTH ||
        pointerDownState.origin.x >
          JOB_ELEMENTS_WIDTH + this.state.calendarWidth ||
        pointerDownState.origin.y < CANVAS_HEADER_HEIGHT ||
        pointerDownState.origin.y > CANVAS_HEADER_HEIGHT + this.state.jobsHeight
      ) {
        return;
      }
    }

    if (this.state.activeTool.type === "text") {
      this.handleTextOnPointerDown(event, pointerDownState);
      return;
    } else if (
      this.state.activeTool.type === "arrow" ||
      this.state.activeTool.type === "line"
    ) {
      this.handleLinearElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
    } else if (this.state.activeTool.type === "image") {
      // reset image preview on pointerdown
      setCursor(this.interactiveCanvas, CURSOR_TYPE.CROSSHAIR);

      // retrieve the latest element as the state may be stale
      const pendingImageElement =
        this.state.pendingImageElementId &&
        this.scene.getElement(this.state.pendingImageElementId);

      if (!pendingImageElement) {
        return;
      }

      this.setState({
        draggingElement: pendingImageElement,
        editingElement: pendingImageElement,
        pendingImageElementId: null,
        multiElement: null,
      });

      const { x, y } = viewportCoordsToSceneCoords(event, this.state);
      mutateElement(pendingImageElement, {
        x,
        y,
      });
    } else if (this.state.activeTool.type === "freedraw") {
      this.handleFreeDrawElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
    } else if (this.state.activeTool.type === "custom") {
      setCursor(this.interactiveCanvas, CURSOR_TYPE.AUTO);
      // CHANGED:ADD 2022-10-28 #14
    } else if (this.state.activeTool.type === "task") {
      this.handleTaskElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
      // CHANGED:ADD 2022-12-7 #157
    } else if (this.state.activeTool.type === "milestone") {
      this.handleMilestoneElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
      // CHANGED:ADD 2022-11-2 #64
    } else if (this.state.activeTool.type === "link") {
      this.handleLinkElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
      // CHANGED:ADD 2022-11-05 #86
    } else if (
      this.state.activeTool.type === "job-text"
    ) {
     // CHANGED:ADD 2023-12-20 #1138
    }  else if (
      this.state.activeTool.type === "comment"
    ) {
    } else if (this.state.activeTool.type === "laser") {
      this.laserTrails.startPath(
        pointerDownState.lastCoords.x,
        pointerDownState.lastCoords.y,
      );
    } else if (
      this.state.activeTool.type !== "eraser" &&
      this.state.activeTool.type !== "hand"
    ) {
      this.createGenericElementOnPointerDown(
        this.state.activeTool.type,
        pointerDownState,
      );
    }

    this.props?.onPointerDown?.(this.state.activeTool, pointerDownState);

    if (this.state.activeTool.type === "eraser") {
      this.eraserTrail.startPath(
        pointerDownState.lastCoords.x,
        pointerDownState.lastCoords.y,
      );
    }

    const onPointerMove =
      this.onPointerMoveFromPointerDownHandler(pointerDownState);

    const onPointerUp =
      this.onPointerUpFromPointerDownHandler(pointerDownState);

    const onKeyDown = this.onKeyDownFromPointerDownHandler(pointerDownState);
    const onKeyUp = this.onKeyUpFromPointerDownHandler(pointerDownState);

    lastPointerUp = onPointerUp;

    if (!this.state.viewModeEnabled || this.state.activeTool.type === "laser") {
      window.addEventListener(EVENT.POINTER_MOVE, onPointerMove);
      window.addEventListener(EVENT.POINTER_UP, onPointerUp);
      window.addEventListener(EVENT.KEYDOWN, onKeyDown);
      window.addEventListener(EVENT.KEYUP, onKeyUp);
      pointerDownState.eventListeners.onMove = onPointerMove;
      pointerDownState.eventListeners.onUp = onPointerUp;
      pointerDownState.eventListeners.onKeyUp = onKeyUp;
      pointerDownState.eventListeners.onKeyDown = onKeyDown;
    }
  };

  private handleCanvasPointerUp = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ) => {
    this.lastPointerUpEvent = event;
    if (this.device.isTouchScreen) {
      const scenePointer = viewportCoordsToSceneCoords(
        { clientX: event.clientX, clientY: event.clientY },
        this.state,
      );
      const hitElement = this.getElementAtPosition(
        scenePointer.x,
        scenePointer.y,
      );
      this.hitLinkElement = this.getElementLinkAtPosition(
        scenePointer,
        hitElement,
      );
      // CHANGED:ADD 2023-03-29 #790
      this.isHittingJobAccordionIcon = this.getElementJobAccordionAtPosition(
        scenePointer,
        hitElement,
      );
    }
    if (
      this.hitLinkElement &&
      !this.state.selectedElementIds[this.hitLinkElement.id]
    ) {
      this.redirectToLink(event, this.device.isTouchScreen);
      // CHANGED:ADD 2023-03-29 #790
    } else if (this.isHittingJobAccordionIcon) {
      this.actionManager.executeAction(actionToggleJobRowExpansion);
    }

    this.removePointer(event);
  };

  private maybeOpenContextMenuAfterPointerDownOnTouchDevices = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ): void => {
    // deal with opening context menu on touch devices
    if (event.pointerType === "touch") {
      invalidateContextMenu = false;

      if (touchTimeout) {
        // If there's already a touchTimeout, this means that there's another
        // touch down and we are doing another touch, so we shouldn't open the
        // context menu.
        invalidateContextMenu = true;
      } else {
        // open the context menu with the first touch's clientX and clientY
        // if the touch is not moving
        touchTimeout = window.setTimeout(() => {
          touchTimeout = 0;
          if (!invalidateContextMenu) {
            this.handleCanvasContextMenu(event);
          }
        }, TOUCH_CTX_MENU_TIMEOUT);
      }
    }
  };

  private resetContextMenuTimer = () => {
    clearTimeout(touchTimeout);
    touchTimeout = 0;
    invalidateContextMenu = false;
  };

  private maybeCleanupAfterMissingPointerUp(
    event: React.PointerEvent<HTMLCanvasElement>,
  ): void {
    if (lastPointerUp !== null) {
      // Unfortunately, sometimes we don't get a pointerup after a pointerdown,
      // this can happen when a contextual menu or alert is triggered. In order to avoid
      // being in a weird state, we clean up on the next pointerdown
      lastPointerUp(event);
    }
  }

  // Returns whether the event is a panning
  private handleCanvasPanUsingWheelOrSpaceDrag = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ): boolean => {
    if (
      !(
        gesture.pointers.size <= 1 &&
        (event.button === POINTER_BUTTON.WHEEL ||
          (event.button === POINTER_BUTTON.MAIN && isHoldingSpace) ||
          isHandToolActive(this.state) ||
          this.state.viewModeEnabled)
      ) ||
      isTextElement(this.state.editingElement)
    ) {
      return false;
    }
    isPanning = true;
    event.preventDefault();

    let nextPastePrevented = false;
    const isLinux = /Linux/.test(window.navigator.platform);

    setCursor(this.interactiveCanvas, CURSOR_TYPE.GRABBING);
    let { clientX: lastX, clientY: lastY } = event;
    const onPointerMove = withBatchedUpdatesThrottled((event: PointerEvent) => {
      const deltaX = lastX - event.clientX;
      const deltaY = lastY - event.clientY;
      lastX = event.clientX;
      lastY = event.clientY;

      /*
       * Prevent paste event if we move while middle clicking on Linux.
       * See issue #1383.
       */
      if (
        isLinux &&
        !nextPastePrevented &&
        (Math.abs(deltaX) > 1 || Math.abs(deltaY) > 1)
      ) {
        nextPastePrevented = true;

        /* Prevent the next paste event */
        const preventNextPaste = (event: ClipboardEvent) => {
          document.body.removeEventListener(EVENT.PASTE, preventNextPaste);
          event.stopPropagation();
        };

        /*
         * Reenable next paste in case of disabled middle click paste for
         * any reason:
         * - right click paste
         * - empty clipboard
         */
        const enableNextPaste = () => {
          setTimeout(() => {
            document.body.removeEventListener(EVENT.PASTE, preventNextPaste);
            window.removeEventListener(EVENT.POINTER_UP, enableNextPaste);
          }, 100);
        };

        document.body.addEventListener(EVENT.PASTE, preventNextPaste);
        window.addEventListener(EVENT.POINTER_UP, enableNextPaste);
      }
      this.translateCanvas({
        // CHANGED:UPDATE 2022-11-15 #134
        // scrollX: this.state.scrollX - deltaX / this.state.zoom.value,
        // scrollY: this.state.scrollY - deltaY / this.state.zoom.value,
        scrollX: this.scroll.getOffsetScrollX(
          this.state.scrollX - deltaX / this.state.zoom.value,
        ),
        scrollY: this.scroll.getOffsetScrollY(
          this.state.scrollY - deltaY / this.state.zoom.value,
        ),
      });
    });
    const teardown = withBatchedUpdates(
      (lastPointerUp = () => {
        lastPointerUp = null;
        isPanning = false;
        if (!isHoldingSpace) {
          if (this.state.viewModeEnabled) {
            setCursor(this.interactiveCanvas, CURSOR_TYPE.GRAB);
          } else {
            setCursorForShape(this.interactiveCanvas, this.state);
          }
        }
        this.setState({
          cursorButton: "up",
        });
        this.savePointer(event.clientX, event.clientY, "up");
        window.removeEventListener(EVENT.POINTER_MOVE, onPointerMove);
        window.removeEventListener(EVENT.POINTER_UP, teardown);
        window.removeEventListener(EVENT.BLUR, teardown);
        onPointerMove.flush();
      }),
    );
    window.addEventListener(EVENT.BLUR, teardown);
    window.addEventListener(EVENT.POINTER_MOVE, onPointerMove, {
      passive: true,
    });
    window.addEventListener(EVENT.POINTER_UP, teardown);
    return true;
  };

  private updateGestureOnPointerDown(
    event: React.PointerEvent<HTMLCanvasElement>,
  ): void {
    gesture.pointers.set(event.pointerId, {
      x: event.clientX,
      y: event.clientY,
    });

    if (gesture.pointers.size === 2) {
      gesture.lastCenter = getCenter(gesture.pointers);
      gesture.initialScale = this.state.zoom.value;
      gesture.initialDistance = getDistance(
        Array.from(gesture.pointers.values()),
      );
    }
  }

  private initialPointerDownState(
    event: React.PointerEvent<HTMLCanvasElement>,
  ): PointerDownState {
    const origin = viewportCoordsToSceneCoords(event, this.state);
    const selectedElements = this.scene.getSelectedElements(this.state);
    const [minX, minY, maxX, maxY] = getCommonBounds(selectedElements);

    return {
      origin,
      withCmdOrCtrl: event[KEYS.CTRL_OR_CMD],
      originInGrid: tupleToCoors(
        getGridPoint(origin.x, origin.y, this.state.gridSize),
      ),
      scrollbars: isOverScrollBars(
        currentScrollBars,
        event.clientX - this.state.offsetLeft,
        event.clientY - this.state.offsetTop,
      ),
      // we need to duplicate because we'll be updating this state
      lastCoords: { ...origin },
      originalElements: this.scene
        .getNonDeletedElements()
        .reduce((acc, element) => {
          acc.set(element.id, deepCopyElement(element));
          return acc;
        }, new Map() as PointerDownState["originalElements"]),
      resize: {
        handleType: false,
        isResizing: false,
        offset: { x: 0, y: 0 },
        arrowDirection: "origin",
        center: { x: (maxX + minX) / 2, y: (maxY + minY) / 2 },
      },
      hit: {
        element: null,
        allHitElements: [],
        wasAddedToSelection: false,
        hasBeenDuplicated: false,
        hasHitCommonBoundingBoxOfSelectedElements:
          this.isHittingCommonBoundingBoxOfSelectedElements(
            origin,
            selectedElements,
          ),
      },
      drag: {
        hasOccurred: false,
        offset: null,
      },
      eventListeners: {
        onMove: null,
        onUp: null,
        onKeyUp: null,
        onKeyDown: null,
      },
      boxSelection: {
        hasOccurred: false,
      },
      elementIdsToErase: {},
    };
  }

  // Returns whether the event is a dragging a scrollbar
  private handleDraggingScrollBar(
    event: React.PointerEvent<HTMLCanvasElement>,
    pointerDownState: PointerDownState,
  ): boolean {
    if (
      !(pointerDownState.scrollbars.isOverEither && !this.state.multiElement)
    ) {
      return false;
    }
    isDraggingScrollBar = true;
    pointerDownState.lastCoords.x = event.clientX;
    pointerDownState.lastCoords.y = event.clientY;
    const onPointerMove = withBatchedUpdatesThrottled((event: PointerEvent) => {
      const target = event.target;
      if (!(target instanceof HTMLElement)) {
        return;
      }

      this.handlePointerMoveOverScrollbars(event, pointerDownState);
    });

    const onPointerUp = withBatchedUpdates(() => {
      isDraggingScrollBar = false;
      setCursorForShape(this.interactiveCanvas, this.state);
      lastPointerUp = null;
      this.setState({
        cursorButton: "up",
      });
      this.savePointer(event.clientX, event.clientY, "up");
      window.removeEventListener(EVENT.POINTER_MOVE, onPointerMove);
      window.removeEventListener(EVENT.POINTER_UP, onPointerUp);
      onPointerMove.flush();
    });

    lastPointerUp = onPointerUp;

    window.addEventListener(EVENT.POINTER_MOVE, onPointerMove);
    window.addEventListener(EVENT.POINTER_UP, onPointerUp);
    return true;
  }

  private clearSelectionIfNotUsingSelection = (): void => {
    if (this.state.activeTool.type !== "selection") {
      this.setState({
        selectedElementIds: {},
        selectedGroupIds: {},
        editingGroupId: null,
      });
    }
  };

  /**
   * @returns whether the pointer event has been completely handled
   */
  private handleSelectionOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    pointerDownState: PointerDownState,
  ): boolean => {
    if (this.state.activeTool.type === "selection") {
      const elements = this.scene.getNonDeletedElements();
      const elementsMap = this.scene.getNonDeletedElementsMap();
      const selectedElements = this.scene.getSelectedElements(this.state);
      if (selectedElements.length === 1 && !this.state.editingLinearElement) {
        // CHANGED:ADD 2023/02/09 #562
        let _scenePointerX = pointerDownState.origin.x;
        if (isJobElement(selectedElements[0])) {
          const _scenePointer = viewportCoordsToSceneCoords(event, {
            ...this.state,
            scrollX: 0,
          });
          _scenePointerX = _scenePointer.x;
        }
        const elementWithTransformHandleType =
          getElementWithTransformHandleType(
            elements,
            this.state,
            _scenePointerX, // CHANGED:UPDATE 2023/02/09 #562
            pointerDownState.origin.y,
            this.state.zoom,
            event.pointerType,
            elementsMap,
          );
        if (elementWithTransformHandleType != null) {
          this.setState({
            resizingElement: elementWithTransformHandleType.element,
          });
          pointerDownState.resize.handleType =
            elementWithTransformHandleType.transformHandleType;
        }
      } else if (selectedElements.length > 1) {
        pointerDownState.resize.handleType = getTransformHandleTypeFromCoords(
          getCommonBounds(selectedElements),
          pointerDownState.origin.x,
          pointerDownState.origin.y,
          this.state.zoom,
          event.pointerType,
        );
      }
      if (pointerDownState.resize.handleType) {
        setCursor(
          this.canvas,
          getCursorForResizingElement({
            transformHandleType: pointerDownState.resize.handleType,
          }),
        );
        pointerDownState.resize.isResizing = true;
        pointerDownState.resize.offset = tupleToCoors(
          getResizeOffsetXY(
            pointerDownState.resize.handleType,
            selectedElements,
            elementsMap,
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          ),
        );
        if (
          selectedElements.length === 1 &&
          isLinearElement(selectedElements[0]) &&
          selectedElements[0].points.length === 2
        ) {
          pointerDownState.resize.arrowDirection = getResizeArrowDirection(
            pointerDownState.resize.handleType,
            selectedElements[0],
          );
        }
        // CHANGED:ADD 2022-10-28 #14
        if (
          selectedElements.length === 1 &&
          isTaskElement(selectedElements[0]) &&
          selectedElements[0].points.length === 2
        ) {
          pointerDownState.resize.arrowDirection = getResizeArrowDirectionEx(
            pointerDownState.resize.handleType,
            selectedElements[0],
          );
        }
        // CHANGED:ADD 2022-11-2 #64
        if (
          selectedElements.length === 1 &&
          isLinkElement(selectedElements[0]) &&
          selectedElements[0].points.length === 2
        ) {
          pointerDownState.resize.arrowDirection = getResizeArrowDirectionEx(
            pointerDownState.resize.handleType,
            selectedElements[0],
          );
        }
      } else {
        if (this.state.selectedLinearElement) {
          const linearElementEditor =
            this.state.editingLinearElement || this.state.selectedLinearElement;
          const ret = LinearElementEditor.handlePointerDown(
            event,
            this.state,
            this.history,
            pointerDownState.origin,
            linearElementEditor,
            this,
          );
          if (ret.hitElement) {
            pointerDownState.hit.element = ret.hitElement;
          }
          if (ret.linearElementEditor) {
            this.setState({ selectedLinearElement: ret.linearElementEditor });

            if (this.state.editingLinearElement) {
              this.setState({ editingLinearElement: ret.linearElementEditor });
            }
          }
          if (ret.didAddPoint) {
            return true;
          }
        }
        // CHANGED:ADD 2022-10-28 #14
        if (this.state.selectedTaskElement) {
          const taskElementEditor = this.state.selectedTaskElement;
          const ret = TaskElementEditor.handlePointerDown(
            event,
            this.state,
            this.history,
            pointerDownState.origin,
            taskElementEditor,
            this,
          );
          if (ret.hitElement) {
            pointerDownState.hit.element = ret.hitElement;
          }
          if (ret.taskElementEditor) {
            this.setState({ selectedTaskElement: ret.taskElementEditor });
          }
          if (ret.didAddPoint) {
            return true;
          }
        }
        // CHANGED:ADD 2022-11-2 #64
        if (this.state.selectedLinkElement) {
          const linkElementEditor = this.state.selectedLinkElement;
          const ret = LinkElementEditor.handlePointerDown(
            event,
            this.state,
            this.history,
            pointerDownState.origin,
            linkElementEditor,
            this,
          );
          if (ret.hitElement) {
            pointerDownState.hit.element = ret.hitElement;
          }
          if (ret.linkElementEditor) {
            this.setState({ selectedLinkElement: ret.linkElementEditor });
          }
          if (ret.didAddPoint) {
            return true;
          }
        }
        // hitElement may already be set above, so check first
        pointerDownState.hit.element =
          pointerDownState.hit.element ??
          this.getElementAtPosition(
            pointerDownState.origin.x,
            pointerDownState.origin.y, {
              includeBoundTextElement: pointerDownState.withCmdOrCtrl, // CHANGED:ADD 2024-03-11 #1746
            }
          );
        // CHANGED:ADD 2023-02-12 #659
        if (!pointerDownState.hit.element && isJobElement(selectedElements[0])) {
          const _scenePointer = viewportCoordsToSceneCoords(event, {
            ...this.state,
            scrollX: 0,
          });
          pointerDownState.hit.element = this.getElementAtPosition(
            _scenePointer.x, 
            _scenePointer.y
          );
        }

        if (pointerDownState.hit.element) {
          // Early return if pointer is hitting link icon
          const hitLinkElement = this.getElementLinkAtPosition(
            {
              x: pointerDownState.origin.x,
              y: pointerDownState.origin.y,
            },
            pointerDownState.hit.element,
          );
          if (hitLinkElement) {
            return false;
          }
        }

        // For overlapped elements one position may hit
        // multiple elements
        pointerDownState.hit.allHitElements = this.getElementsAtPosition(
          pointerDownState.origin.x,
          pointerDownState.origin.y,
          pointerDownState.withCmdOrCtrl, // CHANGED:ADD 2024-03-11 #1746
        );

        const hitElement = pointerDownState.hit.element;
        const someHitElementIsSelected =
          pointerDownState.hit.allHitElements.some((element) =>
            this.isASelectedElement(element),
          );
        if (
          (hitElement === null || !someHitElementIsSelected) &&
          !event.shiftKey &&
          !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
        ) {
          this.clearSelection(hitElement);
        }

        if (this.state.editingLinearElement) {
          this.setState({
            selectedElementIds: {
              [this.state.editingLinearElement.elementId]: true,
            },
          });
          // If we click on something
        } else if (hitElement != null) {
          // on CMD/CTRL, drill down to hit element regardless of groups etc.
          if (event[KEYS.CTRL_OR_CMD]) {
            if (!this.state.selectedElementIds[hitElement.id]) {
              pointerDownState.hit.wasAddedToSelection = true;
            }
            this.setState((prevState) => ({
              ...editGroupForSelectedElement(prevState, hitElement),
              previousSelectedElementIds: this.state.selectedElementIds,
            }));
            // mark as not completely handled so as to allow dragging etc.
            return false;
          }

          // deselect if item is selected
          // if shift is not clicked, this will always return true
          // otherwise, it will trigger selection based on current
          // state of the box
          if (!this.state.selectedElementIds[hitElement.id]) {
            // if we are currently editing a group, exiting editing mode and deselect the group.
            if (
              this.state.editingGroupId &&
              !isElementInGroup(hitElement, this.state.editingGroupId)
            ) {
              this.setState({
                selectedElementIds: {},
                selectedGroupIds: {},
                editingGroupId: null,
              });
            }

            // Add hit element to selection. At this point if we're not holding
            // SHIFT the previously selected element(s) were deselected above
            // (make sure you use setState updater to use latest state)
            if (
              !someHitElementIsSelected &&
              !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
            ) {
              this.setState((prevState) => {
                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: {
                        ...prevState.selectedElementIds,
                        [hitElement.id]: true,
                      },
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this,
                  ),
                  showHyperlinkPopup: hitElement.link ? "info" : false,
                }
              });
              pointerDownState.hit.wasAddedToSelection = true;
            }
          }
        }

        this.setState({
          previousSelectedElementIds: this.state.selectedElementIds,
        });
      }
    }
    return false;
  };

  private isASelectedElement(hitElement: ExcalidrawElement | null): boolean {
    return hitElement != null && this.state.selectedElementIds[hitElement.id];
  }

  private isHittingCommonBoundingBoxOfSelectedElements(
    point: Readonly<{ x: number; y: number }>,
    selectedElements: readonly ExcalidrawElement[],
  ): boolean {
    if (selectedElements.length < 2) {
      return false;
    }

    // How many pixels off the shape boundary we still consider a hit
    const threshold = 10 / this.state.zoom.value;
    const [x1, y1, x2, y2] = getCommonBounds(selectedElements);
    return (
      point.x > x1 - threshold &&
      point.x < x2 + threshold &&
      point.y > y1 - threshold &&
      point.y < y2 + threshold
    );
  }

  private handleTextOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    pointerDownState: PointerDownState,
  ): void => {
    // if we're currently still editing text, clicking outside
    // should only finalize it, not create another (irrespective
    // of state.activeTool.locked)
    if (isTextElement(this.state.editingElement)) {
      return;
    }
    let sceneX = pointerDownState.origin.x;
    let sceneY = pointerDownState.origin.y;

    const element = this.getElementAtPosition(sceneX, sceneY, {
      includeBoundTextElement: true,
    });

    let container = this.getTextBindableContainerAtPosition(sceneX, sceneY);

    if (hasBoundTextElement(element)) {
      container = element as ExcalidrawTextContainer;
      sceneX = element.x + element.width / 2;
      sceneY = element.y + element.height / 2;
    }
    this.startTextEditing({
      sceneX,
      sceneY,
      insertAtParentCenter: !event.altKey,
      container,
    });

    resetCursor(this.interactiveCanvas);
    if (!this.state.activeTool.locked) {
      this.setState({
        activeTool: updateActiveTool(this.state, { type: "selection" }),
      });
    }
  };

  private handleFreeDrawElementOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    elementType: ExcalidrawFreeDrawElement["type"],
    pointerDownState: PointerDownState,
  ) => {
    // Begin a mark capture. This does not have to update state yet.
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      null,
    );

    const element = newFreeDrawElement({
      type: elementType,
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      roundness: null,
      simulatePressure: event.pressure === 0.5,
      locked: false,
      priority: PRIORITY["freedraw"], // CHANGED:ADD 2023-01-23 #391
      layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
    });
    this.setState((prevState) => {
      const nextSelectedElementIds = {
        ...prevState.selectedElementIds,
      };
      delete nextSelectedElementIds[element.id];
      return {
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedElementIds,
          prevState,
        ),
      };
    });

    const pressures = element.simulatePressure
      ? element.pressures
      : [...element.pressures, event.pressure];

    mutateElement(element, {
      points: [[0, 0]],
      pressures,
    });

    const boundElement = getHoveredElementForBinding(
      pointerDownState.origin,
      this,
    );
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      element,
    ]);
    this.setState({
      draggingElement: element,
      editingElement: element,
      startBoundElement: boundElement,
      suggestedBindings: [],
    });
  };

  private createImageElement = ({
    sceneX,
    sceneY,
  }: {
    sceneX: number;
    sceneY: number;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);

    const element = newImageElement({
      type: "image",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      roundness: null,
      opacity: this.state.currentItemOpacity,
      locked: false,
      priority: PRIORITY["image"], // CHANGED:ADD 2023-01-23 #391
      layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
    });

    return element;
  };

  private handleLinearElementOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    elementType: ExcalidrawLinearElement["type"],
    pointerDownState: PointerDownState,
  ): void => {
    if (this.state.multiElement) {
      const { multiElement } = this.state;

      // finalize if completing a loop
      if (
        multiElement.type === "line" &&
        isPathALoop(multiElement.points, this.state.zoom.value)
      ) {
        mutateElement(multiElement, {
          lastCommittedPoint:
            multiElement.points[multiElement.points.length - 1],
        });
        this.actionManager.executeAction(actionFinalize);
        return;
      }

      const { x: rx, y: ry, lastCommittedPoint } = multiElement;

      // clicking inside commit zone → finalize arrow
      if (
        multiElement.points.length > 1 &&
        lastCommittedPoint &&
        distance2d(
          pointerDownState.origin.x - rx,
          pointerDownState.origin.y - ry,
          lastCommittedPoint[0],
          lastCommittedPoint[1],
        ) < LINE_CONFIRM_THRESHOLD
      ) {
        this.actionManager.executeAction(actionFinalize);
        return;
      }

      this.setState((prevState) => ({
        selectedElementIds: {
          ...prevState.selectedElementIds,
          [multiElement.id]: true,
        },
      }));
      // clicking outside commit zone → update reference for last committed
      // point
      mutateElement(multiElement, {
        lastCommittedPoint: multiElement.points[multiElement.points.length - 1],
      });
      setCursor(this.interactiveCanvas, CURSOR_TYPE.POINTER);
    } else {
      const [gridX, gridY] = getGridPoint(
        pointerDownState.origin.x,
        pointerDownState.origin.y,
        this.state.gridSize,
      );

      /* If arrow is pre-arrowheads, it will have undefined for both start and end arrowheads.
      If so, we want it to be null for start and "arrow" for end. If the linear item is not
      an arrow, we want it to be null for both. Otherwise, we want it to use the
      values from appState. */

      const { currentItemStartArrowhead, currentItemEndArrowhead } = this.state;
      const [startArrowhead, endArrowhead] =
        elementType === "arrow"
          ? [currentItemStartArrowhead, currentItemEndArrowhead]
          : [null, null];

      const element = newLinearElement({
        type: elementType,
        x: gridX,
        y: gridY,
        strokeColor: this.state.currentItemStrokeColor,
        backgroundColor: this.state.currentItemBackgroundColor,
        fillStyle: this.state.currentItemFillStyle,
        strokeWidth: this.state.currentItemStrokeWidth,
        strokeStyle: this.state.currentItemStrokeStyle,
        roughness: this.state.currentItemRoughness,
        opacity: this.state.currentItemOpacity,
        roundness:
          this.state.currentItemRoundness === "round"
            ? { type: ROUNDNESS.PROPORTIONAL_RADIUS }
            : null,
        startArrowhead,
        endArrowhead,
        locked: false,
        priority: PRIORITY["line"], // CHANGED:ADD 2023-01-23 #391
        layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
      });
      this.setState((prevState) => {
        const nextSelectedElementIds = {
          ...prevState.selectedElementIds,
        };
        delete nextSelectedElementIds[element.id];
        return {
          selectedElementIds: makeNextSelectedElementIds(
            nextSelectedElementIds,
            prevState,
          ),
        };
      });
      mutateElement(element, {
        points: [...element.points, [0, 0]],
      });
      const boundElement = getHoveredElementForBinding(
        pointerDownState.origin,
        this,
      );
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted(),
        element,
      ]);
      this.setState({
        draggingElement: element,
        editingElement: element,
        startBoundElement: boundElement,
        suggestedBindings: [],
      });
    }
  };

  // CHANGED:ADD 2022-10-28 #14
  private handleTaskElementOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    elementType: ExcalidrawTaskElement["type"],
    pointerDownState: PointerDownState,
  ): void => {
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.state.gridSize,
    );

    /* If arrow is pre-arrowheads, it will have undefined for both start and end arrowheads.
    If so, we want it to be null for start and "dot" for end. If the task item is not
    an arrow, we want it to be null for both. Otherwise, we want it to use the
    values from appState. */

    // UPDATED: ADD 2023-03-01 #740
    const job = Job.getJobElementFromGivenGridY(
      pointerDownState.origin.y,
      this.scene.getNonDeletedElements(),
    );
    if (job === undefined || job.isCompressed) {
      this.setState({
        toast: {
          message: t("toast.warning.placementOnCompressedJobElement"),
          duration: WARNING_TOAST_DEFAULT_DURATION
        },
        editingElement: null,
        draggingElement: null,
        startBoundElementEx: null,
        suggestedBindingsEx: [],
      });
      return;
    }

    const jobOffsetY = gridY - job.y;
    const element = newTaskElement(
      {
        x: gridX,
        y: gridY,
        strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
        strokeWidth: this.state.currentItemStrokeWidthTask, // CHANGED:ADD 2023/2/9 #601
        startArrowhead: this.state.currentItemStartArrowheadEx, // CHANGED:ADD 2024-04-15 #1931
        endArrowhead: this.state.currentItemEndArrowheadEx, // CHANGED:ADD 2024-03-24 #1845
        points: [
          [0, 0],
          [0, 0],
        ],
        priority: PRIORITY["task"], // CHANGED:ADD 2023-01-23 #391
        jobId: job.id, // UPDATED: ADD 2023-03-01 #740
        jobOffsetY, // UPDATED: ADD 2023-03-01 #740
        layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
      },
      this.calendar, // CHANGED:ADD 2022-11-21 #164
    );
    this.setState((prevState) => {
      const nextSelectedElementIds = {
        ...prevState.selectedElementIds,
      };
      delete nextSelectedElementIds[element.id];
      return {
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedElementIds,
          prevState,
        ),
      };
    });
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      element,
    ]);
    this.setState({
      draggingElement: element,
      editingElement: element,
      startBoundElementEx: null, // CHANGED:UPDATE 2022-11-10 #113
      suggestedBindingsEx: [],
    });
  };

  // CHANGED:ADD 2023-12-20 #1138
  private handleCommentElementOnPointerDown = () => {
    // エレメントについて送信された過去のコメントの表示
  };

  // CHANGED:ADD 2022-12-7 #157
  private handleMilestoneElementOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    elementType: ExcalidrawMilestoneElement["type"],
    pointerDownState: PointerDownState,
  ): void => {
    const [gridX, gridY] = getGridPointEx(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.state.gridSize,
      false,
    );

    /* If arrow is pre-arrowheads, it will have undefined for both start and end arrowheads.
    If so, we want it to be null for start and "arrow" for end. If the task item is not
    an arrow, we want it to be null for both. Otherwise, we want it to use the
    values from appState. */

    // UPDATED: ADD 2023-03-01 #740
    const job = Job.getJobElementFromGivenGridY(
      pointerDownState.origin.y,
      this.scene.getNonDeletedElements(),
    );
    if (job === undefined || job.isCompressed) {
      this.setState({
        toast: {
          message: t("toast.warning.placementOnCompressedJobElement"),
          duration: WARNING_TOAST_DEFAULT_DURATION,
        },
        editingElement: null,
        draggingElement: null,
        startBoundElementEx: null,
        suggestedBindingsEx: [],
      });
      return;
    }
    
    const jobOffsetY = gridY - job.y; 
    const gridSize = this.state.gridSize ? this.state.gridSize : GRID_SIZE;
    const element = newMilestoneElement(
      {
        x: gridX,
        y: gridY,
        width: gridSize,
        height: gridSize,
        priority: PRIORITY["milestone"], // CHANGED:ADD 2023-01-23 #391
        jobId: job.id, // UPDATED: ADD 2023-03-01 #740
        jobOffsetY, // UPDATED: ADD 2023-03-01 #740
        layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
      },
      this.calendar, // CHANGED:ADD 2022-11-21 #164
    );

    // CHANGED:ADD 2023-2-11 #671
    const milestoneLineElements = generateMilestoneLineElements(
      [element],
      this.state.jobsHeight,
    );
    this.scene.GenerateMilestoneLineElements([
      ...this.scene.milestoneLineElements,
      ...milestoneLineElements,
    ]);

    this.setState((prevState) => {
      const nextSelectedElementIds = {
        ...prevState.selectedElementIds,
      };
      delete nextSelectedElementIds[element.id];
      return {
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedElementIds,
          prevState,
        ),
      };
    });
    // CHANGED:ADD 2022-11-15 #130
    const startBindingElement = getHoveredElementForBindingEx(
      pointerDownState.origin,
      this,
      "start",
    );

    if (startBindingElement) {
      if (isTaskElement(startBindingElement)) {
        const linkElement = newLinkElement(
          {
            x: element.x + element.width / 2,
            y: element.y + element.height / 2,
            strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
            strokeWidth: this.state.currentItemStrokeWidthLink, // CHANGED:ADD 2023/2/9 #601
            points: [
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
            ],
            isClosed: startBindingElement.isClosed, // CHANGD:ADD 2023/09/28 #1125
            priority: PRIORITY["link"], // CHANGED:ADD 2023-01-23 #391
            layer: startBindingElement.layer, // CHANGED:ADD 2024-10-5 #2114
          },
          this.state.currentItemPointerDirection, //CHANGED:ADD 2024-03-11 #1749
        );
        // CHANGED:ADD 2022-11-29 #215
        mutateElement(startBindingElement, {
          nextDependencies: (startBindingElement.nextDependencies || []).concat(
            element.id,
          ),
        });
        bindOrUnbindLinkElement(
          linkElement,
          startBindingElement,
          element,
          this.scene.getNonDeletedElementsMap(),
        );
        this.scene.replaceAllElements([
          ...this.scene.getElementsIncludingDeleted(),
          linkElement,
        ]);

        // CHANGED:ADD 2023-2-8 #390
        const textElement = newTextElementEx({
          x: 0,
          y: 0,
          strokeColor: this.state.currentItemStrokeColor,// CHANGED:ADD 2023/1/27 #532
          text: "",
          fontSize: this.state.currentItemFontSize,// CHANGED:UPDATE 2023/1/27 #532
          fontFamily: 2,
          textAlign: this.state.currentItemTextAlign,// CHANGED:UPDATE 2023/1/27 #532
          verticalAlign: VERTICAL_ALIGN.MIDDLE,
          containerId: linkElement.id,
          originalText: "",
          locked: false,
          priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
          layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
        });
        mutateElement(linkElement, {
          boundElements: (linkElement.boundElements || []).concat({
            type: "text",
            id: textElement.id,
          }),
        });

        const boundTextCoords = LinkElementEditor.getBoundTextElementPosition(
          linkElement,
          textElement as ExcalidrawTextElementWithContainer,
          this.scene.getNonDeletedElementsMap(),
        );
        const coordX = boundTextCoords.x;
        const coordY = boundTextCoords.y;

        mutateElement(textElement, {
          x: coordX,
          y: coordY,
        });
        const linkElementIndex = this.scene.getElementIndex(linkElement.id);
        this.scene.insertElementAtIndex(textElement, linkElementIndex + 1);
      }
    }
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      element,
    ]);
    this.setState({
      editingElement: null,
      draggingElement: null,
      startBoundElementEx: null, // CHANGED:UPDATE 2022-11-10 #113
      suggestedBindingsEx: [],
    });
  };

  // CHANGED:ADD 2022-11-2 #64
  private handleLinkElementOnPointerDown = (
    event: React.PointerEvent<HTMLCanvasElement>,
    elementType: ExcalidrawLinkElement["type"],
    pointerDownState: PointerDownState,
  ): void => {
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.state.gridSize,
    );

    const element = newLinkElement(
      {
        x: gridX,
        y: gridY,
        strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
        strokeWidth: this.state.currentItemStrokeWidthLink, // CHANGED:ADD 2023/2/9 #601
        points: [[0, 0]],
        priority: PRIORITY["link"], // CHANGED:ADD 2023-01-23 #391
        layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
      },
      this.state.currentItemPointerDirection, //CHANGED:ADD 2024-03-11 #1749
    );
    this.setState((prevState) => {
      const nextSelectedElementIds = {
        ...prevState.selectedElementIds,
      };
      delete nextSelectedElementIds[element.id];
      return {
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedElementIds,
          prevState,
        ),
      };
    });
    const boundElement = getHoveredElementForBindingEx(
      pointerDownState.origin,
      this,
      "start", // CHANGED:ADD 2022-11-11 #116
    );
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      element,
    ]);
    this.setState({
      draggingElement: element,
      editingElement: element,
      startBoundElementEx: boundElement,
      suggestedBindingsEx: [],
    });
  };

  private createGenericElementOnPointerDown = (
    elementType: ExcalidrawGenericElement["type"],
    pointerDownState: PointerDownState,
  ): void => {
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.state.gridSize,
    );
    const element = newElement({
      type: elementType,
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      locked: false,
      priority: PRIORITY[elementType], // CHANGED:ADD 2023-01-23 #391
      layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
    });

    if (element.type === "selection") {
      this.setState({
        selectionElement: element,
        draggingElement: element,
      });
    } else {
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted(),
        element,
      ]);
      this.setState({
        multiElement: null,
        draggingElement: element,
        editingElement: element,
      });
    }
  };

  private onKeyDownFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: KeyboardEvent) => void {
    return withBatchedUpdates((event: KeyboardEvent) => {
      if (this.maybeHandleResize(pointerDownState, event)) {
        return;
      }
      this.maybeDragNewGenericElement(pointerDownState, event);
    });
  }

  private onKeyUpFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: KeyboardEvent) => void {
    return withBatchedUpdates((event: KeyboardEvent) => {
      // Prevents focus from escaping excalidraw tab
      event.key === KEYS.ALT && event.preventDefault();
      if (this.maybeHandleResize(pointerDownState, event)) {
        return;
      }
      this.maybeDragNewGenericElement(pointerDownState, event);
    });
  }

  private onPointerMoveFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ) {
    return withBatchedUpdatesThrottled((event: PointerEvent) => {
      // We need to initialize dragOffsetXY only after we've updated
      // `state.selectedElementIds` on pointerDown. Doing it here in pointerMove
      // event handler should hopefully ensure we're already working with
      // the updated state.
      if (pointerDownState.drag.offset === null) {
        pointerDownState.drag.offset = tupleToCoors(
          getDragOffsetXY(
            this.scene.getSelectedElements(this.state),
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          ),
        );
      }
      const target = event.target;
      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (this.handlePointerMoveOverScrollbars(event, pointerDownState)) {
        return;
      }

      const pointerCoords = viewportCoordsToSceneCoords(event, this.state);

      if (isEraserActive(this.state)) {
        this.handleEraser(event, pointerDownState, pointerCoords);
        return;
      }

      if (this.state.activeTool.type === "laser") {
        this.laserTrails.addPointToPath(pointerCoords.x, pointerCoords.y);
      }

      const [gridX, gridY] = getGridPoint(
        pointerCoords.x,
        pointerCoords.y,
        this.state.gridSize,
      );

      // for arrows/lines, don't start dragging until a given threshold
      // to ensure we don't create a 2-point arrow by mistake when
      // user clicks mouse in a way that it moves a tiny bit (thus
      // triggering pointermove)
      if (
        !pointerDownState.drag.hasOccurred &&
        (this.state.activeTool.type === "arrow" ||
          this.state.activeTool.type === "line")
      ) {
        if (
          distance2d(
            pointerCoords.x,
            pointerCoords.y,
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          ) < DRAGGING_THRESHOLD
        ) {
          return;
        }
      }
      if (pointerDownState.resize.isResizing) {
        pointerDownState.lastCoords.x = pointerCoords.x;
        pointerDownState.lastCoords.y = pointerCoords.y;
        if (this.maybeHandleResize(pointerDownState, event)) {
          return true;
        }
      }
      const elementsMap = this.scene.getNonDeletedElementsMap();

      if (this.state.selectedLinearElement) {
        const linearElementEditor =
          this.state.editingLinearElement || this.state.selectedLinearElement;

        if (
          LinearElementEditor.shouldAddMidpoint(
            this.state.selectedLinearElement,
            pointerCoords,
            this.state,
            elementsMap,
          )
        ) {
          const ret = LinearElementEditor.addMidpoint(
            this.state.selectedLinearElement,
            pointerCoords,
            this.state,
            !event[KEYS.CTRL_OR_CMD],
            elementsMap,
          );
          if (!ret) {
            return;
          }

          // Since we are reading from previous state which is not possible with
          // automatic batching in React 18 hence using flush sync to synchronously
          // update the state. Check https://github.com/excalidraw/excalidraw/pull/5508 for more details.

          flushSync(() => {
            if (this.state.selectedLinearElement) {
              this.setState({
                selectedLinearElement: {
                  ...this.state.selectedLinearElement,
                  pointerDownState: ret.pointerDownState,
                  selectedPointsIndices: ret.selectedPointsIndices,
                },
              });
            }
            if (this.state.editingLinearElement) {
              this.setState({
                editingLinearElement: {
                  ...this.state.editingLinearElement,
                  pointerDownState: ret.pointerDownState,
                  selectedPointsIndices: ret.selectedPointsIndices,
                },
              });
            }
          });

          return;
        } else if (
          linearElementEditor.pointerDownState.segmentMidpoint.value !== null &&
          !linearElementEditor.pointerDownState.segmentMidpoint.added
        ) {
          return;
        }

        const didDrag = LinearElementEditor.handlePointDragging(
          event,
          this.state,
          pointerCoords.x,
          pointerCoords.y,
          (element, pointsSceneCoords) => {
            this.maybeSuggestBindingsForLinearElementAtCoords(
              element,
              pointsSceneCoords,
            );
          },
          linearElementEditor,
          this.scene.getNonDeletedElementsMap(),
        );
        if (didDrag) {
          pointerDownState.lastCoords.x = pointerCoords.x;
          pointerDownState.lastCoords.y = pointerCoords.y;
          pointerDownState.drag.hasOccurred = true;
          if (
            this.state.editingLinearElement &&
            !this.state.editingLinearElement.isDragging
          ) {
            this.setState({
              editingLinearElement: {
                ...this.state.editingLinearElement,
                isDragging: true,
              },
            });
          }
          if (!this.state.selectedLinearElement.isDragging) {
            this.setState({
              selectedLinearElement: {
                ...this.state.selectedLinearElement,
                isDragging: true,
              },
            });
          }
          return;
        }
      }
      // CHANGED:ADD 2022-10-28 #14
      if (this.state.selectedTaskElement) {
        const taskElementEditor = this.state.selectedTaskElement;

        const didDrag = TaskElementEditor.handlePointDragging(
          event,
          this.state,
          pointerCoords.x,
          pointerCoords.y,
          (element, pointsSceneCoords) => {
            // CHANGED:REMOVE 2022-11-2 #64
            // this.maybeSuggestBindingsForTaskElementAtCoords(
            //   element,
            //   pointsSceneCoords,
            // );
          },
          taskElementEditor,
          this.calendar, // CHANGED:ADD 2022-11-21 #164
          this.scene, // CHANGED:ADD 2022-12-28 #397
          this.scene.getNonDeletedElementsMap(),
        );

        if (didDrag) {
          pointerDownState.lastCoords.x = pointerCoords.x;
          pointerDownState.lastCoords.y = pointerCoords.y;
          pointerDownState.drag.hasOccurred = true;
          if (!this.state.selectedTaskElement.isDragging) {
            switch (taskElementEditor.pointerDownState.lastClickedPoint) {
              case 0:
              case 1:
                this.setState({
                  selectedTaskElement: {
                    ...this.state.selectedTaskElement,
                    isDragging: true,
                  },
                });
                break;
              // CHANGED:ADD 2022-11-7 #99
              case 2:
                const [gridX, gridY] = getGridPoint(
                  pointerDownState.origin.x - TASK_TO_LINK_GEN_POSITION,
                  pointerDownState.origin.y,
                  this.state.gridSize,
                );

                const boundElement = getHoveredElementForBindingEx(
                  { x: gridX, y: gridY },
                  this,
                  "start", // CHANGED:ADD 2022-11-11 #116
                );
                const element = newLinkElement(
                  {
                    x: gridX,
                    y: gridY,
                    strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
                    strokeWidth: this.state.currentItemStrokeWidthLink, // CHANGED:ADD 2023/2/9 #601
                    points: [[0, 0]],
                    priority: PRIORITY["link"], // CHANGED:ADD 2023-01-23 #391
                    layer: boundElement!.layer, // CHANGED:ADD 2024-10-5 #2114
                  },
                  this.state.currentItemPointerDirection, //CHANGED:ADD 2024-03-11 #1749
                );
                this.setState((prevState) => {
                  const nextSelectedElementIds = {
                    ...prevState.selectedElementIds,
                  };
                  delete nextSelectedElementIds[element.id];
                  delete nextSelectedElementIds[this.state.selectedTaskElement?.elementId!];
                  return {
                    selectedElementIds: makeNextSelectedElementIds(
                      nextSelectedElementIds,
                      prevState,
                    ),
                  };
                });

                this.scene.replaceAllElements([
                  ...this.scene.getElementsIncludingDeleted(),
                  element,
                ]);
                this.setState({
                  activeTool: updateActiveTool(this.state, {
                    type: "link",
                  }),
                  draggingElement: element,
                  editingElement: element,
                  startBoundElementEx: boundElement,
                  suggestedBindingsEx: [],
                });

                break;
            }
          } else {
            // CHANGED:ADD 2024-3-9 #1750
            // タスクエレメントのドラッグ中に画面端でスクロールする処理
            if (event.clientX > this.state.width - LIBRARY_SIDEBAR_WIDTH) {
              this.translateCanvas({
                scrollX: this.scroll.getOffsetScrollX(
                  this.state.scrollX - DRAG_TASK_AUTO_SCROLL / this.state.zoom.value,
                )
              });
            }
          }
          return;
        }
      }
      // CHANGED:ADD 2022-11-2 #64
      if (this.state.selectedLinkElement) {
        const linkElementEditor = this.state.selectedLinkElement;
        const didDrag = LinkElementEditor.handlePointDragging(
          event,
          this.state,
          pointerCoords.x,
          pointerCoords.y,
          (element, pointsSceneCoords, startOrEnd) => {
            this.maybeSuggestBindingsForLinkElementAtCoords(
              element,
              pointsSceneCoords,
              startOrEnd, // CHANGED:ADD 2022-11-11 #116
            );
          },
          linkElementEditor,
          this.calendar,
          this.scene,
          this.scene.getNonDeletedElementsMap(),
        );
        if (didDrag) {
          pointerDownState.lastCoords.x = pointerCoords.x;
          pointerDownState.lastCoords.y = pointerCoords.y;
          pointerDownState.drag.hasOccurred = true;
          if (!this.state.selectedLinkElement.isDragging) {
            this.setState({
              selectedLinkElement: {
                ...this.state.selectedLinkElement,
                isDragging: true,
              },
            });
          }
          return;
        }
      }

      const hasHitASelectedElement = pointerDownState.hit.allHitElements.some(
        (element) => this.isASelectedElement(element),
      );

      const isSelectingPointsInLineEditor =
        this.state.editingLinearElement &&
        event.shiftKey &&
        this.state.editingLinearElement.elementId ===
          pointerDownState.hit.element?.id;
      if (
        (hasHitASelectedElement ||
          pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements) &&
        !isSelectingPointsInLineEditor
      ) {
        const selectedElements = this.scene.getSelectedElements(this.state)
          .filter((element) => !isLinkElement(element) && // CHANGED:ADD 2022-11-24 #117
            !isCommentElement(element)); // CHANGED:ADD 2023-12-25 #1138
        if (selectedElements.every((element) => element.locked)) {
          return;
        }

        // Marking that click was used for dragging to check
        // if elements should be deselected on pointerup
        pointerDownState.drag.hasOccurred = true;
        // prevent dragging even if we're no longer holding cmd/ctrl otherwise
        // it would have weird results (stuff jumping all over the screen)
        if (selectedElements.length > 0 && !pointerDownState.withCmdOrCtrl) {
          // CHANGED:ADD 2022-12-7 #157
          const [dragX, dragY] =
            selectedElements.every((element) => isMilestoneElement(element))
              ? getGridPointEx(
                pointerCoords.x - pointerDownState.drag.offset.x,
                pointerCoords.y - pointerDownState.drag.offset.y,
                this.state.gridSize,
                true,
              )
              : getGridPoint(
                pointerCoords.x - pointerDownState.drag.offset.x,
                pointerCoords.y - pointerDownState.drag.offset.y,
                this.state.gridSize,
              );

          const [dragDistanceX, dragDistanceY] = [
            Math.abs(pointerCoords.x - pointerDownState.origin.x),
            Math.abs(pointerCoords.y - pointerDownState.origin.y),
          ];

          // We only drag in one direction if shift is pressed
          const lockDirection = event.shiftKey;
          dragSelectedElements(
            pointerDownState,
            selectedElements,
            dragX,
            dragY,
            lockDirection,
            dragDistanceX,
            dragDistanceY,
            this.state,
            this.calendar, // CHANGED:ADD 2022-11-21 #164
            this.scene, // CHANGED:ADD 2022-12-12 #266
          );
          this.maybeSuggestBindingForAll(selectedElements);

          this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455

          // We duplicate the selected element if alt is pressed on pointer move
          // CHANGED:UPDATE 2022-11-25 #202 Alt+ドラッグでの複製を一旦無効にする
          // if (event.altKey && !pointerDownState.hit.hasBeenDuplicated) {
          //   // Move the currently selected elements to the top of the z index stack, and
          //   // put the duplicates where the selected elements used to be.
          //   // (the origin point where the dragging started)

          //   pointerDownState.hit.hasBeenDuplicated = true;

          //   const nextElements = [];
          //   const elementsToAppend = [];
          //   const groupIdMap = new Map();
          //   const oldIdToDuplicatedId = new Map();
          //   const hitElement = pointerDownState.hit.element;
          //   const selectedElementIds = new Set(
          //     this.scene
          //       .getSelectedElements({
          //         selectedElementIds: this.state.selectedElementIds,
          //         includeBoundTextElement: true,
          //         includeElementsInFrames: true,
          //       })
          //       .map((element) => element.id),
          //   );

          //   const elements = this.scene.getNonDeletedElements();

          //   for (const element of elements) {
          //     if (
          //       selectedElementIds.has(element.id) ||
          //       // case: the state.selectedElementIds might not have been
          //       // updated yet by the time this mousemove event is fired
          //       (element.id === hitElement?.id &&
          //         pointerDownState.hit.wasAddedToSelection)
          //     ) {
          //       const duplicatedElement = duplicateElement(
          //         this.state.editingGroupId,
          //         groupIdMap,
          //         element,
          //       );
          //       const [originDragX, originDragY] = getGridPoint(
          //         pointerDownState.origin.x - pointerDownState.drag.offset.x,
          //         pointerDownState.origin.y - pointerDownState.drag.offset.y,
          //         this.state.gridSize,
          //       );
          //       mutateElement(duplicatedElement, {
          //         x: duplicatedElement.x + (originDragX - dragX),
          //         y: duplicatedElement.y + (originDragY - dragY),
          //       });
          //       nextElements.push(duplicatedElement);
          //       elementsToAppend.push(element);
          //       oldIdToDuplicatedId.set(element.id, duplicatedElement.id);
          //     } else {
          //       nextElements.push(element);
          //     }
          //   }
          //   const nextSceneElements = [...nextElements, ...elementsToAppend];
          //   bindTextToShapeAfterDuplication(
          //     nextElements,
          //     elementsToAppend,
          //     oldIdToDuplicatedId,
          //   );
          //   fixBindingsAfterDuplication(
          //     nextSceneElements,
          //     elementsToAppend,
          //     oldIdToDuplicatedId,
          //     "duplicatesServeAsOld",
          //   );
          //   // CHANGED:ADD 2022-10-28 #14
          //   fixBindingsAfterDuplicationEx(
          //     nextSceneElements,
          //     elementsToAppend,
          //     oldIdToDuplicatedId,
          //     "duplicatesServeAsOld",
          //   );
          //   this.scene.replaceAllElements(nextSceneElements);
          // }
          return;
          // CHANGED:ADD 2024-3-11 #1746
        } else if (
          selectedElements.length === 1 &&
          pointerDownState.withCmdOrCtrl &&
          isTextElement(selectedElements[0])
        ) {
          const boundTextElement = selectedElements[0];
          const container = getContainerElement(selectedElements[0], this.scene.getNonDeletedElementsMap());
          if (isTaskElement(container)) {
            mutateElement(selectedElements[0], {
              offsetX: pointerCoords.x - container.x -
                (boundTextElement.textDirection === TEXT_DIRECTION.HORIZONTAL
                  ? BOUNDTEXT_OFFSET_X_HORIZONTAL
                  : BOUNDTEXT_OFFSET_X_VERTICAL),
              offsetY: pointerCoords.y - container.y + BOUNDTEXT_OFFSET_Y,
            });

            return;
          }
        }
      }

      // It is very important to read this.state within each move event,
      // otherwise we would read a stale one!
      const draggingElement = this.state.draggingElement;
      if (!draggingElement) {
        return;
      }

      if (draggingElement.type === "freedraw") {
        const points = draggingElement.points;
        const dx = pointerCoords.x - draggingElement.x;
        const dy = pointerCoords.y - draggingElement.y;

        const lastPoint = points.length > 0 && points[points.length - 1];
        const discardPoint =
          lastPoint && lastPoint[0] === dx && lastPoint[1] === dy;

        if (!discardPoint) {
          const pressures = draggingElement.simulatePressure
            ? draggingElement.pressures
            : [...draggingElement.pressures, event.pressure];

          mutateElement(draggingElement, {
            points: [...points, [dx, dy]],
            pressures,
          });
        }
      } else if (isLinearElement(draggingElement)) {
        pointerDownState.drag.hasOccurred = true;
        const points = draggingElement.points;
        let dx = gridX - draggingElement.x;
        let dy = gridY - draggingElement.y;

        if (shouldRotateWithDiscreteAngle(event) && points.length === 2) {
          ({ width: dx, height: dy } = getLockedLinearCursorAlignSize(
            draggingElement.x,
            draggingElement.y,
            pointerCoords.x,
            pointerCoords.y,
          ));
        }

        if (points.length === 1) {
          mutateElement(draggingElement, {
            points: [...points, [dx, dy]],
          });
        } else if (points.length === 2) {
          mutateElement(draggingElement, {
            points: [...points.slice(0, -1), [dx, dy]],
          });
        }

        if (isBindingElement(draggingElement, false)) {
          // When creating a linear element by dragging
          this.maybeSuggestBindingsForLinearElementAtCoords(
            draggingElement,
            [pointerCoords],
            this.state.startBoundElement,
          );
        }
        // CHANGED:ADD 2022-10-28 #14
      } else if (isTaskElement(draggingElement)) {
        pointerDownState.drag.hasOccurred = true;
        const points = draggingElement.points;
        let dx = gridX - draggingElement.x;
        const dy = gridY - draggingElement.y;
        //CHANGED:REMOVE 2022-11-01 #65
        // if (shouldRotateWithDiscreteAngle(event) && points.length === 2) {
        //   ({ width: dx, height: dy } = getLockedLinearCursorAlignSize(
        //     draggingElement.x,
        //     draggingElement.y,
        //     pointerCoords.x,
        //     pointerCoords.y,
        //   ));
        // }

        //CHANGED:REMOVE 2022-11-01 #79
        // dx = dx < 0? 0:dx; //CHANGED:ADD 2022-10-31 #65
        if (points.length === 1) {
          mutateElement(draggingElement, {
            points: [...points, [dx, 0]],
          });
        } else if (points.length === 2) {
          const gridSize = this.state.gridSize ? this.state.gridSize : 0;
          dx = dx <= 0 ? gridSize: dx; //CHANGED:ADD 2022-11-01 #79
          // CHANGED:ADD 2023-1-13 #416
          dx = Math.min(
            JOB_ELEMENTS_WIDTH + this.state.calendarWidth - draggingElement.x,
            dx,
          );

          // CHANGED:UPDATE 2022-11-21 #164
          const endDate = this.calendar.getPointDate(draggingElement.x + dx);
          // CHANGED:ADD 2022-11-15 #114
          const duration = this.calendar.getDuration(
            draggingElement.startDate,
            endDate,
            draggingElement.holidays, // CHANGED:ADD 2023-1-20 #382
          );

          mutateElement(draggingElement, {
            points: [...points.slice(0, -1), [dx, 0]], //CHANGED:UPDATE 2022-10-31 #65
            endDate, // CHANGED:ADD 2022-11-01 #79,
            duration, // CHANGED:ADD 2022-11-15 #114
          });

          // タスクエレメントのドラッグ中に画面端でスクロールする処理
          if (event.clientX > this.state.width - LIBRARY_SIDEBAR_WIDTH) {
            this.translateCanvas({
              scrollX: this.scroll.getOffsetScrollX(
                this.state.scrollX - DRAG_TASK_AUTO_SCROLL / this.state.zoom.value,
              )
            });
          }
        }

        // CHANGED:REMOVE 2022-11-2 #64
        // if (isBindingElementEx(draggingElement, false)) {
        //   // When creating a task element by dragging
        //   this.maybeSuggestBindingsForTaskElementAtCoords(
        //     draggingElement,
        //     [pointerCoords],
        //     this.state.startBoundElementEx,
        //   );
        // }
        // CHANGED:ADD 2022-11-2 #64
      } else if (isLinkElement(draggingElement)) {
        pointerDownState.drag.hasOccurred = true;
        const points = draggingElement.points;
        let dx = gridX - draggingElement.x;
        let dy = gridY - draggingElement.y;

        if (shouldRotateWithDiscreteAngle(event) && points.length === 2) {
          ({ width: dx, height: dy } = getLockedLinearCursorAlignSize(
            draggingElement.x,
            draggingElement.y,
            pointerCoords.x,
            pointerCoords.y,
          ));
        }

        if (points.length === 1) {
          mutateElement(draggingElement, {
            points: [...points, [dx, dy]],
          });
        } else if (points.length === 2) {
          mutateElement(draggingElement, {
            points: [...points.slice(0, -1), [dx, dy]],
          });
        }

        // CHANGED:ADD 2024-3-9 #1750
        let isRightCollision = false;
        let isTopCollision = false;
        let isBottomCollision = false;

        if (event.clientX > this.state.width - LIBRARY_SIDEBAR_WIDTH) {
          isRightCollision = true;
        }

        if (event.clientY - 110 < CANVAS_HEADER_HEIGHT) {
          isTopCollision = true;
        } else if (event.clientY > this.state.height) {
          isBottomCollision = true;
        }

        // リンクエレメントのドラッグ中に画面端でスクロールする処理
        if (isRightCollision || isTopCollision || isBottomCollision) {
          this.translateCanvas({
            scrollX: isRightCollision
              ? this.scroll.getOffsetScrollX(
                this.state.scrollX - DRAG_LINK_AUTO_SCROLL / this.state.zoom.value,
              )
              : this.state.scrollX,
            scrollY: isTopCollision
              ? this.scroll.getOffsetScrollX(
                this.state.scrollY + DRAG_LINK_AUTO_SCROLL / this.state.zoom.value,
              )
              : isBottomCollision
                ? this.scroll.getOffsetScrollX(
                  this.state.scrollY - DRAG_LINK_AUTO_SCROLL / this.state.zoom.value,
                )
                : this.state.scrollY,
          });
        }

        if (isBindingElementEx(draggingElement, false)) {
          // When creating a link element by dragging
          this.maybeSuggestBindingsForLinkElementAtCoords(
            draggingElement,
            [pointerCoords],
            "end", // CHANGED:ADD 2022-11-11 #116
            this.state.startBoundElementEx,
          );
        }
      } else {
        pointerDownState.lastCoords.x = pointerCoords.x;
        pointerDownState.lastCoords.y = pointerCoords.y;
        this.maybeDragNewGenericElement(pointerDownState, event);
      }

      if (this.state.activeTool.type === "selection") {
        pointerDownState.boxSelection.hasOccurred = true;

        const elements = this.scene.getNonDeletedElements();
        if (
          !event.shiftKey &&
          // allows for box-selecting points (without shift)
          !this.state.editingLinearElement &&
          isSomeElementSelected(elements, this.state)
        ) {
          if (pointerDownState.withCmdOrCtrl && pointerDownState.hit.element) {
            this.setState((prevState) =>
              selectGroupsForSelectedElements(
                {
                  editingGroupId: prevState.editingGroupId,
                  selectedElementIds: {
                    [pointerDownState.hit.element!.id]: true,
                  },
                },
                this.scene.getNonDeletedElements(),
                prevState,
                this,
              ),
            );
          } else {
            this.setState({
              selectedElementIds: {},
              selectedGroupIds: {},
              editingGroupId: null,
            });
          }
        }
        // box-select line editor points
        if (this.state.editingLinearElement) {
          LinearElementEditor.handleBoxSelection(
            event,
            this.state,
            this.setState.bind(this),
            this.scene.getNonDeletedElementsMap(),
          );
          // regular box-select
        } else {
          let shouldReuseSelection = true;

          if (!event.shiftKey && isSomeElementSelected(elements, this.state)) {
            if (
              pointerDownState.withCmdOrCtrl &&
              pointerDownState.hit.element
            ) {
              this.setState((prevState) =>
                selectGroupsForSelectedElements(
                  {
                    ...prevState,
                    selectedElementIds: {
                      [pointerDownState.hit.element!.id]: true,
                    },
                  },
                  this.scene.getNonDeletedElements(),
                  prevState,
                  this,
                ),
              );
            } else {
              shouldReuseSelection = false;
            }
          }
          const elementsWithinSelection = getElementsWithinSelection(
            elements,
            draggingElement,
            elementsMap,
            this.state, // CHANGED:ADD 2024-10-5 #2114
          );

          this.setState((prevState) => {
            const nextSelectedElementIds = {
              ...(shouldReuseSelection && prevState.selectedElementIds),
              ...elementsWithinSelection.reduce(
                (acc: Record<ExcalidrawElement["id"], true>, element) => {
                  acc[element.id] = true;
                  return acc;
                },
                {},
              ),
            };

            if (pointerDownState.hit.element) {
              // if using ctrl/cmd, select the hitElement only if we
              // haven't box-selected anything else
              if (!elementsWithinSelection.length) {
                nextSelectedElementIds[pointerDownState.hit.element.id] = true;
              } else {
                delete nextSelectedElementIds[pointerDownState.hit.element.id];
              }
            }

            prevState = !shouldReuseSelection
              ? { ...prevState, selectedGroupIds: {}, editingGroupId: null }
              : prevState;

            return {
              ...selectGroupsForSelectedElements(
                {
                  editingGroupId: prevState.editingGroupId,
                  selectedElementIds: nextSelectedElementIds,
                },
                this.scene.getNonDeletedElements(),
                prevState,
                this,
              ),
              showHyperlinkPopup:
                elementsWithinSelection.length === 1 &&
                  elementsWithinSelection[0].link
                  ? "info"
                  : false,
              // select linear element only when we haven't box-selected anything else
              selectedLinearElement:
                elementsWithinSelection.length === 1 &&
                  isLinearElement(elementsWithinSelection[0])
                  ? new LinearElementEditor(
                    elementsWithinSelection[0],
                  )
                  : null,
              // CHANGED:ADD 2022-10-28 #14
              // select task element only when we haven't box-selected anything else
              selectedTaskElement:
                elementsWithinSelection.length === 1 &&
                  isTaskElement(elementsWithinSelection[0])
                  ? new TaskElementEditor(
                    elementsWithinSelection[0],
                    this.scene,
                    this.state,
                  )
                  : null,
              // CHANGED:ADD 2022-11-2 #64
              // select link element only when we haven't box-selected anything else
              selectedLinkElement:
                elementsWithinSelection.length === 1 &&
                  isLinkElement(elementsWithinSelection[0])
                  ? new LinkElementEditor(
                    elementsWithinSelection[0],
                    this.scene,
                    this.state,
                  )
                  : null,
            };
          });
        }
      }
    });
  }

  // Returns whether the pointer move happened over either scrollbar
  private handlePointerMoveOverScrollbars(
    event: PointerEvent,
    pointerDownState: PointerDownState,
  ): boolean {
    if (pointerDownState.scrollbars.isOverHorizontal) {
      const x = event.clientX;
      const dx = x - pointerDownState.lastCoords.x;
      this.translateCanvas({
        // CHANGED:UPDATE 2022-11-15 #134
        // scrollX: this.state.scrollX - dx / this.state.zoom.value,
        scrollX: this.scroll.getOffsetScrollX(
          this.state.scrollX - dx / this.state.zoom.value,
        ),
      });
      pointerDownState.lastCoords.x = x;
      return true;
    }

    if (pointerDownState.scrollbars.isOverVertical) {
      const y = event.clientY;
      const dy = y - pointerDownState.lastCoords.y;
      this.translateCanvas({
        scrollY: this.state.scrollY - dy / this.state.zoom.value,
      });
      pointerDownState.lastCoords.y = y;
      return true;
    }
    return false;
  }

  private onPointerUpFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: PointerEvent) => void {
    return withBatchedUpdates((childEvent: PointerEvent) => {
      const {
        draggingElement,
        resizingElement,
        multiElement,
        activeTool,
        isResizing,
        isRotating,
      } = this.state;
      this.setState({
        isResizing: false,
        isRotating: false,
        resizingElement: null,
        selectionElement: null,
        cursorButton: "up",
        // text elements are reset on finalize, and resetting on pointerup
        // may cause issues with double taps
        editingElement:
          multiElement || isTextElement(this.state.editingElement)
            ? this.state.editingElement
            : null,
      });

      this.savePointer(childEvent.clientX, childEvent.clientY, "up");

      const elementsMap = this.scene.getNonDeletedElementsMap();
      // Handle end of dragging a point of a linear element, might close a loop
      // and sets binding element
      if (this.state.editingLinearElement) {
        if (
          !pointerDownState.boxSelection.hasOccurred &&
          pointerDownState.hit?.element?.id !==
            this.state.editingLinearElement.elementId
        ) {
          this.actionManager.executeAction(actionFinalize);
        } else {
          const editingLinearElement = LinearElementEditor.handlePointerUp(
            childEvent,
            this.state.editingLinearElement,
            this.state,
            this,
          );
          if (editingLinearElement !== this.state.editingLinearElement) {
            this.setState({
              editingLinearElement,
              suggestedBindings: [],
            });
          }
        }
      } else if (this.state.selectedLinearElement) {
        if (
          pointerDownState.hit?.element?.id !==
          this.state.selectedLinearElement.elementId
        ) {
          const selectedELements = this.scene.getSelectedElements(this.state);
          // set selectedLinearElement to null if there is more than one element selected since we don't want to show linear element handles
          if (selectedELements.length > 1) {
            this.setState({ selectedLinearElement: null });
          }
        } else {
          const linearElementEditor = LinearElementEditor.handlePointerUp(
            childEvent,
            this.state.selectedLinearElement,
            this.state,
            this,
          );

          const { startBindingElement, endBindingElement } =
            linearElementEditor;
          const element = this.scene.getElement(linearElementEditor.elementId);
          if (isBindingElement(element)) {
            bindOrUnbindLinearElement(
              element,
              startBindingElement,
              endBindingElement,
              elementsMap,
            );
          }

          if (linearElementEditor !== this.state.selectedLinearElement) {
            this.setState({
              selectedLinearElement: {
                ...linearElementEditor,
                selectedPointsIndices: null,
              },
              suggestedBindings: [],
            });
          }
        }
      }

      // CHANGED:ADD 2022-11-30 #214
      if (this.state.selectedTaskElement) {
        if (
          pointerDownState.hit?.element?.id !==
          this.state.selectedTaskElement.elementId
        ) {
          const selectedELements = this.scene.getSelectedElements(this.state);
          // set selectedTaskElement to null if there is more than one element selected since we don't want to show task element handles
          if (selectedELements.length > 1) {
            this.setState({ selectedTaskElement: null });
          }
        } else {
          this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455

          const taskElementEditor = TaskElementEditor.handlePointerUp(
            childEvent,
            this.state.selectedTaskElement,
            this.state,
          );

          if (taskElementEditor !== this.state.selectedTaskElement) {
            this.setState({
              selectedTaskElement: {
                ...taskElementEditor,
                selectedPointsIndices: null,
              },
              suggestedBindingsEx: [],
            });
          }
        }
      }

      // CHANGED:ADD 2022-11-2 #64
      // Handle end of dragging a point of a link element, might close a loop
      // and sets binding element
      if (this.state.selectedLinkElement) {
        if (
          pointerDownState.hit?.element?.id !==
          this.state.selectedLinkElement.elementId
        ) {
          const selectedELements = this.scene.getSelectedElements(this.state);
          // set selectedLinkElement to null if there is more than one element selected since we don't want to show link element handles
          if (selectedELements.length > 1) {
            this.setState({ selectedLinkElement: null });
          }
        } else {
          const linkElementEditor = LinkElementEditor.handlePointerUp(
            childEvent,
            this.state.selectedLinkElement,
            this.state,
            this,
          );

          const { startBindingElement, endBindingElement } = linkElementEditor;
          const element = this.scene.getElement(linkElementEditor.elementId);
          if (isBindingElementEx(element)) {
            bindOrUnbindLinkElement(
              element,
              startBindingElement,
              endBindingElement,
              elementsMap,
            );

            // CHANGED:ADD 2022-12-9 #260
            const isMultiBinding = ((this.scene.getNonDeletedElements()
              .filter((ele) => isLinkElement(ele) &&
                ele.startBinding?.elementId === element.startBinding?.elementId &&
                ele.endBinding?.elementId === element.endBinding?.elementId
              )).length > 1);

            // CHANGED:ADD 2022-11-9 #113
            if (
              !element.startBinding ||
              !element.endBinding ||
              (startBindingElement !== "keep" &&
                startBindingElement?.id === element.endBinding.elementId) ||
              (endBindingElement !== "keep" &&
                endBindingElement?.id === element.startBinding.elementId) ||
              isMultiBinding // CHANGED:ADD 2022-12-9 #260
            ) {
              // CHANGED:ADD 2022-12-14 #128
              lastPointerUp = null;

              if (pointerDownState.eventListeners.onMove) {
                pointerDownState.eventListeners.onMove.flush();
              }

              window.removeEventListener(
                EVENT.POINTER_MOVE,
                pointerDownState.eventListeners.onMove!,
              );
              window.removeEventListener(
                EVENT.POINTER_UP,
                pointerDownState.eventListeners.onUp!,
              );
              window.removeEventListener(
                EVENT.KEYDOWN,
                pointerDownState.eventListeners.onKeyDown!,
              );
              window.removeEventListener(
                EVENT.KEYUP,
                pointerDownState.eventListeners.onKeyUp!,
              );

              const beforeElement = pointerDownState.originalElements.get(element.id);
              if (isBindingElementEx(beforeElement)) {
                mutateElement(element, {
                  ...beforeElement,
                });
                // CHANGED:ADD 2023/01/23 #458
                const boundTextElement = getBoundTextElement(
                  element,
                  this.scene.getNonDeletedElementsMap(),
                );
                if (boundTextElement) {
                  const boundTextBeforeElement = pointerDownState.originalElements.get(boundTextElement.id);
                  if (boundTextBeforeElement && isBoundToContainer(boundTextBeforeElement)) {
                    this.scene.replaceBoundTextElement(
                      boundTextElement,
                      boundTextBeforeElement,
                    );
                  }
                }
              }

              (this.scene.getNonDeletedElements()
                .filter((element) => isBindableElementEx(element)) as ExcalidrawBindableElementEx[])
                .forEach((bindableElement) => {
                  const beforeBindableElement = pointerDownState.originalElements.get(bindableElement.id);
                  if (beforeBindableElement && isBindableElementEx(beforeBindableElement)) {
                    if (
                      (JSON.stringify(bindableElement.boundElementsEx) !==
                        JSON.stringify(beforeBindableElement?.boundElementsEx)) ||
                      (JSON.stringify(bindableElement.prevDependencies) !==
                        JSON.stringify(beforeBindableElement?.prevDependencies)) ||
                      (JSON.stringify(bindableElement.nextDependencies) !==
                        JSON.stringify(beforeBindableElement?.nextDependencies))
                    ) {
                      mutateElement(bindableElement, {
                        ...beforeBindableElement,
                      });
                    }
                  }
                });

              this.setState({ suggestedBindingsEx: [], startBoundElementEx: null });
              resetCursor(this.interactiveCanvas);
              return;
            } else {
              this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455
            }
          }

          if (linkElementEditor !== this.state.selectedLinkElement) {
            this.setState({
              selectedLinkElement: {
                ...linkElementEditor,
                selectedPointsIndices: null,
              },
              suggestedBindingsEx: [],
            });
          }
        }
      }

      lastPointerUp = null;

      if (pointerDownState.eventListeners.onMove) {
        pointerDownState.eventListeners.onMove.flush();
      }

      window.removeEventListener(
        EVENT.POINTER_MOVE,
        pointerDownState.eventListeners.onMove!,
      );
      window.removeEventListener(
        EVENT.POINTER_UP,
        pointerDownState.eventListeners.onUp!,
      );
      window.removeEventListener(
        EVENT.KEYDOWN,
        pointerDownState.eventListeners.onKeyDown!,
      );
      window.removeEventListener(
        EVENT.KEYUP,
        pointerDownState.eventListeners.onKeyUp!,
      );

      if (this.state.pendingImageElementId) {
        this.setState({ pendingImageElementId: null });
      }

      if (draggingElement?.type === "freedraw") {
        const pointerCoords = viewportCoordsToSceneCoords(
          childEvent,
          this.state,
        );

        const points = draggingElement.points;
        let dx = pointerCoords.x - draggingElement.x;
        let dy = pointerCoords.y - draggingElement.y;

        // Allows dots to avoid being flagged as infinitely small
        if (dx === points[0][0] && dy === points[0][1]) {
          dy += 0.0001;
          dx += 0.0001;
        }

        const pressures = draggingElement.simulatePressure
          ? []
          : [...draggingElement.pressures, childEvent.pressure];

        mutateElement(draggingElement, {
          points: [...points, [dx, dy]],
          pressures,
          lastCommittedPoint: [dx, dy],
        });

        this.actionManager.executeAction(actionFinalize);

        return;
      }
      if (isImageElement(draggingElement)) {
        const imageElement = draggingElement;
        try {
          this.initializeImageDimensions(imageElement);
          this.setState(
            { selectedElementIds: { [imageElement.id]: true } },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } catch (error: any) {
          console.error(error);
          this.scene.replaceAllElements(
            this.scene
              .getElementsIncludingDeleted()
              .filter((el) => el.id !== imageElement.id),
          );
          this.actionManager.executeAction(actionFinalize);
        }
        return;
      }

      if (isLinearElement(draggingElement)) {
        if (draggingElement!.points.length > 1) {
          this.history.resumeRecording();
        }
        const pointerCoords = viewportCoordsToSceneCoords(
          childEvent,
          this.state,
        );

        if (
          !pointerDownState.drag.hasOccurred &&
          draggingElement &&
          !multiElement
        ) {
          mutateElement(draggingElement, {
            points: [
              ...draggingElement.points,
              [
                pointerCoords.x - draggingElement.x,
                pointerCoords.y - draggingElement.y,
              ],
            ],
          });
          this.setState({
            multiElement: draggingElement,
            editingElement: this.state.draggingElement,
          });
        } else if (pointerDownState.drag.hasOccurred && !multiElement) {
          if (
            isBindingEnabled(this.state) &&
            isBindingElement(draggingElement, false)
          ) {
            maybeBindLinearElement(
              draggingElement,
              this.state,
              pointerCoords,
              this,
            );
          }
          this.setState({ suggestedBindings: [], startBoundElement: null });
          if (!activeTool.locked) {
            resetCursor(this.interactiveCanvas);
            this.setState((prevState) => ({
              draggingElement: null,
              activeTool: updateActiveTool(this.state, {
                type: "selection",
              }),
              selectedElementIds: {
                ...prevState.selectedElementIds,
                [draggingElement.id]: true,
              },
              selectedLinearElement: new LinearElementEditor(
                draggingElement,
              ),
            }));
          } else {
            this.setState((prevState) => ({
              draggingElement: null,
              selectedElementIds: {
                ...prevState.selectedElementIds,
                [draggingElement.id]: true,
              },
            }));
          }
        }
        return;
      }

      // CHANGED:ADD 2022-11-30 #214
      if (isTaskElement(draggingElement)) {
        if (pointerDownState.drag.hasOccurred && draggingElement.width > 0) {
          // CHANGED:ADD 2022-12-23 #383
          const startBindingElement = getHoveredElementForBindingEx(
            { x: draggingElement.x, y: draggingElement.y },
            this,
            "start",
          );

          if (startBindingElement) {
            const linkElement = newLinkElement(
              {
                x: draggingElement.x,
                y: draggingElement.y,
                strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
                strokeWidth: this.state.currentItemStrokeWidthLink, // CHANGED:ADD 2023/2/9 #601
                points: [
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0],
                ],
                isClosed: startBindingElement.isClosed, // CHANGD:ADD 2023/09/28 #1125
                priority: PRIORITY["link"], // CHANGED:ADD 2023-01-23 #391
                layer: startBindingElement.layer, // CHANGED:ADD 2024-10-5 #2114
              },
              this.state.currentItemPointerDirection, //CHANGED:ADD 2024-03-11 #1749
            );
            mutateElement(startBindingElement, {
              nextDependencies: (
                startBindingElement.nextDependencies || []
              ).concat(draggingElement.id),
            });
            bindOrUnbindLinkElement(
              linkElement,
              startBindingElement,
              draggingElement,
              elementsMap,
            );
            this.scene.replaceAllElements([
              ...this.scene.getElementsIncludingDeleted(),
              linkElement,
            ]);

            // CHANGED:ADD 2023/01/23 #390
            const textElement = newTextElementEx({
              x: 0,
              y: 0,
              strokeColor: this.state.currentItemStrokeColor, // CHANGED:ADD 2023/1/27 #532
              text: "", // CHANGED:UPDATE 2023-08-22 #924
              fontSize: this.state.currentItemFontSize, // CHANGED:UPDATE 2023/1/27 #532
              fontFamily: 2,
              textAlign: this.state.currentItemTextAlign, // CHANGED:UPDATE 2023/1/27 #532
              verticalAlign: VERTICAL_ALIGN.MIDDLE,
              containerId: linkElement.id,
              originalText: "",
              locked: false,
              priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
              layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
            });
            mutateElement(linkElement, {
              boundElements: (linkElement.boundElements || []).concat({
                type: "text",
                id: textElement.id,
              }),
            });

            const boundTextCoords =
              LinkElementEditor.getBoundTextElementPosition(
                linkElement,
                textElement as ExcalidrawTextElementWithContainer,
                elementsMap
              );
            const coordX = boundTextCoords.x;
            const coordY = boundTextCoords.y;

            mutateElement(textElement, {
              x: coordX,
              y: coordY,
            });
            const linkElementIndex = this.scene.getElementIndex(linkElement.id);
            this.scene.insertElementAtIndex(textElement, linkElementIndex + 1);
          }

          // this.updateCriticalPathElements(); //CHANGED:REMOVE 2023-1-23 #534

          // CHANGED:ADD 2022-12-8 #268
          const container = draggingElement;
          const midPoint = getContainerCenterEx(
            draggingElement,
            this.state,
            elementsMap,
          );

          const sceneX = midPoint.x;
          const sceneY = midPoint.y;

          // CHANGED:UPDATE 2024-08-27 #2183
          // // CHANGED:ADD 2022-12-13 #308
          // if (!this.state.activeTool.locked) {
          //   this.startTextEditing({
          //     sceneX,
          //     sceneY,
          //     insertAtParentCenter: true,
          //     container,
          //   });
          //   // CHANGED:ADD 2022-12-13 #313
          // } else {
          //   let shouldBindToContainer = false;

          //   let parentCenterPosition =
          //     this.getTextWysiwygSnappedToCenterPosition(
          //       sceneX,
          //       sceneY,
          //       this.state,
          //       container,
          //     );
          //   if (container && parentCenterPosition) {
          //     shouldBindToContainer = true;
          //   }

          //   const element = newTextElementEx({
          //     x: parentCenterPosition
          //       ? parentCenterPosition.elementCenterX
          //       : sceneX,
          //     y: parentCenterPosition
          //       ? parentCenterPosition.elementCenterY
          //       : sceneY,
          //     text: "",
          //     strokeColor: container.strokeColor, // CHANGED:UPDATE 2023/8/29 #961
          //     fontSize: this.state.currentItemFontSize,// CHANGED:UPDATE 2023/1/27 #532
          //     fontFamily: 2,
          //     textAlign: this.state.currentItemTextAlign,// CHANGED:UPDATE 2023/1/27 #532
          //     horizontalAlign: this.state.currentItemTextHorizontalAlign, // CHANGED:ADD 2024-03-27 #1881
          //     verticalAlign: this.state.currentItemTextVerticalAlign, // CHANGED:UPDATE 2024-03-27 #1881
          //     textBorderNone: this.state.currentItemTextBorderNone, // CHANGED:ADD 2024-03-27 #1790
          //     textBorderOpacity: this.state.currentItemTextBorderOpacity, // CHANGED:ADD 2024-03-27 #1779
          //     textDirection: this.state.currentItemTextDirection, // CHANGED:ADD 2024/02/01 #1510
          //     containerId: container?.id,
          //     originalText: "",
          //     locked: false,
          //     priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
          //     layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          //   });

          //   if (shouldBindToContainer && container) {
          //     mutateElement(container, {
          //       boundElements: (container.boundElements || []).concat({
          //         type: "text",
          //         id: element.id,
          //       }),
          //     });

          //     const boundTextCoords = TaskElementEditor.getBoundTextElementPosition(
          //       container,
          //       element as ExcalidrawTextElementWithContainer,
          //       elementsMap,
          //     );
          //     const coordX = boundTextCoords.x;
          //     const coordY = boundTextCoords.y;

          //     mutateElement(element, {
          //       x: coordX,
          //       y: coordY,
          //     });

          //     const containerIndex = this.scene.getElementIndex(container.id);
          //     this.scene.insertElementAtIndex(element, containerIndex + 1);
          //   }
          // }
          let shouldBindToContainer = false;

          let parentCenterPosition =
            this.getTextWysiwygSnappedToCenterPosition(
              sceneX,
              sceneY,
              this.state,
              container,
            );
          if (container && parentCenterPosition) {
            shouldBindToContainer = true;
          }

          const element = newTextElementEx({
            x: parentCenterPosition
              ? parentCenterPosition.elementCenterX
              : sceneX,
            y: parentCenterPosition
              ? parentCenterPosition.elementCenterY
              : sceneY,
            text: "",
            strokeColor: container.strokeColor, // CHANGED:UPDATE 2023/8/29 #961
            fontSize: this.state.currentItemFontSize,// CHANGED:UPDATE 2023/1/27 #532
            fontFamily: 2,
            textAlign: this.state.currentItemTextAlign,// CHANGED:UPDATE 2023/1/27 #532
            horizontalAlign: this.state.currentItemTextHorizontalAlign, // CHANGED:ADD 2024-03-27 #1881
            verticalAlign: this.state.currentItemTextVerticalAlign, // CHANGED:UPDATE 2024-03-27 #1881
            textBorderNone: this.state.currentItemTextBorderNone, // CHANGED:ADD 2024-03-27 #1790
            textBorderOpacity: this.state.currentItemTextBorderOpacity, // CHANGED:ADD 2024-03-27 #1779
            textDirection: this.state.currentItemTextDirection, // CHANGED:ADD 2024/02/01 #1510
            containerId: container?.id,
            originalText: "",
            locked: false,
            priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
            layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
          });

          if (shouldBindToContainer && container) {
            mutateElement(container, {
              boundElements: (container.boundElements || []).concat({
                type: "text",
                id: element.id,
              }),
            });

            const boundTextCoords = TaskElementEditor.getBoundTextElementPosition(
              container,
              element as ExcalidrawTextElementWithContainer,
              elementsMap,
            );
            const coordX = boundTextCoords.x;
            const coordY = boundTextCoords.y;

            mutateElement(element, {
              x: coordX,
              y: coordY,
            });

            const containerIndex = this.scene.getElementIndex(container.id);
            this.scene.insertElementAtIndex(element, containerIndex + 1);
          }

          if (!this.state.activeTool.locked) {
            this.setState({
              openDialog: "editTask",
            });
          }

          this.updateCriticalPathElements(); // //CHANGED:ADD 2023-1-23 #534
        }
      }

      // CHANGED:UPDATE 2022-11-2 #64
      if (isLinkElement(draggingElement)) {
        // CHANGED:REMOVE 2022-12-8 #128
        // if (draggingElement!.points.length > 1) {
        //   this.history.resumeRecording();
        // }
        const pointerCoords = viewportCoordsToSceneCoords(
          childEvent,
          this.state,
        );

        if (!pointerDownState.drag.hasOccurred && draggingElement) {
          mutateElement(draggingElement, {
            points: [
              ...draggingElement.points,
              [
                pointerCoords.x - draggingElement.x,
                pointerCoords.y - draggingElement.y,
              ],
            ],
          });
          this.setState({
            editingElement: this.state.draggingElement,
          });
        } else if (pointerDownState.drag.hasOccurred) {
          if (
            isBindingEnabledEx(this.state) &&
            isBindingElementEx(draggingElement, false)
          ) {
            maybeBindLinkElement(
              draggingElement,
              this.state,
              pointerCoords,
              this,
            );

            // CHANGED:ADD 2022-11-9 #113
            if (!draggingElement.endBinding) {
              // CHANGED:ADD 2022-12-14 #128
              if (draggingElement.startBinding) {
                const startBindingElement = Scene.getScene(
                  draggingElement,
                )?.getNonDeletedElement(draggingElement.startBinding.elementId);

                if (isBindableElementEx(startBindingElement)) {
                  mutateElement(startBindingElement, {
                    boundElementsEx: startBindingElement.boundElementsEx?.filter(
                      (element) => element.type !== "link" || element.id !== draggingElement.id,
                    ),
                  });
                }
              }
              this.scene.replaceAllElements(
                this.scene
                  .getElementsIncludingDeleted()
                  .filter((el) => el.id !== draggingElement.id),
              );
              this.setState({ suggestedBindingsEx: [], startBoundElementEx: null });
            } else {
              //CHANGED:ADD 2022-11-16 #90
              const points = draggingElement.points;
              // CHANGED:UPDATE 2023-2-9 #597 初期のミッドポイントを左寄せに変更
              // const midPointXOffset =
              //   points[0][0] + (points[1][0] - points[0][0]) / 2;
              // CHANGED:UPDATE 2024-03-11 #1749
              const midPoint = LinkElementEditor.generateMidPoints(
                [...points],
                this.state.currentItemPointerDirection,
              );

              mutateElement(draggingElement, {
                points: [points[0], ...midPoint, points[1]],
              });

              //CHANGED:ADD 2023/01/17 #390
              const container = draggingElement;
              const startDate = this.calendar.getPointDate(container.x);
              const endDate = this.calendar.getPointDate(
                container.x + container.width,
              );
              const duration = this.calendar.getDuration(startDate, endDate);

              const CenterPoint = getContainerCenterEx(
                container,
                this.state,
                elementsMap,
              );
              const sceneX = CenterPoint.x;
              const sceneY = CenterPoint.y;

              let shouldBindToContainer = false;

              let parentCenterPosition =
                this.getTextWysiwygSnappedToCenterPosition(
                  sceneX,
                  sceneY,
                  this.state,
                  container,
                );
              if (container && parentCenterPosition) {
                shouldBindToContainer = true;
              }

              const element = newTextElementEx({
                x: parentCenterPosition
                  ? parentCenterPosition.elementCenterX
                  : sceneX,
                y: parentCenterPosition
                  ? parentCenterPosition.elementCenterY
                  : sceneY,
                text: duration > 0 ? `(${duration})` : "",
                strokeColor: this.state.currentItemStrokeColor,// CHANGED:ADD 2023/1/27 #532
                fontSize: this.state.currentItemFontSize,// CHANGED:UPDATE 2023/1/27 #532
                fontFamily: 2,
                textAlign: this.state.currentItemTextAlign,// CHANGED:UPDATE 2023/1/27 #532
                verticalAlign: VERTICAL_ALIGN.MIDDLE,
                textDirection: this.state.currentItemTextDirection, // CHANGED:ADD 2024/02/01 #1510
                containerId: draggingElement.id,
                originalText: "",
                locked: false,
                priority: PRIORITY["text"], // CHANGED:ADD 2023-01-23 #391
                layer: this.state.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
              });

              if (shouldBindToContainer && container) {
                mutateElement(container, {
                  boundElements: (container.boundElements || []).concat({
                    type: "text",
                    id: element.id,
                  }),
                  duration,
                });

                // CHANGED:ADD 2024-04-15 #1917
                if (isLinkElement(container) && container.startBinding && container.endBinding) {
                  const startBindingElement =
                    this.scene.getNonDeletedElementsMap().get(container.startBinding.elementId);
                  const endBindingElement =
                    this.scene.getNonDeletedElementsMap().get(container.endBinding.elementId);

                  if (isBindableElementEx(startBindingElement) && isBindableElementEx(endBindingElement)) {
                    mutateElement(startBindingElement, {
                      freeFloats: (startBindingElement.freeFloats?.filter(
                        (float) => float.id !== endBindingElement.id) || [])
                        .concat({
                          id: container.endBinding.elementId,
                          type: endBindingElement.type,
                          duration: duration,
                        })
                    });
                  }
                }

                const boundTextCoords =
                  LinkElementEditor.getBoundTextElementPosition(
                    container,
                    element as ExcalidrawTextElementWithContainer,
                    this.scene.getNonDeletedElementsMap(),
                  );
                const coordX = boundTextCoords.x;
                const coordY = boundTextCoords.y;

                mutateElement(element, {
                  x: coordX,
                  y: coordY,
                });

                const containerIndex = this.scene.getElementIndex(container.id);
                this.scene.insertElementAtIndex(element, containerIndex + 1);
              }

              this.updateCriticalPathElements(); // CHANGED:ADD 2023-1-23 #455
            }
          }
          this.setState({ suggestedBindingsEx: [], startBoundElementEx: null });
          resetCursor(this.interactiveCanvas);
          this.setState((prevState) => ({
            draggingElement: null,
            activeTool: updateActiveTool(this.state, {
              type: "selection",
            }),
            selectedElementIds: {
              ...prevState.selectedElementIds,
              [draggingElement.id]: true,
            },
            selectedLinkElement: new LinkElementEditor(
              draggingElement,
              this.scene,
              this.state,
            ),
          }));
        }
        return;
      }

      if (
        activeTool.type !== "selection" &&
        draggingElement &&
        isInvisiblySmallElement(draggingElement)
      ) {
        // remove invisible element which was added in onPointerDown
        this.scene.replaceAllElements(
          // CHANGED:UPDATE 2024-03-25 #1855
          // this.scene.getElementsIncludingDeleted().slice(0, -1),
          this.scene.getElementsIncludingDeleted()
            .filter((el) => el.id !== draggingElement.id),
        );
        this.setState({
          draggingElement: null,
        });
        return;
      }

      if (draggingElement) {
        mutateElement(
          draggingElement,
          getNormalizedDimensions(draggingElement),
        );
      }

      if (resizingElement) {
        this.history.resumeRecording();

        // CHANGED:ADD 2023/02/03 #562
        if (isJobElement(resizingElement)) {
          const original = pointerDownState.originalElements.get(
            resizingElement.id,
          );
          if (original && resizingElement.height !== original.height) {
            const diffH = resizingElement.height - original.height;
            const jobsHeight = this.state.jobsHeight + diffH;
            this.setState({
              jobsHeight,
            });

            // CHANGED:UPDATE 2023/02/13 #633 #647 #740
            const elements = this.scene.getNonDeletedElements();
            Job.updateJobElements(
              elements,
              this.scene.getNonDeletedElementsMap(),
              original.y,
              original.height,
              diffH,
              this.state,
              this.calendar,
            );

            // CHANGED:ADD 2023-2-10 #638
            this.scene.updateBackgroundHeight(jobsHeight);

            const jobElements = Job.getJobElements(
              this.scene.getNonDeletedElements(),
            );

            this.scene.jobPanelElements = generateJobPanelElements(
              jobElements,
              this.state.calendarWidth,
            );

            // CHANGED:ADD 2023-03-01 #726
            this.scene.jobLineElements = generateJobLineElements(
              jobElements,
              this.state.calendarWidth,
            );
          }
        }
      }

      if (resizingElement && isInvisiblySmallElement(resizingElement)) {
        this.scene.replaceAllElements(
          this.scene
            .getElementsIncludingDeleted()
            .filter((el) => el.id !== resizingElement.id),
        );
      }

      // Code below handles selection when element(s) weren't
      // drag or added to selection on pointer down phase.
      const hitElement = pointerDownState.hit.element;
      //TODO:デバッグ用
      if (import.meta.env.DEV) {
        if (hitElement) {
          console.log(hitElement);
        }
      }
      if (
        this.state.selectedLinearElement?.elementId !== hitElement?.id &&
        isLinearElement(hitElement)
      ) {
        const selectedELements = this.scene.getSelectedElements(this.state);
        // set selectedLinearElement when no other element selected except
        // the one we've hit
        if (selectedELements.length === 1) {
          this.setState({
            selectedLinearElement: new LinearElementEditor(hitElement),
          });
        }
      }
      // CHANGED:ADD 2022-10-28 #14
      if (
        this.state.selectedTaskElement?.elementId !== hitElement?.id &&
        isTaskElement(hitElement)
      ) {
        const selectedELements = this.scene.getSelectedElements(this.state);
        // set selectedTaskElement when no other element selected except
        // the one we've hit
        if (selectedELements.length === 1) {
          this.setState({
            selectedTaskElement: new TaskElementEditor(hitElement, this.scene, this.state),
          });
        }
      }
      // CHANGED:ADD 2022-11-2 #64
      if (
        this.state.selectedLinkElement?.elementId !== hitElement?.id &&
        isLinkElement(hitElement)
      ) {
        const selectedELements = this.scene.getSelectedElements(this.state);
        // set selectedLinkElement when no other element selected except
        // the one we've hit
        if (selectedELements.length === 1) {
          this.setState({
            selectedLinkElement: new LinkElementEditor(hitElement, this.scene, this.state),
          });
        }
      }

      const pointerStart = this.lastPointerDownEvent;
      const pointerEnd = this.lastPointerUpEvent || this.lastPointerMoveEvent;

      if (isEraserActive(this.state) && pointerStart && pointerEnd) {
        this.eraserTrail.endPath();

        const draggedDistance = distance2d(
          pointerStart.clientX,
          pointerStart.clientY,
          pointerEnd.clientX,
          pointerEnd.clientY,
        );

        if (draggedDistance === 0) {
          const scenePointer = viewportCoordsToSceneCoords(
            {
              clientX: pointerEnd.clientX,
              clientY: pointerEnd.clientY,
            },
            this.state,
          );
          const hitElements = this.getElementsAtPosition(
            scenePointer.x,
            scenePointer.y,
          );
          // コメントエレメントのみの消しゴム削除を防ぐ（消す時はタスク等と一緒に消す）
          hitElements
            .filter((element) => !isJobElement(element) && !isCommentElement(element)) // CHANGED:ADD 2023-2-9 #606 CHANGED: UPDATE 2023-12-21 #1138  
            .forEach(
              (hitElement) =>
              (pointerDownState.elementIdsToErase[hitElement.id] = {
                erase: true,
                opacity: hitElement.opacity,
              }),
            );
        }
        this.eraseElements(pointerDownState);
        return;
      } else if (Object.keys(pointerDownState.elementIdsToErase).length) {
        this.restoreReadyToEraseElements(pointerDownState);
      }

      if (isCommentElement(hitElement)) {
        // コメントアイコンをクリック時にエレメントに紐づくコメント一覧を表示
        // this.actionManager.executeAction(actionListComments, "api");
        this.setState({
          selectedElementIds: {[hitElement.id]: true},
          openSidebar: "comments",
        });
      }

      if (
        hitElement &&
        !pointerDownState.drag.hasOccurred &&
        !pointerDownState.hit.wasAddedToSelection &&
        // if we're editing a line, pointerup shouldn't switch selection if
        // box selected
        (!this.state.editingLinearElement ||
          !pointerDownState.boxSelection.hasOccurred)
      ) {
        // when inside line editor, shift selects points instead
        if (childEvent.shiftKey && !this.state.editingLinearElement) {
          if (this.state.selectedElementIds[hitElement.id]) {
            if (isSelectedViaGroup(this.state, hitElement)) {
              this.setState((_prevState) => {
                const nextSelectedElementIds = {
                  ..._prevState.selectedElementIds,
                };

                // We want to unselect all groups hitElement is part of
                // as well as all elements that are part of the groups
                // hitElement is part of
                for (const groupedElement of hitElement.groupIds.flatMap(
                  (groupId) =>
                    getElementsInGroup(
                      this.scene.getNonDeletedElements(),
                      groupId,
                    ),
                )) {
                  delete nextSelectedElementIds[groupedElement.id];
                }

                return {
                  selectedGroupIds: {
                    ..._prevState.selectedElementIds,
                    ...hitElement.groupIds
                      .map((gId) => ({ [gId]: false }))
                      .reduce((prev, acc) => ({ ...prev, ...acc }), {}),
                  },
                  selectedElementIds: makeNextSelectedElementIds(
                    nextSelectedElementIds,
                    _prevState,
                  ),
                };
              });
              // if not gragging a linear element point (outside editor)
            } else if (!this.state.selectedLinearElement?.isDragging) {
              // remove element from selection while
              // keeping prev elements selected

              this.setState((prevState) => {
                const newSelectedElementIds = {
                  ...prevState.selectedElementIds,
                };
                delete newSelectedElementIds[hitElement!.id];
                const newSelectedElements = getSelectedElements(
                  this.scene.getNonDeletedElements(),
                  { selectedElementIds: newSelectedElementIds },
                );

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: newSelectedElementIds,
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this,
                  ),
                  // set selectedLinearElement only if thats the only element selected
                  selectedLinearElement:
                    newSelectedElements.length === 1 &&
                      isLinearElement(newSelectedElements[0])
                      ? new LinearElementEditor(newSelectedElements[0])
                      : prevState.selectedLinearElement,
                };
              });
            }
          } else {
            // add element to selection while
            // keeping prev elements selected
            this.setState((_prevState) => ({
              selectedElementIds: {
                ..._prevState.selectedElementIds,
                [hitElement!.id]: true,
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            ...selectGroupsForSelectedElements(
              {
                editingGroupId: prevState.editingGroupId,
                selectedElementIds: { [hitElement.id]: true },
              },
              this.scene.getNonDeletedElements(),
              prevState,
              this,
            ),
            selectedLinearElement:
              isLinearElement(hitElement) &&
                // Don't set `selectedLinearElement` if its same as the hitElement, this is mainly to prevent resetting the `hoverPointIndex` to -1.
                // Future we should update the API to take care of setting the correct `hoverPointIndex` when initialized
                prevState.selectedLinearElement?.elementId !== hitElement.id
                ? new LinearElementEditor(hitElement)
                : prevState.selectedLinearElement,
          }));
        }
      }

      // CHANGED:ADD 2022-10-28 #14
      if (
        hitElement &&
        !pointerDownState.drag.hasOccurred &&
        !pointerDownState.hit.wasAddedToSelection &&
        // if we're editing a line, pointerup shouldn't switch selection if
        // box selected
        !pointerDownState.boxSelection.hasOccurred
      ) {
        // when inside line editor, shift selects points instead
        if (childEvent.shiftKey) {
          if (this.state.selectedElementIds[hitElement.id]) {
            if (isSelectedViaGroup(this.state, hitElement)) {
              this.setState((_prevState) => {
                const nextSelectedElementIds = {
                  ..._prevState.selectedElementIds,
                };

                // We want to unselect all groups hitElement is part of
                // as well as all elements that are part of the groups
                // hitElement is part of
                for (const groupedElement of hitElement.groupIds.flatMap(
                  (groupId) =>
                    getElementsInGroup(
                      this.scene.getNonDeletedElements(),
                      groupId,
                    ),
                )) {
                  delete nextSelectedElementIds[groupedElement.id];
                }

                return {
                  selectedGroupIds: {
                    ..._prevState.selectedElementIds,
                    ...hitElement.groupIds
                      .map((gId) => ({ [gId]: false }))
                      .reduce((prev, acc) => ({ ...prev, ...acc }), {}),
                  },
                  selectedElementIds: makeNextSelectedElementIds(
                    nextSelectedElementIds,
                    _prevState,
                  ),
                };
              });
              // if not gragging a task element point (outside editor)
            } else if (!this.state.selectedTaskElement?.isDragging) {
              // remove element from selection while
              // keeping prev elements selected

              this.setState((prevState) => {
                const newSelectedElementIds = {
                  ...prevState.selectedElementIds,
                };
                delete newSelectedElementIds[hitElement!.id];
                const newSelectedElements = getSelectedElements(
                  this.scene.getNonDeletedElements(),
                  { selectedElementIds: newSelectedElementIds },
                );

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      ...prevState,
                      selectedElementIds: newSelectedElementIds,
                      // set selectedTaskElement only if thats the only element selected
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this,
                  ),
                  selectedTaskElement:
                    newSelectedElements.length === 1 &&
                      isTaskElement(newSelectedElements[0])
                      ? new TaskElementEditor(
                        newSelectedElements[0],
                        this.scene,
                        this.state,
                      )
                      : prevState.selectedTaskElement,
                };
              });
            }
          } else {
            // add element to selection while
            // keeping prev elements selected
            this.setState((_prevState) => ({
              selectedElementIds: {
                ..._prevState.selectedElementIds,
                [hitElement!.id]: true,
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            ...selectGroupsForSelectedElements(
              {
                editingGroupId: prevState.editingGroupId,
                selectedElementIds: { [hitElement.id]: true },
              },
              this.scene.getNonDeletedElements(),
              prevState,
              this,
            ),
            selectedTaskElement:
              isTaskElement(hitElement) &&
                // Don't set `selectedTaskElement` if its same as the hitElement, this is mainly to prevent resetting the `hoverPointIndex` to -1.
                // Future we should update the API to take care of setting the correct `hoverPointIndex` when initialized
                prevState.selectedTaskElement?.elementId !== hitElement.id
                ? new TaskElementEditor(hitElement, this.scene, this.state)
                : prevState.selectedTaskElement,
          }));
        }
      }

      // CHANGED:ADD 2022-11-2 #64
      if (
        hitElement &&
        !pointerDownState.drag.hasOccurred &&
        !pointerDownState.hit.wasAddedToSelection &&
        // if we're editing a line, pointerup shouldn't switch selection if
        // box selected
        !pointerDownState.boxSelection.hasOccurred
      ) {
        // when inside line editor, shift selects points instead
        if (childEvent.shiftKey) {
          if (this.state.selectedElementIds[hitElement.id]) {
            if (isSelectedViaGroup(this.state, hitElement)) {
              this.setState((_prevState) => {
                const nextSelectedElementIds = {
                  ..._prevState.selectedElementIds,
                };

                // We want to unselect all groups hitElement is part of
                // as well as all elements that are part of the groups
                // hitElement is part of
                for (const groupedElement of hitElement.groupIds.flatMap(
                  (groupId) =>
                    getElementsInGroup(
                      this.scene.getNonDeletedElements(),
                      groupId,
                    ),
                )) {
                  delete nextSelectedElementIds[groupedElement.id];
                }

                return {
                  selectedGroupIds: {
                    ..._prevState.selectedElementIds,
                    ...hitElement.groupIds
                      .map((gId) => ({ [gId]: false }))
                      .reduce((prev, acc) => ({ ...prev, ...acc }), {}),
                  },
                  selectedElementIds: makeNextSelectedElementIds(
                    nextSelectedElementIds,
                    _prevState,
                  ),
                };
              });
              // if not gragging a link element point (outside editor)
            } else if (!this.state.selectedLinkElement?.isDragging) {
              // remove element from selection while
              // keeping prev elements selected
              this.setState((prevState) => {
                const newSelectedElementIds = {
                  ...prevState.selectedElementIds,
                };
                delete newSelectedElementIds[hitElement!.id];
                const newSelectedElements = getSelectedElements(
                  this.scene.getNonDeletedElements(),
                  { selectedElementIds: newSelectedElementIds },
                );

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: newSelectedElementIds,
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this,
                  ),
                  // set selectedLinkElement only if thats the only element selected
                  selectedLinkElement:
                    newSelectedElements.length === 1 &&
                      isLinkElement(newSelectedElements[0])
                      ? new LinkElementEditor(
                        newSelectedElements[0],
                        this.scene,
                        this.state,
                      )
                      : prevState.selectedLinkElement,
                };
              });
            }
          } else {
            // add element to selection while
            // keeping prev elements selected
            this.setState((_prevState) => ({
              selectedElementIds: {
                ..._prevState.selectedElementIds,
                [hitElement!.id]: true,
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            ...selectGroupsForSelectedElements(
              {
                editingGroupId: prevState.editingGroupId,
                selectedElementIds: { [hitElement.id]: true },
              },
              this.scene.getNonDeletedElements(),
              prevState,
              null,
            ),
            selectedLinkElement:
              isLinkElement(hitElement) &&
                // Don't set `selectedLinkElement` if its same as the hitElement, this is mainly to prevent resetting the `hoverPointIndex` to -1.
                // Future we should update the API to take care of setting the correct `hoverPointIndex` when initialized
                prevState.selectedLinkElement?.elementId !== hitElement.id
                ? new LinkElementEditor(hitElement, this.scene, this.state)
                : prevState.selectedLinkElement,
          }));
        }
      }

      if (
        !pointerDownState.drag.hasOccurred &&
        !this.state.isResizing &&
        ((hitElement &&
          isHittingElementBoundingBoxWithoutHittingElement(
            hitElement,
            this.state,
            pointerDownState.origin.x,
            pointerDownState.origin.y,
            this.scene.getNonDeletedElementsMap(),
          )) ||
          (!hitElement &&
            pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements))
      ) {
        if (this.state.editingLinearElement) {
          this.setState({ editingLinearElement: null });
        } else {
          // Deselect selected elements
          this.setState({
            selectedElementIds: {},
            selectedGroupIds: {},
            editingGroupId: null,
          });
        }
        return;
      }

      if (
        !activeTool.locked &&
        activeTool.type !== "freedraw" &&
        draggingElement
      ) {
        this.setState((prevState) => ({
          selectedElementIds: {
            ...prevState.selectedElementIds,
            [draggingElement.id]: true,
          },
        }));
      }

      if (
        activeTool.type !== "selection" ||
        isSomeElementSelected(this.scene.getNonDeletedElements(), this.state)
      ) {
        this.history.resumeRecording();
      }

      if (pointerDownState.drag.hasOccurred || isResizing || isRotating) {
        isBindingEnabled(this.state)
          ? bindOrUnbindSelectedElements(
            this.scene.getSelectedElements(this.state),
            this,
          )
          : unbindLinearElements(
            this.scene.getNonDeletedElements(),
            elementsMap,
          );
        // CHANGED:REMOVE 2022-12-11 #282
        // (isBindingEnabledEx(this.state)
        //   ? bindOrUnbindSelectedElementsEx
        //   : unbindLinkElements)(
        //   getSelectedElements(this.scene.getNonDeletedElements(), this.state),
        // );
      }

      if (activeTool.type === "laser") {
        this.laserTrails.endPath();
        return;
      }

      if (!activeTool.locked && activeTool.type !== "freedraw") {
        resetCursor(this.interactiveCanvas);
        this.setState({
          draggingElement: null,
          suggestedBindings: [],
          suggestedBindingsEx: [], // CHANGED:ADD 2022-10-28 #14
          activeTool: updateActiveTool(this.state, { type: "selection" }),
        });
      } else {
        this.setState({
          draggingElement: null,
          suggestedBindings: [],
          suggestedBindingsEx: [], // CHANGED:ADD 2022-10-28 #14
        });
      }
    });
  }

  // CHANGED:ADD 2024-02-15 #1567
  private onPointerUpFromPointerDownHandlerViewMode(
    pointerDownState: PointerDownState,
  ): (event: PointerEvent) => void {
    return withBatchedUpdates((childEvent: PointerEvent) => {
      this.savePointer(childEvent.clientX, childEvent.clientY, "up");

      window.removeEventListener(
        EVENT.POINTER_UP,
        pointerDownState.eventListeners.onUp!,
      );

      // Code below handles selection when element(s) weren't
      // drag or added to selection on pointer down phase.
      const hitElement = pointerDownState.hit.element;
      //TODO:デバッグ用
      if (import.meta.env.DEV) {
        if (hitElement) {
          console.log(hitElement);
        }
      }
 
      if (isCommentElement(hitElement)) {
        // コメントアイコンをクリック時にエレメントに紐づくコメント一覧を表示
        // this.actionManager.executeAction(actionListComments, "api");
        this.setState({
          selectedElementIds: {[hitElement.id]: true},
          openSidebar: "comments",
        });
      }
    });
  }

  private restoreReadyToEraseElements = (
    pointerDownState: PointerDownState,
  ) => {
    const elements = this.scene.getElementsIncludingDeleted().map((ele) => {
      if (
        pointerDownState.elementIdsToErase[ele.id] &&
        pointerDownState.elementIdsToErase[ele.id].erase
      ) {
        return newElementWith(ele, {
          opacity: pointerDownState.elementIdsToErase[ele.id].opacity,
        });
      } else if (
        isBoundToContainer(ele) &&
        pointerDownState.elementIdsToErase[ele.containerId] &&
        pointerDownState.elementIdsToErase[ele.containerId].erase
      ) {
        return newElementWith(ele, {
          opacity: pointerDownState.elementIdsToErase[ele.containerId].opacity,
        });
      }
      return ele;
    });

    this.scene.replaceAllElements(elements);
  };

  private eraseElements = (pointerDownState: PointerDownState) => {
    const commentableElementIdsToDelete: string[] = [];
    const elements = this.scene.getElementsIncludingDeleted().map((ele) => {
      if (
        pointerDownState.elementIdsToErase[ele.id] &&
        pointerDownState.elementIdsToErase[ele.id].erase
      ) {
        // CHANGED:ADD 2023-03-04 #753
        if (isMilestoneElement(ele)) {
          this.scene.GenerateMilestoneLineElements(this.scene.milestoneLineElements.filter((line) => !line.boundElements?.find((bound) => bound.type === "milestone" && bound.id === ele.id)));
        } 
        
        if (isCommentableElement(ele)) { // CHANGED: ADD 2023-12-21 #1138  
          commentableElementIdsToDelete.push(ele.id);
          this.scene.GenerateMilestoneLineElements(this.scene.milestoneLineElements.filter((line) => !line.boundElements?.find((bound) => bound.type === "milestone" && bound.id === ele.id)));
        }

        return newElementWith(ele, { isDeleted: true });
      } else if (
        isBoundToContainer(ele) &&
        pointerDownState.elementIdsToErase[ele.containerId] &&
        pointerDownState.elementIdsToErase[ele.containerId].erase
      ) {
        return newElementWith(ele, { isDeleted: true });
      }
      return ele;
    });

    // CHANGED: ADD 2023-12-21 #1138  
    const commentDeletionIncludedElements = elements.map((ele) => {
      if (isCommentElement(ele) && commentableElementIdsToDelete.includes(ele.commentElementId)) {
        return newElementWith(ele, { isDeleted: true });
      }
      return ele;
    })
  
    // CHANGED:ADD 2022-12-13 #304
    fixBindingsAfterDeletionEx(
      commentDeletionIncludedElements,
      this.scene.getElementsIncludingDeleted().filter(({ id }) => pointerDownState.elementIdsToErase[id]),
    );

    this.history.resumeRecording();
    this.scene.replaceAllElements(commentDeletionIncludedElements);
  };

  private initializeImage = async ({
    imageFile,
    imageElement: _imageElement,
    showCursorImagePreview = false,
  }: {
    imageFile: File;
    imageElement: ExcalidrawImageElement;
    showCursorImagePreview?: boolean;
  }) => {
    // at this point this should be guaranteed image file, but we do this check
    // to satisfy TS down the line
    if (!isSupportedImageFile(imageFile)) {
      throw new Error(t("errors.unsupportedFileType"));
    }
    const mimeType = imageFile.type;

    setCursor(this.interactiveCanvas, "wait");

    if (mimeType === MIME_TYPES.svg) {
      try {
        imageFile = SVGStringToFile(
          await normalizeSVG(await imageFile.text()),
          imageFile.name,
        );
      } catch (error: any) {
        console.warn(error);
        throw new Error(t("errors.svgImageInsertError"));
      }
    }

    // generate image id (by default the file digest) before any
    // resizing/compression takes place to keep it more portable
    const fileId = await ((this.props.generateIdForFile?.(
      imageFile,
    ) as Promise<FileId>) || generateIdFromFile(imageFile));

    if (!fileId) {
      console.warn(
        "Couldn't generate file id or the supplied `generateIdForFile` didn't resolve to one.",
      );
      throw new Error(t("errors.imageInsertError"));
    }

    const existingFileData = this.files[fileId];
    if (!existingFileData?.dataURL) {
      try {
        imageFile = await resizeImageFile(imageFile, {
          maxWidthOrHeight: DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
        });
      } catch (error: any) {
        console.error("error trying to resing image file on insertion", error);
      }

      if (imageFile.size > MAX_ALLOWED_FILE_BYTES) {
        throw new Error(
          t("errors.fileTooBig", {
            maxSize: `${Math.trunc(MAX_ALLOWED_FILE_BYTES / 1024 / 1024)}MB`,
          }),
        );
      }
    }

    if (showCursorImagePreview) {
      const dataURL = this.files[fileId]?.dataURL;
      // optimization so that we don't unnecessarily resize the original
      // full-size file for cursor preview
      // (it's much faster to convert the resized dataURL to File)
      const resizedFile = dataURL && dataURLToFile(dataURL);

      this.setImagePreviewCursor(resizedFile || imageFile);
    }

    const dataURL =
      this.files[fileId]?.dataURL || (await getDataURL(imageFile));

    const imageElement = mutateElement(
      _imageElement,
      {
        fileId,
      },
      false,
    ) as NonDeleted<InitializedExcalidrawImageElement>;

    return new Promise<NonDeleted<InitializedExcalidrawImageElement>>(
      async (resolve, reject) => {
        try {
          this.files = {
            ...this.files,
            [fileId]: {
              mimeType,
              id: fileId,
              dataURL,
              created: Date.now(),
              lastRetrieved: Date.now(),
            },
          };
          const cachedImageData = this.imageCache.get(fileId);
          if (!cachedImageData) {
            this.addNewImagesToImageCache();
            await this.updateImageCache([imageElement]);
          }
          if (cachedImageData?.image instanceof Promise) {
            await cachedImageData.image;
          }
          if (
            this.state.pendingImageElementId !== imageElement.id &&
            this.state.draggingElement?.id !== imageElement.id
          ) {
            this.initializeImageDimensions(imageElement, true);
          }
          resolve(imageElement);
        } catch (error: any) {
          console.error(error);
          reject(new Error(t("errors.imageInsertError")));
        } finally {
          if (!showCursorImagePreview) {
            resetCursor(this.interactiveCanvas);
          }
        }
      },
    );
  };

  /**
   * inserts image into elements array and rerenders
   */
  private insertImageElement = async (
    imageElement: ExcalidrawImageElement,
    imageFile: File,
    showCursorImagePreview?: boolean,
  ) => {
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      imageElement,
    ]);

    try {
      await this.initializeImage({
        imageFile,
        imageElement,
        showCursorImagePreview,
      });
    } catch (error: any) {
      mutateElement(imageElement, {
        isDeleted: true,
      });
      this.actionManager.executeAction(actionFinalize);
      this.setState({
        errorMessage: error.message || t("errors.imageInsertError"),
      });
    }
  };

  private setImagePreviewCursor = async (imageFile: File) => {
    // mustn't be larger than 128 px
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Basic_User_Interface/Using_URL_values_for_the_cursor_property
    const cursorImageSizePx = 96;

    const imagePreview = await resizeImageFile(imageFile, {
      maxWidthOrHeight: cursorImageSizePx,
    });

    let previewDataURL = await getDataURL(imagePreview);

    // SVG cannot be resized via `resizeImageFile` so we resize by rendering to
    // a small canvas
    if (imageFile.type === MIME_TYPES.svg) {
      const img = await loadHTMLImageElement(previewDataURL);

      let height = Math.min(img.height, cursorImageSizePx);
      let width = height * (img.width / img.height);

      if (width > cursorImageSizePx) {
        width = cursorImageSizePx;
        height = width * (img.height / img.width);
      }

      const canvas = document.createElement("canvas");
      canvas.height = height;
      canvas.width = width;
      const context = canvas.getContext("2d")!;

      context.drawImage(img, 0, 0, width, height);

      previewDataURL = canvas.toDataURL(MIME_TYPES.svg) as DataURL;
    }

    if (this.state.pendingImageElementId) {
      setCursor(this.interactiveCanvas, `url(${previewDataURL}) 4 4, auto`);
    }
  };

  private onImageAction = async (
    { insertOnCanvasDirectly } = { insertOnCanvasDirectly: false },
  ) => {
    try {
      const clientX = this.state.width / 2 + this.state.offsetLeft;
      const clientY = this.state.height / 2 + this.state.offsetTop;

      const { x, y } = viewportCoordsToSceneCoords(
        { clientX, clientY },
        this.state,
      );

      const imageFile = await fileOpen({
        description: "Image",
        extensions: ["jpg", "png", "svg", "gif"],
      });

      const imageElement = this.createImageElement({
        sceneX: x,
        sceneY: y,
      });

      if (insertOnCanvasDirectly) {
        this.insertImageElement(imageElement, imageFile);
        this.initializeImageDimensions(imageElement);
        this.setState(
          {
            selectedElementIds: { [imageElement.id]: true },
          },
          () => {
            this.actionManager.executeAction(actionFinalize);
          },
        );
      } else {
        this.setState(
          {
            pendingImageElementId: imageElement.id,
          },
          () => {
            this.insertImageElement(
              imageElement,
              imageFile,
              /* showCursorImagePreview */ true,
            );
          },
        );
      }
    } catch (error: any) {
      if (error.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      this.setState(
        {
          pendingImageElementId: null,
          editingElement: null,
          activeTool: updateActiveTool(this.state, { type: "selection" }),
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  private initializeImageDimensions = (
    imageElement: ExcalidrawImageElement,
    forceNaturalSize = false,
  ) => {
    const image =
      isInitializedImageElement(imageElement) &&
      this.imageCache.get(imageElement.fileId)?.image;

    if (!image || image instanceof Promise) {
      if (
        imageElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        imageElement.height < DRAGGING_THRESHOLD / this.state.zoom.value
      ) {
        const placeholderSize = 100 / this.state.zoom.value;
        mutateElement(imageElement, {
          x: imageElement.x - placeholderSize / 2,
          y: imageElement.y - placeholderSize / 2,
          width: placeholderSize,
          height: placeholderSize,
        });
      }

      return;
    }

    if (
      forceNaturalSize ||
      // if user-created bounding box is below threshold, assume the
      // intention was to click instead of drag, and use the image's
      // intrinsic size
      (imageElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        imageElement.height < DRAGGING_THRESHOLD / this.state.zoom.value)
    ) {
      const minHeight = Math.max(this.state.height - 120, 160);
      // max 65% of canvas height, clamped to <300px, vh - 120px>
      const maxHeight = Math.min(
        minHeight,
        Math.floor(this.state.height * 0.5) / this.state.zoom.value,
      );

      const height = Math.min(image.naturalHeight, maxHeight);
      const width = height * (image.naturalWidth / image.naturalHeight);

      // add current imageElement width/height to account for previous centering
      // of the placeholder image
      const x = imageElement.x + imageElement.width / 2 - width / 2;
      const y = imageElement.y + imageElement.height / 2 - height / 2;

      mutateElement(imageElement, { x, y, width, height });
    }
  };

  /** updates image cache, refreshing updated elements and/or setting status
      to error for images that fail during <img> element creation */
  private updateImageCache = async (
    elements: readonly InitializedExcalidrawImageElement[],
    files = this.files,
  ) => {
    const { updatedFiles, erroredFiles } = await _updateImageCache({
      imageCache: this.imageCache,
      fileIds: elements.map((element) => element.fileId),
      files,
    });
    if (updatedFiles.size || erroredFiles.size) {
      for (const element of elements) {
        if (updatedFiles.has(element.fileId)) {
          ShapeCache.delete(element);
        }
      }
    }
    if (erroredFiles.size) {
      this.scene.replaceAllElements(
        this.scene.getElementsIncludingDeleted().map((element) => {
          if (
            isInitializedImageElement(element) &&
            erroredFiles.has(element.fileId)
          ) {
            return newElementWith(element, {
              status: "error",
            });
          }
          return element;
        }),
      );
    }

    return { updatedFiles, erroredFiles };
  };

  /** adds new images to imageCache and re-renders if needed */
  private addNewImagesToImageCache = async (
    imageElements: InitializedExcalidrawImageElement[] = getInitializedImageElements(
      this.scene.getNonDeletedElements(),
    ),
    files: BinaryFiles = this.files,
  ) => {
    const uncachedImageElements = imageElements.filter(
      (element) => !element.isDeleted && !this.imageCache.has(element.fileId),
    );

    if (uncachedImageElements.length) {
      const { updatedFiles } = await this.updateImageCache(
        uncachedImageElements,
        files,
      );
      if (updatedFiles.size) {
        this.scene.informMutation();
      }
    }
  };

  /** generally you should use `addNewImagesToImageCache()` directly if you need
   *  to render new images. This is just a failsafe  */
  private scheduleImageRefresh = throttle(() => {
    this.addNewImagesToImageCache();
  }, IMAGE_RENDER_TIMEOUT);

  private updateBindingEnabledOnPointerMove = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ) => {
    const shouldEnableBinding = shouldEnableBindingForPointerEvent(event);
    if (this.state.isBindingEnabled !== shouldEnableBinding) {
      this.setState({ isBindingEnabled: shouldEnableBinding });
    }
    // CHANGED:REMOVE 2023-1-20 #450
    // if (this.state.isBindingEnabledEx !== shouldEnableBinding) {
    //   this.setState({ isBindingEnabledEx: shouldEnableBinding });
    // }
  };

  private maybeSuggestBindingAtCursor = (pointerCoords: {
    x: number;
    y: number;
  }): void => {
    const hoveredBindableElement = getHoveredElementForBinding(
      pointerCoords,
      this,
    );
    this.setState({
      suggestedBindings:
        hoveredBindableElement != null ? [hoveredBindableElement] : [],
    });
  };

  // CHANGED:ADD 2022-11-2 #64
  private maybeSuggestBindingAtCursorEx = (pointerCoords: {
    x: number;
    y: number;
  }): void => {
    const hoveredBindableElement = getHoveredElementForBindingEx(
      pointerCoords,
      this,
      "start", // CHANGED:ADD 2022-11-11 #116
    );
    this.setState({
      suggestedBindingsEx:
        hoveredBindableElement != null ? [hoveredBindableElement] : [],
    });
  };

  private maybeSuggestBindingsForLinearElementAtCoords = (
    linearElement: NonDeleted<ExcalidrawLinearElement>,
    /** scene coords */
    pointerCoords: {
      x: number;
      y: number;
    }[],
    // During line creation the start binding hasn't been written yet
    // into `linearElement`
    oppositeBindingBoundElement?: ExcalidrawBindableElement | null,
  ): void => {
    if (!pointerCoords.length) {
      return;
    }

    const suggestedBindings = pointerCoords.reduce(
      (acc: NonDeleted<ExcalidrawBindableElement>[], coords) => {
        const hoveredBindableElement = getHoveredElementForBinding(
          coords,
          this,
        );
        if (
          hoveredBindableElement != null &&
          !isLinearElementSimpleAndAlreadyBound(
            linearElement,
            oppositeBindingBoundElement?.id,
            hoveredBindableElement,
          )
        ) {
          acc.push(hoveredBindableElement);
        }
        return acc;
      },
      [],
    );

    this.setState({ suggestedBindings });
  };

  // CHANGED:REMOVE 2022-11-2 #64
  // private maybeSuggestBindingsForTaskElementAtCoords = (
  //   taskElement: NonDeleted<ExcalidrawTaskElement>,
  //   /** scene coords */
  //   pointerCoords: {
  //     x: number;
  //     y: number;
  //   }[],
  //   // During line creation the start binding hasn't been written yet
  //   // into `taskElement`
  //   oppositeBindingBoundElement?: ExcalidrawBindableElementEx | null,
  // ): void => {
  //   if (!pointerCoords.length) {
  //     return;
  //   }

  //   const suggestedBindingsEx = pointerCoords.reduce(
  //     (acc: NonDeleted<ExcalidrawBindableElementEx>[], coords) => {
  //       const hoveredBindableElement = getHoveredElementForBindingEx(
  //         coords,
  //         this.scene,
  //       );
  //       if (
  //         hoveredBindableElement != null &&
  //         !isTaskElementSimpleAndAlreadyBound(
  //           taskElement,
  //           oppositeBindingBoundElement?.id,
  //           hoveredBindableElement,
  //         )
  //       ) {
  //         acc.push(hoveredBindableElement);
  //       }
  //       return acc;
  //     },
  //     [],
  //   );

  //   this.setState({ suggestedBindingsEx });
  // };

  // CHANGED:ADD 2022-11-2 #64
  private maybeSuggestBindingsForLinkElementAtCoords = (
    linkElement: NonDeleted<ExcalidrawLinkElement>,
    /** scene coords */
    pointerCoords: {
      x: number;
      y: number;
    }[],
    startOrEnd: "start" | "end", // CHANGED:ADD 2022-11-11 #116
    // During line creation the start binding hasn't been written yet
    // into `linkElement`
    oppositeBindingBoundElement?: ExcalidrawBindableElementEx | null,
  ): void => {
    if (!pointerCoords.length) {
      return;
    }

    // CHANGED:UPDATE 2022-11-11 #116
    const suggestedBindingsEx = pointerCoords.reduce(
      (acc: SuggestedPointBindingEx[], coords) => {
        const hoveredBindableElement = getHoveredElementForBindingEx(
          coords,
          this,
          startOrEnd,
        );
        if (
          hoveredBindableElement != null &&
          !isLinkElementSimpleAndAlreadyBound(
            linkElement,
            oppositeBindingBoundElement?.id,
            hoveredBindableElement,
          ) &&
          linkElement.layer === hoveredBindableElement.layer // CHANGED:ADD 2024-10-7 #2114
        ) {
          acc.push([linkElement, startOrEnd, hoveredBindableElement]);
        }
        return acc;
      },
      [],
    );

    this.setState({ suggestedBindingsEx });
  };

  private maybeSuggestBindingForAll(
    selectedElements: NonDeleted<ExcalidrawElement>[],
  ): void {
    const suggestedBindings = getEligibleElementsForBinding(
      selectedElements,
      this,
    );
    this.setState({ suggestedBindings });
    // CHANGED:ADD 2022-10-28 #14
    const suggestedBindingsEx =
      getEligibleElementsForBindingEx(
        selectedElements,
        this,
      );
    this.setState({ suggestedBindingsEx });
  }

  private clearSelection(hitElement: ExcalidrawElement | null): void {
    this.setState((prevState) => ({
      selectedElementIds: {},
      selectedGroupIds: {},
      // Continue editing the same group if the user selected a different
      // element from it
      editingGroupId:
        prevState.editingGroupId &&
        hitElement != null &&
        isElementInGroup(hitElement, prevState.editingGroupId)
          ? prevState.editingGroupId
          : null,
    }));
    this.setState({
      selectedElementIds: {},
      previousSelectedElementIds: this.state.selectedElementIds,
    });
  }

  private handleInteractiveCanvasRef = (canvas: HTMLCanvasElement | null) => {
    // canvas is null when unmounting
    if (canvas !== null) {
      this.interactiveCanvas = canvas;

      // -----------------------------------------------------------------------
      // NOTE wheel, touchstart, touchend events must be registered outside
      // of react because react binds them them passively (so we can't prevent
      // default on them)
      // CHANGED:ADD 2023-2-15 #711
      this.interactiveCanvas.addEventListener(EVENT.WHEEL, this.preventDefault);
      // CHANGED:UDPATE 2023-2-15 #711
      this.interactiveCanvas.addEventListener(EVENT.WHEEL, this.handleWheelEx, {
        passive: false,
      });
      this.interactiveCanvas.addEventListener(
        EVENT.TOUCH_START,
        this.onTouchStart,
      );
      this.interactiveCanvas.addEventListener(EVENT.TOUCH_END, this.onTouchEnd);
      // -----------------------------------------------------------------------
    } else {
      // // CHANGED:ADD 2023-2-15 #711
      // this.interactiveCanvas?.removeEventListener(
      //   EVENT.WHEEL,
      //   this.preventDefault
      // );
      // CHANGED:UDPATE 2023-2-15 #711
      this.interactiveCanvas?.removeEventListener(
        EVENT.WHEEL,
        this.handleWheelEx
      );
      this.interactiveCanvas?.removeEventListener(
        EVENT.TOUCH_START,
        this.onTouchStart,
      );
      this.interactiveCanvas?.removeEventListener(
        EVENT.TOUCH_END,
        this.onTouchEnd,
      );
    }
  };

  private handleAppOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    // must be retrieved first, in the same frame
    // CHANGED: ADD 2024-02-23 #1684
    if (this.state.openSidebar === "comments") {
      // コメントのファイルアップロードと被るのでコメント表示時にはファイルアップロードを禁止
      return;
    }
    const { file, fileHandle } = await getFileFromEvent(event);

    try {
      if (isSupportedImageFile(file)) {
        // first attempt to decode scene from the image if it's embedded
        // ---------------------------------------------------------------------

        if (file?.type === MIME_TYPES.png || file?.type === MIME_TYPES.svg) {
          try {
            const scene = await loadFromBlob(
              file,
              this.state,
              this.scene.getElementsIncludingDeleted(),
              fileHandle,
            );
            this.syncActionResult({
              ...scene,
              appState: {
                ...(scene.appState || this.state),
                isLoading: false,
              },
              replaceFiles: true,
              commitToHistory: true,
            });
            return;
          } catch (error: any) {
            if (error.name !== "EncodingError") {
              throw error;
            }
          }
        }

        // if no scene is embedded or we fail for whatever reason, fall back
        // to importing as regular image
        // ---------------------------------------------------------------------

        const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
          event,
          this.state,
        );

        const imageElement = this.createImageElement({ sceneX, sceneY });
        this.insertImageElement(imageElement, file);
        this.initializeImageDimensions(imageElement);
        this.setState({ selectedElementIds: { [imageElement.id]: true } });

        return;
      }
    } catch (error: any) {
      return this.setState({
        isLoading: false,
        errorMessage: error.message,
      });
    }

    const libraryJSON = event.dataTransfer.getData(MIME_TYPES.conpathlib);
    if (
      libraryJSON && typeof libraryJSON === "string" &&
      (this.state.width - LIBRARY_SIDEBAR_WIDTH) > event.clientX
    ) {
      try {
        const libraryItems = parseLibraryJSON(libraryJSON);
        // CHANGED:UPDATE 2024/02/06 #1579
        // this.addElementsFromPasteOrLibrary({
        //   elements: distributeLibraryItemsOnSquareGrid(libraryItems),
        //   position: event,
        //   files: null,
        // });
        await this.addElementsFromPasteOrLibrary({
          elements: distributeLibraryItemsOnSquareGrid(libraryItems),
          taskChildren: {
            assignUsers: libraryItems.reduce(
              (acc: ExcalidrawAssignUsers[], item) => {
                if (item.taskChildren && item.taskChildren.assignUsers) {
                  acc.push(...item.taskChildren.assignUsers);
                }
                return acc;
              }, []),
            assignResources: libraryItems.reduce(
              (acc: ExcalidrawAssignResources[], item) => {
                if (item.taskChildren && item.taskChildren.assignResources) {
                  acc.push(...item.taskChildren.assignResources);
                }
                return acc;
              }, []),
            tags: libraryItems.reduce(
              (acc: ExcalidrawTags[], item) => {
                if (item.taskChildren && item.taskChildren.tags) {
                  acc.push(...item.taskChildren.tags);
                }
                return acc;
              }, []),
            checklists: libraryItems.reduce(
              (acc: ExcalidrawChecklist[], item) => {
                if (item.taskChildren && item.taskChildren.checklists) {
                  acc.push(...item.taskChildren.checklists);
                }
                return acc;
              }, []),
          },
          position: event,
          files: null,
        });
      } catch (error: any) {
        this.setState({ errorMessage: error.message });
      }
      return;
    }

    if (file) {
      // atetmpt to parse an excalidraw/excalidrawlib file
      await this.loadFileToCanvas(file, fileHandle);
    }
  };

  loadFileToCanvas = async (
    file: File,
    fileHandle: FileSystemHandle | null,
  ) => {
    file = await normalizeFile(file);
    try {
      const ret = await loadSceneOrLibraryFromBlob(
        file,
        this.state,
        this.scene.getElementsIncludingDeleted(),
        fileHandle,
      );
      if (ret.type === MIME_TYPES.conpath) {
        this.setState({ isLoading: true });
        this.syncActionResult({
          ...ret.data,
          appState: {
            ...(ret.data.appState || this.state),
            isLoading: false,
          },
          replaceFiles: true,
          commitToHistory: true,
        });
      } else if (ret.type === MIME_TYPES.conpathlib) {
        await this.library
          .updateLibrary({
            libraryItems: file,
            merge: true,
            openLibraryMenu: true,
          })
          .catch((error) => {
            console.error(error);
            this.setState({ errorMessage: t("errors.importLibraryError") });
          });
      }
    } catch (error: any) {
      this.setState({ isLoading: false, errorMessage: error.message });
    }
  };

  private handleCanvasContextMenu = (
    event: React.MouseEvent<HTMLElement | HTMLCanvasElement>,
  ) => {
    event.preventDefault();
    if (
      (("pointerType" in event.nativeEvent &&
        event.nativeEvent.pointerType === "touch") ||
        ("pointerType" in event.nativeEvent &&
          event.nativeEvent.pointerType === "pen" &&
          // always allow if user uses a pen secondary button
          event.button !== POINTER_BUTTON.SECONDARY)) &&
      this.state.activeTool.type !== "selection"
      // this.state.viewModeEnabled // CHANGED:ADD 2023/09/22 #1123
    ) {
      return;
    }

    const { x, y } = viewportCoordsToSceneCoords(event, this.state);
    const element = this.getElementAtPosition(x, y, {
      preferSelected: true,
      includeLockedElements: true,
    });

    const selectedElements = this.scene.getSelectedElements(this.state);
    const isHittignCommonBoundBox =
      this.isHittingCommonBoundingBoxOfSelectedElements(
        { x, y },
        selectedElements,
      );

    const type = (element || isHittignCommonBoundBox) && !isCommentElement(element) ? "element" : "canvas";


    trackEvent("contextMenu", "openContextMenu", type);

    this.setState(
      {
        ...(element && !this.state.selectedElementIds[element.id]
          ? {
            ...this.state,
            ...selectGroupsForSelectedElements(
              {
                editingGroupId: this.state.editingGroupId,
                selectedElementIds: { [element.id]: true },
              },
              this.scene.getNonDeletedElements(),
              this.state,
              null,
            ),
            selectedLinearElement: isLinearElement(element)
              ? new LinearElementEditor(element)
              : null,
            // CHANGED:ADD 2022-10-28 #14
            selectedTaskElement: isTaskElement(element)
              ? new TaskElementEditor(element, this.scene, this.state)
              : null,
            // CHANGED:ADD 2022-11-2 #64
            selectedLinkElement: isLinkElement(element)
              ? new LinkElementEditor(element, this.scene, this.state)
              : null,
          }
          : this.state),
        showHyperlinkPopup: false,
      },
      () => {
        const container = this.excalidrawContainerRef.current!;
        const contextMenuItems = this.getContextMenuItems(type);
        let itemsHeight = 0;
        const items = contextMenuItems.reduce((acc: ContextMenuItem[], item) => {
          if (!item) return acc;
          if (item === CONTEXT_MENU_SEPARATOR) {
            acc.push(item);
            itemsHeight += 1;
          } else if (
            !item.predicate ||
            item.predicate(
            this.scene.getNonDeletedElements(),
            this.state,
            this.actionManager.app.props,
            this.actionManager.app,
            )) {
            acc.push(item);
            itemsHeight += CONTEXT_MENU_ITEM_HEIGHT;
          }
          return acc;
        }, []);
        const { innerHeight } = window;
        const { top: offsetTop, left: offsetLeft } =
          container.getBoundingClientRect();
        const diffY = event.clientY + itemsHeight - innerHeight
        const left = event.clientX - offsetLeft;
        const top = diffY > 0 ? event.clientY - offsetTop + itemsHeight - diffY : event.clientY - offsetTop;

        this.setState({
          contextMenu: { top, left, items },
        });
      },
    );
  };

  private maybeDragNewGenericElement = (
    pointerDownState: PointerDownState,
    event: MouseEvent | KeyboardEvent,
  ): void => {
    const draggingElement = this.state.draggingElement;
    const pointerCoords = pointerDownState.lastCoords;
    if (!draggingElement) {
      return;
    }
    if (
      draggingElement.type === "selection" &&
      this.state.activeTool.type !== "eraser"
    ) {
      dragNewElement(
        draggingElement,
        this.state.activeTool.type,
        pointerDownState.origin.x,
        pointerDownState.origin.y,
        pointerCoords.x,
        pointerCoords.y,
        distance(pointerDownState.origin.x, pointerCoords.x),
        distance(pointerDownState.origin.y, pointerCoords.y),
        shouldMaintainAspectRatio(event),
        shouldResizeFromCenter(event),
      );
    } else {
      const [gridX, gridY] = getGridPoint(
        pointerCoords.x,
        pointerCoords.y,
        this.state.gridSize,
      );

      const image =
        isInitializedImageElement(draggingElement) &&
        this.imageCache.get(draggingElement.fileId)?.image;
      const aspectRatio =
        image && !(image instanceof Promise)
          ? image.width / image.height
          : null;

      dragNewElement(
        draggingElement,
        this.state.activeTool.type,
        pointerDownState.originInGrid.x,
        pointerDownState.originInGrid.y,
        gridX,
        gridY,
        distance(pointerDownState.originInGrid.x, gridX),
        distance(pointerDownState.originInGrid.y, gridY),
        isImageElement(draggingElement)
          ? !shouldMaintainAspectRatio(event)
          : shouldMaintainAspectRatio(event),
        shouldResizeFromCenter(event),
        aspectRatio,
      );

      this.maybeSuggestBindingForAll([draggingElement]);
    }
  };

  private maybeHandleResize = (
    pointerDownState: PointerDownState,
    event: MouseEvent | KeyboardEvent,
  ): boolean => {
    const selectedElements = this.scene.getSelectedElements(this.state);
    const transformHandleType = pointerDownState.resize.handleType;
    this.setState({
      // TODO: rename this state field to "isScaling" to distinguish
      // it from the generic "isResizing" which includes scaling and
      // rotating
      isResizing: transformHandleType && transformHandleType !== "rotation",
      isRotating: transformHandleType === "rotation",
    });
    const pointerCoords = pointerDownState.lastCoords;
    const [resizeX, resizeY] = getGridPoint(
      pointerCoords.x - pointerDownState.resize.offset.x,
      pointerCoords.y - pointerDownState.resize.offset.y,
      this.state.gridSize,
    );
    if (
      transformElements(
        pointerDownState.originalElements,
        transformHandleType,
        selectedElements,
        this.scene.getElementsMapIncludingDeleted(),
        shouldRotateWithDiscreteAngle(event),
        shouldResizeFromCenter(event),
        selectedElements.length === 1 && isImageElement(selectedElements[0])
          ? !shouldMaintainAspectRatio(event)
          : shouldMaintainAspectRatio(event),
        resizeX,
        resizeY,
        pointerDownState.resize.center.x,
        pointerDownState.resize.center.y,
        this.state, //CHANGED:ADD 2022-11-01 #79
        this.calendar, // CHANGED:ADD 2022/01/19 #390
        this.scene,
      )
    ) {
      this.maybeSuggestBindingForAll(selectedElements);
      return true;
    }
    return false;
  };

  private getContextMenuItems = (
    type: "canvas" | "element",
  ): ContextMenuItems => {
    const options: ContextMenuItems = [];

    // CHANGED:UPDATE 2024-02-14 #1655
    // options.push(actionCopyAsPng, actionCopyAsSvg);
    options.push(actionCopyAsPng);

    // canvas contextMenu
    // -------------------------------------------------------------------------

    if (type === "canvas") {
      if (this.state.viewModeEnabled) {
        return [
          ...options,
          CONTEXT_MENU_SEPARATOR,
          actionToggleGridMode,
          actionToggleZenMode,
          // actionToggleViewMode, // CHANGED:REMOVE 2024-03-06 #1731
          CONTEXT_MENU_SEPARATOR,
          actionToggleStats,
        ];
      }

      return [
        actionPaste,
        CONTEXT_MENU_SEPARATOR,
        actionCopyAsPng,
        // actionCopyAsSvg, // CHANGED:REMOVE 2024-02-14 #1655
        copyText,
        CONTEXT_MENU_SEPARATOR,
        actionSelectAll,
        CONTEXT_MENU_SEPARATOR,
        actionToggleGridMode,
        actionToggleZenMode,
        actionToggleEmphasizedMode, // CHANGED:ADD 2023-3-10 #763
        actionToggleTransparentMode, // CHANGED:ADD 2023-3-10 #763
        actionToggleCriticalPathMode, // CHANGED:ADD 2024-3-9 #1753
        actionToggleOverdueTaskMode, // CHANGED:ADD 2024-5-15 #1848
        // actionToggleViewMode, // CHANGED:REMOVE 2024-03-06 #1731
        CONTEXT_MENU_SEPARATOR,
        actionToggleStats,
      ];
    }

    // element contextMenu
    // -------------------------------------------------------------------------

    options.push(copyText);

    if (this.state.viewModeEnabled) {
      return [
        actionCopy,
        // actionAddComment, // CHANGED: ADD 2023-12-06 #1327
        actionSendComment, //CHANGED: ADD 2023-02-22 #1684
        ...options,
      ];
    }

    return [
      actionCut,
      actionCopy,
      actionPaste,
      CONTEXT_MENU_SEPARATOR,
      ...options,
      CONTEXT_MENU_SEPARATOR,
      actionCopyStyles,
      actionPasteStyles,
      CONTEXT_MENU_SEPARATOR,
      actionGroup,
      actionUnbindText,
      actionBindText,
      actionWrapTextInContainer,
      actionUngroup,
      CONTEXT_MENU_SEPARATOR,
      actionSendComment, //CHANGED: ADD 2023-02-22 #1684
      // actionAddComment, // CHANGED: ADD 2023-12-04 #1327
      // actionAddToLibrary, // CHANGED:REMOVE 2024-02-06 #1579
      actionToggleAddLibrary, // CHANGED:ADD 2024-02-06 #1579
      CONTEXT_MENU_SEPARATOR,
      actionSendBackward,
      actionBringForward,
      actionSendToBack,
      actionBringToFront,
      CONTEXT_MENU_SEPARATOR,
      // actionFlipHorizontal,
      // actionFlipVertical,
      CONTEXT_MENU_SEPARATOR,
      actionToggleLinearEditor,
      actionLink, // CHANGED:ADD 2024-2-7 #1591
      actionToggleEditTask, // CHANGED:ADD 2023-1-26 #517
      actionAlignLeftTask, // CHANGED:ADD 2023-1-27 #509
      CONTEXT_MENU_SEPARATOR,
      actionCopyJobRow, // CHANGED:ADD 2024-5-22 #2047
      actionPasteJobRowAbove, // CHANGED:ADD 2024-5-22 #2047
      actionPasteJobRowBelow, // CHANGED:ADD 2024-5-22 #2047
      CONTEXT_MENU_SEPARATOR,
      actionInsertJobRowAbove, // CHANGED:ADD 2023-2-9 #588
      actionInsertJobRowBelow, // CHANGED:ADD 2023-2-9 #588
      actionToggleJobRowExpansion, // CHANGED: ADD 2023-03-01 #740
      actionDuplicateSelection,
      actionToggleLock,
      actionToggleClose, // CHANGED:ADD 2023-02-26 #739
      CONTEXT_MENU_SEPARATOR,
      actionDeleteSelected,
    ];
  };

  private handleWheel = withBatchedUpdates(
    (
      event: WheelEvent | React.WheelEvent<HTMLDivElement | HTMLCanvasElement>,
    ) => {
    event.preventDefault();
    if (isPanning) {
      return;
    }

    const { deltaX, deltaY } = event;
    // note that event.ctrlKey is necessary to handle pinch zooming
    if (event.metaKey || event.ctrlKey) {
      const sign = Math.sign(deltaY);
      const MAX_STEP = ZOOM_STEP * 500;
      const absDelta = Math.abs(deltaY);
      let delta = deltaY;
      if (absDelta > MAX_STEP) {
        delta = MAX_STEP * sign;
      }

      // CHANGED:UPDATE 2022-11-01 #86
      // let newZoom = this.state.zoom.value - delta / 100;
      let newZoom = this.state.zoom.value - delta / 1000;
      // increase zoom steps the more zoomed-in we are (applies to >100% only)
      newZoom +=
        Math.log10(Math.max(1, this.state.zoom.value)) *
        -sign *
        // reduced amplification for small deltas (small movements on a trackpad)
        Math.min(1, absDelta / 20);

      this.translateCanvas((state) => ({
        ...getStateForZoom(
          {
            viewportX: cursorX,
            viewportY: cursorY,
            nextZoom: getNormalizedZoom(newZoom),
          },
          state,
        ),
        shouldCacheIgnoreZoom: true,
      }));
      this.resetShouldCacheIgnoreZoomDebounced();
      return;
    }

    // scroll horizontally when shift pressed
    if (event.shiftKey) {
      this.translateCanvas(({ zoom, scrollX }) => ({
        // on Mac, shift+wheel tends to result in deltaX
        // CHANGED:UPDATE 2022-11-15 #134
        // scrollX: scrollX - (deltaY || deltaX) / zoom.value,
        scrollX: this.scroll.getOffsetScrollX(
          scrollX - (deltaY || deltaX) / zoom.value,
        ),
      }));
      return;
    }

    this.translateCanvas(({ zoom, scrollX, scrollY }) => ({
      // CHANGED:UPDATE 2022-11-15 #134
      // scrollX: scrollX - deltaX / zoom.value,
      // scrollY: scrollY - deltaY / zoom.value,
      scrollX: this.scroll.getOffsetScrollX(scrollX - deltaX / zoom.value),
      scrollY: this.scroll.getOffsetScrollY(scrollY - deltaY / zoom.value),
    }));
  });

  // CHANGE:ADD 2023-2-15 #711
  private preventDefault = (event: WheelEvent) => {
    event.preventDefault();
  }

  // CHANGED:UPDATE 2024/1/31 #1563
  private handleWheelEx = ((event: WheelEvent) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.handleWheel(event);
        ticking = false;
      });

      ticking = true;
    }
  });

  private getTextWysiwygSnappedToCenterPosition(
    x: number,
    y: number,
    appState: AppState,
    container?: ExcalidrawTextContainer | null,
  ) {
    if (container) {
      let elementCenterX = container.x + container.width / 2;
      let elementCenterY = container.y + container.height / 2;

      //CHANGED:UPDATE 2022/12/08 #225
      let elementCenter;
      if (isGraphElement(container)) {
        elementCenter = getContainerCenterEx(
          container,
          this.state,
          this.scene.getNonDeletedElementsMap(),
        );
      } else {
        elementCenter = getContainerCenter(
          container,
          appState,
          this.scene.getNonDeletedElementsMap(),
        );
      }
      if (elementCenter) {
        elementCenterX = elementCenter.x;
        elementCenterY = elementCenter.y;
      }
      const distanceToCenter = Math.hypot(
        x - elementCenterX,
        y - elementCenterY,
      );
      const isSnappedToCenter =
        distanceToCenter < TEXT_TO_CENTER_SNAP_THRESHOLD;
      if (isSnappedToCenter) {
        const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
          { sceneX: elementCenterX, sceneY: elementCenterY },
          appState,
        );
        return { viewportX, viewportY, elementCenterX, elementCenterY };
      }
    }
  }

  private savePointer = (x: number, y: number, button: "up" | "down") => {
    if (!x || !y) {
      return;
    }
    const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
      { clientX: x, clientY: y },
      this.state,
    );

    if (isNaN(sceneX) || isNaN(sceneY)) {
      // sometimes the pointer goes off screen
    }

    const pointer: CollaboratorPointer = {
      x: sceneX,
      y: sceneY,
      tool: this.state.activeTool.type === "laser" ? "laser" : "pointer",
    };

    this.props.onPointerUpdate?.({
      pointer,
      button,
      pointersMap: gesture.pointers,
    });
  };

  private resetShouldCacheIgnoreZoomDebounced = debounce(() => {
    if (!this.unmounted) {
      this.setState({ shouldCacheIgnoreZoom: false });
    }
  }, 300);

  private updateDOMRect = (cb?: () => void) => {
    if (this.excalidrawContainerRef?.current) {
      const excalidrawContainer = this.excalidrawContainerRef.current;
      const {
        width,
        height,
        left: offsetLeft,
        top: offsetTop,
      } = excalidrawContainer.getBoundingClientRect();
      const {
        width: currentWidth,
        height: currentHeight,
        offsetTop: currentOffsetTop,
        offsetLeft: currentOffsetLeft,
      } = this.state;

      if (
        width === currentWidth &&
        height === currentHeight &&
        offsetLeft === currentOffsetLeft &&
        offsetTop === currentOffsetTop
      ) {
        if (cb) {
          cb();
        }
        return;
      }

      this.setState(
        {
          width,
          height,
          offsetLeft,
          offsetTop,
        },
        () => {
          cb && cb();
        },
      );
    }
  };

  public refresh = () => {
    this.setState({ ...this.getCanvasOffsets() });
  };

  private getCanvasOffsets(): Pick<AppState, "offsetTop" | "offsetLeft"> {
    if (this.excalidrawContainerRef?.current) {
      const excalidrawContainer = this.excalidrawContainerRef.current;
      const { left, top } = excalidrawContainer.getBoundingClientRect();
      return {
        offsetLeft: left,
        offsetTop: top,
      };
    }
    return {
      offsetLeft: 0,
      offsetTop: 0,
    };
  }

  private async updateLanguage() {
    const currentLang =
      languages.find((lang) => lang.code === this.props.langCode) ||
      defaultLang;
    await setLanguage(currentLang);
    this.setAppState({});
  }

  // CHANGED:ADD 2022-11-30 #214
  private updateCriticalPathElements = () => {
    let didChange = false;

    const criticalPathIds = CriticalPath.getCriticalPathIds(
      this.scene.getNonDeletedElements(),
    );
    const nextElements = [...this.scene.getElementsIncludingDeleted()];

    (nextElements
      .filter((element) => !element.isDeleted && isGraphElement(element)) as ExcalidrawGraphElement[])
      .forEach((element) => {
        let isCriticalPath = false;

        if (criticalPathIds.has(element.id)) {
          isCriticalPath = true;
        }
  
        if (element.isCriticalPath !== isCriticalPath) {
          const newElement = newElementWith(element, {
            isCriticalPath,
            priority: isCriticalPath
              ? PRIORITY[`${element.type}-cp`]
              : PRIORITY[element.type],
          });
          const index = nextElements.findIndex((el) => el === element);
          if (index !== -1) {
            nextElements.splice(index, 1, newElement);
            didChange = true;
          }

          // CHANGED:ADD 2023/8/29 #961
          const textElement = getBoundTextElement(
            element,
            this.scene.getNonDeletedElementsMap(),
          );
          if (textElement) {
            const newElement = newElementWith(textElement, {
              // strokeColor: isCriticalPath
              //   ? this.state.criticalPathColor
              //   : element.strokeColor,
              priority: isCriticalPath
                ? PRIORITY["text-cp"]
                : PRIORITY["text"],
            });

            const index = nextElements.findIndex((el) => el === textElement);
            if (index !== -1) {
              nextElements.splice(index, 1, newElement);
              didChange = true;
            }
          }
        }
      });

    if (didChange) {
      this.scene.replaceAllElements(nextElements);
    }
  };
}

// -----------------------------------------------------------------------------
// TEST HOOKS
// -----------------------------------------------------------------------------

declare global {
  interface Window {
    h: {
      elements: readonly ExcalidrawElement[];
      state: AppState;
      setState: React.Component<any, AppState>["setState"];
      app: InstanceType<typeof App>;
      history: History;
    };
  }
}

export const createTestHook = () => {
  if (import.meta.env.MODE === ENV.TEST || import.meta.env.DEV) {
    window.h = window.h || ({} as Window["h"]);

    Object.defineProperties(window.h, {
      elements: {
        configurable: true,
        get() {
          return this.app?.scene.getElementsIncludingDeleted();
        },
        set(elements: ExcalidrawElement[]) {
          return this.app?.scene.replaceAllElements(elements);
        },
      },
    });
  }
};

createTestHook();
export default App;
