import React, { forwardRef, useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { t } from "src/excalidraw/i18n";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import { AppState } from "src/excalidraw/types";
import CommentInput from "src/conpath/components/CommentInput";

import { Popover } from "src/excalidraw/components/Popover";
import "./CommentPopover.scss";
import { SendMessageIcon } from "src/excalidraw/components/icons";

import { useStore } from "src/conpath/hooks/useStore";

import { ExcalidrawCommentableElement } from "../element/types";
import CommentModel from "src/conpath/models/CommentModel";
import { RotatingLines } from "react-loader-spinner";
import Colors from "src/conpath/constants/Colors";
import ProjectModel from "src/conpath/models/ProjectModel";
import { OrganizationRole } from "src/conpath/constants/Role";

interface Props {
  appState: AppState,
  top: number,
  left: number,
  element: ExcalidrawCommentableElement,
  updateData: (formData?: {
    rootCommentId: string,
    commentableElement: ExcalidrawCommentableElement,
  }) => void;
}
const CommentPopover: React.FC<Props> = forwardRef((
  {
    appState,
    top,
    left,
    element,
    updateData,
  },
  ref,
) => {
  const setAppState = useExcalidrawSetAppState();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [project, setProject] = useState<ProjectModel|null>(null);
  const [newComment] = useState<CommentModel>(new CommentModel({
    id: "",
    text: "",
    projectId: appState.projectId,
    rootCommentId: "",
    commentElementId: element.id,
    mentioningTo: [],
    uploadedFiles: [],
    createdBy: "",
    createdAt: new Date(),
    updatedAt: null,
    isDeleted: false,
    isDeleteElementRequired: false,
    replayCount: 0,
    lastReplayedAt: null,
  }));

  useEffect(() => {
    const getComments = async () => {
      if (!project && selectedOrganization) {
        const selectedProject = selectedOrganization.
        projects.find((project) => project.id === appState.projectId);
        setProject(selectedProject!);
      }
    }

    getComments();
  }, []);

  const onClose = () => {
    setAppState({ openDialog: null });
  };

  const onChangeComment = useCallback((value: string) => {
    newComment.updateText(value);
  }, [newComment]);

  const onSubmitButtonPressed = async () => {
    if (!loginUser || !project || !selectedOrganization) return;

    newComment.setAuthor(loginUser.id);
    newComment.setOrganizationId(selectedOrganization.id);

    const result = await newComment.create({ projectName: project.name, organizationName: selectedOrganization.name });

    if (result.error) {
      setAppState({ errorMessage: result.error });
      onClose();
      return;
    }

    updateData({ 
      rootCommentId: result.commentId!, 
      commentableElement: element
    });
    onClose();
  };

  return (
    <Popover
      onCloseRequest={onClose}
      top={top}
      left={left}
      fitInViewport={true}
      offsetLeft={appState.offsetLeft}
      offsetTop={appState.offsetTop}
      viewportWidth={appState.width}
      viewportHeight={appState.height}
    >
      {selectedOrganization && loginUser && (
        <div className="comment-popup">
          <CommentInput 
            disabled={false}
            value={newComment.text}
            items={selectedOrganization
              .getJoinedUserExceptMe(loginUser)
              .filter((user) => 
                (project && project.roles[user.id]) ||
                (project?.teams && Object.keys(project.teams).some((key) =>
                  selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
                )))
              .map((user) => {
              return {
                id: user.id,
                display: user.username
              }
            }) || []}
            mentions={newComment.mentioningTo}
            placeholder={t("labels.commentInput")} 
            onChange={onChangeComment}
            onMentioned={(mention) => newComment.addMention(mention)}
          />
          {
            newComment.isCreating ? 
              (
                <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="32"
                    visible={true}
                  />
              ) :
              (
                <button 
                  disabled={newComment.text.trim().length === 0}
                  onClick={onSubmitButtonPressed}>
                  {SendMessageIcon}
                </button>
              )
          }
        </div>
      )}
    </Popover>
  )
});

export default observer(CommentPopover);