import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  useDevice,
  useExcalidrawSetAppState,
} from "src/excalidraw/components/App";
import { useOnClickOutside } from "src/excalidraw/extensions/hooks/useOutsideClick";
import { AppState } from "src/excalidraw/types";
import { Sidebar } from "src/excalidraw/components/Sidebar/Sidebar";
import { trackEvent } from "src/excalidraw/analytics";

import "./ThreadListSidebarPanel.scss";
import { CircularCheckedIcon, CommentIcon, DashedCheckedIcon } from "src/excalidraw/components/icons";
import { t } from "src/excalidraw/i18n";
import SearchBar from "src/conpath/components/SearchBar";
import { DATE_FORMAT, formatDate } from "src/utils/dateUtils";
import CommentInput from "src/conpath/components/CommentInput";
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";
import ProjectModel from "src/conpath/models/ProjectModel";
import CommentModel from "src/conpath/models/CommentModel";
import { ExcalidrawCommentElement } from "../element/types";

interface Props {
  appState: AppState;
  elements: ExcalidrawCommentElement[];
  commentElementIds: string[];
  openComments: (comment: CommentModel) => void;
};

export const ThreadListSidebarPanel: React.FC<Props> = observer(({
  appState,
  elements,
  commentElementIds,
  openComments
}) => {

  const setAppState = useExcalidrawSetAppState();
  const device = useDevice();
  const { userStore, organizationStore } = useStore();

  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [project, setProject] = useState<ProjectModel|null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const ref = useRef<HTMLDivElement | null>(null);

  const threads = project?.getThreads;
  const users = selectedOrganization?.users || [];

  useEffect(() => {
    const getComments = async () => {
      if (!project && selectedOrganization) {
        const selectedProject = selectedOrganization.
        projects.find((project) => project.id === appState.projectId);

        const result = await selectedProject?.getThreadsInProject(commentElementIds, users);
        if (result && result.error) setAppState({ errorMessage: result.error });
        
        setProject(selectedProject!);
      }
    }

    getComments();
  }, []);


  useEffect(() => {
    return () => {
      project?.clearThreads();
    }
  }, [project])

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ openSidebar: null });
  }, [setAppState]);

  useOnClickOutside(
    ref,
    useCallback(
      (event) => {
        // If click on the library icon, do nothing so that LibraryButton
        // can toggle library menu
        if ((event.target as Element).closest(".ToolIcon__library")) {
          return;
        }
        if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
          closeLibrary();
        }
      },
      [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar],
    ),
  );

  const onSearchButtonPressed = useCallback(() => {
    if (!project) return;

    project.setThreadSearchText(searchText);
  }, [searchText, project]);

  return (
    <Sidebar
      __isInternal
      // necessary to remount when switching between internal
      // and custom (host app) sidebar, so that the `props.onClose`
      // is colled correctly
      key="comment"
      className="layer-ui__thread-sidebar"
      initialDockedState={appState.isSidebarDocked}
      onDock={(docked) => {
        trackEvent(
          "library",
          `toggleLibraryDock (${docked ? "dock" : "undock"})`,
          `sidebar (${device.isMobile ? "mobile" : "desktop"})`,
        );
      }}
      ref={ref}
    >
      <Sidebar.Header className="layer-ui__thread-header">
        <div className="header-content-wrapper">
          <div className="header-label">
            {CommentIcon}
            <p>{t("labels.comment.allThreads")}</p>
          </div>
          <SearchBar 
            className="search-bar"
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)}
            onEnterKeyPressed={onSearchButtonPressed}
            placeholder="コメント検索" />
        </div>
      </Sidebar.Header>
      { selectedOrganization && loginUser && (
        <ul className="layer-ui__thread-list-wrapper">
          {threads && threads.map((comment) => {
            const commentElementOnCanvas = elements.find((el) => el.id === comment.id);

            return (
              <li 
                key={comment.id}
                className="thread-body"
                onClick={() => openComments(comment)}>
                <div className="thread">
                  <div 
                    className="thread-overlay"
                    onClick={(e) => {
                      e.stopPropagation();
                      openComments(comment);
                    }}/>
                  <CommentInput 
                    disabled={true}
                    value={comment.text}
                    items={[]}
                    mentions={[]}
                    placeholder={t("labels.commentInput")} 
                    onChange={() => {}}
                    onMentioned={() => {}} /> 
                </div>
                <div className="sub-section">
                  <div className="sub-section__profile-wrapper">
                    {
                      comment.profileImageUrl ?
                      (
                        <img className="profile-image" src={comment.profileImageUrl} />
                      ) :
                      (
                        <p className="profile-image__no-image">
                          {comment.username.charAt(0)}
                        </p>
                      )
                    }
                    <p className="username">{comment.username}</p>
                    <p className="time">{formatDate(comment.createdAt, DATE_FORMAT.COMMENT)}</p>
                    { comment.isDeleted && (
                      <div className="deleted-thread">
                        <p>{t("labels.comment.deletedThread")}</p>
                      </div>
                    )}
                  </div>
                  <div className="sub-section__replay-info-wrapper">
                    <p className="count">{comment.replayCount}件の返信</p>
                    {comment.lastReplayedAt && comment.replayCount ? (
                      <p className="time">最終返信: {formatDate(comment.lastReplayedAt, DATE_FORMAT.COMMENT)}</p>
                    ) : null}
                  </div>
                  <div className="sub-section__icon-wrapper">
                    {/* <button className="icon">
                      {MenuIcon}
                    </button> */}
                    <div className={commentElementOnCanvas?.isClosed ? "icon--closed" : "icon" }>
                      { commentElementOnCanvas?.isClosed ?
                        (CircularCheckedIcon) : (DashedCheckedIcon)
                      }
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </Sidebar>
  );
});