import _ from "lodash";

//types and interface
import { BookmarkedUsers, LibraryResponse } from "src/conpath/interfaces/Library";

//firebase
import { db } from "src/configs/firebase";
import {
  DocumentData,
  DocumentReference,
  doc,
  runTransaction,
} from "firebase/firestore";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import Library from "../interfaces/Library";
import { action } from "mobx";
import InternalError from "../interfaces/InternalError";
import { firebaseTimeToDate } from "src/utils/timeUtils";

export default class LibraryModel implements Library {
  id!: string;
  name!: string;
  category!: string;
  contentText!: string;
  bookmarkedUsers!: BookmarkedUsers;
  createdBy!: string;
  createdAt!: Date;

  organizationId: string | null = null;

  constructor(library: LibraryResponse) {
    this.setFields(library);
  };

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public getFields(): Library {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      contentText: this.contentText,
      bookmarkedUsers: this.bookmarkedUsers || {},
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    };
  }

  public setFields(library: LibraryResponse) {
    this.id = library.id;
    this.name = library.name;
    this.category = library.category;
    this.contentText = library.contentText;
    this.bookmarkedUsers = library.bookmarkedUsers;
    this.createdBy = library.createdBy;
    this.createdAt = firebaseTimeToDate(library.createdAt);
  }

  public setName(name: string) {
    this.name = name;
  }

  public setCategory(category: string) {
    this.category = category;
  }

  public setBookmarkedUsers(userId: string, isBookmarked: boolean) {
    const nextBookmarkedUsers = this.bookmarkedUsers;

    if (isBookmarked) {
      nextBookmarkedUsers[userId] = true;
    } else {
      delete nextBookmarkedUsers[userId];
    }

    this.bookmarkedUsers = nextBookmarkedUsers;
  }

  @action
  public async updateLibrary() {

    const libraryDocumentRef = this.getLibraryDocumentRef();

    if (!libraryDocumentRef) {
      throw new Error("No library collection ref found.");
    }

    // Updating library document
    const libraryUpdatingField: Partial<LibraryResponse> = {
      name: this.name,
      category: this.category,
      bookmarkedUsers: this.bookmarkedUsers,
    };

    await runTransaction(db, async (transaction) => {
      transaction.update(libraryDocumentRef, libraryUpdatingField);
    });
  }

  @action
  public async delete(): Promise<InternalError> {

    try {
      const libraryDocumentRef = this.getLibraryDocumentRef();
      if (_.isEmpty(libraryDocumentRef)) {
        throw new Error("No library collection ref found.");
      }

      await runTransaction(db, async (transaction) => {
        transaction.delete(libraryDocumentRef);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "削除に失敗しました。" };
    }
  }

  private getLibraryDocumentRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.organizations.this, this.organizationId!, FirestoreCollections.organizations.libraries, this.id);
  }
};