import { Dayjs } from 'dayjs';
import React from 'react';

export type DefaultRecordType = Record<string, any>;

export namespace Gantt {
  export interface Major {
    width: number;
    left: number;
    label: string;
    key: string;
  }
  export interface MajorAmp {
    label: string;
    startDate: Dayjs;
    endDate: Dayjs;
  }
  export interface Minor {
    width: number;
    left: number;
    label: string;
    isWeek: boolean;
    isSunday: boolean;
    isSaturday: boolean;
    key: string;
  }
  export interface MinorAmp {
    label: string;
    currentDate: Dayjs;
    startDate: Dayjs;
    endDate: Dayjs;
  }
  export type Sight = 'day' | 'week' | 'month' | 'quarter' | 'halfYear';
  export type MoveType = 'left' | 'right' | 'move' | 'create';
  export type TaskBarType = 'numberOfTasksCompleted' | 'percentOfTasksCompleted' | 'numberOfDaysCompleted' | 'percentOfDaysCompleted';

  export const ChartType = {
    Roadmap: 0,
    Resources: 1,
  } as const;

  export type ChartType = typeof ChartType[keyof typeof ChartType];

  export const BarType = {
    Project: 0,
    Milestone: 1,
    Task: 2,
    User: 3,
    Resource: 4,
    Nothing: -1,
  } as const;

  export type BarType = typeof BarType[keyof typeof BarType];

  export enum ESightValues {
    day = 2880,
    week = 3600,
    month = 14400,
    quarter = 86400,
    halfYear = 115200,
  }

  export interface SightConfig {
    type: Sight;
    label: string;
    value: ESightValues;
    selected: boolean;
  }

  export interface TaskBarConfig {
    type: TaskBarType;
    label: string;
    selected: boolean;
  }

  export interface ViewConfig {
    translateX: number;
    sightConfig: Gantt.SightConfig;
    taskBarConfig: Gantt.TaskBarType;
    visibleDataIds: string[];
  }

  export interface Bar<RecordType = DefaultRecordType> {
    key: React.Key;
    label: string;
    width: number;
    translateX: number;
    translateY: number;
    stepGesture: string;
    invalidDateRange: boolean;
    dateTextFormat: (startX: number) => string;
    getDateWidth: (startX: number, endX: number) => string;
    task: Item<RecordType>;
    record: Record<RecordType>;
    loading: boolean;
    startDate: string | null;
    endDate: string | null;
    _group?: boolean;
    _collapsed: boolean;
    _visible: boolean;
    _depth: number;
    _index?: number;
    _childrenCount: number;
    _parent?: Item<RecordType>;
  }

  export interface Item<RecordType = DefaultRecordType> {
    record: Record<RecordType>;
    key: React.Key;
    startDate: string | null;
    endDate: string | null;
    content: string;
    collapsed: boolean;
    visible: boolean;
    isAlwaysVisible: boolean;
    group?: boolean;
    children?: Item<RecordType>[];
    _parent?: Item<RecordType>;
    _bar?: Bar<RecordType>;
    _depth?: number;
    _index?: number;
    id: string;
    barType: BarType;
    isMilestone: boolean;
    isTask: boolean;
    projectId: string;
    projectName: string;
    projectColor?: string;
  }

  export type Record<RecordType = DefaultRecordType> = RecordType & {
    group?: boolean;
    borderColor?: string;
    backgroundColor?: string;
    collapsed?: boolean;
    visible?: boolean;
    isAlwaysVisible?: boolean;
    children?: Record<RecordType>[];
    disabled?: boolean;
    id: string;
    barType: BarType;
    isMilestone?: boolean;
    isTask?: boolean;
    ratio?: number;
    completedTaskCount?: number;
    totalTaskCount?: number;
    countRatio?: number;
    completedDays?: number;
    totalDays?: number;
    daysRatio?: number;
    duration?: number;
    isClosed?: boolean;
    isDelayed?: boolean;
    isOverlap?: boolean;
    isGap?: boolean;
    name?: string;
    projectId?: string;
    projectName?: string;
    projectColor?: string;
    startDate: Date;
    endDate: Date;
  }

  export type ColumnAlign = 'center' | 'right' | 'left';
  export interface Column<RecordType = DefaultRecordType> {
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    flex?: number;
    name: string;
    label: string;
    style?: Object;
    render?: (item: Record<RecordType>) => React.ReactNode;
    align?: ColumnAlign;
  }
  export type DependenceType = 'start_finish' | 'finish_start' | 'start_start' | 'finish_finish' | 'float' | 'overlap' | 'gap';
  export interface Dependence {
    from: string;
    to: string;
    type: DependenceType;
    color?: string;
    duration?: number;
  }
}
