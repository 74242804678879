
export const FirebaseHttpsRequests = {
  createOrganization: "createOrganization",
  createOrganizationUsers: "createOrganizationUsers",
  fetchCustomToken: "fetchCustomToken",
  removeUserFromOrganization: "removeUserFromOrganization",
  resendInvitationEmail: "resendInvitationEmail",
  acceptOrganizationInvitation: "acceptOrganizationInvitation",
  declineOrganizationInvitation: "declineOrganizationInvitation",
  sendComment: "sendComment",
  enqueueHandleDeleteOrRestoreComments: "enqueueHandleDeleteOrRestoreComments",
} as const;

export type FirebaseHttpsRequests = typeof FirebaseHttpsRequests[keyof typeof FirebaseHttpsRequests];
