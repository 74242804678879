//types and interface
import User, { OrganizationUserDocumentFields, OrganizationUserResponse, UpdateOrganizationUserForm } from "src/conpath/interfaces/User";
import { OrganizationRole } from "src/conpath/constants/Role";
import { OrganizationUserState } from "src/conpath/constants/OrganizationUserState";
import InternalError from "../interfaces/InternalError";
import { RemoveUserFromOrganizationParam, ResendInvitationRequestParm } from "src/conpath/interfaces/HttpRequests";

//constants
import { FirebaseHttpsRequests } from "src/conpath/constants/FirebaseHttpsRequests";

//utils
import { dateToFirebaseTime, firebaseTimeToDate } from "src/utils/timeUtils";

//firebase
import { db, functions } from "src/configs/firebase";
import { httpsCallable } from "firebase/functions";
import {
  DocumentData,
  DocumentReference,
  doc,
  updateDoc,
} from "firebase/firestore";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import { 
  RemoveUserFromOrganizationErrorType, 
  RemoveUserFromOrganizationErrors, 
  ResentInvitationRequestError, 
  ResentInvitationRequestErrorType 
} from "src/conpath/constants/errors/OrganizationUserRequestErrors";
export default class OrganizationUserModel implements User {

  id!: string;
  docId!: string;
  email!: string;
  username!: string;
  profileImageUrl!: string | null;
  role!: OrganizationRole;
  state!: OrganizationUserState;
  teamIds!: string[] | null;
  invitationId!: string | null;
  emailSentAt!: Date | null;
  joinedAt!: Date | null;
  removedAt!: Date | null;
  declinedAt!: Date | null;

  organizationId: string | null = null;

  constructor(user: OrganizationUserResponse) {
    this.setFields(user);
  };

  public isJoined(): boolean {
    return this.state === OrganizationUserState.joined;
  } 

  /**
   * キャンセル未満の状態(Email送信済、Emailの送信失敗、ユーザーの承認待ち、招待の有効期限切れ)
   * @returns 招待中のユーザー
   */
  public isInvited(): boolean {
    return this.state <= OrganizationUserState.invitationExpired;
  }

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  /**
   * ユーザーの退会処理を行う関数
   * CloudFunctionのremove-user-from-organization/を呼び出す
   * !WARNING: 管理ユーザーのみこのメソッドを呼び出す予定
   */
  public async removeUser(): Promise<InternalError> {
    
    try {
      if (!this.organizationId) {
        throw new Error(RemoveUserFromOrganizationErrorType.General);
      }
      const params: RemoveUserFromOrganizationParam = {
        organizationId: this.organizationId,
        userId: this.id,
      };

      const request = httpsCallable(functions, FirebaseHttpsRequests.removeUserFromOrganization);
      await request(params);
      return {};
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      const message = RemoveUserFromOrganizationErrors[error.message as RemoveUserFromOrganizationErrorType] || RemoveUserFromOrganizationErrors.General;
      return { error: message };
    }
  }
  /**
   * ユーザーの再招待を行う関数
   * CloudFunctionのresend-invitation-email/を呼び出す
   * !WARNING: 管理ユーザーのみこのメソッドを呼び出す予定
   */
  public async resendInvitation(): Promise<InternalError> {

    try {
      if (!this.organizationId) {
        throw new Error(ResentInvitationRequestErrorType.General);
      }

      const params: ResendInvitationRequestParm = {
        organizationId: this.organizationId,
        userEmail: this.email,
      };
      const request = httpsCallable(functions, FirebaseHttpsRequests.resendInvitationEmail);
      await request(params);
      return {};
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      const message = ResentInvitationRequestError[error.message as ResentInvitationRequestErrorType] || ResentInvitationRequestError.General;
      return { error: message };
      // Sentry here
    }
  }

  /**
   * ユーザーの更新(管理、編集、閲覧)
   * !WARNING: 管理ユーザーのみこのメソッドを呼び出す予定
   */
  public async updateUser(user: UpdateOrganizationUserForm): Promise<boolean> {
    return await this.updateOrganizationUserField(user);
  }

  /**
   * ユーザーの権限変更(管理、編集、閲覧)
   * !WARNING: 管理ユーザーのみこのメソッドを呼び出す予定
   */
  public async updateRole(newRole: OrganizationRole): Promise<boolean> {
    return await this.updateOrganizationUserField({
      "role": newRole
    });
  }

  /**
   * ユーザー招待のキャンセル時に仕様
   * !WARNING: 管理ユーザーのみこのメソッドを呼び出す予定
   */
  public async cancelInvitation(newState: OrganizationUserState): Promise<boolean> {
    return await this.updateOrganizationUserField({
      "state": newState,
      "invitationId": null,
    });
  }

  public updateInvitationId(newInvitationId: string | null) {
    this.invitationId = newInvitationId;
  }
  
  public getFields(): OrganizationUserResponse {
    return {
      id: this.id,
      docId: this.docId,
      email: this.email,
      username: this.username ? this.username : null,
      profileImageUrl: this.profileImageUrl ? this.profileImageUrl : null,
      role: this.role,
      state: this.state,
      teamIds: this.teamIds ? this.teamIds : [],
      invitationId: this.invitationId ? this.invitationId : null,
      emailSentAt: this.emailSentAt ? dateToFirebaseTime(this.emailSentAt) : null,
      joinedAt: this.joinedAt ? dateToFirebaseTime(this.joinedAt) : null,
      removedAt: this.removedAt ? dateToFirebaseTime(this.removedAt) : null,
      declinedAt: this.declinedAt ? dateToFirebaseTime(this.declinedAt) : null,
    } as OrganizationUserResponse;
  }

  public setFields(user: OrganizationUserResponse) {
    this.id = user.id;
    this.docId = user.docId;
    this.email = user.email;
    this.username = user.username;
    this.profileImageUrl = user.profileImageUrl;
    this.role = user.role;
    this.state = user.state;
    this.teamIds = user.teamIds ? user.teamIds : [];
    this.invitationId = user.invitationId;
    this.emailSentAt = user.emailSentAt ? firebaseTimeToDate(user.emailSentAt) : null;
    this.joinedAt = user.joinedAt ? firebaseTimeToDate(user.joinedAt) : null;
    this.removedAt = user.removedAt ? firebaseTimeToDate(user.removedAt) : null;
    this.declinedAt = user.declinedAt ? firebaseTimeToDate(user.declinedAt) : null;
  }

  private async updateOrganizationUserField(updatingFields: Partial<{[key in OrganizationUserDocumentFields]: any }>): Promise<boolean> {
    try {
      if (!this.organizationId) {
        throw new Error("Organization has not been set.");
      }
  
      const documentRef = this.getOrganizationUserDocumentRef();
      await updateDoc(documentRef, updatingFields);
      return true;
    } catch (error) {
      console.log(`[Error] Exception in updateOrganizationUserField: ${error}`);
      // Sentry here
      return false;
    }
  }


  private getOrganizationUserDocumentRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.organizations.this, this.organizationId!, FirestoreCollections.organizations.users, this.docId);
  }
};