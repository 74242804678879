import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classNames from "classnames";
import _ from "lodash";
import { useStore } from "src/conpath/hooks/useStore";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Flip, ToastContainer, toast } from "react-toastify";
import { Tab } from "@headlessui/react";

//mobx
import { observer } from "mobx-react-lite";

//constant
import { Paths } from "src/conpath/constants/Routes";
import Colors from "src/conpath/constants/Colors";
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import { APP_NAME } from "src/excalidraw/constants";

//styles
import "./Projects.scss";

//components
import { firebaseTimeToDate } from "src/utils/timeUtils";
import { MoreIcon, CalenderIcon } from "src/conpath/components/icons";
import { Button } from "src/conpath/components/Button";
import { ProjectRole } from "src/conpath/constants/Role";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineDuplicate } from "react-icons/hi";
import { Tooltip } from "src/excalidraw/components/Tooltip";
import SearchListBox, { SearchListOption } from "../../components/SearchListBox";
import SearchTagsInput from "../../components/SearchTagsInput";
import {
  ProjectFilter,
  ProjectFilterTags,
} from "../../interfaces/ProjectFilter";
import Header from "src/conpath/components/layouts/Header";

const sortingOption = [
  { value: 0, label: "新しい順" },
  { value: 1, label: "古い順" },
];

const statusOptions = [{ value: "archived", label: "アーカイブ済" }];

const Projects: React.FC = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  const [selectedIndex, setSelectedIndex] = useState(loginUser?.projectSortSelected || 0);
  const ref = useRef<HTMLDivElement>(null);

  const [searchText, setSearchText] = useState<string[]>([]);
  const [projectFilter, setProjectFilter] = useState<ProjectFilter>({
    searchText: null,
    searchStatus: "",
    searchTag: null,
  });

  useEffect(() => {
    if (state && state.message) {
      toast.success(state.message, {
        toastId: state.message,
      })
      navigate(window.location, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    const getProjects = async () => {
      if (loginUser) {
        await selectedOrganization?.getOrganizationUsers();
        await selectedOrganization?.getResources();
        await selectedOrganization?.getTeams();
        await selectedOrganization?.getProjects(loginUser);
      }
    };

    getProjects();
  }, [loginUser, selectedOrganization]);

  const tagsOption = useMemo(() => {
    const obj = selectedOrganization?.projects.reduce((acc, value) => {
      acc = {
        ...acc,
        ...value.tags,
      };
      return acc;
    }, {}) || {};
    return (Object.keys(obj).map((tag) => ({ value: tag, label: tag } as SearchListOption)));
  }, [selectedOrganization?.projects]);

  useEffect(() => {
    const _searchText = [];
    if (projectFilter?.searchStatus) {
      _searchText.push(`${ProjectFilterTags.searchStatus}:${statusOptions.find((key) => key.value === projectFilter?.searchStatus)?.label}`);
    }
    if (projectFilter?.searchTag) {
      _searchText.push(`${ProjectFilterTags.searchTag}:${tagsOption.find((key) => key.value === projectFilter?.searchTag)?.label}`);
    }
    if (projectFilter?.searchText) {
      _searchText.push(`${ProjectFilterTags.searchText}:${projectFilter?.searchText}`);
    }

    setSearchText(_searchText);
  }, [projectFilter]);

  const onChangeText = useCallback((tags: string[]) => {
    if (tags.length > searchText.length) {
      const currentValue = projectFilter?.searchText;
      if (currentValue !== tags[tags.length - 1]) {
        setProjectFilter({
          ...projectFilter,
          "searchText": tags[tags.length - 1],
        });
      }
    } else {
      if (
        projectFilter?.searchText &&
        tags.filter((v) => v.includes(ProjectFilterTags.searchText)).length === 0
      ) {
        setProjectFilter({
          ...projectFilter,
          "searchText": null,
        });
      }

      if (
        projectFilter?.searchStatus &&
        tags.filter((v) => v.includes(ProjectFilterTags.searchStatus)).length === 0
      ) {

        setProjectFilter({
          ...projectFilter,
          "searchStatus": null,
        });
      }

      if (
        projectFilter?.searchTag &&
        tags.filter((v) => v.includes(ProjectFilterTags.searchTag)).length === 0
      ) {
        setProjectFilter({
          ...projectFilter,
          "searchTag": null,
        });
      }
    }
  }, [projectFilter, searchText]);

  useEffect(() => {
    if (organizationStore.errorTexts.length) {
      organizationStore.errorTexts.forEach((error) => {
        toast.error(error);
      });
      organizationStore.clearErrorTexts();
    }
  }, [organizationStore.errorTexts]);

  const navigateToProject = (projectId: string) => {
    // navigate(`${Paths.projects}/${projectId}`);

    // プロジェクトを新規タブで開く
    window.open(`${Paths.projects}/${projectId}`, "_blank");
  };

  const onCreateProjectButtonPressed = useCallback(async () => {
    const projectLimitExceeded = await checkProjectLimitExceeded();

    // アクティブなプロジェクトがプランの上限を超えていたら追加できないように
    if (projectLimitExceeded) {
      organizationStore.setErrorTexts([
        "プランの上限を超えているため、プロジェクトをアーカイブするか削除してください。",
      ]);
      return;
    }

    navigate(`${Paths.projects}${Paths.create}`);
  }, [navigate]);

  const onEditButtonPressed = useCallback((projectId: string) => {
    navigate(`${Paths.projects}/${projectId}${Paths.edit}`, {
      state: { projectId },
    });
  },
    [navigate],
  );

  const onDuplicateButtonPressed = useCallback(async (projectId: string) => {
    const projectLimitExceeded = await checkProjectLimitExceeded();

    // アクティブなプロジェクトがプランの上限を超えていたら複製できないように
    if (projectLimitExceeded) {
      organizationStore.setErrorTexts([
        "プランの上限を超えているため、プロジェクトをアーカイブするか削除してください。",
      ]);
      return;
    }

    navigate(`${Paths.projects}/${projectId}${Paths.duplicate}`, {
      state: { projectId },
    });
  },
    [navigate],
  );

  const checkProjectLimitExceeded = (async () => {
    const currentPlan = selectedOrganization?.getCurrentPlan();
    const addProjects = selectedOrganization?.addProjects || 0;
    const activeProjects = await selectedOrganization?.getActiveProjectCount() || 0;

    return (activeProjects >= (currentPlan!.projectLimit + addProjects));
  });

  const handleSelect = useCallback(
    (index: number) => {
      loginUser?.setProjectSortSelected(index);
      setSelectedIndex(index);
    },
    [loginUser]
  );

  // 日付の設定
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // プロジェクトごとにポップアップの状態を管理するためのステートと関数を追加
  const [isPopupOpenForProject, setIsPopupOpenForProject] = useState<{ [projectId: string]: boolean }>({});

  // ポップアップの状態を設定する関数
  const setPopupOpenForProject = (projectId: string, isOpen: boolean) => {
    setIsPopupOpenForProject({ [projectId]: isOpen });
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsPopupOpenForProject({});
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  if (organizationStore.isLoading) {
    return (
      <div className="project-body">
        <ToastContainer
          position="top-center"
          autoClose={TOAST_DEFAULT_DURATION}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="spinner-wrapper">
          <RotatingLines
            strokeColor={Colors.primary}
            strokeWidth="5"
            animationDuration="0.75"
            width="42"
            visible={true}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`プロジェクト - ${APP_NAME}`}</title>
        </Helmet>
        <Header />
        <div className="project-body">
          <ToastContainer
            position="top-center"
            transition={Flip}
            autoClose={TOAST_DEFAULT_DURATION}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <div className="organization-info"></div> */}
          <div className="projects-wrapper">
            <div className="search-header">
              <div className="search-header__title-wrapper">
                <p style={{ fontSize: "22px" }}>
                  プロジェクト一覧
                </p>
              </div>
              <div className="search-header__search-bar-wrapper">
                <div className="search-header__search-bar-wrapper search-bar">
                  <SearchTagsInput
                    tags={searchText}
                    onChangeTags={(newTags) => { onChangeText(newTags) }}
                    className="input w-[400px]"
                  />
                  <SearchListBox
                    title="ステータス"
                    options={statusOptions}
                    selected={projectFilter?.searchStatus || ""}
                    onChange={(selectedValue: string) => {
                      const currentValue = projectFilter?.searchStatus;
                      setProjectFilter({
                        ...projectFilter,
                        "searchStatus": currentValue !== selectedValue ? selectedValue : null,
                      })
                    }}
                    customClass="cursor-pointer border-[1px] border-[#DDDDDD] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                  />
                  <SearchListBox
                    title="タグ"
                    options={tagsOption}
                    selected={projectFilter.searchTag || ""}
                    onChange={(selectedValue: string) => {
                      const currentValue = "";
                      setProjectFilter({
                        ...projectFilter,
                        "searchTag": currentValue !== selectedValue ? selectedValue : null,
                      })
                    }}
                    customClass="cursor-pointer border-[1px] border-[#DDDDDD] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                  />
                </div>
                {(loginUser?.isOrganizationOwner() ||
                  loginUser?.isOrganizationMember()) && (
                    <Button
                      style={{ width: "170px", fontSize: "14px" }}
                      theme="secondary"
                      onClick={onCreateProjectButtonPressed}
                    >
                      + 新規プロジェクト
                    </Button>
                  )}
              </div>
            </div>
            {(selectedOrganization?.projects && selectedOrganization.projects.length > 0) &&
              <div className="sort-select">
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={(index) => handleSelect(index)}
                >
                  <Tab.List className="flex space-x-1 rounded-full border bg-white p-1.5 h-[45px]">
                    {sortingOption.map((unit) => (
                      <Tab
                        key={unit.value}
                        className={({ selected }) =>
                          classNames(
                            'w-full rounded-full text-sm leading-5 align-middle',
                            'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
                            selected
                              ? 'bg-[#E3F2FA]'
                              : 'hover:bg-white'
                          )
                        }
                      >
                        {unit.label}
                      </Tab>
                    ))}
                  </Tab.List>
                </Tab.Group>
              </div>
            }
            <ul className="project-list-wrapper">
              {selectedOrganization?.projects
                .filter((project) => projectFilter.searchText
                  ? (
                    project.name.toLowerCase().includes(projectFilter.searchText.toLowerCase()) ||
                    project.description.toLowerCase().includes(projectFilter.searchText.toLowerCase())
                  )
                  : true)
                .filter((project) => projectFilter.searchTag
                  ? Object.keys(project.tags).some((tag) => tag === projectFilter.searchTag)
                  : true)
                .filter((project) => projectFilter.searchStatus
                  ? projectFilter.searchStatus === "archived"
                    ? project.isArchived === true
                    : true
                  : true)
                .slice()
                .sort((a, b) => {
                  if (!a.isArchived && b.isArchived) {
                    return -1;
                  } else if (a.isArchived && !b.isArchived) {
                    return 1;
                  }
                  if (selectedIndex === 0) {
                    // 開始日が新しい順
                    return b.startDate.seconds - a.startDate.seconds;
                  } else {
                    // 開始日が古い順
                    return a.startDate.seconds - b.startDate.seconds;
                  }
                })
                .map((project) => {
                  const projectStartDate: string = firebaseTimeToDate(project.startDate).toLocaleDateString('ja-JP', dateOptions);
                  const projectEndDate: string = firebaseTimeToDate(project.endDate).toLocaleDateString('ja-JP', dateOptions);
                  const PopupMenu = () => {
                    return (
                      <div className="popup-menu">
                        <div
                          className="popup-menu__item"
                          onClick={(event) => {
                            event.stopPropagation(); // イベントのバブリングを停止
                            onEditButtonPressed(project.id); // ボタンをクリックした時にポップアップメニューを表示・非表示
                          }}
                        >
                          <div className="popup-menu__item__icon">
                            <AiOutlineSetting />
                          </div>
                          <p>プロジェクトの設定</p>
                        </div>
                        <div
                          className="popup-menu__item"
                          onClick={(event) => {
                            event.stopPropagation(); // イベントのバブリングを停止
                            onDuplicateButtonPressed(project.id); // ボタンをクリックした時にポップアップメニューを表示・非表示
                          }}
                        >
                          <div className="popup-menu__item__icon">
                            <HiOutlineDuplicate />
                          </div>
                          <p>プロジェクトの複製</p>
                        </div>
                      </div>
                    );
                  };

                  return (
                    <div
                      className="card-body"
                      key={project.id}
                      onClick={() => navigateToProject(project.id)}
                      ref={ref}
                    >
                      <div className="firstRow">
                        <div className="title-container">
                          <div
                            className={"project-color"}
                            style={{ backgroundColor: project.color }}
                          />
                          <p className="title">{project.name}</p>
                          {project.isArchived &&
                            <div className={"archived"} >
                              <p>アーカイブ済</p>
                            </div>
                          }
                        </div>

                        {(loginUser?.isOrganizationOwner() ||
                          project.getUserRole(loginUser?.id) === ProjectRole.admin ||
                          project.getTeamRole(loginUser?.id, selectedOrganization.teams) === ProjectRole.admin) &&
                          <>
                            <MoreIcon
                              className="icon rotate-90deg"
                              onClick={(event) => {
                                event.stopPropagation(); // イベントのバブリングを停止
                                // プロジェクトごとにポップアップの状態を管理
                                setPopupOpenForProject(project.id, !isPopupOpenForProject[project.id]);
                              }}
                            />
                            {isPopupOpenForProject[project.id] && <PopupMenu />} {/* ポップアップメニューが表示されている場合にコンポーネントを表示 */}
                          </>
                        }
                      </div>
                      <div className="card-section">
                        <div className="description project-title">
                          <p>{project.description}</p>
                        </div>
                        <div className="description">
                          {
                            Object.keys(project.tags).map((tag, i) => {
                              return(
                                <div className={'font-medium rounded flex items-center bg-[#E3F2FA] mr-1'}>
                                  <p className={`py-1 px-1 text-sm whitespace-nowrap`}>{tag}</p>
                                </div>
                              );
                            })
                          }
                        </div>
                        <div className="description">
                          <div className="description calendar">
                            <CalenderIcon />
                            <p>{`${projectStartDate} - ${projectEndDate}`}</p>
                          </div>
                          <p>{Object.keys(project.roles).length}人のメンバー</p>
                          <div className="description__profile-image-wrapper">
                            {
                              Object.keys(project.roles)
                                .filter((userId) => userId !== loginUser!.id)
                                .slice(0, 5)
                                .map((userId) => {
                                  const user = selectedOrganization?.findUserById(userId);

                                  return (
                                    <Tooltip key={userId} label={user?.username || ""}>
                                      {user && user.profileImageUrl
                                        ? <img src={user.profileImageUrl} />
                                        : <div>
                                          {user ? user.username.charAt(0) : ""}
                                        </div>
                                      }
                                    </Tooltip>
                                  )
                                })
                            }
                          </div>
                          <p>{Object.keys(project.teams).length}チーム</p>
                          <div className="description__profile-image-wrapper">
                            {
                              Object.keys(project.teams)
                                .slice(0, 5)
                                .map((teamId) => {
                                  const team = selectedOrganization.findTeamById(teamId);

                                  return (
                                    <Tooltip label={team?.name || ""}>
                                      <div>
                                        {team ? team.name.charAt(0) : ""}
                                      </div>
                                    </Tooltip>
                                  )
                                })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </ul>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
});

export default Projects;
