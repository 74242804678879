import { SignInForm, SignUpForm } from "src/conpath/interfaces/AuthForm";
import { isEmailValid } from "./validations";
import _ from "lodash";

export default class UserStoreValidation {
  public validateSignInForm(form: SignInForm): string | null {
    if (_.isEmpty(form.email.trim()) || _.isEmpty(form.password.trim())) {
      return "入力していない項目があります"
    }

    // Firestore上、 Authに渡したメールアドレスが、メールアドレスの形をしていない場合、`[firebase_auth/invalid-email] The email address is badly formatted.`というエラーが返るので、それを処理する関数。
    if (!isEmailValid(form.email.trim())) {
      return "有効なメールアドレスを入力してください"
    }

    return null;
  }

  public validateSignUpForm(form: SignUpForm): string | null {
    if (_.isEmpty(form.email.trim()) || _.isEmpty(form.password.trim()) || _.isEmpty(form.username.trim())) {
      return "入力していない項目があります"
    }
    
    if (!isEmailValid(form.email.trim())) {
      return "有効なメールアドレスを入力してください"
    }

    return null;
  }

  public isEmailValid(email: string): boolean {
    return isEmailValid(email.trim());
  }
}
