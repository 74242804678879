import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import Context from '../../context';
import './index.scss';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Gantt } from '../..'

const TaskBarLabelSelect: React.FC = () => {
  const { store, prefixCls } = useContext(Context);
  const { TaskBarTypeList, tableWidth } = store;
  const handleSelect = useCallback(
    (type: Gantt.TaskBarType) => {
      store.switchTaskBarLabel(type);
    },
    [store]
  );

  return (
    <div className={`${prefixCls}-task-bar-label-select`} style={{ left: tableWidth + 260 }}>
      <Listbox value={TaskBarTypeList.find((t) => t.selected)?.type} onChange={(value) => handleSelect(value)}>
        <div className="flex space-x-1 rounded-full border bg-white p-1.5 h-[45px]">
          <Listbox.Button
            className={({ open }) =>
              classNames(
                'flex items-center justify-center w-full rounded-full',
                'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
                open
                  ? 'bg-[#E3F2FA] shadow'
                  : 'hover:bg-white'
              )
            }>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
              <path d="M18.362,22.847H3.436v-5.5H25.375v1.107" transform="translate(-1.406 -7.096)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
              <rect width="10.969" height="5.502" transform="translate(2.031 10.249)" stroke="#000" strokeWidth="1.2" />
              <path d="M33.41,25.214,34.8,26.689l2.955-2.949" transform="translate(-13.668 -9.712)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            </svg>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute bottom-2 h-[158px] w-[249px] flex items-center mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              <div>
                {TaskBarTypeList.map((option, optionIdx) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-[#E3F2FA]' : ''
                      }`
                    }
                    value={option.type}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {option.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#46AADF]">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>

            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default observer(TaskBarLabelSelect);
