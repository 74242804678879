import { action, observable, runInAction, makeObservable } from "mobx";
import _ from "lodash";

//types and interface
import Checklist from "../interfaces/Checklist";

//constants
import { DocumentUpdateState } from "src/conpath/constants/General";

//firebase
import LoginUserModel from "./LoginUserModel";
import dayjs from "dayjs";

export default class ChecklistModel implements Checklist {

  @observable
  id: string;
  @observable
  title: string;
  @observable
  url: string;
  @observable
  isChecked: boolean;
  @observable
  checkedBy: string | null;
  @observable
  checkedAt: Date | null;
  @observable
  createdBy: string;
  @observable
  createdAt: Date | null;

  @observable
  existsInFirestore: boolean = false;
  
  @observable
  documentState: DocumentUpdateState = DocumentUpdateState.NONE;

  constructor(checklist: Checklist) {
    this.id = checklist.id;
    this.title = checklist.title;
    this.url = checklist.url;
    this.isChecked = checklist.isChecked;
    this.checkedBy = checklist.checkedBy;
    this.checkedAt = checklist.checkedAt;
    this.createdBy = checklist.createdBy;
    this.createdAt = checklist.createdAt;

    makeObservable(this);
  };

  //public
  public getFields(): Checklist {
    return {
      id: this.id,
      title: this.title,
      url: this.url,
      isChecked: this.isChecked,
      checkedBy: this.checkedBy,
      checkedAt: this.checkedAt,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    };
  }

  @action
  public setExistInFirestore() {
    runInAction(() => {
      this.existsInFirestore = true;
    })
  }

  @action
  public toggleCheckState(user: LoginUserModel) {
    runInAction(() => {
      this.checkedBy = this.isChecked ? null : user.id;
      this.checkedAt = this.isChecked ? null : dayjs().toDate();
      this.isChecked = !this.isChecked;
      this.documentState = DocumentUpdateState.SAVE;
    });
  }

  @action
  public changeTitle(newTitle: string) {
    runInAction(() => {
      this.title = newTitle;
      this.documentState = DocumentUpdateState.SAVE;
    });
  }

  @action
  public changeDocumentState(state: DocumentUpdateState) {
    runInAction(() => {
      this.documentState = state;
    })
  }

  @action
  public changeUrl(newUrl: string) {
    runInAction(() => {
      this.url = newUrl;
      this.documentState = DocumentUpdateState.SAVE;
    });
  }
  //private

};

