import { Timestamp } from "firebase/firestore";

export const FileType = {
  "image/png": "image/png",
  "image/jpeg": "image/jpeg",
  "application/pdf": "application/pdf"
} as const;
export type FileType = typeof FileType[keyof typeof FileType];

export interface UploadedFile {
  fileName: string;
  displayName: string;
  fileSizeInMB: number;
  fileType: FileType;
  hasUploaded: boolean;
};

export interface UploadedFileResponse extends Omit<UploadedFile, "hasUploaded">{};

export default interface Comment {
  id: string;
  projectId: string;
  commentElementId: string;
  rootCommentId: string | null;
  text: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date | null;
  mentioningTo: string[];
  uploadedFiles: UploadedFile[];
  isDeleted: boolean;
  isDeleteElementRequired: boolean;
  replayCount: number;
  lastReplayedAt: Date | null;
};

export interface CommentResponse extends Omit<Comment, "createdAt"|"updatedAt"|"lastReplayedAt"|"uploadedFiles"> {
  createdAt: Timestamp;
  updatedAt: Timestamp | null;
  lastReplayedAt: Timestamp | null;
  uploadedFiles: UploadedFileResponse[];
};

export const CommentDocumentFields = {
  id: "id",
  projectId: "projectId",
  commentElementId: "commentElementId",
  rootCommentId: "rootCommentId",
  text: "text",
  createdBy: "createdBy",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
  mentioningTo: "mentioningTo",
  uploadedFiles: "uploadedFiles",
  isDeleted: "isDeleted",
  isDeleteElementRequired: "isDeleteElementRequired",
  replayCount: "replayCount",
  lastReplayedAt: "lastReplayedAt"
} as const;

export type CommentDocumentFields = typeof CommentDocumentFields[keyof typeof CommentDocumentFields];
