import { centerScrollOn } from "src/excalidraw/scene/scroll";
import { AppState } from "src/excalidraw/types";
import Calendar from "../calendar";

// CHANGED:UPDATED 2022-12-11 #249
class Scroll {
  limitX: number;
  limitY: number;
  constructor({ limitX, limitY }: { limitX: number; limitY: number }) {
    this.limitX = limitX;
    this.limitY = limitY;
  }

  public updateScrollLimit = ({
    newScrollLimitX,
    newScrollLimitY,
  }: {
    newScrollLimitX: number;
    newScrollLimitY: number;
  }) => {
    this.limitX = newScrollLimitX;
    this.limitY = newScrollLimitY;
  };

  public getOffsetScrollX = (scrollX: number): number => {
    return Math.min(0, Math.max(-this.limitX, scrollX));
  };

  public getOffsetScrollY = (scrollY: number): number => {
    return Math.min(0, Math.max(-this.limitY, scrollY));
  };

  // CHANGED:ADD 2023-2-24 #744
  static calculateScrollCenterEx = (
    appState: AppState,
    targetX?: number,
    targetY?: number,
  ): { scrollX: number; scrollY: number, todayScrollX: number } => {
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);

    const calendar = new Calendar(
      appState.gridSize,
      appState.projectStartDate,
      appState.holidays,
    );

    if (targetX) {
      const x = targetX;
      const y = targetY || 0;
      const { scrollX, scrollY } = centerScrollOn({
        scenePoint: { x, y },
        viewportDimensions: { width: appState.width, height: appState.height },
        zoom: appState.zoom,
        appState,
      });

      const todayX = calendar.getDatePoint(currentDate);
      const { scrollX: todayScrollX } = centerScrollOn({
        scenePoint: { x: todayX, y },
        viewportDimensions: { width: appState.width, height: appState.height },
        zoom: appState.zoom,
        appState,
      });

      return { scrollX, scrollY, todayScrollX };
    } else {
      const x = calendar.getDatePoint(currentDate);
      const { scrollX, scrollY } = centerScrollOn({
        scenePoint: { x, y: 0 },
        viewportDimensions: { width: appState.width, height: appState.height },
        zoom: appState.zoom,
        appState,
      });

      return { scrollX, scrollY: appState.scrollY, todayScrollX: scrollX };
    }
  };
}

export default Scroll;
