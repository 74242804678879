import { ProjectInputForm } from "src/conpath/interfaces/Project";
import _ from "lodash";
import dayjs from "dayjs";

export default class ProjectValidation {

  extraDuration: number = 1 * 24 * 60 * 60;

  private presenceCheckFailed(form: ProjectInputForm): boolean {
    return (_.isEmpty(form.name.trim()))
  }

  private durationCheckFailed(form: ProjectInputForm): boolean {
    if (!form.startDate || !form.endDate) return true;
    return (dayjs(form.startDate).add(1, 'day') >= dayjs(form.endDate)); // 工事期間は１日以上
  }

  private usersCheckFailed(form: ProjectInputForm): boolean {
    return (_.isEmpty(form.roles))
  }

  public validateProject(form: ProjectInputForm): boolean {
    
    if (this.presenceCheckFailed(form)) return false;
    if (this.durationCheckFailed(form)) return false;
    // if (this.usersCheckFailed(form)) return false;
    
    return true;
  }
}