
export const GeneralDocumentQueryErrorType = {
  DocumentEmpty: "DocumentEmpty",
  NetworkingError: "NetworkingError"
} as const;

export type GeneralDocumentQueryErrorType = typeof GeneralDocumentQueryErrorType[keyof typeof GeneralDocumentQueryErrorType];

export const GeneralDocumentUpdateErrorType = {
  General: "General"
} as const;

export type GeneralDocumentUpdateErrorType = typeof GeneralDocumentUpdateErrorType[keyof typeof GeneralDocumentUpdateErrorType];

