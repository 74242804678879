import { GanttLocale } from "../Gantt";

export const jaJP: GanttLocale = Object.freeze({
  today: "今日",
  day: "日",
  days: "日",
  week: "週",
  month: "月",
  quarter: "四半期",
  halfYear: "半年",
  firstHalf: "上半期",
  secondHalf: "下半期",
  majorFormat: {
    day: "YYYY年MM月",
    week: "YYYY年MM月",
    month: "YYYY年",
    quarter: "YYYY年",
    halfYear: "YYYY年",
  },
  minorFormat: {
    day: "YYYY-MM-D",
    week: "YYYY-D",
    month: "YYYY-M月",
    quarter: "YYYY-Q",
    halfYear: "YYYY-",
  }
});