import { OrganizationRole, ProjectRole } from "src/conpath/constants/Role";
import { OrganizationUserState } from "src/conpath/constants/OrganizationUserState";
import { Timestamp } from "firebase/firestore";

export default interface User {
  id: string;
  docId: string;
  email: string;
  username: string;
  profileImageUrl: string | null;
  role: OrganizationRole;
  state: OrganizationUserState;
  teamIds: string[] | null;
  invitationId: string | null;
  emailSentAt: Date | null; //UTC
  joinedAt: Date | null; // UTC
  removedAt: Date | null; // UTC
  declinedAt: Date | null; // UTC
}

export interface OrganizationUserResponse extends Omit<User, "emailSentAt" | "joinedAt" | "removedAt" | "declinedAt"> {
  emailSentAt: Timestamp | null;
  joinedAt: Timestamp | null;
  removedAt: Timestamp | null;
  declinedAt: Timestamp | null;
}

export const OrganizationUserDocumentFields = {
  id: "id",
  docId: "docId",
  email: "email",
  username: "username",
  role: "role",
  state: "state",
  invitationId: "invitationId",
  emailSentAt: "emailSentAt",
  joinedAt: "joinedAt",
  removedAt: "removedAt",
  declinedAt: "declinedAt",
} as const;

export type OrganizationUserDocumentFields = typeof OrganizationUserDocumentFields[keyof typeof OrganizationUserDocumentFields];

export interface CreateOrganizationUsersRequest extends Pick<User, "email" | "role"> {
  teamIds: string[];
};
export interface UpdateOrganizationUserForm extends Pick<User, "username" | "role"> { 
  
};

export interface ProjectUserRoles {
  [userId: string]: ProjectRole;
}