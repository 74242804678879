import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
// import { nanoid } from 'nanoid';

import { dateToFirebaseTime } from "src/utils/timeUtils";
import { RotatingLines } from "react-loader-spinner";
import ProjectForm from "src/conpath/components/projects/ProjectForm";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import { useNavigate } from "react-router-dom";

//styles
import "./CreateProject.scss";
import Colors, { DEFAULT_PROJECT_COLOR } from "src/conpath/constants/Colors";
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";
import { ProjectInputForm } from "src/conpath/interfaces/Project";
import { Paths } from "src/conpath/constants/Routes";
import { ProjectRole } from "src/conpath/constants/Role";

//utils
import { FirebaseStorage } from 'src/conpath/constants/FirebaseStorage';
import { CreateProjectRequestError } from 'src/conpath/constants/errors/OrganizationRequestErrors';
import { TOAST_DEFAULT_DURATION } from 'src/conpath/constants/Toast';
import { Tags } from "../../interfaces/Tag";

const CreateProject: React.FC = observer(() => {
  const navigate = useNavigate();
  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  startDate.setDate(1);
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + 1);
  endDate.setDate(0);

  const [project, setProject] = useState<ProjectInputForm>({
    id: "",
    name: "",
    color: DEFAULT_PROJECT_COLOR,
    address: "",
    description: "",
    tags: {},
    startDate,
    endDate,
    holidays: [],
    jobsHeight: 0,
    backgroundColor: ColorsEx.backgroundColor.white,
    roles: {},
    teams: {},
    resources: {},
    isArchived: false,
    archivedAt: null,
  });
  const [errorText, setErrorText] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (loginUser) {
      // const userRef = createUserRef(userStore.user!.id);
      setProject((prev) => ({
        ...prev,
        roles:
        {
          [loginUser.id]: ProjectRole.admin,
        },
      }));
    }
  }, [loginUser]);

  const onChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      if (name) {
        setProject((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setErrorText("");
    },
    [setProject],
  );

  const onChangeDate = useCallback(
    (startDate: Date, endDate: Date | null) => {
      setProject((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
      setErrorText("");
    },
    [setProject],
  );

  const onChangeAssignedUsers = useCallback(
    (roles: any) => {
      setProject((prev) => ({
        ...prev,
        roles: roles,
      }));
      setErrorText("");
    },
    [project.roles],
  );

  const onChangeAssignedTeams = useCallback(
    (teams: any) => {
      setProject((prev) => ({
        ...prev,
        teams: teams,
      }));
      setErrorText("");
    },
    [project.teams],
  );

  const onChangeAssignedResources = useCallback(
    (resources: any) => {
      setProject((prev) => ({
        ...prev,
        resources: resources,
      }));
      setErrorText("");
    },
    [project.resources],
  );

  const onChangeColor = useCallback(
    (value: string) => {
      setProject((prev) => ({
        ...prev,
        color: value,
      }));
      setErrorText("");
    },
    [setProject],
  );

  const onChangeTags = useCallback(
    (value: Tags) => {
      setProject((prev) => ({
        ...prev,
        tags: value,
      }));
      setErrorText("");
    },
    [setProject],
  );

  const onCancel = useCallback(() => {
    navigate(Paths.projects, { replace: true });
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;
    setIsProcessing(true);
    const result = await selectedOrganization.createProject(project, loginUser, selectedOrganization);
    if (result.error) {
      setErrorText(result.error);
      setIsProcessing(false);
      if (result.error === CreateProjectRequestError.UserRemovedFromOrganization) {
        setTimeout(async () => {
          await userStore.signOut();
        }, TOAST_DEFAULT_DURATION + 1000);
      }
    } else {
      const prefix = `${FirebaseStorage.projects}/${selectedOrganization?.id}`;
      result?.project?.saveElementsToStorage(prefix, []);
      setIsProcessing(false);
      navigate(Paths.projects, { state: { message: "プロジェクトの作成に成功しました!" } });
    }
  }, [project, navigate, setErrorText, selectedOrganization, loginUser]);

  return (
    <div className="project-create-container">
      {selectedOrganization ? (
        <div className="inner-container">
          <ProjectForm
            isCreating={true}
            isDuplicate={false}
            isProcessing={isProcessing}
            projectState={project}
            onChangeProjectState={onChangeState}
            errorText={errorText}
            setErrorText={setErrorText}
            onChangeDuration={onChangeDate}
            organization={selectedOrganization}
            onChangeAssignedUsers={onChangeAssignedUsers}
            onChangeAssignedTeams={onChangeAssignedTeams}
            onChangeAssignedResources={onChangeAssignedResources}
            onChangeColor={onChangeColor}
            onChangeTags={onChangeTags}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </div>
      ) : (
        <div className="spinner-wrapper">
          <RotatingLines
            strokeColor={Colors.primary}
            strokeWidth="5"
            animationDuration="0.75"
            width="42"
            visible={true}
          />
        </div>
      )}
    </div>
  );
});

export default CreateProject;
