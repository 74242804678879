import { Timestamp } from "firebase/firestore";

export default interface Milestone {
  id: string;
  text: string;
  date: Date;
  projectId: string;
  prevDependencies: readonly string[] | null;
  nextDependencies: readonly string[] | null;
  isClosed: boolean;
  isDeleted: boolean;
  created: number;
  createdBy: string;
  updated: number;
  updatedBy: string;
};

export interface MilestoneResponse extends Omit<Milestone, "date"> {
  date: Timestamp;
};

export interface GanttChartMilestone extends Milestone {
  isTask: boolean;
  ratio: number;
};

export const MilestoneDocumentFields = {
  id: "id",
  text: "text",
  date: "date",
  organizationId: "organizationId",
  projectId: "projectId",
  prevDependencies: "prevDependencies",
  nextDependencies: "nextDependencies",
  isClosed: "isClosed",
  isDeleted: "isDeleted",
  created: "created",
  createdBy: "createdBy",
  updated: "updated",
  updatedBy: "updatedBy",
} as const;

export type MilestoneDocumentFields = typeof MilestoneDocumentFields[keyof typeof MilestoneDocumentFields];
