import React, { useMemo } from "react";
import { BsExclamationLg } from "react-icons/bs";

const DelayIcon = ({
  width,
  thickness,
  color,
  scale = 1.5,
  onClick,
}: {
  width: number;
  thickness: number;
  color: string;
  scale?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement> | undefined) => void;
}) => {
  const icon = useMemo(
    () => (
      <div
        style={{
          position: "absolute",
          transform: "translateX(-16px)",
          zIndex: 1,
        }}
        onClick={onClick}
      >
        <BsExclamationLg
          fill="white"
          style={{
            width,
            height: thickness,
            background: color,
            borderRadius: "100%",
            transformOrigin: "center",
            transform: `scale(${scale})`,
          }}
        />
      </div>
    ),
    [width, thickness, color, scale],
  );

  return icon;
};

export default DelayIcon;
