import oc from "open-color";
import { STROKE_COLORS, BACKGROUND_COLORS } from "./constants";

const shades = (index: number) => [
  oc.red[index],
  oc.pink[index],
  oc.grape[index],
  oc.violet[index],
  oc.indigo[index],
  oc.blue[index],
  oc.cyan[index],
  oc.teal[index],
  oc.green[index],
  oc.lime[index],
  oc.yellow[index],
  oc.orange[index],
];

// CHANGED:AUPDATE 2023/09/11 #1042
export default {
  canvasBackground: [oc.white, oc.gray[0], oc.gray[1], ...shades(0)],
  elementBackground: ["transparent", ...BACKGROUND_COLORS],
  elementStroke: [oc.black, ...STROKE_COLORS],
};
