//models

//interface
import { ProjectUserRoles } from "./User";
import { ProjectTeamRoles } from "./Team";
import { Timestamp } from "firebase/firestore";
import { Range } from './FirebaseTimestamp';
import { ProjectResources } from "./Resource";
import { Tags } from "./Tag";

export default interface Project {
  id: string;
  name: string;
  color: string;
  address: string;
  description: string;
  tags: Tags;
  startDate: Timestamp;
  endDate: Timestamp;
  sceneVersion: number;
  holidays: string[];
  jobsHeight: number;
  backgroundColor: string;
  roles: ProjectUserRoles;
  teams: ProjectTeamRoles;
  resources: ProjectResources;
  isArchived: boolean;
  archivedAt: Timestamp | null;
  updatedAt: Timestamp;
}

export interface ProjectResponse extends Omit<Project, ""> {
}

export interface ProjectInputForm extends Omit<ProjectResponse,
  | "startDate"
  | "endDate"
  | "sceneVersion"
  | "archivedAt"
  | "updatedAt"
> {
  startDate: Date | null;
  endDate: Date | null;
  archivedAt: Date | null;
}

export const ProjectFields = {
  id: "id",
  name: "name",
  color: "color",
  description: "description",
  tags: "tags",
  startDate: "startDate",
  endDate: "endDate",
  roles: "roles",
  teams: "teams",
  resources: "resources",
  isArchived: "isArchived",
  archivedAt: "archivedAt",
} as const;

export interface ProjectSearchField {
  name?: string;
  startDate?: Range;
  endDate?: Range;
}


export interface ElementInfoValue {
  resources: string[];
  assignedUsernames: string[];
  lastRetrievedAt: Date;
};