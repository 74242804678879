import { register } from "src/excalidraw/actions/register";
import { KEYS } from "../../keys";

export const actionLayerSettings = register({
  name: "toggleLayerSettings",
  viewMode: true,
  trackEvent: { category: "menu", action: "toggleLayerSettings" },
  perform: (_elements, appState, _, { focusContainer }) => {
    if (appState.openDialog === "layerSettings") {
      focusContainer();
    }
    return {
      appState: {
        ...appState,
        openDialog: appState.openDialog === "layerSettings" ? null : "layerSettings",
      },
      commitToHistory: false,
    };
  },
  keyTest: (event) => event.key === KEYS.L,
});
