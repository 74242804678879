import rough from "roughjs/bin/rough";
import { NonDeletedExcalidrawElement, NonDeletedSceneElementsMap } from "../element/types";
import { Bounds, getCommonBounds } from "../element/bounds";
import { renderSceneToSvg, renderStaticScene } from "../renderer/renderScene";
import { arrayToMap, distance, toBrandedType } from "../utils";
import { AppState, BinaryFiles } from "../types";
import { CANVAS_HEADER_HEIGHT, DEFAULT_EXPORT_PADDING, GRID_SIZE, JOB_ELEMENTS_WIDTH, SVG_NS, THEME_FILTER } from "../constants";
import { getDefaultAppState } from "../appState";
import { serializeAsJSON } from "src/excalidraw/data/json";
import {
  getInitializedImageElements,
  updateImageCache,
} from "../element/image";
import * as htmlToImage from 'html-to-image';
import { RenderableElementsMap } from "./types";
import { CriticalPathColor } from "src/conpath/constants/Colors";
import { Renderer } from "./Renderer";
import Scene from "./Scene";

export const SVG_EXPORT_TAG = `<!-- svg-source:excalidraw -->`;

export const exportToCanvas = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    backgroundHTML = true,
    scrollX = 0,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    backgroundHTML?: boolean;
    scrollX?: number;
  },
  createCanvas: (
    width: number,
    height: number,
  ) => { canvas: HTMLCanvasElement; scale: number } = (width, height) => {
    const canvas = document.createElement("canvas");
    canvas.width = width * appState.exportScale;
    canvas.height = height * appState.exportScale;
    return { canvas, scale: appState.exportScale };
  },
  scene?: Scene,
) => {
  const [minX, minY, width, height] = getCanvasSize(elements, exportPadding);
  const renderHeight = backgroundHTML ? CANVAS_HEADER_HEIGHT : 0

  const { canvas, scale = 1 } = createCanvas(
    width,
    height + renderHeight,
  );

  const defaultAppState = getDefaultAppState();

  const { imageCache } = await updateImageCache({
    imageCache: new Map(),
    fileIds: getInitializedImageElements(elements).map(
      (element) => element.fileId,
    ),
    files,
  });
  const gridSize = (appState.gridSize || GRID_SIZE);
  const dateLength = Math.trunc((canvas.width - 10 - JOB_ELEMENTS_WIDTH) / gridSize);
  const startX = Math.abs(scrollX) / gridSize;

  if (backgroundHTML) {
    // 背景を取得する処理
    const bgHeaderImage = new Image();
    const bgContentImage = new Image();
    const fixedImage = new Image();
    const bgHeaderHTML = document.getElementById("bg-calendar__header");
    const bgContentHTML = document.getElementById("bg-calendar__content");
    const fixedHTML = document.getElementById("fixed__wrapper");

    // エクスポート用に配置と倍率を調整
    const cloneHeaderHTML = bgHeaderHTML?.cloneNode(true) as HTMLElement;
    cloneHeaderHTML.id = ``;
    cloneHeaderHTML.style.opacity = "0";
    cloneHeaderHTML.style.top = `0px`;
    cloneHeaderHTML.style.left = `0px`;
    cloneHeaderHTML.style.transform = `scale(1)`
    cloneHeaderHTML.style.zIndex = `-2`;
    cloneHeaderHTML.className += ` export`;
    // CHANGED:ADD 2024-05-09 #1992
    // 必要範囲のみを抽出
    const headerDates = cloneHeaderHTML.children[0]?.querySelectorAll(
      ".bg-calendar__dates__text",
    );
    // 範囲外の日付は削除
    headerDates.forEach((date, i) => {
      if(startX > i || i >= startX + dateLength) {
        date.remove()
      };
    });
    // 月も削除
    const headerMonths = cloneHeaderHTML.children[0]?.querySelectorAll(
      ".bg-calendar__month",
    );
    headerMonths.forEach((month, i) => {
      const hasDates = month.querySelectorAll(".bg-calendar__dates__text");
      if(hasDates.length === 0) {
        month.remove();
      }
    })

    const cloneContentHTML = bgContentHTML?.cloneNode(true) as HTMLElement;
    cloneContentHTML.id = ``;
    cloneContentHTML.style.opacity = "0";
    cloneContentHTML.style.top = `0px`;
    cloneContentHTML.style.left = `0px`;
    cloneContentHTML.style.transform = `scale(1)`;
    cloneContentHTML.style.zIndex = `-2`;
    cloneContentHTML.className += ` export`;
    // CHANGED:ADD 2024-05-09 #1992
    // 必要範囲のみを抽出
    const contentChildren = cloneContentHTML.children[0].querySelectorAll(
      ".bg-calendar__content__rect",
    );
    if (contentChildren) {
      const children = Array.from(contentChildren).filter(
        (_, i) => startX <= i && i < startX + dateLength,
      );
      children.forEach((child) => {
        if (child instanceof HTMLElement) {
          child.style.borderColor = "#686868";
        }
      });
      cloneContentHTML.children[0].replaceChildren(...children);
    }
    if (cloneContentHTML.style.backgroundColor === "transparent") {
      cloneContentHTML.style.backgroundColor = "#FFFFFF";
    }

    const cloneFixedHTML = fixedHTML?.cloneNode(true) as HTMLElement;
    cloneFixedHTML.id = ``;
    cloneFixedHTML.style.opacity = "0";
    cloneFixedHTML.style.transform = `scale(1)`;
    cloneFixedHTML.style.zIndex = `-2`;
    cloneFixedHTML.style.backgroundColor = `#FFFFFF`;
    cloneFixedHTML.className += ` export`;
    const titleElement = document.createElement("div");
    titleElement.className = "fixed__title";
    titleElement.textContent = document.title;
    const insertTarget = cloneFixedHTML.querySelector(".fixed__date__wrapper");
    cloneFixedHTML.childNodes[0].insertBefore(titleElement, insertTarget);

    document.body.appendChild(cloneHeaderHTML);
    document.body.appendChild(cloneContentHTML);
    document.body.appendChild(cloneFixedHTML);

    // html-to-png
    const headerDataUrl = await htmlToImage.toPng(cloneHeaderHTML, {
      style: { opacity: "1" },
    });
    bgHeaderImage.src = headerDataUrl;
    const contentDataUrl = await htmlToImage.toPng(cloneContentHTML, {
      style: { opacity: "1" },
    });
    bgContentImage.src = contentDataUrl;
    const filterFixed = (node: HTMLElement) => {
      const exclusionClasses = ["export_rm"];
      return !exclusionClasses.some((classname) => {
        return node.classList?.contains(classname);
      });
    };
    const fixedDataUrl = await htmlToImage.toPng(cloneFixedHTML, {
      filter: filterFixed,
      style: { opacity: "1" },
    });
    fixedImage.src = fixedDataUrl;

    // png-to-canvas
    const ctx = canvas.getContext("2d");
    const x = (JOB_ELEMENTS_WIDTH + exportPadding) * scale;
    const w = (width - JOB_ELEMENTS_WIDTH - exportPadding * 2) * scale;
    const hHeader = CANVAS_HEADER_HEIGHT * scale;
    const hContent = (height - exportPadding * 2) * scale;
    const yHeader = exportPadding * scale;
    const yContent = (CANVAS_HEADER_HEIGHT + exportPadding) * scale;

    const xFixed = exportPadding * scale;
    const yFixed = exportPadding * scale;
    const wFixed = JOB_ELEMENTS_WIDTH * scale;
    const hFixed = CANVAS_HEADER_HEIGHT * scale;

    await ctx?.drawImage(bgHeaderImage, x, yHeader, cloneHeaderHTML.clientWidth, cloneHeaderHTML.clientHeight);
    await ctx?.drawImage(bgContentImage, x, yContent, cloneContentHTML.clientWidth, cloneContentHTML.clientHeight);
    await ctx?.drawImage(fixedImage, xFixed, yFixed, wFixed, hFixed);

    cloneHeaderHTML.parentNode?.removeChild(cloneHeaderHTML);
    cloneContentHTML.parentNode?.removeChild(cloneContentHTML);
    cloneFixedHTML.parentNode?.removeChild(cloneFixedHTML);
  }

  if (scene) {
    const _scrollX = -minX + exportPadding + scrollX;
    const _scrollY = -minY + exportPadding + renderHeight;

    const renderer = new Renderer(scene);
    const versionNonce = scene.getVersionNonce();
    const { elementsMap, visibleElements } =
      renderer.getRenderableElements({
        versionNonce,
        zoom: defaultAppState.zoom,
        offsetLeft: appState.offsetLeft,
        offsetTop: appState.offsetTop,
        scrollX: _scrollX,
        scrollY: _scrollY,
        height: canvas.height,
        width: canvas.width,
        editingElement: null,
        pendingImageElementId: null,
        viewLayers: appState.viewLayers, // CHANGED:ADD 2024-10-5 #2114
      });

    const allElementsMap = scene.getNonDeletedElementsMap();

    renderStaticScene({
      canvas,
      rc: rough.canvas(canvas),
      elementsMap,
      allElementsMap,
      visibleElements,
      scale,
      appState: {
        ...appState,
        viewBackgroundColor: exportBackground ? viewBackgroundColor : null,
        scrollX: _scrollX,
        scrollY: _scrollY,
        zoom: defaultAppState.zoom,
        shouldCacheIgnoreZoom: false,
        theme: appState.exportWithDarkMode ? "dark" : "light",
      },
      renderConfig: {
        canvasBackgroundColor: viewBackgroundColor,
        imageCache,
        renderGrid: false,
        isExporting: true,
        criticalPathModeEnabled: appState.criticalPathModeEnabled,
        overdueTaskModeEnabled: appState.overdueTaskModeEnabled,
        criticalPathColor: appState.criticalPathColor,
        gridSize: appState.gridSize,
        holidays: appState.holidays,
      },
    });
  } else {
    renderStaticScene({
      canvas,
      rc: rough.canvas(canvas),
      elementsMap: toBrandedType<RenderableElementsMap>(arrayToMap(elements)),
      allElementsMap: toBrandedType<NonDeletedSceneElementsMap>(
        arrayToMap(elements),
      ),
      visibleElements: elements,
      scale,
      appState: {
        ...appState,
        viewBackgroundColor: exportBackground ? viewBackgroundColor : null,
        scrollX: -minX + exportPadding + scrollX,
        scrollY:
          -minY + exportPadding + renderHeight,
        zoom: defaultAppState.zoom,
        shouldCacheIgnoreZoom: false,
        theme: appState.exportWithDarkMode ? "dark" : "light",
      },
      renderConfig: {
        canvasBackgroundColor: viewBackgroundColor,
        imageCache,
        renderGrid: false,
        isExporting: true,
        criticalPathModeEnabled: appState.criticalPathModeEnabled,
        overdueTaskModeEnabled: appState.overdueTaskModeEnabled,
        criticalPathColor: appState.criticalPathColor,
        gridSize: appState.gridSize,
        holidays: appState.holidays,
      },
    });
  }

  return canvas;
};

export const exportToSvg = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: {
    exportBackground: boolean;
    exportPadding?: number;
    exportScale?: number;
    viewBackgroundColor: string;
    exportWithDarkMode?: boolean;
    exportEmbedScene?: boolean;
    criticalPathModeEnabled?: boolean;
    overdueTaskModeEnabled?: boolean;
    criticalPathColor?: CriticalPathColor;
    gridSize?: number | null;
    holidays?: string[];
  },
  files: BinaryFiles | null,
  opts?: {
    serializeAsJSON?: () => string;
  },
): Promise<SVGSVGElement> => {
  const {
    exportPadding = DEFAULT_EXPORT_PADDING,
    exportWithDarkMode = false,
    viewBackgroundColor,
    exportScale = 1,
    exportEmbedScene,
    criticalPathModeEnabled = true,
    overdueTaskModeEnabled = true,
    criticalPathColor = CriticalPathColor.primary,
    gridSize = GRID_SIZE,
    holidays = [],
  } = appState;
  let metadata = "";
  
  if (exportEmbedScene) {
    try {
      metadata = await (
        await import(/* webpackChunkName: "image" */ "src/excalidraw/data/image")
      ).encodeSvgMetadata({
        text: opts?.serializeAsJSON
          ? opts?.serializeAsJSON?.()
          : serializeAsJSON(elements, appState, files || {}, "local"),
      });
    } catch (error: any) {
      console.error(error);
    }
  }
  const [minX, minY, width, height] = getCanvasSize(elements, exportPadding);

  // initialize SVG root
  const svgRoot = document.createElementNS(SVG_NS, "svg");
  svgRoot.setAttribute("version", "1.1");
  svgRoot.setAttribute("xmlns", SVG_NS);
  svgRoot.setAttribute("viewBox", `0 0 ${width} ${height}`);
  svgRoot.setAttribute("width", `${width * exportScale}`);
  svgRoot.setAttribute("height", `${height * exportScale}`);
  if (appState.exportWithDarkMode) {
    svgRoot.setAttribute("filter", THEME_FILTER);
  }

  let assetPath = "https://excalidraw.com/";

  // Asset path needs to be determined only when using package
  if (import.meta.env.IS_EXCALIDRAW_NPM_PACKAGE) {
    assetPath =
      window.EXCALIDRAW_ASSET_PATH ||
      `https://unpkg.com/${import.meta.env.PKG_NAME}@${import.meta.env.PKG_VERSION}`;

    if (assetPath?.startsWith("/")) {
      assetPath = assetPath.replace("/", `${window.location.origin}/`);
    }
    assetPath = `${assetPath}/dist/excalidraw-assets/`;
  }
  svgRoot.innerHTML = `
  ${SVG_EXPORT_TAG}
  ${metadata}
  <defs>
    <style class="style-fonts">
      @font-face {
        font-family: "Virgil";
        src: url("${assetPath}Virgil.woff2");
      }
      @font-face {
        font-family: "Cascadia";
        src: url("${assetPath}Cascadia.woff2");
      }
    </style>
  </defs>
  `;
  // render background rect
  if (appState.exportBackground && viewBackgroundColor) {
    const rect = svgRoot.ownerDocument!.createElementNS(SVG_NS, "rect");
    rect.setAttribute("x", "0");
    rect.setAttribute("y", "0");
    rect.setAttribute("width", `${width}`);
    rect.setAttribute("height", `${height}`);
    rect.setAttribute("fill", viewBackgroundColor);
    svgRoot.appendChild(rect);
  }

  const rsvg = rough.svg(svgRoot);
  renderSceneToSvg(
    elements,
    toBrandedType<RenderableElementsMap>(arrayToMap(elements)),
    rsvg,
    svgRoot,
    files || {},
    {
      offsetX: -minX + exportPadding,
      offsetY: -minY + exportPadding,
      isExporting: true,
      exportWithDarkMode,
      canvasBackgroundColor: viewBackgroundColor,
      criticalPathModeEnabled,
      overdueTaskModeEnabled,
      criticalPathColor,
      holidays,
      gridSize,
    });

  return svgRoot;
};

// calculate smallest area to fit the contents in
export const getCanvasSize = (
  elements: readonly NonDeletedExcalidrawElement[],
  exportPadding: number,
): Bounds => {
  const [minX, minY, maxX, maxY] = getCommonBounds(elements);
  const width = distance(minX, maxX) + exportPadding * 2;
  const height = distance(minY, maxY) + exportPadding + exportPadding;

  return [minX, minY, width, height];
};

export const getExportSize = (
  elements: readonly NonDeletedExcalidrawElement[],
  exportPadding: number,
  scale: number,
): [number, number] => {
  const [, , width, height] = getCanvasSize(elements, exportPadding).map(
    (dimension) => Math.trunc(dimension * scale),
  );

  return [width, height];
};
