import { getBoundTextElement } from "src/excalidraw/element/textElement";
import { ElementsMap, ExcalidrawElement } from "src/excalidraw/element/types";
import { isTaskElement } from "../element/typeChecks";
import { ExcalidrawTaskElement } from "../element/types";
import "./TaskProperties.scss"; //CHANGED:ADD 2023/01/26 #519
import { useStore } from "src/conpath/hooks/useStore";

export const TaskProperties = ({
  targetElements,
  elementsMap,
  projectId,
}: {
  targetElements: ExcalidrawElement[];
  elementsMap: ElementsMap;
  projectId: string;
}) => {
  const { organizationStore } = useStore();
  const { selectedOrganization } = organizationStore;

  const project = selectedOrganization?.projects
    .find((project) => project.id === projectId);

  let element: ExcalidrawTaskElement | null = null;

  if (targetElements.length <= 2) {
    element = targetElements.find((el) =>
      isTaskElement(el),
    ) as ExcalidrawTaskElement | null;
  }

  if (!element) {
    return null;
  }

  const title = getBoundTextElement(element, elementsMap)?.originalText; //CHANGED:UPDATE 2023/01/26 #519 → 2024/02/02 #1510 
  const startDate = new Date(element.startDate);
  const endDate = new Date(element.endDate);
  endDate.setDate(endDate.getDate() - 1);
  const layer = project?.layers.find((layer) => layer.index === element.layer)?.name;

  //CHANGED:ADD 2023/01/26 #519
  const sidebarTaskDate = (date: Date) => {
    return (
      <div className="sidebar-task__date__wrapper__columns">
        <span className="sidebar-task__date year-num">
          {date.getFullYear()}
          <span className="year-num__unit">年</span>
        </span>
        <span className="sidebar-task__date date-num">
          {("0" + (date.getMonth() + 1)).slice(-2)}
        </span>
        月
        <span className="sidebar-task__date date-num">
          {("0" + date.getDate()).slice(-2)}
        </span>
        日
        <span className="sidebar-task__date day-week">
          ({date.toLocaleString("default", { weekday: "short" })})
        </span>
      </div>
    );
  };
  return (
    <>
      {/* CHANGED:UPDATE 2023/01/26 #519 */}
      {title && (
        <h3
          className="sidebar-task__title"
          dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br>') }}
        ></h3>
      )}
      {layer &&
        <label>
          {project?.layers.find((layer) => layer.index === element.layer)?.name}
        </label>
      }
      <div className="sidebar-task__date__wrapper">
        {sidebarTaskDate(startDate)}
        <span>~</span>
        {sidebarTaskDate(endDate)}
      </div>
    </>
  );
};
