import { GRID_OF_LEFT_MARGIN, GRID_SIZE, JOB_ELEMENTS_WIDTH } from "src/excalidraw/constants";
import { getDifferenceInDates } from "src/excalidraw/extensions/shared/utils/dateUtils";

class Calendar {
  gridSize: number;
  baseDate: Date;
  hd: string[]; // CHANGED:ADD 2022-12-8 #267

  // CHANGED:UPDATE 2023-2-7 #541
  constructor(gridSize: number | null, baseDate?: Date, hd?: string[]) {
    const date = baseDate ? new Date(baseDate) : new Date();
    date.setHours(0, 0, 0, 0);

    this.gridSize = gridSize || GRID_SIZE;
    this.baseDate = date;
    this.hd = hd || [];
  }

  // CHANGED:ADD 2022-11-01 #79
  getPointDates = (x: number) => {
    const diffDate = this.gridSize ? x / this.gridSize : 0;
    const startDate = new Date(this.baseDate);

    startDate.setDate(startDate.getDate() + diffDate - GRID_OF_LEFT_MARGIN);

    const endDate = new Date(startDate);
    return { startDate, endDate };
  };

  getPointDate = (x: number) => {
    const diffDate = this.gridSize ? x / this.gridSize : 0;
    const gridDate = new Date(this.baseDate);

    gridDate.setDate(gridDate.getDate() + diffDate - GRID_OF_LEFT_MARGIN);

    return gridDate;
  };

  // CHANGED:ADD 2023-2-6 #541
  getDatePoint = (date: Date) => {
    const diffDay = (date.getTime() - this.baseDate.getTime()) / (1000 * 3600 * 24);

    return JOB_ELEMENTS_WIDTH + diffDay * this.gridSize;
  };

  // CHANGED:ADD 2022-11-15 #114
  getDuration = (startDate: Date, endDate: Date, holidays?: string[] | null) => {
    // CHANGED:UPDATE 2022-12-8 #267
    // const duration = (endDate.getTime() - startDate.getTime()) / 86400000;
    let duration = 0;

    if (startDate.getTime() < endDate.getTime()) {
      for (
        let date = new Date(startDate);
        date.getTime() < endDate.getTime();
        date.setDate(date.getDate() + 1)
      ) {
        const isHoliday = (holidays ? holidays : this.hd)?.some(
          (holiday) => new Date(holiday).toDateString() === date.toDateString(),
        );

        if (!isHoliday) {
          duration++;
        }
      }
    }

    return duration;
  };

  static getCalendarWidth = (
    start: Date,
    end: Date,
    gridSize: number,
  ): number => {
    return (getDifferenceInDates(start, end) + 1) * gridSize;
  };
}

export default Calendar;
