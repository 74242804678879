import React, { useCallback, useEffect, useState } from "react";
import SettingsLayout from "src/conpath/components/settings/SettingLayout";
import { RotatingLines } from 'react-loader-spinner';
import { Button } from "src/conpath/components/Button";

//interfaces
import {
  Image,
  OrganizationDocumentFields,
  OrganizationProfileForm,
} from "src/conpath/interfaces/Organization";
//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
//others
import { Paths } from "src/conpath/constants/Routes";
import Colors, {
  CriticalPathColor,
  CriticalPathColorMap,
} from "src/conpath/constants/Colors";
import { FloatLimit } from "../../constants/General";
import "./GeneralSettings.scss";
import Selection, { SelectionOption } from "src/conpath/components/Selection";
import Tooltip from "@mui/material/Tooltip";
import { CiCircleQuestion } from "react-icons/ci";

const criticalPathColorSelection: SelectionOption[] = [
  {
    value: CriticalPathColor.primary,
    label: CriticalPathColorMap[CriticalPathColor.primary],
  },
  {
    value: CriticalPathColor.secondary,
    label: CriticalPathColorMap[CriticalPathColor.secondary],
  },
];

const GeneralSettings: React.FC = observer(() => {
  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [profile, setProfile] = useState<OrganizationProfileForm>({
    name: "",
    criticalPathColor: "",
    iconImageFile: { file: null, url: "" },
    floatAlarmLimit: 0,
    floatWarningLimit: 0,
  });
  const [isModified, setIsModified] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [blobLogoImage, setBlobLogoImage] = useState<Image>({
    file: null,
    url: "",
  });

  const setCurrentProfile = () => {
    const field = selectedOrganization!.getFields();
    setProfile({
      name: field.name,
      criticalPathColor: field.criticalPathColor || CriticalPathColor.primary,
      iconImageFile: { file: null, url: field.iconImageUrl },
      floatAlarmLimit: field.floatAlarmLimit || FloatLimit.alarm,
      floatWarningLimit: field.floatWarningLimit || FloatLimit.warning,
    });
  };

  useEffect(() => {
    if (selectedOrganization) {
      setCurrentProfile();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  /**
   * handle changing user inputs (username and description).
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setProfile((prev) => ({
        ...prev,
        [name]: value
      }));
      if (!isModified) setIsModified(true);
    }
  };

  const onChangeLogoImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      const { size } = file;
      if (size > 12 * 1000000) return;

      const imageURL: string = URL.createObjectURL(file); //preview
      const image: Image = {
        url: imageURL,
        file,
      };
      setBlobLogoImage(image);
      if (!isModified) setIsModified(true);
    },
    [setBlobLogoImage],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "e" || event.key === "-" || event.key === ".") {
      event.preventDefault();
    }
  };

  const onUpdateButtonPressed = useCallback(async () => {
    if (blobLogoImage.file) {
      setProfile((prev) => ({
        ...prev,
        iconImageFile: blobLogoImage,
      }));
    }
    if (!selectedOrganization) return;
    const result = await selectedOrganization.updateOrganizationProfile({
      ...profile,
      iconImageFile: blobLogoImage,
    });
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }

    await organizationStore.setSelectedOrganization(selectedOrganization.id);
    setIsModified(false);
    setBlobLogoImage({ file: null, url: "" });
    setSuccessMessage("組織を更新しました。");
  }, [organizationStore, selectedOrganization, profile, blobLogoImage]);

  const onCancelButtonPressed = useCallback(() => {
    if (!selectedOrganization) return;

    setCurrentProfile();
    setBlobLogoImage({ file: null, url: "" });
    setIsModified(false);
  }, [selectedOrganization, setProfile, blobLogoImage]);

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle="一般"
        currentPath={Paths.settings}
      >
        <div className="setting-body">
          <div className="section">
            <div className="form__wrapper form__wrapper--box">
              <div className="form-container">
                <div className="selection-wrapper">
                  <p>組織名</p>
                  {loginUser?.isOrganizationOwner() ? (
                    <input
                      name={OrganizationDocumentFields.name}
                      className="general-setting--input"
                      value={profile.name}
                      onChange={onChangeInput}
                    />
                  ) : (
                    <label>{profile.name}</label>
                  )}
                </div>
              </div>
              <div className="form-container">
                <div className="selection-wrapper">
                  <p>クリティカルパスの色</p>
                  {loginUser?.isOrganizationOwner ? (
                    <Selection
                      id="role-selection"
                      value={profile.criticalPathColor}
                      options={criticalPathColorSelection}
                      onChange={(value: string) => {
                        setProfile((prev) => ({
                          ...prev,
                          [OrganizationDocumentFields.criticalPathColor]: value,
                        }));
                        if (!isModified) setIsModified(true);
                      }}
                    />
                  ) : (
                    <label>
                      {CriticalPathColorMap[profile.criticalPathColor as CriticalPathColor || CriticalPathColor.primary]}
                    </label>
                  )}
                </div>
              </div>
              <div className="form-container form-container_1fr">
                <div className="selection-wrapper">
                  <p>印刷ヘッダーロゴ</p>
                  {loginUser?.isOrganizationOwner() ? (
                    blobLogoImage.file || profile.iconImageFile.url ? (
                      <div className="general-setting--logo">
                        <img
                          src={
                            blobLogoImage.file
                              ? blobLogoImage.url
                              : profile.iconImageFile.url
                          }
                        />
                        <div className="general-setting--logo-btns">
                          <label className="general-setting--logo-change">
                            変更する
                            <input
                              id="logoUploadInput"
                              type="file"
                              className="general-setting--input"
                              onChange={onChangeLogoImage}
                              style={{ display: 'none' }}
                            />
                          </label>
                          <div
                            onClick={() => {
                              setBlobLogoImage({ file: null, url: "" })
                              setProfile((prev) => ({
                                ...prev,
                                iconImageFile: { file: null, url: "" },
                              }));
                              if (!isModified) setIsModified(true);
                            }}
                          >
                            リセット
                          </div>
                        </div>
                      </div>

                    ) : (
                      <label htmlFor='logoUploadInput' className="general-setting--logo-upload">
                        画像をアップロード
                        <input
                          id="logoUploadInput"
                          type="file"
                          className="general-setting--input"
                          onChange={onChangeLogoImage}
                          style={{ display: 'none' }}
                        />
                      </label>
                    )
                  ) : (
                    <div className="general-setting--logo">
                      <img src={profile.iconImageFile.url} />
                    </div>
                  )}
                </div>
              </div>
              <div className="roadmap-setting-container">
                <h4>ロードマップ設定</h4>
                <p className="description">マイルストーンまでの余裕日数</p>
                <div className="form-container">
                  <div className="selection-wrapper">
                    <p>アラート表示</p>
                    <Tooltip title="設定した日数以内になるとロードマップでアラート表示(赤色)されます" arrow>
                      <div>
                        <CiCircleQuestion />
                      </div>
                    </Tooltip>
                    {loginUser?.isOrganizationOwner()
                      ?
                      <input
                        type="number"
                        min="0"
                        name={OrganizationDocumentFields.floatAlarmLimit}
                        className="roadmap-setting-container--input"
                        value={profile.floatAlarmLimit}
                        onChange={onChangeInput}
                        onKeyDown={handleKeyDown}
                      />
                      :
                      <label>
                        {profile.floatAlarmLimit}
                      </label>
                    }
                    <p className="within-date">日以内</p>
                  </div>
                </div>
                <div className="form-container">
                  <div className="selection-wrapper">
                    <p>警告表示</p>
                    <Tooltip title="設定した日数以内になるとロードマップで警告表示(オレンジ色)されます" arrow>
                      <div>
                        <CiCircleQuestion />
                      </div>
                    </Tooltip>
                    {loginUser?.isOrganizationOwner()
                      ?
                      <input
                        type="number"
                        min={0}
                        name={OrganizationDocumentFields.floatWarningLimit}
                        className="roadmap-setting-container--input"
                        value={profile.floatWarningLimit}
                        onChange={onChangeInput}
                        onKeyDown={handleKeyDown}
                      />
                      :
                      <label>
                        {profile.floatWarningLimit}
                      </label>
                    }
                    <p className="within-date">日以内</p>
                  </div>
                </div>
              </div>
              {loginUser?.isOrganizationOwner() && (
                loginUser?.isNetworking ?
                  <div className="general-setting--spinner-wrapper">
                    <RotatingLines
                      strokeColor={Colors.primary}
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="42"
                      visible={true}
                    />
                  </div>
                  :
                  <div className="general-setting--buttonContainer">
                    <div className="general-setting--buttonRow">
                      <Button
                        disabled={!isModified}
                        name="abort"
                        theme="solid"
                        onClick={onCancelButtonPressed}>
                        キャンセル
                      </Button>
                      <Button
                        disabled={!isModified}
                        name="submit"
                        theme="main"
                        onClick={onUpdateButtonPressed}>
                        更新
                      </Button>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </SettingsLayout >
    </>
  )
});

export default GeneralSettings;