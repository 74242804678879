import { SelectionOption } from 'src/conpath/components/Selection';
import { OrganizationRole, OrganizationRoleMap, ProjectRole, ProjectRoleMap } from "src/conpath/constants/Role";
import { OrganizationPlan, OrganizationPlanMap } from './Plan';

export const organizationRoleSelections: SelectionOption[] = [
  {
    value: OrganizationRole.owner.toString(),
    label: OrganizationRoleMap[OrganizationRole.owner]
  },
  {
    value: OrganizationRole.member.toString(),
    label: OrganizationRoleMap[OrganizationRole.member]
  },
  {
    value: OrganizationRole.guest.toString(),
    label: OrganizationRoleMap[OrganizationRole.guest]
  }
];

export const projectRoleSelections: SelectionOption[] = [
  {
    value: ProjectRole.admin.toString(),
    label: ProjectRoleMap[ProjectRole.admin]
  },
  {
    value: ProjectRole.writer.toString(),
    label: ProjectRoleMap[ProjectRole.writer]
  },
  {
    value: ProjectRole.viewer.toString(),
    label: ProjectRoleMap[ProjectRole.viewer]
  }
];

export const organizationPlanSelection: SelectionOption[] = [
  {
    value: OrganizationPlan.trial.toString(),
    label: OrganizationPlanMap[OrganizationPlan.trial].name,
  },
  {
    value: OrganizationPlan.entry.toString(),
    label: OrganizationPlanMap[OrganizationPlan.entry].name,
  },
  {
    value: OrganizationPlan.business.toString(),
    label: OrganizationPlanMap[OrganizationPlan.business].name,
  },
  {
    value: OrganizationPlan.professional.toString(),
    label: OrganizationPlanMap[OrganizationPlan.professional].name,
  },
  {
    value: OrganizationPlan.enterprise.toString(),
    label: OrganizationPlanMap[OrganizationPlan.enterprise].name,
  },
];