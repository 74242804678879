import { ExcalidrawRectangleElement, ExcalidrawTextElement } from "src/excalidraw/element/types";
import Calendar from "src/excalidraw/extensions/calendar";
import {
  generateJobBackgroundElements,
  generateJobLineElements,
  generateJobPanelElements,
} from "./job";
import { generateMilestoneLineElements } from "./milestone";
import { ExcalidrawJobElement, ExcalidrawMilestoneElement } from "src/excalidraw/extensions/element/types";
import Job from "src/excalidraw/extensions/job";

export const generateBackgroundElements = (
  projectStartDate: Date,
  projectEndDate: Date,
  jobElements: ExcalidrawJobElement[],
  milestoneElements: ExcalidrawMilestoneElement[], // CHANGED:ADD 2023-2-11 #671
  backgroundColor: string,
  gridSize: number,
  holidays: string[],
): {
  jobBackgroundElements: ExcalidrawJobElement[];
  jobPanelElements: ExcalidrawRectangleElement[];
  jobLineElements: ExcalidrawRectangleElement[]; // CHANGED:ADD 2023-03-01 #726
  jobsHeight: number;
  milestoneLineElements: ExcalidrawRectangleElement[]; // CHANGED:ADD 2023-2-11 #671
  calendarWidth: number;
} => {
  let jobBackgroundElements = null;
  let jobPanelElements = null;
  let jobLineElements = null; // CHANGED:ADD 2023-03-01 #726
  let milestoneLineElements = null; // CHANGED:ADD 2023-2-11 #671

  const calendarWidth = Calendar.getCalendarWidth(
    projectStartDate,
    projectEndDate,
    gridSize,
  );

  const jobsHeight = Job.getJobElementsHeight(jobElements);
  jobBackgroundElements = generateJobBackgroundElements(jobsHeight);
  jobPanelElements = generateJobPanelElements(jobElements, calendarWidth);
  jobLineElements = generateJobLineElements(jobElements, calendarWidth); // CHANGED:ADD 2023-03-01 #726

  // CHANGED:ADD 2023-2-11 #671
  milestoneLineElements = generateMilestoneLineElements(milestoneElements, jobsHeight);

  return {
    jobBackgroundElements,
    jobPanelElements,
    jobLineElements, // CHANGED:ADD 2023-03-01 #726
    jobsHeight,
    milestoneLineElements, // CHANGED:ADD 2023-2-11 #671
    calendarWidth,
  };
};
