import { arePointsEqual, distance2d, getBezierXY } from "../math";
import { NormalizedZoomValue, Point, Zoom } from "../types";
import { LINE_CONFIRM_THRESHOLD } from "../constants";
import { NonDeleted } from "../element/types";
import { ExcalidrawLinkElement, ExcalidrawTaskElement } from "./element/types"; // from extensions
import { getCurvePathOps } from "../element/bounds";
import { Mutable } from "../utility-types";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";

// Checks if the first and last point are close enough
// to be considered a loop
export const isPathALoopEx = (
  points: ExcalidrawLinkElement["points"],
  /** supply if you want the loop detection to account for current zoom */
  zoomValue: Zoom["value"] = 1 as NormalizedZoomValue,
): boolean => {
  if (points.length >= 3) {
    const [first, last] = [points[0], points[points.length - 1]];
    const distance = distance2d(first[0], first[1], last[0], last[1]);

    // Adjusting LINE_CONFIRM_THRESHOLD to current zoom so that when zoomed in
    // really close we make the threshold smaller, and vice versa.
    return distance <= LINE_CONFIRM_THRESHOLD / zoomValue;
  }
  return false;
};

// CHANGED:ADD 2022-12-7 #157
// TODO: Rounding this point causes some shake when free drawing
export const getGridPointEx = (
  x: number,
  y: number,
  gridSize: number | null,
  isDragging: boolean,
): [number, number] => {
  if (gridSize) {
    const _gridSize = gridSize / 2;
    let offsetX = 0;
    let offsetY = 0;

    if (isDragging) {
      offsetX = x % gridSize < _gridSize ? _gridSize : - _gridSize;
      offsetY = y % gridSize < _gridSize ? _gridSize : - _gridSize;
    } else {
      offsetX = - _gridSize;
      offsetY = - _gridSize;
    }

    return [
      Math.round(x / gridSize) * gridSize + offsetX,
      Math.round(y / gridSize) * gridSize + offsetY,
    ];
  }
  return [x, y];
};

export const getControlPointsForBezierCurveEx = (
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
  endPoint: Point,
) => {
  const shape = ShapeCache.generateElementShape(element, null);
  if (!shape) {
    return null;
  }

  const ops = getCurvePathOps(shape[0]);
  let currentP: Mutable<Point> = [0, 0];
  let index = 0;
  let minDistance = Infinity;
  let controlPoints: Mutable<Point>[] | null = null;

  while (index < ops.length) {
    const { op, data } = ops[index];
    if (op === "move") {
      currentP = data as unknown as Mutable<Point>;
    }
    if (op === "bcurveTo") {
      const p0 = currentP;
      const p1 = [data[0], data[1]] as Mutable<Point>;
      const p2 = [data[2], data[3]] as Mutable<Point>;
      const p3 = [data[4], data[5]] as Mutable<Point>;
      const distance = distance2d(p3[0], p3[1], endPoint[0], endPoint[1]);
      if (distance < minDistance) {
        minDistance = distance;
        controlPoints = [p0, p1, p2, p3];
      }
      currentP = p3;
    }
    index++;
  }

  return controlPoints;
};

export const getPointsInBezierCurveEx = (
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
  endPoint: Point,
) => {
  const controlPoints: Mutable<Point>[] = getControlPointsForBezierCurveEx(
    element,
    endPoint,
  )!;
  if (!controlPoints) {
    return [];
  }
  const pointsOnCurve: Mutable<Point>[] = [];
  let t = 1;
  // Take 20 points on curve for better accuracy
  while (t > 0) {
    const point = getBezierXY(
      controlPoints[0],
      controlPoints[1],
      controlPoints[2],
      controlPoints[3],
      t,
    );
    pointsOnCurve.push([point[0], point[1]]);
    t -= 0.05;
  }
  if (pointsOnCurve.length) {
    if (arePointsEqual(pointsOnCurve.at(-1)!, endPoint)) {
      pointsOnCurve.push([endPoint[0], endPoint[1]]);
    }
  }
  return pointsOnCurve;
};

export const getBezierCurveArcLengthsEx = (
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
  endPoint: Point,
) => {
  const arcLengths: number[] = [];
  arcLengths[0] = 0;
  const points = getPointsInBezierCurveEx(element, endPoint);
  let index = 0;
  let distance = 0;
  while (index < points.length - 1) {
    const segmentDistance = distance2d(
      points[index][0],
      points[index][1],
      points[index + 1][0],
      points[index + 1][1],
    );
    distance += segmentDistance;
    arcLengths.push(distance);
    index++;
  }

  return arcLengths;
};

export const getBezierCurveLengthEx = (
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
  endPoint: Point,
) => {
  const arcLengths = getBezierCurveArcLengthsEx(element, endPoint);
  return arcLengths.at(-1) as number;
};

// This maps interval to actual interval t on the curve so that when t = 0.5, its actually the point at 50% of the length
export const mapIntervalToBezierTEx = (
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
  endPoint: Point,
  interval: number, // The interval between 0 to 1 for which you want to find the point on the curve,
) => {
  const arcLengths = getBezierCurveArcLengthsEx(element, endPoint);
  const pointsCount = arcLengths.length - 1;
  const curveLength = arcLengths.at(-1) as number;
  const targetLength = interval * curveLength;
  let low = 0;
  let high = pointsCount;
  let index = 0;
  // Doing a binary search to find the largest length that is less than the target length
  while (low < high) {
    index = Math.floor(low + (high - low) / 2);
    if (arcLengths[index] < targetLength) {
      low = index + 1;
    } else {
      high = index;
    }
  }
  if (arcLengths[index] > targetLength) {
    index--;
  }
  if (arcLengths[index] === targetLength) {
    return index / pointsCount;
  }

  return (
    1 -
    (index +
      (targetLength - arcLengths[index]) /
        (arcLengths[index + 1] - arcLengths[index])) /
      pointsCount
  );
};
