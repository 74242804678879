export const Paths = {
  "login": "/login",
  "signup": "/signup",
  "signupOrganization": "/signup-organization",
  "resetPassword": "/reset-password",
  "invite": "/invite",
  "invalid": "/invalid",
  "accept": "/accept",
  "dashboard": "/",
  "projects": "/projects",
  "resources": "/resources",
  "roadmap": "/roadmap",
  "settings": "/settings",
  "users": "/users",
  "teams": "/teams",
  "holiday": "/holiday",
  "profile": "/profile",
  "account": "/account",
  "billings": "/billings",
  "create": "/create",
  "edit": "/edit",
  "duplicate": "/duplicate",
} as const;

export type Paths = typeof Paths[keyof typeof Paths];
