import _ from "lodash";

//types and interface
import { ResourceResponse } from "src/conpath/interfaces/Resource";

//firebase
import { db } from "src/configs/firebase";
import {
  DocumentData,
  DocumentReference,
  doc,
  runTransaction,
} from "firebase/firestore";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import Resource from "../interfaces/Resource";
import { Tags } from "../interfaces/Tag";
import { action } from "mobx";
import InternalError from "../interfaces/InternalError";
import { firebaseTimeToDate } from "src/utils/timeUtils";

export default class ResourceModel implements Resource {
  iconImageUrl!: string | null;
  id!: string;
  index!: number;
  name!: string;
  tags!: Tags;
  memo!: string;
  isActive!: boolean;
  isDeleted!: boolean;
  createdBy!: string;
  createdAt!: Date | null;

  organizationId: string | null = null;

  constructor(resource: ResourceResponse) {
    this.setFields(resource);
  };

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public getFields(): Resource {
    return {
      iconImageUrl: this.iconImageUrl,
      id: this.id,
      index: this.index,
      name: this.name,
      tags: this.tags,
      memo: this.memo,
      isActive: this.isActive,
      isDeleted: this.isDeleted,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    };
  }

  public setFields(resource: ResourceResponse) {
    this.iconImageUrl = resource.iconImageUrl;
    this.id = resource.id;
    this.index = resource.index || -1;
    this.name = resource.name;
    this.tags = resource.tags;
    this.memo = resource.memo;
    this.isActive = resource.isActive;
    this.isDeleted = resource.isDeleted;
    this.createdBy = resource.createdBy || "";
    this.createdAt = resource.createdAt ? firebaseTimeToDate(resource.createdAt) : null;
  }

  @action
  public async delete(): Promise<InternalError> {

    try {
      const resourceRef = this.getResourceDocumentRef();
      if (_.isEmpty(resourceRef)) {
        throw new Error("No resource collection ref found.");
      }

      // Updating resource document
      const resourceUpdatingField: Partial<ResourceResponse> = {
        isDeleted: true,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(resourceRef, resourceUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "削除に失敗しました。" };
    }
  }

  @action
  public async updateIsActive(isActive: boolean): Promise<InternalError> {

    try {
      const resourceRef = this.getResourceDocumentRef();
      if (_.isEmpty(resourceRef)) {
        throw new Error("No resource collection ref found.");
      }

      // Updating resource document
      const resourceUpdatingField: Partial<ResourceResponse> = {
        isActive: isActive,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(resourceRef, resourceUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "アクティブ状態の更新に失敗しました。" };
    }
  }

  private getResourceDocumentRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.organizations.this, this.organizationId!, FirestoreCollections.organizations.resources, this.id);
  }
};