import { exportToBackend } from "src/excalidraw/excalidraw-app/data";
import { register } from "src/excalidraw/actions/register";

export const actionSaveToActiveCloudFile = register({
  name: "saveToActiveCloudFile",
  trackEvent: { category: "export" },
  predicate: (elements, appState, props, app) => {
    return (
      !!app.props.UIOptions.canvasActions.saveToActiveCloudFile &&
      !!appState.cloudFileHandle &&
      !appState.viewModeEnabled
    );
  },
  perform: async (elements, appState, value, app) => {
    try {
      const result = await exportToBackend(elements, appState, app.files);

      return {
        commitToHistory: false,
        appState: {
          ...appState,
          cloudFileHandle: result
            ? {
                id: result?.id,
                key: result?.key,
              }
            : null,
          openDialog: result ? "shareLink" : null, // CHANGED:ADD 2023-2-24 #741
        },
      };
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
});
