import { updateBoundElements } from "./binding";
// CHANGED:ADD 2022-10-28 #14
import { updateBoundElementsEx } from "../extensions/element/binding"; // from extensions
import { getCommonBounds } from "./bounds";
import { mutateElement, newElementWith } from "./mutateElement";
import { getPerfectElementSize } from "./sizeHelpers";
import { NonDeletedExcalidrawElement } from "./types";
import { AppState, PointerDownState } from "../types";
import { getBoundTextElement } from "./textElement";
import { isSelectedViaGroup } from "../groups";
import {
  isCommentElement,
  isCommentableElement,
  isLinkElement,
  isMilestoneElement,
  isNodeElement,
  isTaskElement,
} from "../extensions/element/typeChecks"; // from extensions
// CHANGED:ADD 2022-11-21 #164
import Calendar from "../extensions/calendar"; // from extensions
// CHANGED:UPDATE 2022-12-18 #347
import { getMovableSelectedElements } from "../extensions/element/dragElements"; // from extensions
import Scene from "src/excalidraw/scene/Scene"; // CHANGED:ADD 2022-12-12 #266
import { getGridPoint } from "src/excalidraw/math";
import { getGridPointEx } from "src/excalidraw/extensions/math";
import Job from "src/excalidraw/extensions/job";
import { COMMENT_OFFSET_X, COMMENT_OFFSET_Y } from "../constants";

export const dragSelectedElements = (
  pointerDownState: PointerDownState,
  selectedElements: NonDeletedExcalidrawElement[],
  pointerX: number,
  pointerY: number,
  lockDirection: boolean = false,
  distanceX: number = 0,
  distanceY: number = 0,
  appState: AppState,
  calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
  scene: Scene, // CHANGED:ADD 2022-12-12 #266
) => {
  const [x1, y1] = getCommonBounds(selectedElements);

  // CHANGED:UPDATE 2022-12-19 #347
  const offset = getMovableSelectedElements(
    selectedElements,
    scene.getElementsMapIncludingDeleted(),
    { x: pointerX - x1, y: pointerY - y1 },
    appState,
    true, // CHANGED:ADD 2022-12-28 #397
  );

  selectedElements.forEach((element) => {
    updateElementCoords(
      lockDirection,
      distanceX,
      distanceY,
      pointerDownState,
      element,
      offset,
      appState,
      calendar, // CHANGED:ADD 2022-11-21 #164
      scene, // CHANGED:ADD 2023-2-11 #671
    );
    // update coords of bound text only if we're dragging the container directly
    // (we don't drag the group that it's part of)
    if (
      // container isn't part of any group
      // (perf optim so we don't check `isSelectedViaGroup()` in every case)
      !element.groupIds.length ||
      // container is part of a group, but we're dragging the container directly
      (appState.editingGroupId && !isSelectedViaGroup(appState, element))
    ) {
      const textElement = getBoundTextElement(
        element,
        scene.getNonDeletedElementsMap(),
      );
      if (textElement) {
        // CHANGED:ADD 2022-12-12 #266
        if (isTaskElement(element)) {
          scene.updateBoundTextElement(
            textElement,
            textElement.originalText, // CHANGED:UPDATE 2023-08-22 #924
            textElement.originalText,
            textElement.isDeleted,
          );
        }
        updateElementCoords(
          lockDirection,
          distanceX,
          distanceY,
          pointerDownState,
          textElement,
          offset,
          appState,
          calendar, // CHANGED:ADD 2022-11-21 #164
          scene, // CHANGED:ADD 2023-2-11 #671
        );
      }
    }

    updateBoundElements(element, scene.getElementsMapIncludingDeleted(), {
      simultaneouslyUpdated: selectedElements,
    });
    // CHANGED:ADD 2022-10-28 #14
    updateBoundElementsEx(
      element,
      scene.getElementsMapIncludingDeleted(),
      appState, //CHANGED:ADD 2022-11-01 #79
      calendar, // CHANGED:ADD 2022/01/19 #390
      { simultaneouslyUpdated: selectedElements },
    );
  });
};

const updateElementCoords = (
  lockDirection: boolean,
  distanceX: number,
  distanceY: number,
  pointerDownState: PointerDownState,
  element: NonDeletedExcalidrawElement,
  offset: { x: number; y: number },
  appState: AppState,
  calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
  scene: Scene, // CHANGED:ADD 2023-2-11 #671
) => {
  let x: number;
  let y: number;

  // CHANGED:ADD 2022-11-18 #149
  if (isNodeElement(element)) {
    if (lockDirection) {
      const lockX = lockDirection && distanceX < distanceY;
      const lockY = lockDirection && distanceX > distanceY;
      const original = pointerDownState.originalElements.get(element.id);
      x = lockX && original ? original.x : element.x + offset.x;
      y = lockY && original ? original.y : element.y + offset.y;
    } else {
      x = element.x + offset.x;
      y = element.y + offset.y;
    }

    if (isTaskElement(element)) {
      // CHANGED:ADD 2023-2-10 #380
      const [gridX, gridY] = getGridPoint(
        x,
        y,
        appState.gridSize,
      );

      // CHANGED:UPDATE 2022-11-21 #164
      const startDate = calendar.getPointDate(x);
      const endDate = calendar.getPointDate(x + element.width);
      // CHANGED:ADD 2022-12-8 #267
      const duration = calendar.getDuration(
        startDate,
        endDate,
        element.holidays, // CHANGED:ADD 2023-1-20 #382
      );

      //CHANGED:ADD 2023-03-01 #740
      let isMovableY = true;
      if (element.isVisible) {
        const job = Job.getJobElementFromGivenGridY(
          y,
          scene.getNonDeletedElements(),
        );
        if (job === undefined || job.isCompressed) {
          isMovableY = false;
        }
      }

      // CHANGED:UPDATE 2023/08/30 #967
      // mutateElement(element, {
      //   x: gridX,
      //   y: isMovableY ? gridY : element.y,
      //   startDate,
      //   endDate,
      //   duration, // CHANGED:ADD 2022-12-8 #267
      // });
      scene.replaceAllElements([
        ...scene.getElementsIncludingDeleted().map((_element) => {
          if (_element.id === element.id && isTaskElement(_element)) {
            return newElementWith(_element, {
              x: gridX,
              y: isMovableY ? gridY : element.y,
              startDate,
              endDate,
              duration, // CHANGED:ADD 2022-12-8 #267
            });
            // CHANGED: ADD 2024-03-08 #1138, #1741
          } else if (isCommentElement(_element) && _element.commentElementId === element.id) {
            return newElementWith(_element, {
              x: element.x + element.width + offset.x + COMMENT_OFFSET_X,
              y: isMovableY ? element.y + offset.y + COMMENT_OFFSET_Y : element.y + COMMENT_OFFSET_Y,
            });
          }
          return _element;
        }),
      ]);
      // CHANGED:ADD 2022-12-7 #157
    } else if (isMilestoneElement(element)) {
      // CHANGED:ADD 2023-2-10 #380
      const [gridX, gridY] = getGridPointEx(
        x,
        y,
        appState.gridSize,
        true,
      );

      const date = calendar.getPointDate(x + element.width / 2);

      //CHANGED:ADD 2023-03-01 #740
      let isMovableY = true;
      if (element.isVisible) {
        const job = Job.getJobElementFromGivenGridY(
          element.y + offset.y + element.height / 2,
          scene.getNonDeletedElements(),
        );
        if (job === undefined || job.isCompressed) {
          isMovableY = false;
        }
      }

      mutateElement(element, {
        x: gridX,
        y: isMovableY ? gridY : element.y,
        date,
      });

      // CHANGED:ADD 2023-2-11 #671
      scene.updateMilestoneLine(element);
    }

    return;
  }

  if (lockDirection) {
    const lockX = lockDirection && distanceX < distanceY;
    const lockY = lockDirection && distanceX > distanceY;
    const original = pointerDownState.originalElements.get(element.id);
    x = lockX && original ? original.x : element.x + offset.x;
    y = lockY && original ? original.y : element.y + offset.y;
  } else {
    x = element.x + offset.x;
    y = element.y + offset.y;
  }

  mutateElement(element, {
    x,
    y,
  });
};
export const getDragOffsetXY = (
  selectedElements: NonDeletedExcalidrawElement[],
  x: number,
  y: number,
): [number, number] => {
  //CHANGED:UPDATE 2022-12-23 #371
  // const [x1, y1] = getCommonBounds(selectedElements);
  const [x1, y1] = getCommonBounds(
    selectedElements.filter((element) => !isLinkElement(element)),
  );
  return [x - x1, y - y1];
};

export const dragNewElement = (
  draggingElement: NonDeletedExcalidrawElement,
  elementType: AppState["activeTool"]["type"],
  originX: number,
  originY: number,
  x: number,
  y: number,
  width: number,
  height: number,
  shouldMaintainAspectRatio: boolean,
  shouldResizeFromCenter: boolean,
  /** whether to keep given aspect ratio when `isResizeWithSidesSameLength` is
      true */
  widthAspectRatio?: number | null,
) => {
  if (shouldMaintainAspectRatio && draggingElement.type !== "selection") {
    if (widthAspectRatio) {
      height = width / widthAspectRatio;
    } else {
      // Depending on where the cursor is at (x, y) relative to where the starting point is
      // (originX, originY), we use ONLY width or height to control size increase.
      // This allows the cursor to always "stick" to one of the sides of the bounding box.
      if (Math.abs(y - originY) > Math.abs(x - originX)) {
        ({ width, height } = getPerfectElementSize(
          elementType,
          height,
          x < originX ? -width : width,
        ));
      } else {
        ({ width, height } = getPerfectElementSize(
          elementType,
          width,
          y < originY ? -height : height,
        ));
      }

      if (height < 0) {
        height = -height;
      }
    }
  }

  let newX = x < originX ? originX - width : originX;
  let newY = y < originY ? originY - height : originY;

  if (shouldResizeFromCenter) {
    width += width;
    height += height;
    newX = originX - width / 2;
    newY = originY - height / 2;
  }

  if (width !== 0 && height !== 0) {
    mutateElement(draggingElement, {
      x: newX,
      y: newY,
      width,
      height,
    });
  }
};
