import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import "./SelectLayer.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import ProjectModel from "src/conpath/models/ProjectModel";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck } from "react-icons/fa";

interface SelectingLayer {
  id: string;
  index: number;
  name: string;
}
const hasLayerCheck = ({
  selectedLayer,
  targetLayer,
}: {
  selectedLayer: SelectingLayer;
  targetLayer: SelectingLayer;
}): boolean => {
  return selectedLayer.index === targetLayer.index;
};

const SelectLayer = observer(
  (props: {
    taskModel: TaskModel;
    organization: OrganizationModel | null;
    project: ProjectModel | null;
    onSaveLayer: () => void;
  }) => {
    const { taskModel, organization, project, onSaveLayer } = props;

    const [selectedLayer, setSelectedLayer] = useState<SelectingLayer>({
      id: "",
      index: 0,
      name: "",
    });
    const [projectLayers, setProjectLayers] = useState<SelectingLayer[]>(
      [],
    );

    // 全レイヤー
    useEffect(() => {
      const layers = project?.layers.filter((layer) => !layer.isDeleted)
        .map((layer) => {
          return {
            id: layer.id,
            index: layer.index,
            name: layer.name,
          } as SelectingLayer;
        }) || [];

      setProjectLayers(layers);
    }, [organization]);

    useEffect(() => {
      if (projectLayers.length > 0) {
        const _selectedLayer = projectLayers.find((layer) => layer.index === taskModel.layer) || projectLayers[0];
        setSelectedLayer(_selectedLayer);
      } else {
        const _selectedLayer = {
          id: "",
          index: 0,
          name: "レイヤー 1",
        };
        setSelectedLayer(_selectedLayer);
      }
    }, [projectLayers, taskModel.layer]);

    // if(!project) return <></>;
    return (
      <div className="flex relative">
        <SelectionLayerList
          projectLayers={projectLayers}
          selectedLayer={selectedLayer}
          taskModel={taskModel}
          onSaveLayer={onSaveLayer}
        />
      </div>
    );
  },
);

const SelectionLayerList = (props: {
  taskModel: TaskModel;
  projectLayers: SelectingLayer[];
  selectedLayer: SelectingLayer;
  onSaveLayer: () => void;
}) => {
  const { projectLayers, selectedLayer, taskModel, onSaveLayer } = props;
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const prev = isOpenAssignList;
        if (prev) {
          setIsOpenAssignList(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, isOpenAssignList]);

  const List = React.useMemo(() => {
    const onSave = (targetLayer: SelectingLayer) => {
      taskModel.setLayer(targetLayer.index);
    }

    const onSelectLayerButtonPressed = () => {
      setIsOpenAssignList(!isOpenAssignList);
    };

    return (
      <Menu ref={ref} as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSelectLayerButtonPressed();
            onSaveLayer();
          }
          return (
            <>
              <Menu.Button
                className={classNames("flex items-center group/assign-button no-select", /* テキスト選択を無効にするために no-select クラスを追加 */
                  projectLayers.length > 0
                    ? "cursor-pointer"
                    : ""
                )}
                as="div"
                disabled={projectLayers.length === 0}
                onClick={() => {
                  onSelectLayerButtonPressed();
                }}
              >
                <label className={classNames("task-content__label !mb-0",
                  projectLayers.length > 0
                    ? "cursor-pointer group-hover/assign-button:text-primary-color"
                    : ""
                )}>
                  {selectedLayer.name}
                </label>
                {projectLayers.length > 0 &&
                  <GoGear
                    className="ml-2 text-black cursor-pointer group-hover/assign-button:text-primary-color"
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-[1] absolute left-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {projectLayers.length > 0 && (
                    <>
                      {projectLayers.map((layer) => {
                        const isCheck = hasLayerCheck({
                          selectedLayer: selectedLayer,
                          targetLayer: layer,
                        });
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-layer flex items-center hover:bg-primary-color transition ease-out duration-100 no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                            onClick={() => onSave(layer)}
                          >
                            <FaCheck
                              className={`${isCheck
                                ? "text-black group-hover/select-layer:text-white"
                                : "text-transparent"
                                } mr-2`}
                            />
                            <span className="group-hover/select-layer:text-white">
                              {layer.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    projectLayers,
    selectedLayer,
  ]);

  return List;
};

export default SelectLayer;
