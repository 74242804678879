
const firebaseStorageErrorMessage: { [key: string]: string } = {
  "storage/unauthorized": "ユーザー認証に失敗しました。再度ログインしてお試しください。",
  "storage/canceled": "ファイルの保存処理がキャンセルされました。時間をおいて再度お試しください。",
  "storage/unknown": "予期せぬエラーが発生しました。時間をおいて再度お試しください。",
};

export function getFirebaseStorageErrorText(type: string): string {
  const errorText = firebaseStorageErrorMessage[type];
  if (errorText) return errorText;
  return "ファイルの保存処理に失敗しました。時間をおいて再度お試しください。";
};