import { find } from 'lodash';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { BAR_BASE_HEIGHT, TOP_PADDING } from '../../constants';
import Context from '../../context'
import { ONE_DAY_MS } from '../../store'
import { Gantt } from '../../types'
import { useMemoizedFn } from 'ahooks'
import './index.scss'
import { BarColors } from 'src/conpath/constants/Colors'
import DelayIcon from './DelayIcon'
import DelayBubble from "./DelayBubble";
import HoverToolTip from 'src/conpath/components/HoverToolTip';

interface TaskBarProps {
  data: Gantt.Bar;
}

interface TaskBarSetting {
  colors: string[];
  strokeColors: string[];
  strokeWidth: number;
  thickness: number;
  roundness: number;
  label?: {
    text: string;
    fontSize: number;
    color: string;
    width: number;
    height: number;
    paddingRight: number;
  };
  delayIconScale?: number;
}

const TaskBar: React.FC<TaskBarProps> = ({ data }) => {
  const {
    store,
    getBarColor,
    // renderBar,
    onBarClick,
    prefixCls,
    barHeight,
    alwaysShowTaskBar,
    renderLeftText,
    renderRightText,
  } = useContext(Context);

  const {
    width,
    translateX,
    translateY,
    invalidDateRange,
    stepGesture,
    dateTextFormat,
    record,
    loading,
    getDateWidth,
    _depth,
    _collapsed,
  } = data;

  const { disabled = false } = record || {};

  const prefixClsTaskBar = `${prefixCls}-task-bar`;

  const { selectionIndicatorTop, showSelectionIndicator, rowHeight, locale, taskBarConfig, dependencies } = store;

  const [tooltip, setTooltip] = useState<{ left: number; top: number; visible: boolean; text: string }>({
    left: 0,
    top: 0,
    visible: false,
    text: '',
  });
  const [zIndex, setZIndex] = useState<number>(1);

  const showDragBar = useMemo(() => {
    if (!showSelectionIndicator) return false
    // 差
    const baseTop = TOP_PADDING + rowHeight / 2 - barHeight / 2;
    return selectionIndicatorTop === translateY - baseTop;
  }, [showSelectionIndicator, selectionIndicatorTop, translateY, rowHeight, barHeight]);

  const taskBarSettings = useMemo((): TaskBarSetting => {
    const percent = taskBarConfig.type === "percentOfTasksCompleted"
      ? `${record.countRatio?.toFixed()}%`
      : taskBarConfig.type === "percentOfDaysCompleted"
        ? `${record.daysRatio?.toFixed()}%`
        : null;
    const numberOfCompleted = taskBarConfig.type === "numberOfTasksCompleted"
      ? `${record.completedTaskCount}/${record.totalTaskCount}`
      : taskBarConfig.type === "numberOfDaysCompleted"
        ? `${record.completedDays}/${record.totalDays}`
        : null;

    const text = percent
      ? percent
      : numberOfCompleted
        ? numberOfCompleted
        : "";

    switch (record.barType) {
      case Gantt.BarType.Project: // Project Bar
        const paddingRight = percent
          ? percent.length * 16
          : numberOfCompleted
            ? numberOfCompleted.length * 12
            : 0;

        return {
          colors: record.isDelayed
            ? [BarColors.OVERDUE, BarColors.OVERDUE]
            : [BarColors.PROJECT_PRIMARY, BarColors.WHITE],
          strokeColors: record.isDelayed
            ? [BarColors.OVERDUE, BarColors.OVERDUE]
            : [BarColors.PROJECT_PRIMARY, BarColors.PROJECT_PRIMARY],
          thickness: 26,
          roundness: 13,
          strokeWidth: 3,
          label: {
            text: percent
              ? percent
              : numberOfCompleted
                ? numberOfCompleted
                : "",
            fontSize: 16,
            color: BarColors.WHITE,
            width: 16 * text.length + paddingRight,
            height: 20,
            paddingRight,
          },
        };
      case Gantt.BarType.Milestone: // Milestone Bar
        const _paddingRight = percent
          ? percent.length * 14
          : numberOfCompleted
            ? numberOfCompleted.length * 10
            : 0;

        return {
          colors: record.isDelayed
            ? [BarColors.OVERDUE, BarColors.OVERDUE]
            : [BarColors.MILESTONE_PRIMARY, BarColors.WHITE],
          strokeColors: record.isDelayed
            ? [BarColors.OVERDUE, BarColors.OVERDUE]
            : [BarColors.MILESTONE_PRIMARY, BarColors.MILESTONE_PRIMARY],
          thickness: 17,
          roundness: 0,
          strokeWidth: 1,
          label: {
            text,
            fontSize: 14,
            color: BarColors.WHITE,
            width: 14 * text.length + _paddingRight,
            height: 14,
            paddingRight: _paddingRight,
          },
        };
      case Gantt.BarType.User: // User Bar
      case Gantt.BarType.Resource: // Resource Bar
        return {
          colors: [],
          strokeColors: [],
          thickness: 17,
          roundness: 0,
          strokeWidth: 1,
        };
      default: // Task Bar
        const colors = record.isClosed
          ? [BarColors.CLOSED, BarColors.CLOSED]
          : record.isDelayed
            ? [BarColors.OVERDUE, BarColors.OVERDUE]
            : [BarColors.TASK_PRIMARY, BarColors.TASK_SECONDARY];
        return {
          colors,
          strokeColors: colors,
          thickness: 8,
          roundness: 0,
          strokeWidth: 1,
          delayIconScale: 2.5,
        };
    }
  }, [_depth, record, width, taskBarConfig]);

  const handleBeforeResize = (type: Gantt.MoveType) => () => {
    if (disabled) return
    store.handleDragStart(data, type);
  };

  const handleResize = useCallback(
    ({ width: newWidth, x }: { width: number; x: number }) => {
      if (disabled) return
      store.updateBarSize(data, { width: newWidth, x });
    },
    [data, store, disabled],
  );

  const handleLeftResizeEnd = useCallback(
    (oldSize: { width: number; x: number }) => {
      store.handleDragEnd();
      store.updateTaskDate(data, oldSize, 'left');
    },
    [data, store],
  );

  const handleRightResizeEnd = useCallback(
    (oldSize: { width: number; x: number }) => {
      store.handleDragEnd();
      store.updateTaskDate(data, oldSize, 'right');
    },
    [data, store],
  );

  const handleMoveEnd = useCallback(
    (oldSize: { width: number; x: number }) => {
      store.handleDragEnd();
      store.updateTaskDate(data, oldSize, 'move');
    },
    [data, store],
  );

  const handleAutoScroll = useCallback(
    (delta: number) => {
      store.setTranslateX(store.translateX + delta);
    },
    [store],
  );

  const allowDrag = showDragBar && !loading;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (onBarClick) onBarClick(data.record)
    },
    [data.record, onBarClick],
  );
  const reachEdge = useMemoizedFn((position: 'left' | 'right') => position === 'left' && store.translateX <= 0);

  const grid = useMemo(() => ONE_DAY_MS / store.pxUnitAmp, [store.pxUnitAmp])

  const moveCalc = -(width / store.pxUnitAmp);

  const days = useMemo(() => {
    // const daysWidth = Number(getDateWidth(translateX + width + moveCalc, translateX));
    const daysWidth = record.duration || 0;

    return `${daysWidth} ${daysWidth > 1 ? locale.days : locale.day}`;
  }, [translateX, width, moveCalc, translateX]);

  const [showBubble, setShowBubble] = useState(false); // 「期限を過ぎています」の吹き出しの表示に関するstate

  const renderBar = () => {
    if (record.barType === Gantt.BarType.Task) {
      return renderTaskBar();
    } else if (
      record.barType === Gantt.BarType.Project ||
      record.barType === Gantt.BarType.Milestone
    ) {
      return renderRoadmapParentBar();
    } else if (
      record.barType === Gantt.BarType.User ||
      record.barType === Gantt.BarType.Resource
    ) {
      return renderResourceParentBar();
    }
  };

  const renderTaskBar = () => {
    const ratio = record.ratio ? record.ratio / 100 : 0;
    const widths = ratio === 1 ? [width] : [width * ratio, width * (1 - ratio)];
    const { colors, strokeColors, strokeWidth, thickness, delayIconScale } = taskBarSettings;

    const handleMouseEnter = () => {
      setShowBubble(true); // 期限を過ぎているtask-barにカーソルを合わせると表示
    };

    const handleMouseLeave = () => {
      setShowBubble(false);
    };

    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          // CHANGED:UPDATE 2023-12-18 #1396
          width={width}
          height={barHeight}
          viewBox={`0 0 ${width} ${barHeight}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {widths.map((width, index) => {
            const prevWidth = index === 0 ? 0 : widths[index - 1];
            return (
              <rect
                key={index}
                x={prevWidth}
                y="0"
                width={width}
                height={thickness}
                fill={colors[index]}
                stroke={strokeColors[index]}
                strokeWidth={strokeWidth}
                rx={0}
                ry={0}
              />
            );
          })}
        </svg>
        {record.isDelayed && (
          <DelayIcon
            width={width}
            thickness={thickness}
            color={colors[0]}
            scale={delayIconScale}
          />
        )}
        {record.isDelayed && showBubble && (
          <DelayBubble
            className={`${prefixClsTaskBar}-bubble`}
            width={width}
            thickness={thickness}
          />
        )}
      </>
    );
  };

  const renderRoadmapParentBar = () => {
    const ratio = (taskBarConfig.type === "numberOfTasksCompleted" || taskBarConfig.type === "percentOfTasksCompleted")
      ? record.countRatio ? record.countRatio / 100 : 0
      : (taskBarConfig.type === "numberOfDaysCompleted" || taskBarConfig.type === "percentOfDaysCompleted")
        ? record.daysRatio ? record.daysRatio / 100 : 0
        : 0;
    const widths = ratio === 1 ? [width] : [width * ratio, width * (1 - ratio)];
    const { colors, strokeColors, strokeWidth, thickness, roundness, label } = taskBarSettings;

    const floats =
      record.isMilestone && _collapsed
        ? dependencies
            .filter(
              (dependence) =>
                dependence.type === "float" &&
                dependence.from.includes(record.id),
            )
            .sort((a, b) => a.duration! - b.duration!)
        : [];
    const color = floats.length > 0 ? floats[0].color : "";
    const diff =
      floats.length > 0 ? floats[0].duration?.toFixed(0) || "0" : "0";
    const floatWidth = 76 + Number(diff.length) * 8;
    const _width = width + (floats.length > 0 ? (floatWidth + 20) : 0);

    const showLabel = width > label!.width;
    let textPositionX = widths[0] - label!.paddingRight;
    if (widths.length > 1 && widths[0] < label!.width) {
      textPositionX = widths[0] + 8;
      label!.color = colors[0];
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        // CHANGED:UPDATE 2023-12-18 #1396
        width={_width}
        height={thickness}
        viewBox={`0 0 ${_width} ${thickness}`}
      >
        {widths.map((width, index) => {
          const prevWidth = index === 0 ? 0 : widths[index - 1];
          return (
            <rect
              key={index}
              x={prevWidth}
              y="0"
              width={width}
              height={thickness}
              fill={colors[index]}
              rx={roundness}
              ry={roundness}
            />
          );
        })}
        {(widths[0] < (width - roundness)) &&
          <rect
            x={widths[0] - roundness}
            y="0"
            width={roundness}
            height={thickness}
            fill={colors[0]}
            rx={0}
            ry={0}
          />
        }
        <rect
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          width={width - strokeWidth}
          height={thickness - strokeWidth}
          fill='transparent'
          stroke={strokeColors[0]}
          strokeWidth={strokeWidth}
          rx={roundness}
          ry={roundness}
        />
        {showLabel && (
          <text
            x={textPositionX}
            y={label!.height}
            fontFamily="Verdana"
            fontSize={label!.fontSize}
            fill={label!.color}
            width={label!.width}
          >
            {label!.text}
          </text>
        )}
        {floats.length > 0 &&
          <>
            <rect
              fill="transparent"
              stroke={color}
              x={width + 10}
              y={thickness / 2 - 8}
              width={floatWidth}
              height={16}
              rx={8}
              ry={8}
            />
            <text
              x={width + 20}
              y={thickness / 2 + 4}
              fill={color}
              fontSize={12}
              className="small">
              {`余裕日数 (${diff})`}
            </text>
          </>
        }
      </svg>
    );
  };

  const renderResourceParentBar = () => {
    const { thickness, roundness } = taskBarSettings;

    const parent = find(store.data, (bar) => bar.id === record.id);
    const children = (parent?.children || []).filter((task) => task.visible);

    const dates: Date[] = [];
    const records: Gantt.Record[] = [];
    let currentDate = new Date(record.startDate);
    let startDate = new Date(record.startDate);
    let prevTasks = children.filter((task) => currentDate <= task.record.endDate && currentDate >= task.record.startDate);

    while (currentDate <= record.endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);

      const tasks = children.filter((task) => currentDate <= task.record.endDate && currentDate >= task.record.startDate);
      const count = tasks.length;
      const prevCount = prevTasks.length;
      if (prevCount !== count) {
        if (prevCount > 0) {
          const record = {
            startDate,
            endDate: dates[dates.length - 1],
            name: prevCount === 1
              ? prevTasks[0].record.name
              : prevCount,
            projectName: prevCount === 1
              ? prevTasks[0].record.projectName
              : undefined,
            projectColor: prevCount === 1
              ? prevTasks[0].record.projectColor
              : undefined,
          } as Gantt.Record;

          records.push(record);
        }

        startDate = new Date(currentDate);
        prevTasks = tasks;
      } else if (
        prevCount === 1 &&
        count === 1 &&
        prevTasks[0].record.id !== tasks[0].record.id
      ) {
        const record = {
          startDate,
          name: prevTasks[0].record.name,
          endDate: prevTasks[0].record.endDate,
          projectName: prevTasks[0].record.projectName,
          projectColor: prevTasks[0].record.projectColor,
        } as Gantt.Record;

        records.push(record);

        startDate = new Date(currentDate);
        prevTasks = tasks;
      }
    }

    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          // CHANGED:UPDATE 2023-12-18 #1396
          width={width}
          height={thickness}
          viewBox={`0 0 ${width} ${thickness}`}
        >
          <defs>
            <pattern
              id='overlap'
              width='3'
              height='10'
              patternUnits='userSpaceOnUse'
              patternTransform='rotate(45)'
            >
              <line stroke='#DF4646' strokeWidth='2.5px' y2='10' />
            </pattern>
          </defs>
          {records.map((_record) => {
            const index = (_record.startDate.getTime() - record.startDate.getTime()) / 86400000;
            const diffDay = ((_record.endDate.getTime() - _record.startDate.getTime()) / 86400000) + 1;
            const prevWidth = index * width / dates.length;
            const _width = diffDay * width / dates.length;

            return (
              <rect
                key={index}
                x={prevWidth}
                y="0"
                width={_width}
                height={thickness}
                fill={_record.projectName ? _record.projectColor: "url(#overlap)"}
                fillOpacity={_record.projectName ? 0.6 : 1}
                rx={roundness}
                ry={roundness}
                onMouseEnter={() => {
                  setTooltip({
                    left: prevWidth,
                    top: 20,
                    visible: true,
                    text: !_record.projectName
                      ? `${_record.name}件のタスクが重複`
                      : _record.name
                        ? `${_record.projectName} - ${_record.name}`
                        : _record.projectName,
                  });
                  setZIndex(1000);
                }}
                onMouseLeave={() => {
                  setTooltip({ ...tooltip, visible: false });
                  setZIndex(1);
                }}
              />
            );
          })}
        </svg>
      </>
    );
  };

  // CHANGED:ADD 2023/12/06 #1339
  const _translateY =
    translateY + BAR_BASE_HEIGHT / 2 - taskBarSettings.thickness / 2;
  return (
    <div
      role='none'
      className={classNames(prefixClsTaskBar, {
        [`${prefixClsTaskBar}-invalid-date-range`]: invalidDateRange,
        [`${prefixClsTaskBar}-overdue`]: !invalidDateRange,
      })}
      style={{
        transform: `translate(${translateX}px, ${_translateY}px)`,
        zIndex: zIndex,
      }}
      onClick={handleClick}
    >
      {loading && <div className={`${prefixClsTaskBar}-loading`} />}
      <div>
        {allowDrag && (
          <>
            {/* {stepGesture !== 'moving' && (
              <div className={styles['dependency-handle']} style={{ left: -34, width: 12 }}>
                <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle className={styles.outer} stroke="#87D2FF" fill="#FFFFFF" cx="6" cy="6" r="5.5" />
                    <circle className={styles.inner} fill="#87D2FF" cx="6" cy="6" r="2" />
                  </g>
                </svg>
              </div>
            )}
            {stepGesture !== 'moving' && (
              <div className={classNames(styles['dependency-handle'], styles.right)} style={{ left: width + 28, width: 12 }}>
                <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle className={styles.outer} stroke="#87D2FF" fill="#FFFFFF" cx="6" cy="6" r="5.5" />
                    <circle className={styles.inner} fill="#87D2FF" cx="6" cy="6" r="2" />
                  </g>
                </svg>
              </div>
            )} */}
            {/* <DragResize
              className={classNames(`${prefixClsTaskBar}-resize-handle`, `${prefixClsTaskBar}-resize-handle-left`, {
                [`${prefixClsTaskBar}-resize-handle-disabled`]: disabled,
              })}
              style={{ left: -14 }}
              // onResize={handleResize}
              onResizeElement={handleResize}
              onResizeEnd={handleLeftResizeEnd}
              defaultSize={{
                x: translateX,
                width,
              }}
              minWidth={30}
              grid={grid}
              type='left'
              scroller={store.chartElementRef.current || undefined}
              onAutoScroll={handleAutoScroll}
              reachEdge={reachEdge}
              onBeforeResize={handleBeforeResize('left')}
              disabled={disabled}
            />
            <DragResize
              className={classNames(`${prefixClsTaskBar}-resize-handle`, `${prefixClsTaskBar}-resize-handle-right`, {
                [`${prefixClsTaskBar}-resize-handle-disabled`]: disabled,
              })}
              style={{ left: width + 1 }}
              // onResize={handleResize}
              onResizeElement={handleResize}
              onResizeEnd={handleRightResizeEnd}
              defaultSize={{
                x: translateX,
                width,
              }}
              minWidth={30}
              grid={grid}
              type='right'
              scroller={store.chartElementRef.current || undefined}
              onAutoScroll={handleAutoScroll}
              reachEdge={reachEdge}
              onBeforeResize={handleBeforeResize('right')}
              disabled={disabled}
            />
            <div
              className={classNames(`${prefixClsTaskBar}-resize-bg`, `${prefixClsTaskBar}-resize-bg-compact`)}
              style={{ width: width + 30, left: -14 }}
            /> */}
          </>
        )}
        {/* <DragResize
          className={`${prefixClsTaskBar}-bar`}
          // onResize={handleResize}
          onResizeElement={handleResize}
          onResizeEnd={handleMoveEnd}
          defaultSize={{
            x: translateX,
            width,
          }}
          minWidth={30}
          grid={grid}
          type='move'
          scroller={store.chartElementRef.current || undefined}
          onAutoScroll={handleAutoScroll}
          reachEdge={reachEdge}
          onBeforeResize={handleBeforeResize('move')}
        > */}
          {/* {renderBar ? (
            renderBar(data, {
              width: width + 1,
              height: barHeight + 1,
            })
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width={width + 1}
              height={barHeight + 1}
              viewBox={`0 0 ${width + 1} ${barHeight + 1}`}
            >
              <path
                fill={record.backgroundColor || (getBarColor && getBarColor(record).backgroundColor) || themeColor[0]}
                stroke={record.borderColor || (getBarColor && getBarColor(record).borderColor) || themeColor[1]}
                d={`
              M${width - 2},0.5
              l-${width - 5},0
              c-0.41421,0 -0.78921,0.16789 -1.06066,0.43934
              c-0.27145,0.27145 -0.43934,0.64645 -0.43934,1.06066
              l0,5.3

              c0.03256,0.38255 0.20896,0.724 0.47457,0.97045
              c0.26763,0.24834 0.62607,0.40013 1.01995,0.40013
              l4,0

              l${width - 12},0

              l4,0
              c0.41421,0 0.78921,-0.16789 1.06066,-0.43934
              c0.27145,-0.27145 0.43934,-0.64645 0.43934,-1.06066

              l0,-5.3
              c-0.03256,-0.38255 -0.20896,-0.724 -0.47457,-0.97045
              c-0.26763,-0.24834 -0.62607,-0.40013 -1.01995,-0.40013z
            `}
              />
            </svg>
          )} */}
        <HoverToolTip
          position={"bottom"}
          tailCenter={true}
          style={{
            left: `${tooltip?.left}px`,
            top: `${tooltip?.top}px`,
          }}
          isShow={!!tooltip.visible}
          value={tooltip.text}
        />
        <div
          onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (
              record.barType === Gantt.BarType.Project ||
              record.barType === Gantt.BarType.Milestone
            ) {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.clientX - rect.left - 12;
              setTooltip({
                left: x,
                top: 40,
                visible: true,
                text: taskBarSettings.label?.text || ""
              });
              setZIndex(1000);
            }
          }}
          onMouseLeave={() => {
            setTooltip({...tooltip, visible: false });
            setZIndex(1);
          }}
          className={`${prefixClsTaskBar}-bar`}>
          {renderBar()}
        </div>
        {/* </DragResize> */}
      </div>
      {/* barの上の日数の表示 コメントアウトをやめるとbarの上に日数が表示される */}
      {(allowDrag || disabled || alwaysShowTaskBar) &&
        (record.barType === Gantt.BarType.Project ||
          record.barType === Gantt.BarType.Milestone ||
          record.barType === Gantt.BarType.Task) && (
          // <div className={`${prefixClsTaskBar}-label`} style={{ left: width - days.length / 2 - 10 }}>
          <div
            className={`${prefixClsTaskBar}-label`}
            style={{ left: width - days.length * 8 }}
          >
            {days}
          </div>
        )}

      {/* barの横の日時の表示 コメントアウトをやめるとbarの横に日時が表示される */}
      {/* {(stepGesture === 'moving' || allowDrag || alwaysShowTaskBar) && (
        <>
          <div className={`${prefixClsTaskBar}-date-text`} style={{ left: width + 16 }}>
            {renderRightText ? renderRightText(data) : dateTextFormat(translateX + width + moveCalc)}
          </div>
          <div className={`${prefixClsTaskBar}-date-text`} style={{ right: width + 16 }}>
            {renderLeftText ? renderLeftText(data) : dateTextFormat(translateX)}
          </div>
        </>
      )} */}
    </div>
  );
};

export default observer(TaskBar);
