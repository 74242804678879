import { register } from "src/excalidraw/actions/register";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";
import { isGraphElement, isOriginTextElement } from "src/excalidraw/extensions/element/typeChecks";
import { getBoundTextElement, getContainerElement } from "src/excalidraw/element/textElement";

export const actionToggleCriticalPathMode = register({
  name: "criticalPathMode",
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.criticalPathModeEnabled,
  },
  perform(elements, appState, _, app) {
    elements.forEach((element) => {
      const elementsMap = app.scene.getNonDeletedElementsMap();
      if (isGraphElement(element) && element.isCriticalPath) {
        ShapeCache.delete(element);

        const boundTextElement = getBoundTextElement(element, elementsMap);
        if (boundTextElement) {
          ShapeCache.delete(boundTextElement);
        }
      }
    });

    return {
      appState: {
        ...appState,
        criticalPathModeEnabled: !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.criticalPathModeEnabled,
  contextItemLabel: "buttons.criticalPathMode",
});

