// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  DocumentData,
  DocumentReference,
  Query,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { isDevelopment, isLocal } from "src/utils/envUtils";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
};

if (isDevelopment()) {
  console.log(firebaseConfig);
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);
functions.region = 'asia-northeast1';
export const inQueryLimit = 10;

export type QueryType = Query<DocumentData>;
export type DocumentRefType = DocumentReference<DocumentData>;

if (isLocal()) {
  const localhost = "127.0.0.1";
  connectAuthEmulator(auth, `http://${localhost}:9099/`);
  connectFirestoreEmulator(db, localhost, 8080);
  connectStorageEmulator(storage, localhost, 9199);
  connectFunctionsEmulator(functions, localhost, 5001);
  console.log("[Log] Running in local");
}
