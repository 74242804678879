

export enum DocumentUpdateState {
  SAVE,
  DELETE,
  NONE
};

export const FloatLimit = {
  alarm: 5,
  warning: 10,
} as const;
