import clsx from "clsx";
import { ReactNode } from "react";
import "./DialogActionButton.scss";
import Spinner from "../../components/Spinner";

interface DialogActionButtonExProps {
  label: string;
  children?: ReactNode;
  actionType?: "primary" | "danger";
  isLoading?: boolean;
  disabled?: boolean;
  customClass?: string;
}

const DialogActionButtonEx = ({
  label,
  onClick,
  className,
  children,
  actionType,
  type = "button",
  isLoading,
  disabled,
  customClass = "",
  ...rest
}: DialogActionButtonExProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const cs = actionType ? `Dialog__action-button--${actionType}` : "";

  return (
    <button
      className={clsx("Dialog__action-button", cs, className) + " " + customClass}
      type={type}
      aria-label={label}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children && (
        <div style={isLoading ? { visibility: "hidden" } : {}}>{children}</div>
      )}
      <div style={isLoading ? { visibility: "hidden" } : {}}>{label}</div>
      {isLoading && (
        <div style={{ position: "absolute", inset: 0 }}>
          <Spinner />
        </div>
      )}
    </button>
  );
};

export default DialogActionButtonEx;
