import { isSomeElementSelected } from "src/excalidraw/scene";
import { KEYS } from "src/excalidraw/keys";
import { ToolButton } from "../components/ToolButton";
import { t } from "src/excalidraw/i18n";
import { register } from "./register";
import { getNonDeletedElements } from "../element";
import { ExcalidrawElement } from "../element/types";
import { AppState } from "src/excalidraw/types";
import { mutateElement, newElementWith } from "../element/mutateElement";
import { getElementsInGroup } from "src/excalidraw/groups";
import { LinearElementEditor } from "../element/linearElementEditor";
import { fixBindingsAfterDeletion } from "../element/binding";
// CHANGED:ADD 2022-11-16 #
import { fixBindingsAfterDeletionEx, updateBoundElementsEx } from "src/excalidraw/extensions/element/binding"; // from extensions
import { isBoundToContainer, isTextElement } from "../element/typeChecks";
import { arrayToMap, updateActiveTool } from "src/excalidraw/utils";
import { TrashIcon } from "../components/icons";
// CHANGED:ADD 2022-11-30 #214
import CriticalPath from "src/excalidraw/extensions/criticalPath"; // from extensions
// CHANGED:UPDATE 2023-2-15 #707
import { isCommentElement, isCommentableElement, isJobElement, isMilestoneElement, isNodeElement } from "src/excalidraw/extensions/element/typeChecks";
import Job from "src/excalidraw/extensions/job";
import { CANVAS_HEADER_HEIGHT, WARNING_TOAST_DEFAULT_DURATION } from "src/excalidraw/constants";
import { getBoundTextElement, handleBindTextResize, redrawTextBoundingBox } from "src/excalidraw/element/textElement";
import Calendar from "src/excalidraw/extensions/calendar";
import { ExcalidrawJobElement } from "src/excalidraw/extensions/element/types";

const deleteSelectedElements = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  // CHANGED:ADD 2023-12-28 1138
  const deletingCommentElementIds: string[] = [];
  const newElements = elements.map((el) => {
    if (isCommentableElement(el) && appState.selectedElementIds[el.id]) {
      deletingCommentElementIds.push(el.id);
    }
    // CHANGED: ADD 2024-01-25 #1138-3 コメントエレメントの単独消去禁止
    if (appState.selectedElementIds[el.id] && !isCommentElement(el)) {
      return newElementWith(el, { isDeleted: true });
    }
    if (
      isBoundToContainer(el) &&
      appState.selectedElementIds[el.containerId]
    ) {
      return newElementWith(el, { isDeleted: true });
    }
    return el;
  });

  return {
    elements: newElements.map((el) => {
      if (isCommentElement(el) && deletingCommentElementIds.includes(el.commentElementId)) {
        return newElementWith(el, { isDeleted: true });
      }
      return el;
    }),
    appState: {
      ...appState,
      selectedElementIds: {},
    },
  };
};

const handleGroupEditingState = (
  appState: AppState,
  elements: readonly ExcalidrawElement[],
): AppState => {
  if (appState.editingGroupId) {
    const siblingElements = getElementsInGroup(
      getNonDeletedElements(elements),
      appState.editingGroupId!,
    );
    if (siblingElements.length) {
      return {
        ...appState,
        selectedElementIds: { [siblingElements[0].id]: true },
      };
    }
  }
  return appState;
};

export const actionDeleteSelected = register({
  name: "deleteSelectedElements",
  trackEvent: { category: "element", action: "delete" },
  perform: (elements, appState, _, app) => {
    if (appState.editingLinearElement) {
      const {
        elementId,
        selectedPointsIndices,
        startBindingElement,
        endBindingElement,
      } = appState.editingLinearElement;
      const elementsMap = app.scene.getNonDeletedElementsMap();
      const element = LinearElementEditor.getElement(elementId, elementsMap);
      if (!element) {
        return false;
      }
      // case: no point selected → do nothing, as deleting the whole element
      // is most likely a mistake, where you wanted to delete a specific point
      // but failed to select it (or you thought it's selected, while it was
      // only in a hover state)
      if (selectedPointsIndices == null) {
        return false;
      }

      // case: deleting last remaining point
      if (element.points.length < 2) {
        const nextElements = elements.map((el) => {
          if (el.id === element.id) {
            return newElementWith(el, { isDeleted: true });
          }
          return el;
        });
        const nextAppState = handleGroupEditingState(appState, nextElements);

        return {
          elements: CriticalPath.calcCriticalPath(
            nextElements,
            arrayToMap(nextElements),
            appState,
          ), // CHANGED:ADD 2023-1-23 #455
          appState: {
            ...nextAppState,
            editingLinearElement: null,
          },
          commitToHistory: false,
        };
      }

      // We cannot do this inside `movePoint` because it is also called
      // when deleting the uncommitted point (which hasn't caused any binding)
      const binding = {
        startBindingElement: selectedPointsIndices?.includes(0)
          ? null
          : startBindingElement,
        endBindingElement: selectedPointsIndices?.includes(
          element.points.length - 1,
        )
          ? null
          : endBindingElement,
      };

      LinearElementEditor.deletePoints(element, selectedPointsIndices);

      return {
        elements: CriticalPath.calcCriticalPath(
          elements,
          arrayToMap(elements),
          appState,
        ), // CHANGED:ADD 2023-1-23 #455
        appState: {
          ...appState,
          editingLinearElement: {
            ...appState.editingLinearElement,
            ...binding,
            selectedPointsIndices:
              selectedPointsIndices?.[0] > 0
                ? [selectedPointsIndices[0] - 1]
                : [0],
          },
        },
        commitToHistory: true,
      };
    }

    // CHANGED:ADD 2023-2-3 #568
    const deletedJobElements = elements.filter(
      (el) => appState.selectedElementIds[el.id] && isJobElement(el),
    ) as ExcalidrawJobElement[];

    if (deletedJobElements.some((element) => element.isCompressed)) {
      return {
        commitToHistory: false,
        appState: {
          ...appState,
          toast: {
            message: t("toast.warning.deleteOnCompressedJobElement"),
            duration: WARNING_TOAST_DEFAULT_DURATION,
          },
        },
      };
    }

    const elementsMap = app.scene.getNonDeletedElementsMap();
    deletedJobElements
      .sort((a, b) => b.y - a.y)
      .forEach((element) => {
      elements
        .filter((el) => !el.isDeleted && !(isTextElement(el) && el.containerId))
        .forEach((el) => {
          // CHANGED:ADD 2023-2-15 #707
          const offsetY = isMilestoneElement(el) ? - el.height / 2 : 0;
          if (
            // CHANGED:UPDATE 2023-2-15 #707
            element.y < el.y &&
            element.y + element.height >= el.y + el.height + offsetY
          ) {
            appState = {
              ...appState,
              selectedElementIds: {
                ...appState.selectedElementIds,
                [el.id]: true,
              },
            };
          } else if (element.y + element.height < el.y) {
            mutateElement(el, {
              y: el.y - element.height,
            });

            if (isNodeElement(el)) {
              const calendar = new Calendar(
                appState.gridSize,
                appState.projectStartDate,
                appState.holidays,
              );

              updateBoundElementsEx(
                el,
                elementsMap,
                appState,
                calendar,
              );
            }

            const boundText = getBoundTextElement(el, elementsMap);
            if (boundText) {
              redrawTextBoundingBox(boundText, el, elementsMap);
            }
          }
        });
    });

    let { elements: nextElements, appState: nextAppState } =
      deleteSelectedElements(elements, appState);
    fixBindingsAfterDeletion(
      nextElements,
      elements.filter(({ id }) => appState.selectedElementIds[id]),
    );
    // CHANGED:ADD 2022-11-16 #114
    fixBindingsAfterDeletionEx(
      nextElements,
      elements.filter(({ id }) => appState.selectedElementIds[id]),
    );

    nextAppState = handleGroupEditingState(nextAppState, nextElements);

    // CHANGED:ADD 2023-2-3 #561
    if (deletedJobElements.length > 0) {
      const elementsMap = arrayToMap(nextElements);
      const jobElements = Job.getJobElements(nextElements);
      const jobsHeight = Job.getJobElementsHeight(jobElements);
      jobElements.sort((a, b) => a.y - b.y);

      let newY = CANVAS_HEADER_HEIGHT;
      jobElements.forEach((element) => {
        mutateElement(element, {
          y: newY,
        });

        const boundText = getBoundTextElement(element, elementsMap);
        if (boundText) {
          handleBindTextResize(element, elementsMap, false);
        }

        newY += element.height;
      });

      return {
        elements: CriticalPath.calcCriticalPath(
          nextElements,
          arrayToMap(nextElements),
          appState,
        ), // CHANGED:ADD 2023-1-23 #455
        appState: {
          ...nextAppState,
          activeTool: updateActiveTool(appState, { type: "selection" }),
          multiElement: null,
          jobsHeight,
        },
        commitToHistory: true,
        updatedJobElements: true, // CHANGED:ADD 2023-2-10 #638
        updatedMilestoneElements: true, // CHANGED:ADD 2023-2-11 #671
      }
    } else {
      return {
        elements: CriticalPath.calcCriticalPath(
          nextElements,
          arrayToMap(nextElements),
          appState,
        ), // CHANGED:ADD 2023-1-23 #455
        appState: {
          ...nextAppState,
          activeTool: updateActiveTool(appState, { type: "selection" }),
          multiElement: null,
        },
        commitToHistory: isSomeElementSelected(
          getNonDeletedElements(elements),
          appState,
        ),
        updatedMilestoneElements: true, // CHANGED:ADD 2023-2-11 #671
      }
    };
  },
  contextItemLabel: "labels.delete",
  keyTest: (event, appState, elements) =>
    (event.key === KEYS.BACKSPACE || event.key === KEYS.DELETE) &&
    !event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={TrashIcon}
      title={t("labels.delete")}
      aria-label={t("labels.delete")}
      onClick={() => updateData(null)}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});
