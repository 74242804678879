import {
  NonDeleted,
  ExcalidrawElement,
  ExcalidrawTextElementWithContainer, //CHANGED:ADD 2023/01/17 #390
  ElementsMap,
} from "../../element/types";
import {
  ExcalidrawLinkElement,
  PointBindingEx,
  ExcalidrawBindableElementEx,
  ExcalidrawNodeElement,
} from "./types"; // from extensions
import {
  distance2d,
  rotate,
  isPathALoop,
  getGridPoint,
  rotatePoint,
  arePointsEqual,
} from "../../math";
import { getBezierCurveLengthEx } from "../math"; // from extensions
import { getElementAbsoluteCoords, getLockedLinearCursorAlignSize } from "../../element";
import {
  getCurvePathOps,
  getElementPointsCoords,
  getMinMaxXYFromCurvePathOps,
} from "../../element/bounds";
import { Point, AppState, AppClassProperties } from "src/excalidraw/types";
import { mutateElement } from "../../element/mutateElement";
import History from "../../history";

import Scene from "src/excalidraw/scene/Scene";
import {
  bindOrUnbindLinkElement,
  getHoveredElementForBindingEx,
  isBindingEnabledEx,
} from "./binding"; // from extensions
import { tupleToCoors } from "src/excalidraw/utils";
import { isBindableElementEx, isBindingElementEx, isLinkElement, isTaskElement } from "./typeChecks"; // from extensions
import { shouldRotateWithDiscreteAngle } from "src/excalidraw/keys";
import Calendar from "../calendar";
import { getBoundTextElement, handleBindTextResize } from "src/excalidraw/element/textElement";
import { POINTER_DIRECTION, TEXT_ALIGN } from "src/excalidraw/constants";
import { Mutable } from "src/excalidraw/utility-types";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";

export class LinkElementEditor {
  public readonly elementId: ExcalidrawElement["id"] & {
    _brand: "excalidrawLinkElementId";
  };
  /** indices */
  public readonly selectedPointsIndices: readonly number[] | null;

  public readonly pointerDownState: Readonly<{
    prevSelectedPointsIndices: readonly number[] | null;
    /** index */
    lastClickedPoint: number;
    origin: Readonly<{ x: number; y: number }> | null;
  }>;

  /** whether you're dragging a point */
  public readonly isDragging: boolean;
  public readonly lastUncommittedPoint: Point | null;
  public readonly pointerOffset: Readonly<{ x: number; y: number }>;
  public readonly startBindingElement:
    | ExcalidrawBindableElementEx
    | null
    | "keep";
  public readonly endBindingElement:
    | ExcalidrawBindableElementEx
    | null
    | "keep";
  public readonly hoverPointIndex: number;

  // CHANGED:ADD 2024-3-9 #1775
  public readonly dependencyElementIds: { [id: string]: boolean } = {};

  constructor(element: NonDeleted<ExcalidrawLinkElement>, scene: Scene, appState: AppState) {
    this.elementId = element.id as string & {
      _brand: "excalidrawLinkElementId";
    };
    LinkElementEditor.normalizePoints(element);

    this.selectedPointsIndices = null;
    this.lastUncommittedPoint = null;
    this.isDragging = false;
    this.pointerOffset = { x: 0, y: 0 };
    this.startBindingElement = "keep";
    this.endBindingElement = "keep";
    this.pointerDownState = {
      prevSelectedPointsIndices: null,
      lastClickedPoint: -1,
      origin: null,
    };
    this.hoverPointIndex = -1;

    // CHANGED:ADD 2024-3-9 #1775
    let dependencyElementIds: { [id: string]: boolean } = {};
    const queue: ExcalidrawNodeElement[] = [];
    const discovered: Set<ExcalidrawElement["id"]> = new Set();

    if (appState.emphasizedModeEnabled || appState.transparentModeEnabled) {
      const el = scene.getElement(element.startBinding?.elementId || "");

      if (isTaskElement(el)) {
        discovered.add(el.id);
        queue.push(el);

        while (queue.length > 0) {
          const v = queue.shift() as ExcalidrawNodeElement;

          v.prevDependencies?.forEach((e) => {
            if (!discovered.has(e)) {
              discovered.add(e);

              const u = scene.getNonDeletedElement(e);
              if (u && isBindableElementEx(u, true)) {
                dependencyElementIds = {
                  ...dependencyElementIds,
                  [u.id]: true,
                };

                const linkElement = scene.getNonDeletedElements().find(
                  (element) =>
                    isLinkElement(element) &&
                    element.startBinding?.elementId === u.id &&
                    element.endBinding?.elementId === v.id,
                );

                if (linkElement && linkElement.id !== element.id) {
                  dependencyElementIds = {
                    ...dependencyElementIds,
                    [linkElement.id]: true,
                  };
                }
                queue.push(u);
              }
            }
          });
        }

        queue.push(el);

        while (queue.length > 0) {
          const v = queue.shift() as ExcalidrawNodeElement;

          v.nextDependencies?.forEach((e) => {
            if (!discovered.has(e)) {
              discovered.add(e);

              const u = scene.getNonDeletedElement(e);
              if (u && isBindableElementEx(u, true)) {
                dependencyElementIds = {
                  ...dependencyElementIds,
                  [u.id]: true,
                };

                const linkElement = scene.getNonDeletedElements().find(
                  (element) =>
                    isLinkElement(element) &&
                    element.startBinding?.elementId === v.id &&
                    element.endBinding?.elementId === u.id,
                );

                if (linkElement && linkElement.id !== element.id) {
                  dependencyElementIds = {
                    ...dependencyElementIds,
                    [linkElement.id]: true,
                  };
                }
                queue.push(u);
              }
            }
          });
        }

        dependencyElementIds = {
          ...dependencyElementIds,
          [el.id]: true,
        };
      }
    }

    this.dependencyElementIds = dependencyElementIds;
  }

  // ---------------------------------------------------------------------------
  // static methods
  // ---------------------------------------------------------------------------

  static POINT_HANDLE_SIZE = 10;
  /**
   * @param id the `elementId` from the instance of this class (so that we can
   *  statically guarantee this method returns an ExcalidrawLinkElement)
   */
  static getElement(id: InstanceType<typeof LinkElementEditor>["elementId"]) {
    const element = Scene.getScene(id)?.getNonDeletedElement(id);
    if (element) {
      return element as NonDeleted<ExcalidrawLinkElement>;
    }
    return null;
  }

  /** @returns whether point was dragged */
  static handlePointDragging(
    event: PointerEvent,
    appState: AppState,
    scenePointerX: number,
    scenePointerY: number,
    maybeSuggestBinding: (
      element: NonDeleted<ExcalidrawLinkElement>,
      pointSceneCoords: { x: number; y: number }[],
      startOrEnd: "start" | "end", // CHANGED:ADD 2022-11-11 #116
    ) => void,
    linkElementEditor: LinkElementEditor,
    calendar: Calendar,
    scene: Scene,
    elementsMap: ElementsMap,
  ): boolean {
    if (!linkElementEditor) {
      return false;
    }
    // CHANGED:UPDATE 2022-11-21 #125
    // const { selectedPointsIndices, elementId } = linkElementEditor;
    const { elementId } = linkElementEditor;
    const element = LinkElementEditor.getElement(elementId);
    if (!element) {
      return false;
    }
    let { selectedPointsIndices } = linkElementEditor;
    let isMidPointsMove = false;
    let gridSize = appState.gridSize;
    if (
      selectedPointsIndices &&
      selectedPointsIndices.length === 1 &&
      (selectedPointsIndices[0] === 1 || selectedPointsIndices[0] === 2)
    ) {
      selectedPointsIndices = [1, 2];
      isMidPointsMove = true;
      gridSize = null;
    }

    // point that's being dragged (out of all selected points)
    const draggingPoint = element.points[
      linkElementEditor.pointerDownState.lastClickedPoint
    ] as [number, number] | undefined;

    if (selectedPointsIndices && draggingPoint) {
      if (
        shouldRotateWithDiscreteAngle(event) &&
        selectedPointsIndices.length === 1 &&
        element.points.length > 1
      ) {
        const selectedIndex = selectedPointsIndices[0];
        const referencePoint =
          element.points[selectedIndex === 0 ? 1 : selectedIndex - 1];

        const [width, height] = LinkElementEditor._getShiftLockedDelta(
          element,
          elementsMap,
          referencePoint,
          [scenePointerX, scenePointerY],
          appState.gridSize,
        );

        LinkElementEditor.movePoints(element, [
          {
            index: selectedIndex,
            point: [width + referencePoint[0], height + referencePoint[1]],
            isDragging:
              selectedIndex ===
              linkElementEditor.pointerDownState.lastClickedPoint,
          },
        ]);
      } else {
        // CHANGED:UPDATE 2022-11-22 #125
        // const newDraggingPointPosition = LinkElementEditor.createPointAt(
        //   element,
        //   scenePointerX - linkElementEditor.pointerOffset.x,
        //   scenePointerY - linkElementEditor.pointerOffset.y,
        //   appState.gridSize,
        // );

        // const deltaX = newDraggingPointPosition[0] - draggingPoint[0];
        // const deltaY = newDraggingPointPosition[1] - draggingPoint[1];

        // LinkElementEditor.movePoints(
        //   element,
        //   selectedPointsIndices.map((pointIndex) => {
        //     const newPointPosition =
        //       pointIndex ===
        //         linkElementEditor.pointerDownState.lastClickedPoint
        //         ? LinkElementEditor.createPointAt(
        //           element,
        //           scenePointerX - linkElementEditor.pointerOffset.x,
        //           scenePointerY - linkElementEditor.pointerOffset.y,
        //           appState.gridSize,
        //         )
        //         : ([
        //           element.points[pointIndex][0] + deltaX,
        //           element.points[pointIndex][1] + deltaY,
        //         ] as const);
        //     return {
        //       index: pointIndex,
        //       point: newPointPosition,
        //       isDragging:
        //         pointIndex ===
        //         linkElementEditor.pointerDownState.lastClickedPoint,
        //     };
        //   }),
        // );

        //CHANGED:UPDATE 2024-03-11 #1749
        if (isMidPointsMove) {
          // midPointを並行移動する処理
          const newDraggingPointPosition = LinkElementEditor.createPointAt(
            element,
            elementsMap,
            scenePointerX - linkElementEditor.pointerOffset.x,
            scenePointerY - linkElementEditor.pointerOffset.y,
            gridSize,
          );

          if (element.pointerDirection === POINTER_DIRECTION.VERTICAL) {
            let deltaY = newDraggingPointPosition[1] - draggingPoint[1];

            LinkElementEditor.movePoints(
              element,
              selectedPointsIndices.map((pointIndex) => {
                const newPointPosition = [
                  element.points[pointIndex][0],
                  element.points[pointIndex][1] + deltaY,
                ] as const;
                return {
                  index: pointIndex,
                  point: newPointPosition,
                  isDragging:
                    pointIndex ===
                    linkElementEditor.pointerDownState.lastClickedPoint,
                };
              }),
            );
          } else {
            let deltaX = newDraggingPointPosition[0] - draggingPoint[0];
            if (element.points[1][0] + deltaX < 0) {
              deltaX = element.points[1][0] * -1;
            } else if (
              element.points[1][0] + deltaX >
              element.points[element.points.length - 1][0]
            ) {
              deltaX =
                element.points[element.points.length - 1][0] -
                element.points[2][0];
            }
            LinkElementEditor.movePoints(
              element,
              selectedPointsIndices.map((pointIndex) => {
                const newPointPosition = [
                  element.points[pointIndex][0] + deltaX,
                  element.points[pointIndex][1],
                ] as const;
                return {
                  index: pointIndex,
                  point: newPointPosition,
                  isDragging:
                    pointIndex ===
                    linkElementEditor.pointerDownState.lastClickedPoint,
                };
              }),
            );
          }
        } else {
          // 始点または終点を操作する処理
          const newEdgePoint = LinkElementEditor.createPointAt(
            element,
            elementsMap,
            scenePointerX - linkElementEditor.pointerOffset.x,
            scenePointerY - linkElementEditor.pointerOffset.y,
            gridSize,
          );
          const pointerDownIndex =
            linkElementEditor.pointerDownState.lastClickedPoint;
          const diff =
            pointerDownIndex === 0
              ? element.pointerDirection === POINTER_DIRECTION.VERTICAL
                ? newEdgePoint[1]
                : newEdgePoint[0]
              : 0;
          const elementPointsMaxIndex = element.points.length - 1;
          if (
            (pointerDownIndex === 0 &&
              newEdgePoint[0] <= element.points[elementPointsMaxIndex][0]) ||
            (pointerDownIndex === elementPointsMaxIndex &&
              newEdgePoint[0] >= element.points[0][0])
          ) {
            const midPointIndex =
              linkElementEditor.pointerDownState.lastClickedPoint === 0 ? 1 : 2;
            const cornerPointIndex = midPointIndex === 1 ? 2 : 1;

            let newMidPoint: Point =
              element.pointerDirection === POINTER_DIRECTION.VERTICAL
                ? [newEdgePoint[0], element.points[midPointIndex][1] - diff]
                : [element.points[midPointIndex][0] - diff, newEdgePoint[1]];
            const newMidPoints = [{ index: midPointIndex, point: newMidPoint }];

            if(element.pointerDirection !== POINTER_DIRECTION.VERTICAL) {
              const isMoveCornerPoint =
              (pointerDownIndex === 0 &&
                element.points[midPointIndex][0] - diff <= 0) ||
              (pointerDownIndex === elementPointsMaxIndex &&
                newEdgePoint[0] - newMidPoint[0] <= 0)
                ? true
                : false;

              if (isMoveCornerPoint) {
                if (pointerDownIndex === 0) {
                  newMidPoint = [0, 0];
                  newMidPoints[0].point = newMidPoint;

                  const newCornerPoint: Point = [
                    newMidPoint[0],
                    element.points[elementPointsMaxIndex][1],
                  ];
                  newMidPoints.push({
                    index: cornerPointIndex,
                    point: newCornerPoint,
                  });
                } else if (pointerDownIndex === elementPointsMaxIndex) {
                  newMidPoint = newEdgePoint;
                  newMidPoints[0].point = newMidPoint;

                  const newCornerPoint: Point = [
                    newMidPoint[0],
                    element.points[cornerPointIndex][1],
                  ];
                  newMidPoints.push({
                    index: cornerPointIndex,
                    point: newCornerPoint,
                  });
                }
              }
            }
            //CHANGED:ADD 2023/01/20 #390
            const startDate = calendar.getPointDate(element.x);
            const endDate = calendar.getPointDate(element.x + element.width);

            const newDuration = calendar.getDuration(startDate, endDate);
            if (element.duration !== newDuration) {
              mutateElement(element, {
                duration: newDuration,
              });

              // CHANGED:ADD 2024-04-15 #1917
              if (isLinkElement(element) && element.startBinding && element.endBinding) {
                const startBindingElement = elementsMap.get(element.startBinding.elementId);
                const endBindingElement = elementsMap.get(element.endBinding.elementId);

                if (isBindableElementEx(startBindingElement) && isBindableElementEx(endBindingElement)) {
                  mutateElement(startBindingElement, {
                    freeFloats: (startBindingElement.freeFloats?.filter(
                      (float) => float.id !== endBindingElement.id) || [])
                      .concat({
                        id: element.endBinding.elementId,
                        type: endBindingElement.type,
                        duration: newDuration,
                      })
                  });
                }
              }

              const boundTextElement = getBoundTextElement(element, elementsMap);
              if (boundTextElement) {
                const text = newDuration > 0 ? `(${newDuration})` : "";
                mutateElement(boundTextElement, {
                  text,
                });

                scene.updateBoundTextElement(
                  boundTextElement,
                  text,
                  boundTextElement.originalText,
                  boundTextElement.isDeleted,
                );

                handleBindTextResize(element, elementsMap, false);
              }
            }
            LinkElementEditor.movePoints(element, [
              {
                index: pointerDownIndex,
                point: newEdgePoint,
              },
              ...newMidPoints,
            ]);
          }
        }
      }

      // suggest bindings for first and last point if selected
      if (isBindingElementEx(element, false)) {
        const coords: { x: number; y: number }[] = [];

        const firstSelectedIndex = selectedPointsIndices[0];
        if (firstSelectedIndex === 0) {
          coords.push(
            tupleToCoors(
              LinkElementEditor.getPointGlobalCoordinates(
                element,
                element.points[0],
                elementsMap,
              ),
            ),
          );
        }

        const lastSelectedIndex =
          selectedPointsIndices[selectedPointsIndices.length - 1];
        if (lastSelectedIndex === element.points.length - 1) {
          coords.push(
            tupleToCoors(
              LinkElementEditor.getPointGlobalCoordinates(
                element,
                element.points[lastSelectedIndex],
                elementsMap,
              ),
            ),
          );
        }

        if (coords.length) {
          maybeSuggestBinding(
            element,
            coords,
            selectedPointsIndices[0] === 0 ? "start" : "end", // CHANGED:ADD 2022-11-11 #116
          );
        }
      }

      return true;
    }

    return false;
  }

  // CHANGED:ADD 2024-03-11 #1749
  static generateMidPoints(
    points: Point[],
    direction: AppState["currentItemPointerDirection"],
  ): Point[] {
    const midPointXOffset = points[0][0];
    if(direction === POINTER_DIRECTION.HORIZONTAL) {
      const midPoint1: Point = points[0];
      const midPoint2: Point = [midPointXOffset, points[1][1]];
      const midPoint = [midPoint1, midPoint2];
      return midPoint;  
    } else {
      const midPoint1: Point = [midPointXOffset, points[1][1]];
      const midPoint2: Point = points[1];
      const midPoint = [midPoint1, midPoint2];
      return midPoint;  
    }
  }

  static handlePointerUp(
    event: PointerEvent,
    editingLinkElement: LinkElementEditor,
    appState: AppState,
    app: AppClassProperties,
  ): LinkElementEditor {
    const elementsMap = app.scene.getNonDeletedElementsMap();

    const { elementId, selectedPointsIndices, isDragging, pointerDownState } =
      editingLinkElement;
    const element = LinkElementEditor.getElement(elementId);
    if (!element) {
      return editingLinkElement;
    }

    const bindings: Mutable<
      Partial<
        Pick<
          InstanceType<typeof LinkElementEditor>,
          "startBindingElement" | "endBindingElement"
        >
      >
    > = {};

    if (isDragging && selectedPointsIndices) {
      for (const selectedPoint of selectedPointsIndices) {
        if (
          selectedPoint === 0 ||
          selectedPoint === element.points.length - 1
        ) {
          if (isPathALoop(element.points, appState.zoom.value)) {
            LinkElementEditor.movePoints(element, [
              {
                index: selectedPoint,
                point:
                  selectedPoint === 0
                    ? element.points[element.points.length - 1]
                    : element.points[0],
              },
            ]);
          }

          const bindingElement = isBindingEnabledEx(appState)
            ? getHoveredElementForBindingEx(
              tupleToCoors(
                LinkElementEditor.getPointAtIndexGlobalCoordinates(
                  element,
                  selectedPoint!,
                  elementsMap,
                ),
              ),
              app,
              selectedPoint === 0 ? "start" : "end", // CHANGED:ADD 2022-11-11 #116
            )
            : null;

          bindings[
            selectedPoint === 0 ? "startBindingElement" : "endBindingElement"
          ] = element.layer === bindingElement?.layer ? bindingElement : null; // CHANGED:UPDATE 2024-10-5 #2114
        }
      }
    }

    return {
      ...editingLinkElement,
      ...bindings,
      // if clicking without previously dragging a point(s), and not holding
      // shift, deselect all points except the one clicked. If holding shift,
      // toggle the point.
      selectedPointsIndices:
        isDragging || event.shiftKey
          ? !isDragging &&
            event.shiftKey &&
            pointerDownState.prevSelectedPointsIndices?.includes(
              pointerDownState.lastClickedPoint,
            )
            ? selectedPointsIndices &&
            selectedPointsIndices.filter(
              (pointIndex) =>
                pointIndex !== pointerDownState.lastClickedPoint,
            )
            : selectedPointsIndices
          : selectedPointsIndices?.includes(pointerDownState.lastClickedPoint)
            ? [pointerDownState.lastClickedPoint]
            : selectedPointsIndices,
      isDragging: false,
      pointerOffset: { x: 0, y: 0 },
    };
  }

  static isSegmentTooShort(
    element: NonDeleted<ExcalidrawLinkElement>,
    startPoint: Point,
    endPoint: Point,
    zoom: AppState["zoom"],
  ) {
    let distance = distance2d(
      startPoint[0],
      startPoint[1],
      endPoint[0],
      endPoint[1],
    );
    if (element.points.length > 2 && element.roundness) {
      distance = getBezierCurveLengthEx(element, endPoint);
    }

    return distance * zoom.value < LinkElementEditor.POINT_HANDLE_SIZE * 4;
  }

  static handlePointerDown(
    event: React.PointerEvent<HTMLCanvasElement>,
    appState: AppState,
    history: History,
    scenePointer: { x: number; y: number },
    linkElementEditor: LinkElementEditor,
    app: AppClassProperties,
  ): {
    didAddPoint: boolean;
    hitElement: NonDeleted<ExcalidrawElement> | null;
    linkElementEditor: LinkElementEditor | null;
    } {
    const elementsMap = app.scene.getNonDeletedElementsMap();

    const ret: ReturnType<typeof LinkElementEditor["handlePointerDown"]> = {
      didAddPoint: false,
      hitElement: null,
      linkElementEditor: null,
    };

    if (!linkElementEditor) {
      return ret;
    }

    const { elementId } = linkElementEditor;
    const element = LinkElementEditor.getElement(elementId);

    if (!element) {
      return ret;
    }

    const clickedPointIndex = LinkElementEditor.getPointIndexUnderCursor(
      element,
      elementsMap,
      appState.zoom,
      scenePointer.x,
      scenePointer.y,
    );
    // if we clicked on a point, set the element as hitElement otherwise
    // it would get deselected if the point is outside the hitbox area
    if (clickedPointIndex >= 0) {
      ret.hitElement = element;
    } else {
      // You might be wandering why we are storing the binding elements on
      // LinkElementEditor and passing them in, instead of calculating them
      // from the end points of the `linkElement` - this is to allow disabling
      // binding (which needs to happen at the point the user finishes moving
      // the point).
      const { startBindingElement, endBindingElement } = linkElementEditor;
      if (isBindingEnabledEx(appState) && isBindingElementEx(element)) {
        bindOrUnbindLinkElement(
          element,
          startBindingElement,
          endBindingElement,
          elementsMap,
        );
      }
    }

    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const targetPoint =
      clickedPointIndex > -1 &&
      rotate(
        element.x + element.points[clickedPointIndex][0],
        element.y + element.points[clickedPointIndex][1],
        cx,
        cy,
        (element.angle || 0),
      );

    const nextSelectedPointsIndices =
      clickedPointIndex > -1 || event.shiftKey
        ? event.shiftKey ||
          linkElementEditor.selectedPointsIndices?.includes(clickedPointIndex)
          ? normalizeSelectedPoints([
            ...(linkElementEditor.selectedPointsIndices || []),
            clickedPointIndex,
          ])
          : [clickedPointIndex]
        : null;
    ret.linkElementEditor = {
      ...linkElementEditor,
      pointerDownState: {
        prevSelectedPointsIndices: linkElementEditor.selectedPointsIndices,
        lastClickedPoint: clickedPointIndex,
        origin: { x: scenePointer.x, y: scenePointer.y },
      },
      selectedPointsIndices: nextSelectedPointsIndices,
      pointerOffset: targetPoint
        ? {
          x: scenePointer.x - targetPoint[0],
          y: scenePointer.y - targetPoint[1],
        }
        : { x: 0, y: 0 },
    };

    return ret;
  }

  static arePointsEqual(point1: Point | null, point2: Point | null) {
    if (!point1 && !point2) {
      return true;
    }
    if (!point1 || !point2) {
      return false;
    }
    return arePointsEqual(point1, point2);
  }

  /** scene coords */
  static getPointGlobalCoordinates(
    element: NonDeleted<ExcalidrawLinkElement>,
    point: Point,
    elementsMap: ElementsMap,
  ) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;

    let { x, y } = element;
    [x, y] = rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0));
    return [x, y] as const;
  }

  /** scene coords */
  //CHANGED:UPDATE 2024-03-11 #1749
  static getPointsGlobalCoordinates(
    element: NonDeleted<ExcalidrawLinkElement>,
    elementsMap: ElementsMap,
  ): Point[] {
    // CHANGED:ADD 2024-03-25 #1852
    if (element.points.length !== 4) {
      // 誤った操作の対策用
      // 基本的にリンクエレメントの数は4つになるので
      return [[0, 0]];
    }
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    // CHANGED:UPDATE 2022-11-16 #125
    // return element.points.map((point) => {
    const midPoints = [...element.points.slice(1, 3)];
    const _midPoint: Point =
      element.pointerDirection === POINTER_DIRECTION.VERTICAL
        ? [(midPoints[1][0] - midPoints[0][0]) / 2, midPoints[0][1]]
        : [midPoints[0][0], (midPoints[1][1] - midPoints[0][1]) / 2];

    const _points: Point[] = [...element.points.slice(0, 1), _midPoint, ...element.points.slice(-1)];
    // const _points: Point[] = [...element.points.slice(0,1), ...element.points.slice(-1)];
    return _points.map((point) => {
      let { x, y } = element;
      [x, y] = rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0));
      return [x, y] as const;
    });
  }

  static getPointAtIndexGlobalCoordinates(
    element: NonDeleted<ExcalidrawLinkElement>,
    indexMaybeFromEnd: number, // -1 for last element
    elementsMap: ElementsMap,
  ): Point {
    const index =
      indexMaybeFromEnd < 0
        ? element.points.length + indexMaybeFromEnd
        : indexMaybeFromEnd;
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;

    // CHANGED:UPDATE 2022-11-21 #125
    // const point = element.points[index];
    let point = element.points[index];
    if (index == 1 && element.points.length > 3) {
      const midPoint1 = element.points[1];
      const midPoint2 = element.points[2];

      const midPoint: Point =
        element.pointerDirection === POINTER_DIRECTION.VERTICAL
          ? [(midPoint2[0] - midPoint1[0]) / 2, midPoint2[1]]
          : [midPoint1[0], (midPoint2[1] - midPoint1[1]) / 2];
      point = midPoint;
    }
    const { x, y } = element;
    return point
      ? rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0))
      : rotate(x, y, cx, cy, (element.angle || 0));
  }

  static pointFromAbsoluteCoords(
    element: NonDeleted<ExcalidrawLinkElement>,
    absoluteCoords: Point,
    elementsMap: ElementsMap,
  ): Point {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const [x, y] = rotate(
      absoluteCoords[0],
      absoluteCoords[1],
      cx,
      cy,
      -(element.angle || 0),
    );
    return [x - element.x, y - element.y];
  }

  static getPointIndexUnderCursor(
    element: NonDeleted<ExcalidrawLinkElement>,
    elementsMap: ElementsMap,
    zoom: AppState["zoom"],
    x: number,
    y: number,
  ) {
    const pointHandles =
      LinkElementEditor.getPointsGlobalCoordinates(element, elementsMap);
    let idx = pointHandles.length;
    // loop from right to left because points on the right are rendered over
    // points on the left, thus should take precedence when clicking, if they
    // overlap
    while (--idx > -1) {
      const point = pointHandles[idx];
      if (
        distance2d(x, y, point[0], point[1]) * zoom.value <
        // +1px to account for outline stroke
        LinkElementEditor.POINT_HANDLE_SIZE + 1
      ) {
        // CHANGED:UPDATE 2022-11-16 #125
        // return idx;
        return idx > 1 ? element.points.length - 1 : idx;
      }
    }
    return -1;
  }

  static createPointAt(
    element: NonDeleted<ExcalidrawLinkElement>,
    elementsMap: ElementsMap,
    scenePointerX: number,
    scenePointerY: number,
    gridSize: number | null,
  ): Point {
    const pointerOnGrid = getGridPoint(scenePointerX, scenePointerY, gridSize);
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const [rotatedX, rotatedY] = rotate(
      pointerOnGrid[0],
      pointerOnGrid[1],
      cx,
      cy,
      -(element.angle || 0),
    );

    return [rotatedX - element.x, rotatedY - element.y];
  }

  /**
   * Normalizes line points so that the start point is at [0,0]. This is
   * expected in various parts of the codebase. Also returns new x/y to account
   * for the potential normalization.
   */
  static getNormalizedPoints(element: ExcalidrawLinkElement) {
    const { points } = element;

    const offsetX = points[0][0];
    const offsetY = points[0][1];

    return {
      points: points.map((point, _idx) => {
        return [point[0] - offsetX, point[1] - offsetY] as const;
      }),
      x: element.x + offsetX,
      y: element.y + offsetY,
    };
  }

  // element-mutating methods
  // ---------------------------------------------------------------------------

  static normalizePoints(element: NonDeleted<ExcalidrawLinkElement>) {
    mutateElement(element, LinkElementEditor.getNormalizedPoints(element));
  }

  static movePoints(
    element: NonDeleted<ExcalidrawLinkElement>,
    targetPoints: { index: number; point: Point; isDragging?: boolean }[],
    otherUpdates?: {
      startBinding?: PointBindingEx;
      endBinding?: PointBindingEx;
      isVisible?: boolean;
    },
  ) {
    const { points, pointerDirection } = element;

    // in case we're moving start point, instead of modifying its position
    // which would break the invariant of it being at [0,0], we move
    // all the other points in the opposite direction by delta to
    // offset it. We do the same with actual element.x/y position, so
    // this hacks are completely transparent to the user.
    let offsetX = 0;
    let offsetY = 0;

    const selectedOriginPoint = targetPoints.find(({ index }) => index === 0);

    if (selectedOriginPoint) {
      offsetX =
        selectedOriginPoint.point[0] + points[selectedOriginPoint.index][0];
      offsetY =
        selectedOriginPoint.point[1] + points[selectedOriginPoint.index][1];
    }

    const nextPoints = points.map((point, idx) => {
      const selectedPointData = targetPoints.find((p) => p.index === idx);
      if (selectedPointData) {
        //CHANGED:UPDATE 2022-11-16 #90
        // if (selectedOriginPoint) {
        //   return point;
        // }
        if (selectedOriginPoint && idx === 1) {
          return (
            pointerDirection === POINTER_DIRECTION.VERTICAL
              ? [point[0], selectedPointData.point[1]]
              : [selectedPointData.point[0], point[1]]
          ) as Point;
        } else if (selectedOriginPoint && idx === 2) {
          return (
            pointerDirection === POINTER_DIRECTION.VERTICAL
              ? [point[0] - offsetX, selectedPointData.point[1]]
              : [selectedPointData.point[0], point[1] - offsetY]
          ) as Point;
        } else if (selectedOriginPoint) {
          return point;
        }

        const deltaX =
          selectedPointData.point[0] - points[selectedPointData.index][0];
        const deltaY =
          selectedPointData.point[1] - points[selectedPointData.index][1];

        return [point[0] + deltaX, point[1] + deltaY] as const;
      }
      return offsetX || offsetY
        ? ([point[0] - offsetX, point[1] - offsetY] as const)
        : point;
    });

    LinkElementEditor._updatePoints(
      element,
      nextPoints,
      offsetX,
      offsetY,
      otherUpdates,
    );
  }

  private static _updatePoints(
    element: NonDeleted<ExcalidrawLinkElement>,
    nextPoints: readonly Point[],
    offsetX: number,
    offsetY: number,
    otherUpdates?: {
      startBinding?: PointBindingEx;
      endBinding?: PointBindingEx;
      isVisible?: boolean; //CHANGED: ADD 2023-03-03 #740
    },
  ) {
    const nextCoords = getElementPointsCoords(element, nextPoints);
    const prevCoords = getElementPointsCoords(element, element.points);
    const nextCenterX = (nextCoords[0] + nextCoords[2]) / 2;
    const nextCenterY = (nextCoords[1] + nextCoords[3]) / 2;
    const prevCenterX = (prevCoords[0] + prevCoords[2]) / 2;
    const prevCenterY = (prevCoords[1] + prevCoords[3]) / 2;
    const dX = prevCenterX - nextCenterX;
    const dY = prevCenterY - nextCenterY;
    const rotated = rotate(offsetX, offsetY, dX, dY, (element.angle || 0));

    mutateElement(element, {
      ...otherUpdates,
      points: nextPoints,
      x: element.x + rotated[0],
      y: element.y + rotated[1],
    });
  }

  private static _getShiftLockedDelta(
    element: NonDeleted<ExcalidrawLinkElement>,
    elementsMap: ElementsMap,
    referencePoint: Point,
    scenePointer: Point,
    gridSize: number | null,
  ) {
    const referencePointCoords = LinkElementEditor.getPointGlobalCoordinates(
      element,
      referencePoint,
      elementsMap,
    );

    const [gridX, gridY] = getGridPoint(
      scenePointer[0],
      scenePointer[1],
      gridSize,
    );

    const { width, height } = getLockedLinearCursorAlignSize(
      referencePointCoords[0],
      referencePointCoords[1],
      gridX,
      gridY,
    );

    return rotatePoint([width, height], [0, 0], -(element.angle || 0));
  }

  static getElementAbsoluteCoords = (
    element: ExcalidrawLinkElement,
  ): [number, number, number, number, number, number] => {
    let coords: [number, number, number, number, number, number];
    let x1;
    let y1;
    let x2;
    let y2;
    if (element.points.length < 2 || !ShapeCache.get(element)) {
      // XXX this is just a poor estimate and not very useful
      const { minX, minY, maxX, maxY } = element.points.reduce(
        (limits, [x, y]) => {
          limits.minY = Math.min(limits.minY, y);
          limits.minX = Math.min(limits.minX, x);

          limits.maxX = Math.max(limits.maxX, x);
          limits.maxY = Math.max(limits.maxY, y);

          return limits;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
      );
      x1 = minX + element.x;
      y1 = minY + element.y;
      x2 = maxX + element.x;
      y2 = maxY + element.y;
    } else {
      const shape = ShapeCache.generateElementShape(element, null)!;

      // first element is always the curve
      const ops = getCurvePathOps(shape[0]);

      const [minX, minY, maxX, maxY] = getMinMaxXYFromCurvePathOps(ops);
      x1 = minX + element.x;
      y1 = minY + element.y;
      x2 = maxX + element.x;
      y2 = maxY + element.y;
    }
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    coords = [x1, y1, x2, y2, cx, cy];

    return coords;
  };

  //CHANGED:ADD 2023/01/17 #390
  static getBoundTextElementPosition = (
    element: ExcalidrawLinkElement,
    boundTextElement: ExcalidrawTextElementWithContainer,
    elementsMap: ElementsMap,
  ): { x: number; y: number } => {
    const points = this.getPointsGlobalCoordinates(element, elementsMap);
    if (points.length < 2) {
      mutateElement(boundTextElement, { isDeleted: true });
    }

    // CHANGED:UPDATE 2023-04-01 #810
    // let x = points[0][0] + 10;
    const x =
      boundTextElement.textAlign === TEXT_ALIGN.LEFT
        ? element.x + 10
        : boundTextElement.textAlign === TEXT_ALIGN.RIGHT
          ? element.x +
          element.width -
          boundTextElement.width
          : element.x + element.width / 2 - boundTextElement.width / 2;
    let y = 0;
    if (element.pointerDirection === POINTER_DIRECTION.VERTICAL) {
      y = points[1][1] - boundTextElement.height - 12;
    } else if (points[1][0] - points[0][0] < boundTextElement.width + 10) {
      y = points[2][1] - boundTextElement.height - 12;
    } else {
      y = points[0][1] - boundTextElement.height - 12;
    }
    return { x, y };
  };

  // CHANGED: ADD 2023-03-01 #740
  static shouldHideElement = (linkElement: ExcalidrawLinkElement, startY: number, endY: number): boolean => {
    const top = linkElement.y + linkElement.points[1][1];
    const bottom = linkElement.y + linkElement.points[2][1];
    return (startY < top && top <= endY) &&
            (startY < bottom && bottom <= endY)
  }
}

const normalizeSelectedPoints = (
  points: (number | null)[],
): number[] | null => {
  let nextPoints = [
    ...new Set(points.filter((p) => p !== null && p !== -1)),
  ] as number[];
  nextPoints = nextPoints.sort((a, b) => a - b);
  return nextPoints.length ? nextPoints : null;
};
