import { DocumentReference, Timestamp } from "firebase/firestore";
import { OrganizationActivityState } from "src/conpath/constants/OrganizationActivityState";

export interface OrganizationActivityResponse {
  id: string;
  userId: string;
  projectId: string;
  state: OrganizationActivityState;
  docRef: DocumentReference;
  text: string;
  beforeValue: string;
  afterValue: string;
  timestamp: Timestamp;
};

export const OrganizationActivityDocumentFields = {
  id: "id",
  userId: "userId",
  projectId: "projectId",
  state: "state",
  docRef: "docRef",
  text: "text",
  beforeValue: "beforeValue",
  afterValue: "afterValue",
  timestamp: "timestamp",
} as const;

export type OrganizationActivityDocumentFields = typeof OrganizationActivityDocumentFields[keyof typeof OrganizationActivityDocumentFields];
