import * as GA from "../../ga";
import * as GAPoint from "../../gapoints";
import * as GADirection from "../../gadirections";
import * as GALine from "../../galines";
import * as GATransform from "../../gatransforms";

import {
  distance2d,
  isPointInPolygon,
  rotate,
} from "../../math";
import { pointsOnBezierCurves } from "points-on-curve";
import { GRID_SIZE, TASK_TO_LINK_GEN_POSITION } from "src/excalidraw/constants"; //CHANGED:ADD 2022-11-07 #99

import {
  NonDeletedExcalidrawElement,
  ExcalidrawElement,
  NonDeleted,
  StrokeRoundness,
  ElementsMap,
} from "../../element/types";
import {
  ExcalidrawBindableElementEx,
  ExcalidrawTaskElement,
  ExcalidrawLinkElement,
  ExcalidrawMilestoneElement,
} from "./types"; // from extensions
import { getElementAbsoluteCoords, getCurvePathOps, Bounds } from "../../element/bounds";

import { AppClassProperties, Point } from "src/excalidraw/types";
import { Drawable } from "roughjs/bin/core";
import { HitTestArgs } from "../../element/collision";
import { Mutable } from "src/excalidraw/utility-types";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";

export const bindingBorderTestEx = (
  element: NonDeleted<ExcalidrawBindableElementEx>,
  { x, y }: { x: number; y: number },
  app: AppClassProperties,
  startOrEnd: "start" | "end", // CHANGED:ADD 2022-11-11 #116
): boolean => {
  const threshold = maxBindingGapEx(element, element.width, element.height);
  const check = isOutsideCheck;
  const point: Point = [x, y];
  return hitTestPointAgainstElement({
    element,
    elementsMap: app.scene.getElementsMapIncludingDeleted(),
    point,
    threshold,
    check,
    hitTestPoint: startOrEnd === "start" ? "end" : "start", // CHANGED:ADD 2022-11-11 #116
  });
};

export const maxBindingGapEx = (
  element: ExcalidrawElement,
  elementWidth: number,
  elementHeight: number,
): number => {
  // Aligns diamonds with rectangles
  const shapeRatio = element.type === "milestone" ? 1 / Math.sqrt(2) : 1;
  const smallerDimension = shapeRatio * Math.min(elementWidth, elementHeight);
  // We make the bindable boundary bigger for bigger elements

  // CHANGED:UPDATE 2022-11-9 #107
  // return Math.max(16, Math.min(0.25 * smallerDimension, 32));
  return Math.max(GRID_SIZE / 2, Math.min(0.25 * smallerDimension, 32));
};

type HitTestArgsEx = {
  element: NonDeletedExcalidrawElement;
  elementsMap: ElementsMap;
  point: Point;
  threshold: number;
  check: (distance: number, threshold: number) => boolean;
  hitTestPoint: "start" | "end"; // CHANGED:ADD 2022-11-11 #116
};

const hitTestPointAgainstElement = (args: HitTestArgsEx): boolean => {
  switch (args.element.type) {
    case "task":
    case "milestone": // CHANGED:ADD 2022-12-7 #157
      //CHANGED:UPDATE 2022-11-07 #99
      const distance = distanceToBindableElementEx(
        args.element,
        args.elementsMap,
        args.point,
        args.hitTestPoint, // CHANGED:ADD 2022-11-11 #116
      );
      return args.check(distance, args.threshold);
  }

  return false;
};

export const distanceToBindableElementEx = (
  element: ExcalidrawBindableElementEx,
  elementsMap: ElementsMap,
  point: Point,
  hitTestPoint: "start" | "end", // CHANGED:ADD 2022-11-11 #116
): number => {
  switch (element.type) {
    case "task":
      return distanceToTask(
        element,
        point,
        hitTestPoint, // CHANGED:ADD 2022-11-11 #116
      );
    // CHANGED:ADD 2022-12-7 #157
    case "milestone":
      return distanceToMilestone(element, point, elementsMap);
  }
};

const isInsideCheck = (distance: number, threshold: number): boolean => {
  return distance < threshold;
};

const isOutsideCheck = (distance: number, threshold: number): boolean => {
  return 0 <= distance && distance < threshold;
};

const distanceToTask = (
  element: ExcalidrawTaskElement,
  point: Point,
  hitTestPoint: "start" | "end", // CHANGED:ADD 2022-11-11 #116
): number => {
  //CHANGED:UPDATE 2022-11-11 #116
  return hitTestPoint === "start"
    ? distance2d(element.x, element.y, point[0], point[1])
    : distance2d(
        element.x + element.points[1][0],
        element.y,
        point[0],
        point[1],
      );
};

// CHANGED:ADD 2022-12-7 #157
const distanceToMilestone = (
  element: ExcalidrawMilestoneElement,
  point: Point,
  elementsMap: ElementsMap,
): number => {
  const [, pointRel, hwidth, hheight] = pointRelativeToElement(
    element,
    point,
    elementsMap,
  );
  const side = GALine.equation(element.height, element.width, hheight * hwidth);

  return GAPoint.distanceToLine(pointRel, side);
};

// CHANGED:ADD 2022-11-9 #107
export const hitTestTask = (args: HitTestArgs): boolean => {
  const { element, elementsMap, point, threshold } = args;

  const [, pointRel, hwidth, hheight] = pointRelativeToElement(
    element,
    point,
    elementsMap,
    TASK_TO_LINK_GEN_POSITION,
  );

  const distance = Math.max(
    GAPoint.distanceToLine(pointRel, GALine.equation(0, 1, -hheight)),
    GAPoint.distanceToLine(pointRel, GALine.equation(1, 0, -hwidth)),
  );

  return args.check(distance, threshold);
};

// CHANGED:ADD 2022-12-7 #157
export const hitTestMilestone = (args: HitTestArgs): boolean => {
  const { element, elementsMap, point, threshold } = args;

  const [, pointRel, hwidth, hheight] = pointRelativeToElement(element, point, elementsMap);
  const side = GALine.equation(hheight, hwidth, -hheight * hwidth);
  const distance = GAPoint.distanceToLine(pointRel, side);

  return args.check(distance, threshold);
};

export const hitTestLink = (args: HitTestArgs): boolean => {
  const { element, threshold } = args;
  if (!ShapeCache.get(element)) {
    return false;
  }

  const [point, pointAbs, hwidth, hheight] = pointRelativeToElement(
    args.element,
    args.point,
    args.elementsMap,
  );
  const side1 = GALine.equation(0, 1, -hheight);
  const side2 = GALine.equation(1, 0, -hwidth);
  if (
    !isInsideCheck(GAPoint.distanceToLine(pointAbs, side1), threshold) ||
    !isInsideCheck(GAPoint.distanceToLine(pointAbs, side2), threshold)
  ) {
    return false;
  }
  const [relX, relY] = GAPoint.toTuple(point);

  const shape = ShapeCache.get(element as ExcalidrawLinkElement);

  if (!shape) {
    return false;
  }

  if (args.check === isInsideCheck) {
    const hit = shape.some((subshape) =>
      hitTestCurveInside(
        subshape,
        relX,
        relY,
        element.roundness ? "round" : "sharp",
      ),
    );
    if (hit) {
      return true;
    }
  }

  // hit test all "subshapes" of the link element
  return shape.some((subshape) =>
    hitTestRoughShape(subshape, relX, relY, threshold),
  );
};

// CHANGED:ADD 2023-01-22 #391 Jobを選択できるようにするための、hit判定のための関数
// CHANGED:UPDATE 2023-01-29 #543 （固定されているエレメントなので）RelativeのCoordではなく、ViewPortのCoordをとる
export const hitTestJob = (args: HitTestArgs): boolean => {
  const { appState, element, elementsMap, point, threshold } = args;
  if (typeof appState === "undefined") return false;

  const pointOnViewPort: Point = [point[0] + appState.scrollX, point[1] ]; // x軸固定なのでyはSceneのCoord
  const [, pointRel, hwidth, hheight] = pointRelativeToElement(element, pointOnViewPort, elementsMap);
  const distance = Math.max(
    GAPoint.distanceToLine(pointRel, GALine.equation(0, 1, -hheight)),
    GAPoint.distanceToLine(pointRel, GALine.equation(1, 0, -hwidth)),
  );

  return args.check(distance, threshold);
};

// Returns:
//   1. the point relative to the elements (x, y) position
//   2. the point relative to the element's center with positive (x, y)
//   3. half element width
//   4. half element height
//
// Note that for linear elements the (x, y) position is not at the
// top right corner of their boundary.
//
// Rectangles, diamonds and ellipses are symmetrical over axes,
// and other elements have a rectangular boundary,
// so we only need to perform hit tests for the positive quadrant.
const pointRelativeToElement = (
  element: ExcalidrawElement,
  pointTuple: Point,
  elementsMap: ElementsMap,
  correction: number = 0, //CHANGED:ADD 2022-11-07 #99
): [GA.Point, GA.Point, number, number] => {
  //CHANGED:UPDATE 2022-11-07 #99
  const point = GAPoint.from(pointTuple);
  const elementCoords = getElementAbsoluteCoords(element, elementsMap);
  const _elementCoords: any = elementCoords;
  _elementCoords[2] = _elementCoords[2] + correction;
  // const center = coordsCenter(elementCoords);
  const center = coordsCenter(_elementCoords);
  const rotate = GATransform.rotation(center, (element.angle || 0));
  const pointRotated = GATransform.apply(rotate, point);
  const pointRelToCenter = GA.sub(pointRotated, GADirection.from(center));
  const pointRelToCenterAbs = GAPoint.abs(pointRelToCenter);
  const elementPos = GA.offset(element.x, element.y);
  const pointRelToPos = GA.sub(pointRotated, elementPos);
  // const [ax, ay, bx, by] = elementCoords;
  const [ax, ay, bx, by] = _elementCoords;
  const halfWidth = (bx - ax) / 2;
  const halfHeight = (by - ay) / 2;
  return [pointRelToPos, pointRelToCenterAbs, halfWidth, halfHeight];
};

const relativizationToElementCenter = (
  element: ExcalidrawElement,
  elementsMap: ElementsMap,
): GA.Transform => {
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
  const center = coordsCenter([x1, y1, x2, y2]);
  // GA has angle orientation opposite to `rotate`
  const rotate = GATransform.rotation(center, (element.angle || 0));
  const translate = GA.reverse(
    GATransform.translation(GADirection.from(center)),
  );
  return GATransform.compose(rotate, translate);
};

const coordsCenter = ([ax, ay, bx, by]: Bounds): GA.Point => {
  return GA.point((ax + bx) / 2, (ay + by) / 2);
};

// The focus distance is the oriented ratio between the size of
// the `element` and the "focus image" of the element on which
// all focus points lie, so it's a number between -1 and 1.
// The line going through `a` and `b` is a tangent to the "focus image"
// of the element.
export const determineFocusDistanceEx = (
  element: ExcalidrawBindableElementEx,
  // Point on the line, in absolute coordinates
  a: Point,
  // Another point on the line, in absolute coordinates (closer to element)
  b: Point,
  elementsMap: ElementsMap,
): number => {
  const relateToCenter = relativizationToElementCenter(element, elementsMap);
  const aRel = GATransform.apply(relateToCenter, GAPoint.from(a));
  const bRel = GATransform.apply(relateToCenter, GAPoint.from(b));
  const line = GALine.through(aRel, bRel);
  const q = element.height / element.width;
  const hwidth = element.width / 2;
  const hheight = element.height / 2;
  const n = line[2];
  const m = line[3];
  const c = line[1];
  const mabs = Math.abs(m);
  const nabs = Math.abs(n);
  switch (element.type) {
    case "task":
      return c / (hwidth * (nabs + q * mabs));
    // CHANGED:ADD 2022-12-7 #157
    case "milestone":
      return mabs < nabs ? c / (nabs * hwidth) : c / (mabs * hheight);
  }
};

// Returns 2 or 0 intersection points between line going through `a` and `b`
// and the `element`, in ascending order of distance from `a`.
export const intersectElementWithLine = (
  element: ExcalidrawBindableElementEx,
  // Point on the line, in absolute coordinates
  a: Point,
  // Another point on the line, in absolute coordinates
  b: Point,
  // If given, the element is inflated by this value
  gap: number = 0,
  elementsMap: ElementsMap,
): Point[] => {
  const relateToCenter = relativizationToElementCenter(element, elementsMap);
  const aRel = GATransform.apply(relateToCenter, GAPoint.from(a));
  const bRel = GATransform.apply(relateToCenter, GAPoint.from(b));
  const line = GALine.through(aRel, bRel);
  const reverseRelateToCenter = GA.reverse(relateToCenter);
  const intersections = getSortedElementLineIntersections(
    element,
    line,
    aRel,
    gap,
  );
  return intersections.map((point) =>
    GAPoint.toTuple(GATransform.apply(reverseRelateToCenter, point)),
  );
};

const getSortedElementLineIntersections = (
  element: ExcalidrawBindableElementEx,
  // Relative to element center
  line: GA.Line,
  // Relative to element center
  nearPoint: GA.Point,
  gap: number = 0,
): GA.Point[] => {
  let intersections: GA.Point[];
  switch (element.type) {
    case "task":
    case "milestone": // CHANGED:ADD 2022-12-7 #157
      const corners = getCorners(element);
      intersections = corners
        .flatMap((point, i) => {
          const edge: [GA.Point, GA.Point] = [point, corners[(i + 1) % 4]];
          return intersectSegment(line, offsetSegment(edge, gap));
        })
        .concat(
          corners.flatMap((point) => getCircleIntersections(point, gap, line)),
        );
      break;
  }
  if (intersections.length < 2) {
    // Ignore the "edge" case of only intersecting with a single corner
    return [];
  }
  const sortedIntersections = intersections.sort(
    (i1, i2) =>
      GAPoint.distance(i1, nearPoint) - GAPoint.distance(i2, nearPoint),
  );
  return [
    sortedIntersections[0],
    sortedIntersections[sortedIntersections.length - 1],
  ];
};

const getCorners = (
  element:
    | ExcalidrawTaskElement
    | ExcalidrawMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  scale: number = 1,
): GA.Point[] => {
  const hx = (scale * element.width) / 2;
  const hy = (scale * element.height) / 2;
  switch (element.type) {
    case "task":
      return [
        GA.point(hx, hy),
        GA.point(hx, -hy),
        GA.point(-hx, -hy),
        GA.point(-hx, hy),
      ];
    // CHANGED:ADD 2022-12-7 #157
    case "milestone":
      return [
        GA.point(0, hy),
        GA.point(hx, 0),
        GA.point(0, -hy),
        GA.point(-hx, 0),
      ];
  }
};

// Returns intersection of `line` with `segment`, with `segment` moved by
// `gap` in its polar direction.
// If intersection coincides with second segment point returns empty array.
const intersectSegment = (
  line: GA.Line,
  segment: [GA.Point, GA.Point],
): GA.Point[] => {
  const [a, b] = segment;
  const aDist = GAPoint.distanceToLine(a, line);
  const bDist = GAPoint.distanceToLine(b, line);
  if (aDist * bDist >= 0) {
    // The intersection is outside segment `(a, b)`
    return [];
  }
  return [GAPoint.intersect(line, GALine.through(a, b))];
};

const offsetSegment = (
  segment: [GA.Point, GA.Point],
  distance: number,
): [GA.Point, GA.Point] => {
  const [a, b] = segment;
  const offset = GATransform.translationOrthogonal(
    GADirection.fromTo(a, b),
    distance,
  );
  return [GATransform.apply(offset, a), GATransform.apply(offset, b)];
};

export const getCircleIntersections = (
  center: GA.Point,
  radius: number,
  line: GA.Line,
): GA.Point[] => {
  if (radius === 0) {
    return GAPoint.distanceToLine(line, center) === 0 ? [center] : [];
  }
  const m = line[2];
  const n = line[3];
  const c = line[1];
  const [a, b] = GAPoint.toTuple(center);
  const r = radius;
  const squares = m * m + n * n;
  const discr = r * r * squares - (m * a + n * b + c) ** 2;
  if (squares === 0 || discr <= 0) {
    return [];
  }
  const discrRoot = Math.sqrt(discr);
  const xn = a * n * n - b * m * n - m * c;
  const yn = b * m * m - a * m * n - n * c;

  return [
    GA.point((xn + n * discrRoot) / squares, (yn - m * discrRoot) / squares),
    GA.point((xn - n * discrRoot) / squares, (yn + m * discrRoot) / squares),
  ];
};

export const findFocusPointForRectangularsEx = (
  element:
    | ExcalidrawTaskElement
    | ExcalidrawMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  // Between -1 and 1 for how far away should the focus point be relative
  // to the size of the element. Sign determines orientation.
  relativeDistance: number,
  // The point for which we're trying to find the focus point, relative
  // to the element center.
  point: GA.Point,
): GA.Point => {
  const relativeDistanceAbs = Math.abs(relativeDistance);
  const orientation = Math.sign(relativeDistance);
  const corners = getCorners(element, relativeDistanceAbs);

  let maxDistance = 0;
  let tangentPoint: null | GA.Point = null;
  corners.forEach((corner) => {
    const distance = orientation * GALine.through(point, corner)[1];
    if (distance > maxDistance) {
      maxDistance = distance;
      tangentPoint = corner;
    }
  });
  return tangentPoint!;
};

const pointInBezierEquation = (
  p0: Point,
  p1: Point,
  p2: Point,
  p3: Point,
  [mx, my]: Point,
  lineThreshold: number,
) => {
  // B(t) = p0 * (1-t)^3 + 3p1 * t * (1-t)^2 + 3p2 * t^2 * (1-t) + p3 * t^3
  const equation = (t: number, idx: number) =>
    Math.pow(1 - t, 3) * p3[idx] +
    3 * t * Math.pow(1 - t, 2) * p2[idx] +
    3 * Math.pow(t, 2) * (1 - t) * p1[idx] +
    p0[idx] * Math.pow(t, 3);

  // go through t in increments of 0.01
  let t = 0;
  while (t <= 1.0) {
    const tx = equation(t, 0);
    const ty = equation(t, 1);

    const diff = Math.sqrt(Math.pow(tx - mx, 2) + Math.pow(ty - my, 2));

    if (diff < lineThreshold) {
      return true;
    }

    t += 0.01;
  }

  return false;
};

const hitTestCurveInside = (
  drawable: Drawable,
  x: number,
  y: number,
  sharpness: StrokeRoundness,
) => {
  const ops = getCurvePathOps(drawable);
  const points: Mutable<Point>[] = [];
  let odd = false; // select one line out of double lines
  for (const operation of ops) {
    if (operation.op === "move") {
      odd = !odd;
      if (odd) {
        points.push([operation.data[0], operation.data[1]]);
      }
    } else if (operation.op === "bcurveTo") {
      if (odd) {
        points.push([operation.data[0], operation.data[1]]);
        points.push([operation.data[2], operation.data[3]]);
        points.push([operation.data[4], operation.data[5]]);
      }
    } else if (operation.op === "lineTo") {
      if (odd) {
        points.push([operation.data[0], operation.data[1]]);
      }
    }
  }
  if (points.length >= 4) {
    if (sharpness === "sharp") {
      return isPointInPolygon(points, x, y);
    }
    const polygonPoints = pointsOnBezierCurves(points, 10, 5);
    return isPointInPolygon(polygonPoints, x, y);
  }
  return false;
};

const hitTestRoughShape = (
  drawable: Drawable,
  x: number,
  y: number,
  lineThreshold: number,
) => {
  // read operations from first opSet
  const ops = getCurvePathOps(drawable);

  // set start position as (0,0) just in case
  // move operation does not exist (unlikely but it is worth safekeeping it)
  let currentP: Point = [0, 0];

  return ops.some(({ op, data }, idx) => {
    // There are only four operation types:
    // move, bcurveTo, lineTo, and curveTo
    if (op === "move") {
      // change starting point
      currentP = data as unknown as Point;
      // move operation does not draw anything; so, it always
      // returns false
    } else if (op === "bcurveTo") {
      // create points from bezier curve
      // bezier curve stores data as a flattened array of three positions
      // [x1, y1, x2, y2, x3, y3]
      const p1 = [data[0], data[1]] as Point;
      const p2 = [data[2], data[3]] as Point;
      const p3 = [data[4], data[5]] as Point;

      const p0 = currentP;
      currentP = p3;

      // check if points are on the curve
      // cubic bezier curves require four parameters
      // the first parameter is the last stored position (p0)
      const retVal = pointInBezierEquation(
        p0,
        p1,
        p2,
        p3,
        [x, y],
        lineThreshold,
      );

      // set end point of bezier curve as the new starting point for
      // upcoming operations as each operation is based on the last drawn
      // position of the previous operation
      return retVal;
    } else if (op === "lineTo") {
      return hitTestCurveInside(drawable, x, y, "sharp");
    } else if (op === "qcurveTo") {
      // TODO: Implement this
      console.warn("qcurveTo is not implemented yet");
    }

    return false;
  });
};
