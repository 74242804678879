import "./Tooltip.scss";

import React, { useEffect } from "react";

import { HYPERLINK_TOOLTIP_DELAY, GENERAL_TOOLTIP_DELAY } from "src/excalidraw/constants";
import { ElementsMap, NonDeletedExcalidrawElement } from "../element/types";
import { getElementAbsoluteCoords } from "../element";
import { trackEvent } from "../analytics";

type TOOLTIP_TYPE = "HYPERLINK" | "GENERAL";

// TimeoutID: TimeoutDuration
const TooltipConfig: Record<TOOLTIP_TYPE, {
  id: number | null,
  styleKey: string,
  category: string,
  timeout: number,
  visible: boolean,
}> = {
  "HYPERLINK": {
    id: null,
    styleKey: "__hyperlink",
    category: "hyperlink",
    timeout: HYPERLINK_TOOLTIP_DELAY,
    visible: false,
  },
  "GENERAL": {
    id: null,
    styleKey: "__general",
    category: "general",
    timeout: GENERAL_TOOLTIP_DELAY,
    visible: false,
  },
};

export const showTooltip = ({
  tooltipType,
  element,
  elementsMap,
  coordinate,
  label
}: {
  tooltipType: TOOLTIP_TYPE,
  element: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  coordinate?: {
    x: number,
    y: number,
  },
  label?: string,
}) => {
  if (TooltipConfig[tooltipType].id) {
    clearTimeout(TooltipConfig[tooltipType].id!);
  }

  TooltipConfig[tooltipType].id = window.setTimeout(
    () => renderTooltip({ tooltipType, element, elementsMap, coordinate, label}),
    TooltipConfig[tooltipType].timeout,
  );
};

export const hideTooltip = (
  tooltipType: TOOLTIP_TYPE,
) => {
  if (TooltipConfig[tooltipType].id) {
    clearTimeout(TooltipConfig[tooltipType].id!);
  }

  if (TooltipConfig[tooltipType].visible) {
    const tooltipDiv = getTooltipDiv();
    const className = `conpath-tooltip${TooltipConfig[tooltipType].styleKey}`;
    const child = document.getElementById(className + "__label");
    tooltipDiv.classList.remove(className);
    if (child) tooltipDiv.removeChild(child);
    }
};


const renderTooltip = ({
  tooltipType,
  element,
  elementsMap,
  coordinate,
  label
}: {
  tooltipType: TOOLTIP_TYPE,
  element: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  coordinate?: {
    x: number,
    y: number,
  },
  label?: string,
}) => {
  const tooltipDiv = getTooltipDiv();
  const className = `conpath-tooltip${TooltipConfig[tooltipType].styleKey}`;
  tooltipDiv.classList.add(className);
  tooltipDiv.style.maxWidth = "20rem";
  tooltipDiv.textContent = "";

  const pClassName = className + "__label";
  document.getElementById(pClassName)?.remove();

  const childElement = document.createElement("p");
  childElement.id = pClassName;

  let maxWidth = 0
  if (label) {
    label.split("\n").forEach((line, index) => {
      if (index > 0) {
        childElement.appendChild(document.createElement("br"));
      }
      childElement.appendChild(document.createTextNode(line));

      if (line.length > maxWidth) maxWidth = line.length;
    })
  }
  tooltipDiv.appendChild(childElement);
  
  let left: number;
  let top: number; 
  let width: number;
  let height: number;

  if (coordinate) {
    left = coordinate.x;
    top = coordinate.y;
    width = tooltipDiv.offsetWidth;
    height = tooltipDiv.offsetHeight;
  } else {
    const coord = getElementAbsoluteCoords(element, elementsMap);
    left = coord[0];
    top = coord[1];
    width = coord[2];
    height = coord[3];
  }


  updateTooltipPosition(
    tooltipDiv,
    {
      left,
      top,
      width,
      height,
    },
    "top",
  );

  if (tooltipType === "HYPERLINK") {
    trackEvent("hyperlink", "tooltip", "link-icon");
  }

  TooltipConfig[tooltipType].visible = true;
}

export const getTooltipDiv = () => {
  const existingDiv = document.querySelector<HTMLDivElement>(
    ".conpath-tooltip",
  );
  if (existingDiv) {
    return existingDiv;
  }
  const div = document.createElement("div");
  document.body.appendChild(div);
  div.classList.add("conpath-tooltip");
  return div;
};

export const updateTooltipPosition = (
  tooltip: HTMLDivElement,
  item: {
    left: number;
    top: number;
    width: number;
    height: number;
  },
  position: "bottom" | "top" = "bottom",
) => {
  const tooltipRect = tooltip.getBoundingClientRect();

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const margin = 5;

  let left = item.left + item.width / 2 - tooltipRect.width / 2;
  if (left < 0) {
    left = margin;
  } else if (left + tooltipRect.width >= viewportWidth) {
    left = viewportWidth - tooltipRect.width - margin;
  }

  let top: number;

  if (position === "bottom") {
    top = item.top + item.height + margin;
    if (top + tooltipRect.height >= viewportHeight) {
      top = item.top - tooltipRect.height - margin;
    }
  } else {
    top = item.top - tooltipRect.height - margin;
    if (top < 0) {
      top = item.top + item.height + margin;
    }
  }

  Object.assign(tooltip.style, {
    top: `${top}px`,
    left: `${left}px`,
  });
};

const updateTooltip = (
  item: HTMLDivElement,
  tooltip: HTMLDivElement,
  label: string,
  long: boolean,
) => {
  tooltip.classList.add("conpath-tooltip--visible");
  tooltip.style.minWidth = long ? "50ch" : "10ch";
  tooltip.style.maxWidth = long ? "50ch" : "15ch";

  tooltip.textContent = label;

  const itemRect = item.getBoundingClientRect();
  updateTooltipPosition(tooltip, itemRect);
};

type TooltipProps = {
  children: React.ReactNode;
  label: string;
  long?: boolean;
  style?: React.CSSProperties;
};

export const Tooltip = ({
  children,
  label,
  long = false,
  style,
}: TooltipProps) => {
  useEffect(() => {
    return () =>
      getTooltipDiv().classList.remove("conpath-tooltip--visible");
  }, []);
  return (
    <div
      className="conpath-tooltip-wrapper"
      onPointerEnter={(event) =>
        updateTooltip(
          event.currentTarget as HTMLDivElement,
          getTooltipDiv(),
          label,
          long,
        )
      }
      onPointerLeave={() =>
        getTooltipDiv().classList.remove("conpath-tooltip--visible")
      }
      style={style}
    >
      {children}
    </div>
  );
};
