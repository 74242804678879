import { register } from "src/excalidraw/actions/register";
import SettingsDialog from "src/excalidraw/extensions/components/SettingsDialog";
import { GRID_SIZE } from "src/excalidraw/constants";
import { generateBackgroundElements } from "../app/data/background";
import Job from "../job";
import Milestone from "../milestone";

export const actionSettings = register({
  name: "settings",
  trackEvent: { category: "canvas" },
  perform: (elements, appState, value) => {
    const projectStartDate = new Date(value[0]);
    const projectEndDate = new Date(value[1]);
    // CHANGED: ADD 2023-01-21 #391
    const jobElements = Job.getJobElements(elements);

    // CHANGED: ADD 2023-2-11 #671
    const milestoneElements = Milestone.getMilestoneElements(elements);

    const {
      jobPanelElements,
      jobLineElements,
      milestoneLineElements,
      calendarWidth,
    } = generateBackgroundElements(
      projectStartDate,
      projectEndDate,
      jobElements,
      milestoneElements,
      appState.viewBackgroundColor,
      appState.gridSize ? appState.gridSize : GRID_SIZE,
      appState.holidays,
    );

    return {
      jobPanelElements,
      jobLineElements, // CHANGED:ADD 2023-03-01 #726
      milestoneLineElements, // CHANGED:ADD 2023-2-11 #671
      appState: {
        ...appState,
        projectStartDate,
        projectEndDate,
        calendarWidth,
      },
      commitToHistory: false,
      resetHistory: true, // CHANGED:ADD 2023-1-26 #439
      updatedProjectDate: true, // CHANGED:ADD 2023-2-14 #676
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <SettingsDialog
      elements={elements}
      updateData={updateData}
      appState={appState}
    />
  ),
});
