import { NonDeleted } from "../../element/types";
import { ExcalidrawLinkElement, ExcalidrawTaskElement } from "./types"; // from extensions
import { MaybeTransformHandleType } from "../../element/transformHandles";

export const getResizeArrowDirectionEx = (
  transformHandleType: MaybeTransformHandleType,
  element:
    | NonDeleted<ExcalidrawTaskElement>
    | NonDeleted<ExcalidrawLinkElement>,
): "origin" | "end" => {
  const [, [px, py]] = element.points;
  const isResizeEnd =
    (transformHandleType === "nw" && (px < 0 || py < 0)) ||
    (transformHandleType === "ne" && px >= 0) ||
    (transformHandleType === "sw" && px <= 0) ||
    (transformHandleType === "se" && (px > 0 || py > 0));
  return isResizeEnd ? "end" : "origin";
};
