import { createContext, StrictMode } from "react";
import { createRoot } from "react-dom/client";
// CHANGED:UPDATE 2022-10-18 #38
// import ExcalidrawApp from "./excalidraw-app";
// import ExcalidrawApp from "./excalidraw/extensions/app";
import App from "./App";
// import "windi.css"

// import "./excalidraw/excalidraw-app/pwa";
import "./configs/sentry";
import RootStore from "./conpath/stores";

window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

export const StoreContext = createContext<RootStore>({} as RootStore);

root.render(
  <StrictMode>
    <StoreContext.Provider value={new RootStore()}>
      <App />
    </StoreContext.Provider>
  </StrictMode>,
);
