import { isEqual } from "lodash";
import React, { useCallback } from 'react';

//mobx
import { observer } from "mobx-react-lite";

import polyfill from "src/excalidraw/polyfill";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useRef, useState } from "react";
import { trackEvent } from "src/excalidraw/analytics";
// import { getDefaultAppState } from "src/excalidraw/appState";
import { ErrorDialog } from "src/excalidraw/components/ErrorDialog";
import { TopErrorBoundary } from "src/excalidraw/components/TopErrorBoundary";
import {
  APP_NAME,
  CANVAS_HEADER_HEIGHT,
  DEFAULT_ELEMENT_PROPS,
  DEFAULT_JOB_HEIGHT,
  EVENT,
  GRID_SIZE,
  JOB_ELEMENTS_WIDTH,
  NUMBER_OF_JOBS,
  PRIORITY,
  THEME,
  TITLE_TIMEOUT,
  VERSION_TIMEOUT,
} from "src/excalidraw/constants";
// import { loadFromBlob } from "src/excalidraw/data/blob";
import {
  ExcalidrawElement,
  FileId,
  NonDeletedExcalidrawElement,
  Theme,
} from "src/excalidraw/element/types";
import { useCallbackRefState } from "src/excalidraw/hooks/useCallbackRefState";
import { t } from "src/excalidraw/i18n";
import {
  Excalidraw,
  defaultLang,
} from "src/excalidraw/packages/excalidraw/index";
import {
  AppState,
  LibraryItems,
  ExcalidrawImperativeAPI,
  BinaryFiles,
  ExcalidrawInitialDataState,
} from "src/excalidraw/types";
import {
  debounce,
  getVersion,
  getFrame,
  isTestEnv,
  preventUnload,
  ResolvablePromise,
  resolvablePromise,
} from "src/excalidraw/utils";
import {
  FIREBASE_STORAGE_PREFIXES,
  STORAGE_KEYS,
  SYNC_BROWSER_TABS_TIMEOUT,
} from "src/excalidraw/excalidraw-app/app_constants";
import Collab, {
  CollabAPI,
  collabAPIAtom,
  collabDialogShownAtom,
  isCollaboratingAtom,
  isOfflineAtom,
} from "src/excalidraw/excalidraw-app/collab/Collab";
import {
  getLibraryItemsFromStorage,
  importFromLocalStorage,
  importUsernameFromLocalStorage,
} from "src/excalidraw/excalidraw-app/data/localStorage";
// import CustomStats from "src/excalidraw/excalidraw-app/CustomStats";
import CustomStatsEx from 'src/excalidraw/extensions/components/CustomStatsEx';
import { restore, restoreAppState, RestoredDataState } from "src/excalidraw/data/restore";
// import { ExportToExcalidrawPlus } from "../excalidraw/excalidraw-app/components/ExportToExcalidrawPlus";
import { updateStaleImageStatuses } from "src/excalidraw/excalidraw-app/data/FileManager";
import { isInitializedImageElement } from "src/excalidraw/element/typeChecks";
import { loadFilesFromFirebase } from "src/excalidraw/excalidraw-app/data/firebase";
import { LocalData } from "src/excalidraw/excalidraw-app/data/LocalData";
import { isBrowserStorageStateNewer } from "src/excalidraw/excalidraw-app/data/tabSync";
import clsx from "clsx";
import { reconcileElements } from "src/excalidraw/excalidraw-app/collab/reconciliation";
import { parseLibraryTokensFromUrl, useHandleLibrary } from "src/excalidraw/data/library";
import { AppMainMenuEx } from "src/excalidraw/extensions/app/components/AppMainMenuEx";
// import { AppWelcomeScreen } from "../excalidraw/excalidraw-app/components/AppWelcomeScreen";
// import { AppFooter } from "../excalidraw/excalidraw-app/components//AppFooter";
import { atom, Provider, useAtom, useAtomValue } from "jotai";
import { useAtomWithInitialValue } from "src/excalidraw/jotai";
import { appJotaiStore } from "src/excalidraw/excalidraw-app/app-jotai";
import { useStore } from "src/conpath/hooks/useStore";
import _ from "lodash";
import { useNavigate, useParams } from 'react-router-dom';
// import { toJS } from "mobx";
import { newElementWith } from 'src/excalidraw/packages/excalidraw/index';

import OrganizationModel from 'src/conpath/models/OrganizationModel';
import ProjectModel from 'src/conpath/models/ProjectModel';

import { ResolutionType } from "src/excalidraw/utility-types";

//extensions
import Job from "src/excalidraw/extensions/job";
import { newCommentElement, newJobElement } from "src/excalidraw/extensions/element/newElement";
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";
import { firebaseTimeToDate } from 'src/utils/timeUtils';
import { OrganizationRole, ProjectRole } from 'src/conpath/constants/Role';
import { CustomSidebar } from 'src/excalidraw/extensions/components/CustomSidebar';
import { isCommentableElement, isCommentElement, isLinkElement, isTaskElement } from 'src/excalidraw/extensions/element/typeChecks';
// import Scroll from "src/excalidraw/extensions/scene/scroll";
// import { ImportedDataState } from 'src/excalidraw/data/types';

//styles
import "src/excalidraw/excalidraw-app/index.scss";
import "./Project.scss";
import { Dialog } from 'src/excalidraw/components/Dialog';
import { Paths } from 'src/conpath/constants/Routes';
import { ExcalidrawCommentElement } from 'src/excalidraw/extensions/element/types';
import { clearAppStateForLoginUser } from 'src/excalidraw/appState';
import LoginUserModel from "../../models/LoginUserModel";
import { CriticalPathColor } from "../../constants/Colors";

polyfill();

window.EXCALIDRAW_THROTTLE_RENDER = true;

const languageDetector = new LanguageDetector();
languageDetector.init({
  languageUtils: {},
});

const RE_COLLAB_LINK = /projects\/([a-zA-Z0-9_-]+)/;

const isCollaborationLink = (projectId: string) => {
  return RE_COLLAB_LINK.test(projectId);
};

const getCollaborationLinkData = (projectId: string) => {
  const match = projectId.match(RE_COLLAB_LINK);
  return match ? { roomId: match[1], roomKey: "GqOuPVE0Nk4PZIfAHmzfJg" } : null;
};

const getTaskId = (link: string) => {
  const hash = new URL(link).hash;
  const match = hash.match(/^#task=([a-zA-Z0-9_-]+)$/);

  return match ? match[1] : null;
};

const getCommentId = (link: string) => {
  const hash = new URL(link).hash;
  const match = hash.match(/^#comment=([a-zA-Z0-9_-]+)$/);

  return match ? match[1] : null;
};

const initializeScene = async (opts: {
  collabAPI: CollabAPI;
  excalidrawAPI: ExcalidrawImperativeAPI;
  project: ProjectModel; // CHANGED:ADD 2023/07/28 #864
}): Promise<
  { scene: ExcalidrawInitialDataState | null } & (
    | { isExternalScene: true; id: string; key: string }
    | { isExternalScene: false; id?: null; key?: null }
  )
> => {
  // CHANGED:UPDATE 2023/07/25 #874
  // const searchParams = new URLSearchParams(window.location.search);
  // const id = searchParams.get("id");
  // const jsonBackendMatch = window.location.hash.match(
  //   /^#json=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/,
  // );
  // const externalUrlMatch = window.location.hash.match(/^#url=(.*)$/);

  // // const localDataState = importFromLocalStorage();

  // let scene: RestoredDataState & {
  //   scrollToContent?: boolean;
  // } = await loadScene(null, null, null);

  // let roomLinkData = getCollaborationLinkData(window.location.href);
  // const isExternalScene = !!(id || jsonBackendMatch || roomLinkData);
  // if (isExternalScene) {
  //   if (
  //     // don't prompt if scene is empty
  //     !scene.elements.length ||
  //     // don't prompt for collab scenes because we don't override local storage
  //     roomLinkData ||
  //     // otherwise, prompt whether user wants to override current scene
  //     window.confirm(t("alerts.loadSceneOverridePrompt"))
  //   ) {
  //     if (jsonBackendMatch) {
  //       scene = await loadScene(
  //         jsonBackendMatch[1],
  //         jsonBackendMatch[2],
  //         null,
  //       );
  //     }
  //     scene.scrollToContent = true;
  //     if (!roomLinkData) {
  //       window.history.replaceState({}, APP_NAME, window.location.origin);
  //     }
  //   } else {
  //     // https://github.com/excalidraw/excalidraw/issues/1919
  //     if (document.hidden) {
  //       return new Promise((resolve, reject) => {
  //         window.addEventListener(
  //           "focus",
  //           () => initializeScene(opts).then(resolve).catch(reject),
  //           {
  //             once: true,
  //           },
  //         );
  //       });
  //     }

  //     roomLinkData = null;
  //     window.history.replaceState({}, APP_NAME, window.location.origin);
  //   }
  // } else if (externalUrlMatch) {
  //   window.history.replaceState({}, APP_NAME, window.location.origin);

  //   const url = externalUrlMatch[1];
  //   try {
  //     const request = await fetch(window.decodeURIComponent(url));
  //     const data = await loadFromBlob(await request.blob(), null, null);
  //     if (
  //       !scene.elements.length ||
  //       window.confirm(t("alerts.loadSceneOverridePrompt"))
  //     ) {
  //       return { scene: data, isExternalScene };
  //     }
  //   } catch (error: any) {
  //     return {
  //       scene: {
  //         appState: {
  //           errorMessage: t("alerts.invalidSceneUrl"),
  //         },
  //       },
  //       isExternalScene,
  //     };
  //   }
  // }

  // if (roomLinkData && opts.collabAPI) {
  //   const { excalidrawAPI } = opts;

  //   const scene = await opts.collabAPI.startCollaboration(roomLinkData);

  //   return {
  //     // when collaborating, the state may have already been updated at this
  //     // point (we may have received updates from other clients), so reconcile
  //     // elements and appState with existing state
  //     scene: {
  //       ...scene,
  //       appState: {
  //         ...restoreAppState(
  //           {
  //             ...scene?.appState,
  //             theme: scene?.appState?.theme,
  //           },
  //           excalidrawAPI.getAppState(),
  //         ),
  //         // necessary if we're invoking from a hashchange handler which doesn't
  //         // go through App.initializeScene() that resets this flag
  //         isLoading: false,
  //       },
  //       elements: reconcileElements(
  //         scene?.elements || [],
  //         excalidrawAPI.getSceneElementsIncludingDeleted(),
  //         excalidrawAPI.getAppState(),
  //       ),
  //     },
  //     isExternalScene: true,
  //     id: roomLinkData.roomId,
  //     key: roomLinkData.roomKey,
  //   };
  // } else if (scene) {
  //   // CHANGED:UPDATE 2023-2-12 #512
  //   return isExternalScene && jsonBackendMatch
  //     ? {
  //       scene: {
  //         ...scene,
  //         appState: {
  //           ...scene.appState,
  //           cloudFileHandle: {
  //             id: jsonBackendMatch[1],
  //             key: jsonBackendMatch[2],
  //           },
  //         }
  //       },
  //       isExternalScene,
  //       id: jsonBackendMatch[1],
  //       key: jsonBackendMatch[2],
  //     }
  //     : { scene, isExternalScene: false };
  // }
  let roomLinkData = getCollaborationLinkData(window.location.href);
  const taskId = getTaskId(window.location.href);
  const commentId = getCommentId(window.location.href);

  if (roomLinkData) {
    const { excalidrawAPI } = opts;

    const scene = await opts.collabAPI.startCollaboration(roomLinkData);
    // CHANGED:ADD 2023/08/24 #935
    if (!scene) {
      return { scene: null, isExternalScene: false };
    }

    let rootCommentId: null | string = null;
    if (commentId) {
      const comment = await opts.project.getCommentById(commentId);
      if (comment) {
        rootCommentId = comment.rootCommentId ?? comment.id;
      }
    }

    const _elements = reconcileElements(
      scene?.elements || [],
      excalidrawAPI.getSceneElementsIncludingDeleted(),
      excalidrawAPI.getAppState(),
    );

    opts.project.setFirebaseVersionCache(_elements);

    const threads = await opts.project.getAllThreadsIncludingDeletedByProjectId();
    const newCommentElements: ExcalidrawCommentElement[] = [];
    // Roadmap,Resourceで追加されたスレッドをcanvasに反映
    threads
      .filter((thread) => !thread.isDeleteElementRequired && !_elements.find((el) => isCommentElement(el) && el.id === thread.id))
      .map((comment) => {
        const commentableElement = _elements.find((el) => isCommentableElement(el) && el.id === comment.commentElementId);
        if (commentableElement) {
          newCommentElements.push(
            newCommentElement({
              id: comment.id,
              x: commentableElement.x + commentableElement.width - 12,
              y: commentableElement.y + 12,
              strokeColor: scene.appState?.currentItemStrokeColor,
              strokeWidth: scene.appState?.currentItemStrokeWidth,
              width: GRID_SIZE,
              height: GRID_SIZE,
              priority: PRIORITY.comment,
              commentElementId: commentableElement.id,
              isVisible: true,
              isClosed: false,
              layer: commentableElement.layer, // CHANGED:ADD 2024-10-7 #2114
            })
          );
        }
      });

    const elements = [
      // Roadmap、Resourceで消去されたスレッドをcanvasに反映
      ..._elements.map((el) => {
        if (isCommentElement(el)) {
          const thread = threads.find((thread) => thread.id === el.id);
          if (thread) {
            return newElementWith(el, {
              isDeleted: thread.isDeleteElementRequired,
            });
          }
        }
        return el;
      }),
      ...newCommentElements];

    if (taskId) {
      const index = elements.findIndex((el) => isTaskElement(el) && el.id === taskId);
      if (index === -1) {
        await opts.project.deleteTask(taskId);
      }
    }

    return {
      // when collaborating, the state may have already been updated at this
      // point (we may have received updates from other clients), so reconcile
      // elements and appState with existing state
      scene: {
        ...scene,
        appState: {
          ...restoreAppState(
            {
              ...scene?.appState,
              theme: scene?.appState?.theme,
            },
            excalidrawAPI.getAppState(),
          ),
          // necessary if we're invoking from a hashchange handler which doesn't
          // go through App.initializeScene() that resets this flag
          isLoading: false,
          selectedElementIds: Object.fromEntries(
            elements
              .filter((el) =>
                (isTaskElement(el) && el.id === taskId) ||
                (isCommentElement(el) && el.id === rootCommentId))
              .map((el) => [el.id, true]),
          ),
        },
        elements,
      },
      isExternalScene: true,
      id: roomLinkData.roomId,
      key: roomLinkData.roomKey,
    };
  }
  return { scene: null, isExternalScene: false };
};

const detectedLangCode = languageDetector.detect() || defaultLang.code;
export const appLangCodeAtom = atom(
  Array.isArray(detectedLangCode) ? detectedLangCode[0] : detectedLangCode,
);

const ExcalidrawWrapper = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [langCode, setLangCode] = useAtom(appLangCodeAtom);

  const { projectId } = useParams();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [project, setProject] = useState<ProjectModel | null>(null);
  const [projectRole, setProjectRole] = useState<ProjectRole | null>(null);

  // initial state
  // ---------------------------------------------------------------------------

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
  }>({ promise: null! });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise =
      resolvablePromise<ExcalidrawInitialDataState | null>();
  }

  useEffect(() => {
    trackEvent("load", "frame", getFrame());
    // Delayed so that the app has a time to load the latest SW
    setTimeout(() => {
      trackEvent("load", "version", getVersion());
    }, VERSION_TIMEOUT);
  }, []);

  useEffect(() => {
    const loadProjectData = async () => {
      if (!projectId) return;

      if (selectedOrganization) {
        const _project = selectedOrganization.projects.find((project) => project.id === projectId);
        let projectUserRole: ProjectRole | null = null;
        if (_project?.roles) {
          Object.keys(_project?.roles).forEach((key) => {
            if (key === loginUser?.id) {
              projectUserRole = _project?.roles[key];
            }
          });
        }
        if (_project?.teams) {
          Object.keys(_project?.teams).forEach((key) => {
            if (selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(loginUser?.id || ""))) {
              // プロジェクトメンバー、プロジェクトチームの権限の高い方を参照
              projectUserRole = Math.min(projectUserRole || ProjectRole.viewer, _project?.teams[key]) as ProjectRole;
            }
          });
        }
        // const organizationRole = selectedOrganization.findUserById(loginUser?.id)?.role;
        const organizationRole = loginUser?.organizationRole;

        if (
          _project &&
          (organizationRole === OrganizationRole.owner || projectUserRole)
        ) {
          await _project.getLayers();

          _project.setOrganizationId(selectedOrganization.id);
          setProject(_project);

          const _projectRole = _project.isArchived
            ? ProjectRole.viewer
            : organizationRole === OrganizationRole.owner
              ? ProjectRole.admin
              : projectUserRole;
          setProjectRole(_projectRole);

          document.title = `${_project.name} - ${APP_NAME}`;
        } else {
          setErrorMessage(t("errors.collabLoadFailed"));
        }
      }
    };

    loadProjectData();
  }, [selectedOrganization, loginUser, projectId]);

  const [excalidrawAPI, excalidrawRefCallback] =
    useCallbackRefState<ExcalidrawImperativeAPI>();

  const [collabAPI] = useAtom(collabAPIAtom);
  // const [, setCollabDialogShown] = useAtom(collabDialogShownAtom);
  const [isCollaborating] = useAtomWithInitialValue(isCollaboratingAtom, () => {
    return isCollaborationLink(window.location.href);
  });

  useHandleLibrary({
    excalidrawAPI,
    getInitialLibraryItems: getLibraryItemsFromStorage,
  });

  useEffect(() => {
    if (!collabAPI || !excalidrawAPI || !selectedOrganization || !loginUser || !project || !projectRole) {
      return;
    }

    const loadImages = (
      data: ResolutionType<typeof initializeScene>,
      isInitialLoad = false,
    ) => {
      if (!data.scene) {
        return;
      }
      if (collabAPI?.isCollaborating()) {
        if (data.scene.elements) {
          collabAPI
            .fetchImageFilesFromFirebase({
              elements: data.scene.elements,
              forceFetchFiles: true,
            })
            .then(({ loadedFiles, erroredFiles }) => {
              excalidrawAPI.addFiles(loadedFiles);
              updateStaleImageStatuses({
                excalidrawAPI,
                erroredFiles,
                elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
              });
            });
        }
      } else {
        const fileIds =
          data.scene.elements?.reduce((acc, element) => {
            if (isInitializedImageElement(element)) {
              return acc.concat(element.fileId);
            }
            return acc;
          }, [] as FileId[]) || [];

        if (data.isExternalScene) {
          loadFilesFromFirebase(
            `${FIREBASE_STORAGE_PREFIXES.shareLinkFiles}/${data.id}`,
            data.key,
            fileIds,
          ).then(({ loadedFiles, erroredFiles }) => {
            excalidrawAPI.addFiles(loadedFiles);
            updateStaleImageStatuses({
              excalidrawAPI,
              erroredFiles,
              elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
            });
          });
        } else if (isInitialLoad) {
          if (fileIds.length) {
            LocalData.fileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  excalidrawAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  excalidrawAPI,
                  erroredFiles,
                  elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
          // on fresh load, clear unused files from IDB (from previous
          // session)
          LocalData.fileStorage.clearObsoleteFiles({ currentFileIds: fileIds });
        }
      }
    };

    // CHANGED:UPDATE 2023/07/28 #864
    // initializeScene({ collabAPI, excalidrawAPI }).then(async (data) => {
    initializeScene({
      collabAPI,
      excalidrawAPI,
      project,
    }).then(async (data) => {
      if (data.scene) {
        loadImages(data, /* isInitialLoad */ true);

        // CHANGED:ADD 2023-2-4 #576
        const elements = data.scene?.elements || [];

        const jobElements = Job.getJobElements(elements);

        if (jobElements.length === 0) {
          const offsetY = CANVAS_HEADER_HEIGHT;
          const height = DEFAULT_JOB_HEIGHT;
          let jobsHeight = 0;

          for (let i = 0; i < NUMBER_OF_JOBS; i++) {
            const jobElement = newJobElement({
              x: 0,
              y: jobsHeight + offsetY,
              strokeColor: ColorsEx.lineColor.border, // CHANGED:UPDATE 2023-03-01 #726
              backgroundColor: ColorsEx.backgroundColor.white,
              width: JOB_ELEMENTS_WIDTH,
              height,
              priority: PRIORITY.job,
            });

            jobsHeight += height;
            jobElements.push(jobElement);
          }

          data.scene = {
            ...data.scene,
            elements: [...elements, ...jobElements],
          };
        }

        initialStatePromiseRef.current.promise.resolve(data.scene);
      }
    });

    const onHashChange = async (event: HashChangeEvent) => {
      event.preventDefault();
      const libraryUrlTokens = parseLibraryTokensFromUrl();
      if (!libraryUrlTokens) {
        if (
          collabAPI?.isCollaborating() &&
          !isCollaborationLink(window.location.href)
        ) {
          collabAPI.stopCollaboration(false);
        }
        excalidrawAPI.updateScene({ appState: { isLoading: true } });

        // CHANGED:UPDATE 2023/07/28 #864
        // initializeScene({ collabAPI, excalidrawAPI }).then((data) => {
        initializeScene({
          collabAPI,
          excalidrawAPI,
          project,
        }).then((data) => {
          loadImages(data);
          if (data.scene) {
            excalidrawAPI.updateScene({
              ...data.scene,
              ...restore(data.scene, null, null, { repairBindings: true }),
              commitToHistory: true,
              replaceScene: true, // CHANGED:ADD 2023-1-16 #425
            });
          }
        });
      }
    };

    // const titleTimeout = setTimeout(
    //   () => (document.title = APP_NAME),
    //   TITLE_TIMEOUT,
    // );

    const syncData = debounce(() => {
      if (isTestEnv()) {
        return;
      }
      if (
        !document.hidden &&
        ((collabAPI && !collabAPI.isCollaborating()))
      ) {
        // don't sync if local state is newer or identical to browser state
        if (isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_DATA_STATE)) {
          const localDataState = importFromLocalStorage();
          const username = importUsernameFromLocalStorage();
          let langCode = languageDetector.detect() || defaultLang.code;
          if (Array.isArray(langCode)) {
            langCode = langCode[0];
          }
          setLangCode(langCode);
          excalidrawAPI.updateScene({
            ...localDataState,
          });
          excalidrawAPI.updateLibrary({
            libraryItems: getLibraryItemsFromStorage(),
          });
          collabAPI?.setUsername(username || "");
        }

        if (isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_FILES)) {
          const elements = excalidrawAPI.getSceneElementsIncludingDeleted();
          const currFiles = excalidrawAPI.getFiles();
          const fileIds =
            elements?.reduce((acc, element) => {
              if (
                isInitializedImageElement(element) &&
                // only load and update images that aren't already loaded
                !currFiles[element.fileId]
              ) {
                return acc.concat(element.fileId);
              }
              return acc;
            }, [] as FileId[]) || [];
          if (fileIds.length) {
            LocalData.fileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  excalidrawAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  excalidrawAPI,
                  erroredFiles,
                  elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
        }
      }
    }, SYNC_BROWSER_TABS_TIMEOUT);

    const onUnload = () => {
      LocalData.flushSave();
    };

    const visibilityChange = (event: FocusEvent | Event) => {
      if (event.type === EVENT.BLUR || document.hidden) {
        LocalData.flushSave();
      }
      if (
        event.type === EVENT.VISIBILITY_CHANGE ||
        event.type === EVENT.FOCUS
      ) {
        syncData();
      }
    };

    // CHANGED:ADD 2023/08/19 #921
    const handlePopState = () => {
      if (collabAPI.isCollaborating()) {
        window.location.reload();
      }
      window.removeEventListener('popstate', handlePopState);
    }

    window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);
    window.addEventListener(EVENT.UNLOAD, onUnload, false);
    window.addEventListener(EVENT.BLUR, visibilityChange, false);
    document.addEventListener(EVENT.VISIBILITY_CHANGE, visibilityChange, false);
    window.addEventListener(EVENT.FOCUS, visibilityChange, false);
    window.addEventListener('popstate', handlePopState); // CHANGED:ADD 2023/08/19 #921
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
      window.removeEventListener(EVENT.UNLOAD, onUnload, false);
      window.removeEventListener(EVENT.BLUR, visibilityChange, false);
      window.removeEventListener(EVENT.FOCUS, visibilityChange, false);
      // window.removeEventListener('popstate', handlePopState); // CHANGED:ADD 2023/08/19 #921
      document.removeEventListener(
        EVENT.VISIBILITY_CHANGE,
        visibilityChange,
        false,
      );
      // clearTimeout(titleTimeout);
    };
  }, [collabAPI, excalidrawAPI, setLangCode, selectedOrganization, project, projectRole]);

  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      LocalData.flushSave();

      if (
        excalidrawAPI &&
        LocalData.fileStorage.shouldPreventUnload(
          excalidrawAPI.getSceneElements(),
        )
      ) {
        preventUnload(event);
      }
    };
    window.addEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    return () => {
      window.removeEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    };
  }, [excalidrawAPI]);

  useEffect(() => {
    languageDetector.cacheUserLanguage(langCode);
  }, [langCode]);

  // const [theme, setTheme] = useState<Theme>(
  //   () =>
  //     localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_THEME) ||
  //     // FIXME migration from old LS scheme. Can be removed later. #5660
  //     importFromLocalStorage().appState?.theme ||
  //     THEME.LIGHT,
  // );

  // useEffect(() => {
  //   localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_THEME, theme);
  //   // currently only used for body styling during init (see public/index.html),
  //   // but may change in the future
  //   document.documentElement.classList.toggle("dark", theme === THEME.DARK);
  // }, [theme]);
  const [theme, setTheme] = useState<Theme>(THEME.LIGHT);

  const onChange = (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => {
    if (collabAPI?.isCollaborating()) {
      // CHANGED:ADD 2023/08/29 #950
      if (
        appState.selectedLinkElement ||
        isLinkElement(appState.editingElement)
      ) {
        return;
      }

      // CHANGED:UPDATE 2023/08/03 #884
      // collabAPI.syncElements(elements);
      collabAPI.syncElements(elements, appState);
    }

    // CHANGED:ADD 2024/04/15 #1926
    const loginUserState = clearAppStateForLoginUser(appState);
    if (!isEqual(loginUserState, loginUser?.appState)) {
      loginUser?.setAppState(loginUserState);
    }

    // setTheme(appState.theme);

    // // this check is redundant, but since this is a hot path, it's best
    // // not to evaludate the nested expression every time
    // if (!LocalData.isSavePaused()) {
    //   // CHANGED:ADD 2023-1-24 #438
    //   if (
    //     appState.selectedLinkElement ||
    //     isLinkElement(appState.editingElement)
    //   ) {
    //     return;
    //   }

    //   LocalData.save(elements, appState, files, () => {
    //     if (excalidrawAPI) {
    //       let didChange = false;

    //       const elements = excalidrawAPI
    //         .getSceneElementsIncludingDeleted()
    //         .map((element) => {
    //           if (
    //             LocalData.fileStorage.shouldUpdateImageElementStatus(element)
    //           ) {
    //             const newElement = newElementWith(element, { status: "saved" });
    //             if (newElement !== element) {
    //               didChange = true;
    //             }
    //             return newElement;
    //           }
    //           return element;
    //         });

    //       if (didChange) {
    //         excalidrawAPI.updateScene({
    //           elements,
    //         });
    //       }
    //     }
    //   });
    // }
  };

  // CHANGED:REMOVE 2023/07/25 #874
  // const onExportToBackend = async (
  //   exportedElements: readonly NonDeletedExcalidrawElement[],
  //   appState: AppState,
  //   files: BinaryFiles,
  //   canvas: HTMLCanvasElement,
  // ) => {
  //   if (exportedElements.length === 0) {
  //     return window.alert(t("alerts.cannotExportEmptyCanvas"));
  //   }
  //   if (canvas) {
  //     try {
  //       const result = await exportToBackend(
  //         exportedElements,
  //         {
  //           ...appState,
  //           viewBackgroundColor: appState.exportBackground
  //             ? appState.viewBackgroundColor
  //             : getDefaultAppState().viewBackgroundColor,
  //         },
  //         files,
  //       );

  //       // CHANGED:ADD 2023-2-12 #512
  //       excalidrawAPI?.updateScene({
  //         appState: {
  //           ...appState,
  //           cloudFileHandle: result
  //             ? {
  //               id: result?.id,
  //               key: result?.key,
  //             }
  //             : null,
  //           openDialog: result ? "shareLink" : null, // CHANGED:ADD 2023-3-6 #741
  //         },
  //       });
  //     } catch (error: any) {
  //       if (error.name !== "AbortError") {
  //         const { width, height } = canvas;
  //         console.error(error, { width, height });
  //         setErrorMessage(error.message);
  //       }
  //     }
  //   }
  // };

  const renderCustomStats = (
    elements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
  ) => {
    return (
      <CustomStatsEx
        setToast={(message) => excalidrawAPI!.setToast({ message })}
        appState={appState}
        elements={elements}
      />
    );
  };

  // CHANGED:ADD 2023/08/26 #957
  const renderCustomSidebar = () => {
    return <CustomSidebar />;
  };

  const onLibraryChange = async (items: LibraryItems) => {
    if (!items.length) {
      localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY);
      return;
    }
    const serializedItems = JSON.stringify(items);
    localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY, serializedItems);
  };

  const onDeleteProject = useCallback(async () => {
    try {
      await project?.delete();
      collabAPI?.stopCollaboration(false);
      navigate(Paths.projects, { state: { message: t("alerts.deletedProject") } });
    } catch (error) {
      console.log(error);
      setErrorMessage(t("errors.deleteProjectFailed"));
    }
  }, [collabAPI, project, navigate]);

  const isOffline = useAtomValue(isOfflineAtom);

  if (!project) return null;

  const viewLayers = project.layers.filter(
    (layer) => !layer.isDeleted && !!layer.visibleUsers[loginUser?.id || ""]
  );

  return (
    <>
      <div
        // CHANGED:UPDATE 2023/07/27 #880
        // style={{ height: "100%", width: "calc(100% - 200px)", zIndex: 0 }}
        className={clsx("conpath-app", {
          "is-collaborating": isCollaborating,
        })}
      >
        <div className="toolbar-header" /> {/* ツールバー用（画面上側）に隙間を設定 #933 */}
        <div style={{ height: "100%", width: "100%", display: "flex" }}>
          <div className="toolbar-default" /> {/* ツールバー用（画面左側）に隙間を設定 #933 */}
          <Excalidraw
            ref={excalidrawRefCallback}
            onChange={onChange}
            initialData={initialStatePromiseRef.current.promise}
            isCollaborating={isCollaborating}
            onPointerUpdate={collabAPI?.onPointerUpdate}
            // viewModeEnabled={false}
            zenModeEnabled={false}
            gridModeEnabled={true}
            UIOptions={{
              canvasActions: {
                // toggleTheme: true,
                toggleTheme: false,
                export: {
                  // onExportToBackend, // CHANGED:REMOVE 2023/07/25 #874
                  // renderCustomUI: (elements, appState, files) => {
                  //   return (
                  //     <ExportToExcalidrawPlus
                  //       elements={elements}
                  //       appState={appState}
                  //       files={files}
                  //       onError={(error) => {
                  //         excalidrawAPI?.updateScene({
                  //           appState: {
                  //             errorMessage: error.message,
                  //           },
                  //         });
                  //       }}
                  //     />
                  //   );
                  // },
                },
              },
            }}
            langCode={langCode}
            renderCustomStats={renderCustomStats}
            renderSidebar={renderCustomSidebar}
            detectScroll={false}
            handleKeyboardGlobally={true}
            onLibraryChange={onLibraryChange}
            autoFocus={true}
            theme={theme}
            onDeleteProject={onDeleteProject}
            initialAppState={(
              {
                ...loginUser?.appState,
                organizationId: project.organizationId, // CHANGED:ADD 2024/02/06 #1579
                projectId,
                projectName: project.name,
                projectStartDate: firebaseTimeToDate(project.startDate), // CHANGED:ADD 2023/07/28 #864
                projectEndDate: firebaseTimeToDate(project.endDate), // CHANGED:ADD 2023/07/28 #864
                projectRole: projectRole || ProjectRole.viewer, // CHANGED:ADD 2023/08/10 #904
                projectMembers: selectedOrganization?.users
                  .filter((user) =>
                    (project.roles && project.roles[user.id]) ||
                    (project.teams && Object.keys(project.teams).some((key) =>
                      selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
                    ))
                  ).map((user) => user.id), // CHANGED:ADD 2024-02-26 #1707
                holidays: project.holidays,
                viewBackgroundColor: project.backgroundColor,
                criticalPathColor: selectedOrganization?.criticalPathColor as CriticalPathColor || CriticalPathColor.primary, // CHANGED:ADD 2024/02/22 #1653
                selectedLayer: project.layers.length > 0
                  ? project.layers.find((layer) => !layer.isDeleted && !!layer.selectedUsers[loginUser?.id || ""])?.index || project.layers[0].index
                  : 0,
                viewLayers: project.layers.length > 0
                  ? (viewLayers.length > 0
                    ? viewLayers.map((layer) => layer.index)
                    : [project.layers[0].index])
                  : [0],
              }
            )}
            selectedProject={project}
          // renderTopRightUI={(isMobile) => {
          //   if (isMobile) {
          //     return null;
          //   }
          //   return (
          //     <LiveCollaborationTrigger
          //       isCollaborating={isCollaborating}
          //       onSelect={() => setCollabDialogShown(true)}
          //     />
          //   );
          // }}
          >
            <AppMainMenuEx />
            {/* <AppWelcomeScreen setCollabDialogShown={setCollabDialogShown} /> */}
            {/* <AppFooter /> */}
            {isCollaborating && isOffline && (
              <div className="collab-offline-warning">
                {t("alerts.collabOfflineWarning")}
              </div>
            )}
          </Excalidraw>
          {
            excalidrawAPI &&
            loginUser &&
            project &&
            <Collab
              excalidrawAPI={excalidrawAPI}
              loginUser={loginUser}
              project={project}
            />
          }
          {message && (
            <Dialog small onCloseRequest={() => setMessage("")} title={""}>
              {message}
            </Dialog>
          )}
          {errorMessage && (
            <ErrorDialog onClose={() => setErrorMessage("")}>
              {errorMessage}
            </ErrorDialog>
          )}
        </div>
      </div>
    </>
  );
};

const Project: React.FC = observer(() => {
  return (
    // <TopErrorBoundary>
    <Provider unstable_createStore={() => appJotaiStore}>
      <ExcalidrawWrapper />
    </Provider>
    // </TopErrorBoundary>
  );
});

export default Project;
