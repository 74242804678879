import { useCallback, useEffect, useRef, useState } from "react";
import OpenColor from "open-color";

import { Dialog } from "src/excalidraw/components/Dialog";
import { t } from "src/excalidraw/i18n";

import { AppState, LibraryItem } from "src/excalidraw/types";
import { exportToCanvas } from "src/excalidraw/packages/utils";
import DialogActionButton from "src/excalidraw/components/DialogActionButton";

import "./EditLibrary.scss";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import { ExcalidrawElement, NonDeleted } from "src/excalidraw/element/types";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import Checkbox from "src/conpath/components/CheckBox";

interface EditLibraryDataParams {
  name: string;
  category: string;
}

const RenderPreviewElements = ({
  elements,
  appState,
}: {
  elements: readonly NonDeleted<ExcalidrawElement>[];
  appState: AppState;
}) => {
  const svgRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = svgRef.current;
    if (!node) {
      return;
    }
    (async () => {
      exportToCanvas({
        elements,
        appState: {
          ...appState,
          viewBackgroundColor: OpenColor.white,
          exportBackground: true,
        },
        exportPadding: 20,
        backgroundHTML: false,
        files: null,
      }).then((canvas) => {
        node.replaceChildren(canvas);
      });
    })();
  }, [elements, appState]);

  return (
    <div className="render-library-item">
      <div ref={svgRef} className="render-library-item__canvas" />
    </div>
  );
};

const EditLibrary = observer(({
  libItem,
  appState,
  onConfirm,
  onCancel,
}: {
  libItem: LibraryItem;
  appState: AppState;
  onConfirm: () => void,
  onCancel: () => void,
}) => {
  const setAppState = useExcalidrawSetAppState();
  const { organizationStore } = useStore();
  const { selectedOrganization } = organizationStore;

  const [libraryData, setLibraryData] = useState<EditLibraryDataParams>({
    name: libItem.name || "",
    category: libItem.category || "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInputChange = (event: any) => {
    setLibraryData({
      ...libraryData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const libraryModel = selectedOrganization?.libraries.find((library) => library.id === libItem.id);
    libraryModel?.setName(libraryData.name);
    libraryModel?.setCategory(libraryData.category);
    await libraryModel?.updateLibrary().then(() => {
      libItem.name = libraryData.name;
      libItem.category = libraryData.category;

      setAppState({
        toast: { message: t("toast.updateToLibrary") },
      });

      onConfirm();
    }).catch((err) => {
      console.error(err);
      setIsSubmitting(false);
    });
  };

  return (
    <Dialog
      onCloseRequest={onCancel}
      title={t("editLibrary.title")}
      className="edit-library"
      small={true}
    >
      <form onSubmit={onSubmit}>
        <div className="edit-library__fields">
          <div className="edit-library__field">
            <label>
              <p>{t("editLibrary.libraryName")}</p>
              <span aria-hidden="true" className="required">
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              required
              value={libraryData.name}
              onChange={onInputChange}
              placeholder={t("editLibrary.placeholder.libraryName")}
            />
          </div>
          <div className="edit-library__field">
            <label>
              <p>{t("editLibrary.category")}</p>
              <span aria-hidden="true" className="required">
                *
              </span>
            </label>
            <input
              type="text"
              name="category"
              required
              value={libraryData.category}
              onChange={onInputChange}
              placeholder={t("editLibrary.placeholder.category")}
            />
          </div>
          <div className="edit-library__checkbox-wrapper">
            <p>・オプション</p>
            <div className="edit-library__checkbox-container">
              <label>
                <Checkbox
                  props={{
                    checked: !!libItem.taskChildren?.assignUsers?.length,
                    sx: {
                      color: "#DDDDDD",
                      backgroundImage: "linear-gradient(to right, white, white)",
                      borderRadius: 0,
                      height: 18,
                      width: 18,
                    },
                  }}
                />
                <p>{t("editLibrary.addAssignUsers")}</p>
              </label>
              <label>
                <Checkbox
                  props={{
                    checked: !!libItem.taskChildren?.assignResources?.length,
                    sx: {
                      color: "#DDDDDD",
                      backgroundImage: "linear-gradient(to right, white, white)",
                      borderRadius: 0,
                      height: 18,
                      width: 18,
                    },
                  }}
                />
                <p>{t("editLibrary.addAssignResources")}</p>
              </label>
            </div>
          </div>
        </div>
        <RenderPreviewElements
          elements={libItem.elements}
          appState={appState}
        />
        <div className="edit-library__buttons">
          <DialogActionButton
            label={t("buttons.cancel")}
            onClick={onCancel}
            data-testid="cancel-clear-canvas-button"
          />
          <DialogActionButton
            type="submit"
            label={t("editLibrary.submit")}
            actionType="primary"
            isLoading={isSubmitting}
            className="font-bold"
          />
        </div>
      </form>
    </Dialog>
  );
});

export default EditLibrary;
