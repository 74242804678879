import { UploadedFileResponse } from "./Comment";
import { CreateOrganizationUsersRequest } from "./User";


export interface ResendInvitationRequestParm {
  organizationId: string; 
  userEmail: string;
};

export interface CreateOrganizationRequestParam {
  organizationName: string;
  organizationPhoneNumber: string;
  plan: number,
  userId: string;
};

export interface AddOrganizationUserRequestParam {
  organizationId: string;
  users: CreateOrganizationUsersRequest[]
};

export interface AddOrganizationUserResponse {
  failedEmails: { email: string;
  message: string }[];
};

export interface RemoveUserFromOrganizationParam {
  userId: string;
  organizationId: string;
};

export interface AcceptInvitationRequestParam {
  organizationId: string;
  userId: string;
  invitationId: string;
};

export interface DeclineInvitationRequestParam extends AcceptInvitationRequestParam {
  inviteeEmail: string;
  skipDeletingAccount: boolean;
};

export interface SendCommentRequestParam {
  id?: string | null;
  projectId: string;
  projectName: string;
  organizationId: string;
  organizationName: string;
  text: string;
  commentElementId: string;
  rootCommentId?: string | null;
  mentioningTo: string[];
  uploadedFiles: UploadedFileResponse[];
};

export const ThreadUpdateType = {
  RESTORE: "RESTORE",
  DELETE: "DELETE",
} as const;

export type ThreadUpdateType = typeof ThreadUpdateType[keyof typeof ThreadUpdateType];

export interface UpdatingThreadParam {
  id: string;
  type: ThreadUpdateType;
  isDeleted: boolean;
};

export interface DeleteOrRestoreCommentsParam {
  projectId: string;
  organizationId: string;
  threads: UpdatingThreadParam[];
};