import React, { useMemo, useState } from "react";
import { ActionManager } from "src/excalidraw/actions/manager";
import Scene from "src/excalidraw/scene/Scene";
import { AppState } from "src/excalidraw/types";
import "./FixedLayerUI.scss";
import Calendar from "src/excalidraw/extensions/calendar";
import Scroll from "src/excalidraw/extensions/scene/scroll";
import { CANVAS_HEADER_HEIGHT, JOB_ELEMENTS_WIDTH } from "src/excalidraw/constants";
import { ProjectRole } from "src/conpath/constants/Role";
import { atom, useAtom } from "jotai";
import { jotaiScope } from "../../jotai";
import DropdownMenu from "src/excalidraw/components/dropdownMenu/DropdownMenu";
import { CalendarIcon, SettingsIcon, TrashIcon } from "src/excalidraw/components/icons";
import { Button } from "src/conpath/components/Button";

import { t } from "src/excalidraw/i18n";

import DialogActionButton from "./DialogActionButton";

interface Props {
  actionManager: ActionManager;
  appState: AppState;
  scene: Scene;
  calendar: Calendar;
  scroll: Scroll;
  setAppState: React.Component<any, AppState>["setState"];
}

export const isSettingsMenuOpenAtom = atom(false);

/**
 * @param appState AppState
 * @returns
 */
const FixedLayerUI = ({ actionManager, appState, setAppState }: Props) => {
  const renderAction = useMemo(
    () => actionManager.renderAction("expandCollapseAllJobRow"),
    [actionManager, appState],
  );

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useAtom(
    isSettingsMenuOpenAtom,
    jotaiScope,
  );

  const startDate = new Date(appState.projectStartDate);
  const endDate = new Date(appState.projectEndDate);
  const viewMode = appState.projectRole !== ProjectRole.viewer && !appState.viewModeEnabled;
  const isProjectAdmin = appState.projectRole == ProjectRole.admin;

  const projectDate = (date: Date) => {
    return (
      <div className="fixed__date__wrapper__columns">
        <span className="fixed__date year-num">
          {date.getFullYear()}
          <span className="year-num__unit">年</span>
        </span>
        <span className="fixed__date date-num">
          {("0" + (date.getMonth() + 1)).slice(-2)}
        </span>
        月
        <span className="fixed__date date-num">
          {("0" + date.getDate()).slice(-2)}
        </span>
        日
        <span className="fixed__date day-week">
          {date.toLocaleString("default", { weekday: "short" })}
        </span>
      </div>
    );
  };

  const renderSettignsMenu = () => {
    return (
      <DropdownMenu open={isSettingsMenuOpen}>
        <DropdownMenu.Trigger
          className="Sidebar__dropdown-btn"
          onToggle={() => setIsSettingsMenuOpen(!isSettingsMenuOpen)}
        >
          {SettingsIcon}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          onClickOutside={() => setIsSettingsMenuOpen(false)}
          onSelect={() => setIsSettingsMenuOpen(false)}
          className="library-menu"
        >
          <DropdownMenu.Item
            icon={SettingsIcon}
            onSelect={() => {
              setAppState({ openDialog: "settings" });
            }}
            data-testid=""
            aria-label={t("settingsDialog.title")}
          >
            {t("settingsDialog.title")}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            icon={CalendarIcon}
            onSelect={() => {
              setAppState({ openDialog: "datePicker" });
            }}
            data-testid=""
            aria-label={t("datePickerDialog.title")}
          >
            {t("datePickerDialog.title")}
          </DropdownMenu.Item>
          {isProjectAdmin &&
            <>
              <DialogActionButton
                customClass="button cancel w-full font-bold"
                label={t("buttons.deleteProject")}
                style={{ padding: "11px", fontSize: "14px", fontWeight: "lighter", marginTop: "10px" }}
                onClick={() => {
                  setAppState({ openDialog: "deleteProject" });
                }}
                actionType="danger"
              />
            </>
          }
        </DropdownMenu.Content>
      </DropdownMenu>
    );
  };

  return (
    <>
      {!appState.isLoading && (
        <div
          id="fixed__wrapper"
          className="fixed__wrapper"
          style={{
            width: JOB_ELEMENTS_WIDTH,
            height: CANVAS_HEADER_HEIGHT,
            transformOrigin: "left top",
            transform: `scale(${appState.zoom.value})`,
          }}
        >
          <div>
            <div className="fixed__date__wrapper">
              <span className="fixed__date__wrapper__title">工期</span>
              {projectDate(startDate)}
              <span className="fixed__date__wrapper__tilde">〜</span>
              {projectDate(endDate)}
              {viewMode &&
                renderSettignsMenu()
              }
            </div>
            {viewMode &&
              renderAction
            }
          </div>
        </div>
      )}
    </>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  if (
    prevProps.appState.jobsHeight !== nextProps.appState.jobsHeight ||
    prevProps.appState.projectStartDate !== nextProps.appState.projectStartDate ||
    prevProps.appState.projectEndDate !== nextProps.appState.projectEndDate ||
    prevProps.appState.projectRole !== nextProps.appState.projectRole ||
    prevProps.appState.zoom.value !== nextProps.appState.zoom.value ||
    prevProps.appState.isLoading !== nextProps.appState.isLoading ||
    prevProps.appState.viewModeEnabled !== nextProps.appState.viewModeEnabled
  ) {
    return false;
  }
  return true;
}

export default React.memo(FixedLayerUI, areEqual);
