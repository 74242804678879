import clsx from "clsx";
import { actionShortcuts } from "src/excalidraw/actions";
import { ActionManager } from "src/excalidraw/actions/manager";
import { NonDeletedExcalidrawElement } from "../../element/types";
import { t } from "src/excalidraw/i18n";
import {
  AppClassProperties,
  AppProps,
  AppState,
} from "src/excalidraw/types";
import {
  ExitZenModeAction,
  FinalizeAction,
  ShapesSwitcher,
  UndoRedoActions,
  ZoomActions,
} from "../../components/Actions";
import { useDevice } from "../../components/App";
import { useTunnels } from "../../components/context/tunnels";
import { HelpButton } from "../../components/HelpButton";
import { Section } from "../../components/Section";
import Stack from "../../components/Stack";

import { HintViewerEx } from "../../components/HintViewer"; // CHANGED:UPDATE 2023/09/01 #973
import { Island } from "../../components/Island";
import { LockButton } from "../../components/LockButton";
import { PenModeButton } from "../../components/PenModeButton";
import { HandButton } from "../../components/HandButton";
import { isHandToolActive } from "../../appState";
import { LayerButton } from "./LayerButton";
import { actionLayerSettings } from "../actions/actionLayer";

const ToolBarEx = ({
  appState,
  actionManager,
  showExitZenModeBtn,
  canvas,
  setAppState,
  elements,
  onPenModeToggle,
  onLockToggle,
  onHandToolToggle,
  renderWelcomeScreen,
  UIOptions,
  app,
}: {
  appState: AppState;
  actionManager: ActionManager;
  showExitZenModeBtn: boolean;
  canvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  onPenModeToggle: AppClassProperties["togglePenMode"];
  onLockToggle: () => void;
  onHandToolToggle: () => void;
  renderWelcomeScreen: boolean;
  UIOptions: AppProps["UIOptions"];
  app: AppClassProperties;
}) => {
  const { footerCenterTunnel, welcomeScreenHelpHintTunnel, welcomeScreenToolbarHintTunnel } = useTunnels();

  const device = useDevice();
  const showFinalize =
    !appState.viewModeEnabled && appState.multiElement && device.isTouchScreen;

  return (
    <footer
      role="contentinfo"
      className="layer-ui__wrapper__footer App-menu App-menu_bottom"
    >
      <div
        className={clsx("layer-ui__wrapper__footer-left zen-mode-transition", {
          "layer-ui__wrapper__footer-left--transition-left":
            appState.zenModeEnabled,
        })}
      >
        <Stack.Col gap={2} className="undo-redo-zoom-actions-container">
          <Section heading="canvasActions">
            {!appState.viewModeEnabled && (
              <UndoRedoActions
                renderAction={actionManager.renderAction}
                className={clsx("undo-redo-actions", {
                  "layer-ui__wrapper__footer-left--transition-bottom":
                    appState.zenModeEnabled,
                })}
              />
            )}

            <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            />

            {showFinalize && (
              <FinalizeAction
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-left":
                    appState.zenModeEnabled,
                })}
              />
            )}
          </Section>
        </Stack.Col>
      </div>
      {!appState.viewModeEnabled && (
        <Section heading="shapes" className="toolbar-section">
          {(heading: React.ReactNode) => (
            <Stack.Col gap={4} align="start">
              <Stack.Row
                gap={1}
                className={clsx("App-toolbar-container", {
                  "zen-mode": appState.zenModeEnabled,
                })}
              >
                <Island
                  padding={1}
                  className={clsx("App-toolbar", "left-sidebar", {
                    "zen-mode": appState.zenModeEnabled,
                  })}
                >
                  {/* CHANGED:ADD 2023/09/01 #973 */}
                  <HintViewerEx
                    appState={appState}
                    elements={elements}
                    isMobile={device.isMobile}
                    device={device}
                  />
                  <Stack.Col gap={2}>
                    <PenModeButton
                      zenModeEnabled={appState.zenModeEnabled}
                      checked={appState.penMode}
                      onChange={() => onPenModeToggle(null)}
                      title={t("toolBar.penMode")}
                      penDetected={appState.penDetected}
                    />
                    <LockButton
                      checked={appState.activeTool.locked}
                      onChange={onLockToggle}
                      title={t("toolBar.lock")}
                    />

                    <div className="App-toolbar__divider"></div>

                    <HandButton
                      checked={isHandToolActive(appState)}
                      onChange={() => onHandToolToggle()}
                      title={t("toolBar.hand")}
                      isMobile
                    />
                    <ShapesSwitcher
                      appState={appState}
                      activeTool={appState.activeTool}
                      UIOptions={UIOptions}
                      app={app}
                    />
                  </Stack.Col>
                </Island>
              </Stack.Row>
            </Stack.Col>
          )}
        </Section>
      )}
      <footerCenterTunnel.Out />
      <div
        className={clsx("layer-ui__wrapper__footer-right zen-mode-transition", {
          "transition-right disable-pointerEvents": appState.zenModeEnabled,
        })}
      ></div>
      <div
        className={clsx("layer-ui__wrapper__footer-right zen-mode-transition", {
          "transition-right disable-pointerEvents": appState.zenModeEnabled,
        })}
      >
        <div className="help-icon-container">
          {renderWelcomeScreen && <welcomeScreenHelpHintTunnel.Out />}
          <HelpButton
            onClick={() => actionManager.executeAction(actionShortcuts)}
          />
        </div>
        {/* CHANGED:ADD 2024-10-5 #2114 */}
        <div className="layer-icon-container">
          <LayerButton
            onClick={() => actionManager.executeAction(actionLayerSettings)}
          />
        </div>
      </div>
      <ExitZenModeAction
        actionManager={actionManager}
        showExitZenModeBtn={showExitZenModeBtn}
      />
    </footer>
  );
};

export default ToolBarEx;
ToolBarEx.displayName = "ToolBarEx";
