import dayjs from "dayjs";

/**
 * STANDARD: YYYY/MM/DD (DAY)
 * JAPANESE_ORIGINAL: YYYY年MM月DD日 (曜日)
 * COMMENT: YYYY/MM/DD (DAY) or 20時間前/20分前
 */
export enum DATE_FORMAT {
  STANDARD,
  JAPANESE_ORIGINAL,
  COMMENT
};

const DAY_STRING = [
  "日",
  "月",
  "火",
  "水",
  "木",
  "金",
  "土",
]

/**
 * Date -> YYYY年MM月DD日（DAY）
 * @param date 
 */
export const formatDate = (d: Date | null, format: DATE_FORMAT = DATE_FORMAT.STANDARD): string => {
  if (!d) return "";
  
  const _d = dayjs(d);
  const now = dayjs();
  const minuteDiff = now.diff(_d, "minutes");
  if (format === DATE_FORMAT.COMMENT && 
      0 <= minuteDiff && (minuteDiff / 24 / 60) < 1) {
    if (minuteDiff > 60) return `${Math.round(minuteDiff / 60)}時間前`;
    return `${minuteDiff}分前`;
  }

  if (format === DATE_FORMAT.STANDARD) return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}(${getDayInJa(d.getDay())})`

  return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日(${getDayInJa(d.getDay())})`
};

export const getDayInJa = (day: number): string => {
  return DAY_STRING[day] ? DAY_STRING[day] : "";
}