import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import SettingsLayout from 'src/conpath/components/settings/SettingLayout';
import { Button } from 'src/conpath/components/Button';
import { Dialog, Transition } from '@headlessui/react';

//interfaces
//mobx
import { observer } from "mobx-react-lite";
import { useStore } from 'src/conpath/hooks/useStore';
//others
import { Paths } from 'src/conpath/constants/Routes';
import "./AccountSettings.scss";
import { EditIcon } from 'src/conpath/components/icons';



const AccountSettings: React.FC = observer(() => {

  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
  const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = useState<boolean>(false);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState<boolean>(false);

  const newEmailRef = useRef<HTMLInputElement>(null);
  const confirmEmailRef = useRef<HTMLInputElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  function closeUpdateEmailModal() {
    setModalErrorMessage("");
    setIsUpdateEmailModalOpen(false);
  }

  function openUpdateEmailModal() {
    setIsUpdateEmailModalOpen(true);
  }

  function closeUpdatePassowordModal() {
    setModalErrorMessage("");
    setIsUpdatePasswordModalOpen(false);
  }

  function openUpdatePasswordModal() {
    setIsUpdatePasswordModalOpen(true);
  }


  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  /**
   * メールアドレスの変更
   */
  const onUpdateEmailButtonPressed = useCallback(async () => {
    if (!loginUser || !selectedOrganization) return;

    const result = await loginUser.updateEmail({
      newEmail: newEmailRef.current?.value || "",
      confirmEmail: confirmEmailRef.current?.value || "",
    });

    if (result.error) {
      setModalErrorMessage(result.error);
      return;
    }

    await userStore.setUser(loginUser.id);
    userStore.loginUser?.setOrganizationRole(selectedOrganization);
    setSuccessMessage("メールアドレスを更新しました。");
    closeUpdateEmailModal();

  }, [loginUser, selectedOrganization]);

  /**
   * パスワードの変更
   */
  const onUpdatePasswordButtonPressed = useCallback(async () => {
    if (!loginUser) return;

    const result = await loginUser.updatePassword({
      currentPassword: currentPasswordRef.current?.value || "",
      newPassword: newPasswordRef.current?.value || "",
      confirmPassword: confirmPasswordRef.current?.value || "",
    });

    if (result.error) {
      setModalErrorMessage(result.error);
      return;
    }

    setSuccessMessage("パスワードを更新しました。");
    closeUpdatePassowordModal();

  }, [loginUser]);

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle='アカウント'
        currentPath={Paths.account}>
        <div className="setting-body">
          <div className="section">
            <div className="form__wrapper">
              <div className="form-container">
                <div className="selection-wrapper">
                  <p>メールアドレス</p>
                  <div className="flex w-full">
                    <label>
                      {loginUser?.email}
                    </label>
                    <EditIcon 
                      onClick={openUpdateEmailModal}
                      className='flex ml-2 transition-all hover:opacity-70 cursor-pointer'
                    />
                  </div>
                  <Transition appear show={isUpdateEmailModalOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeUpdateEmailModal}>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black/25" />
                      </Transition.Child>

                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              {/* <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                メールアドレス変更
                              </Dialog.Title> */}
                              <div className="mb-4">
                                <p className="text-sm text-gray-500">
                                  新しいメールアドレスを入力してください。
                                </p>
                              </div>
                              <div className="mb-4">
                                <label
                                  className="block text-[14px]">
                                  現在のメールアドレス
                                </label>
                                <label
                                  className="pt-2 block text-[16px]">
                                  {loginUser?.email}
                                </label>
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="text-[14px]">
                                  新しいメールアドレス
                                </label>
                                <input
                                  type="email"
                                  ref={newEmailRef}
                                  id="email"
                                  className="input rounded-[4px]"
                                  required
                                />
                              </div>
                              <div className="">
                                <label
                                  htmlFor="confirm_email"
                                  className=" text-[14px]">
                                  新しいメールアドレス（確認）
                                </label>
                                <input
                                  type="email"
                                  ref={confirmEmailRef}
                                  id="confirm_email"
                                  className="input rounded-[4px]"
                                  placeholder="確認のためにもう一度入力してください"
                                  required
                                />
                              </div>
                              {modalErrorMessage && (
                                <span className="text-sm text-red-500 mt-[2px]">{modalErrorMessage}</span>
                              )}
                              <div className="account-setting--buttonRow p-0 mt-8">
                                <button
                                  onClick={closeUpdateEmailModal}
                                  className="button solid"
                                >
                                  キャンセル
                                </button>
                                <button
                                  onClick={onUpdateEmailButtonPressed}
                                  className="button main"
                                >
                                  更新
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
              </div>
              <div className="form-container">
                <div className="selection-wrapper">
                  <p>パスワード</p>
                  <div className="flex w-full">
                    <label>
                      ••••••••
                    </label>
                    <EditIcon
                      className='flex ml-2 transition-all hover:opacity-70 cursor-pointer'
                      onClick={openUpdatePasswordModal}
                    />
                  </div>
                  <Transition appear show={isUpdatePasswordModalOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeUpdatePassowordModal}>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black/25" />
                      </Transition.Child>

                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              {/* <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                パスワード変更
                              </Dialog.Title> */}
                              <div className="mb-4">
                                <p className="text-sm text-gray-500">
                                  現在のパスワードと新しいパスワードを入力してください
                                </p>
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="current_password"
                                  className="block text-[14px]">
                                  現在のパスワード
                                </label>
                                <input
                                  type="password"
                                  ref={currentPasswordRef}
                                  id="current_password"
                                  className="input rounded-[4px]"
                                  required
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="password"
                                  className="block text-[14px]">
                                  新しいパスワード(6桁以上)
                                </label>
                                <input
                                  type="password"
                                  ref={newPasswordRef}
                                  id="password"
                                  className="input rounded-[4px]"
                                  required
                                />
                              </div>
                              <div className="">
                                <label
                                  htmlFor="confirm_password"
                                  className="block text-[14px]">
                                  新しいパスワード(確認）
                                </label>
                                <input
                                  type="password"
                                  ref={confirmPasswordRef}
                                  id="confirm_password"
                                  className="input rounded-[4px]"
                                  placeholder="確認のためにもう一度入力してください"
                                  required
                                />
                              </div>
                              {modalErrorMessage && (
                                <span className="text-sm text-red-500 mt-[2px]">{modalErrorMessage}</span>
                              )}
                              <div className="account-setting--buttonRow p-0 mt-8">
                                <button
                                  onClick={closeUpdatePassowordModal}
                                  className="button solid"
                                >
                                  キャンセル
                                </button>
                                <button
                                  onClick={onUpdatePasswordButtonPressed}
                                  className="button main"
                                >
                                  更新
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  )
});

export default AccountSettings;