import React from "react";
import {
  getJapaneseDay,
  generateDateRange,
  generateMonthlyRange,
  checkIncludeDates,
  checkEqualDates,
} from "src/excalidraw/extensions/shared/utils/dateUtils";
import type { AppState } from "../../types";
import "./BackgroundHTML.scss";
import {
  CANVAS_HEADER_HEIGHT,
  GRID_SIZE,
  JOB_ELEMENTS_WIDTH,
} from "src/excalidraw/constants";
import ReactDOM from "react-dom";

type backgroundCalendarProps = {
  state: {
    projectStartDate: AppState["projectStartDate"];
    projectEndDate: AppState["projectEndDate"];
    holidays: AppState["holidays"];
    jobsHeight: AppState["jobsHeight"];
    gridSize: AppState["gridSize"];
  };
};

const isCalendarUpdate = (
  prevProps: backgroundCalendarProps,
  nextProps: backgroundCalendarProps,
) => {
  if (
    !checkEqualDates(
      prevProps.state.projectStartDate,
      nextProps.state.projectStartDate,
    ) ||
    !checkEqualDates(
      prevProps.state.projectEndDate,
      nextProps.state.projectEndDate,
    ) ||
    JSON.stringify(prevProps.state.holidays) !=
      JSON.stringify(nextProps.state.holidays) ||
    prevProps.state.jobsHeight != nextProps.state.jobsHeight
  ) {
    return false;
  }

  return true;
};

const BackgroundCalendarHeader = React.memo(
  (props: backgroundCalendarProps) => {
    const [backgroundContents, setBackgroundContents] = React.useState<JSX.Element[]>([]);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const {
        projectStartDate: startDate,
        projectEndDate: endDate,
        holidays,
        jobsHeight,
        gridSize,
      } = props.state;

      const targetDateArray = generateDateRange(startDate, endDate);
      const holidaysTypOfeDates = holidays.map((date) => new Date(date));
      const nowDate = new Date();

      const targetYearMonthRange = generateMonthlyRange(startDate, endDate);
      const targetYearMonth = targetYearMonthRange.map((date) => {
        return { year: date.getFullYear(), month: date.getMonth() };
      });

      const newBackgroundContents = targetYearMonth.map((ym) => {
        const currentYearMonthDates = targetDateArray.filter(
          (targetDate) => {
            return (
              targetDate.getFullYear() === ym.year &&
              targetDate.getMonth() === ym.month
            );
          },
        );

        return (
          <div className="bg-calendar__month" key={`${ym.year}-${ym.month}`}>
            <p
              style={{
                marginTop: `20px`,
                height: `${gridSize}px`,
                lineHeight: `${gridSize}px`,
              }}
            >{`${ym.year}年${ym.month + 1}月`}</p>
            <div className="bg-calendar__dates">
              {currentYearMonthDates.map((currentYearMonthDate, index) => {
                let classDaily = "";
                if (checkEqualDates(nowDate, currentYearMonthDate)) {
                  classDaily = "nowday";
                } else if (
                  checkIncludeDates(holidaysTypOfeDates, currentYearMonthDate)
                ) {
                  classDaily = "holiday";
                }
                return (
                  <div
                    key={index}
                    className={`bg-calendar__dates__text ${classDaily}`}
                    style={{
                      width: `${gridSize}px`,
                      height: `${
                        (gridSize ? gridSize * 2 : GRID_SIZE * 2) - 12
                      }px`,
                    }}
                  >
                    <p>{`${currentYearMonthDate.getDate()}`}</p>
                    <p>{` ${getJapaneseDay(currentYearMonthDate)}`}</p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      });

      setBackgroundContents(newBackgroundContents);
    }, [props.state]);

    return <div ref={wrapperRef}>{backgroundContents}</div>;
  },
  isCalendarUpdate,
);

const BackgroundCalendarContents = React.memo(
  (props: backgroundCalendarProps) => {
    const [backgroundContents, setBackgroundContents] = React.useState<JSX.Element[]>([]);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const {
        projectStartDate: startDate,
        projectEndDate: endDate,
        holidays,
        jobsHeight,
        gridSize,
      } = props.state;

      const targetDateArray = generateDateRange(startDate, endDate);
      const holidaysTypOfeDates = holidays.map((date) => new Date(date));
      const nowDate = new Date();

      // 年月分の配列を生成
      const targetYearMonthRange = generateMonthlyRange(startDate, endDate);
      const targetYearMonth = targetYearMonthRange.map((date) => {
        return { year: date.getFullYear(), month: date.getMonth() };
      });

      // 新しい backgroundContents 状態を設定
      const newBackgroundContents = targetYearMonth.map((ym) => {
        const currentYearMonthDates = targetDateArray.filter(
          (targetDate) => {
            return (
              targetDate.getFullYear() === ym.year &&
              targetDate.getMonth() === ym.month
            );
          },
        );

        return (
          <React.Fragment key={`${ym.year}-${ym.month}`}>
            {currentYearMonthDates.map((currentYearMonthDate, index) => {
              let classDaily = "";
              if (checkIncludeDates(holidaysTypOfeDates, currentYearMonthDate)) {
                classDaily = "holiday";
              } else if (checkEqualDates(nowDate, currentYearMonthDate)) {
                classDaily = "nowday";
              }
              return (
                <div
                  key={index}
                  className={`bg-calendar__content__rect ${classDaily}`}
                  style={{ width: `${gridSize}px`, height: `${jobsHeight}px` }}
                />
              );
            })}
          </React.Fragment>
        );
      });

      setBackgroundContents(newBackgroundContents);
    }, [props.state]);

    return <div ref={wrapperRef}>{backgroundContents}</div>;
  },
  isCalendarUpdate,
);

const BackgroundCalendar = (props: { appState: AppState }) => {
  const { appState } = props;
  if(appState.isLoading){
    return <></>;
  }
  const { scrollX, scrollY, zoom, viewBackgroundColor } = appState;
  const contentProps = {
    projectStartDate: appState.projectStartDate,
    projectEndDate: appState.projectEndDate,
    holidays: appState.holidays,
    jobsHeight: appState.jobsHeight,
    gridSize: appState.gridSize,
  };

  const left = `${JOB_ELEMENTS_WIDTH * zoom.value}px`;
  const top = `${CANVAS_HEADER_HEIGHT * zoom.value}px`;
  const _zoom = zoom.value;
  const _scrollX = scrollX * _zoom;
  const _scrollY = scrollY * _zoom;

  const headerStyle: React.CSSProperties = {
    transformOrigin: "left top",
    transform: `translate(${_scrollX}px, ${0}) scale(${_zoom})`,
    left,
  };
  const contentStyle: React.CSSProperties = {
    ...headerStyle,
    transform: `translate(${_scrollX}px, ${_scrollY}px) scale(${_zoom})`,
    top,
    background: viewBackgroundColor,
  };

  return (
    <div className="bg-calendar__wrapper">
      <div id="bg-calendar__header" style={headerStyle} className="bg-calendar__header">
        <BackgroundCalendarHeader state={contentProps} />
      </div>
      <div id="bg-calendar__content" style={contentStyle} className="bg-calendar__content">
        <BackgroundCalendarContents state={contentProps} />
      </div>
    </div>
  );
};

export default BackgroundCalendar;
