import { KEYS } from "src/excalidraw/keys";
import { register } from "./register";
import { ExcalidrawElement } from "../element/types";
import { duplicateElement, getNonDeletedElements } from "../element";
import { getSelectedElements, isSomeElementSelected } from "src/excalidraw/scene";
import { ToolButton } from "../components/ToolButton";
import { t } from "src/excalidraw/i18n";
import { arrayToMap, getLoginUserId, getShortcutKey, getUpdatedTimestamp } from "src/excalidraw/utils";
import { LinearElementEditor } from "../element/linearElementEditor";
import {
  selectGroupsForSelectedElements,
  getSelectedGroupForElement,
  getElementsInGroup,
} from "src/excalidraw/groups";
import { AppState } from "src/excalidraw/types";
import { fixBindingsAfterDuplication } from "../element/binding";
// CHANGED:ADD 2022-11-24 #197
import { fixBindingsAfterDuplicationEx } from "src/excalidraw/extensions/element/binding"; // from extensions
import { ActionResult } from "./types";
import { GRID_SIZE } from "src/excalidraw/constants";
import {
  bindTextToShapeAfterDuplication,
  getBoundTextElement,
} from "../element/textElement";
import { isBoundToContainer, isTextElement } from "../element/typeChecks";
import { normalizeElementOrder } from "../element/sortElements";
import { DuplicateIcon } from "../components/icons";
import {
  isJobElement,
  isLinkElement, // CHANGED:ADD 2023-02-13 #686
  isMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  isNodeElement, // CHANGED:ADD 2022-12-01 #221
  isTaskElement, // CHANGED:ADD 2022-12-01 #221
} from "src/excalidraw/extensions/element/typeChecks"; // from extensions
import {
  ExcalidrawMilestoneElement, // CHANGED:ADD 2022-12-7 #157
  ExcalidrawTaskElement, // CHANGED:ADD 2022-12-01 #221
} from "src/excalidraw/extensions/element/types"; // from extensions
// CHANGED:ADD 2022-11-30 #214
import CriticalPath from "src/excalidraw/extensions/criticalPath"; // from extensions

export const actionDuplicateSelection = register({
  name: "duplicateSelection",
  trackEvent: { category: "element" },
  // CHANGED:ADD 2023-2-8 #608
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements(appState);
    return !selectedElements.some((element) => isJobElement(element));
  },
  perform: (elements, appState, _, app) => {
    // duplicate selected point(s) if editing a line
    if (appState.editingLinearElement) {
      const ret = LinearElementEditor.duplicateSelectedPoints(
        appState,
        app.scene.getElementsMapIncludingDeleted(),
      );

      if (!ret) {
        return false;
      }

      return {
        elements,
        appState: ret.appState,
        commitToHistory: true,
      };
    }

    // CHANGED: ADD 2023-02-20 #722
    if (
      appState.selectedLinkElement ||
      isLinkElement(appState.editingElement)
    ) {
      return {
        commitToHistory: false,
      };
    }

    return {
      ...duplicateElements(elements, appState),
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.duplicateSelection",
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.D,
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type="button"
      icon={DuplicateIcon}
      title={`${t("labels.duplicateSelection")} — ${getShortcutKey(
        "CtrlOrCmd+D",
      )}`}
      aria-label={t("labels.duplicateSelection")}
      onClick={() => updateData(null)}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});

const duplicateElements = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
): Partial<ActionResult> => {
  // ---------------------------------------------------------------------------

  // step (1)

  const sortedElements = normalizeElementOrder(elements);
  const groupIdMap = new Map();
  const newElements: ExcalidrawElement[] = [];
  const oldElements: ExcalidrawElement[] = [];
  const oldIdToDuplicatedId = new Map();

  const duplicateAndOffsetElement = (element: ExcalidrawElement) => {
    // CHANGED:ADD 2022-12-01 #221
    // CHANGED:UPDATE 2023-03-21 #719
    const x =
      isNodeElement(element) || isLinkElement(element)
        ? element.x
        : element.x + GRID_SIZE / 2;
    const y =
      isNodeElement(element) || isLinkElement(element)
        ? element.y + GRID_SIZE
        : element.y + GRID_SIZE / 2;
    const duplicateOption: any = {
      x,
      y,
      layer: appState.selectedLayer, // CHANGED:ADD 2024-10-5 #2114
    };

    if (isTaskElement(element)) {
      const startDate: ExcalidrawTaskElement["startDate"] = element.startDate;
      const endDate: ExcalidrawTaskElement["endDate"] = element.endDate;
      duplicateOption.startDate = startDate;
      duplicateOption.endDate = endDate;
      duplicateOption.created = getUpdatedTimestamp(); // CHANGED:ADD 2024-02-17 #1677
      duplicateOption.createdBy = getLoginUserId(); // CHANGED:ADD 2024-02-17 #1677
      // CHANGED:UPDATE 2022-12-7 #157
    } else if (isMilestoneElement(element)) {
      const date: ExcalidrawMilestoneElement["date"] = element.date;
      duplicateOption.date = date;
      duplicateOption.created = getUpdatedTimestamp(); // CHANGED:ADD 2024-02-17 #1677
      duplicateOption.createdBy = getLoginUserId(); // CHANGED:ADD 2024-02-17 #1677
    }

    const newElement = duplicateElement(
      appState.editingGroupId,
      groupIdMap,
      element,
      // CHANGED:UPDATE 2022-12-01 #221
      // {
      //   x: element.x + GRID_SIZE / 2,
      //   y: element.y + GRID_SIZE / 2,
      // },
      duplicateOption,
    );
    oldIdToDuplicatedId.set(element.id, newElement.id);
    oldElements.push(element);
    newElements.push(newElement);
    return newElement;
  };

  // const selectedElementIds = arrayToMap(
  //   getSelectedElements(sortedElements, appState, true),
  // );

  // CHANGED: UPDATE 2023-02-20 #723
  const duplicateElements = getSelectedElements(sortedElements, appState, {
    includeBoundTextElement: true,
  });
  const duplicateElementIds = new Set(
    duplicateElements.map((element) => element.id),
  );

  const filteredElementIds: Set<string> = new Set();
  duplicateElements.forEach((element) => {
    if (
      (isLinkElement(element) &&
        (!element.startBinding ||
          !element.endBinding ||
          !duplicateElementIds.has(element.startBinding.elementId) ||
          !duplicateElementIds.has(element.endBinding.elementId))) ||
      isJobElement(element)
    ) {
      filteredElementIds.add(element.id);
    }
  });
  const selectedElementIds = arrayToMap(
    getSelectedElements(sortedElements, appState, {
      includeBoundTextElement: true,
    }).filter(
      (element) =>
        !filteredElementIds.has(element.id) &&
        !(
          isTextElement(element) &&
          element.containerId &&
          filteredElementIds.has(element.containerId)
        ),
    ),
  );

  // Ids of elements that have already been processed so we don't push them
  // into the array twice if we end up backtracking when retrieving
  // discontiguous group of elements (can happen due to a bug, or in edge
  // cases such as a group containing deleted elements which were not selected).
  //
  // This is not enough to prevent duplicates, so we do a second loop afterwards
  // to remove them.
  //
  // For convenience we mark even the newly created ones even though we don't
  // loop over them.
  const processedIds = new Map<ExcalidrawElement["id"], true>();

  const markAsProcessed = (elements: ExcalidrawElement[]) => {
    for (const element of elements) {
      processedIds.set(element.id, true);
    }
    return elements;
  };

  const elementsWithClones: ExcalidrawElement[] = [];

  let index = -1;

  while (++index < sortedElements.length) {
    const element = sortedElements[index];

    if (processedIds.get(element.id)) {
      continue;
    }

    const boundTextElement = getBoundTextElement(element, arrayToMap(elements));
    if (selectedElementIds.get(element.id)) {
      // if a group or a container/bound-text, duplicate atomically
      if (element.groupIds.length || boundTextElement) {
        const groupId = getSelectedGroupForElement(appState, element);
        if (groupId) {
          const groupElements = getElementsInGroup(sortedElements, groupId);
          elementsWithClones.push(
            ...markAsProcessed([
              ...groupElements,
              ...groupElements.map((element) =>
                duplicateAndOffsetElement(element),
              ),
            ]),
          );
          continue;
        }
        if (boundTextElement) {
          elementsWithClones.push(
            ...markAsProcessed([
              element,
              boundTextElement,
              duplicateAndOffsetElement(element),
              duplicateAndOffsetElement(boundTextElement),
            ]),
          );
          continue;
        }
      }
      elementsWithClones.push(
        ...markAsProcessed([element, duplicateAndOffsetElement(element)]),
      );
    } else {
      elementsWithClones.push(...markAsProcessed([element]));
    }
  }

  // step (2)

  // second pass to remove duplicates. We loop from the end as it's likelier
  // that the last elements are in the correct order (contiguous or otherwise).
  // Thus we need to reverse as the last step (3).

  const finalElementsReversed: ExcalidrawElement[] = [];

  const finalElementIds = new Map<ExcalidrawElement["id"], true>();
  index = elementsWithClones.length;

  while (--index >= 0) {
    const element = elementsWithClones[index];
    if (!finalElementIds.get(element.id)) {
      finalElementIds.set(element.id, true);
      finalElementsReversed.push(element);
    }
  }

  // step (3)

  const finalElements = finalElementsReversed.reverse();

  // ---------------------------------------------------------------------------

  bindTextToShapeAfterDuplication(
    elementsWithClones,
    oldElements,
    oldIdToDuplicatedId,
  );
  fixBindingsAfterDuplication(
    elementsWithClones,
    oldElements,
    oldIdToDuplicatedId,
  );
  fixBindingsAfterDuplicationEx(
    elementsWithClones,
    oldElements,
    oldIdToDuplicatedId,
  ); // CHANGED:ADD 2022-11-24 #197

  return {
    elements: CriticalPath.calcCriticalPath(
      finalElements,
      arrayToMap(finalElements),
      appState,
    ), // CHANGED:ADD 2023-1-23 #455
    appState: {
      ...appState,
      ...selectGroupsForSelectedElements(
        {
          editingGroupId: appState.editingGroupId,
          selectedElementIds: newElements.reduce(
            (acc: Record<ExcalidrawElement["id"], true>, element) => {
              if (!isBoundToContainer(element)) {
                acc[element.id] = true;
              }
              return acc;
            },
            {},
          ),
        },
        getNonDeletedElements(finalElements),
        appState,
        null,
      ),
    },
    updatedMilestoneElements: true, // CHANGED:ADD 2023-2-14 #693
  };
};
