import { useEffect, useState } from "react";
import { debounce, getVersion, nFormatter } from "src/excalidraw/utils";
import { DEFAULT_VERSION } from "src/excalidraw/constants";
import { t } from "src/excalidraw/i18n";
import { copyTextToSystemClipboard } from "src/excalidraw/clipboard";
import { AppState } from "src/excalidraw/types";
import { NonDeletedExcalidrawElement } from "src/excalidraw/element/types";

type Props = {
  setToast: (message: string) => void;
  elements: readonly NonDeletedExcalidrawElement[];
  appState: AppState;
};
const CustomStatsEx = (props: Props) => {
  const version = getVersion();
  let hash;
  let timestamp;

  if (version !== DEFAULT_VERSION) {
    timestamp = version.slice(0, 16).replace("T", " ");
    hash = version.slice(21);
  } else {
    timestamp = t("stats.versionNotAvailable");
  }

  return (
    <>
      <tr>
        <td
          colSpan={2}
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={async () => {
            try {
              await copyTextToSystemClipboard(getVersion());
              props.setToast(t("toast.copyToClipboard"));
            } catch {}
          }}
          title={t("stats.versionCopy")}
        >
          {timestamp}
          <br />
          {hash}
        </td>
      </tr>
    </>
  );
};

export default CustomStatsEx;
