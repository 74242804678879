import { action, observable, makeAutoObservable, runInAction } from "mobx";
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
//types
import  { OrganizationResponse, OrganizationDocumentFields } from "src/conpath/interfaces/Organization";
//models
import OrganizationModel from "src/conpath/models/OrganizationModel";
import LoginUserModel from "../models/LoginUserModel";
//firebase
import { db, functions } from "src/configs/firebase";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { FirestoreCollections } from "src/conpath/constants/FirestoreCollections";
import { CreateOrganizationRequestErrorType, CreateOrganizationRequestErrors, GetOrganizationRequestError } from "src/conpath/constants/errors/OrganizationRequestErrors";
import { GeneralDocumentQueryErrorType } from "../constants/errors";
import { CreateOrganizationRequestParam } from "../interfaces/HttpRequests";
import { FirebaseHttpsRequests } from "../constants/FirebaseHttpsRequests";
import InternalError from "../interfaces/InternalError";

class OrganizationStore {

  @observable
  selectedOrganization: OrganizationModel | null = null;
  @observable
  organizations: OrganizationResponse[] = [];
  @observable
  isLoading: boolean = false;

  @observable
  errorTexts: string[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'OrganizationStore',
      properties: [{
        key: 'selectedOrganization',
        'serialize': (value) => {
          return value?.getFields();
        },
        'deserialize': (value) => {
          if (value) {
            return new OrganizationModel(value);
          }
          return null;
        }
      }],
      storage: window.localStorage,
    });
  }

  @action
  async clear(): Promise<void> {
    this.selectedOrganization = null;
    this.organizations = [];
    await clearPersistedStore(this);
  }

  @action
  async load(user: LoginUserModel): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    })
    try {
      const docRef = collection(db, FirestoreCollections.organizations.this);

      // adminの場合は全ての組織を取得する
      const organizationSnaps = user.isSuperUser
        ? await getDocs(query(docRef))
        : await getDocs(query(docRef, where(OrganizationDocumentFields.userIds, "array-contains", user.id)));

      if (organizationSnaps.empty) {
        this.clear();
        throw new Error(GetOrganizationRequestError.DocumentEmpty);
      }

      const organizations: OrganizationResponse[] = [];
      organizationSnaps.forEach((doc) => {
        //複数だったら全て
        organizations.push(doc.data() as OrganizationResponse);
      });

      const selectedOrganization =
        organizations.find((organization) => organization.id === user.selectedOrganizationId) || organizations[0];

      runInAction(async () => {
        const errorMessages: string[] = [];
        this.selectedOrganization = new OrganizationModel(selectedOrganization);
        this.organizations = organizations;
        
        const getOrganizationUsersResult = await this.selectedOrganization.getOrganizationUsers();
        if (getOrganizationUsersResult.error) errorMessages.push(getOrganizationUsersResult.error);

        const getResourcesResult = await this.selectedOrganization.getResources();
        if (getResourcesResult.error) errorMessages.push(getResourcesResult.error);

        const getTeamsResult = await this.selectedOrganization.getTeams();
        if (getTeamsResult.error) errorMessages.push(getTeamsResult.error);

        user.setOrganizationRole(this.selectedOrganization); // getProjectsで組織権限を参照しているので、その前に権限をセットしておく。

        const getOrganizationProjectsResult = await this.selectedOrganization.getProjects(user);
        if (getOrganizationProjectsResult.error) errorMessages.push(getOrganizationProjectsResult.error);


        this.errorTexts = [...errorMessages];
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      //Sentry here
      
      const message = GetOrganizationRequestError[error.message as GeneralDocumentQueryErrorType] || GetOrganizationRequestError.DocumentEmpty; 
      runInAction(() => {
        this.errorTexts.push(message);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  public async setSelectedOrganization(uid: string) {
    try {
      const docRef = doc(db, FirestoreCollections.organizations.this, uid);
      const organizationDoc = await getDoc(docRef);
      if (!organizationDoc.exists()) return;
      const organization = organizationDoc.data() as OrganizationResponse;
      runInAction(() => {
        this.selectedOrganization = new OrganizationModel(organization);
      });
    } catch (error) {
      console.log(error);
      //Sentry
    }
  }


  /**
   * オーナーユーザーのサインアップと組織作成を行う関数
   * CloudFunctionのcreate-organization/を呼び出す
   * @param organizationName
   * @param organizationPhoneNumber
   * @param userId
   * @returns 
   */
  public async createOrganization(
    organizationName: string,
    organizationPhoneNumber: string,
    plan: number,
    userId: string,
  ): Promise<InternalError> {
    const params: CreateOrganizationRequestParam = {
      organizationName,
      organizationPhoneNumber,
      plan,
      userId,
    };
    try {
      const request = httpsCallable(functions, FirebaseHttpsRequests.createOrganization);
      await request(params);
      return {};
    } catch (err) {
      const error = err as { message?: string };

      const message = CreateOrganizationRequestErrors[error.message as CreateOrganizationRequestErrorType] || CreateOrganizationRequestErrors.General;
      return { error: message };
      // Sentry here

    }
  }

  @action
  public setErrorTexts(errorTexts: string[]) {
    runInAction(() => {
      this.errorTexts = errorTexts;
    });
  }

  @action
  public clearErrorTexts() {
    runInAction(() => {
      this.errorTexts = [];
    });
  }
}

export default OrganizationStore;
