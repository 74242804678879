import { ElementsMap, NonDeletedExcalidrawElement } from "../../element/types";
import { AppState } from "src/excalidraw/types";
import { ExcalidrawNodeElement } from "./types";
import { TaskElementEditor } from "./taskElementEditor";
import Scene from "src/excalidraw/scene/Scene";
import {
  isBindableElementEx,
  isJobElement,
  isLinkElement,
  isMilestoneElement,
  isNodeElement,
  isTaskElement,
} from "./typeChecks"; // from extensions
import { CANVAS_HEADER_HEIGHT, JOB_ELEMENTS_WIDTH } from "src/excalidraw/constants";
import { LinkElementEditor } from "./linkElementEditor";

// CHANGED:UPDATE 2022-12-19 #347
export const getMovableSelectedElements = (
  selectedElements: NonDeletedExcalidrawElement[],
  elementsMap: ElementsMap,
  offset: { x: number; y: number },
  appState: AppState,
  isDragging: boolean, // CHANGED:ADD 2022-12-28 #397
): { x: number; y: number } => {
  // CHANGED:ADD 2022-12-27 #389
  const originalSelectedElementIds = new Set(
    selectedElements.map((element) => element.id),
  );

  if (isDragging) {
    const adjacencyElements = getAdjacencyElements(
      selectedElements,
      elementsMap,
      new Set(selectedElements.map((element) => element.id)),
      offset.x,
    );

    if (adjacencyElements.length > 0) {
      selectedElements.push(...adjacencyElements);
      offset.y = 0;
    }
  }

  const leftOrRight = offset.x === 0 ? "keep" : offset.x < 0 ? "left" : "right";
  const topOrBottom = offset.y === 0 ? "keep" : offset.y < 0 ? "top" : "bottom";
  const startXLimits: Set<number> = new Set();
  const endXLimits: Set<number> = new Set();
  const startYLimits: Set<number> = new Set();
  const endYLimits: Set<number> = new Set();

  // CHANGED:UPDATE 2023-01-25 #391
  if (selectedElements.some((element) => isJobElement(element))) {
    // JobElementをx, y軸固定するためにoffsetを0にする。dragできないようにする処理。
    offset.x = 0;
    offset.y = 0;
  }

  const selectedNodeElements = selectedElements
    .filter((element) => isNodeElement(element))
    .map((element) => element as ExcalidrawNodeElement);

  if (leftOrRight !== "keep" || topOrBottom !== "keep") {
    selectedNodeElements.forEach((element) => {
      let startX = 0;
      let endX = 0;
      let startY = 0;
      let endY = 0;
      if (isTaskElement(element)) {
        startX = element.x;
        endX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
          element,
          -1,
          elementsMap,
        )[0];
        startY = element.y;
        endY = TaskElementEditor.getPointAtIndexGlobalCoordinates(
          element,
          -1,
          elementsMap,
        )[1];
      } else if (isMilestoneElement(element)) {
        startX = element.x + element.width / 2;
        endX = element.x + element.width / 2;
        startY = element.y + element.height / 2;
        endY = element.y + element.height / 2;
      }

      if (leftOrRight === "left") {
        const distance = JOB_ELEMENTS_WIDTH - startX;
        startXLimits.add(distance);
      } else if (leftOrRight === "right") {
        const distance = JOB_ELEMENTS_WIDTH + appState.calendarWidth - endX;
        endXLimits.add(distance);
      }

      if (topOrBottom === "top") {
        const distance = CANVAS_HEADER_HEIGHT - startY;
        startYLimits.add(distance);
      } else if (topOrBottom === "bottom") {
        const distance = CANVAS_HEADER_HEIGHT + appState.jobsHeight - endY;
        endYLimits.add(distance);
      }
    });
  }

  if (leftOrRight !== "keep") {
    // CHANGED:ADD 2023-2-21 #590
    if (isDragging) {
      const selectedElementIds = new Set(
        selectedElements.map((element) => element.id),
      );
      const isLocked = selectedNodeElements.some((element) => {
        const dependencies =
          leftOrRight === "left"
            ? element.nextDependencies
            : element.prevDependencies;

        return dependencies?.some((dependElementId) => {
          const linkElement = Scene.getScene(element)!
            .getNonDeletedElements()
            .find(
              (el) =>
                isLinkElement(el) &&
                el[leftOrRight === "left" ? "startBinding" : "endBinding"]
                  ?.elementId === element.id &&
                el[leftOrRight === "left" ? "endBinding" : "startBinding"]
                  ?.elementId === dependElementId,
            );

          if (
            isLinkElement(linkElement) &&
            linkElement.locked &&
            !selectedElementIds.has(dependElementId)
          ) {
            return true;
          }

          return false;
        });
      });

      if (isLocked) {
        return {
          x: 0,
          y: Math.max(...startYLimits, Math.min(offset.y, ...endYLimits)),
        };
      }
    }

    selectedNodeElements.forEach((element) => {
      let startX = 0;
      let endX = 0;
      if (isTaskElement(element)) {
        startX = element.x;
        endX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
          element,
          -1,
          elementsMap,
        )[0];
      } else if (isMilestoneElement(element)) {
        startX = element.x + element.width / 2;
        endX = element.x + element.width / 2;
      }

      const dependencies =
        leftOrRight === "left"
          ? element.prevDependencies
          : element.nextDependencies;

      dependencies?.forEach((dependElementId) => {
        const bindingElement =
          Scene.getScene(element)?.getNonDeletedElement(dependElementId);
        if (bindingElement && isBindableElementEx(bindingElement, true)) {
          let targetStartX = 0;
          let targetEndX = 0;

          if (isTaskElement(bindingElement)) {
            targetStartX = bindingElement.x;
            targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
              bindingElement,
              -1,
              elementsMap,
            )[0];
          } else if (isMilestoneElement(bindingElement)) {
            targetStartX = bindingElement.x + bindingElement.width / 2;
            targetEndX = bindingElement.x + bindingElement.width / 2;
          }

          // CHANGED:ADD 2023-2-21 #590
          const linkElement = Scene.getScene(element)!
            .getNonDeletedElements()
            .find(
              (el) =>
                isLinkElement(el) &&
                el[leftOrRight === "left" ? "endBinding" : "startBinding"]
                  ?.elementId === element.id &&
                el[leftOrRight === "left" ? "startBinding" : "endBinding"]
                  ?.elementId === dependElementId,
            );

          if (isLinkElement(linkElement) && linkElement.locked) {
            targetStartX = linkElement.x;
            targetEndX = LinkElementEditor.getPointAtIndexGlobalCoordinates(
              linkElement,
              -1,
              elementsMap,
            )[0];
          }

          if (leftOrRight === "left") {
            const distance = targetEndX - startX;
            if (
              distance !== 0 ||
              (distance === 0 &&
                (bindingElement.locked ||
                  (isMilestoneElement(bindingElement) &&
                    !originalSelectedElementIds.has(bindingElement.id))))
            ) {
              startXLimits.add(distance);
            }
          } else if (leftOrRight === "right") {
            const distance = targetStartX - endX;
            if (
              distance !== 0 ||
              (distance === 0 &&
                (bindingElement.locked ||
                  (isMilestoneElement(bindingElement) &&
                    !originalSelectedElementIds.has(bindingElement.id))))
            ) {
              endXLimits.add(distance);
            }
          }
        }
      });
    });
  }

  return {
    x: Math.max(...startXLimits, Math.min(offset.x, ...endXLimits)),
    y: Math.max(...startYLimits, Math.min(offset.y, ...endYLimits)),
  };
};

// CHANGED:ADD 2022-12-27 #389
export const getAdjacencyElements = (
  selectedElements: NonDeletedExcalidrawElement[],
  elementsMap: ElementsMap,
  discovered: Set<NonDeletedExcalidrawElement["id"]>,
  offsetX: number,
): NonDeletedExcalidrawElement[] => {
  const adjacencyElements: NonDeletedExcalidrawElement[] = [];

  const leftOrRight = offsetX === 0 ? "keep" : offsetX < 0 ? "left" : "right";
  if (leftOrRight !== "keep") {
    (
      selectedElements.filter((element) =>
        isNodeElement(element),
      ) as ExcalidrawNodeElement[]
    ).forEach((element) => {
      let startX = 0;
      let endX = 0;
      if (isTaskElement(element)) {
        startX = element.x;
        endX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
          element,
          -1,
          elementsMap,
        )[0];
      } else if (isMilestoneElement(element)) {
        startX = element.x + element.width / 2;
        endX = element.x + element.width / 2;
      }

      const dependencies =
        leftOrRight === "left"
          ? element.prevDependencies
          : element.nextDependencies;

      dependencies?.forEach((dependElementId) => {
        const bindingElement =
          Scene.getScene(element)?.getNonDeletedElement(dependElementId);
        if (bindingElement && isTaskElement(bindingElement)) {
          const targetStartX = bindingElement.x;
          const targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
            bindingElement,
            -1,
            elementsMap,
          )[0];

          let distance = 0;
          if (leftOrRight === "left") {
            distance = targetEndX - startX;
          } else if (leftOrRight === "right") {
            distance = targetStartX - endX;
          }

          // CHANGED:ADD 2023-2-21 #590
          const linkElement = Scene.getScene(element)!
            .getNonDeletedElements()
            .find(
              (el) =>
                isLinkElement(el) &&
                el[leftOrRight === "left" ? "endBinding" : "startBinding"]
                  ?.elementId === element.id &&
                el[leftOrRight === "left" ? "startBinding" : "endBinding"]
                  ?.elementId === dependElementId,
            );

          if (
            (distance === 0 || linkElement?.locked) &&
            !discovered.has(bindingElement.id)
          ) {
            discovered.add(bindingElement.id);
            adjacencyElements.push(bindingElement);
            adjacencyElements.push(
              ...getAdjacencyElements(
                [bindingElement],
                elementsMap,
                discovered,
                offsetX,
              ),
            );
          }
        }
      });
    });
  }

  return adjacencyElements;
};
