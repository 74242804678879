import React from "react";
import { observer } from "mobx-react-lite";

import { FileIcon, PDFIcon, JPEGIcon, TrashIcon } from "src/excalidraw/components/icons";
import CommentModel from "../models/CommentModel";
import LoginUserModel from "../models/LoginUserModel";
import "./FilePreviews.scss";
import { FileType, UploadedFile } from "src/conpath/interfaces/Comment";
import { RotatingLines } from "react-loader-spinner";
import Colors from "../constants/Colors";
import { toast } from "react-toastify";

interface Props {
  isEditing: boolean;
  comment: CommentModel;
  loginUser: LoginUserModel;
};

const FilePreviews: React.FC<Props> = (props: Props) => {
  const { isEditing, comment } = props;

  const getFileIcon = (fileType: FileType) => {
    switch (fileType) {
      case "image/png":
      case "image/jpeg":
        return JPEGIcon
      case "application/pdf":
        return PDFIcon
      default:
        return FileIcon
    }
  };

  const openFileInNewTab = async (file: UploadedFile) => {
    const filePath = await comment.getFileDownloadUrl(file);
    if (filePath) {
      window.open(filePath, "_blank");
    } else {
      toast.error("ファイルを取得できませんでした。");
    }
  };

  return (
    <div className="file-preview-wrapper">
      { 
        comment.uploadedFiles.map((file) => {
          return (
            <div key={file.fileName} className="item">
              { file.hasUploaded && isEditing && (
                <button 
                  onClick={() => {
                    comment.setSelectedFileToDelete(file);
                  }}
                  className="item__trash-icon">
                  {TrashIcon}
                </button>
              )}
              <div className="item__file-icon">
                {getFileIcon(file.fileType)}
              </div>
              {
                file.hasUploaded ? 
                <div className="item__file-description">
                  { isEditing ? 
                    <p>{file.displayName}</p>
                    :
                    <a onClick={() => openFileInNewTab(file)}>
                      {file.displayName}
                    </a>
                  }
                  <p>{file.fileSizeInMB} MB</p>
                </div>
                :
                <div className="item__file-description">
                  <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                  <p>Uploading...</p>
                </div>
              }
            </div>
          )
        })
      }
    </div>
  )
};

export default observer(FilePreviews);