import React from "react";
import "./Badge.scss";
import { IoClose } from "react-icons/io5";


type Props = React.ComponentPropsWithoutRef<'span'> & {
  onClose?: () => void,
  className?: string
}
const Badge: React.FC<Props> = ({ onClose, className, ...props }: Props) => {
  return (
    <div className={'font-medium rounded flex items-center bg-[#E3F2FA] mr-1' + className}>
      <p className={`py-1 px-1 text-sm whitespace-nowrap`}>{props.children}</p>
      {onClose && (
        <span
          className={'items-center h-full w-hull cursor-pointer'}
          onClick={() => onClose && onClose()}
        >
          <IoClose />
        </span>
      )}
    </div>
  );
};

export default React.memo(Badge);
