export const UpsertTeamRequestErrorType = {
  General: "General",
  ValidationFailed: "ValidationFailed",
} as const;

export type UpsertTeamRequestErrorType = typeof UpsertTeamRequestErrorType[keyof typeof UpsertTeamRequestErrorType];


export const CreateTeamRequestError: { [key in UpsertTeamRequestErrorType]: string } = {
  General: "チームの作成に失敗しました。",
  ValidationFailed: "チームの作成に失敗しました。入力欄をご確認ください。",
} as const;


export const UpdateTeamRequestError: { [key in UpsertTeamRequestErrorType]: string } = {
  General: "チームの編集に失敗しました。",
  ValidationFailed: "チームの編集に失敗しました。入力欄をご確認ください。",
} as const;