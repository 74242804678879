import ProjectModel from "src/conpath/models/ProjectModel";
import TeamModel from "src/conpath/models//TeamModel";
import OrganizationUserModel from "src/conpath/models/OrganizationUserModel";
import { ProjectResponse } from "./Project";
import { OrganizationUserResponse } from "./User";
import { TeamResponse } from "./Team";
import Task from "./Task";
import Milestone from "src/excalidraw/extensions/milestone";
import { ResourceResponse } from "./Resource";
import ResourceModel from "../models/ResourceModel";

export interface Image {
  url: string,
  file: File|null
}

export default interface Organization {
  id: string;
  name: string;
  iconImageUrl: string;
  phoneNumber: string;
  plan: number;
  addUsers: number;
  addProjects: number;
  criticalPathColor: string;
  floatAlarmLimit: number;
  floatWarningLimit: number;
  projects: ProjectModel[]; // sub collection
  users: OrganizationUserModel[]; // sub collection (propertiesは異なるが、userと同じ)
  userIds: string[];
  resources: ResourceModel[]; // sub collection
  teams: TeamModel[]; // sub collection
  createdAt: Date; // UTC
  updatedAt: Date; // UTC
}
export interface IconImageFile {
  iconImageFile: Image;
}

export interface OrganizationResponse extends Omit<Organization, "projects" | "users" | "resources" | "teams"> {
  projects: ProjectResponse[];
  users: OrganizationUserResponse[];
  resources: ResourceResponse[];
  tasks: Task[];
  milestones: Milestone[];
  teams: TeamResponse[];
}

export const OrganizationDocumentFields = {
  id: "id",
  name: "name",
  iconImageUrl: "iconImageUrl",
  phoneNumber: "phoneNumber",
  plan: "plan",
  addUsers: "addUsers",
  addProjects: "addProjects",
  criticalPathColor: "criticalPathColor",
  floatAlarmLimit: "floatAlarmLimit",
  floatWarningLimit: "floatWarningLimit",
  projects: "projects",
  users: "users",
  userIds: "userIds",
  teams: "teams",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
} as const;

export type OrganizationDocumentFields = typeof OrganizationDocumentFields[keyof typeof OrganizationDocumentFields];

// export interface OrganizationProfileForm extends Pick<Organization, "name" | "criticalPathColor" | "iconImageUrl"> { };
export interface OrganizationProfileForm extends Pick<Organization & IconImageFile,
  | "name"
  | "criticalPathColor"
  | "iconImageFile"
  | "floatAlarmLimit"
  | "floatWarningLimit"
> { };
