import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner';

//mobx
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

//interfaces
import { SignUpOrganizationForm } from "src/conpath/interfaces/AuthForm";

//styles
import "./SignUpOrganization.scss";
import Colors from "src/conpath/constants/Colors";
import { Paths } from "src/conpath/constants/Routes";
import Selection from "src/conpath/components/Selection";
import { OrganizationPlan } from "../../constants/Plan";
import { OrganizationDocumentFields } from "../../interfaces/Organization";
import { organizationPlanSelection } from "../../constants/Selections";

const SignUpOrganization: React.FC = observer(() => {
  const navigate = useNavigate();
  const { userStore, organizationStore } = useStore();

  const [state, setState] = useState<SignUpOrganizationForm>({
    username: "",
    email: "",
    password: "",
    organizationName: "",
    organizationPhoneNumber: "",
    plan: OrganizationPlan.trial.toString(),
  });
  const [errorText, setErrorText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setState((prev) => ({ ...prev, [name]: value }));
    }
    setErrorText("");
  };

  const onSubmit = async () => {
    if (!userStore) return;

    setIsLoading(true);
    const result = await userStore.signUp(state, false);

    if (result.error) {
      setIsLoading(false);
      setErrorText(result.error);
      return;
    }

    const createOrganizationResult = await organizationStore.createOrganization(
      state.organizationName,
      state.organizationPhoneNumber,
      parseInt(state.plan),
      result.userId!,
    );

    if (createOrganizationResult.error) {
      setIsLoading(false);
      setErrorText(createOrganizationResult.error);
      return;
    }

    await userStore.setUser(result.userId!);
    setIsLoading(false);

    navigate(Paths.dashboard, { replace: true });
  };

  return (
    <div className="input__body">
      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
      <div className="input__card card--signup topSpacer">
        <h2 className="input__title">アカウント作成</h2>
        <div className="error-text-wrapper">
          <p>{errorText}</p>
        </div>
        <div className="input__wrapper minSpacer">
          <p className="input__label">ユーザー名</p>
          <input
            value={state.username}
            onChange={onChange}
            type="text"
            name="username"
            placeholder="ユーザー名"
          />
          <p className="input__label">メールアドレス</p>
          <input
            value={state.email}
            onChange={onChange}
            type="email"
            name="email"
            placeholder="メールアドレス"
          />
          <p className="input__label">パスワード　６文字以上</p>
          <input
            value={state.password}
            onChange={onChange}
            type="password"
            name="password"
            placeholder="パスワード"
          />
          <p className="input__label">組織名</p>
          <input
            value={state.organizationName}
            onChange={onChange}
            type="text"
            name="organizationName"
            placeholder="組織名"
          />
          <p className="input__label">電話番号</p>
          <input
            value={state.organizationPhoneNumber}
            onChange={onChange}
            type="text"
            name="organizationPhoneNumber"
            placeholder="電話番号"
          />
          <p className="input__label">プラン</p>
          <Selection
            id="plan-selection"
            value={state.plan}
            options={organizationPlanSelection}
            onChange={(value: string) => {
              setState((prev) => ({
                ...prev,
                [OrganizationDocumentFields.plan]: value,
              }));
            }}
          />
        </div>
        {isLoading ? (
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="24"
              visible={true}
            />
          </div>
        ) : (
          <div className="input__buttonWrapper">
            <button onClick={onSubmit} className="button main">
              次へ
            </button>
          </div>
        )}
      </div>
      <div className="input__card topSpacer">
        <p>アカウントを既にお持ちの方</p>
        <button
          onClick={() => navigate(Paths.login)}
          style={{ color: "gray" }}
          className="text-button minTopSpacer"
        >
          ログイン画面へ
        </button>
      </div>
    </div>
  );
});

export default SignUpOrganization;
