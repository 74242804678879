import { t } from "src/excalidraw/i18n";
import "./ShareLinkDialog.scss";
import { AppState } from "src/excalidraw/types";
import { Dialog } from "src/excalidraw/components/Dialog";
import React from "react";
import { copyTextToSystemClipboard } from "src/excalidraw/clipboard";
import { ToolButton } from "src/excalidraw/components/ToolButton";
import OpenColor from "open-color";

const ShareLinkDialog = ({
  appState,
  setAppState,
}: {
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const handleClose = React.useCallback(() => {
    setAppState({ openDialog: null });
  }, [setAppState]);

  const url = new URL(window.location.href);
  // We need to store the key (and less importantly the id) as hash instead
  // of queryParam in order to never send it to the server
  url.hash = `json=${appState.cloudFileHandle?.id},${appState.cloudFileHandle?.key}`;
  const urlString = url.toString();

  return (
    <>
      <Dialog
        onCloseRequest={handleClose}
        title={""}
        className="ShareLinkDialog"
        small={true}
      >
        <label className="share-link__label">
          {`🔒${t("alerts.uploadedSecurly")}`}
        </label>
        <label className="share-link__input-container">
          <input
            className="share-link__input-container__textarea"
            value={urlString}
            onFocus={(e) => {
              e.target.setSelectionRange(0, e.target.value.length);
              e.target.blur();
            }}
            readOnly
          />
        </label>
        <div
          className="copy-link"
          style={{
            ["--card-color" as any]: OpenColor.lime[7],
            ["--card-color-darker" as any]: OpenColor.lime[8],
            ["--card-color-darkest" as any]: OpenColor.lime[9],
          }}
        >
          <ToolButton
            className="copy-link-button"
            type="button"
            title={t("buttons.copyLinkToClipboard")}
            aria-label={t("buttons.copyLinkToClipboard")}
            showAriaLabel={true}
            onClick={() => {
              try {
                copyTextToSystemClipboard(urlString);
                setAppState({
                  toast: { message: t("toast.copyToClipboard") },
                });
              } catch {}
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ShareLinkDialog;
