import { AppState } from "../types";
import { NonDeletedExcalidrawElement } from "./types";
import { getSelectedElements } from "../scene";
import { isCommentElement } from "../extensions/element/typeChecks";

export const showSelectedShapeActions = (
  appState: AppState,
  elements: readonly NonDeletedExcalidrawElement[],
) => {
  if (!appState.viewModeEnabled &&
    appState.activeTool.type !== "custom" &&
    // CHANGED:UPDATE 2022-12-16 #339
    // (appState.editingElement ||
    ((appState.editingElement &&
      appState.editingElement.type !== "task" &&
      appState.editingElement.type !== "link") ||
      (appState.activeTool.type !== "selection" &&
        appState.activeTool.type !== "task" &&
        appState.activeTool.type !== "link" &&
        appState.activeTool.type !== "milestone" &&
        appState.activeTool.type !== "eraser" &&
        appState.activeTool.type !== "hand" &&
        appState.activeTool.type !== "laser"))) {
    return true;
  }

  const selectedElements = getSelectedElements(elements, appState);
  return Boolean(selectedElements.length && !selectedElements.some(isCommentElement));
};