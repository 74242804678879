import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { t } from "src/excalidraw/i18n";
import copy from "copy-to-clipboard";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import { AppState } from "src/excalidraw/types";

import { Popover } from "src/excalidraw/components/Popover";
import "./CommentListPopover.scss";
import {
  CheckedIcon,
  ClipIcon,
  CommentIcon,
  CrossIcon,
  MenuIcon,
  PenIcon,
  SendMessageIcon,
  TrashIcon,
} from "src/excalidraw/components/icons";
import CommentInput from "src/conpath/components/CommentInput";

import { useStore } from "src/conpath/hooks/useStore";

import { ExcalidrawCommentElement, ExcalidrawCommentableElement } from "../element/types";
import { DATE_FORMAT, formatDate } from "src/utils/dateUtils";
import CommentModel from "src/conpath/models/CommentModel";
import { RotatingLines } from "react-loader-spinner";
import Colors from "src/conpath/constants/Colors";
import ProjectModel from "src/conpath/models/ProjectModel";
import { OrganizationRole } from "src/conpath/constants/Role";

interface Props {
  appState: AppState,
  top: number,
  left: number,
  commentElement: ExcalidrawCommentElement,
  commentableElement: ExcalidrawCommentableElement,
  updateData: (formData?: {
    commentableElement: ExcalidrawCommentableElement,
  }) => void;
}
const CommentListPopover: React.FC<Props> = forwardRef((
  {
    appState,
    top,
    left,
    commentElement,
    commentableElement,
    updateData,
  },
  ref,
) => {
  const setAppState = useExcalidrawSetAppState();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  
  const [project, setProject] = useState<ProjectModel|null>(null);
  const [newComment, setNewComment] = useState<CommentModel>(new CommentModel({
    id: "",
    text: "",
    projectId: appState.projectId,
    rootCommentId: commentElement.id,
    commentElementId: commentableElement.id,
    mentioningTo: [],
    uploadedFiles: [],
    createdBy: "",
    createdAt: new Date(),
    updatedAt: null,
    isDeleted: false,
    isDeleteElementRequired: false,
    replayCount: 0,
    lastReplayedAt: null,
  }));
  const [updatingComment, setUpdatingComment] = useState<CommentModel|null>(null);
  const [menuOpenedCommentId, setMenuOpenedCommentId] = useState<string|null>(null);
  const [isDeleteReload, setIsDeleteReload] = useState<boolean>(false);

  const users = selectedOrganization?.users || [];
  const comments = project?.getComments;

  useEffect(() => {
    const getComments = async () => {
      if (!project && selectedOrganization) {
        const selectedProject = selectedOrganization.
        projects.find((project) => project.id === appState.projectId);
        await selectedProject?.getCommentsByElementId(commentElement.commentElementId, users);
        setProject(selectedProject!);
      }
    }

    getComments();
  }, []);

  useEffect(() => {
    return () => {
      project?.clearComments();
    }
  }, [project])

  /**
   * 全てのコメントが消去された時にCommentElementをcanvasから消去する処理
   */
  useEffect(() => {
    if (isDeleteReload && comments?.length === 0) {
      setIsDeleteReload(false);
      updateData({
        commentableElement: commentableElement
      });
    }
  }, [comments, isDeleteReload]);

  const onClose = () => {
    setAppState({ openDialog: null });
  };

  const onChangeComment = useCallback((value: string, type: "UPDATE" | "CREATE") => {
    if (type === "CREATE") {
      newComment.updateText(value);
    } else {
      updatingComment?.updateText(value);
    }
  }, [newComment, updatingComment]);

  const onSubmitButtonPressed = async () => {
    if (!loginUser || !project || !selectedOrganization) return;

    newComment.setAuthor(loginUser.id);
    newComment.setOrganizationId(project.organizationId);
    const result = await newComment.create({
      projectName: project.name,
      organizationName: selectedOrganization.name
    });

    if (result.error) {
      setAppState({ errorMessage: result.error });
      return;
    }

    setNewComment(new CommentModel({
      id: "",
      text: "",
      projectId: appState.projectId,
      rootCommentId: commentElement.id,
      commentElementId: commentableElement.id,
      mentioningTo: [],
      uploadedFiles: [],
      createdBy: "",
      createdAt: new Date(),
      updatedAt: null,
      isDeleted: false,
      isDeleteElementRequired: false,
      replayCount: 0,
      lastReplayedAt: null,
    }));

    await project.getCommentsByElementId(commentElement.commentElementId, users);
  };

  /**
   * すでにあるコメントを更新する処理
   * 親のコメントは既に登録済なので、
   * @returns 
   */
  const onUpdateButtonPressed = async () => {
    if (!updatingComment || !project || !selectedOrganization) return;

    const result = await updatingComment.update({
      projectName: project.name,
      organizationName: selectedOrganization.name
    });

    if (result.error) {
      setAppState({ errorMessage: result.error });
      return;
    }

    setUpdatingComment(null);
    await project.getCommentsByElementId(commentElement.commentElementId, users);
  }

  const onDeleteButtonPressed = async (comment: CommentModel) => {
    if (!project) return;

    const result = await comment.delete();
    if (result.error) {
      setAppState({ errorMessage: result.error });
      return;
    }
    
    await project.getCommentsByElementId(commentElement.commentElementId, users);
    setMenuOpenedCommentId(null);
    setIsDeleteReload(true);
  }

  const copyLinkToClipboard = (comment: CommentModel) => {
    const url = window.location.origin + window.location.pathname;
    const isCopy = copy(url + `#comment=${comment.id}`);
    if (isCopy) {
      setAppState({ toast: {
        message: t("toast.linkCopyToClipboard"),
      }});
    }
    setMenuOpenedCommentId(null);
  }

  return (
    <Popover
      onCloseRequest={onClose}
      top={top}
      left={left}
      fitInViewport={true}
      offsetLeft={appState.offsetLeft}
      offsetTop={appState.offsetTop}
      viewportWidth={appState.width}
      viewportHeight={appState.height}
    >
      <div 
        onClick={() => {
          if (menuOpenedCommentId) {
            setMenuOpenedCommentId(null);
          }
        }}
        className="comment-list">
        <div className="comment-list__header">
          <div className="button-label-wrapper">
            <div className="comment-label-wrapper">
              <div className="comment-label-wrapper__icon">
                {CommentIcon}
              </div>
              <p>
                コメント
              </p>
            </div> 
            <div className="button-wrapper">
              <button>
                {CheckedIcon}
              </button>
              {/* <button>
                {TrashIcon}
              </button> */}
              <button>
                {ClipIcon}
              </button>
            </div>
            {/* <div className="arrow-button-wrapper">
              <button>
                {LeftArrowIcon}
              </button>
              <button>
                {RightArrowIcon}
              </button>
            </div> */}
          </div>
          <button
            onClick={onClose}
            className="close-button">
            {CrossIcon}
          </button>
        </div>
        { selectedOrganization && loginUser && (
        <>
          <ul className="comment-list__body">
            { comments && comments.map((comment) => {
              if (updatingComment && comment.id === updatingComment.id) {
                return (
                  <li key={comment.id} className="comment-container">
                    <div className="input-container">
                      <CommentInput 
                        disabled={false}
                        value={updatingComment.text}
                        items={selectedOrganization
                          .getJoinedUserExceptMe(loginUser)
                          .filter((user) =>
                            project.roles[user.id] ||
                            (project.teams && Object.keys(project.teams).some((key) =>
                              selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
                            ))
                          ).map((user) => {
                          return {
                            id: user.id,
                            display: user.username
                          }
                        }) || []}
                        mentions={updatingComment.mentioningTo}
                        placeholder={t("labels.commentInput")} 
                        onChange={(text) => onChangeComment(text, "UPDATE")}
                        onMentioned={(mention) => updatingComment.addMention(mention)} /> 
                    </div>
                    <div className="comment-container__edit-comment-button-wrapper">
                    {
                      updatingComment.isUpdating ? 
                      (
                        <RotatingLines
                          strokeColor={Colors.primary}
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="32"
                          visible={true}
                        />
                      ) :
                      (
                        <>
                          <button 
                            onClick={() => {
                              setUpdatingComment(null);
                            }}
                            className="cancel-button">
                            キャンセル
                          </button>
                          <button 
                            disabled={updatingComment.text.trim().length === 0}
                            onClick={onUpdateButtonPressed}
                            className="update-button">
                            更新する
                          </button>
                        </>
                      )
                      }
                    </div>
                  </li>
                )
              }

              return (
                <li className="comment-container">
                  <CommentInput 
                    disabled={true}
                    value={comment.text}
                    items={[]}
                    mentions={[]}
                    placeholder={t("labels.commentInput")} 
                    onChange={() => {}}
                    onMentioned={() => {}} /> 
                  <div className="comment-container__extra-information">
                    <div className="profile-wrapper">
                      <div className="image-wrapper">
                        {
                          comment.profileImageUrl ? 
                          (
                            <img  src={comment.profileImageUrl} className="profile-image" />
                          ) :
                          (
                            <div className="profile-image--no-image">
                              {comment.username.charAt(0) || ""}
                            </div>
                          )
                        }
                      </div>
                      <p className="name-label">
                        {comment.username || ""}
                      </p>
                      <p className="time-label">
                        {comment.createdAt ? formatDate(comment.createdAt, DATE_FORMAT.COMMENT) : ""}
                      </p>
                    </div>
                    { comment.createdBy === loginUser.id && (
                      <button
                        onClick={() => setMenuOpenedCommentId(comment.id)}
                        >
                        {MenuIcon}
                      </button> 
                    )}  
                    { menuOpenedCommentId === comment.id && (
                        <div className="popup-menu">
                          {
                            comment.isDeleting ?
                            (
                              <div className="spinner-wrapper">
                                <RotatingLines
                                  strokeColor={Colors.primary}
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="32"
                                  visible={true}
                                />
                              </div>
                            ) :
                            (
                              <div className="popup-menu__section">
                                <div
                                  className="item"
                                  onClick={(event) => {
                                    event.stopPropagation(); 
                                    const newComment = new CommentModel(comment.getFields());
                                    newComment.setOrganizationId(selectedOrganization.id);
                                    setUpdatingComment(newComment);
                                  }}
                                >
                                  <div className="icon">{PenIcon}</div>
                                  <p>編集</p>
                                </div>
                                <div
                                  className="item"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    copyLinkToClipboard(comment);
                                  }}
                                >
                                  <div className="icon">{ClipIcon}</div>
                                  <p>リンクをコピー</p>
                                </div>
                                <div
                                  className="item-delete"
                                  onClick={(event) => {
                                    event.stopPropagation(); 
                                    onDeleteButtonPressed(comment);
                                  }}
                                >
                                  <div className="icon">{TrashIcon}</div>
                                  <p>消去</p>
                                </div>
                              </div>
                            )
                          }

                        </div>
                    )}
                  </div>
                </li>
              )
            }
            )}
          </ul>
          { !updatingComment && (
            <div className="comment-input-section">
              <CommentInput 
                disabled={false}
                value={newComment.text}
                items={selectedOrganization
                  .getJoinedUserExceptMe(loginUser)
                  .filter((user) => 
                    (project && project.roles[user.id]) ||
                    (project?.teams && Object.keys(project.teams).some((key) =>
                      selectedOrganization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
                    ))
                ).map((user) => {
                  return {
                    id: user.id,
                    display: user.username
                  }
                }) || []}
                mentions={newComment.mentioningTo}
                placeholder={t("labels.commentInput")} 
                onChange={(value) => onChangeComment(value, "CREATE")}
                onMentioned={(mention) => newComment.addMention(mention)}
              />
              {
                newComment.isCreating ? 
                (
                  <RotatingLines
                      strokeColor={Colors.primary}
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="32"
                      visible={true}
                    />
                ) :
                (
                  <button 
                    disabled={newComment.text.trim().length === 0}
                    onClick={onSubmitButtonPressed}>
                    {SendMessageIcon}
                  </button>
                )
              }
            </div>
          )}
        </>
        )}
      </div>
    </Popover>
  )
});

export default observer(CommentListPopover);