import React, { useCallback, useEffect, useRef, useState } from 'react';
import SettingsLayout from 'src/conpath/components/settings/SettingLayout';
import { RotatingLines } from 'react-loader-spinner';
import { Button } from 'src/conpath/components/Button';

//interfaces
import { LoginUserDocumentFields, LoginUserProfileForm, Image } from 'src/conpath/interfaces/LoginUser';
//mobx
import { observer } from "mobx-react-lite";
import { useStore } from 'src/conpath/hooks/useStore';
//others
import { Paths } from 'src/conpath/constants/Routes';
import Colors from 'src/conpath/constants/Colors';
import "./ProfileSettings.scss";



const ProfileSettings: React.FC = observer(() => {

  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [profile, setProfile] = useState<LoginUserProfileForm>({
    username: "",
    phoneNumber: "",
    profileImage: null,
  });
  const [defaultImageChar, setDefaultImageChar] = useState<string>("");
  const [isModified, setIsModified] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const setCurrentProfile = () => {
    const field = loginUser!.getFields();
    setProfile({
      username: field.username,
      phoneNumber: field.phoneNumber,
      profileImage: field.profileImageUrl ? {
        url: field.profileImageUrl,
        file: null,
      } : null
    });
    setDefaultImageChar(field.username.charAt(0));
  };

  useEffect(() => {
    if (loginUser) {
      setCurrentProfile();
    }
  }, [loginUser]);


  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  /**
   * handle changing user inputs (username and description).
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setProfile((prev) => ({
        ...prev,
        [name]: value
      }));
      if (!isModified) setIsModified(true);
    }
  };

  /**
   * プロフィール画像の変更処理
   */
  const onChangeImageButtonPressed = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files) {
        setErrorMessage("プロフィール画像を変更できませんでした。");
        return;
      }

      const file = event.target.files[0];
      const { size } = file;
      if (size > 12 * 1_000_000) {
        setErrorMessage("12MB以上の画像は選択できません。");
        return;
      }
  
      const imageURL: string = URL.createObjectURL(file); //preview
      const image: Image = {
        url: imageURL,
        file: file,
      };
      setProfile(prev => ({
        ...prev,
        profileImage: image
      }));
      if (!isModified) setIsModified(true);
    } finally {
      event.target.value = '';
    }
  };

  /**
   * プロフィール画像の取り消し
   */
  const onRemoveImageButtonPressed = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button: HTMLButtonElement = event.currentTarget;
    const { name } = button;
    if (name === "profileImageUrl") {
      setProfile((prev) => ({
        ...prev,
        profileImage: loginUser?.profileImageUrl ? {
          url: loginUser.profileImageUrl,
          file: null,
        } : null
      }));
    }
  };

  const onUpdateButtonPressed = useCallback(async () => {
    if (!loginUser || !selectedOrganization) return;
    const result = await loginUser.updateUserProfile(profile);
    if (result.error) {
      setErrorMessage(result.error);
      return;
    }

    await userStore.setUser(loginUser.id);
    userStore.loginUser?.setOrganizationRole(selectedOrganization);
    setIsModified(false);
    setSuccessMessage("プロフィールを更新しました。");

  }, [userStore, loginUser, selectedOrganization, profile]);

  const onCancelButtonPressed = useCallback(() => {
    if (!loginUser) return;

    setCurrentProfile();
    setIsModified(false);
  }, [loginUser, setProfile]);

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle='プロフィール'
        currentPath={Paths.profile}>
        <div className="profile-setting setting-body">
          <div className="section">
            <div className="form__wrapper form__wrapper--box">
              <div className="form-container">
                <div className="selection-wrapper">
                  <p>ユーザー名</p>
                  <input
                    name={LoginUserDocumentFields.username}
                    className="profile-setting--input"
                    value={profile.username}
                    onChange={onChangeInput}
                    onBlur={() => {
                      setDefaultImageChar(profile.username.charAt(0));
                    }}
                  />
                </div>
              </div>
              {/* <div className="v-stack p-[12px]">
                <h3 className="project__title my-2">
                  電話番号
                </h3>
                <input
                  name={LoginUserDocumentFields.phoneNumber}
                  className="profile-setting--input"
                  value={profile.phoneNumber}
                  placeholder="ハイフン抜き 例: 05012345678"
                  onChange={onChangeInput}
                />
              </div> */}
              <div>
                <div className="selection-wrapper">
                  <p>プロフィール画像</p>
                  <div className="profile-setting--imageSelectionWrapper">
                    <input
                      name="file"
                      type="file"
                      accept="image/*"
                      hidden
                      ref={inputRef}
                      onChange={onChangeImage}
                    />
                    <div className="image-wrapper">
                      {
                        profile.profileImage ?
                          <img className="profile-image" src={profile.profileImage.url} alt={"PROFILE_IMG"} />
                          :
                          <div className="default-image">
                            {defaultImageChar}
                          </div>
                      }
                    </div>
                    <div className="image-selection">
                      <p className="label">
                        プロフィール画像はプロジェクトのコラボレーション時に他のユーザーに表示されます。
                      </p>
                      <div className="text-button-wrapper">
                        <button
                          name="profileImageUrl"
                          onClick={onChangeImageButtonPressed} className="clickable-label">
                          変更する
                        </button>
                        <button
                          name="profileImageUrl"
                          onClick={onRemoveImageButtonPressed} className="clickable-label">
                          リセット
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loginUser?.isNetworking ?
                  <div className="profile-setting--spinner-wrapper">
                    <RotatingLines
                      strokeColor={Colors.primary}
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="42"
                      visible={true}
                    />
                  </div>
                  :
                  <div className="profile-setting--buttonContainer">
                    <div className="profile-setting--buttonRow">
                      <Button
                        disabled={!isModified}
                        name="abort"
                        theme="solid"
                        onClick={onCancelButtonPressed}>
                        キャンセル
                      </Button>
                      <Button
                        disabled={!isModified}
                        name="submit"
                        theme="main"
                        onClick={onUpdateButtonPressed}>
                        更新
                      </Button>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  )
});

export default ProfileSettings;