import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Flip, ToastContainer, toast } from "react-toastify";
import RcGantt, { jaJP } from "src/conpath/components/gantt";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";

//styles
import "./Roadmap.scss";

//constants
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import { APP_NAME } from "src/excalidraw/constants";

//components
import { DefaultRecordType, Gantt } from "src/conpath/components/gantt/types";
import SearchListBox, { SearchListOption } from "src/conpath/components/SearchListBox";
import { Button } from "src/conpath/components/Button";
import { RoadmapFilterTags } from "src/conpath/interfaces/RoadmapFilter";
import SearchTagsInput from "src/conpath/components/SearchTagsInput";
import Checkbox from "src/conpath/components/CheckBox";
import Header from "src/conpath/components/layouts/Header";

//models
import TaskModel from "src/conpath/models/TaskModel";
import { OrganizationUserState } from "src/conpath/constants/OrganizationUserState";
import Sidebar from "src/conpath/components/sidebar/Sidebar";

const Roadmap: React.FC = observer(() => {
  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  const roadmapFilter = selectedOrganization?.roadmapFilter;

  const [searchText, setSearchText] = useState<string[]>([]);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);

  const statusOptions = [
    { value: "open", label: "未完了" },
    { value: "closed", label: "完了済み" },
  ];

  useEffect(() => {
    if (selectedOrganization?.roadmapDataFetchError) {
      toast.error(selectedOrganization.roadmapDataFetchError);
      selectedOrganization.removeRoadmapError();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (loginUser && selectedOrganization) {
      selectedOrganization?.subscribeRoadmapData(loginUser, selectedOrganization);
    }

    return () => {
      selectedOrganization?.unsubscribeRoadmapData();
    }
  }, [selectedOrganization, loginUser]);

  const assignResourcesOption = useMemo(() => {
    return (
      selectedOrganization?.resources
        ?.filter((resource) => resource.isActive && !resource.isDeleted)
        .map(
          (resource) =>
            ({ value: resource.id, label: resource.name } as SearchListOption),
        ) || []
    );
  }, [selectedOrganization?.resources]);

  const assignUsersOption = useMemo(() => {
    return selectedOrganization?.users?.filter(user => user.state === OrganizationUserState.joined)
      .map(user => ({ value: user.id, label: user.username })) || [];
  }, [selectedOrganization?.users]);

  const projectOptions = useMemo((): SearchListOption[] => {
    return selectedOrganization?.getActiveProjects()
      .map(project => ({ value: project.id, label: project.name })) || [];
  }, [selectedOrganization?.projects]);

  const milestoneOptions = useMemo((): SearchListOption[] => {
    const options: SearchListOption[] = [];

    selectedOrganization?.projectTasks?.forEach((item) => {
      item.children?.forEach((child) => {
        if (child.isMilestone) {
          options.push({ value: child.id, label: child.name || "" });
        }
      });
    });

    return options;
  }, [selectedOrganization?.projectTasks]);

  useEffect(() => {
    const _searchText = [];
    if (roadmapFilter?.searchStatus) {
      _searchText.push(`${RoadmapFilterTags.searchStatus}:${statusOptions.find((key) => key.value === roadmapFilter?.searchStatus)?.label}`);
    }
    if (roadmapFilter?.searchAssignUser) {
      _searchText.push(`${RoadmapFilterTags.searchAssignUser}:${assignUsersOption.find((key) => key.value === roadmapFilter?.searchAssignUser)?.label}`);
    }
    if (roadmapFilter?.searchAssignResource) {
      _searchText.push(`${RoadmapFilterTags.searchAssignResource}:${assignResourcesOption.find((key) => key.value === roadmapFilter?.searchAssignResource)?.label}`);
    }
    if (roadmapFilter?.searchProject) {
      _searchText.push(`${RoadmapFilterTags.searchProject}:${projectOptions.find((key) => key.value === roadmapFilter?.searchProject)?.label}`);
    }
    if (roadmapFilter?.searchMilestone) {
      _searchText.push(`${RoadmapFilterTags.searchMilestone}:${milestoneOptions.find((key) => key.value === roadmapFilter?.searchMilestone)?.label}`);
    }
    if (roadmapFilter?.searchText) {
      _searchText.push(`${RoadmapFilterTags.searchText}:${roadmapFilter?.searchText}`);
    }

    setSearchText(_searchText);
  }, [roadmapFilter]);

  const onChangeText = useCallback((tags: string[]) => {
    if (tags.length > searchText.length) {
      const currentValue = roadmapFilter?.searchText;
      if (currentValue !== tags[tags.length - 1]) {
        selectedOrganization?.setRoadmapFilter({ "searchText": tags[tags.length - 1] })
      }
    } else {
      if (
        roadmapFilter?.searchText &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchText)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchText": null })
      }

      if (
        roadmapFilter?.searchAssignUser &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchAssignUser)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchAssignUser": null })
      }

      if (
        roadmapFilter?.searchAssignResource &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchAssignResource)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchAssignResource": null })
      }

      if (
        roadmapFilter?.searchStatus &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchStatus)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchStatus": null })
      }

      if (
        roadmapFilter?.searchProject &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchProject)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchProject": null })
      }

      if (
        roadmapFilter?.searchMilestone &&
        tags.filter((v) => v.includes(RoadmapFilterTags.searchMilestone)).length === 0
      ) {
        selectedOrganization?.setRoadmapFilter({ "searchMilestone": null })
      }
    }
  }, [roadmapFilter, searchText]);

  const updateTaskProperties = async () => {
    if (selectedTask) {
      if (selectedTask.isUpdatedChecklists) {
        await selectedTask.saveChecklist();
      }

      selectedOrganization?.forceReloadRoadmapData(selectedOrganization);
    }
  };

  /**
   * TaskBarクリック時にそのタスクに関する情報を表示
   */
  const onBarClick = useCallback(async (record: Gantt.Record<DefaultRecordType>) => {
    if (!record.isTask || !selectedOrganization) {
      onCloseSidebar();
      return;
    };

    await updateTaskProperties();
    const task = selectedOrganization.getTaskById(record.id.split(",")[0]) || null;
    setSelectedTask(task);
  }, [selectedTask, selectedOrganization]);

  const onCloseSidebar = async () => {
    if (!selectedOrganization) return;

    await updateTaskProperties();
    setSelectedTask(null);
  };

  const onExpand = useCallback(async (record: Gantt.Record<DefaultRecordType>, collapsed: boolean) => {
    if (record.isTask) {
      await onBarClick(record);
    } else {
      selectedOrganization?.setRowCollapse(record, collapsed);
    }
  }, [selectedOrganization, onBarClick]);

  const onExpandAll = useCallback((collapsed: boolean) => {
    selectedOrganization?.setAllRowsCollapse(collapsed);
  }, [selectedOrganization]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`ロードマップ - ${APP_NAME}`}</title>
        </Helmet>
        <Header />
        <div
          className="roadmap-container">
          <ToastContainer
            position="top-center"
            transition={Flip}
            autoClose={TOAST_DEFAULT_DURATION}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="roadmap-nav">
            {/* <SearchListBox
            title="担当者"
            options={milestoneOptions}
            selected={roadmapFilter?.searchMilestone || ""}
            onChange={(selectedValue: string) => {
              const currentValue = roadmapFilter?.searchMilestone;
              selectedOrganization?.setRoadmapFilter({
                "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          />
          <SearchListBox
            title="業者"
            options={milestoneOptions}
            selected={roadmapFilter?.searchMilestone || ""}
            onChange={(selectedValue: string) => {
              const currentValue = roadmapFilter?.searchMilestone;
              selectedOrganization?.setRoadmapFilter({
                "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          />
          <SearchListBox
            title="部署"
            options={milestoneOptions}
            selected={roadmapFilter?.searchMilestone || ""}
            onChange={(selectedValue: string) => {
              const currentValue = roadmapFilter?.searchMilestone;
              selectedOrganization?.setRoadmapFilter({
                "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          /> */}
            <SearchListBox
              title="ステータス"
              options={statusOptions}
              selected={roadmapFilter?.searchStatus || ""}
              onChange={(selectedValue: string) => {
                const currentValue = roadmapFilter?.searchStatus;
                selectedOrganization?.setRoadmapFilter({
                  "searchStatus": currentValue !== selectedValue ? selectedValue : null,
                })
              }}
              customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            />
            <SearchListBox
              title="担当者"
              options={assignUsersOption}
              selected={roadmapFilter?.searchAssignUser || ""}
              onChange={(selectedValue: string) => {
                const currentValue = roadmapFilter?.searchAssignUser;
                selectedOrganization?.setRoadmapFilter({
                  "searchAssignUser": currentValue !== selectedValue ? selectedValue : null,
                })
              }}
              disabled={!assignUsersOption.length}
              customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            />
            <SearchListBox
              title="リソース"
              options={assignResourcesOption}
              selected={roadmapFilter?.searchAssignResource || ""}
              onChange={(selectedValue: string) => {
                const currentValue = roadmapFilter?.searchAssignResource;
                selectedOrganization?.setRoadmapFilter({
                  "searchAssignResource": currentValue !== selectedValue ? selectedValue : null,
                })
              }}
              disabled={!assignResourcesOption.length}
              customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            />
            <SearchListBox
              title="プロジェクト"
              options={projectOptions}
              selected={roadmapFilter?.searchProject || ""}
              onChange={(selectedValue: string) => {
                const currentValue = roadmapFilter?.searchProject;
                selectedOrganization?.setRoadmapFilter({
                  "searchProject": currentValue !== selectedValue ? selectedValue : null,
                })
              }}
              disabled={!projectOptions.length}
              customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            />
            <SearchListBox
              title="マイルストーン"
              options={milestoneOptions}
              selected={roadmapFilter?.searchMilestone || ""}
              onChange={(selectedValue: string) => {
                const currentValue = roadmapFilter?.searchMilestone;
                selectedOrganization?.setRoadmapFilter({
                  "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
                })
              }}
              disabled={!milestoneOptions.length}
              customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            />
            <div className="flex flex-row items-center mr-[40px] ml-[20px]">
              <Checkbox
                props={{
                  onClick: () => selectedOrganization?.setRoadmapFilter({ "showOnlyDelayedTasks": !selectedOrganization?.roadmapFilter.showOnlyDelayedTasks }),
                  checked: selectedOrganization?.roadmapFilter.showOnlyDelayedTasks,
                  className: "task-checklist__item__checkbox",
                  sx: {
                    color: "#DDDDDD",
                    backgroundImage: "linear-gradient(to right, white, white)",
                    borderRadius: 0,
                    height: 18,
                    width: 18,
                  },
                }}
              />
              <label className="text-left sm:text-sm relative ml-[7px]">期限遅れ</label>
            </div>

            <div className="search-wrapper search-bar mr-5">
              {/* <SearchBar
              value={searchText}
              onChange={onChangeText}
              onEnterKeyPressed={() => {
                selectedOrganization?.setRoadmapFilter({ "searchText": searchText })
              }}
              placeholder=""
              style={{ border: "1px solid #F5F6F7", borderRadius: "5px", height: "45px" }}
            /> */}
              <SearchTagsInput
                tags={searchText}
                onChangeTags={(newTags) => { onChangeText(newTags) }}
                className="search-box"
              />
            </div>
            <Button theme="solid" style={{ fontSize: "14px" }} onClick={() => {
              selectedOrganization?.resetRoadmapFilters()
              setSearchText([]);
            }}>
              クリア
            </Button>
          </div>
          <div
            className="gantt-chart-container">
            <RcGantt
              data={
                selectedOrganization ?
                  selectedOrganization.projectTasks : []
              }
              dependencies={
                selectedOrganization ?
                  selectedOrganization.projectDependencies : []
              }
              locale={jaJP}
              columns={[
                {
                  name: "name",
                  label: "",
                  minWidth: 200,
                },
              ]}
              onExpand={onExpand}
              onExpandAll={onExpandAll}
              onUpdate={async () => {
                return false;
              }}
              onRow={{
                onClick: onBarClick,
              }}
              onBarClick={onBarClick}
              storeName="RoadmapStore"
              chartType={Gantt.ChartType.Roadmap}
            />
            {selectedTask && (
              <Sidebar
                task={selectedTask}
                loginUser={loginUser}
                organization={selectedOrganization}
                onCloseSidebar={onCloseSidebar}
                isResources={false}
              />
            )}
          </div>
        </div>
      </HelmetProvider>
    </>
  );
});

export default Roadmap;
