import {
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import _ from "lodash";
import Select from 'react-select';
import { t } from "src/excalidraw/i18n";
import { LibraryItems, LibraryItem, AppState } from "src/excalidraw/types";

import "./LibraryMenuEx.scss";
import LibraryMenuItemsEx from "./LibraryMenuItemsEx";
import { EVENT } from "src/excalidraw/constants";
import { KEYS } from "src/excalidraw/keys";
import { trackEvent } from "src/excalidraw/analytics";
import Spinner from "src/excalidraw/components/Spinner";
import {
  useDevice,
  useExcalidrawElements,
  useExcalidrawSetAppState,
} from "src/excalidraw/components/App";
import { useOnClickOutside } from "src/excalidraw/extensions/hooks/useOutsideClick";
import { Sidebar } from "src/excalidraw/components/Sidebar/Sidebar";
import { getSelectedElements } from "src/excalidraw/scene";
import { NonDeletedExcalidrawElement } from "src/excalidraw/element/types";
import { LibraryMenuHeaderEx } from "./LibraryMenuHeaderContentEx";

import SearchBar from "src/conpath/components/SearchBar";

import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

export interface LibraryItemsData {
  status: "loading" | "loaded";
  isInitialized: boolean;
  libraryItems: LibraryItems;
}

const LibraryMenuWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div ref={ref} className="layer-ui__library">
      {children}
    </div>
  );
});

export const LibraryMenuContent = ({
  pendingElements,
  onToggleBookmarkFromLibrary,
  setAppState,
  id,
  appState,
  selectedItems,
  onSelectItems,
  libraryItemsData,
}: {
  pendingElements: LibraryItem["elements"];
  onToggleBookmarkFromLibrary: (libraryItem: LibraryItem) => void;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  appState: AppState;
  selectedItems: LibraryItem["id"][];
  onSelectItems: (id: LibraryItem["id"][]) => void;
  libraryItemsData: LibraryItemsData;
}) => {
  if (
    libraryItemsData.status === "loading" &&
    !libraryItemsData.isInitialized
  ) {
    return (
      <LibraryMenuWrapper>
        <div className="layer-ui__library-message">
          <div>
            <Spinner size="2em" />
            <span>{t("labels.libraryLoadingMessage")}</span>
          </div>
        </div>
      </LibraryMenuWrapper>
    );
  }

  return (
    <LibraryMenuWrapper>
      <LibraryMenuItemsEx
        isLoading={libraryItemsData.status === "loading"}
        libraryItems={libraryItemsData.libraryItems}
        onAddToLibrary={() =>
          setAppState({ openDialog: "addLibrary" })
        }
        onToggleBookmarkFromLibrary={onToggleBookmarkFromLibrary}
        pendingElements={pendingElements}
        selectedItems={selectedItems}
        onSelectItems={onSelectItems}
        id={id}
        theme={appState.theme}
      />
    </LibraryMenuWrapper>
  );
};

export const LibraryMenuEx: React.FC<{
  appState: AppState;
  onInsertElements: (elements: readonly NonDeletedExcalidrawElement[]) => void;
  focusContainer: () => void;
  id: string;
}> = observer(({
  appState,
  onInsertElements,
  focusContainer,
  id,
}) => {
  const setAppState = useExcalidrawSetAppState();
  const elements = useExcalidrawElements();
  const device = useDevice();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [selectedItems, setSelectedItems] = useState<LibraryItem["id"][]>([]);
  const [libraryItems, setLibraryItems] = useState<LibraryItem[]>([]);
  const [libraryItemsData, setLibraryItemsData] = useState<LibraryItemsData>({
    status: "loading",
    isInitialized: false,
    libraryItems: [],
  });

  const [searchText, setSearchText] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<{ value: string, label: string } | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<{ value: string, label: string }[]>([]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getLibraries = async () => {
      if (selectedOrganization) {
        const result = await selectedOrganization.getLibraries();
        if (result && result.error) setAppState({ errorMessage: result.error });
      }
    }

    getLibraries();
  }, []);

  useEffect(() => {
    if (selectedOrganization) {
      const _libraryItems = selectedOrganization.libraries
        .map((library) => ({
          id: library.id,
          name: library.name,
          status: "published",
          category: library.category,
          ...JSON.parse((library.contentText)),
          isBookmarked: Object.keys(library.bookmarkedUsers).includes(loginUser?.id || ""),
          createdBy: library.createdBy,
          created: library.createdAt.getTime(),
        } as LibraryItem));

      const categories = new Set(_libraryItems.map(e => e.category!));
      const options = [...categories.values()].map(e => ({ value: e, label: e }));
      setCategoryOptions(options);

      setLibraryItems(_libraryItems);
      setLibraryItemsData({
        status: "loaded",
        isInitialized: true,
        libraryItems: _libraryItems.sort((a, b) => {
          if (a.isBookmarked && !b.isBookmarked) {
            return -1;
          } else if (!a.isBookmarked && b.isBookmarked) {
            return 1;
          }
          return b.created - a.created;
        })
      });
    }
  }, [selectedOrganization?.libraries])

  useEffect(() => {
    return () => {
      selectedOrganization?.clearLibraries();
    }
  }, [selectedOrganization])

  useEffect(() => {
    onSearch();
  }, [searchCategory]);

  const onSearchButtonPressed = useCallback(() => {
    onSearch();
  }, [searchText]);

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ openSidebar: null });
  }, [setAppState]);

  useOnClickOutside(
    ref,
    useCallback(
      (event) => {
        // If click on the library icon, do nothing so that LibraryButton
        // can toggle library menu
        if ((event.target as Element).closest(".ToolIcon__library")) {
          return;
        }
        if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
          closeLibrary();
        }
      },
      [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar],
    ),
  );

  const onSearch = useCallback(() => {
    if (selectedOrganization) {
      const _libraryItems = libraryItems
        .sort((a, b) => {
          if (a.isBookmarked && !b.isBookmarked) {
            return -1;
          } else if (!a.isBookmarked && b.isBookmarked) {
            return 1;
          }
          return b.created - a.created;
        })
        .filter((v) => !_.isEmpty(searchText)
          ? v.name!.toLowerCase().includes(searchText.toLowerCase())
          : true)
        .filter((v) => searchCategory ? v.category === searchCategory.value : true);

      setLibraryItemsData({
        ...libraryItemsData,
        libraryItems: _libraryItems,
      });
    }
  }, [libraryItems, searchText, searchCategory]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === KEYS.ESCAPE &&
        (!appState.isSidebarDocked || !device.canDeviceFitSidebar)
      ) {
        closeLibrary();
      }
    };
    document.addEventListener(EVENT.KEYDOWN, handleKeyDown);
    return () => {
      document.removeEventListener(EVENT.KEYDOWN, handleKeyDown);
    };
  }, [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar]);

  const removeFromLibrary = useCallback(
    async (libraryItems: LibraryItems) => {
      if (loginUser) {
        // 作成者または組織のオーナーのみ削除可能とする
        if (
          loginUser.isOrganizationOwner() ||
          !libraryItems.some((item) => selectedItems.includes(item.id) && loginUser.id !== item.createdBy)
        ) {
          selectedOrganization?.deleteLibraries(selectedItems)
            .catch(() => {
              setAppState({ errorMessage: t("alerts.errorRemovingFromLibrary") });
            });
          setSelectedItems([]);
        } else {
          setAppState({ errorMessage: t("alerts.permissionErrorRemovingFromLibrary") });
        }
      }
    },
    [loginUser, selectedOrganization, setAppState, selectedItems, setSelectedItems],
  );

  const updateFromLibrary = useCallback(() => {
    const categories = new Set(libraryItems.map(e => e.category!));
    const options = [...categories.values()].map(e => ({ value: e, label: e }));
    setCategoryOptions(options);
  }, [libraryItems]);

  const toggleBookmarkFromLibrary = useCallback(
    async (libraryItem: LibraryItem) => {
      if (loginUser) {
        const libraryModel = selectedOrganization?.libraries.find((library) => library.id === libraryItem.id);
        libraryModel?.setBookmarkedUsers(loginUser.id, libraryItem.isBookmarked!);
        await libraryModel?.updateLibrary();

        onSearch();
      }
    },
    [loginUser, selectedOrganization, onSearch],
  );

  return (
    <Sidebar
      __isInternal
      // necessary to remount when switching between internal
      // and custom (host app) sidebar, so that the `props.onClose`
      // is colled correctly
      key="library"
      className="layer-ui__library-sidebar"
      initialDockedState={appState.isSidebarDocked}
      onDock={(docked) => {
        trackEvent(
          "library",
          `toggleLibraryDock (${docked ? "dock" : "undock"})`,
          `sidebar (${device.isMobile ? "mobile" : "desktop"})`,
        );
      }}
      ref={ref}
    >
      <Sidebar.Header className="layer-ui__library-header">
        <div className="layer-ui__library">
          <div className="header-content-wrapper">
            <SearchBar
              className="search-bar"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onEnterKeyPressed={onSearchButtonPressed}
              placeholder="ライブラリ名で検索"
            />
            <div className="header-content-wrapper__category-select">
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    fontSize: "13px",
                    fontWeight: "normal",
                    // color: Colors.textColor,
                    padding: 4,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    minHeight: '28px',
                    height: '28px',
                    boxShadow: state.isFocused ? "0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 inset!important" : "none",
                    border: "1px solid #C9C9C9",
                    '&:hover': {
                      borderColor: "#C9C9C9",
                    }
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: '28px',
                    padding: '0 2px'
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '28px',
                  }),
                  singleValue: base => ({
                    ...base,
                    fontSize: "13px",
                    fontWeight: "normal",
                    // color: Colors.textColor,
                  }),
                  input: base => ({
                    ...base,
                    fontSize: "13px",
                    // color: Colors.textColor,
                  }),
                  placeholder: base => ({
                    ...base,
                    color: "#9CA3AF",
                    // color: Colors.textColor,
                  }),
                }}
                isClearable={true}
                value={searchCategory}
                isSearchable={true}
                options={categoryOptions}
                onChange={setSearchCategory}
                placeholder={"カテゴリーで検索"}
              />
            </div>
          </div>
        </div>
      </Sidebar.Header>
      <LibraryMenuHeaderEx
        appState={appState}
        setAppState={setAppState}
        selectedItems={selectedItems}
        onSelectItems={setSelectedItems}
        onRemoveFromLibrary={() =>
          removeFromLibrary(libraryItemsData.libraryItems)
        }
        onUpdateFromLibrary={updateFromLibrary}
        libraryItemsData={libraryItemsData}
      />
      <LibraryMenuContent
        pendingElements={getSelectedElements(elements, appState, {
          includeBoundTextElement: true,
        })}
        onToggleBookmarkFromLibrary={toggleBookmarkFromLibrary}
        setAppState={setAppState}
        id={id}
        appState={appState}
        selectedItems={selectedItems}
        onSelectItems={setSelectedItems}
        libraryItemsData={libraryItemsData}
      />
    </Sidebar>
  );
});