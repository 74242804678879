import { FileType } from "src/conpath/interfaces/Comment"


export const getFileExtension = (type?: FileType): string => {
  switch(type) {
    case "image/png":
      return ".png";
    case "image/jpeg":
      return ".jpeg";
    case "application/pdf":
      return ".pdf";
    default:
      return ".conpath";
  }
}