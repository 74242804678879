import { AppState, PointerCoords, Zoom } from "../types";
import { ExcalidrawElement } from "../element/types";
import {
  getCommonBounds,
  getClosestElementBounds,
  getVisibleElements,
} from "../element";

import {
  sceneCoordsToViewportCoords,
  viewportCoordsToSceneCoords,
} from "../utils";
import {
  CANVAS_MARGIN_BOTTOM,
  CANVAS_MARGIN_RIGHT,
  JOB_ELEMENTS_WIDTH
} from "src/excalidraw/constants";
// CHANGED:ADD 2022-12-5 #177
import Scroll from "src/excalidraw/extensions/scene/scroll"; // from extensions
import Calendar from "src/excalidraw/extensions/calendar"; // from extensions

const isOutsideViewPort = (appState: AppState, cords: Array<number>) => {
  const [x1, y1, x2, y2] = cords;
  const { x: viewportX1, y: viewportY1 } = sceneCoordsToViewportCoords(
    { sceneX: x1, sceneY: y1 },
    appState,
  );
  const { x: viewportX2, y: viewportY2 } = sceneCoordsToViewportCoords(
    { sceneX: x2, sceneY: y2 },
    appState,
  );
  return (
    viewportX2 - viewportX1 > appState.width ||
    viewportY2 - viewportY1 > appState.height
  );
};

export const centerScrollOn = ({
  scenePoint,
  viewportDimensions,
  zoom,
  appState, // CHANGED:ADD 2022-12-5 #177
}: {
  scenePoint: PointerCoords;
  viewportDimensions: { height: number; width: number };
  zoom: Zoom;
  appState: AppState; // CHANGED:ADD 2022-12-5 #177
}) => {
  // CHANGED:ADD 2022-12-5 #177
  const scroll = new Scroll({
    limitX:
      appState.calendarWidth -
      ((window.innerWidth - CANVAS_MARGIN_RIGHT) / zoom.value - JOB_ELEMENTS_WIDTH),
    limitY:
      appState.jobsHeight -
      (window.innerHeight - CANVAS_MARGIN_BOTTOM) / zoom.value,
  });

  return {
    // CHANGED:UPDATE 2022-12-5 #177
    // scrollX: viewportDimensions.width / 2 / zoom.value - scenePoint.x,
    // scrollY: viewportDimensions.height / 2 / zoom.value - scenePoint.y,
    scrollX: scroll.getOffsetScrollX(viewportDimensions.width / 2 / zoom.value - scenePoint.x),
    scrollY: scroll.getOffsetScrollY(viewportDimensions.height / 2 / zoom.value - scenePoint.y),
  };
};

export const calculateScrollCenter = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
): { scrollX: number; scrollY: number } => {
  elements = getVisibleElements(elements);

  if (!elements.length) {
    return {
      scrollX: 0,
      scrollY: 0,
    };
  }
  let [x1, y1, x2, y2] = getCommonBounds(elements);

  if (isOutsideViewPort(appState, [x1, y1, x2, y2])) {
    [x1, y1, x2, y2] = getClosestElementBounds(
      elements,
      viewportCoordsToSceneCoords(
        { clientX: appState.scrollX, clientY: appState.scrollY },
        appState,
      ),
    );
  }

  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  return centerScrollOn({
    scenePoint: { x: centerX, y: centerY },
    viewportDimensions: { width: appState.width, height: appState.height },
    zoom: appState.zoom,
    appState, // CHANGED:ADD 2022-12-5 #177
  });
};
