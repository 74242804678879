import { OrganizationProfileForm } from "src/conpath/interfaces/Organization";
import _ from "lodash";
import ProjectValidation from "./ProjectValidation";

export default class OrganizationValidation extends ProjectValidation {
  public validateOrganizationProfileInput(form: OrganizationProfileForm): string|null {    
    if (_.isEmpty(form.name.trim())) return "組織名を記入してください。";
    
    return null;
  }
}