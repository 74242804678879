import React, { useCallback, useContext, useMemo } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import Context from '../../context';
import './index.scss';
import { Gantt } from '../../types';

const TableHeader: React.FC = () => {
  const { store, prefixCls, onExpandAll, chartType } = useContext(Context);
  const { columns, tableWidth, getBarList } = store;
  const width = tableWidth;
  const columnsWidth = store.getColumnsWidth;
  const prefixClsTableHeader = `${prefixCls}-table-header`;

  const { enableExpand, enableExpandAll, enableCollapseAll } = useMemo(() => {
    const filteredBars = chartType === Gantt.ChartType.Roadmap
      ? getBarList.filter((bar) => bar._depth <= 1 && bar._childrenCount > 0)
      : getBarList.filter((bar) => bar._depth === 0 && bar._childrenCount > 0);

    return {
      enableExpand: chartType === Gantt.ChartType.Roadmap
        ? getBarList.some((bar) => bar._depth === 0 && bar._collapsed)
        : false,
      enableExpandAll: filteredBars.some((bar) => bar._collapsed),
      enableCollapseAll: filteredBars.some((bar) => !bar._collapsed),
    };
  }, [getBarList, chartType]);

  const handleClick = useCallback(
    debounce((collapsed: boolean) => {
      if (onExpandAll) onExpandAll(collapsed);
      store.setAllRowsCollapse(collapsed);
    }, 500),
    [store, onExpandAll],
  );

  return (
    <div className={prefixClsTableHeader} style={{ width, height: 56 }}>
      <div
        className={`${prefixClsTableHeader}-head`}
        style={{ width, height: 56 }}
      >
        <div className={`${prefixClsTableHeader}-row`} style={{ height: 56 }}>
          {columns.map((column, index) => (
            <div
              key={column.name}
              className={`${prefixClsTableHeader}-cell`}
              style={{
                width: columnsWidth[index],
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                textAlign: column.align || "left",
                ...column.style,
              }}
            >
              <div style={{ width: "95%" }}>
                <label className="w-full flex bg-white themeSwitcherTwo rounded-[5px] border-solid border-[1px] border-[#DDDDDD] cursor-pointer">
                  <fieldset className="w-1/2">
                    <div
                      className={`fixed__switch rounded-[5px] rounded-r-none ${enableExpandAll ? "bg-[#F5F6F7]" : "bg-[#DDDDDD]"}`}
                      onClick={() => handleClick(false)}
                    >
                      {`${enableExpand ? "列を展開" : "列をすべて展開"}`}
                    </div>
                  </fieldset>
                  <fieldset className="w-1/2">
                    <div
                      className={`fixed__switch rounded-[5px] rounded-l-none ${enableCollapseAll ? "bg-[#F5F6F7]" : "bg-[#DDDDDD]"}`}
                      onClick={() => handleClick(true)}
                    >
                      列をすべて閉じる
                    </div>
                  </fieldset>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(TableHeader);
