import { Calendar, CalendarProps } from "react-multi-date-picker";
import gregorian_ja from "src/excalidraw/extensions/locale/gregorian_ja";
import "./Calendar.scss";
import { IoCaretDownOutline } from "react-icons/io5";
import classNames from "classnames";

const RMDPClaendar = ({ props }: { props: CalendarProps }) => {
  const customRenderButton = (
    direction: string,
    handleClick: React.MouseEventHandler<HTMLButtonElement>,
    disabled: boolean,
  ) => {
    return (
      <button
        onClick={handleClick}
        className={classNames("conpath-calendar__nav", {
          ["left"]: direction == "left",
          ["disabled"]: disabled,
        })}
      >
        <IoCaretDownOutline />
      </button>
    );
  };

  const cn = classNames("conpath-calendar", {
    [`${props.className}`]: !!props.className,
    [`conpath-calendar--disabled`]: props.disabled,
  });
  const _props = {
    ...props,
    locale: gregorian_ja,
    className: cn,
    renderButton: customRenderButton,
  };

  return <Calendar {..._props} />;
};
export default RMDPClaendar;
