import type { Drawable, Options } from "roughjs/bin/core";
import type { RoughGenerator } from "roughjs/bin/generator";
import { getDiamondPoints, getArrowheadPoints, getArrowheadExPoints } from "../element";
import type { ElementShapes } from "./types";
import type {
  ExcalidrawElement,
  NonDeletedExcalidrawElement,
  ExcalidrawSelectionElement,
  ExcalidrawLinearElement,
  Arrowhead,
} from "../element/types";
import { isPathALoop, getCornerRadius } from "../math";
import { generateFreeDrawShape } from "../renderer/renderElement";
import { isTransparent, assertNever } from "../utils";
import { simplify } from "points-on-curve";
import {
  CLITICAL_PATH_TASK_LINE_WIDTH,
  CLOSED_LINK_LINE_WIDTH, 
  CLOSED_TASK_LINE_WIDTH,
  DASH_STROKE_LENGTH,
  GRID_SIZE,
  LINK_LINE_WIDTH, ROUGHNESS,
} from "../constants";
import {
  isLinearElement,
} from "../element/typeChecks";
import { canChangeRoundness } from "./comparisons";
import { isTaskElement } from "../extensions/element/typeChecks";
import { ArrowheadEx, ExcalidrawTaskElement } from "../extensions/element/types";
import dayjs from "dayjs";
import ColorsEx from "../extensions/constants/ColorsEx";
import { CriticalPathColor } from "@/src/conpath/constants/Colors";

const getDashArrayDashed = (strokeWidth: number) => [5, 5];

const getDashArrayDotted = (strokeWidth: number) => [1.5, 6 + strokeWidth];

function adjustRoughness(element: ExcalidrawElement): number {
  const roughness = (element.roughness || 0);

  const maxSize = Math.max(element.width, element.height);
  const minSize = Math.min(element.width, element.height);

  // don't reduce roughness if
  if (
    // both sides relatively big
    (minSize >= 20 && maxSize >= 50) ||
    // is round & both sides above 15px
    (minSize >= 15 &&
      !!element.roundness &&
      canChangeRoundness(element.type)) ||
    // relatively long linear element
    (isLinearElement(element) && maxSize >= 50)
  ) {
    return roughness;
  }

  return Math.min(roughness / (maxSize < 10 ? 3 : 2), 2.5);
}

export const generateRoughOptions = (
  element: ExcalidrawElement,
  continuousPath = false,
): Options => {
  const options: Options = {
    seed: element.seed,
    strokeLineDash:
      element.strokeStyle === "dashed"
        ? getDashArrayDashed(element.strokeWidth)
        : element.strokeStyle === "dotted"
          ? getDashArrayDotted(element.strokeWidth)
          : undefined,
    // for non-solid strokes, disable multiStroke because it tends to make
    // dashes/dots overlay each other
    disableMultiStroke: element.strokeStyle !== "solid",
    // for non-solid strokes, increase the width a bit to make it visually
    // similar to solid strokes, because we're also disabling multiStroke
    strokeWidth:
      element.strokeStyle !== "solid"
        ? element.strokeWidth + 0.5
        : element.strokeWidth,
    // when increasing strokeWidth, we must explicitly set fillWeight and
    // hachureGap because if not specified, roughjs uses strokeWidth to
    // calculate them (and we don't want the fills to be modified)
    fillWeight: element.strokeWidth / 2,
    hachureGap: element.strokeWidth * 4,
    roughness: adjustRoughness(element),
    stroke: element.strokeColor,
    preserveVertices:
      continuousPath || (element.roughness || 0) < ROUGHNESS.cartoonist,
  };

  switch (element.type) {
    case "job": // CHANGED:ADD 2022-11-18 #175
    case "milestone": // CHANGED:ADD 2022-12-7 #157
    case "rectangle":
    case "diamond":
    case "ellipse": {
      options.fillStyle = element.fillStyle;
      options.fill = isTransparent(element.backgroundColor)
        ? undefined
        : element.backgroundColor;
      if (element.type === "ellipse") {
        options.curveFitting = 1;
      }
      return options;
    }
    case "line":
    case "freedraw": {
      if (isPathALoop(element.points)) {
        options.fillStyle = element.fillStyle;
        options.fill =
          element.backgroundColor === "transparent"
            ? undefined
            : element.backgroundColor;
      }
      return options;
    }
    case "arrow":
      return options;
    case "task": // CHANGED:ADD 2022-10-28 #14
    case "link": // CHANGED:ADD 2022-11-2 #64
      return options;
    default: {
      throw new Error(`Unimplemented type ${element.type}`);
    }
  }
};

const getArrowheadShapes = (
  element: ExcalidrawLinearElement,
  shape: Drawable[],
  position: "start" | "end",
  arrowhead: Arrowhead,
  generator: RoughGenerator,
  options: Options,
  canvasBackgroundColor: string,
) => {
  const arrowheadPoints = getArrowheadPoints(
    element,
    shape,
    position,
    arrowhead,
  );

  if (arrowheadPoints === null) {
    return [];
  }

  switch (arrowhead) {
    case "dot": {
      const [x, y, diameter] = arrowheadPoints;

      // always use solid stroke for arrowhead
      delete options.strokeLineDash;

      return [
        generator.circle(x, y, diameter, {
          ...options,
          fill: element.strokeColor,
          fillStyle: "solid",
          stroke: element.strokeColor,
          roughness: Math.min(0.5, options.roughness || 0),
        }),
      ];
    }
    case "triangle": {
      const [x, y, x2, y2, x3, y3] = arrowheadPoints;

      // always use solid stroke for triangle arrowhead
      delete options.strokeLineDash;

      return [
        generator.polygon(
          [
            [x, y],
            [x2, y2],
            [x3, y3],
            [x, y],
          ],
          {
            ...options,
            fill: element.strokeColor,
            fillStyle: "solid",
            roughness: Math.min(1, options.roughness || 0),
          },
        ),
      ];
    }
    case "bar":
    case "arrow":
    default: {
      const [x2, y2, x3, y3, x4, y4] = arrowheadPoints;

      if (element.strokeStyle === "dotted") {
        // for dotted arrows caps, reduce gap to make it more legible
        const dash = getDashArrayDotted(element.strokeWidth - 1);
        options.strokeLineDash = [dash[0], dash[1] - 1];
      } else {
        // for solid/dashed, keep solid arrow cap
        delete options.strokeLineDash;
      }
      options.roughness = Math.min(1, options.roughness || 0);
      return [
        generator.line(x3, y3, x2, y2, options),
        generator.line(x4, y4, x2, y2, options),
      ];
    }
  }
};

/**
 * Generates the roughjs shape for given element.
 *
 * Low-level. Use `ShapeCache.generateElementShape` instead.
 *
 * @private
 */

/**
 * Generates the element's shape and puts it into the cache.
 * @param element
 * @param generator
 */
export const _generateElementShape = (
  element: Exclude<NonDeletedExcalidrawElement, ExcalidrawSelectionElement>,
  generator: RoughGenerator,
  {
    isExporting,
    canvasBackgroundColor,
    criticalPathModeEnabled,
    overdueTaskModeEnabled,
    criticalPathColor,
    gridSize,
    holidays,
  }: {
    isExporting: boolean;
    canvasBackgroundColor: string;
    criticalPathModeEnabled: boolean;
    overdueTaskModeEnabled: boolean;
    criticalPathColor: CriticalPathColor;
    gridSize: number | null;
    holidays: string[];
  },
): Drawable | Drawable[] | null => {
  switch (element.type) {
    case "job": // CHANGED:ADD 2022-11-18 #175
    case "rectangle": {
      let shape: ElementShapes[typeof element.type];
      // this is for rendering the stroke/bg of the embeddable, especially
      // when the src url is not set

      if (element.roundness) {
        const w = element.width;
        const h = element.height;
        const r = getCornerRadius(Math.min(w, h), element);
        shape = generator.path(
          `M ${r} 0 L ${w - r} 0 Q ${w} 0, ${w} ${r} L ${w} ${h - r
          } Q ${w} ${h}, ${w - r} ${h} L ${r} ${h} Q 0 ${h}, 0 ${h - r
          } L 0 ${r} Q 0 0, ${r} 0`,
          generateRoughOptions(element, true),
        );
      } else {
        shape = generator.rectangle(
          0,
          0,
          element.width,
          element.height,
          generateRoughOptions(element),
        );
      }
      return shape;
    }
    case "diamond": {
      let shape: ElementShapes[typeof element.type];

      const [topX, topY, rightX, rightY, bottomX, bottomY, leftX, leftY] =
        getDiamondPoints(element);
      if (element.roundness) {
        const verticalRadius = getCornerRadius(Math.abs(topX - leftX), element);

        const horizontalRadius = getCornerRadius(
          Math.abs(rightY - topY),
          element,
        );

        shape = generator.path(
          `M ${topX + verticalRadius} ${topY + horizontalRadius} L ${rightX - verticalRadius
          } ${rightY - horizontalRadius}
            C ${rightX} ${rightY}, ${rightX} ${rightY}, ${rightX - verticalRadius
          } ${rightY + horizontalRadius}
            L ${bottomX + verticalRadius} ${bottomY - horizontalRadius}
            C ${bottomX} ${bottomY}, ${bottomX} ${bottomY}, ${bottomX - verticalRadius
          } ${bottomY - horizontalRadius}
            L ${leftX + verticalRadius} ${leftY + horizontalRadius}
            C ${leftX} ${leftY}, ${leftX} ${leftY}, ${leftX + verticalRadius} ${leftY - horizontalRadius
          }
            L ${topX - verticalRadius} ${topY + horizontalRadius}
            C ${topX} ${topY}, ${topX} ${topY}, ${topX + verticalRadius} ${topY + horizontalRadius
          }`,
          generateRoughOptions(element, true),
        );
      } else {
        shape = generator.polygon(
          [
            [topX, topY],
            [rightX, rightY],
            [bottomX, bottomY],
            [leftX, leftY],
          ],
          generateRoughOptions(element),
        );
      }
      return shape;
    }
    case "ellipse": {
      const shape: ElementShapes[typeof element.type] = generator.ellipse(
        element.width / 2,
        element.height / 2,
        element.width,
        element.height,
        generateRoughOptions(element),
      );
      return shape;
    }
    case "line":
    case "arrow": {
      let shape: ElementShapes[typeof element.type];
      const options = generateRoughOptions(element);

      // points array can be empty in the beginning, so it is important to add
      // initial position to it
      const points = element.points.length ? element.points : [[0, 0]];

      // curve is always the first element
      // this simplifies finding the curve for an element
      if (!element.roundness) {
        if (options.fill) {
          shape = [generator.polygon(points as [number, number][], options)];
        } else {
          shape = [generator.linearPath(points as [number, number][], options)];
        }
      } else {
        shape = [generator.curve(points as [number, number][], options)];
      }

      // add lines only in arrow
      if (element.type === "arrow") {
        const { startArrowhead = null, endArrowhead = "arrow" } = element;

        if (startArrowhead !== null) {
          const shapes = getArrowheadShapes(
            element,
            shape,
            "start",
            startArrowhead,
            generator,
            options,
            canvasBackgroundColor,
          );
          shape.push(...shapes);
        }

        if (endArrowhead !== null) {
          if (endArrowhead === undefined) {
            // Hey, we have an old arrow here!
          }

          const shapes = getArrowheadShapes(
            element,
            shape,
            "end",
            endArrowhead,
            generator,
            options,
            canvasBackgroundColor,
          );
          shape.push(...shapes);
        }
      }
      return shape;
    }
    // CHANGED:ADD 2022-11-2 #64
    case "link": {
      let shape: ElementShapes[typeof element.type];
      const options = generateRoughOptions(element);
      options.strokeLineDash = [DASH_STROKE_LENGTH, DASH_STROKE_LENGTH];

      // CHANGED:ADD 2023-1-23 #455
      options.stroke = criticalPathModeEnabled && element.isCriticalPath
        ? criticalPathColor
        : element.strokeColor;

      // CHANGED:ADD 2023-02-27 #739
      options.strokeWidth = element.isClosed
        ? CLOSED_LINK_LINE_WIDTH
        : element.strokeWidth

      // points array can be empty in the beginning, so it is important to add
      // initial position to it
      const points = element.points.length ? element.points : [[0, 0]];

      shape = [
        generator.linearPath(points as [number, number][], options),
      ];

      return shape;
    }
    // CHANGED:ADD 2022-10-28 #14
    case "task": {
      let shape: ElementShapes[typeof element.type];
      const options = generateRoughOptions(element);

      // CHANGED:ADD 2023-1-23 #455
      options.stroke = getTaskStrokeColor(
        element,
        criticalPathModeEnabled,
        overdueTaskModeEnabled,
        criticalPathColor,
      );

      // CHANGED:ADD 2023-02-27 #739
      options.strokeWidth = element.isClosed
        ? CLOSED_TASK_LINE_WIDTH
        : criticalPathModeEnabled && element.isCriticalPath
          ? CLITICAL_PATH_TASK_LINE_WIDTH
          : element.strokeWidth;

      // points array can be empty in the beginning, so it is important to add
      // initial position to it
      const points = element.points.length ? element.points : [[0, 0]];

      // curve is always the first element
      // this simplifies finding the curve for an element
      if (!element.roundness) {
        if (options.fill) {
          shape = [generator.polygon(points as [number, number][], options)];
        } else {
          if (isTaskElement(element)) {
            shape = strokeTaskLine(
              element,
              generator,
              {
                criticalPathModeEnabled,
                overdueTaskModeEnabled,
                criticalPathColor,
                gridSize,
                holidays,
              },
            );
          } else {
            shape = [
              generator.linearPath(points as [number, number][], options),
            ];
          }
        }
      } else {
        shape = [generator.curve(points as [number, number][], options)];
      }

      // add lines only in arrow
      const { startArrowhead = null, endArrowhead = "dot" } = element;

      // CHANGED:UPDATE 2022-12-12 #290
      const getArrowheadShapesEx = (
        element: ExcalidrawTaskElement,
        shape: Drawable[],
        position: "start" | "end",
        arrowhead: ArrowheadEx,
        criticalPathModeEnabled: boolean,
      ) => {
        const arrowheadPoints = getArrowheadExPoints(
          element,
          shape,
          position,
          arrowhead,
          criticalPathModeEnabled,
        );

        if (arrowheadPoints === null) {
          return [];
        }

        // Other arrowheads here...
        if (arrowhead === "dot" || arrowhead === "dot_small") {
          const [x, y, r] = arrowheadPoints;

          return [
            generator.circle(x, y, r, {
              ...options,
              fill: getTaskStrokeColor(
                element,
                criticalPathModeEnabled,
                overdueTaskModeEnabled,
                criticalPathColor,
              ), // CHANGED:UPDATE 2023-1-23 #455
              fillStyle: "solid",
              stroke: "none",
            }),
          ];
        }

        // CHANGED:ADD 2024-04-16 #1934
        if (arrowhead === "circle") {
          const [x, y, r] = arrowheadPoints;

          return [
            generator.circle(x, y, r, {
              ...options,
              fill: "#FFF",
              fillStyle: "solid",
              strokeWidth: 2,
            }),
          ];
        }

        // Arrow arrowheads
        const [x2, y2, x3, y3, x4, y4] = arrowheadPoints;

        if (element.strokeStyle === "dotted") {
          // for dotted arrows caps, reduce gap to make it more legible
          const dash = getDashArrayDotted(element.strokeWidth - 1);
          options.strokeLineDash = [dash[0], dash[1] - 1];
        } else {
          // for solid/dashed, keep solid arrow cap
          delete options.strokeLineDash;
        }
        return [
          generator.line(x3, y3, x2, y2, options),
          generator.line(x4, y4, x2, y2, options),
        ];
      };

      if (startArrowhead !== null) {
        if (startArrowhead === undefined) {
          // Hey, we have an old arrow here!
        }

        const shapes = getArrowheadShapesEx(
          element,
          shape,
          "start",
          startArrowhead,
          criticalPathModeEnabled,
        );
        shape.push(...shapes);
      }

      if (endArrowhead !== null) {
        if (endArrowhead === undefined) {
          // Hey, we have an old arrow here!
        }

        const shapes = getArrowheadShapesEx(
          element,
          shape,
          "end",
          endArrowhead,
          criticalPathModeEnabled,
        );
        shape.push(...shapes);
      }

      return shape;
    }
    case "freedraw": {
      let shape: ElementShapes[typeof element.type];
      generateFreeDrawShape(element);

      if (isPathALoop(element.points)) {
        // generate rough polygon to fill freedraw shape
        const simplifiedPoints = simplify(element.points, 0.75);
        shape = generator.curve(simplifiedPoints as [number, number][], {
          ...generateRoughOptions(element),
          stroke: "none",
        });
      } else {
        shape = null;
      }
      return shape;
    }
    case "comment": //CHANGED:ADD 2024-02-25
    case "milestone": // CHANGED:UPDATE 2023/08/25 #932
    case "text":
    case "job-text": // CHANGED: ADD 2023-01-28 #391
    case "image": {
      const shape: ElementShapes[typeof element.type] = null;
      // we return (and cache) `null` to make sure we don't regenerate
      // `element.canvas` on rerenders
      return shape;
    }
    default: {
      assertNever(
        element,
        `generateElementShape(): Unimplemented type ${(element as any)?.type}`,
      );
      return null;
    }
  }
};

const strokeTaskLine = (
  element: ExcalidrawTaskElement,
  generator: RoughGenerator,
  {
    criticalPathModeEnabled,
    overdueTaskModeEnabled,
    criticalPathColor,
    gridSize,
    holidays,
  }: {
    criticalPathModeEnabled: boolean;
    overdueTaskModeEnabled: boolean;
    criticalPathColor: CriticalPathColor;
    gridSize: number | null;
    holidays: string[];
  },
) => {
  const { startDate, endDate, strokeWidth, strokeColor, isCriticalPath } =
    element;
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffDay = (end.getTime() - start.getTime()) / 86400000;
  const duration = element.duration;
  const w = gridSize ? gridSize : 0;
  const h = element.isClosed
    ? CLOSED_TASK_LINE_WIDTH
    : criticalPathModeEnabled && element.isCriticalPath
      ? CLITICAL_PATH_TASK_LINE_WIDTH
      : strokeWidth;

  if (diffDay != duration) {
    const _holidays = (element.holidays ? element.holidays : holidays)
      ?.filter((date) => {
        const _date = new Date(date);
        return start <= _date && _date < end ? true : false;
      })
      .map((date) => new Date(date));
    const shapes = [];
    for (let i = 0; i < diffDay; i++) {
      const targetDate = new Date(start);
      targetDate.setDate(targetDate.getDate() + i);
      const lineStyleAlpha = _holidays.some((hd) => {
        return (
          hd.getFullYear() == targetDate.getFullYear() &&
          hd.getMonth() == targetDate.getMonth() &&
          hd.getDate() == targetDate.getDate()
        );
      })
        ? "40"
        : "";
      const lineStyle = `${getTaskStrokeColor(element, criticalPathModeEnabled, overdueTaskModeEnabled, criticalPathColor)
        }${lineStyleAlpha}`;
      const line = generator.line(w * i, 0, w * i + w, 0, {
        roughness: 0,
        strokeWidth: h,
        stroke: lineStyle,
      });
      shapes.push(line);
    }
    return shapes;
  }
  return [
    generator.line(0, 0, element.width, 0, {
      roughness: 0,
      strokeWidth: h,
      stroke: getTaskStrokeColor(element, criticalPathModeEnabled, overdueTaskModeEnabled, criticalPathColor)
    }),
  ];
};

export const getTaskStrokeColor = (
  element: ExcalidrawTaskElement,
  criticalPathModeEnabled: boolean,
  overdueTaskModeEnabled: boolean,
  criticalPathColor: CriticalPathColor,
): string => {
  if (criticalPathModeEnabled && element.isCriticalPath) 
    return criticalPathColor;

  if (
    overdueTaskModeEnabled &&
    !element.isClosed &&
    element.endDate.valueOf() < dayjs().endOf("day").valueOf()
  )
    return ColorsEx.lineColor.overdue;
    
  return element.strokeColor;
}