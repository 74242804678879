import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";

import { useStore } from "src/conpath/hooks/useStore";

import { ExcalidrawCommentElement, ExcalidrawCommentableElement } from "../element/types";
import { UpdateDataType } from "./EditTaskElement";

import ProjectModel from "src/conpath/models/ProjectModel";
import { AppState } from "src/excalidraw/types";
import CommentList from "src/conpath/components/sidebar/CommentList";

interface Props {
  appState: AppState,
  commentElement?: ExcalidrawCommentElement,
  commentableElement: ExcalidrawCommentableElement;
  updateData: ({
    type,
    commentableElement,
    commentId,
  }: {
    type: UpdateDataType,
    commentableElement: ExcalidrawCommentableElement,
    commentId?: string,
  }) => void;
}
const CommentInTaskEditDialog: React.FC<Props> = (
  {
    appState,
    commentElement,
    commentableElement,
    updateData
  }
) => {
  const setAppState = useExcalidrawSetAppState();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [project, setProject] = useState<ProjectModel|null>(null);

  useEffect(() => {
    const getComments = async () => {
      if (selectedOrganization) {
        const selectedProject = selectedOrganization.
        projects.find((project) => project.id === appState.projectId);
        // コメントの取得
        await selectedProject?.getCommentsByElementId(commentableElement.id, selectedOrganization.users);
        setProject(selectedProject!);
      }
    }
    
    getComments();

    return () => {
      if (project) project.clearComments();
    }
  }, []);

  if (!loginUser || !selectedOrganization || !project) return null;

  return (
    <CommentList
      isCanvas={true}
      commentElementId={commentElement?.id}
      commentableElementId={commentableElement.id}
      project={project}
      loginUser={loginUser}
      selectedOrganization={selectedOrganization}
      setErrorText={(error) => {
        setAppState({ errorMessage: error });
      }}
      updateCallback={(commentId: string) => {
        updateData({
          type: UpdateDataType.CREATE_COMMENT_ELEMENT,
          commentableElement: commentableElement,
          commentId: commentId,
        });
      }}
      deleteCallback={() => {
        updateData({
          type: UpdateDataType.DELETE_COMMENT_ELEMENT,
          commentableElement: commentableElement,
        });
      }}
    />
  )
};

export default observer(CommentInTaskEditDialog);