import React, { useEffect, useMemo } from 'react';
import { observer } from "mobx-react-lite";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import "./App.scss";
//hooks
import { useStore } from 'src/conpath/hooks/useStore';

//models
import BaseUserModel from 'src/conpath/models/LoginUserModel';

import { Paths } from 'src/conpath/constants/Routes';
import { OrganizationRole } from 'src/conpath/constants/Role';

//components
import ConPathWrapper from 'src/conpath/components/layouts/ConPathWrapper';

//views: dashboard
import Dashboard from 'src/conpath/views/dashboard/Dashboard';
//views: auth
import Login from 'src/conpath/views/auth/Login';
import SignUp from 'src/conpath/views/auth/SignUp';
import SignUpOrganization from 'src/conpath/views/auth/SignUpOrganization';
import ResetPassword from 'src/conpath/views/auth/ResetPassword';
import Invitation from 'src/conpath/views/auth/Invitation';
import InvalidInvitation from 'src/conpath/views/auth/InvalidInvitation';
import InvitationAccept from 'src/conpath/views/auth/InvitationAccept';
//views: projects
import Projects from 'src/conpath/views/projects/Projects';
import Project from 'src/conpath/views/projects/Project';
import EditProject from './conpath/views/projects/EditProject';
import CreateProject from 'src/conpath/views/projects/CreateProject';
import DuplicateProject from 'src/conpath/views/projects/DuplicateProject';
//views: resources
import Resources from 'src/conpath/views/resources/Resources';
//views: roadmap
import Roadmap from 'src/conpath/views/roadmap/Roadmap';
//views: settings
import GeneralSettings from 'src/conpath/views/settings/GeneralSettings';
import UsersSettings from 'src/conpath/views/settings/UsersSettings';
import TeamsSettings from 'src/conpath/views/settings/TeamsSettings';
import CreateTeam from 'src/conpath/views/settings/CreateTeam';
import EditTeam from 'src/conpath/views/settings/EditTeam';
import ResourcesSettings from 'src/conpath/views/settings/ResourcesSettings';
import BillingSettings from 'src/conpath/views/settings/BillingSettings';
import ProfileSettings from 'src/conpath/views/settings/ProfileSettings';
import AccountSettings from 'src/conpath/views/settings/AccountSettings';

//views: other
import Page404 from './conpath/views/Page404';
import { APP_NAME } from './excalidraw/constants';

const App: React.FC = observer(() => {

  const { userStore } = useStore();
  const { loginUser } = userStore;

  useEffect(() => {
    const check = async () => {
      if (userStore) {
        await userStore.checkAlreadySignedIn();
      }
    };

    check();
  }, [userStore]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute user={loginUser} />}>
          <Route path={Paths.dashboard} element={<Dashboard />} />
          <Route path={Paths.projects}>
            <Route index={true} element={<Projects />} />
            <Route path={Paths.create.slice(1)} element={<CreateProject />} />
            <Route path={":projectId"}>
              <Route index={true} element={<Project />} />
              <Route path={Paths.edit.slice(1)} element={<EditProject />} />
              <Route path={Paths.duplicate.slice(1)} element={<DuplicateProject />} />
            </Route>
          </Route>

          <Route path={Paths.resources} element={<RouteAuthGuard component={<Resources />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
          <Route path={Paths.roadmap} element={<RouteAuthGuard component={<Roadmap />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />

          <Route path={Paths.settings}>
            <Route index={true} element={<GeneralSettings />} />
            <Route path={Paths.users.slice(1)} element={<RouteAuthGuard component={<UsersSettings />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
            <Route path={Paths.teams.slice(1)}>
              <Route index={true} element={<RouteAuthGuard component={<TeamsSettings />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
              <Route path={Paths.create.slice(1)} element={<RouteAuthGuard component={<CreateTeam />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
              <Route path={":teamId"}>
                <Route path={Paths.edit.slice(1)} element={<RouteAuthGuard component={<EditTeam />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
              </Route>
            </Route>
            <Route path={Paths.resources.slice(1)} element={<RouteAuthGuard component={<ResourcesSettings />} user={loginUser} allowRoles={[OrganizationRole.owner, OrganizationRole.member]} />} />
            <Route path={Paths.billings.slice(1)} element={<BillingSettings />} />
            <Route path={Paths.profile.slice(1)} element={<ProfileSettings />} />
            <Route path={Paths.account.slice(1)} element={<AccountSettings />} />
          </Route>

          <Route path='/*' element={<Page404 />} />
        </Route>

        <Route element={<AuthRoutes user={loginUser} />}>
          <Route path={Paths.login} element={<Login />} />
          <Route path={Paths.signup} element={<SignUp />} />
          <Route path={Paths.signupOrganization} element={<SignUpOrganization />} />
          <Route path={Paths.resetPassword} element={<ResetPassword />} />
          <Route path={Paths.invite}>
            <Route index={true} element={<Invitation />} />
            <Route path={Paths.accept.slice(1)} element={<InvitationAccept />} />
            <Route path={Paths.invalid.slice(1)} element={<InvalidInvitation />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
});

const AuthRoutes: React.FC<{ user: BaseUserModel | null }> = ({
  user,
}: {
  user: BaseUserModel | null;
}) => {
  document.title = APP_NAME;

  const isInvite = useMemo(() => {
    return window.location.pathname === Paths.invite || 
            window.location.pathname === Paths.invite + Paths.accept;
  }, [window.location.pathname]);

  if (user && !isInvite) {
    return <Navigate to={Paths.dashboard} replace />;
  }
  return <Outlet />;
};

const PrivateRoute: React.FC<{ user: BaseUserModel | null }> = ({
  user,
}: {
  user: BaseUserModel | null;
}) => {
  if (!user) {
    return <Navigate to={Paths.login} replace />;
  }

  return (
    <>
      <ConPathWrapper>
        <Outlet />
      </ConPathWrapper>
    </>
  );
};

export const RouteAuthGuard: React.FC<{
  component: React.ReactNode;
  user: BaseUserModel | null;
  redirect?: string;
  allowRoles?: OrganizationRole[];
}> = ({
  component,
  user,
  redirect,
  allowRoles,
}: {
  component: React.ReactNode,
  user: BaseUserModel | null;
  redirect?: string;
  allowRoles?: OrganizationRole[];
}) => {
  let allowRoute = false;
  if (user && user.organizationRole) {
    allowRoute = allowRoles ? allowRoles.includes(user.organizationRole) : true;
  }

  if (!allowRoute) {
    if (redirect) {
      return <Navigate to={redirect} replace={false} />;
    }
    return <Page404 />;
  }

  return <>{component}</>;
};

export default App;
