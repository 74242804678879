import {
  CANVAS_MARGIN_BOTTOM,
  CANVAS_MARGIN_RIGHT as CANVAS_MARGIN_RIGHT,
  JOB_ELEMENTS_WIDTH,
  MIN_ZOOM
} from "../constants";
import { AppState, NormalizedZoomValue } from "../types";
// CHANGED:ADD 2022-12-5 #177
import Scroll from "../extensions/scene/scroll"; // from extensions

export const getNormalizedZoom = (zoom: number): NormalizedZoomValue => {
  return Math.max(MIN_ZOOM, Math.min(zoom, 30)) as NormalizedZoomValue;
};

export const getStateForZoom = (
  {
    viewportX,
    viewportY,
    nextZoom,
  }: {
    viewportX: number;
    viewportY: number;
    nextZoom: NormalizedZoomValue;
  },
  appState: AppState,
) => {
  const appLayerX = viewportX - appState.offsetLeft;
  const appLayerY = viewportY - appState.offsetTop;

  const currentZoom = appState.zoom.value;

  // get original scroll position without zoom
  const baseScrollX = appState.scrollX + (appLayerX - appLayerX / currentZoom);
  const baseScrollY = appState.scrollY + (appLayerY - appLayerY / currentZoom);

  // get scroll offsets for target zoom level
  const zoomOffsetScrollX = -(appLayerX - appLayerX / nextZoom);
  const zoomOffsetScrollY = -(appLayerY - appLayerY / nextZoom);

  // CHANGED:ADD 2022-12-5 #177
  const scroll = new Scroll({
    limitX:
      appState.calendarWidth -
      ((window.innerWidth - CANVAS_MARGIN_RIGHT) / nextZoom - JOB_ELEMENTS_WIDTH),
    limitY:
      appState.jobsHeight -
      (window.innerHeight - CANVAS_MARGIN_BOTTOM) / nextZoom,
  });

  return {
    // CHANGED:UPDATE 2022-12-5 #177
    // scrollX: baseScrollX + zoomOffsetScrollX,
    // scrollY: baseScrollY + zoomOffsetScrollY,
    scrollX: scroll.getOffsetScrollX(baseScrollX + zoomOffsetScrollX),
    scrollY: scroll.getOffsetScrollY(baseScrollY + zoomOffsetScrollY),
    zoom: {
      value: nextZoom,
    },
  };
};
