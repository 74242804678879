import { ExcalidrawElement } from "src/excalidraw/element/types";
import { ExcalidrawMilestoneElement } from "./element/types";
import { isMilestoneElement } from "./element/typeChecks";

class Milestone {
  static getMilestoneElements(
    elements: readonly ExcalidrawElement[],
  ): ExcalidrawMilestoneElement[] {
    return elements.filter(
      (element) => !element.isDeleted && isMilestoneElement(element),
    ) as ExcalidrawMilestoneElement[];
  }
}

export default Milestone;
