import React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme: "primary" | "secondary" | "main" | "info" | "solid" | "cancel";
}

export const Button = React.memo((props: Props) => {
  const { theme, ...rest } = props;

  if (theme === "primary") {
    return <button className="button primary" {...rest} />;
  } else if (theme === "secondary") {
    return <button className="button secondary" {...rest} />;
  } else if (theme === "info") {
    return <button className="button info" {...rest} />;
  } else if (theme === "main") {
    return <button className="button main" {...rest} />;
  } else if (theme === "solid") {
    return <button className="button solid" {...rest} />;
  }
  // cancel
  return <button className="button cancel" {...rest} />;
});
