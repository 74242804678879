import { find, filter, orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import Context from '../../context';
import { DefaultRecordType, Gantt } from '../../types';
import './Dependence.scss';
import dayjs from 'dayjs';

const spaceX = 0;
const spaceY = 0;

interface DependenceProps {
  data: Gantt.Dependence;
}
interface Point {
  x: number;
  y: number;
}
/**
 * ポイントへのアクセス
 *
 * @param from
 * @param to
 */
function getPoints(from: Point, to: Point, type: Gantt.DependenceType) {
  const { x: fromX, y: fromY } = from;
  const { x: toX, y: toY } = to;
  const sameSide = type === 'finish_finish' || type === 'start_start'
  // 同じ方向、必要なポイントは2つ
  if (sameSide) {
    if (type === 'start_start') {
      return [
        { x: Math.min(fromX - spaceX, toX - spaceX), y: fromY },
        { x: Math.min(fromX - spaceX, toX - spaceX), y: toY },
      ];
    }
    return [
      { x: Math.max(fromX + spaceX, toX + spaceX), y: fromY },
      { x: Math.max(fromX + spaceX, toX + spaceX), y: toY },
    ];
  }
  // 異なる方向において、4つのキーポイントが必要

  return [
    { x: type === 'finish_start' ? fromX + spaceX : fromX - spaceX, y: fromY },
    {
      x: type === 'finish_start' ? fromX + spaceX : fromX - spaceX,
      y: toY - spaceY,
    },
    {
      x: type === 'finish_start' ? toX - spaceX : toX + spaceX,
      y: toY - spaceY,
    },
    { x: type === 'finish_start' ? toX - spaceX : toX + spaceX, y: toY },
  ];
}
const Dependence: React.FC<DependenceProps> = ({ data }) => {
  const { store, barHeight } = useContext(Context);
  const { from, to, type, color = '#A2D4EF', duration = 0 } = data;
  const barList = store.getBarList;
  const fromBar = find(barList, (bar) => bar.record.id === from);
  const [showBubble, setShowBubble] = useState(false);

  const handleMouseEnter = () => {
    setShowBubble(true);
  };

  const handleMouseLeave = () => {
    setShowBubble(false);
  };

  if (!fromBar) return null;

  const toBars = filter(barList, (bar) => bar.record.id === to);
  let toBar: Gantt.Bar<DefaultRecordType> | undefined;
  
  // If connecting to milestone
  if (type === 'float') {
    toBar = orderBy(toBars, [function(bar) { 
      const diff = fromBar.translateY - bar.translateY;
      if (diff < 0) {
        return Infinity;
      }
      return fromBar.translateY - bar.translateY;
    }], ["desc"]).pop();
  } else {
    toBar = toBars.pop();
  }

  if (!toBar) return null;

  const posY = barHeight / 4;
  const [start, end] = (() => [
    {
      x: type === 'finish_finish' || type === 'finish_start' || type === 'float' || type === 'gap'
        ? fromBar.translateX + fromBar.width
        : type === 'overlap'
          ? toBar.translateX
          : fromBar.translateX,
      y: fromBar.translateY + posY,
    },
    {
      x: type === 'finish_finish' || type === 'start_finish' || type === 'float'
        ? toBar.translateX + toBar.width
        : type === 'overlap'
          ? Math.min(fromBar.translateX + fromBar.width, toBar.translateX + toBar.width)
          : toBar.translateX,
      y: toBar.translateY + posY,
    },
  ])();

  const points = [...getPoints(start, end, type), end];

  // Size of the circle
  const circleRadius = 5; // You can adjust the radius as needed
  const circleStrokeWidth = 2; // Adjust the thickness of the donut ring

  if (type === 'float') {
    // 余裕日数の表示
    const x = start.x;
    const y = end.y + 10.6;
    const x2 = end.x;
    const y2 = start.y + 5.3;
    const width = end.x - start.x;
    const height = start.y - end.y - 5.3;

    const remainDateTextY = y2 - height / 2;
    const remainDateTextX = x2 + 10;

    const diff = duration.toFixed(0);

    return (
      <g className={"task-dependency-line"}>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={color}
          fillOpacity={0.2}
        />
        {/* left line */}
        <polyline
          fill={color}
          fillOpacity={0.2}
          stroke={color}
          points={`${x},${y} ${x},${y2}`} />
        {/* right line */}
        <polyline
          fill={color}
          fillOpacity={0.2}
          stroke={color}
          points={`${x2},${y} ${x2},${y2}`} />
        <polyline
          fill={color}
          fillOpacity={0.2}
          stroke={color}
          points={`${x2},${remainDateTextY} ${remainDateTextX},${remainDateTextY}`} />
        <rect
          fill="white"
          stroke={color}
          x={remainDateTextX}
          y={remainDateTextY - 10}
          width={80 + Number(diff.length) * 8}
          height={20}
          rx={10}
          ry={10}
        />
        <text
          x={remainDateTextX + 10}
          y={remainDateTextY + 4}
          fill={color}
          fontSize={12}
          className="small">
          {`余裕日数 (${diff})`}
        </text>
      </g>
    );
  } else if (type === "overlap" || type === "gap") {
    // 重複、余白の表示
    const x = start.x + 1;
    const y = type === "overlap" ? start.y + 3.5 : start.y - 5;
    const x2 = end.x - 1;
    const y2 = type === "overlap" ? end.y - 5 : end.y + 3.5;
    const width = x2 - x;
    const height = y2 - y;

    const endDate = (fromBar.translateX + fromBar.width < toBar.translateX + toBar.width)
      ? fromBar.endDate
      : toBar.endDate;

    const diffCoefficient = type === "overlap" ? -1 : 1;
    const diff = (dayjs(toBar.startDate).diff(endDate, "dates") / 1000 / 60 / 60 / 24 * diffCoefficient - diffCoefficient).toFixed(0);
    const diffWidth = (20 + Number(diff.length) * 5) ^ 2;
    const diffHeight = 20;

    const remainDateTextX = x2 - width / 2 - diffWidth / 2;
    const remainDateTextY = y2 - height / 2 - diffHeight / 2;
    // const circleRadius = 10;
    const barHeight = 8;

    return (
        <g 
          className={"task-dependency-line"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill={color}
            fillOpacity={0.2}
          />
          {/* left line */}
          <polyline
            fill={color}
            fillOpacity={0.2}
            stroke={color}
            points={`${x},${y} ${x},${y2}`}
          />
          {/* right line */}
          <polyline
            fill={color}
            fillOpacity={0.2}
            stroke={color}
            points={`${x2},${y} ${x2},${y2}`}
          />
          <rect
            fill="white"
            stroke={color}
            x={remainDateTextX}
            y={remainDateTextY}
            width={diffWidth}
            height={diffHeight}
            rx={10}
            ry={10}
          />
          <text
            x={remainDateTextX + diffWidth / 2}
            y={remainDateTextY + diffHeight / 2 + 1}
            width={diffWidth}
            height={diffHeight}
            textAnchor={"middle"}
            dominantBaseline={"middle"}
            fill={color}
            fontSize={12}
            className="small"
          >
            {`${diff}`}
          </text>
          {showBubble && (
            <>
            <svg
              x={x - 105 + (width / 2)}
              y={type === "overlap" ? y - height : y - barHeight - (height / 2)}
              xmlns="http://www.w3.org/2000/svg" width="133" height="42.034" viewBox="0 0 133 42.034"
            >
              <rect width="133" height="31" rx="4" fill={color}/>
              <g transform="translate(103 31)">
                <g transform="translate(0)">
                  <path d="M13.09,0,1.382,10.824C.793,11.369,0,10.79,0,9.813V0Z" fill={color}/>
                </g>
              </g>
              <text
                x={66.5}
                y={15.5}
                width={diffWidth}
                height={diffHeight}
                textAnchor={"middle"}
                dominantBaseline={"middle"}
                fill={"white"}
                fontSize={13}
                className="small"
              >
                {type === "overlap" ? `重複タスクがあります` : "余白があります"}
              </text>
            </svg>
            </>
          )}
          {/* ovarrap From-bar */}
          {type === "overlap" && ( 
            <>
              <rect
                x={x}
                y={y - barHeight}
                width={width}
                height={barHeight}
                fill={color}
                stroke={color}
              />
              {/* <rect
                x={x2 - circleRadius}
                y={y - circleRadius - barHeight / 2}
                width={circleRadius * 2}
                height={circleRadius * 2}
                fill={color}
                stroke={color}
                rx={circleRadius}
                ry={circleRadius}
              />
              <BsExclamationLg
              fill="white"
              x={x2 - 8}
              y={y - 11}
              /> */}
            </>
          )}
          {/* ovarrap To-bar */}
          {type === "overlap" && (
            <>
              <rect
                x={x}
                y={y2}
                width={width}
                height={8}
                fill={color}
                stroke={color}
              />
              {/* <rect
                x={x2 - circleRadius}
                y={y2 - (circleRadius - barHeight) - barHeight / 2}
                width={circleRadius * 2}
                height={circleRadius * 2}
                fill={color}
                stroke={color}
                rx={circleRadius}
                ry={circleRadius}
              />
              <BsExclamationLg
                fill="white"
                x={x2 - 8}
                y={y2 - 3}
              /> */}
            </>
          )}
        </g>
    );
  }

  return (
    <g stroke={color} className={"task-dependency-line"}>
      {/* Line Path */}
      <path
        style={{ stroke: color }}
        d={`
          M${start.x},${start.y}
          ${points.map(point => `L${point.x},${point.y}`).join('\n')}
          L${end.x},${end.y}
          `}
        strokeWidth='2'
        fill='none'
      />
      {/* Start Donut */}
      <circle
        cx={start.x}
        cy={start.y}
        r={circleRadius}
        fill='#FFFFFF'
        stroke={color}
        strokeWidth={circleStrokeWidth}
      />
      {/* End Donut */}
      <circle
        cx={end.x}
        cy={end.y}
        r={circleRadius}
        fill='#FFFFFF'
        stroke={color}
        strokeWidth={circleStrokeWidth}
      />
    </g>
  );
};

export default observer(Dependence);
