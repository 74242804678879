import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import "./SelectMultipleTaskAssignResources.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import ProjectModel from "src/conpath/models/ProjectModel";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck, FaMinus } from "react-icons/fa";
import { TbPinnedFilled } from "react-icons/tb";
import { t } from "src/excalidraw/i18n";

interface SelectingResource {
  id: string;
  name: string;
  profileImageUrl: string | null;
}
const hasResourceCheck = ({
  list,
  targetResource,
}: {
  list: SelectingResource[];
  targetResource: SelectingResource;
}): boolean => {
  return list.some((resource) => resource.id === targetResource.id);
};

const SelectMultipleTaskAssignResources = observer(
  (props: {
    tasks: TaskModel[];
    organization: OrganizationModel | null;
    project: ProjectModel | null;
    onSaveAssignResources: () => void;
  }) => {
    const { tasks, organization, project, onSaveAssignResources } = props;

    const [assignedResources, setAssignedResources] = useState<SelectingResource[]>([]);
    const [indeterminateResources, setIndeterminateResources] = useState<SelectingResource[]>([]);
    const [resources, setResources] = useState<SelectingResource[]>([]);
    const [projectResourceIds, setProjectResourceIds] = useState<string[]>([]);

    // 全リソース
    useEffect(() => {
      const resources = organization?.getNonDeletedResources()
        .filter((resource) => resource.isActive)
        .map((resource) => {
          return {
            id: resource.id,
            // email: resource.email,
            name: resource.name,
            profileImageUrl: resource.iconImageUrl,
          } as SelectingResource;
        }) || [];

      setResources(resources);

      setProjectResourceIds(Object.keys(project?.resources || {}));
    }, [organization, project]);

    // すでにアサインされたリソース
    useEffect(() => {
      const isAssigned = (resource: SelectingResource): boolean => {
        return tasks.every((task) =>
          task.assignResourceIds.some((u) => u === resource.id)
        )
      };
      setAssignedResources(resources.filter((resource) => isAssigned(resource)));

      const isIndeterminate = (resource: SelectingResource): boolean => {
        return tasks.some((task) =>
          task.assignResourceIds.some((u) => u === resource.id)
        )
      };
      setIndeterminateResources(resources.filter((resource) => isIndeterminate(resource)));
    }, [resources, tasks]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionResourceList
            resources={resources}
            assignedResources={assignedResources}
            indeterminateResources={indeterminateResources}
            projectResourceIds={projectResourceIds}
            tasks={tasks}
            onSaveAssignResources={onSaveAssignResources}
          />
        </div>
      </>
    );
  },
);

const SelectionResourceList = (props: {
  tasks: TaskModel[];
  resources: SelectingResource[];
  assignedResources: SelectingResource[];
  indeterminateResources: SelectingResource[];
  projectResourceIds: string[];
  onSaveAssignResources: () => void;
}) => {
  const { resources, assignedResources, indeterminateResources, projectResourceIds, tasks, onSaveAssignResources } = props;
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);

  // セレクトスクロール量 設定 
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  useEffect(() => {
    const tableWrapper = document.querySelector('.table-wrapper') as HTMLElement;

    if (tableWrapper) {
      setScrollHeight(tableWrapper.offsetHeight - 30);
    }
  }, []);


  const List = React.useMemo(() => {
    const onSave = (targetResource: SelectingResource) => {
      const hasResource = hasResourceCheck({ list: assignedResources, targetResource });
      tasks.forEach((task) => {
        if (!hasResource) {
          task.addResource(targetResource.id);
        } else {
          task.removeResource(targetResource.id);
        }
      });
    }

    const onSelectResourceButtonPressed = () => {
      setIsOpenAssignList(!isOpenAssignList);
    };

    if (tasks.length === 0) {
      return (
        <label className="task-content__label !mb-0">
          {t("labels.editTask.assignResource")}
        </label>
      )
    }

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSelectResourceButtonPressed();
            onSaveAssignResources();
          }
          return (
            <>
              <Menu.Button
                className="flex items-center group/assign-button cursor-pointer"
                as="div"
                onClick={() => {
                  onSelectResourceButtonPressed();
                }}
              >
                <label className="task-content__label !mb-0 cursor-pointer group-hover/assign-button:text-primary-color">
                  {t("labels.editTask.assignResource")}
                </label>
                <GoGear
                  className="ml-2 cursor-pointer text-black group-hover/assign-button:text-primary-color"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items style={{ maxHeight: scrollHeight, overflow: "scroll" }} className="z-[1] absolute right-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {/* Assigned resources */}
                  {resources.length > 0 && (
                    <>
                      <div className="p-2 text-xs">
                        {t("labels.editTask.assignResource")}
                      </div>
                      {resources
                        .sort((a, b) => {
                          if (projectResourceIds.includes(a.id) && !projectResourceIds.includes(b.id)) {
                            return -1;
                          } else if (!projectResourceIds.includes(a.id) && projectResourceIds.includes(b.id)) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((resource) => {
                          const isAssigned = hasResourceCheck({
                            list: assignedResources,
                            targetResource: resource,
                          });
                          const isIndeterminate = hasResourceCheck({
                            list: indeterminateResources,
                            targetResource: resource,
                          });
                          const isProjectResource = projectResourceIds.includes(resource.id);
                          return (
                            <div
                              className="cursor-pointer p-2 group/select-resource flex items-center hover:bg-primary-color transition ease-out duration-100"
                              onClick={() => onSave(resource)}
                            >
                              <TbPinnedFilled
                                className={`${isProjectResource
                                  ? "text-black group-hover/select-resource:text-white"
                                  : "text-transparent"
                                  } mr-1`}
                              />
                              {isAssigned
                                ? <FaCheck
                                  className={"text-black group-hover/select-resource:text-white mr-2"}
                                />
                                : <FaMinus
                                  className={`${isIndeterminate
                                    ? "text-black group-hover/select-resource:text-white"
                                    : "text-transparent"
                                    } mr-2`}
                                />
                              }
                              {resource.profileImageUrl ? (
                                <img
                                  src={resource.profileImageUrl}
                                  className="w-5 rounded-full object-contain mr-2"
                                />
                              ) : (
                                <div className="w-5 h-5 bg-[var(--color-resource)]  text-white text-center leading-5 rounded-full mr-2">
                                  <p className="text-[12px]">{resource.name.charAt(0)}</p>
                                </div>
                              )}
                              <span className="group-hover/select-resource:text-white">
                                {resource.name}
                              </span>
                            </div>
                          );
                        })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    tasks,
    resources,
    assignedResources,
    indeterminateResources,
    projectResourceIds,
  ]);

  return List;
};

export default SelectMultipleTaskAssignResources;
