import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import "./SelectAssignResources.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import ProjectModel from "src/conpath/models/ProjectModel";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck } from "react-icons/fa";
import { TbPinnedFilled } from "react-icons/tb";
import { Tags } from "src/conpath/interfaces/Tag";

interface SelectingResource {
  id: string;
  name: string;
  tags: Tags;
  memo: string;
  profileImageUrl: string | null;
}
const hasResourceCheck = ({
  list,
  targetResource,
}: {
  list: SelectingResource[];
  targetResource: SelectingResource;
}): boolean => {
  return list.some((resource) => resource.id === targetResource.id);
};

const toggeleResources = ({
  list,
  targetResource,
}: {
  list: SelectingResource[];
  targetResource: SelectingResource;
}): SelectingResource[] => {
  if (hasResourceCheck({ list, targetResource })) {
    return list.filter((resource) => resource.id !== targetResource.id);
  }
  return [...list, targetResource];
};

const SelectAssignResources = observer(
  (props: {
    taskModel: TaskModel;
    organization: OrganizationModel | null;
    project: ProjectModel | null;
    onChangeAssignResources: () => void;
  }) => {
    const { taskModel, organization, project, onChangeAssignResources } = props;

    const [assignedResources, setAssignedResources] = useState<SelectingResource[]>([]);
    const [resources, setResources] = useState<SelectingResource[]>([]);
    const [projectResourceIds, setProjectResourceIds] = useState<string[]>([]);

    // 全リソース
    useEffect(() => {
      const resources = organization?.getNonDeletedResources()
        .filter((resource) => resource.isActive)
        .map((resource) => {
          return {
            id: resource.id,
            name: resource.name,
            tags: resource.tags,
            memo: resource.memo,
            profileImageUrl: resource.iconImageUrl,
          } as SelectingResource;
        }) || [];

      setResources(resources);

      setProjectResourceIds(Object.keys(project?.resources || {}));
    }, [organization]);

    // すでにアサインされたリソース
    useEffect(() => {
      const _assignedResourceIds = taskModel.assignResourceIds;
      const isAssigned = (resource: SelectingResource): boolean => {
        return _assignedResourceIds.some((u) => u === resource.id);
      };
      setAssignedResources(resources.filter((resource) => isAssigned(resource)));
    }, [resources, taskModel.assignResourceIds]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionResourceList
            resources={resources}
            assignedResources={assignedResources}
            projectResourceIds={projectResourceIds}
            taskModel={taskModel}
            onChangeAssignResources={onChangeAssignResources}
          />
        </div>
        <div className="assign-resource__list">
          {assignedResources.map((resource) => (
            <div key={resource.id} className="flex items-center pt-1 pb-1">
              {resource.profileImageUrl ? (
                <img
                  src={resource.profileImageUrl}
                  className="w-5 rounded-full object-contain"
                />
              ) : (
                <div className="w-5 h-5 bg-[var(--color-resource)] text-white text-center leading-5 rounded-full">
                  <p className="text-[12px]">{resource.name.charAt(0)}</p>
                </div>
              )}
              <div className="v-stack ml-2">
                <p className="name-label">{resource.name}</p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  },
);

const SelectionResourceList = (props: {
  taskModel: TaskModel;
  resources: SelectingResource[];
  assignedResources: SelectingResource[];
  projectResourceIds: string[];
  onChangeAssignResources: () => void;
}) => {
  const { resources, assignedResources, projectResourceIds, taskModel, onChangeAssignResources } = props;
  const [listAssignedResources, setListAssignedResources] =
    useState<SelectingResource[]>(assignedResources);
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);
  const [filteredResources, setFilteredResources] = useState<SelectingResource[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setListAssignedResources(assignedResources);
  }, [assignedResources]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const prev = isOpenAssignList;
        if (prev) {
          setIsOpenAssignList(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, isOpenAssignList]);

  const List = React.useMemo(() => {
    const onSave = async () => {
      const checkUnchanged =
        listAssignedResources.length === assignedResources.length &&
        listAssignedResources.every((resource) => {
          return hasResourceCheck({ list: assignedResources, targetResource: resource });
        });
      if (!checkUnchanged) {
        const resources = listAssignedResources.map((resource) => resource.id);
        taskModel.setAssginResources(resources);
        await taskModel.saveAssignResources();
        onChangeAssignResources();
      }
    };

    const onSearch = (searchText: string) => {
      const list = new Set(listAssignedResources.map((resource) => resource.id));

      const _filteredResources = resources
        .filter((v) => !_.isEmpty(searchText)
          ? (
            v.name.toLowerCase().includes(searchText.toLowerCase()) ||
            Object.keys(v.tags).some((value => value.toLowerCase().includes(searchText.toLowerCase()))) ||
            v.memo.toLowerCase().includes(searchText.toLowerCase())
          )
          : true)
        .sort((a, b) => {
          if (list.has(a.id) && !list.has(b.id)) {
            return -1;
          } else if (!list.has(a.id) && list.has(b.id)) {
            return 1;
          }
          if (projectResourceIds.includes(a.id) && !projectResourceIds.includes(b.id)) {
            return -1;
          } else if (!projectResourceIds.includes(a.id) && projectResourceIds.includes(b.id)) {
            return 1;
          }
          return 0;
        })

      setFilteredResources(_filteredResources);
    };

    const onSelectResourceButtonPressed = () => {
      const prev = isOpenAssignList;
      if (prev) {
        onSave();
      }
      setIsOpenAssignList(!isOpenAssignList);
      onSearch("");
    };

    return (
      <Menu ref={ref} as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSave();
          }
          return (
            <>
              <Menu.Button
                className="flex items-center group/assign-button cursor-pointer no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                as="div"
                onClick={() => {
                  onSelectResourceButtonPressed();
                }}
              >
                <label className="task-content__label !mb-0 cursor-pointer group-hover/assign-button:text-primary-color">
                  関連リソース
                </label>
                <GoGear
                  className="ml-2 cursor-pointer text-black group-hover/assign-button:text-primary-color"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-[1] absolute left-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {resources.length > 0 && (
                    <>
                      {/* Assigned resources */}
                      <div className="m-2">
                        <input
                          className="w-full border-[1px] border-solid border-[var(--button-gray-3)] p-2 rounded-[4px]"
                          type="text"
                          onChange={(e) => onSearch(e.target.value)}
                          placeholder={"検索..."}
                        />
                      </div>
                      {filteredResources.map((resource) => {
                        const isCheck = hasResourceCheck({
                          list: listAssignedResources,
                          targetResource: resource,
                        });
                        const isProjectResource = projectResourceIds.includes(resource.id);
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-resource flex items-center hover:bg-primary-color transition ease-out duration-100 no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                            onClick={() => {
                              setListAssignedResources(
                                toggeleResources({
                                  list: listAssignedResources,
                                  targetResource: resource,
                                }),
                              );
                            }}
                          >
                            <TbPinnedFilled
                              className={`${isProjectResource
                                ? "text-black group-hover/select-resource:text-white"
                                : "text-transparent"
                                } mr-1`}
                            />
                            <FaCheck
                              className={`${isCheck
                                ? "text-black group-hover/select-resource:text-white"
                                : "text-transparent"
                                } mr-2`}
                            />
                            {resource.profileImageUrl ? (
                              <img
                                src={resource.profileImageUrl}
                                className="w-5 rounded-full object-contain mr-2"
                              />
                            ) : (
                              <div className="w-5 h-5 bg-[var(--color-resource)] text-white text-center leading-5 rounded-full mr-2">
                                <p className="text-[12px]">{resource.name.charAt(0)}</p>
                              </div>
                            )}
                            <span className="group-hover/select-resource:text-white">
                              {resource.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    resources,
    filteredResources,
    assignedResources,
    listAssignedResources,
    projectResourceIds,
  ]);

  return List;
};

export default SelectAssignResources;
