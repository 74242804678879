import { register } from "src/excalidraw/actions/register";

export const actionToggleEmphasizedMode = register({
  name: "emphasizedMode",
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.emphasizedModeEnabled,
  },
  perform(elements, appState) {
    return {
      appState: {
        ...appState,
        emphasizedModeEnabled: !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.emphasizedModeEnabled,
  contextItemLabel: "buttons.emphasizedMode",
});

export const actionToggleTransparentMode = register({
  name: "transparentMode",
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.transparentModeEnabled,
  },
  perform(elements, appState) {
    return {
      appState: {
        ...appState,
        transparentModeEnabled: !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.transparentModeEnabled,
  contextItemLabel: "buttons.transparentMode",
});
