import {
  NonDeleted,
  ExcalidrawElement,
  ExcalidrawTextElementWithContainer,
  ElementsMap,
} from "../../element/types";
import {
  ExcalidrawTaskElement,
  PointBindingEx,
  ExcalidrawBindableElementEx,
  ExcalidrawNodeElement,
} from "./types"; // from extensions
import {
  distance2d,
  rotate,
  getGridPoint,
  rotatePoint,
  arePointsEqual,
  centerPoint, //CHANGED:ADD 2022/12/08 #225
} from "../../math";
import { getBezierCurveLengthEx } from "../math"; // from extensions
import {
  getElementAbsoluteCoords,
  getLockedLinearCursorAlignSize,
} from "../../element";
import {
  Bounds,
  getCurvePathOps,
  getElementPointsCoords,
  getMinMaxXYFromCurvePathOps,
} from "../../element/bounds";
import { Point, AppState, AppClassProperties } from "src/excalidraw/types";
import { mutateElement } from "../../element/mutateElement";
import History from "../../history";

import Scene from "src/excalidraw/scene/Scene";
// CHANGED:ADD 2022-11-14 #137
import { updateBoundElementEx, updateBoundElementsEx } from "./binding"; // from extensions
import {
  getBoundTextElement,
  handleBindTextResize,
} from "../../element/textElement";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";
// CHANGED:ADD 2022-11-21 #164
import Calendar from "../calendar"; // from extensions
// import { GRID_SIZE } from "src/excalidraw/constants"; // CHANGED:ADD 2022-11-04 #94
import {
  BOUNDTEXT_OFFSET_X_HORIZONTAL,
  BOUNDTEXT_OFFSET_X_VERTICAL,
  BOUNDTEXT_OFFSET_Y,
  COMMENT_OFFSET_X,
  COMMENT_OFFSET_Y,
  GRID_SIZE,
  JOB_ELEMENTS_WIDTH,
  TASK_TO_LINK_GEN_POSITION,
  TEXT_ALIGN,
  TEXT_DIRECTION,
  VERTICAL_ALIGN,
} from "src/excalidraw/constants"; // CHANGED:UPDATE 2022-11-04 #99
// CHANGED:ADD 2022-11-17 #149
import {
  isBindableElementEx,
  isCommentElement,
  isLinkElement,
  isMilestoneElement,
  isTaskElement,
} from "./typeChecks"; // from extensions
// CHANGED:ADD 2022-12-28 #397
import {
  getMovableSelectedElements,
  getAdjacencyElements,
} from "./dragElements"; // from extensions

export class TaskElementEditor {
  public readonly elementId: ExcalidrawElement["id"] & {
    _brand: "excalidrawTaskElementId";
  };
  /** indices */
  public readonly selectedPointsIndices: readonly number[] | null;

  public readonly pointerDownState: Readonly<{
    prevSelectedPointsIndices: readonly number[] | null;
    /** index */
    lastClickedPoint: number;
    origin: Readonly<{ x: number; y: number }> | null;
  }>;

  /** whether you're dragging a point */
  public readonly isDragging: boolean;
  public readonly lastUncommittedPoint: Point | null;
  public readonly pointerOffset: Readonly<{ x: number; y: number }>;
  public readonly startBindingElement:
    | ExcalidrawBindableElementEx
    | null
    | "keep";
  public readonly endBindingElement:
    | ExcalidrawBindableElementEx
    | null
    | "keep";
  public readonly hoverPointIndex: number;

  // CHANGED:ADD 2023-2-27 #743
  public readonly dependencyElementIds: { [id: string]: boolean } = {};

  constructor(element: NonDeleted<ExcalidrawTaskElement>, scene: Scene, appState: AppState) {
    this.elementId = element.id as string & {
      _brand: "excalidrawTaskElementId";
    };
    TaskElementEditor.normalizePoints(element);

    this.selectedPointsIndices = null;
    this.lastUncommittedPoint = null;
    this.isDragging = false;
    this.pointerOffset = { x: 0, y: 0 };
    this.startBindingElement = "keep";
    this.endBindingElement = "keep";
    this.pointerDownState = {
      prevSelectedPointsIndices: null,
      lastClickedPoint: -1,
      origin: null,
    };
    this.hoverPointIndex = -1;

    // CHANGED:ADD 2023-2-27 #743
    let dependencyElementIds: { [id: string]: boolean } = {};
    const queue: ExcalidrawNodeElement[] = [];
    const discovered: Set<ExcalidrawElement["id"]> = new Set();


    if (appState.emphasizedModeEnabled || appState.transparentModeEnabled) {

      discovered.add(element.id);
      queue.push(element);

      while (queue.length > 0) {
        const v = queue.shift() as ExcalidrawNodeElement;


        v.prevDependencies?.forEach((e) => {
          if (!discovered.has(e)) {
            discovered.add(e);

            const u = scene.getNonDeletedElement(e);
            if (u && isBindableElementEx(u, true)) {
              dependencyElementIds = {
                ...dependencyElementIds,
                [u.id]: true,
              };

              const linkElement = scene.getNonDeletedElements().find(
                (element) =>
                  isLinkElement(element) &&
                  element.startBinding?.elementId === u.id &&
                  element.endBinding?.elementId === v.id,
              );

              if (linkElement) {
                dependencyElementIds = {
                  ...dependencyElementIds,
                  [linkElement.id]: true,
                };
              }
              queue.push(u);
            }
          }
        });
      }

      queue.push(element);

      while (queue.length > 0) {
        const v = queue.shift() as ExcalidrawNodeElement;

        v.nextDependencies?.forEach((e) => {
          if (!discovered.has(e)) {
            discovered.add(e);

            const u = scene.getNonDeletedElement(e);
            if (u && isBindableElementEx(u, true)) {
              dependencyElementIds = {
                ...dependencyElementIds,
                [u.id]: true,
              };

              const linkElement = scene.getNonDeletedElements().find(
                (element) =>
                  isLinkElement(element) &&
                  element.startBinding?.elementId === v.id &&
                  element.endBinding?.elementId === u.id,
              );

              if (linkElement) {
                dependencyElementIds = {
                  ...dependencyElementIds,
                  [linkElement.id]: true,
                };
              }
              queue.push(u);
            }
          }
        });
      }
    }

    this.dependencyElementIds = dependencyElementIds;
  }

  // ---------------------------------------------------------------------------
  // static methods
  // ---------------------------------------------------------------------------

  static POINT_HANDLE_SIZE = 10;
  /**
   * @param id the `elementId` from the instance of this class (so that we can
   *  statically guarantee this method returns an ExcalidrawTaskElement)
   */
  static getElement(id: InstanceType<typeof TaskElementEditor>["elementId"]) {
    const element = Scene.getScene(id)?.getNonDeletedElement(id);
    if (element) {
      return element as NonDeleted<ExcalidrawTaskElement>;
    }
    return null;
  }

  /** @returns whether point was dragged */
  static handlePointDragging(
    event: PointerEvent,
    appState: AppState,
    scenePointerX: number,
    scenePointerY: number,
    maybeSuggestBinding: (
      element: NonDeleted<ExcalidrawTaskElement>,
      pointSceneCoords: { x: number; y: number }[],
    ) => void,
    taskElementEditor: TaskElementEditor,
    calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
    scene: Scene, // CHANGED:ADD 2022-12-28 #397
    elementsMap: ElementsMap,
  ): boolean {
    if (!taskElementEditor) {
      return false;
    }
    const { selectedPointsIndices, elementId } = taskElementEditor;
    const element = TaskElementEditor.getElement(elementId);
    if (!element) {
      return false;
    }

    // CHANGED:ADD 2022-11-8 #99
    if (taskElementEditor.pointerDownState.lastClickedPoint === 2) {
      return true;
    }

    // point that's being dragged (out of all selected points)
    const draggingPoint = element.points[
      taskElementEditor.pointerDownState.lastClickedPoint
    ] as [number, number] | undefined;

    if (selectedPointsIndices && draggingPoint) {
      // CHANGED:REMOVE 2022-11-1 #65
      const newDraggingPointPosition = TaskElementEditor.createPointAt(
        element,
        elementsMap,
        scenePointerX - taskElementEditor.pointerOffset.x,
        scenePointerY - taskElementEditor.pointerOffset.y,
        appState.gridSize,
      );

      const deltaX = newDraggingPointPosition[0] - draggingPoint[0];
      const deltaY = 0; // CHANGED:UPDATE 2022-10-31 #65
      const _gridSize = appState.gridSize ? appState.gridSize : GRID_SIZE; // CHANGED:ADD 2022-11-04 #94

      const selectedPointIndex = selectedPointsIndices[0];
      const leftOrRight = deltaX === 0 ? "keep" : deltaX < 0 ? "left" : "right";

      // CHANGED:ADD 2022-11-17 #149
      const startXLimits: Set<number> = new Set();
      const endXLimits: Set<number> = new Set();

      // CHANGED:ADD 2022-12-28 #397
      if (leftOrRight !== "keep") {
        if (selectedPointIndex === 0 && leftOrRight === "left") {
          const distance = JOB_ELEMENTS_WIDTH - element.x;
          startXLimits.add(distance);
        } else if (selectedPointIndex === 1 && leftOrRight === "right") {
          const distance = JOB_ELEMENTS_WIDTH + appState.calendarWidth - element.x;
          endXLimits.add(distance);
        }

        if (
          (selectedPointIndex === 0 && leftOrRight === "left") ||
          (selectedPointIndex === 1 && leftOrRight === "right")
        ) {
          const adjacencyElements = getAdjacencyElements(
            [element],
            elementsMap,
            new Set(element.id),
            deltaX,
          );

          if (adjacencyElements.length > 0) {
            const offset = getMovableSelectedElements(
              adjacencyElements,
              elementsMap,
              { x: deltaX, y: deltaY },
              appState,
              false,
            );

            adjacencyElements.forEach((element) => {
              if (isTaskElement(element) && !element.locked) {
                const startDate = calendar.getPointDate(element.x + offset.x);
                const endDate = calendar.getPointDate(element.x + offset.x + element.width);
                const duration = calendar.getDuration(
                  startDate,
                  endDate,
                  element.holidays, // CHANGED:ADD 2023-1-20 #382
                );

                mutateElement(element, {
                  x: element.x + offset.x,
                  y: element.y + offset.y,
                  startDate,
                  endDate,
                  duration,
                });

                // CHANGED: ADD 2024-03-08 #1138, #1741
                scene.getNonDeletedElements()
                  .filter((el) => isCommentElement(el) && el.commentElementId === element.id)
                  .forEach((commentElement) => {
                    mutateElement(commentElement, {
                      x: element.x + element.width + COMMENT_OFFSET_X,
                      y: element.y + COMMENT_OFFSET_Y,
                    });
                  });

                const boundTextElement = getBoundTextElement(element, elementsMap);
                if (boundTextElement) {
                  scene.updateBoundTextElement(
                    boundTextElement,
                    boundTextElement.originalText,
                    boundTextElement.originalText,
                    boundTextElement.isDeleted,
                  );
                }
              }

              updateBoundElementsEx(
                element,
                elementsMap,
                appState,
                calendar, // CHANGED:ADD 2022/01/19 #390
                { simultaneouslyUpdated: adjacencyElements },
              );
            });
          }
        }

        const dependencies =
          leftOrRight === "left"
            ? element.prevDependencies
            : element.nextDependencies;

        dependencies?.forEach((dependElementId) => {
          const bindingElement =
            Scene.getScene(element)?.getNonDeletedElement(dependElementId);
          if (bindingElement && isBindableElementEx(bindingElement, true)) {
            let targetStartX = 0;
            let targetEndX = 0;

            if (isTaskElement(bindingElement)) {
              targetStartX = bindingElement.x;
              targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
                bindingElement,
                -1,
                elementsMap,
              )[0];
            } else if (isMilestoneElement(bindingElement)) {
              targetStartX = bindingElement.x + bindingElement.width / 2;
              targetEndX = bindingElement.x + bindingElement.width / 2;
            }

            if (selectedPointIndex === 0 && leftOrRight === "left") {
              const distance = targetEndX - element.x;
              startXLimits.add(distance);
            } else if (selectedPointIndex === 1 && leftOrRight === "right") {
              const distance = targetStartX - element.x;
              endXLimits.add(distance);
            }
          }
        });
      }

      TaskElementEditor.movePoints(
        element,
        selectedPointsIndices.map((pointIndex) => {
          // CHANGED:UPDATE 2022-10-31 #65
          const newPointPosition = [
            pointIndex === 0
              ? Math.max(
                ...startXLimits,
                Math.min(
                  element.points[1][0] - _gridSize,
                  element.points[pointIndex][0] + deltaX,
                ),
              )
              : Math.max(
                0 + _gridSize,
                Math.min(
                  element.points[pointIndex][0] + deltaX,
                  ...endXLimits,
                ),
              ),
            element.points[pointIndex][1] + deltaY,
          ] as const;

          // CHANGED:ADD 2022-11-14 #137
          updateBoundElementEx(
            element,
            elementsMap,
            appState,
            pointIndex === 0 ? "end" : "start",
            calendar, // CHANGED:ADD 2022/01/19 #390
          );

          return {
            index: pointIndex,
            point: newPointPosition,
            isDragging:
              pointIndex ===
              taskElementEditor.pointerDownState.lastClickedPoint,
          };
        }),
        calendar, // CHANGED:ADD 2022-11-21 #164
      );

      // CHANGED: ADD 2024-03-08 #1138, #1741
      scene.getNonDeletedElements()
        .filter((el) => isCommentElement(el) && el.commentElementId === element.id)
        .forEach((commentElement) => {
          mutateElement(commentElement, {
            x: element.x + element.width + COMMENT_OFFSET_X,
            y: element.y + COMMENT_OFFSET_Y,
          });
        })

      //CHANGED:ADD 2022/12/08 #225
      const boundTextElement = getBoundTextElement(element, elementsMap);
      if (boundTextElement) {
        handleBindTextResize(element, elementsMap, false);
      }
      // CHANGED:REMOVE 2022-11-2 #64
      // // suggest bindings for first and last point if selected
      // if (isBindingElementEx(element, false)) {
      //   const coords: { x: number; y: number }[] = [];

      //   const firstSelectedIndex = selectedPointsIndices[0];
      //   if (firstSelectedIndex === 0) {
      //     coords.push(
      //       tupleToCoors(
      //         TaskElementEditor.getPointGlobalCoordinates(
      //           element,
      //           element.points[0],
      //         ),
      //       ),
      //     );
      //   }

      //   const lastSelectedIndex =
      //     selectedPointsIndices[selectedPointsIndices.length - 1];
      //   if (lastSelectedIndex === element.points.length - 1) {
      //     coords.push(
      //       tupleToCoors(
      //         TaskElementEditor.getPointGlobalCoordinates(
      //           element,
      //           element.points[lastSelectedIndex],
      //         ),
      //       ),
      //     );
      //   }

      //   if (coords.length) {
      //     maybeSuggestBinding(element, coords);
      //   }
      // }

      return true;
    }

    return false;
  }

  static handlePointerUp(
    event: PointerEvent,
    editingTaskElement: TaskElementEditor,
    appState: AppState,
  ): TaskElementEditor {
    const { elementId } =
      editingTaskElement;
    const element = TaskElementEditor.getElement(elementId);
    if (!element) {
      return editingTaskElement;
    }

    return {
      ...editingTaskElement,
      isDragging: false,
      pointerOffset: { x: 0, y: 0 },
    };
  }

  static isSegmentTooShort(
    element: NonDeleted<ExcalidrawTaskElement>,
    startPoint: Point,
    endPoint: Point,
    zoom: AppState["zoom"],
  ) {
    let distance = distance2d(
      startPoint[0],
      startPoint[1],
      endPoint[0],
      endPoint[1],
    );
    if (element.points.length > 2 && element.roundness) {
      distance = getBezierCurveLengthEx(element, endPoint);
    }

    return distance * zoom.value < TaskElementEditor.POINT_HANDLE_SIZE * 4;
  }

  static handlePointerDown(
    event: React.PointerEvent<HTMLCanvasElement>,
    appState: AppState,
    history: History,
    scenePointer: { x: number; y: number },
    taskElementEditor: TaskElementEditor,
    app: AppClassProperties,
  ): {
    didAddPoint: boolean;
    hitElement: NonDeleted<ExcalidrawElement> | null;
    taskElementEditor: TaskElementEditor | null;
  } {
    const elementsMap = app.scene.getNonDeletedElementsMap();

    const ret: ReturnType<typeof TaskElementEditor["handlePointerDown"]> = {
      didAddPoint: false,
      hitElement: null,
      taskElementEditor: null,
    };

    if (!taskElementEditor) {
      return ret;
    }

    const { elementId } = taskElementEditor;
    const element = TaskElementEditor.getElement(elementId);

    if (!element) {
      return ret;
    }

    const clickedPointIndex = TaskElementEditor.getPointIndexUnderCursor(
      element,
      elementsMap,
      appState.zoom,
      scenePointer.x,
      scenePointer.y,
    );
    // if we clicked on a point, set the element as hitElement otherwise
    // it would get deselected if the point is outside the hitbox area
    if (clickedPointIndex >= 0) {
      ret.hitElement = element;
    } else {
      // You might be wandering why we are storing the binding elements on
      // TaskElementEditor and passing them in, instead of calculating them
      // from the end points of the `taskElement` - this is to allow disabling
      // binding (which needs to happen at the point the user finishes moving
      // the point).
      // CHANGED:REMOVE 2022-11-2 #64
      // const { startBindingElement, endBindingElement } = taskElementEditor;
      // if (isBindingEnabledEx(appState) && isBindingElementEx(element)) {
      //   bindOrUnbindTaskElement(
      //     element,
      //     startBindingElement,
      //     endBindingElement,
      //   );
      // }
    }

    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const targetPoint =
      element.points.length === clickedPointIndex
        ? clickedPointIndex > -1 &&
        rotate(
          element.x +
          element.points[element.points.length - 1][0] +
          TASK_TO_LINK_GEN_POSITION,
          element.y + element.points[element.points.length - 1][1],
          cx,
          cy,
          (element.angle || 0),
        )
        : clickedPointIndex > -1 &&
        rotate(
          element.x + element.points[clickedPointIndex][0],
          element.y + element.points[clickedPointIndex][1],
          cx,
          cy,
          (element.angle || 0),
        );

    const nextSelectedPointsIndices =
      clickedPointIndex > -1 || event.shiftKey
        ? event.shiftKey ||
          taskElementEditor.selectedPointsIndices?.includes(clickedPointIndex)
          ? normalizeSelectedPoints([
            ...(taskElementEditor.selectedPointsIndices || []),
            clickedPointIndex,
          ])
          : [clickedPointIndex]
        : null;
    ret.taskElementEditor = {
      ...taskElementEditor,
      pointerDownState: {
        prevSelectedPointsIndices: taskElementEditor.selectedPointsIndices,
        lastClickedPoint: clickedPointIndex,
        origin: { x: scenePointer.x, y: scenePointer.y },
      },
      selectedPointsIndices: nextSelectedPointsIndices,
      pointerOffset: targetPoint
        ? {
          x: scenePointer.x - targetPoint[0],
          y: scenePointer.y - targetPoint[1],
        }
        : { x: 0, y: 0 },
    };

    return ret;
  }

  static arePointsEqual(point1: Point | null, point2: Point | null) {
    if (!point1 && !point2) {
      return true;
    }
    if (!point1 || !point2) {
      return false;
    }
    return arePointsEqual(point1, point2);
  }

  /** scene coords */
  static getPointGlobalCoordinates(
    element: NonDeleted<ExcalidrawTaskElement>,
    point: Point,
    elementsMap: ElementsMap,
  ) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;

    let { x, y } = element;
    [x, y] = rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0));
    return [x, y] as const;
  }

  /** scene coords */
  static getPointsGlobalCoordinates(
    element: NonDeleted<ExcalidrawTaskElement>,
    elementsMap: ElementsMap,
  ): Point[] {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    //CHANGED:UPDATE 2022-11-07 #99
    // return element.points.map((point) => {
    const points = element.points.map((point) => {
      let { x, y } = element;
      [x, y] = rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0));
      return [x, y] as const;
    });

    //CHANGED:ADD 2022-11-07 #99
    const lastPoints = points.slice(-1)[0];
    return points.concat([
      [lastPoints[0] + TASK_TO_LINK_GEN_POSITION, lastPoints[1]],
    ]);
  }

  static getPointAtIndexGlobalCoordinates(
    element: NonDeleted<ExcalidrawTaskElement>,
    indexMaybeFromEnd: number, // -1 for last element
    elementsMap: ElementsMap,
  ): Point {
    const index =
      indexMaybeFromEnd < 0
        ? element.points.length + indexMaybeFromEnd
        : indexMaybeFromEnd;
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;

    //CHANGED:UPDATE 2022-11-07 #99
    // const point = element.points[index];
    const length = element.points.length;
    const genLinkPoint = [
      element.points[length - 1][0] + TASK_TO_LINK_GEN_POSITION,
      element.points[length - 1][1],
    ];
    const point =
      element.points.length === index ? genLinkPoint : element.points[index];
    const { x, y } = element;
    return point
      ? rotate(x + point[0], y + point[1], cx, cy, (element.angle || 0))
      : rotate(x, y, cx, cy, (element.angle || 0));
  }

  static pointFromAbsoluteCoords(
    element: NonDeleted<ExcalidrawTaskElement>,
    absoluteCoords: Point,
    elementsMap: ElementsMap,
  ): Point {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const [x, y] = rotate(
      absoluteCoords[0],
      absoluteCoords[1],
      cx,
      cy,
      -(element.angle || 0),
    );
    return [x - element.x, y - element.y];
  }

  static getPointIndexUnderCursor(
    element: NonDeleted<ExcalidrawTaskElement>,
    elementsMap: ElementsMap,
    zoom: AppState["zoom"],
    x: number,
    y: number,
  ) {
    const pointHandles =
      TaskElementEditor.getPointsGlobalCoordinates(element, elementsMap);
    let idx = pointHandles.length;
    // loop from right to left because points on the right are rendered over
    // points on the left, thus should take precedence when clicking, if they
    // overlap
    while (--idx > -1) {
      const point = pointHandles[idx];
      if (
        distance2d(x, y, point[0], point[1]) * zoom.value <
        // +1px to account for outline stroke
        TaskElementEditor.POINT_HANDLE_SIZE + 1
      ) {
        return idx;
      }
    }
    return -1;
  }

  static createPointAt(
    element: NonDeleted<ExcalidrawTaskElement>,
    elementsMap: ElementsMap,
    scenePointerX: number,
    scenePointerY: number,
    gridSize: number | null,
  ): Point {
    const pointerOnGrid = getGridPoint(scenePointerX, scenePointerY, gridSize);
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const [rotatedX, rotatedY] = rotate(
      pointerOnGrid[0],
      pointerOnGrid[1],
      cx,
      cy,
      -(element.angle || 0),
    );

    return [rotatedX - element.x, rotatedY - element.y];
  }

  /**
   * Normalizes line points so that the start point is at [0,0]. This is
   * expected in various parts of the codebase. Also returns new x/y to account
   * for the potential normalization.
   */
  static getNormalizedPoints(element: ExcalidrawTaskElement) {
    const { points } = element;

    const offsetX = points[0][0];
    const offsetY = points[0][1];

    return {
      points: points.map((point, _idx) => {
        return [point[0] - offsetX, point[1] - offsetY] as const;
      }),
      x: element.x + offsetX,
      y: element.y + offsetY,
    };
  }

  // element-mutating methods
  // ---------------------------------------------------------------------------

  static normalizePoints(element: NonDeleted<ExcalidrawTaskElement>) {
    mutateElement(element, TaskElementEditor.getNormalizedPoints(element));
  }

  static movePoints(
    element: NonDeleted<ExcalidrawTaskElement>,
    targetPoints: { index: number; point: Point; isDragging?: boolean }[],
    calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
    otherUpdates?: {
      startBinding?: PointBindingEx;
      endBinding?: PointBindingEx;
    },
  ) {
    const { points } = element;

    // in case we're moving start point, instead of modifying its position
    // which would break the invariant of it being at [0,0], we move
    // all the other points in the opposite direction by delta to
    // offset it. We do the same with actual element.x/y position, so
    // this hacks are completely transparent to the user.
    let offsetX = 0;
    let offsetY = 0;

    const selectedOriginPoint = targetPoints.find(({ index }) => index === 0);

    if (selectedOriginPoint) {
      offsetX =
        selectedOriginPoint.point[0] + points[selectedOriginPoint.index][0];
      offsetY =
        selectedOriginPoint.point[1] + points[selectedOriginPoint.index][1];
    }

    const nextPoints = points.map((point, idx) => {
      const selectedPointData = targetPoints.find((p) => p.index === idx);
      if (selectedPointData) {
        if (selectedOriginPoint) {
          return point;
        }

        const deltaX =
          selectedPointData.point[0] - points[selectedPointData.index][0];
        const deltaY =
          selectedPointData.point[1] - points[selectedPointData.index][1];

        return [point[0] + deltaX, point[1] + deltaY] as const;
      }
      return offsetX || offsetY
        ? ([point[0] - offsetX, point[1] - offsetY] as const)
        : point;
    });

    TaskElementEditor._updatePoints(
      element,
      nextPoints,
      offsetX,
      offsetY,
      calendar, // CHANGED:ADD 2022-11-21 #164
      otherUpdates,
    );
  }

  private static _updatePoints(
    element: NonDeleted<ExcalidrawTaskElement>,
    nextPoints: readonly Point[],
    offsetX: number,
    offsetY: number,
    calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
    otherUpdates?: {
      startBinding?: PointBindingEx;
      endBinding?: PointBindingEx;
    },
  ) {
    const nextCoords = getElementPointsCoords(element, nextPoints);
    const prevCoords = getElementPointsCoords(element, element.points);
    const nextCenterX = (nextCoords[0] + nextCoords[2]) / 2;
    const nextCenterY = (nextCoords[1] + nextCoords[3]) / 2;
    const prevCenterX = (prevCoords[0] + prevCoords[2]) / 2;
    const prevCenterY = (prevCoords[1] + prevCoords[3]) / 2;
    const dX = prevCenterX - nextCenterX;
    const dY = prevCenterY - nextCenterY;
    const rotated = rotate(offsetX, offsetY, dX, dY, (element.angle || 0));

    const startDate = calendar.getPointDate(nextCoords[0] + rotated[0]); //CHANGED:UPDATE 2023-01-16 #412
    const endDate = calendar.getPointDate(nextCoords[2] + rotated[0]); //CHANGED:UPDATE 2023-01-16 #412
    // CHANGED:ADD 2022-11-15 #114
    const duration = calendar.getDuration(
      startDate,
      endDate,
      element.holidays, // CHANGED:ADD 2023-1-20 #382
    );

    mutateElement(element, {
      ...otherUpdates,
      points: nextPoints,
      x: element.x + rotated[0],
      y: element.y + rotated[1],
      startDate, //CHANGED:ADD 2022-11-01 #79
      endDate, //CHANGED:ADD 2022-11-01 #79
      duration, // CHANGED:ADD 2022-11-15 #114
    });
  }

  private static _getShiftLockedDelta(
    element: NonDeleted<ExcalidrawTaskElement>,
    elementsMap: ElementsMap,
    referencePoint: Point,
    scenePointer: Point,
    gridSize: number | null,
  ) {
    const referencePointCoords = TaskElementEditor.getPointGlobalCoordinates(
      element,
      referencePoint,
      elementsMap,
    );

    const [gridX, gridY] = getGridPoint(
      scenePointer[0],
      scenePointer[1],
      gridSize,
    );

    const { width, height } = getLockedLinearCursorAlignSize(
      referencePointCoords[0],
      referencePointCoords[1],
      gridX,
      gridY,
    );

    return rotatePoint([width, height], [0, 0], -(element.angle || 0));
  }

  static getBoundTextElementPosition = (
    element: ExcalidrawTaskElement,
    boundTextElement: ExcalidrawTextElementWithContainer,
    elementsMap: ElementsMap,
  ): { x: number; y: number } => {
    const points = TaskElementEditor.getPointsGlobalCoordinates(element, elementsMap);
    if (points.length < 2) {
      mutateElement(boundTextElement, { isDeleted: true });
    }
    //CHANGED:UPDATE 2022/12/08 #225
    // const x = boundTextElement.width / 2;
    // const y = boundTextElement.height / 2;
    const midPoint = centerPoint(points[0], points[1]);

    // CHANGED:UPDATE 2024-03-08 #1746
    // CHANGED:UPDATE 2023-04-01 #810
    // const x = points[0][0] + 10;
    // const x =
    //   boundTextElement.textAlign === TEXT_ALIGN.LEFT
    //     ? element.x +
    //     (boundTextElement.textDirection === TEXT_DIRECTION.HORIZONTAL ? 20 : 5)  // CHANGED:UPDATE 2024/02/01 #1510
    //     : boundTextElement.textAlign === TEXT_ALIGN.RIGHT
    //       ? element.x +
    //       element.width -
    //       boundTextElement.width
    //       : element.x + element.width / 2 - boundTextElement.width / 2;

    // const y = midPoint[1] - boundTextElement.height - 24; // CHANGED:UPDATE 2023/08/29 #955
    const x =
      (
        boundTextElement.horizontalAlign === TEXT_ALIGN.LEFT
          ? element.x +
          (boundTextElement.textDirection === TEXT_DIRECTION.HORIZONTAL
            ? BOUNDTEXT_OFFSET_X_HORIZONTAL
            : BOUNDTEXT_OFFSET_X_VERTICAL)
          : (boundTextElement.horizontalAlign === TEXT_ALIGN.RIGHT
            ? element.x + element.width - boundTextElement.width
            : element.x + element.width / 2 - boundTextElement.width / 2)
      ) +
      (boundTextElement.offsetX ?? 0);

    const y =
      (
        boundTextElement.verticalAlign === VERTICAL_ALIGN.BOTTOM
          ? midPoint[1] + BOUNDTEXT_OFFSET_Y
          : midPoint[1] - boundTextElement.height - BOUNDTEXT_OFFSET_Y
      ) +
      (boundTextElement.offsetY ?? 0);

    return { x, y };
  };

  static getMinMaxXYWithBoundText = (
    element: ExcalidrawTaskElement,
    elementsMap: ElementsMap,
    elementBounds: Bounds,
    boundTextElement: ExcalidrawTextElementWithContainer,
  ): [number, number, number, number, number, number] => {
    let [x1, y1, x2, y2] = elementBounds;
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const { x: boundTextX1, y: boundTextY1 } =
      TaskElementEditor.getBoundTextElementPosition(
        element,
        boundTextElement,
        elementsMap,
      );
    const boundTextX2 = boundTextX1 + boundTextElement.width;
    const boundTextY2 = boundTextY1 + boundTextElement.height;

    const topLeftRotatedPoint = rotatePoint([x1, y1], [cx, cy], (element.angle || 0));
    const topRightRotatedPoint = rotatePoint([x2, y1], [cx, cy], (element.angle || 0));

    const counterRotateBoundTextTopLeft = rotatePoint(
      [boundTextX1, boundTextY1],

      [cx, cy],

      -(element.angle || 0),
    );
    const counterRotateBoundTextTopRight = rotatePoint(
      [boundTextX2, boundTextY1],

      [cx, cy],

      -(element.angle || 0),
    );
    const counterRotateBoundTextBottomLeft = rotatePoint(
      [boundTextX1, boundTextY2],

      [cx, cy],

      -(element.angle || 0),
    );
    const counterRotateBoundTextBottomRight = rotatePoint(
      [boundTextX2, boundTextY2],

      [cx, cy],

      -(element.angle || 0),
    );

    if (
      topLeftRotatedPoint[0] < topRightRotatedPoint[0] &&
      topLeftRotatedPoint[1] >= topRightRotatedPoint[1]
    ) {
      x1 = Math.min(x1, counterRotateBoundTextBottomLeft[0]);
      x2 = Math.max(
        x2,
        Math.max(
          counterRotateBoundTextTopRight[0],
          counterRotateBoundTextBottomRight[0],
        ),
      );
      y1 = Math.min(y1, counterRotateBoundTextTopLeft[1]);

      y2 = Math.max(y2, counterRotateBoundTextBottomRight[1]);
    } else if (
      topLeftRotatedPoint[0] >= topRightRotatedPoint[0] &&
      topLeftRotatedPoint[1] > topRightRotatedPoint[1]
    ) {
      x1 = Math.min(x1, counterRotateBoundTextBottomRight[0]);
      x2 = Math.max(
        x2,
        Math.max(
          counterRotateBoundTextTopLeft[0],
          counterRotateBoundTextTopRight[0],
        ),
      );
      y1 = Math.min(y1, counterRotateBoundTextBottomLeft[1]);

      y2 = Math.max(y2, counterRotateBoundTextTopRight[1]);
    } else if (topLeftRotatedPoint[0] >= topRightRotatedPoint[0]) {
      x1 = Math.min(x1, counterRotateBoundTextTopRight[0]);
      x2 = Math.max(x2, counterRotateBoundTextBottomLeft[0]);
      y1 = Math.min(y1, counterRotateBoundTextBottomRight[1]);

      y2 = Math.max(y2, counterRotateBoundTextTopLeft[1]);
    } else if (topLeftRotatedPoint[1] <= topRightRotatedPoint[1]) {
      x1 = Math.min(
        x1,
        Math.min(
          counterRotateBoundTextTopRight[0],
          counterRotateBoundTextTopLeft[0],
        ),
      );

      x2 = Math.max(x2, counterRotateBoundTextBottomRight[0]);
      y1 = Math.min(y1, counterRotateBoundTextTopRight[1]);
      y2 = Math.max(y2, counterRotateBoundTextBottomLeft[1]);
    }

    return [x1, y1, x2, y2, cx, cy];
  };

  static getElementAbsoluteCoords = (
    element: ExcalidrawTaskElement,
    elementsMap: ElementsMap,
    includeBoundText: boolean = false,
  ): [number, number, number, number, number, number] => {
    let coords: [number, number, number, number, number, number];
    let x1;
    let y1;
    let x2;
    let y2;
    if (element.points.length < 2 || !ShapeCache.get(element)) {
      // XXX this is just a poor estimate and not very useful
      const { minX, minY, maxX, maxY } = element.points.reduce(
        (limits, [x, y]) => {
          limits.minY = Math.min(limits.minY, y);
          limits.minX = Math.min(limits.minX, x);

          limits.maxX = Math.max(limits.maxX, x);
          limits.maxY = Math.max(limits.maxY, y);

          return limits;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
      );
      x1 = minX + element.x;
      y1 = minY + element.y;
      x2 = maxX + element.x;
      y2 = maxY + element.y;
    } else {
      const shape = ShapeCache.generateElementShape(element, null);

      // first element is always the curve
      const renderShape = shape.filter((s) => s.shape == "line");
      const opsMin = getCurvePathOps(renderShape[0]);
      const opsMax = getCurvePathOps(renderShape[renderShape.length - 1]);
      const [minX, minY, ,] = getMinMaxXYFromCurvePathOps(opsMin);
      const [, , maxX, maxY] = getMinMaxXYFromCurvePathOps(opsMax);
      x1 = minX + element.x;
      y1 = minY + element.y;
      x2 = maxX + element.x;
      y2 = maxY + element.y;
    }
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    coords = [x1, y1, x2, y2, cx, cy];

    if (!includeBoundText) {
      return coords;
    }
    const boundTextElement = getBoundTextElement(element, elementsMap);
    if (boundTextElement) {
      coords = TaskElementEditor.getMinMaxXYWithBoundText(
        element,
        elementsMap,
        [x1, y1, x2, y2],
        boundTextElement,
      );
    }

    return coords;
  };
}

const normalizeSelectedPoints = (
  points: (number | null)[],
): number[] | null => {
  let nextPoints = [
    ...new Set(points.filter((p) => p !== null && p !== -1)),
  ] as number[];
  nextPoints = nextPoints.sort((a, b) => a - b);
  return nextPoints.length ? nextPoints : null;
};
