import cssVariables from "./css/variables.module.scss";
import { AppProps } from "./types";
import { ExcalidrawElement, FontFamilyValues } from "./element/types";
import oc from "open-color";

export const isDarwin = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
export const isWindows = /^Win/.test(navigator.platform);
export const isAndroid = /\b(android)\b/i.test(navigator.userAgent);
export const isFirefox =
  "netscape" in window &&
  navigator.userAgent.indexOf("rv:") > 1 &&
  navigator.userAgent.indexOf("Gecko") > 1;
export const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
export const isSafari =
  !isChrome && navigator.userAgent.indexOf("Safari") !== -1;
// keeping function so it can be mocked in test
export const isBrave = () =>
  (navigator as any).brave?.isBrave?.name === "isBrave";

export const APP_NAME = "ConPath";

export const DRAGGING_THRESHOLD = 10; // px
export const LINE_CONFIRM_THRESHOLD = 8; // px
export const ELEMENT_SHIFT_TRANSLATE_AMOUNT = 5;
export const ELEMENT_TRANSLATE_AMOUNT = 1;
export const TEXT_TO_CENTER_SNAP_THRESHOLD = 30;
export const SHIFT_LOCKING_ANGLE = Math.PI / 12;
export const CURSOR_TYPE = {
  TEXT: "text",
  CROSSHAIR: "crosshair",
  GRABBING: "grabbing",
  GRAB: "grab",
  POINTER: "pointer",
  MOVE: "move",
  COL_RESIZE: "col-resize", // CHANGED:ADD 2022-12-12 #188
  ROW_RESIZE: "row-resize", // CHANGED:ADD 2024-03-11 #1749
  AUTO: "",
};
export const POINTER_BUTTON = {
  MAIN: 0,
  WHEEL: 1,
  SECONDARY: 2,
  TOUCH: -1,
} as const;

export const POINTER_EVENTS = {
  enabled: "all",
  disabled: "none",
  // asserted as any so it can be freely assigned to React Element
  // "pointerEnvets" CSS prop
  inheritFromUI: "var(--ui-pointerEvents)" as any,
} as const;

export enum EVENT {
  COPY = "copy",
  PASTE = "paste",
  CUT = "cut",
  KEYDOWN = "keydown",
  KEYUP = "keyup",
  MOUSE_MOVE = "mousemove",
  RESIZE = "resize",
  UNLOAD = "unload",
  FOCUS = "focus",
  BLUR = "blur",
  DRAG_OVER = "dragover",
  DROP = "drop",
  GESTURE_END = "gestureend",
  BEFORE_UNLOAD = "beforeunload",
  GESTURE_START = "gesturestart",
  GESTURE_CHANGE = "gesturechange",
  POINTER_MOVE = "pointermove",
  POINTER_UP = "pointerup",
  STATE_CHANGE = "statechange",
  WHEEL = "wheel",
  TOUCH_START = "touchstart",
  TOUCH_END = "touchend",
  HASHCHANGE = "hashchange",
  VISIBILITY_CHANGE = "visibilitychange",
  SCROLL = "scroll",
  // custom events
  EXCALIDRAW_LINK = "conpath-link",
  MENU_ITEM_SELECT = "menu.itemSelect",
}

export const ENV = {
  TEST: "test",
  DEVELOPMENT: "development",
};

export const CLASSES = {
  SHAPE_ACTIONS_MENU: "App-menu__left",
};

// 1-based in case we ever do `if(element.fontFamily)`
export const FONT_FAMILY = {
  Virgil: 1,
  Helvetica: 2,
  Cascadia: 3,
};

export const THEME = {
  LIGHT: "light",
  DARK: "dark",
};

export const WINDOWS_EMOJI_FALLBACK_FONT = "Segoe UI Emoji";

export const MIN_FONT_SIZE = 1;
export const DEFAULT_FONT_SIZE = 16; // CHANGED:UPDATE 2024/03/09 #1765
// CHANGED:UPDATE 2022-10-20 #43
// export const DEFAULT_FONT_FAMILY: FontFamilyValues = FONT_FAMILY.Virgil;
export const DEFAULT_FONT_FAMILY: FontFamilyValues = FONT_FAMILY.Helvetica;
export const DEFAULT_TEXT_ALIGN = "left";
export const DEFAULT_VERTICAL_ALIGN = "top";
export const DEFAULT_TEXT_DIRECTION = "horizontal"; // CHANGED:ADD 2024/02/01 #1510
export const DEFAULT_POINTER_DIRECTION = "horizontal"; // CHANGED:ADD 2024/03/11 #1749
export const DEFAULT_VERSION = "{version}";

export const CANVAS_ONLY_ACTIONS = ["selectAll"];

// CHANGED:UPDATE 2022-11-01 #98
// export const GRID_SIZE = 20; // TODO make it configurable?
export const GRID_SIZE = 32; // TODO make it configurable?
export const DASH_STROKE_LENGTH = GRID_SIZE / 6;

export const IMAGE_MIME_TYPES = {
  svg: "image/svg+xml",
  png: "image/png",
  jpg: "image/jpeg",
  gif: "image/gif",
  webp: "image/webp",
  bmp: "image/bmp",
  ico: "image/x-icon",
  avif: "image/avif",
  jfif: "image/jfif",
} as const;

export const MIME_TYPES = {
  conpath: "application/vnd.conpath+json",
  conpathlib: "application/vnd.conpathlib+json",
  json: "application/json",
  svg: "image/svg+xml",
  "conpath.svg": "image/svg+xml",
  png: "image/png",
  "conpath.png": "image/png",
  jpg: "image/jpeg",
  gif: "image/gif",
  webp: "image/webp",
  bmp: "image/bmp",
  ico: "image/x-icon",
  binary: "application/octet-stream",
} as const;

export const EXPORT_IMAGE_TYPES = {
  png: "png",
  svg: "svg",
  clipboard: "clipboard",
} as const;

export const EXPORT_DATA_TYPES = {
  excalidraw: "conpath",
  excalidrawClipboard: "conpath/clipboard",
  excalidrawLibrary: "conpathlib",
} as const;

export const EXPORT_SOURCE =
  window.EXCALIDRAW_EXPORT_SOURCE || window.location.origin;

// time in milliseconds
export const IMAGE_RENDER_TIMEOUT = 500;
export const TAP_TWICE_TIMEOUT = 300;
export const TOUCH_CTX_MENU_TIMEOUT = 500;
export const TITLE_TIMEOUT = 10000;
export const VERSION_TIMEOUT = 30000;
export const SCROLL_TIMEOUT = 100;
export const ZOOM_STEP = 0.1;
export const MIN_ZOOM = 0.1;
export const GENERAL_TOOLTIP_DELAY = 500;
export const HYPERLINK_TOOLTIP_DELAY = 300;

// Report a user inactive after IDLE_THRESHOLD milliseconds
export const IDLE_THRESHOLD = 60_000;
// Report a user active each ACTIVE_THRESHOLD milliseconds
export const ACTIVE_THRESHOLD = 3_000;

export const THEME_FILTER = cssVariables.themeFilter;

export const URL_QUERY_KEYS = {
  addLibrary: "addLibrary",
} as const;

export const URL_HASH_KEYS = {
  addLibrary: "addLibrary",
} as const;

export const DEFAULT_UI_OPTIONS: AppProps["UIOptions"] = {
  canvasActions: {
    changeViewBackgroundColor: true,
    clearCanvas: true,
    export: { saveFileToDisk: true },
    loadScene: true,
    saveToActiveFile: true,
    saveToActiveCloudFile: true, // CHANGED:ADD 2023-2-12 #512
    toggleTheme: null,
    saveAsImage: true,
    printImage: true,
  },
};

export const WARNING_TOAST_DEFAULT_DURATION = 1000;

// breakpoints
// -----------------------------------------------------------------------------
// sm screen
export const MQ_SM_MAX_WIDTH = 640;
// md screen
export const MQ_MAX_WIDTH_PORTRAIT = 730;
export const MQ_MAX_WIDTH_LANDSCAPE = 1000;
export const MQ_MAX_HEIGHT_LANDSCAPE = 500;
// sidebar
export const MQ_RIGHT_SIDEBAR_MIN_WIDTH = 1229;
// -----------------------------------------------------------------------------

export const LIBRARY_SIDEBAR_WIDTH = parseInt(cssVariables.rightSidebarWidth);
export const DEFAULT_SIDEBAR_WIDTH = parseInt(cssVariables.rightDefaultSidebarWidth);

export const MAX_DECIMALS_FOR_SVG_EXPORT = 2;

export const EXPORT_SCALES = [1, 2, 3];
export const DEFAULT_EXPORT_PADDING = 10; // px

export const DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT = 1440;

export const MAX_ALLOWED_FILE_BYTES = 2 * 1024 * 1024;

export const SVG_NS = "http://www.w3.org/2000/svg";

export const ENCRYPTION_KEY_BITS = 128;

export const VERSIONS = {
  excalidraw: 2,
  excalidrawLibrary: 2,
} as const;

export const BOUND_TEXT_PADDING = 5;
export const ARROW_LABEL_WIDTH_FRACTION = 0.7;
export const ARROW_LABEL_FONT_SIZE_TO_MIN_WIDTH_RATIO = 11;
export const TASK_LABEL_FONT_SIZE_TO_MIN_WIDTH_RATIO = 16; // CHANGED:ADD 2024/02/01 #1510
export const MILESTONE_LABEL_MIN_WIDTH = 300; // CHANGED:ADD 2024/02/01 #1510
export const LINK_LABEL_MIN_WIDTH = 100; // CHANGED:ADD 2024/02/01 #1510

export const CONTEXT_MENU_ITEM_HEIGHT = 30; //CHANGED:ADD 2024-0310 #1740

export const VERTICAL_ALIGN = {
  TOP: "top",
  MIDDLE: "middle",
  BOTTOM: "bottom",
};

export const TEXT_ALIGN = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

// CHANGED:ADD 2024/02/01 #1510
export const TEXT_DIRECTION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};
// CHANGED:ADD 2024/03/11 #1749
export const POINTER_DIRECTION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const ELEMENT_READY_TO_ERASE_OPACITY = 20;

// Radius represented as 25% of element's largest side (width/height).
// Used for LEGACY and PROPORTIONAL_RADIUS algorithms, or when the element is
// below the cutoff size.
export const DEFAULT_PROPORTIONAL_RADIUS = 0.25;
// Fixed radius for the ADAPTIVE_RADIUS algorithm. In pixels.
export const DEFAULT_ADAPTIVE_RADIUS = 32;
// roundness type (algorithm)
export const ROUNDNESS = {
  // Used for legacy rounding (rectangles), which currently works the same
  // as PROPORTIONAL_RADIUS, but we need to differentiate for UI purposes and
  // forwards-compat.
  LEGACY: 1,

  // Used for linear elements & diamonds
  PROPORTIONAL_RADIUS: 2,

  // Current default algorithm for rectangles, using fixed pixel radius.
  // It's working similarly to a regular border-radius, but attemps to make
  // radius visually similar across differnt element sizes, especially
  // very large and very small elements.
  //
  // NOTE right now we don't allow configuration and use a constant radius
  // (see DEFAULT_ADAPTIVE_RADIUS constant)
  ADAPTIVE_RADIUS: 3,
} as const;

/** key containt id of precedeing elemnt id we use in reconciliation during
 * collaboration */
export const PRECEDING_ELEMENT_KEY = "__precedingElement__";

export const ROUGHNESS = {
  architect: 0,
  artist: 1,
  cartoonist: 2,
} as const;

export const STROKE_WIDTH = {
  thin: 1,
  bold: 2,
  extraBold: 4,
} as const;

export const DEFAULT_ELEMENT_PROPS: {
  strokeColor: ExcalidrawElement["strokeColor"];
  backgroundColor: ExcalidrawElement["backgroundColor"];
  fillStyle: ExcalidrawElement["fillStyle"];
  strokeWidth: ExcalidrawElement["strokeWidth"];
  strokeStyle: ExcalidrawElement["strokeStyle"];
  roughness: ExcalidrawElement["roughness"];
  opacity: ExcalidrawElement["opacity"];
  locked: ExcalidrawElement["locked"];
} = {
  strokeColor: oc.black,
  backgroundColor: "transparent",
  fillStyle: "hachure",
  strokeWidth: 1,
  strokeStyle: "solid",
  roughness: 0, // CHANGED:UPDATE 2022-10-20 #40
  opacity: 100,
  locked: false,
};

// CHANGED:ADD 2023-01-23 #391
export const PRIORITY: {
  [key in
    | ExcalidrawElement["type"]
    | "job-text"
    | "text-cp"
    | "task-cp"
    | "milestone-cp"
    | "link-cp"
  ]: number;
} = {
  "rectangle": 1, // background, job background, job panel, job line, calendar bg
  "image": 1,
  "diamond": 2,
  "ellipse": 2,
  "freedraw": 2,
  "arrow": 2,
  "line": 2,
  "selection": 2,
  "link": 10,
  "link-cp": 11,
  "task": 20,
  "task-cp": 21,
  "text": 22,
  "text-cp": 23,
  "milestone": 24,
  "milestone-cp": 25,
  "comment": 26, // CHANGED:ADD 2023-12-23 #1138
  "job": 60,
  "job-text": 61,
}

// canvas
export const CANVAS_MARGIN_BOTTOM = 500; // CHANGED:UPDATE 2023/09/01 #978
export const CANVAS_MARGIN_RIGHT = 550; // CHANGED:UPDATE 2023/01/27 #529
export const CANVAS_EXPORT_MAX_WIDTH_OR_HEIGHT = 16000; // CHANGED:ADD 2024/03/12 #1776
export const BEFORE_PRINT_MONTH = 0; // CHANGED:ADD 2024/03/15 #1776
export const AFTER_PRINT_MONTH = 3; // CHANGED:ADD 2024/03/15 #1776

// calendar
export const CANVAS_HEADER_HEIGHT = 128;
export const CALENDAR_MONTH_OFFSET_Y = 20;
export const CALENDAR_HEIGHT = CANVAS_HEADER_HEIGHT - CALENDAR_MONTH_OFFSET_Y;
export const CALENDAR_MONTH_TEXT_OFFSET_Y = 28;
export const CALENDAR_DATE_OFFSET_Y = 62;
export const CALENDAR_DATE_TEXT_OFFSET_Y = 70;
export const CALENDAR_DAY_OF_WEEK_OFFSET_Y = 94;
export const CALENDAR_DAY_OF_WEEK_TEXT_OFFSET_Y = 92;
export const CALNEDAR_FONT_SIZE = 14;
export const NUMBER_OF_MONTHS = 3;

// job
export const GRID_OF_LEFT_MARGIN = 8;
export const JOB_ELEMENTS_WIDTH = 256;
export const JOB_ROWS = 4;
export const NUMBER_OF_JOBS = 5;
export const DEFAULT_JOB_HEIGHT = GRID_SIZE * JOB_ROWS;
export const COMPRESSED_JOB_HEIGHT = GRID_SIZE;
export const JOB_ACCORDION_BUTTON_SIZE = 10;
export const JOB_ACCORDION_BUTTON_ROUND = 3;

// task
export const TASK_TO_LINK_GEN_POSITION = 15;
export const DEFAULT_TASK_FONT_SIZE = 16;
export const BOUNDTEXT_OFFSET_X_HORIZONTAL = 20;
export const BOUNDTEXT_OFFSET_X_VERTICAL = 5;
export const BOUNDTEXT_OFFSET_Y = 24;

// comment
export const COMMENT_AUTO_SCROLL_X_CENTER_OFFSET = 250;
export const COMMENT_OFFSET_X = -12;
export const COMMENT_OFFSET_Y = 12;

// element
// CHANGED:ADD 2023-03-08 #759
export const DEPENDENCY_ELEMENT_SHADOW_BLUR = 9;
export const DEPENDENCY_ELEMENT_SHADOW_OFFSET_X = 1;
export const DEPENDENCY_ELEMENT_SHADOW_OFFSET_Y = 2;
export const NON_DEPENDENCY_ELEMENT_OPACITY = 0.2;
export const ELEMENT_CLOSED_OPACITY = 0.5;

export const CLITICAL_PATH_TASK_LINE_WIDTH = 14;
export const CLOSED_TASK_LINE_WIDTH = 1.5;
export const LINK_LINE_WIDTH = 2;
export const CLOSED_LINK_LINE_WIDTH = 1.5;
export const DRAG_LINK_AUTO_SCROLL = 8; // CHANGED:ADD 2024-3-9 #1750
export const DRAG_TASK_AUTO_SCROLL = 16; // CHANGED:ADD 2024-3-12 #1800

// CHANGED:ADD 2024-12-05 #1327 src/conpath/views/projects/Project.scss
export const TOOL_BAR_WIDTH = 60;
// CHANGED:UPDATE 2024-08-27 #2174
export const HEADER_HEIGHT = 50;

export const STROKE_COLORS = [
  "#546E7A",
  "#6E4E45",
  "#DF4546",
  "#DD559E",
  "#7D53C1",
  "#4F3E93",
  "#3D4DAA",
  "#316CB1",
  "#00A371",
  "#78C654",
  "#CDDC39",
  "#F4DA50",
  "#F49C45",
  "#E8733E",
];

export const BACKGROUND_COLORS = [
  "#A9B6BC",
  "#B6A5A1",
  "#F3B89E",
  "#F9CDA1",
  "#F9ECA7",
  "#E5ED9C",
  "#BAE2A9",
  "#7FD1B7",
  "#96B5D8",
  "#A0A7D7",
  "#A69EC9",
  "#C1AAE2",
  "#EEA9CE",
  "#EFA2A2",
];