import { AppState, Point } from "src/excalidraw/types";
import { ElementsMap, NonDeletedExcalidrawElement } from "../../element/types";

import { JOB_ACCORDION_BUTTON_SIZE } from "src/excalidraw/constants";
import { Bounds } from "../../element/bounds";
import { getElementAbsoluteCoords } from "../../element";

export const getJobAccordionHandleFromCoords = (
  [x1, y1, x2, y2]: Bounds,
  appState: AppState,
): [x: number, y: number, width: number, height: number] => {
  const size = JOB_ACCORDION_BUTTON_SIZE;
  const linkWidth = size / 2;
  const linkHeight = size / 3 * 2;

  // Same as `ne` resize handle
  const x = x2 - 30;
  const y = (y1 + y2) / 2;

  return [
    x + linkWidth / 2,
    y,
    linkWidth,
    linkHeight,
  ];
};

export const isPointHittingJobAccordionIcon = (
  element: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  appState: AppState,
  [x, y]: Point,) => {
  const threshold = 8;
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);

  const [iconX, iconY, iconWidth, iconHeight] = getJobAccordionHandleFromCoords(
    [x1, y1, x2, y2],
    appState,
  );
  const hitLink =
    x > iconX - threshold &&
    x < iconX + threshold + iconWidth &&
    y > iconY - threshold &&
    y < iconY + iconHeight + threshold;
  return hitLink;
};
