import { ExcalidrawElement } from "src/excalidraw/element/types";
import { AppClassProperties } from "src/excalidraw/types";


/**
 * 強制的にcanvasのonPointerDown と onPointerUpハンドラーを呼び出す関数
 * @param selectedElement 
 * @param canvas 
 * @returns 
 */
export const runPointerHandlerOnCanvas = (selectedElement: ExcalidrawElement, canvas: AppClassProperties["interactiveCanvas"]) => {
  if (!canvas) return;

  const pointerDownEvent = new PointerEvent('pointerdown', {
    // Pointer event properties
    button: 0,
    buttons: 1,
    bubbles: true,
    cancelable: true,
    isPrimary: true,
    detail: 0,
    movementX: 0,
    movementY: 0,
    tiltX: 0,
    tiltY: 0,
    width: 1,
    height: 1,
    metaKey: false,
    clientX: selectedElement.x,
    clientY: selectedElement.y,
    pointerType: "mouse",
    pressure: 0,
    // You can add more properties as needed
 });

  canvas.dispatchEvent(pointerDownEvent);
  const pointerUpEvent = new PointerEvent('pointerup', {
    // Pointer event properties
    button: 0,
    buttons: 0,
    bubbles: true,
    cancelable: true,
    isPrimary: true,
    detail: 0,
    movementX: 0,
    movementY: 0,
    tiltX: 0,
    tiltY: 0,
    width: 1,
    height: 1,
    metaKey: false,
    clientX: selectedElement.x,
    clientY: selectedElement.y,
    pointerType: "mouse",
    pressure: 0,
    // You can add more properties as needed
 });

  canvas.dispatchEvent(pointerUpEvent);
};