import {
  NonDeleted,
  NonDeletedExcalidrawElement,
  ExcalidrawElement,
  ElementsMap,
  NonDeletedSceneElementsMap,
} from "../../element/types";
import {
  ExcalidrawLinkElement,
  ExcalidrawTaskElement,
  ExcalidrawBindableElementEx,
  PointBindingEx,
  ExcalidrawNodeElement,
  ExcalidrawJobElement,
  ExcalidrawMilestoneElement,
} from "./types"; // from extensions
import { getElementAtPosition } from "src/excalidraw/scene";
import { AppClassProperties, AppState, Point } from "src/excalidraw/types";
import {
  isBindableElementEx,
  isBindingElementEx,
  isLinkElement,
  isMilestoneElement,
  isTaskElement,
} from "./typeChecks"; // from extensions
import {
  bindingBorderTestEx,
  distanceToBindableElementEx,
  maxBindingGapEx,
  determineFocusDistanceEx,
} from "./collision"; // from extensions
import { mutateElement } from "../../element/mutateElement";
import Scene from "src/excalidraw/scene/Scene";
import { LinkElementEditor } from "./linkElementEditor"; // from extensions
import { TaskElementEditor } from "./taskElementEditor"; // from extensions
import { arrayToMap, tupleToCoors } from "src/excalidraw/utils";
import { getBoundTextElement, handleBindTextResize } from "../../element/textElement";
import { getElementAbsoluteCoords, isTextElement } from "../../element";
import Calendar from "../calendar";
import { POINTER_DIRECTION } from "../../constants";

export type SuggestedBindingEx =
  | NonDeleted<ExcalidrawBindableElementEx>
  | SuggestedPointBindingEx;

export type SuggestedPointBindingEx = [
  NonDeleted<ExcalidrawLinkElement>,
  "start" | "end" | "both",
  NonDeleted<ExcalidrawBindableElementEx>,
];

export const isBindingEnabledEx = (appState: AppState): boolean => {
  return appState.isBindingEnabledEx;
};

const getNonDeletedElements = (
  scene: Scene,
  ids: readonly ExcalidrawElement["id"][],
): NonDeleted<ExcalidrawElement>[] => {
  const result: NonDeleted<ExcalidrawElement>[] = [];
  ids.forEach((id) => {
    const element = scene.getNonDeletedElement(id);
    if (element != null) {
      result.push(element);
    }
  });
  return result;
};

export const bindOrUnbindLinkElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startBindingElement: ExcalidrawBindableElementEx | null | "keep",
  endBindingElement: ExcalidrawBindableElementEx | null | "keep",
  elementsMap: NonDeletedSceneElementsMap,
): void => {
  const boundToElementIds: Set<ExcalidrawBindableElementEx["id"]> = new Set();
  const unboundFromElementIds: Set<ExcalidrawBindableElementEx["id"]> =
    new Set();
  bindOrUnbindLinkElementEdge(
    linkElement,
    startBindingElement,
    endBindingElement,
    "start",
    boundToElementIds,
    unboundFromElementIds,
    elementsMap,
  );
  bindOrUnbindLinkElementEdge(
    linkElement,
    endBindingElement,
    startBindingElement,
    "end",
    boundToElementIds,
    unboundFromElementIds,
    elementsMap,
  );

  const onlyUnbound = Array.from(unboundFromElementIds).filter(
    (id) => !boundToElementIds.has(id),
  );

  getNonDeletedElements(Scene.getScene(linkElement)!, onlyUnbound).forEach(
    (element) => {
      // CHANGED:ADD 2022-11-16 #114
      if (isBindableElementEx(element)) {
        mutateElement(element, {
          boundElementsEx: element.boundElementsEx?.filter(
            (element) => element.type !== "link" || element.id !== linkElement.id,
          ),
        });
        // CHANGED:ADD 2022-12-15 #332
        const boundElements = element.boundElementsEx
          ?.filter((boundElement) => boundElement.type === "link")
          .map(
            (boundElement) =>
              Scene.getScene(element)?.getNonDeletedElement(
                boundElement.id,
              ) as ExcalidrawLinkElement,
          );

        const startBound = boundElements?.find(
          (boundElement) => boundElement.startBinding?.elementId === linkElement.startBinding?.elementId);
        const endBound = boundElements?.find(
          (boundElement) => boundElement.endBinding?.elementId === linkElement.endBinding?.elementId);

        mutateElement(element, {
          prevDependencies: linkElement.startBinding && !startBound
            ? element.prevDependencies?.filter(
              (dependElementId) =>
                dependElementId !== linkElement.startBinding?.elementId,
            )
            : element.prevDependencies || [],
          // CHANGED:ADD 2022-11-27 #208
          nextDependencies: linkElement.endBinding && !endBound
            ? element.nextDependencies?.filter(
              (dependElementId) =>
                dependElementId !== linkElement.endBinding?.elementId,
            )
            : element.nextDependencies || [],
        });

        // CHANGED:ADD 2022-11-27 #208
        if (
          startBindingElement &&
          startBindingElement === "keep" &&
          linkElement.startBinding
        ) {
          const keepStartBindingElement = Scene.getScene(
            linkElement,
          )?.getNonDeletedElement(linkElement.startBinding.elementId);
          if (
            keepStartBindingElement &&
            isBindableElementEx(keepStartBindingElement)
          ) {
            mutateElement(keepStartBindingElement, {
              nextDependencies:
                keepStartBindingElement.nextDependencies?.filter(
                  (dependElementId) => dependElementId !== element.id,
                ),
            });
          }
        }
        if (
          endBindingElement &&
          endBindingElement === "keep" &&
          linkElement.endBinding
        ) {
          const keepEndBindingElement = Scene.getScene(
            linkElement,
          )?.getNonDeletedElement(linkElement.endBinding.elementId);
          if (
            keepEndBindingElement &&
            isBindableElementEx(keepEndBindingElement)
          ) {
            mutateElement(keepEndBindingElement, {
              prevDependencies: keepEndBindingElement.prevDependencies?.filter(
                (dependElementId) => dependElementId !== element.id,
              ),
            });
          }
        }
      }
    },
  );
};

const bindOrUnbindLinkElementEdge = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  bindableElement: ExcalidrawBindableElementEx | null | "keep",
  otherEdgeBindableElement: ExcalidrawBindableElementEx | null | "keep",
  startOrEnd: "start" | "end",
  // Is mutated
  boundToElementIds: Set<ExcalidrawBindableElementEx["id"]>,
  // Is mutated
  unboundFromElementIds: Set<ExcalidrawBindableElementEx["id"]>,
  elementsMap: NonDeletedSceneElementsMap,
): void => {
  if (bindableElement !== "keep") {
    if (bindableElement != null) {
      // Don't bind if we're trying to bind or are already bound to the same
      // element on the other edge already ("start" edge takes precedence).
      if (
        otherEdgeBindableElement == null ||
        (otherEdgeBindableElement === "keep"
          ? !isLinkElementSimpleAndAlreadyBoundOnOppositeEdge(
            linkElement,
            bindableElement,
            startOrEnd,
          )
          : startOrEnd === "start" ||
          otherEdgeBindableElement.id !== bindableElement.id)
      ) {
        // CHANGED:ADD 2022-11-22 #184
        const unbound = unbindLinkElement(linkElement, startOrEnd);
        if (unbound != null) {
          unboundFromElementIds.add(unbound);
        }

        // CHANGED:UPDATE 2022-11-22 #163
        // bindLinkElement(linkElement, bindableElement, startOrEnd);
        // boundToElementIds.add(bindableElement.id);
        if (
          LinkElementEditor.getPointAtIndexGlobalCoordinates(
            linkElement,
            0,
            elementsMap,
          )[0] <=
          LinkElementEditor.getPointAtIndexGlobalCoordinates(
            linkElement,
            -1,
            elementsMap,
          )[0]
        ) {
          bindLinkElement(
            linkElement,
            bindableElement,
            startOrEnd,
            elementsMap,
          );
          boundToElementIds.add(bindableElement.id);
        }
      }
    } else {
      const unbound = unbindLinkElement(linkElement, startOrEnd);
      if (unbound != null) {
        unboundFromElementIds.add(unbound);
      }
    }
  }
};

export const bindOrUnbindSelectedElementsEx = (
  elements: NonDeleted<ExcalidrawElement>[],
  app: AppClassProperties,
): void => {
  elements.forEach((element) => {
    if (isBindingElementEx(element)) {
      bindOrUnbindLinkElement(
        element,
        getElligibleElementForBindingElement(element, "start", app),
        getElligibleElementForBindingElement(element, "end", app),
        app.scene.getNonDeletedElementsMap(),
      );
    } else if (isBindableElementEx(element)) {
      maybeBindBindableElement(
        element,
        app.scene.getNonDeletedElementsMap(),
        app,
      );
    }
  });
};

const maybeBindBindableElement = (
  bindableElement: NonDeleted<ExcalidrawBindableElementEx>,
  elementsMap: NonDeletedSceneElementsMap,
  app: AppClassProperties,
): void => {
  getElligibleElementsForBindableElementAndWhere(bindableElement, app).forEach(
    ([linkElement, where]) =>
      bindOrUnbindLinkElement(
        linkElement,
        where === "end" ? "keep" : bindableElement,
        where === "start" ? "keep" : bindableElement,
        elementsMap,
      ),
  );
};

export const maybeBindLinkElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  appState: AppState,
  pointerCoords: { x: number; y: number },
  app: AppClassProperties,
): void => {
  if (appState.startBoundElementEx != null) {
    bindLinkElement(
      linkElement,
      appState.startBoundElementEx,
      "start",
      app.scene.getNonDeletedElementsMap(),
    );
  }
  const hoveredElement = getHoveredElementForBindingEx(
    pointerCoords,
    app,
    "end", // CHANGED:ADD 2022-11-11 #116
  );
  if (
    hoveredElement != null &&
    !isLinkElementSimpleAndAlreadyBoundOnOppositeEdge(
      linkElement,
      hoveredElement,
      "end",
    ) &&
    // CHANGED:ADD 2022-11-22 #163
    LinkElementEditor.getPointAtIndexGlobalCoordinates(
      linkElement,
      0,
      app.scene.getNonDeletedElementsMap(),
    )[0] <=
    LinkElementEditor.getPointAtIndexGlobalCoordinates(
      linkElement,
      -1,
      app.scene.getNonDeletedElementsMap(),
    )[0] &&
    // CHANGED:ADD 2022-12-9 #260
    (
      linkElement.startBinding &&
      !(arrayToMap(hoveredElement.prevDependencies || []).has(linkElement.startBinding?.elementId))
    ) &&
    linkElement.layer === hoveredElement.layer // CHANGED:ADD 2024-10-7 #2114
  ) {
    bindLinkElement(
      linkElement,
      hoveredElement,
      "end",
      app.scene.getNonDeletedElementsMap(),
    );
  }
};

const bindLinkElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  hoveredElement: ExcalidrawBindableElementEx,
  startOrEnd: "start" | "end",
  elementsMap: NonDeletedSceneElementsMap,
): void => {
  mutateElement(linkElement, {
    [startOrEnd === "start" ? "startBinding" : "endBinding"]: {
      elementId: hoveredElement.id,
      ...calculateFocusAndGap(linkElement, hoveredElement, startOrEnd, elementsMap),
    } as PointBindingEx,
    isClosed: startOrEnd === "start" ? hoveredElement.isClosed : linkElement.isClosed, // CHANGD:ADD 2023/09/28 #1125
  });

  const boundElementsMap = arrayToMap(hoveredElement.boundElementsEx || []);
  if (!boundElementsMap.has(linkElement.id)) {
    mutateElement(hoveredElement, {
      boundElementsEx: (hoveredElement.boundElementsEx?.filter(
        (element) => element.type !== "link" || element.id !== linkElement.id,) || [])
        .concat({
          id: linkElement.id,
          type: "link",
        }),
      // CHANGED:ADD 2022-11-16 #114
      prevDependencies:
        startOrEnd === "end" && linkElement.startBinding
          ? (hoveredElement.prevDependencies?.filter((elementId) =>
            elementId !== linkElement.startBinding?.elementId) || [])
            .concat(linkElement.startBinding.elementId)
          : hoveredElement.prevDependencies || [],
      // CHANGED:ADD 2022-11-27 #208
      nextDependencies:
        startOrEnd === "start" && linkElement.endBinding
          ? (hoveredElement.nextDependencies?.filter((elementId) =>
            elementId !== linkElement.endBinding?.elementId) || [])
            .concat(linkElement.endBinding.elementId)
          : hoveredElement.nextDependencies || [],
    });

    // CHANGED:ADD 2022-11-27 #208
    if (
      linkElement.startBinding &&
      linkElement.startBinding.elementId !== hoveredElement.id
    ) {
      const startBindingElement = Scene.getScene(
        hoveredElement,
      )?.getNonDeletedElement(linkElement.startBinding.elementId);
      if (startBindingElement && isBindableElementEx(startBindingElement) &&
        !(arrayToMap(startBindingElement.nextDependencies || []).has(hoveredElement.id))) {
        mutateElement(startBindingElement, {
          boundElementsEx: (startBindingElement.boundElementsEx?.filter(
            (element) => element.type !== "link" || element.id !== linkElement.id,) || [])
            .concat({
              id: linkElement.id,
              type: "link",
            }),
          nextDependencies:
            (startBindingElement.nextDependencies?.filter((elementId) =>
              elementId !== hoveredElement.id) || [])
              .concat(hoveredElement.id),
        });
      }
    }
    if (
      linkElement.endBinding &&
      linkElement.endBinding.elementId !== hoveredElement.id
    ) {
      const endBindingElement = Scene.getScene(
        hoveredElement,
      )?.getNonDeletedElement(linkElement.endBinding.elementId);
      if (endBindingElement && isBindableElementEx(endBindingElement) &&
        !(arrayToMap(endBindingElement.prevDependencies || []).has(hoveredElement.id))) {
        mutateElement(endBindingElement, {
          boundElementsEx: (endBindingElement.boundElementsEx?.filter(
            (element) => element.type !== "link" || element.id !== linkElement.id,) || [])
            .concat({
              id: linkElement.id,
              type: "link",
            }),
          prevDependencies:
            (endBindingElement.prevDependencies?.filter((elementId) =>
              elementId !== hoveredElement.id) || [])
              .concat(hoveredElement.id),
        });
      }
    }
  }
};

// Don't bind both ends of a simple segment
const isLinkElementSimpleAndAlreadyBoundOnOppositeEdge = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  bindableElement: ExcalidrawBindableElementEx,
  startOrEnd: "start" | "end",
): boolean => {
  const otherBinding =
    linkElement[startOrEnd === "start" ? "endBinding" : "startBinding"];
  return isLinkElementSimpleAndAlreadyBound(
    linkElement,
    otherBinding?.elementId,
    bindableElement,
  );
};

export const isLinkElementSimpleAndAlreadyBound = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  alreadyBoundToId: ExcalidrawBindableElementEx["id"] | undefined,
  bindableElement: ExcalidrawBindableElementEx,
): boolean => {
  return (
    alreadyBoundToId === bindableElement.id && linkElement.points.length < 3
  );
};

export const unbindLinkElements = (
  elements: NonDeleted<ExcalidrawElement>[],
  elementsMap: NonDeletedSceneElementsMap,
): void => {
  elements.forEach((element) => {
    if (isBindingElementEx(element)) {
      bindOrUnbindLinkElement(element, null, null, elementsMap);
    }
  });
};

const unbindLinkElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startOrEnd: "start" | "end",
): ExcalidrawBindableElementEx["id"] | null => {
  const field = startOrEnd === "start" ? "startBinding" : "endBinding";
  const binding = linkElement[field];
  if (binding == null) {
    return null;
  }
  mutateElement(linkElement, { [field]: null });
  return binding.elementId;
};

export const getHoveredElementForBindingEx = (
  pointerCoords: {
    x: number;
    y: number;
  },
  app: AppClassProperties,
  startOrEnd: "start" | "end", // CHANGED:ADD 2022-11-11 #116
): NonDeleted<ExcalidrawBindableElementEx> | null => {
  const hoveredElement = getElementAtPosition(
    app.scene.getNonDeletedElements(),
    (element) =>
      isBindableElementEx(element, false) &&
      bindingBorderTestEx(
        element,
        pointerCoords,
        app,
        startOrEnd, // CHANGED:ADD 2022-11-11 #116
      ),
  );
  return hoveredElement as NonDeleted<ExcalidrawBindableElementEx> | null;
};

const calculateFocusAndGap = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  hoveredElement: ExcalidrawBindableElementEx,
  startOrEnd: "start" | "end",
  elementsMap: NonDeletedSceneElementsMap,
): { focus: number; gap: number } => {
  const direction = startOrEnd === "start" ? -1 : 1;
  const edgePointIndex = direction === -1 ? 0 : linkElement.points.length - 1;
  const adjacentPointIndex = edgePointIndex - direction;
  const edgePoint = LinkElementEditor.getPointAtIndexGlobalCoordinates(
    linkElement,
    edgePointIndex,
    elementsMap,
  );
  const adjacentPoint = LinkElementEditor.getPointAtIndexGlobalCoordinates(
    linkElement,
    adjacentPointIndex,
    elementsMap,
  );
  return {
    focus: determineFocusDistanceEx(hoveredElement, adjacentPoint, edgePoint, elementsMap),
    gap: Math.max(
      1,
      distanceToBindableElementEx(
        hoveredElement,
        elementsMap,
        edgePoint,
        startOrEnd === "start" ? "end" : "start", // CHANGED:ADD 2022-11-11 #116
      ),
    ),
  };
};

// Supports translating, rotating and scaling `changedElement` with bound
// link elements.
// Because scaling involves moving the focus points as well, it is
// done before the `changedElement` is updated, and the `newSize` is passed
// in explicitly.
export const updateBoundElementsEx = (
  changedElement: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  appState: AppState, //CHANGED:ADD 2022-11-01 #79
  calendar: Calendar, //CHANGED:ADD 2022/01/19 #390
  options?: {
    simultaneouslyUpdated?: readonly ExcalidrawElement[];
    newSize?: { width: number; height: number };
    isVisible?: boolean; //CHANGED: ADD 2023-03-04 #740
    jobElement?: ExcalidrawJobElement; //CHANGED: ADD 2023-03-04 #740
  },
) => {
  const boundElementsEx = isBindableElementEx(changedElement) ? changedElement.boundElementsEx ?? [] : [];
  const boundLinkElements = (boundElementsEx).filter(
    (el) => el.type === "link",
  );
  if (boundLinkElements.length === 0) {
    return;
  }
  const { newSize, simultaneouslyUpdated } = options ?? {};
  const simultaneouslyUpdatedElementIds = getSimultaneouslyUpdatedElementIds(
    simultaneouslyUpdated,
  );
  const scene = Scene.getScene(changedElement)!;
  getNonDeletedElements(
    scene,
    boundLinkElements.map((el) => el.id),
  ).forEach((element) => {
    if (!isLinkElement(element)) {
      return;
    }

    const bindableElement = changedElement as ExcalidrawBindableElementEx;
    // In case the boundElements are stale
    if (!doesNeedUpdate(element, bindableElement)) {
      return;
    }
    const startBinding = maybeCalculateNewGapWhenScaling(
      bindableElement,
      element.startBinding,
      newSize,
    );
    const endBinding = maybeCalculateNewGapWhenScaling(
      bindableElement,
      element.endBinding,
      newSize,
    );
    // `linkElement` is being moved/scaled already, just update the binding
    if (simultaneouslyUpdatedElementIds.has(element.id)) {
      mutateElement(element, { startBinding, endBinding });
      return;
    }
    updateBoundPoint(
      element,
      "start",
      startBinding,
      changedElement as ExcalidrawBindableElementEx,
      elementsMap,
      appState.gridSize, //CHANGED:ADD 2022-11-01 #79
      options?.isVisible //CHANGED: ADD 2023-03-04 #740
    );
    updateBoundPoint(
      element,
      "end",
      endBinding,
      changedElement as ExcalidrawBindableElementEx,
      elementsMap,
      appState.gridSize, //CHANGED:ADD 2022-11-01 #79
      options?.isVisible //CHANGED: ADD 2023-03-04 #740
    );

    // CHANGED:ADD 2022/01/19 #390
    const startDate = calendar.getPointDate(element.x);
    const endDate = calendar.getPointDate(element.x + element.width);
    const newDuration = calendar.getDuration(startDate, endDate);
    if (element.duration != newDuration) {
      mutateElement(element, {
        duration: newDuration,
      });
    }

    // CHANGED:ADD 2024-04-15 #1917
    if (isLinkElement(element) && element.startBinding && element.endBinding) {
      const startBindingElement =
        scene.getNonDeletedElementsMap().get(element.startBinding.elementId);
      const endBindingElement =
        scene.getNonDeletedElementsMap().get(element.endBinding.elementId);
      if (isBindableElementEx(startBindingElement) && isBindableElementEx(endBindingElement)) {
        mutateElement(startBindingElement, {
          freeFloats: (startBindingElement.freeFloats?.filter(
            (float) => float.id !== endBindingElement.id) || [])
            .concat({
              id: element.endBinding.elementId,
              type: endBindingElement.type,
              duration: newDuration,
            })
        });
      }
    }

    const boundText = getBoundTextElement(
      element,
      scene.getNonDeletedElementsMap(),
    );
    if (boundText) {
      // CHANGED:ADD 2023-03-15 #740
      const isVisible = options?.isVisible;
      mutateElement(boundText, {
        isVisible,
      })

      handleBindTextResize(element, scene.getNonDeletedElementsMap(), false);
    }
  });
};

// CHANGED:ADD 2022-11-14 #137
export const updateBoundElementEx = (
  changedElement: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  appState: AppState,
  startOrEnd: "start" | "end",
  calendar: Calendar, // CHANGED:ADD 2022/01/19 #390
) => {
  const boundElementsEx = isBindableElementEx(changedElement) ? changedElement.boundElementsEx ?? [] : [];
  const boundLinkElements = (boundElementsEx).filter(
    (el) => el.type === "link",
  );
  if (boundLinkElements.length === 0) {
    return;
  }
  const scene = Scene.getScene(changedElement)!;
  getNonDeletedElements(
    scene,
    boundLinkElements.map((el) => el.id),
  ).forEach((element) => {
    if (!isLinkElement(element)) {
      return;
    }

    const bindableElement = changedElement as ExcalidrawBindableElementEx;
    // In case the boundElements are stale
    if (!doesNeedUpdate(element, bindableElement)) {
      return;
    }

    const existingBinding =
      element[startOrEnd === "start" ? "startBinding" : "endBinding"];

    if (existingBinding?.elementId !== changedElement.id) {
      return;
    }

    updateBoundPoint(
      element,
      startOrEnd,
      existingBinding,
      changedElement as ExcalidrawBindableElementEx,
      elementsMap,
      appState.gridSize,
    );

    // CHANGED:ADD 2022/01/19 #390
    const boundText = getBoundTextElement(
      element,
      scene.getNonDeletedElementsMap(),
    );
    if (boundText) {
      const startDate = calendar.getPointDate(element.x);
      const endDate = calendar.getPointDate(element.x + element.width);
      const newDuration = calendar.getDuration(startDate, endDate);
      if (element.duration != newDuration) {
        mutateElement(element, {
          duration: newDuration,
        });

        // CHANGED:ADD 2024-04-15 #1917
        if (isLinkElement(element) && element.startBinding && element.endBinding) {
          const startBindingElement =
            scene.getNonDeletedElementsMap().get(element.startBinding.elementId);
          const endBindingElement =
            scene.getNonDeletedElementsMap().get(element.endBinding.elementId);
          if (isBindableElementEx(startBindingElement) && isBindableElementEx(endBindingElement)) {
            mutateElement(startBindingElement, {
              freeFloats: (startBindingElement.freeFloats?.filter(
                (float) => float.id !== endBindingElement.id) || [])
                .concat({
                  id: element.endBinding.elementId,
                  type: endBindingElement.type,
                  duration: newDuration,
                })
            });
          }
        }
      }
      handleBindTextResize(element, scene.getNonDeletedElementsMap(), false);
    }
  });
};

const doesNeedUpdate = (
  boundElement: NonDeleted<ExcalidrawLinkElement>,
  changedElement: ExcalidrawBindableElementEx,
) => {
  return (
    boundElement.startBinding?.elementId === changedElement.id ||
    boundElement.endBinding?.elementId === changedElement.id
  );
};

const getSimultaneouslyUpdatedElementIds = (
  simultaneouslyUpdated: readonly ExcalidrawElement[] | undefined,
): Set<ExcalidrawElement["id"]> => {
  return new Set((simultaneouslyUpdated || []).map((element) => element.id));
};

const updateBoundPoint = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startOrEnd: "start" | "end",
  binding: PointBindingEx | null | undefined,
  bindableElement: ExcalidrawBindableElementEx,
  elementsMap: ElementsMap,
  gridSize: number | null, //CHANGED:ADD 2022-11-01 #79
  isVisible?: boolean, //CHANGED: ADD 2023-03-04 #740
): void => {
  if (
    binding == null ||
    // We only need to update the other end if this is a 2 point line element
    (binding.elementId !== bindableElement.id && linkElement.points.length > 2)
  ) {
    return;
  }
  const bindingElement = Scene.getScene(linkElement)!.getElement(
    binding.elementId,
  ) as ExcalidrawBindableElementEx | null;
  if (bindingElement == null) {
    // We're not cleaning up after deleted elements atm., so handle this case
    return;
  }
  const direction = startOrEnd === "start" ? -1 : 1;
  const edgePointIndex = direction === -1 ? 0 : linkElement.points.length - 1;
  const midPointIndex = edgePointIndex + -1 * direction;
  const cornerPointIndex = midPointIndex + -1 * direction;

  // CHAGED:UPDATE 2022-11-14 #135
  // const adjacentPointIndex = edgePointIndex - direction;
  // const adjacentPoint = LinkElementEditor.getPointAtIndexGlobalCoordinates(
  //   linkElement,
  //   adjacentPointIndex,
  // );
  // const focusPointAbsolute = determineFocusPointEx(
  //   bindingElement,
  //   binding.focus,
  //   adjacentPoint,
  // );
  // let newEdgePoint;
  // // The link element was not originally pointing inside the bound shape,
  // // we can point directly at the focus point
  // if (binding.gap === 0) {
  //   newEdgePoint = focusPointAbsolute;
  // } else {
  //   const intersections = intersectElementWithLineEx(
  //     bindingElement,
  //     adjacentPoint,
  //     focusPointAbsolute,
  //     binding.gap,
  //   );
  //   if (intersections.length === 0) {
  //     // This should never happen, since focusPoint should always be
  //     // inside the element, but just in case, bail out
  //     newEdgePoint = focusPointAbsolute;
  //   } else {
  //     // Guaranteed to intersect because focusPoint is always inside the shape
  //     newEdgePoint = intersections[0];
  //   }
  // }
  // LinkElementEditor.movePoints(
  //   linkElement,
  //   [
  //     {
  //       index: edgePointIndex,
  //       point: LinkElementEditor.pointFromAbsoluteCoords(
  //         linkElement,
  //         newEdgePoint,
  //       ),
  //     },
  //   ],
  //   { [startOrEnd === "start" ? "startBinding" : "endBinding"]: binding },
  // );

  //CHANGED:ADD 2022-12-7 #157
  let absoluteCoords: readonly [number, number] = [0, 0];
  if (isTaskElement(bindingElement)) {
    absoluteCoords = TaskElementEditor.getPointAtIndexGlobalCoordinates(
      bindingElement,
      startOrEnd === "start" ? -1 : 0,
      elementsMap,
    );
  } else if (isMilestoneElement(bindingElement)) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(bindingElement, elementsMap);
    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    absoluteCoords = [cx, cy];
  }

  //CHANGED:UPDATE 2022-11-16 #90
  const newEdgePoint = LinkElementEditor.pointFromAbsoluteCoords(
    linkElement,
    absoluteCoords, // CHANGED:UPDATE 2022-12-7 #157
    elementsMap,
  );

  if (linkElement.pointerDirection === POINTER_DIRECTION.VERTICAL) {
    const diff = startOrEnd === "start" ? newEdgePoint[1] : 0;
    let newMidPoint: Point = [
      newEdgePoint[0],
      linkElement.points[midPointIndex][1] - diff,
    ];

    const newMidPoints = [{ index: midPointIndex, point: newMidPoint }];

    // const isMoveCornerPoint =
    //   (startOrEnd === "start" &&
    //     linkElement.points[midPointIndex][1] - diff === 0) ||
    //   (startOrEnd === "end" && newEdgePoint[1] - newMidPoint[1] === 0)
    //     ? true
    //     : false;

    // if (isMoveCornerPoint) {
    //   if (startOrEnd === "start") {
    //     newMidPoint = [0, 0];
    //     newMidPoints[0].point = newMidPoint;

    //     const newCornerPoint: Point = [
    //       linkElement.points[linkElement.points.length - 1][0],
    //       newMidPoint[1],
    //     ];
    //     newMidPoints.push({ index: cornerPointIndex, point: newCornerPoint });
    //   } else if (startOrEnd === "end") {
    //     newMidPoint = newEdgePoint;
    //     newMidPoints[0].point = newMidPoint;

    //     const newCornerPoint: Point = [
    //       linkElement.points[cornerPointIndex][0],
    //       newMidPoint[1],
    //     ];
    //     newMidPoints.push({ index: cornerPointIndex, point: newCornerPoint });
    //   }
    // }

    LinkElementEditor.movePoints(
      linkElement,
      [
        {
          index: edgePointIndex,
          point: newEdgePoint,
        },
        ...newMidPoints,
      ],
      { isVisible: isVisible },
    );
  } else {
    const diff = startOrEnd === "start" ? newEdgePoint[0] : 0;
    let newMidPoint: Point = [
      linkElement.points[midPointIndex][0] - diff,
      newEdgePoint[1],
    ];
    const newMidPoints = [{ index: midPointIndex, point: newMidPoint }];

    const isMoveCornerPoint =
      (startOrEnd === "start" &&
        linkElement.points[midPointIndex][0] - diff <= 0) ||
      (startOrEnd === "end" && newEdgePoint[0] - newMidPoint[0] <= 0)
        ? true
        : false;

    if (isMoveCornerPoint) {
      if (startOrEnd === "start") {
        newMidPoint = [0, 0];
        newMidPoints[0].point = newMidPoint;

        const newCornerPoint: Point = [
          newMidPoint[0],
          linkElement.points[linkElement.points.length - 1][1],
        ];
        newMidPoints.push({ index: cornerPointIndex, point: newCornerPoint });
      } else if (startOrEnd === "end") {
        newMidPoint = newEdgePoint;
        newMidPoints[0].point = newMidPoint;

        const newCornerPoint: Point = [
          newMidPoint[0],
          linkElement.points[cornerPointIndex][1],
        ];
        newMidPoints.push({ index: cornerPointIndex, point: newCornerPoint });
      }
    }

    LinkElementEditor.movePoints(
      linkElement,
      [
        {
          index: edgePointIndex,
          point: newEdgePoint,
        },
        ...newMidPoints,
      ],
      { isVisible: isVisible },
    );
  }

};

const maybeCalculateNewGapWhenScaling = (
  changedElement: ExcalidrawBindableElementEx,
  currentBinding: PointBindingEx | null | undefined,
  newSize: { width: number; height: number } | undefined,
): PointBindingEx | null | undefined => {
  if (currentBinding == null || newSize == null) {
    return currentBinding;
  }
  const { gap, focus, elementId } = currentBinding;
  const { width: newWidth, height: newHeight } = newSize;
  const { width, height } = changedElement;
  const newGap = Math.max(
    1,
    Math.min(
      maxBindingGapEx(changedElement, newWidth, newHeight),
      gap * (newWidth < newHeight ? newWidth / width : newHeight / height),
    ),
  );
  return { elementId, gap: newGap, focus };
};

export const getEligibleElementsForBindingEx = (
  elements: NonDeleted<ExcalidrawElement>[],
  app: AppClassProperties,
): SuggestedBindingEx[] => {
  const includedElementIds = new Set(elements.map(({ id }) => id));
  return elements.flatMap((selectedElement) =>
    isBindingElementEx(selectedElement, false)
      ? (getElligibleElementsForBindingElement(
        selectedElement as NonDeleted<ExcalidrawLinkElement>,
        app,
      ).filter(
        (element) => !includedElementIds.has(element.id),
      ) as SuggestedBindingEx[])
      : isBindableElementEx(selectedElement, false)
        ? getElligibleElementsForBindableElementAndWhere(
          selectedElement,
          app,
        ).filter(
          (binding) => !includedElementIds.has(binding[0].id),
        )
        : [],
  );
};

const getElligibleElementsForBindingElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  app: AppClassProperties,
): NonDeleted<ExcalidrawBindableElementEx>[] => {
  return [
    getElligibleElementForBindingElement(linkElement, "start", app),
    getElligibleElementForBindingElement(linkElement, "end", app),
  ].filter(
    (element): element is NonDeleted<ExcalidrawBindableElementEx> =>
      element != null,
  );
};

const getElligibleElementForBindingElement = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startOrEnd: "start" | "end",
  app: AppClassProperties,
): NonDeleted<ExcalidrawBindableElementEx> | null => {
  return getHoveredElementForBindingEx(
    getLinkElementEdgeCoors(
      linkElement,
      startOrEnd,
      app.scene.getNonDeletedElementsMap(),
    ),
    app,
    startOrEnd, // CHANGED:ADD 2022-11-11 #116
  );
};

const getLinkElementEdgeCoors = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startOrEnd: "start" | "end",
  elementsMap: NonDeletedSceneElementsMap,
): { x: number; y: number } => {
  const index = startOrEnd === "start" ? 0 : -1;
  return tupleToCoors(
    LinkElementEditor.getPointAtIndexGlobalCoordinates(
      linkElement,
      index,
      elementsMap,
    ),
  );
};

const getElligibleElementsForBindableElementAndWhere = (
  bindableElement: NonDeleted<ExcalidrawBindableElementEx>,
  app: AppClassProperties,
): SuggestedPointBindingEx[] => {
  const scene = Scene.getScene(bindableElement)!;
  return scene
    .getNonDeletedElements()
    .map((element) => {
      if (!isBindingElementEx(element, false)) {
        return null;
      }
      const canBindStart = isLinkElementEligibleForNewBindingByBindable(
        element,
        "start",
        bindableElement,
        scene.getNonDeletedElementsMap(),
        app,
      );
      const canBindEnd = isLinkElementEligibleForNewBindingByBindable(
        element,
        "end",
        bindableElement,
        scene.getNonDeletedElementsMap(),
        app,
      );
      if (!canBindStart && !canBindEnd) {
        return null;
      }
      return [
        element,
        canBindStart && canBindEnd ? "both" : canBindStart ? "start" : "end",
        bindableElement,
      ];
    })
    .filter(
      (maybeElement) => maybeElement != null,
    ) as SuggestedPointBindingEx[];
};

const isLinkElementEligibleForNewBindingByBindable = (
  linkElement: NonDeleted<ExcalidrawLinkElement>,
  startOrEnd: "start" | "end",
  bindableElement: NonDeleted<ExcalidrawBindableElementEx>,
  elementsMap: NonDeletedSceneElementsMap,
  app: AppClassProperties,
): boolean => {
  const existingBinding =
    linkElement[startOrEnd === "start" ? "startBinding" : "endBinding"];
  return (
    existingBinding == null &&
    !isLinkElementSimpleAndAlreadyBoundOnOppositeEdge(
      linkElement,
      bindableElement,
      startOrEnd,
    ) &&
    bindingBorderTestEx(
      bindableElement,
      getLinkElementEdgeCoors(linkElement, startOrEnd, elementsMap),
      app,
      startOrEnd, // CHANGED:ADD 2022-11-11 #116
    )
  );
};

// We need to:
// 1: Update elements not selected to point to duplicated elements
// 2: Update duplicated elements to point to other duplicated elements
export const fixBindingsAfterDuplicationEx = (
  sceneElements: ExcalidrawElement[],
  oldElements: readonly ExcalidrawElement[],
  oldIdToDuplicatedId: Map<ExcalidrawElement["id"], ExcalidrawElement["id"]>,
  // There are three copying mechanisms: Copy-paste, duplication and alt-drag.
  // Only when alt-dragging the new "duplicates" act as the "old", while
  // the "old" elements act as the "new copy" - essentially working reverse
  // to the other two.
  duplicatesServeAsOld?: "duplicatesServeAsOld" | undefined,
): void => {
  // First collect all the binding/bindable elements, so we only update
  // each once, regardless of whether they were duplicated or not.
  const allBoundElementIds: Set<ExcalidrawElement["id"]> = new Set();
  const allBindableElementIds: Set<ExcalidrawElement["id"]> = new Set();
  const shouldReverseRoles = duplicatesServeAsOld === "duplicatesServeAsOld";
  oldElements.forEach((oldElement) => {
    if (isBindableElementEx(oldElement)) {
      const { boundElementsEx } = oldElement;
      if (boundElementsEx != null && boundElementsEx.length > 0) {
        boundElementsEx.forEach((boundElement) => {
          if (shouldReverseRoles && !oldIdToDuplicatedId.has(boundElement.id)) {
            allBoundElementIds.add(boundElement.id);
          }
        });
        allBindableElementIds.add(oldIdToDuplicatedId.get(oldElement.id)!);
      }
    }
    if (isBindingElementEx(oldElement)) {
      if (oldElement.startBinding != null) {
        const { elementId } = oldElement.startBinding;
        if (shouldReverseRoles && !oldIdToDuplicatedId.has(elementId)) {
          allBindableElementIds.add(elementId);
        }
      }
      if (oldElement.endBinding != null) {
        const { elementId } = oldElement.endBinding;
        if (shouldReverseRoles && !oldIdToDuplicatedId.has(elementId)) {
          allBindableElementIds.add(elementId);
        }
      }
      if (oldElement.startBinding != null || oldElement.endBinding != null) {
        allBoundElementIds.add(oldIdToDuplicatedId.get(oldElement.id)!);
      }
    }
  });

  // CHANGED:UPDATE 2022-11-24 #197
  // Update the link elements
  (
    sceneElements.filter(({ id }) =>
      allBoundElementIds.has(id),
    ) as ExcalidrawLinkElement[]
  ).forEach((element) => {
    const { startBinding, endBinding } = element;

    const newStartBinding = newBindingAfterDuplication(
      startBinding,
      oldIdToDuplicatedId,
    );
    const newEndBinding = newBindingAfterDuplication(
      endBinding,
      oldIdToDuplicatedId,
    );

    // CHANGED:ADD 2022-12-10 #281
    if (!newStartBinding || !newEndBinding) {
      Array.from(oldIdToDuplicatedId.keys()).forEach((key) => {
        if (oldIdToDuplicatedId.get(key) === element.id) {
          oldIdToDuplicatedId.delete(key);
        }
      });

      // CHANGED:ADD 2023-1-23 #478
      const index = sceneElements.findIndex((el) => el.id === element.id);
      if (index !== -1) {
        sceneElements.splice(index, 1);
      }

      const boundTextIndex = sceneElements.findIndex(
        (el) => isTextElement(el) && el.containerId === element.id,
      );
      if (boundTextIndex !== -1) {
        sceneElements.splice(boundTextIndex, 1);
      }
    } else {
      mutateElement(element, {
        startBinding: newStartBinding,
        endBinding: newEndBinding,
      });
    }
  });

  // CHANGED:UPDATE 2022-11-24 #197
  // Update the bindable shapes
  (
    sceneElements.filter(({ id }) =>
      allBindableElementIds.has(id),
    ) as ExcalidrawNodeElement[]
  ).forEach((bindableElement) => {
    const { boundElementsEx, prevDependencies, nextDependencies, freeFloats: floats } =
      bindableElement;
    if (boundElementsEx != null && boundElementsEx.length > 0) {
      mutateElement(bindableElement, {
        boundElementsEx: boundElementsEx
          ?.filter((boundElement) => oldIdToDuplicatedId.has(boundElement.id))
          .map((boundElement) => {
            return {
              id: oldIdToDuplicatedId.get(boundElement.id)!,
              type: boundElement.type,
            };
          }),
      });
    }
    if (prevDependencies != null && prevDependencies.length > 0) {
      mutateElement(bindableElement, {
        prevDependencies: prevDependencies
          ?.filter((dependElementId) => {
            // CHANGE:UPDATE 2023-2-15 #716
            const findLinkElement = sceneElements.find(
              (element) =>
                allBoundElementIds.has(element.id) &&
                isLinkElement(element) &&
                element.startBinding?.elementId === oldIdToDuplicatedId.get(dependElementId) &&
                element.endBinding?.elementId === bindableElement.id,
            );

            return findLinkElement && oldIdToDuplicatedId.has(dependElementId);
          })
          .map((dependElementId) => {
            return oldIdToDuplicatedId.get(dependElementId)!;
          }),
      });
    }
    // CHANGED:ADD 2022-11-27 #208
    if (nextDependencies != null && nextDependencies.length > 0) {
      mutateElement(bindableElement, {
        nextDependencies: nextDependencies
          ?.filter((dependElementId) => {
            // CHANGE:UPDATE 2023-2-15 #716
            const findLinkElement = sceneElements.find(
              (element) =>
                allBoundElementIds.has(element.id) &&
                isLinkElement(element) &&
                element.startBinding?.elementId === bindableElement.id &&
                element.endBinding?.elementId === oldIdToDuplicatedId.get(dependElementId),
            );

            return findLinkElement && oldIdToDuplicatedId.has(dependElementId);
          })
          .map((dependElementId) => {
            return oldIdToDuplicatedId.get(dependElementId)!;
          }),
      });
    }
    // CHANGED:ADD 2024-04-15 #1917
    if (floats != null && floats.length > 0) {
      mutateElement(bindableElement, {
        freeFloats: floats
          ?.filter((float) => {
            const findLinkElement = sceneElements.find(
              (element) =>
                allBoundElementIds.has(element.id) &&
                isLinkElement(element) &&
                element.startBinding?.elementId === bindableElement.id &&
                element.endBinding?.elementId === oldIdToDuplicatedId.get(float.id),
            );

            return findLinkElement && oldIdToDuplicatedId.has(float.id);
          })
          .map((float) => {
            return {
              id: oldIdToDuplicatedId.get(float.id)!,
              type: float.type,
              duration: float.duration,
            };
          }),
      });
    }
  });
};

const newBindingAfterDuplication = (
  binding: PointBindingEx | null,
  oldIdToDuplicatedId: Map<ExcalidrawElement["id"], ExcalidrawElement["id"]>,
): PointBindingEx | null => {
  if (binding == null) {
    return null;
  }
  const { elementId, focus, gap } = binding;
  // CHANGED:UPDATE 2022-11-24 #197
  return oldIdToDuplicatedId.get(elementId)
    ? {
      focus,
      gap,
      elementId: oldIdToDuplicatedId.get(elementId)!,
    }
    : null;
};

export const fixBindingsAfterDeletionEx = (
  sceneElements: readonly ExcalidrawElement[],
  deletedElements: readonly ExcalidrawElement[],
): void => {
  const deletedElementIds = new Set(
    deletedElements.map((element) => element.id),
  );
  // non-deleted which bindings need to be updated
  const affectedElements: Set<ExcalidrawElement["id"]> = new Set();
  const deletedPrevDependencies: Set<ExcalidrawBindableElementEx["id"]> =
    new Set(); // CHANGED:ADD 2022-11-16 #114
  const deletedNextDependencies: Set<ExcalidrawBindableElementEx["id"]> =
    new Set(); // CHANGED:ADD 2022-11-27 #208
  const deletedFreeFloats: Set<ExcalidrawBindableElementEx["id"]> =
    new Set(); // CHANGED:ADD 2022-11-27 #208
  deletedElements.forEach((deletedElement) => {
    if (isBindableElementEx(deletedElement)) {
      deletedElement.boundElementsEx?.forEach((element) => {
        if (!deletedElementIds.has(element.id)) {
          affectedElements.add(element.id);

          // CHANGED:ADD 2022-11-23 #191
          const linkElement = Scene.getScene(
            deletedElement,
          )?.getNonDeletedElement(element.id);
          if (isBindingElementEx(linkElement)) {
            deletedElementIds.add(linkElement.id);

            if (linkElement.startBinding) {
              affectedElements.add(linkElement.startBinding.elementId);
              deletedPrevDependencies.add(linkElement.startBinding.elementId);
            }
            if (linkElement.endBinding) {
              affectedElements.add(linkElement.endBinding.elementId);
              deletedNextDependencies.add(linkElement.endBinding.elementId); // CHANGED:ADD 2022-11-27 #208
              deletedFreeFloats.add(linkElement.endBinding.elementId); // CHANGED:ADD 2024-04-15 #1917
            }
          }
        }
      });
    } else if (isBindingElementEx(deletedElement)) {
      if (deletedElement.startBinding) {
        affectedElements.add(deletedElement.startBinding.elementId);
        deletedPrevDependencies.add(deletedElement.startBinding.elementId); // CHANGED:ADD 2022-11-16 #114
      }
      if (deletedElement.endBinding) {
        affectedElements.add(deletedElement.endBinding.elementId);
        deletedNextDependencies.add(deletedElement.endBinding.elementId); // CHANGED:ADD 2022-11-27 #208
        deletedFreeFloats.add(deletedElement.endBinding.elementId); // CHANGED:ADD 2024-04-15 #1917
      }
    }
  });
  sceneElements
    .filter(({ id }) => affectedElements.has(id))
    .forEach((element) => {
      if (isBindableElementEx(element)) {
        mutateElement(element, {
          boundElementsEx: newBoundElementsAfterDeletion(
            element.boundElementsEx,
            deletedElementIds,
          ),
          // CHANGED:ADD 2022-11-16 #114
          prevDependencies: newPrevDependenciesAfterDeletion(
            element.prevDependencies,
            deletedPrevDependencies,
          ),
          // CHANGED:ADD 2022-11-27 #208
          nextDependencies: newNextDependenciesAfterDeletion(
            element.nextDependencies,
            deletedNextDependencies,
          ),
          // CHANGED:ADD 2024-04-15 #1917
          freeFloats: newFreeFloatsAfterDeletion(
            element.freeFloats,
            deletedFreeFloats,
          )
        });
      } else if (isBindingElementEx(element)) {
        mutateElement(element, {
          startBinding: newBindingAfterDeletion(
            element.startBinding,
            deletedElementIds,
          ),
          endBinding: newBindingAfterDeletion(
            element.endBinding,
            deletedElementIds,
          ),
          // CHANGED:ADD 2022-11-23 #191
          isDeleted: true,
        });

        // CHANGED:ADD 2023/01/20 #390
        const scene = Scene.getScene(element)!;
        const boundText = getBoundTextElement(
          element,
          scene.getNonDeletedElementsMap(),
        );
        if (boundText) {
          mutateElement(boundText, { isDeleted: true });
        }
      }
    });
};

const newBindingAfterDeletion = (
  binding: PointBindingEx | null,
  deletedElementIds: Set<ExcalidrawElement["id"]>,
): PointBindingEx | null => {
  if (binding == null || deletedElementIds.has(binding.elementId)) {
    return null;
  }
  return binding;
};

const newBoundElementsAfterDeletion = (
  boundElementsEx: ExcalidrawBindableElementEx["boundElementsEx"],
  deletedElementIds: Set<ExcalidrawElement["id"]>,
) => {
  if (!boundElementsEx) {
    return null;
  }
  return boundElementsEx.filter((ele) => !deletedElementIds.has(ele.id));
};

// CHANGED:ADD 2022-11-16 #114
const newPrevDependenciesAfterDeletion = (
  prevDependencies: ExcalidrawBindableElementEx["prevDependencies"],
  deletedPrevDependencies: Set<ExcalidrawElement["id"]>,
) => {
  if (!prevDependencies) {
    return null;
  }
  return prevDependencies.filter((ele) => !deletedPrevDependencies.has(ele));
};

// CHANGED:ADD 2022-11-27 #208
const newNextDependenciesAfterDeletion = (
  nextDependencies: ExcalidrawBindableElementEx["nextDependencies"],
  deletedNextDependencies: Set<ExcalidrawElement["id"]>,
) => {
  if (!nextDependencies) {
    return null;
  }
  return nextDependencies.filter((ele) => !deletedNextDependencies.has(ele));
};

// CHANGED:ADD 2024-04-15 #1917
const newFreeFloatsAfterDeletion = (
  floats: ExcalidrawBindableElementEx["freeFloats"],
  deletedFreeFloats: Set<ExcalidrawElement["id"]>,
) => {
  if (!floats) {
    return null;
  }
  return floats.filter((ele) => !deletedFreeFloats.has(ele.id));
};