import {
  isCommentElement,
  isCommentableElement,
  isJobElement,
  isJobRelatedElement,
  isLinkElement,
} from "src/excalidraw/extensions/element/typeChecks";
import { newElementWith } from "../../element/mutateElement";
import { ExcalidrawElement } from "../../element/types";
import { getSelectedElements } from "src/excalidraw/scene";
import { arrayToMap } from "src/excalidraw/utils";
import { register } from "src/excalidraw/actions/register";
import { isTextElement } from "src/excalidraw/element";
import { t } from "src/excalidraw/i18n";
import { ToolButton } from "src/excalidraw/components/ToolButton";
import { CheckedIcon, ResumeArrowIcon } from "src/excalidraw/components/icons";

// CHANGED:ADD 2023-02-26 #739
export const actionToggleClose = register({
  name: "toggleClose",
  trackEvent: { category: "element" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.some((element) => !isJobElement(element));
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    }).filter((element) => !isJobRelatedElement(element));
    if (!selectedElements.length) {
      return false;
    }

    const operation = getOperation(selectedElements);
    const selectedElementsMap = arrayToMap(selectedElements);
    const isClosed = operation === "close";

    elements.forEach((element) => {
      if (
        !selectedElementsMap.has(element.id) &&
        isLinkElement(element) &&
        element.startBinding &&
        selectedElementsMap.has(element.startBinding.elementId)
      ) {
        selectedElementsMap.set(element.id, element);
      }
    });

    selectedElementsMap.forEach((element) => {
      if (isCommentableElement(element)) {
        const thread = elements.find((el) => isCommentElement(el) && el.commentElementId === element.id);
        if (thread) selectedElementsMap.set(thread.id, thread);
      }
    });


    return {
      elements: elements.map((element) => {
        if (
          !selectedElementsMap.has(element.id) &&
          !(
            isTextElement(element) &&
            element.containerId &&
            selectedElementsMap.has(element.containerId)
          )
        ) {
          return element;
        }

        return newElementWith(element, { isClosed });
      }),
      appState: {
        ...appState,
        selectedLinearElement: isClosed ? null : appState.selectedLinearElement,
        selectedTaskElement: isClosed ? null : appState.selectedTaskElement,
        selectedLinkElement: isClosed ? null : appState.selectedLinkElement,
      },
      commitToHistory: true,
    };
  },
  contextItemLabel: (elements, appState) => {
    const selected = getSelectedElements(elements, appState, {
      includeBoundTextElement: false,
    });
    if (selected.length === 1) {
      return selected[0].isClosed
        ? "labels.elementClose.open"
        : "labels.elementClose.close";
    }

    return getOperation(selected) === "close"
      ? "labels.elementClose.closeAll"
      : "labels.elementClose.openAll";
  },
  keyTest: (event) => false,
  // CHANGED:ADD 2023-03-15 #764
  PanelComponent: ({ elements, appState, updateData }) => {
    let label = "";
    let open = false;

    const selected = getSelectedElements(elements, appState, {
      includeBoundTextElement: false,
    });
    if (selected.length === 1) {
      open = selected[0].isClosed;
      label = open
        ? t("labels.elementClose.open")
        : t("labels.elementClose.close");
    } else {
      open = getOperation(selected) === "open";
      label = open
        ? t("labels.elementClose.openAll")
        : t("labels.elementClose.closeAll");
    }

    return (
      <ToolButton
        type="button"
        icon={open ? ResumeArrowIcon : CheckedIcon}
        title={label}
        aria-label={label}
        onClick={() => updateData(null)}
      />
    );
  },
});

const getOperation = (
  elements: readonly ExcalidrawElement[],
): "close" | "open" => (elements.some((el) => !el.isClosed) ? "close" : "open");
