import { Timestamp } from "firebase/firestore";

export default interface Checklist {
  id: string;
  title: string;
  url: string;
  isChecked: boolean;
  checkedBy: string | null;
  checkedAt: Date | null;
  createdBy: string;
  createdAt: Date | null;
  disableNotification?: boolean;
}

export interface ChecklistResponse extends Omit<Checklist, "checkedAt" | "createdAt"> {
  checkedAt: Timestamp;
  createdAt: Timestamp;
}

export const ChecklistDocumentFields = {
  id: "id",
  title: "title",
  url: "url",
  isChecked: "isChecked",
  checkedBy: "checkedBy",
  checkedAt: "checkedAt",
  createdBy: "createdBy",
  createdAt: "createdAt",
  disableNotification: "disableNotification",
} as const;

export type ChecklistDocumentFields = typeof ChecklistDocumentFields[keyof typeof ChecklistDocumentFields];

export interface ChecklistForm extends Pick<Checklist, "title"> {}