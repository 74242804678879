import React, { Fragment, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { firebaseTimeToDate } from "src/utils/timeUtils";

//components
import { Transition, Listbox } from "@headlessui/react";
import { RotatingLines } from "react-loader-spinner";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { DelayIcon, CalenderIcon } from "../../components/icons";
import Header from "src/conpath/components/layouts/Header";

//types and interface
import { OrganizationResponse } from "src/conpath/interfaces/Organization";
import { TaskResponse } from "src/conpath/interfaces/Task";
import ProjectModel from "src/conpath/models/ProjectModel";
import { OrganizationActivityResponse } from "src/conpath/interfaces/Activity";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from 'src/conpath/hooks/useStore';

//constant
import { Paths } from "src/conpath/constants/Routes";
import Colors from "src/conpath/constants/Colors";
import { OrganizationActivityStateMap } from "src/conpath/constants/OrganizationActivityState";

//styles
import "./Dashboard.scss";
import OrganizationModel from "../../models/OrganizationModel";

// DashboardHeader Component
const DashboardHeader: React.FC<{
  selected: OrganizationResponse | null;
  organizationChange: (value: OrganizationResponse | null) => void;
  organizations: OrganizationResponse[];
}> = ({ selected, organizationChange, organizations }) => (
  <div className="dashboard-header">
    <p className="dashboard-header__title">ダッシュボード</p>
    {organizations.length > 1 &&
      <Listbox value={selected} onChange={organizationChange}>
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-[999] absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {organizations.map((organization, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`
                  }
                  value={organization}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {organization.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    }
  </div>
);

// RemindTasks Component
const RemindTasks: React.FC<{
  remindTasks: TaskResponse[];
  navigateToProjectTask: (task: TaskResponse) => void;
  selectedOrganization: OrganizationModel | null;
  dateOptions: Intl.DateTimeFormatOptions;
  organizationStore: any;
}> = ({
  remindTasks,
  navigateToProjectTask,
  selectedOrganization,
  dateOptions,
  organizationStore,
}) => (
    <div className="dashboard-container remind">
      <div className="dashboard-container__header">
        <p>リマインド</p>
      </div>
      {organizationStore.isLoading || selectedOrganization?.isRemindTaskLoading
        ? (
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="42"
              visible={true}
            />
          </div>
        ) : remindTasks && remindTasks.length > 0 ? (
          <ul className="dashboard-container__remind-ul">
            {remindTasks?.map((task) => {
              const endDate = firebaseTimeToDate(task.endDate);
              const startDate = firebaseTimeToDate(task.startDate);
              const currentDate = new Date();
              endDate.setDate(endDate.getDate() - 1);
              const delayFlg = !task.isClosed && currentDate.getTime() > new Date(task.endDate.seconds * 1000).getTime();

              const timeDifference = endDate.getTime() - currentDate.getTime();
              const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10日 → ミリ秒
              const soonFlg = !task.isClosed && timeDifference <= tenDaysInMilliseconds && timeDifference > 0;
              const todayFlg = !task.isClosed && currentDate.toDateString() === endDate.toDateString();

              return (
                <li
                  className={`dashboard-container__remind-list ${delayFlg && !soonFlg && !todayFlg ? "delay" : ""} ${!delayFlg && soonFlg && !todayFlg ? "soon" : ""} ${!delayFlg && !soonFlg && todayFlg ? "today" : ""}`}
                  key={task.id}
                  onClick={() => navigateToProjectTask(task)}
                >
                  <div className="dashboard-container__remind-list-container">
                    <div className="dashboard-container__remind-list-header">
                      <div className="dashboard-container__remind-list-task-name">
                        {delayFlg && <DelayIcon />}
                        <p className="dashboard-container__remind-list-title" dangerouslySetInnerHTML={{ __html: task.text.replace(/\n/g, '<br>') }}></p>
                      </div>
                      <p className="dashboard-container__remind-list-project-title">{selectedOrganization?.projects.find((p) => p.id === task?.projectId)?.name || ""}</p>
                    </div>
                  </div>
                  <div className="dashboard-container__remind-list-footer">
                    <p className="dashboard-container__remind-list-deadline">期日：</p>
                    <p className={`dashboard-container__remind-list-date`}>{endDate.toLocaleDateString('ja-JP', dateOptions)}</p>
                  </div>
                  {delayFlg && !soonFlg && !todayFlg && <div className="delay-arrow-up">期限を過ぎています</div>}
                  {!delayFlg && soonFlg && !todayFlg && <div className="soon-arrow-up">期限が近づいています</div>}
                  {!delayFlg && !soonFlg && todayFlg && <div className="today-arrow-up">期限当日です</div>}
                  <div className="dashboard-container__remind-start-date">{startDate.toLocaleDateString('ja-JP')}</div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="no-remind-message">リマインドはありません。</p>
        )}
    </div>
  );

// Projects Component
const Projects: React.FC<{
  projects: ProjectModel[];
  navigateToProject: (project: ProjectModel) => void;
  selectedOrganization: OrganizationModel | null;
  dateOptions: Intl.DateTimeFormatOptions;
  dateTimeOptions: Intl.DateTimeFormatOptions;
  organizationStore: any;
}> = ({
  projects,
  navigateToProject,
  selectedOrganization,
  dateOptions,
  dateTimeOptions,
  organizationStore,
}) => (
    <div className="dashboard-container__task">
      <div className="dashboard-container__header">
        <p>プロジェクト</p>
      </div>
      {organizationStore.isLoading || selectedOrganization?.isDashboardProjectLoading
        ? (
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="42"
              visible={true}
            />
          </div>
        ) : projects && projects.length > 0 ? (
          <ul className="dashboard-container__project-ul">
            {projects?.map((project) => {
              const endDate = firebaseTimeToDate(project.endDate);
              const startDate = firebaseTimeToDate(project.startDate);
              const updateDate = firebaseTimeToDate(project.updatedAt);

              return (
                <li className={`dashboard-container__project-list`} key={project.id} onClick={() => navigateToProject(project)}>
                  <div className="dashboard-container__project-list-container">
                    <div className="dashboard-container__project-list-container-left">
                      <div className="dashboard-container__project-list-header">
                        <div className="dashboard-container__project-list-task-name">
                          <p className="dashboard-container__project-list-title" dangerouslySetInnerHTML={{ __html: project.name }}></p>
                        </div>
                      </div>
                      <div className="dashboard-container__task-count-container">
                        <p className={`dashboard-container__task-count`}>今日が期限のタスク <span className="today-task-count">{project.todayTaskCount}</span> 件</p>
                        <p className={`dashboard-container__task-count`}>期限を過ぎているタスク <span className="delay-task-count">{project.overdueTaskCount}</span> 件</p>
                      </div>
                    </div>
                    <div className="dashboard-container__project-list-sidebar">
                      <div className="dashboard-container__project-list-sidebar-container">
                        <CalenderIcon />
                        <p className={`dashboard-container__project-list-date`}>{startDate.toLocaleDateString('ja-JP', dateOptions)} ~ {endDate.toLocaleDateString('ja-JP', dateOptions)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-container__project-update-date">最終更新 : {updateDate.toLocaleDateString('ja-JP', dateTimeOptions)}</div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="no-project-message">プロジェクトはありません。</p>
        )}
    </div>
  );

// Activities Component
const Activities: React.FC<{
  activities: OrganizationActivityResponse[];
  navigateToActivity: (activity: OrganizationActivityResponse) => void;
  selectedOrganization: OrganizationModel | null;
  dateTimeOptions: Intl.DateTimeFormatOptions;
  activitySectionHeightCss: string;
  organizationStore: any;
}> = ({
  activities,
  navigateToActivity,
  selectedOrganization,
  dateTimeOptions,
  activitySectionHeightCss,
  organizationStore,
}) => (
    <div className="dashboard-container__activity">
      <div className="dashboard-container__activity-header">
        <p>最近の更新</p>
      </div>
      <div className="dashboard-container__activity-scroll-container" style={{ height: activitySectionHeightCss }}>
        {organizationStore.isLoading || selectedOrganization?.isDashboardActivityLoading
          ? (
            <div className="spinner-wrapper">
              <RotatingLines
                strokeColor={Colors.primary}
                strokeWidth="5"
                animationDuration="0.75"
                width="42"
                visible={true}
              />
            </div>
          ) : activities && activities.length > 0 ? (
            <ul className="dashboard-container__activity-ul">
              {activities?.map((activity) => {
                const timestamp = firebaseTimeToDate(activity.timestamp);
                const user = selectedOrganization?.findUserById(activity.userId);
                const username = user ? user.username : "";

                return (
                  <li className={`dashboard-container__activity-list`} key={activity.id} onClick={() => navigateToActivity(activity)}>
                    <div className="dashboard-container__activity-list-container">
                      <div className="dashboard-container__activity-update-date">{timestamp.toLocaleDateString('ja-JP', dateTimeOptions)}</div>
                      <div className="dashboard-container__activity-list-header">
                        <div className="dashboard-container__profile-image-wrapper">
                          {user && user.profileImageUrl
                            ? <img src={user.profileImageUrl} />
                            : <div>{user ? user.username.charAt(0) : ""}</div>
                          }
                        </div>
                        <div className="dashboard-container__activity-list-task-name">
                          <p className="dashboard-container__activity-list-title" dangerouslySetInnerHTML={{
                            __html: OrganizationActivityStateMap[activity.state]
                              .replace("${username}", username)
                              .replace("${text}", activity.text)
                              .replace("${beforeValue}", activity.beforeValue)
                              .replace("${afterValue}", activity.afterValue)
                          }}></p>
                        </div>
                      </div>
                      <p className="dashboard-container__activity-list-project-title">{selectedOrganization?.projects.find((p) => p.id === activity.projectId)?.name || ""}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="no-activity-message">最近の更新はありません。</p>
          )}
      </div>
    </div>
  );

const Dashboard: React.FC = observer(() => {
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  const [selected, setSelected] = useState<OrganizationResponse | null>(null);
  const [remindTasks, setRemindTasks] = useState<TaskResponse[]>([]);
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [activities, setActivities] = useState<OrganizationActivityResponse[]>([]);
  const [activitySectionHeightCss, setActivitySectionHeightCss] = useState<string>("");

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  useEffect(() => {
    const getOrganizations = async () => {
      if (loginUser) {
        await organizationStore.load(loginUser);
        organizationStore.organizations.forEach((organization) => {
          if (organization.id === organizationStore.selectedOrganization?.id) {
            setSelected(organization);
          }
        });
      }
    };
    getOrganizations();
  }, [loginUser]);

  useEffect(() => {
    const getRemindTasks = async () => {
      if (loginUser && selectedOrganization && selectedOrganization.projects.length) {
        const remindTasks = await selectedOrganization.getRemindTasks(loginUser);
        setRemindTasks(remindTasks);
      }
    };

    const getDashboardProjects = async () => {
      if (selectedOrganization && selectedOrganization.projects.length) {
        const projects = await selectedOrganization.getDashboardProjects();
        setProjects(projects);
      }
    };

    const getDashboardActivities = async () => {
      if (selectedOrganization && selectedOrganization.projects.length) {
        const activities = await selectedOrganization.getDashboardActivities();
        setActivities(activities);
      }
    };

    if (!organizationStore.isLoading) {
      getRemindTasks();
      getDashboardProjects();
      getDashboardActivities();
    }
  }, [loginUser, organizationStore.isLoading, selectedOrganization?.projects]);

  const organizationChange = useCallback(async (value: OrganizationResponse | null) => {
    if (!value) return;

    await organizationStore.setSelectedOrganization(value.id);
    await organizationStore.selectedOrganization!.getOrganizationUsers();
    await organizationStore.selectedOrganization!.getResources();
    if (loginUser) {
      loginUser.setSelectedOrganizationId(value.id);
      loginUser.setOrganizationRole(organizationStore.selectedOrganization!);
      await organizationStore.selectedOrganization!.getProjects(loginUser);
    }
    userStore.updateUserDocument();

    setSelected(value);
  }, [selected, setSelected]);

  const navigateToProject = (project: ProjectModel) => {
    window.open(`${Paths.projects}/${project.id}`, "_blank");
  };

  const navigateToProjectTask = (task: TaskResponse) => {
    window.open(`${Paths.projects}/${task.projectId}/#task=${task.id}`, "_blank");
  };

  const navigateToActivity = (activity: OrganizationActivityResponse) => {
    const ref = activity.docRef;
    if (ref && ref.path.includes("tasks")) {
      window.open(`${Paths.projects}/${activity.projectId}/#task=${ref.id}`, "_blank");
    } else if (ref && ref.path.includes("comments")) {
      window.open(`${Paths.projects}/${activity.projectId}/#comment=${ref.id}`, "_blank");
    }
  };

  useEffect(() => {
    const cpHeader = document.querySelector('.cp-header') as HTMLElement;
    const dashboardHeader = document.querySelector('.dashboard-header') as HTMLElement;
    const remind = document.querySelector('.remind') as HTMLElement;
    const projectList = document.querySelector('.dashboard-container__project-ul') as HTMLElement;
    const activityHeader = document.querySelector('.dashboard-container__activity-header') as HTMLElement;
    const activityList = document.querySelector('.dashboard-container__activity-scroll-container') as HTMLElement;

    if (projectList && remind && cpHeader && dashboardHeader && activityList) {
      const dashboardTopHeight = cpHeader.offsetHeight + dashboardHeader.offsetHeight + remind.offsetHeight + activityHeader.offsetHeight;
      setActivitySectionHeightCss(projectList.offsetHeight > activityList.offsetHeight ? `${projectList.offsetHeight}px` : `calc(100vh - ${dashboardTopHeight}px)`);
    }
  }, [activities, projects]);

  useEffect(() => {
    const cpHeader = document.querySelector('.cp-header') as HTMLElement;
    const dashboardHeader = document.querySelector('.dashboard-header') as HTMLElement;
    const remind = document.querySelector('.remind') as HTMLElement;
    const activityHeader = document.querySelector('.dashboard-container__activity-header') as HTMLElement;

    if (remind && cpHeader && dashboardHeader && activityHeader) {
      const dashboardTopHeight = cpHeader.offsetHeight + dashboardHeader.offsetHeight + remind.offsetHeight + activityHeader.offsetHeight;
      setActivitySectionHeightCss(`calc(100vh - ${dashboardTopHeight}px)`);
    }
  }, [remindTasks]);

  return (
    <>
      <Header />
      <div className="dashboard">
        <DashboardHeader
          selected={selected}
          organizationChange={organizationChange}
          organizations={organizationStore.organizations}
        />
        <RemindTasks
          remindTasks={remindTasks}
          navigateToProjectTask={navigateToProjectTask}
          selectedOrganization={selectedOrganization}
          dateOptions={dateOptions}
          organizationStore={organizationStore}
        />
        <div className="dashboard-container task-and-update">
          <Projects
            projects={projects}
            navigateToProject={navigateToProject}
            selectedOrganization={selectedOrganization}
            dateOptions={dateOptions}
            dateTimeOptions={dateTimeOptions}
            organizationStore={organizationStore}
          />
          <Activities
            activities={activities}
            navigateToActivity={navigateToActivity}
            selectedOrganization={selectedOrganization}
            dateTimeOptions={dateTimeOptions}
            activitySectionHeightCss={activitySectionHeightCss}
            organizationStore={organizationStore}
          />
        </div>
      </div>
    </>
  );
});

export default Dashboard;
