import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { EVENT } from "../../constants";

// models
import LoginUserModel from "src/conpath/models/LoginUserModel";
import TaskModel from "src/conpath/models/TaskModel";
import ChecklistModel from "src/conpath/models/ChecklistModel";

interface Props {
  taskModel: TaskModel|null;
  loginUser: LoginUserModel|null;
}
const useChecklist = ({
  taskModel,
  loginUser
}: Props) => {

  const [allowPaste, setAllowPaste] = useState<boolean>(false);
  
  const handlePaste = useCallback((event: ClipboardEvent | null, checklist?: ChecklistModel): void => {
    if (!event || !loginUser) return;

    if (!checklist && !allowPaste) return;

                
    const checkTexts = (event.clipboardData?.getData('Text').split("\n") || [])
                        .filter((s) => !isEmpty(s));

    if (!checkTexts.length) return;

    checklist?.changeTitle(checkTexts.shift() || "");
    taskModel?.populateChecklist(loginUser, checkTexts);
    
    setAllowPaste(false);
    event.stopPropagation();
    event.preventDefault();
  }, [allowPaste, taskModel, loginUser]);

  useEffect(() => {

    window.addEventListener(EVENT.PASTE, handlePaste, false);

    return () => {
      window.removeEventListener(EVENT.PASTE, handlePaste, false);
    };
  }, [handlePaste]);

  return { allowPaste, setAllowPaste, handlePaste };
};

export default useChecklist;