import { GeneralDocumentQueryErrorType, GeneralDocumentUpdateErrorType } from ".";


export const GetOrganizationUserRequestError: { [key in GeneralDocumentQueryErrorType]: string } = {
  DocumentEmpty: "組織に属するユーザーが見つかりませんでした。",
  NetworkingError: "組織に属するユーザーの取得に失敗しました。"
} as const;


export const AddOrganizationUsersRequestErrorType = {
  General: "General",
  AuthFailed: "AuthFailed",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  InviterNotExists: "InviterNotExists",
  UserAlreadyExistsInInvitationList: "UserAlreadyExistsInInvitationList",
  AddingOrganizationUserFailed: "AddingOrganizationUserFailed",
  UpdateOrganizationUserFailed: "UpdateOrganizationUserFailed",
} as const;

export type AddOrganizationUsersRequestErrorType = typeof AddOrganizationUsersRequestErrorType[keyof typeof AddOrganizationUsersRequestErrorType];

export const AddOrganizationUsersRequestError: { [key in AddOrganizationUsersRequestErrorType]: string } = {
  General: "ユーザーの招待に失敗しました。",
  AuthFailed: "ユーザー情報が認証できませんでした。再度ログインして下さい。",
  ArgumentValidationFailed: "ユーザーの招待に失敗しました。",
  InviterNotExists: "招待者の情報を取得できませんでした。プロフィールを設定後、再度お試し下さい。",
  UserAlreadyExistsInInvitationList: "は過去に組織への招待履歴があります。", //Emailを主語にする
  AddingOrganizationUserFailed: "の招待に失敗しました。", //Emailを主語にする
  UpdateOrganizationUserFailed: "の再招待に失敗しました。", //Emailを主語にする
} as const;


export const ResentInvitationRequestErrorType = {
  General: "General",
  AuthFailed: "AuthFailed",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  InviterNotExists: "InviterNotExists",
  UpdateOrganizationStateFailed: "UpdateOrganizationStateFailed",
  SendingPubSubMessageFailed: "SendingPubSubMessageFailed",
  SendPubSubAndUpdateStateFailed: "SendPubSubAndUpdateStateFailed",
} as const;

export type ResentInvitationRequestErrorType = typeof ResentInvitationRequestErrorType[keyof typeof ResentInvitationRequestErrorType];

export const ResentInvitationRequestError: { [key in ResentInvitationRequestErrorType]: string } = {
  General: "ユーザーの再招待に失敗しました。",
  AuthFailed: "ユーザー情報が認証できませんでした。再度ログインして下さい。",
  ArgumentValidationFailed: "ユーザーの再招待に失敗しました。",
  InviterNotExists: "招待者の情報を取得できませんでした。プロフィールを設定後、再度お試し下さい。",
  UpdateOrganizationStateFailed: "ユーザーの再招待に失敗しました。", //　必要があれば変更する
  SendingPubSubMessageFailed: "ユーザーの再招待に失敗しました。", //　必要があれば変更する
  SendPubSubAndUpdateStateFailed: "ユーザーの再招待に失敗しました。", //　必要があれば変更する
} as const;

export const RemoveUserFromOrganizationErrorType = {
  General: "General",
  AuthFailed: "AuthFailed",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  DocumentEmpty: "DocumentEmpty",
  UserNotFoundInOrganization: "UserNotFoundInOrganization",
} as const;

export type RemoveUserFromOrganizationErrorType = typeof RemoveUserFromOrganizationErrorType[keyof typeof RemoveUserFromOrganizationErrorType];

export const RemoveUserFromOrganizationErrors: { [key in RemoveUserFromOrganizationErrorType]: string } = {
  General: "組織からの退会に失敗しました。",
  AuthFailed: "ユーザー情報が認証できませんでした。再度ログインして下さい。",
  ArgumentValidationFailed: "組織からの退会に失敗しました。",
  DocumentEmpty: "該当する組織は見つかりませんでした。",
  UserNotFoundInOrganization: "該当するユーザーが見つかりませんでした。",
} as const;

export const AcceptInvitationErrorTypes = {
  General: "General",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  UserDoesNotExist: "UserDoesNotExist",
  DocumentEmpty: "DocumentEmpty",
  UserNotFoundInInvitationList: "UserNotFoundInInvitationList",
  InvitationIdNotMatched: "InvitationIdNotMatched",
  InvitationCanceled: "InvitationCanceled",
} as const;

export type AcceptInvitationErrorTypes = typeof AcceptInvitationErrorTypes[keyof typeof AcceptInvitationErrorTypes];

export const AcceptInvitationRequestErrors: { [key in AcceptInvitationErrorTypes]: string } = {
  UserDoesNotExist: "ConPathアプリにユーザー情報は見つかりませんでした。再度ユーザー登録して下さい。",
  DocumentEmpty: "該当する組織は見つかりませんでした。",
  UserNotFoundInInvitationList: "組織の招待リストにユーザーは見つかりませんでした。",
  InvitationIdNotMatched: "組織の招待コードと一致しませんでした。Emailボックスを確認し、最新の招待メールを再度確認して下さい。",
  InvitationCanceled: "組織が招待を取り止めてしまった為、参加できませんでした。",
  General: "組織への参加に失敗しました。",
  ArgumentValidationFailed: "組織への参加に失敗しました。",
} as const;

export const DeclineOrganizationInvitationErrorType = {
  General: "General",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  DeletingUserFailed: "DeletingUserFailed",
} as const;

export type DeclineOrganizationInvitationErrorType = typeof DeclineOrganizationInvitationErrorType[keyof typeof DeclineOrganizationInvitationErrorType];

export const DeclineOrganizationInvitationErrors: { [key in DeclineOrganizationInvitationErrorType]: string } = {
  General: "組織への招待を辞退できませんでした。",
  ArgumentValidationFailed: "組織への招待を辞退できませんでした。",
  DeletingUserFailed: "ユーザー情報の消去に失敗しました。",
} as const;

