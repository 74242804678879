import React from 'react'
import classNames from 'classnames'
import './RowToggler.scss'

interface RowTogglerProps {
  onClick: React.DOMAttributes<HTMLDivElement>['onClick']
  collapsed: boolean
  level: number
  prefixCls?: string
}
const RowToggler: React.FC<RowTogglerProps> = ({ onClick, collapsed, level, prefixCls = '' }) => {
  const prefixClsRowToggler = `${prefixCls}-row-toggler`
  return (
    <div role='none' onClick={onClick} className={prefixClsRowToggler}>
      <div
        className={classNames(prefixClsRowToggler, {
          [`${prefixClsRowToggler}-collapsed`]: collapsed,
        })}
      >
        <i data-level={level}>
          {level <= 0 ? (
            <svg viewBox='0 0 1024 1024'>
              <path d='M543.2,606.9c-14.8,17.2-40.7,19.2-57.9,4.5c-1.6-1.4-3.1-2.9-4.5-4.5L323.7,423.6 c-14.8-17.2-12.8-43.1,4.4-57.9c7.5-6.4,17-9.9,26.8-9.9h314.3c22.7,0,41.1,18.4,41.1,41.1c0,9.8-3.5,19.3-9.9,26.7L543.2,606.9z' />
            </svg>
          ) : (
            <svg viewBox='0 0 1024 1024'>
              <path d='M543.2,606.9c-14.8,17.2-40.7,19.2-57.9,4.5c-1.6-1.4-3.1-2.9-4.5-4.5L323.7,423.6 c-14.8-17.2-12.8-43.1,4.4-57.9c7.5-6.4,17-9.9,26.8-9.9h314.3c22.7,0,41.1,18.4,41.1,41.1c0,9.8-3.5,19.3-9.9,26.7L543.2,606.9z' />
            </svg>
          )}
        </i>
      </div>
    </div>
  )
}
export default RowToggler
