import React, { useContext, useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Context from '../../context';
import { ROW_HEIGHT, ROW_HEIGHT_RESOURCE, TOP_PADDING } from '../../constants';
import RowToggler from './RowToggler';
import './index.scss';
import DelayIcon from './DelayIcon';
import { Gantt } from '../../types';
import { useStore } from 'src/conpath/hooks/useStore';
import { BsExclamationLg } from 'react-icons/bs';
import { BarColors } from 'src/conpath/constants/Colors';

const TableRows = () => {
  const { chartType } = useContext(Context);
  const Rows = chartType == Gantt.ChartType.Roadmap ? RoadmapRows() : ResourcesRows();
  return <>{Rows}</>;
};

// Roadmap Rows
const RoadmapRows = () => {
  const { store, onRow, tableIndent, expandIcon, prefixCls, onExpand } = useContext(Context);
  const { columns, rowHeight } = store;
  const columnsWidth = store.getColumnsWidth;
  const barList = store.getBarList;

  const { count, start } = store.getVisibleRows;
  const prefixClsTableBody = `${prefixCls}-table-body`;

  const [isHoveredRecordId, setIsHoveredRecordId] = useState("");

  return barList.slice(start, start + count).map((bar, rowIndex) => {
    const parent = bar._parent;
    const parentItem = parent?._parent;
    let isLastChild = false;
    const isProject = !bar._parent;
    const hasChild = !!bar._childrenCount;
    if (parentItem?.children && parentItem.children[parentItem.children.length - 1] === bar._parent)
      isLastChild = true

    return (
      <div
        key={bar.key}
        role="none"
        className={classNames(`${prefixClsTableBody}-row`, {
          [`${prefixCls}-row-toggler-project`]: isProject,
        })}
        style={{
          height: rowHeight,
          top: (rowIndex + start) * rowHeight + TOP_PADDING,
        }}
        onClick={() => {
          // 行クリックでタスクまでスクロール
          const translateX = bar.translateX - 210; // スタート位置を7日分ずらす
          store.setTranslateX(translateX);
          // onRow?.onClick(bar.record);
        }}
        onMouseEnter={() => setIsHoveredRecordId(bar.record.id)}
        onMouseLeave={() => setIsHoveredRecordId("")}
      >
        {bar.record.isDelayed ? (
          <DelayIcon width={9} thickness={9} color={"#DF4646"} />
        ) : isProject ? (
          <div
            className={classNames(
              `${prefixClsTableBody}-icon ${prefixClsTableBody}-icon-project`,
              {
                ["active"]: !bar._collapsed,
              },
            )}
            onClick={(event) => {
              event.stopPropagation();
              if (onExpand) onExpand(bar.task.record, !bar._collapsed);
              store.setRowCollapse(bar.task, !bar._collapsed);
            }}
          />
        ) : (
          ""
        )}
        {columns.map((column, index) => (
          <div
            key={column.name}
            className={`${prefixClsTableBody}-cell`}
            style={{
              width: columnsWidth[index],
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              textAlign: column.align ? column.align : "left",
              paddingLeft: bar._depth !== 0 ? 16 * bar._depth + 40 : 28,
              ...column.style,
            }}
          >
            {index === 0 &&
              new Array(bar._depth).fill(0).map((_, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames(
                    `${prefixClsTableBody}-row-indentation`,
                    {
                      [`${prefixClsTableBody}-row-indentation-hidden`]:
                        (isLastChild && i === bar._depth - 2) || i >= 1,
                      [`${prefixClsTableBody}-row-indentation-both`]:
                        i === bar._depth - 1,
                    },
                  )}
                  style={{
                    top: -(rowHeight / 2) + 1,
                    left: tableIndent * i + 15,
                    width: bar._depth <= 1 ? tableIndent + 5 : 0,
                  }}
                />
              ))}
            {index === 0 && bar._childrenCount > 0 && (
              <div
                style={{
                  position: "absolute",
                  left:
                    bar._depth === 0
                      ? "auto"
                      : tableIndent * bar._depth + 15,
                  right: bar._depth !== 0 ? "auto" : 0,
                  background: "#F5F6F7",
                  zIndex: 9,
                  transform: "translateX(-49%)",
                  padding: 1,
                }}
              >
                {expandIcon ? (
                  expandIcon({
                    level: bar._depth,
                    collapsed: bar._collapsed,
                    onClick: (event) => {
                      event.stopPropagation();
                      if (onExpand)
                        onExpand(bar.task.record, !bar._collapsed);
                      store.setRowCollapse(bar.task, !bar._collapsed);
                    },
                  })
                ) : (
                  <RowToggler
                    prefixCls={prefixCls}
                    level={bar._depth}
                    collapsed={bar._collapsed}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (onExpand)
                        onExpand(bar.task.record, !bar._collapsed);
                      store.setRowCollapse(bar.task, !bar._collapsed);
                    }}
                  />
                )}
              </div>
            )}
            {bar.record.isDelayed ? (
              <DelayIcon width={9} thickness={9} color={BarColors.OVERDUE} />
            ) : !hasChild ? (
              <div
                className={`${prefixClsTableBody}-icon ${prefixClsTableBody}-icon-task`}
              ></div>
            ) : (
              ""
            )}
            <span
              className={`${prefixClsTableBody}-ellipsis`}
              style={{
                lineHeight: `${ROW_HEIGHT}px`,
                color: bar.record.isDelayed ? BarColors.OVERDUE : "#000000",
              }}
            >
              {column.render
                ? column.render(bar.record)
                : bar.record[column.name]}
            </span>
            {bar.record.id === isHoveredRecordId && !hasChild && (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onRow?.onClick(bar.record);
                }}
              >
                開く
              </button>
            )}
          </div>
        ))}
      </div>
    );
  });
};

// Resources Rows
const ResourcesRows = () => {
  const { organizationStore } = useStore();
  const { selectedOrganization } = organizationStore;
  const { store, onRow, tableIndent, expandIcon, prefixCls, onExpand } = useContext(Context);
  const { columns, rowHeight } = store;
  const columnsWidth = store.getColumnsWidth;
  const barList = store.getBarList;

  const { count, start } = store.getVisibleRows;
  const prefixClsTableBody = `${prefixCls}-table-body`;

  const [isHoveredRecordId, setIsHoveredRecordId] = useState("");

  return barList.slice(start, start + count).map((bar, rowIndex) => {
    const parent = bar._parent;
    const parentItem = parent?._parent;
    let isLastChild = false;
    const isResource = !bar._parent;
    const hasChild = !!bar._childrenCount;
    if (parentItem?.children && parentItem.children[parentItem.children.length - 1] === bar._parent)
      isLastChild = true

    const projectName = bar.task.projectName;

    // サムネイルを生成
    const ImageUrl: JSX.Element = bar.record.isTask ? (
      <></>
    ) : (
      (() => {
        const user = selectedOrganization?.users?.find((r) => r.id === bar.record.id);
        // user
        if (user) {
          const { profileImageUrl } = user;
          if (profileImageUrl) {
            return <img src={profileImageUrl} />;
          } else {
            return <div>{user.username.charAt(0)}</div>;
          }
        } else {
          // resource
          const resource = selectedOrganization?.resources?.find(
            (r) => r.id === bar.record.id,
          );
          if (resource) {
            const { iconImageUrl } = resource;
            if (iconImageUrl) {
              return <img src={iconImageUrl} />;
            } else {
              return (
                <div style={{ backgroundColor: "var(--color-resource)" }}>
                  {resource.name.charAt(0)}
                </div>
              );
            }
          } else {
            return <div />;
          }
        }
      })()
    );
    return (
      <div
        key={bar.key}
        role="none"
        className={classNames(`${prefixClsTableBody}-row`, {
          [`${prefixCls}-row-toggler-resource`]: isResource,
          [`${prefixCls}-row-toggler-resource-border`]: isResource && rowIndex > 0,
        })}
        style={{
          height: rowHeight,
          top: (rowIndex + start) * rowHeight + TOP_PADDING,
        }}
        onClick={() => {
          // 行クリックでタスクまでスクロール
          const translateX = bar.translateX - 210; // スタート位置を7日分ずらす
          store.setTranslateX(translateX);
          // onRow?.onClick(bar.record);
        }}
        onMouseEnter={() => setIsHoveredRecordId(bar.record.id)}
        onMouseLeave={() => setIsHoveredRecordId("")}
      >
        {isResource ? (
          <div
            className={classNames(
              `${prefixClsTableBody}-icon`,
              // `${prefixClsTableBody}-icon-resource`,
              {
                ["active"]: !bar._collapsed,
              },
            )}
            onClick={(event) => {
              event.stopPropagation();
              if (onExpand) onExpand(bar.task.record, !bar._collapsed);
              store.setRowCollapse(bar.task, !bar._collapsed);
            }}
          >
            {ImageUrl}
          </div>
        ) : (
          ""
        )}
        {columns.map((column, index) => (
          <div
            key={column.name}
            className={`${prefixClsTableBody}-cell`}
            style={{
              width: columnsWidth[index],
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              textAlign: column.align ? column.align : "left",
              // paddingLeft: bar._depth !== 0 ? 16 * bar._depth + 40 : 28,
              paddingLeft: 20,
              ...column.style,
            }}
          >
            {index === 0 &&
              new Array(bar._depth).fill(0).map((_, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames(
                    `${prefixClsTableBody}-row-indentation`,
                    {
                      [`${prefixClsTableBody}-row-indentation-hidden`]:
                        (isLastChild && i === bar._depth - 2) || i >= 1,
                      [`${prefixClsTableBody}-row-indentation-both`]:
                        i === bar._depth - 1,
                    },
                  )}
                  style={{
                    top: -(rowHeight / 2) + 1,
                    left: tableIndent * i + 22,
                    width:
                      bar._depth <= 1 &&
                      !bar.record.isOverlap &&
                      !bar.record.isGap
                        ? tableIndent - 12
                        : 0,
                  }}
                />
              ))}
            {index === 0 && bar._childrenCount > 0 && (
              <div
                style={{
                  position: "absolute",
                  left:
                    bar._depth === 0 ? "auto" : tableIndent * bar._depth + 15,
                  right: bar._depth !== 0 ? "auto" : 0,
                  background: "#F5F6F7",
                  zIndex: 9,
                  transform: "translateX(-49%)",
                  padding: 1,
                }}
              >
                {expandIcon ? (
                  expandIcon({
                    level: bar._depth,
                    collapsed: bar._collapsed,
                    onClick: (event) => {
                      event.stopPropagation();
                      if (onExpand) onExpand(bar.task.record, !bar._collapsed);
                      store.setRowCollapse(bar.task, !bar._collapsed);
                    },
                  })
                ) : (
                  <RowToggler
                    prefixCls={prefixCls}
                    level={bar._depth}
                    collapsed={bar._collapsed}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (onExpand) onExpand(bar.task.record, !bar._collapsed);
                      store.setRowCollapse(bar.task, !bar._collapsed);
                    }}
                  />
                )}
              </div>
            )}
            {!isResource &&
              (bar.record.isOverlap ? (
                <div
                  className={`${prefixClsTableBody}-icon ${prefixClsTableBody}-icon-overlap`}
                >
                  <BsExclamationLg />
                </div>
              ) : bar.record.isGap ? (
                <div
                  className={`${prefixClsTableBody}-icon ${prefixClsTableBody}-icon-blank`}
                ></div>
              ) : (
                <div className={`${prefixClsTableBody}-noicon`}></div>
              ))
            }
            <div
              className={`${prefixClsTableBody}-ellipsis`}
              style={{
                lineHeight: `${ROW_HEIGHT_RESOURCE}px`,
                color: bar.record.isDelayed ? BarColors.OVERDUE : "#000000",
              }}
            >
              <span>
                {column.render
                  ? column.render(bar.record)
                  : bar.record[column.name]}
              </span>
              {projectName !== "" && (
                <span className={`${prefixClsTableBody}-ellipsis-project-name`}>
                  {projectName}
                </span>
              )}
            </div>
            {bar.record.id === isHoveredRecordId && bar.record.isTask && (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onRow?.onClick(bar.record);
                }}
              >
                開く
              </button>
            )}
          </div>
        ))}
      </div>
    );
  })
};

const ObserverTableRows = observer(TableRows);
const TableBorders = () => {
  const { store, prefixCls } = useContext(Context);
  const { columns } = store;
  const columnsWidth = store.getColumnsWidth;
  const barList = store.getBarList;

  if (barList.length === 0) return null

  const prefixClsTableBody = `${prefixCls}-table-body`;
  return (
    <div role='none' className={`${prefixClsTableBody}-border_row`}>
      {columns.map((column, index) => (
        <div
          key={column.name}
          className={`${prefixClsTableBody}-cell`}
          style={{
            width: columnsWidth[index],
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            textAlign: column.align ? column.align : 'left',
            ...column.style,
          }}
        />
      ))}
    </div>
  );
};

const ObserverTableBorders = observer(TableBorders);

const TableBody: React.FC = () => {
  const { store, prefixCls } = useContext(Context);
  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.persist();
      store.handleMouseMove(event);
    },
    [store],
  );

  const handleMouseLeave = useCallback(() => {
    store.handleMouseLeave();
  }, [store]);

  const prefixClsTableBody = `${prefixCls}-table-body`;

  return (
    <div
      className={prefixClsTableBody}
      style={{
        width: store.tableWidth,
        height: store.bodyScrollHeight,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <ObserverTableBorders />
      <ObserverTableRows />
    </div>
  );
};

export default observer(TableBody);
