import React, { HTMLAttributes, ReactNode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

//models
import LoginUserModel from "src/conpath/models/LoginUserModel";

//components
import { RotatingLines } from "react-loader-spinner";
import { IconType } from "react-icons";
import {
  IoSettingsOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoPersonAddOutline,
  IoCardOutline,
} from "react-icons/io5";
import { Flip, ToastContainer, toast } from "react-toastify";
import Header from "src/conpath/components/layouts/Header";
//styles
import "./SettingLayout.scss";
import "react-toastify/dist/ReactToastify.css";

//constants
import { Paths } from "src/conpath/constants/Routes";
import Colors from "src/conpath/constants/Colors";
import { OrganizationRole } from "src/conpath/constants/Role";
import { APP_NAME } from "src/excalidraw/constants";

const TOAST_DEFAULT_DURATION = 2000;

const SectionName = {
  organization: "組織",
  account: "アカウント",
} as const;

type SectionName = typeof SectionName[keyof typeof SectionName];

type SettingItemType = {
  title: string;
  path: string;
  Icon: IconType;
  key: Paths;
  disabled: boolean;
  allowRoles?: OrganizationRole[],
};

// this is side bar items in the settings page.
const settingsList: { [key in SectionName]: SettingItemType[] } = {
  組織: [
    {
      title: "一般",
      path: Paths.settings,
      Icon: IoSettingsOutline,
      key: Paths.settings,
      disabled: false,
      allowRoles: [OrganizationRole.owner, OrganizationRole.member],
    },
    {
      title: "ユーザー管理",
      path: Paths.settings + Paths.users,
      Icon: IoPersonOutline,
      key: Paths.users,
      disabled: false,
      allowRoles: [OrganizationRole.owner, OrganizationRole.member],
    },
    {
      title: "チーム管理",
      path: Paths.settings + Paths.teams,
      Icon: IoPeopleOutline,
      key: Paths.teams,
      disabled: false,
      allowRoles: [OrganizationRole.owner, OrganizationRole.member],
    },
    {
      title: "リソース管理",
      path: Paths.settings + Paths.resources,
      Icon: IoPersonAddOutline,
      key: Paths.resources,
      disabled: false,
      allowRoles: [OrganizationRole.owner, OrganizationRole.member],
    },
    {
      title: "プラン",
      path: Paths.settings + Paths.billings,
      Icon: IoCardOutline,
      key: Paths.billings,
      disabled: false,
      allowRoles: [OrganizationRole.owner, OrganizationRole.member],
    },
    // {
    //   title: "休日設定",
    //   path: Paths.settings + Paths.holiday,
    //   Icon: IoCalendarOutline,
    //   key: Paths.holiday,
    //   disabled: true,
    // },
  ],
  アカウント: [
    {
      title: "プロフィール設定",
      path: Paths.settings + Paths.profile,
      Icon: IoPersonOutline,
      key: Paths.profile,
      disabled: false,
    },
    {
      title: "アカウント設定",
      path: Paths.settings + Paths.account,
      Icon: IoPersonOutline,
      key: Paths.account,
      disabled: false,
    },
    // {
    //   title: "支払い方法",
    //   path: Paths.settings + Paths.billings,
    //   Icon: IoCardOutline,
    //   key: Paths.billings,
    //   disabled: true,
    // },
  ],
};

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  currentPath: Paths;
  headerTitle: string;
  user: LoginUserModel | null | undefined;
  errorMessage?: string;
  successMessage?: string;
};

const SettingsLayout: React.FC<Props> = ({
  children,
  currentPath,
  headerTitle,
  user,
  errorMessage,
  successMessage,
}: Props) => {
  const navigate = useNavigate();
  // const [toast, setToast] = useState<{ message: string } | null>(null);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.success(successMessage);
    }
  }, [errorMessage, successMessage]);

  /**
   * navigation handler for the selected item's path
   */
  const handleNavigation = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const button: HTMLButtonElement = event.currentTarget;
    if (button.name) {
      navigate(button.name);
    }
  };

  if (!user) {
    return (
      <div className="spinner-container">
        <RotatingLines
          strokeColor={Colors.primary}
          strokeWidth="5"
          animationDuration="0.75"
          width="42"
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`設定 - ${APP_NAME}`}</title>
        </Helmet>
        <Header />
        <div className="layout">
          <ToastContainer
            transition={Flip}
            position="top-center"
            autoClose={TOAST_DEFAULT_DURATION}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="content">
            <div className="settings-sidebar">
              {Object.keys(settingsList)
                .filter((s) => {
                  const section = s as SectionName;
                  const settingContents = settingsList[section];
                  return settingContents.some((item) => item.allowRoles ? user?.organizationRole && item.allowRoles.includes(user.organizationRole) : true)
                })
                .map((s) => {
                  const section = s as SectionName;
                  const settingContents = settingsList[section];
                  return (
                    <React.Fragment key={section}>
                      <li key={section} style={{ listStyle: "none" }}>
                        <p className="section-title">{section}</p>
                        {settingContents.filter((item) => {
                          return item.allowRoles ? user?.organizationRole && item.allowRoles.includes(user.organizationRole) : true;
                        }).map((item) => {
                          const isSelected = item.key === currentPath;
                          return (
                            <button
                              className={
                                item.disabled
                                  ? "item item--disabled"
                                  : isSelected
                                    ? "item item--selected"
                                    : "item item--unselected"
                              }
                              onClick={handleNavigation}
                              name={item.path}
                              key={item.title}
                            >
                              <div className="icon">
                                <item.Icon />
                              </div>
                              <p className="title">{item.title}</p>
                            </button>
                          );
                        })}
                      </li>
                      <hr className="my-horizontal-line" />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className="content-wrapper">
            <div className="header">
              <h2>{headerTitle}</h2>
            </div>
            {children}
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default SettingsLayout;
