import { useState } from "react";
import { t } from "src/excalidraw/i18n";
import "./SettingsDialog.scss";
import ConfirmDialogEx from "./ConfirmDialogEx";
import { AppState } from "src/excalidraw/types";
import { DateRange, Range } from "react-date-range";
import { ja } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ExcalidrawElement } from "src/excalidraw/element/types";
import { getNonDeletedElements } from "src/excalidraw/element";
import { isMilestoneElement, isTaskElement } from "../element/typeChecks";
import React from "react";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";

const SettingsDialog = ({
  elements,
  appState,
  updateData,
}: {
  elements: readonly ExcalidrawElement[];
  appState: AppState;
  updateData: (formData?: string[]) => void;
}) => {
  const setAppState = useExcalidrawSetAppState();
  const [error, setError] = useState(false);
  const [ranges, setRanges] = useState<[Range]>([
    {
      startDate: new Date(appState.projectStartDate),
      endDate: new Date(appState.projectEndDate),
      key: "selection",
    },
  ]);

  const onClose = () => {
    setAppState({ openDialog: null });
  };

  // CHANGED:ADD 2023-1-26 #511
  const onChange = React.useCallback((value: [Range]) => {
    setRanges(value);

    if (value.length > 0 && value[0].startDate && value[0].endDate) {
      const startDate = new Date(value[0].startDate);
      const endDate = new Date(value[0].endDate);
      endDate.setDate(endDate.getDate() + 1);

      const isOutOfRange = getNonDeletedElements(elements).some((element) => {
        if (isTaskElement(element)) {
          if (
            element.startDate.getTime() < startDate.getTime() ||
            element.endDate.getTime() > endDate.getTime()
          ) {
            return true;
          }
        } else if (isMilestoneElement(element)) {
          if (
            element.date.getTime() < startDate.getTime() ||
            element.date.getTime() > endDate.getTime()
          ) {
            return true;
          }
        }
      });

      setError(isOutOfRange);
    } else {
      setError(true);
    }
  }, [ranges]);

  return (
    <>
      <ConfirmDialogEx
        onConfirm={() => {
          updateData([
            ranges[0].startDate!.toDateString(),
            ranges[0].endDate!.toDateString(),
          ]);

          onClose();
        }}
        onCancel={onClose}
        title={t("settingsDialog.title")}
        className={"SettingsDialog"}
        disabled={error}
      >
        <div>
          <DateRange
            ranges={ranges}
            onChange={(item) => onChange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            months={2}
            dateDisplayFormat={"yyyy/MM/dd"}
            monthDisplayFormat={"yyyy/MM"}
            locale={ja}
            direction="horizontal"
            rangeColors={["#46AADF"]}
          />
        </div>
        {/* CHANGED:UPDATE 2023/01/27 #533 */}
        <span className="Dialog__error_message">
          {error && t("errors.invalidProjectDate")}
        </span>
      </ConfirmDialogEx>
    </>
  );
};

export default SettingsDialog;
