import React, { useEffect, useRef } from "react";
import { formatDate } from "src/utils/dateUtils";
import { nanoid } from "nanoid";
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

//style
import "./ChecklistView.scss";

//components
import Checkbox from "../CheckBox";
import { CompleteIcon, PlusIcon, TrashIcon } from "../icons";
import HoverToolTip from "src/conpath/components/HoverToolTip";

//models
import TaskModel from "src/conpath/models/TaskModel";

//constants
import { DocumentUpdateState } from "src/conpath/constants/General";
import { UrlPopover } from "src/excalidraw/extensions/components/EditTaskCheckList";

//hooks
import useChecklist from "src/excalidraw/extensions/hooks/useChecklist";

interface Props {
  task: TaskModel;
}

const ChecklistView: React.FC<Props> = (props: Props) => {
  const { task } = props;
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const listRef = useRef<HTMLUListElement>(null);

  const { allowPaste, setAllowPaste, handlePaste } = useChecklist({ loginUser, taskModel: task });
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const fetchChecklist = async () => {
      await task.getChecklists();
    }

    fetchChecklist();
  }, [task]);

  const onAddButtonPressed = () => {
    if (!loginUser) return;

    task.createChecklist({
      id: nanoid(),
      title: "",
      url: "",
      isChecked: false,
      checkedBy: null,
      checkedAt: null,
      createdBy: loginUser.id,
      createdAt: new Date(),
    });
    listRef.current?.scrollBy({
      top: listRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  if (!selectedOrganization || !loginUser) return null;

  return (
    <div 
      onMouseEnter={() => {
        if (!task.checklists.length) {
          timeoutRef.current = window.setTimeout(() => {
            setAllowPaste(true);
          }, 2000); // 2秒後にツールチップを表示
        };
      }}
      onMouseLeave={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setAllowPaste(false);
      }}
      className={task.checklists.length ? "roadmap-checklist no-border" : "roadmap-checklist"}>
 
      <HoverToolTip 
        position="top"
        tailCenter={true}
        style={{
          width: 250,
          position: 'absolute',
          top: 'calc(50% - 50px)',
          left: 'calc(50% - 125px)',
        }}
        isShow={allowPaste} 
        value="プラスボタンもしくはエクセルのセルをコピー&ペーストで挿入できます。" />

      <ul ref={listRef} className="roadmap-checklist__checklist-items">
        {task.checklists.filter((item) => item.documentState !== DocumentUpdateState.DELETE).length ? 
        task.checklists
          .filter((item) => item.documentState !== DocumentUpdateState.DELETE)
            .map((check) => {
              return (
                <li
                  key={check.id}
                  className="roadmap-checklist__checklist-items item"
                >
                  <div className="v-stack w-full">
                    <div className="checkbox">
                      <Checkbox
                        props={{
                          onClick: () => {
                            check.toggleCheckState(loginUser!);
                          },
                          checked: check.isChecked,
                        }}
                      />
                      <div className="relative w-full">
                        <textarea
                          className="title-input"
                          autoComplete="off"
                          value={check.title}
                          onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => {
                            handlePaste(e as any, check);
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) => {
                            check.changeTitle(e.currentTarget.value);
                          }}/>
                        <UrlPopover
                          props={{ checklist: check, buttonSize: "34px" }}
                        />
                      </div>
                      <button 
                        onClick={() => {
                          task.removeChecklist(check);
                        }}>
                        <TrashIcon />
                      </button>
                    </div>
                    {check.isChecked && (
                      <div className="labels">
                        <div
                          style={{
                            height: "15px",
                            width: "15px",
                            position: "relative",
                            borderLeft: "1px solid #DDD",
                            borderBottom: "1px solid #DDD",
                          }}
                        />
                        <CompleteIcon
                          style={{
                            transform: `scale(${18 / 22})`,
                            alignSelf: "center",
                          }}
                        />
                        <div className="h-stack center">
                          <p className="leading-[28px]">
                            {selectedOrganization.findUserById(
                              check.checkedBy || "",
                            )?.username || ""}
                          </p>
                          <p className="date-label">
                            {formatDate(check.checkedAt)}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              );
            })
          :
          <li className="roadmap-checklist__checklist-items no-items">
            <p>チェックリストはありません。</p>
          </li>
        }
        <button
          className="roadmap-checklist__add-item-button"
          onClick={onAddButtonPressed}
          >
          <PlusIcon />
        </button>
      </ul>
    </div>
  );
};

export default observer(ChecklistView);
