import { GRID_SIZE } from "src/excalidraw/constants";
import { mutateElement } from "src/excalidraw/element/mutateElement";
import { ElementsMap, NonDeleted, NonDeletedExcalidrawElement } from "src/excalidraw/element/types";
import Scene from "src/excalidraw/scene/Scene";
import { AppState } from "src/excalidraw/types";
import Calendar from "./calendar";
import { updateBoundElementsEx } from "./element/binding";
import { TaskElementEditor } from "./element/taskElementEditor";
import { isLinkElement, isTaskElement } from "./element/typeChecks";
import { ExcalidrawTaskElement } from "./element/types";

export const alignTaskElements = (
  selectedElement: NonDeleted<ExcalidrawTaskElement>,
  elementsMap: ElementsMap,
  appState: AppState,
  calendar: Calendar,
  leftOrRight: "left" | "right",
): NonDeletedExcalidrawElement[] => {
  const gridSize = appState.gridSize ? appState.gridSize : GRID_SIZE;

  const updatedElements: NonDeletedExcalidrawElement[] = [];
  const movableElements: ExcalidrawTaskElement[] = [];
  const queue: ExcalidrawTaskElement[] = [];
  queue.push(selectedElement);

  while (queue.length > 0) {
    const v = queue.shift() as ExcalidrawTaskElement;

    const dependencies =
      leftOrRight === "left" ? v.nextDependencies : v.prevDependencies;

    dependencies?.forEach((e) => {
      const u = Scene.getScene(selectedElement)?.getNonDeletedElement(e);
      if (u && isTaskElement(u)) {
        movableElements.push(u);
        queue.push(u);
      }
    });
  }

  const minDate = new Date(appState.projectStartDate);
  const maxDate = new Date(appState.projectEndDate);

  movableElements
    .filter((element) => !element.locked)
    .forEach((element) => {
      const startX = element.x;
      const endX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
        element,
        -1,
        elementsMap,
      )[0];

      const dependencies =
        leftOrRight === "left"
          ? element.prevDependencies
          : element.nextDependencies;

      const endXLimits: Set<number> = new Set();
      const startXLimits: Set<number> = new Set();

      dependencies?.forEach((dependElementId) => {
        const bindingElement =
          Scene.getScene(selectedElement)?.getNonDeletedElement(
            dependElementId,
          );
        if (bindingElement && isTaskElement(bindingElement)) {
          let targetStartX = bindingElement.x;
          let targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
            bindingElement,
            -1,
            elementsMap,
          )[0];

          // CHANGED:ADD 2023-2-21 #590
          const linkElement = Scene.getScene(selectedElement)!
            .getNonDeletedElements()
            .find(
              (el) =>
                isLinkElement(el) &&
                el[leftOrRight === "left" ? "endBinding" : "startBinding"]
                  ?.elementId === element.id &&
                el[leftOrRight === "left" ? "startBinding" : "endBinding"]
                  ?.elementId === dependElementId,
            );

          if (leftOrRight === "left") {
            // CHANGED:ADD 2023-2-21 #590
            if (isLinkElement(linkElement) && linkElement.locked) {
              const startDate = calendar.getPointDate(targetEndX);

              for (
                const endDate = new Date(startDate);
                endDate.getTime() <= maxDate.getTime();
                endDate.setDate(endDate.getDate() + 1)
              ) {
                const isHoliday = appState.holidays?.some(
                  (holiday) =>
                    new Date(holiday).toDateString() === endDate.toDateString(),
                );

                const duration = calendar.getDuration(startDate, endDate);
                if (duration === linkElement.duration && !isHoliday) {
                  break;
                }

                targetEndX += gridSize;
              }
            }

            const distance = targetEndX - startX;
            startXLimits.add(distance);
          } else if (leftOrRight === "right") {
            // CHANGED:ADD 2023-2-21 #590
            if (isLinkElement(linkElement) && linkElement.locked) {
              const endDate = calendar.getPointDate(targetStartX);
              for (
                const startDate = new Date(endDate);
                startDate.getTime() >= minDate.getTime();
                startDate.setDate(startDate.getDate() - 1)
              ) {
                const isHoliday = appState.holidays?.some(
                  (holiday) =>
                    new Date(holiday).toDateString() ===
                    startDate.toDateString(),
                );

                const duration = calendar.getDuration(startDate, endDate);
                if (duration === linkElement.duration && !isHoliday) {
                  break;
                }

                targetStartX -= gridSize;
              }
            }

            const distance = targetStartX - endX;
            endXLimits.add(distance);
          }
        }
      });

      const startDate = new Date(element.startDate);
      const endDate = new Date(element.endDate);

      let offsetX = 0;
      let offsetWidth = 0;

      if (leftOrRight === "left") {
        offsetX = Math.max(...startXLimits);
        offsetWidth = -offsetX;

        startDate.setDate(startDate.getDate() + offsetX / gridSize);
        const date = new Date(endDate);
        date.setDate(date.getDate() - 1);

        for (
          ;
          startDate.getTime() < date.getTime();
          startDate.setDate(startDate.getDate() + 1)
        ) {
          const isHoliday = (
            element.holidays ? element.holidays : appState.holidays
          )?.some(
            (holiday) =>
              new Date(holiday).toDateString() === startDate.toDateString(),
          );

          if (!isHoliday) {
            break;
          }

          offsetX += gridSize;
          offsetWidth -= gridSize;
        }

        let duration = calendar.getDuration(
          startDate,
          endDate,
          element.holidays,
        );
        for (
          ;
          date.getTime() > startDate.getTime();
          date.setDate(date.getDate() - 1)
        ) {
          const isHoliday = (
            element.holidays ? element.holidays : appState.holidays
          )?.some(
            (holiday) =>
              new Date(holiday).toDateString() === date.toDateString(),
          );

          if (duration > element.duration && !isHoliday) {
            duration--;
          } else if (duration === element.duration && !isHoliday) {
            break;
          }

          offsetWidth -= gridSize;
          endDate.setDate(endDate.getDate() - 1);
        }
      } else if (leftOrRight === "right") {
        offsetWidth = Math.min(...endXLimits);

        endDate.setDate(endDate.getDate() + offsetWidth / gridSize);
        const date = new Date(endDate);
        date.setDate(date.getDate() - 1);

        for (
          ;
          date.getTime() > startDate.getTime();
          date.setDate(date.getDate() - 1)
        ) {
          const isHoliday = (
            element.holidays ? element.holidays : appState.holidays
          )?.some(
            (holiday) =>
              new Date(holiday).toDateString() === date.toDateString(),
          );

          if (!isHoliday) {
            break;
          }

          offsetWidth -= gridSize;
          endDate.setDate(endDate.getDate() - 1);
        }

        let duration = calendar.getDuration(
          startDate,
          endDate,
          element.holidays,
        );
        for (
          ;
          startDate.getTime() < date.getTime();
          startDate.setDate(startDate.getDate() + 1)
        ) {
          const isHoliday = (
            element.holidays ? element.holidays : appState.holidays
          )?.some(
            (holiday) =>
              new Date(holiday).toDateString() === startDate.toDateString(),
          );

          if (duration > element.duration && !isHoliday) {
            duration--;
          } else if (duration === element.duration && !isHoliday) {
            break;
          }

          offsetX += gridSize;
          offsetWidth -= gridSize;
        }
      }

      mutateElement(element, {
        x: element.x + offsetX,
        points: [
          ...element.points.slice(0, -1),
          [element.width + offsetWidth, 0],
        ],
        startDate,
        endDate,
      });

      updatedElements.push(element);
    });

  updatedElements.forEach((element) => {
    updateBoundElementsEx(
      element,
      elementsMap,
      appState,
      calendar
    );
  });

  return updatedElements;
};
