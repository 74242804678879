import React from "react";
import { useLocation } from "react-router-dom";

//styles
import "./InvalidInvitation.scss";

const InvalidInvitation: React.FC = () => {
  
  const { state } = useLocation();
  const { reason } = state;

  return (
    <div className="input__body">
      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
      <div className="input__card topSpacer">
        <h2 className="input__title">
          {reason}
        </h2>
        <div className="input__wrapper minSpacer">
          <p className="input__label">
            招待元に再度招待する様お申し付け下さい。
          </p>
        </div>
      </div>
    </div>
  )
};

export default InvalidInvitation;