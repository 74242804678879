import { KEYS } from "src/excalidraw/keys";
import { register } from "./register";
import { selectGroupsForSelectedElements } from "src/excalidraw/groups";
import { getNonDeletedElements, isTextElement } from "../element";
import { ExcalidrawElement } from "../element/types";
import { isLinearElement } from "../element/typeChecks";
import {
  isTaskElement, // CHANGED:ADD 2022-10-28 #14
  isLinkElement, // CHANGED:ADD 2022-11-2 #64
  isJobElement,
  isCommentElement, // CHANGED:ADD 2023-02-07 #579
} from "src/excalidraw/extensions/element/typeChecks"; // from extensions
import { LinearElementEditor } from "../element/linearElementEditor";
// CHANGED:ADD 2022-10-28 #14
import { TaskElementEditor } from "src/excalidraw/extensions/element/taskElementEditor"; // from extensions
// CHANGED:ADD 2022-11-2 #64
import { LinkElementEditor } from "src/excalidraw/extensions/element/linkElementEditor"; // from extensions

export const actionSelectAll = register({
  name: "selectAll",
  trackEvent: { category: "canvas" },
  perform: (elements, appState, value, app) => {
    if (appState.editingLinearElement) {
      return false;
    }
    const selectedElementIds = elements.reduce(
      (map: Record<ExcalidrawElement["id"], true>, element) => {
        if (
          !element.isDeleted &&
          !(isTextElement(element) && element.containerId) &&
          !isJobElement(element) && // CHANGED:ADD 2023-02-07 #579
          !isCommentElement(element) && // CHANGED:ADD 2023-12-20 #1138
          element.isVisible && // CHANGED:ADD 2023-03-23 #787
          appState.viewLayers.includes(element.layer) && // CHANGED:ADD 2024-10-5 #2114
          !element.locked
        ) {
          map[element.id] = true;
        }
        return map;
      },
      {},
    );

    return {
      appState: {
        ...appState,
        ...selectGroupsForSelectedElements(
          {
            editingGroupId: null,
            selectedElementIds,
          },
          getNonDeletedElements(elements),
          appState,
          app,
        ),
        selectedLinearElement:
          // single linear element selected
          Object.keys(selectedElementIds).length === 1 &&
          isLinearElement(elements[0])
            ? new LinearElementEditor(elements[0])
            : null,
        // CHANGED:ADD 2022-10-28 #14
        selectedTaskElement:
          // single task element selected
          Object.keys(selectedElementIds).length === 1 &&
            isTaskElement(elements[0])
            ? new TaskElementEditor(elements[0], app.scene, appState)
            : null,
        // CHANGED:ADD 2022-11-2 #64
        selectedLinkElement:
          // single link element selected
          Object.keys(selectedElementIds).length === 1 &&
            isLinkElement(elements[0])
            ? new LinkElementEditor(elements[0], app.scene, appState)
            : null,
      },
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.selectAll",
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.A,
});
