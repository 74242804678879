import React from "react";
// import { PlusPromoIcon } from "../../components/icons";
import { MainMenu } from "../../../packages/excalidraw/index";
// import { LanguageList } from "./LanguageList";

export const AppMainMenuEx: React.FC<{
}> = React.memo((props) => {
  return (
    <MainMenu contentClass="file-menu">
      <MainMenu.DefaultItems.GoToDashboard /> {/* CHANGED:ADD 2024-08-27 #2174 */}
      <MainMenu.DefaultItems.LoadScene />
      <MainMenu.DefaultItems.SaveToActiveFile />
      <MainMenu.DefaultItems.Export />
      <MainMenu.DefaultItems.SaveAsImage />
      <MainMenu.DefaultItems.PrintAsImage />
      <MainMenu.DefaultItems.Help />
      <MainMenu.DefaultItems.ClearCanvas />
      {/* <MainMenu.DefaultItems.MenuSeparator /> */}
      <MainMenu.DefaultItems.ChangeCanvasBackground />
    </MainMenu>
  );
});
