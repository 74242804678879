export const OrganizationActivityState = {
  taskClosed: 10, // タスクを完了
  taskAllChecked: 20, // タスクのチェックリストが全て完了
  taskMoved: 30, // タスクが移動
  taskDelay: 31, // タスクが延期
  milestoneShortening: 40, // マイルストーンまでの余裕日数が短縮
  addComment: 1000, // コメントが追加
} as const;

export type OrganizationActivityState = typeof OrganizationActivityState[keyof typeof OrganizationActivityState];

export const OrganizationActivityStateMap: { [key in OrganizationActivityState]: string } = {
  10: "${username}さんが${text}を完了しました",
  20: "${username}さんが${text}のチェックリストを完了にしました",
  30: "${username}さんが${text}を移動しました",
  31: "${username}さんが${text}を延期しました",
  40: "${username}さんが${text}の余裕日数を${beforeValue}日->${afterValue}日に短縮しました",
  1000: "${username}さんがコメントしました",
};