import { observer } from 'mobx-react-lite';
import React, { memo, useCallback, useContext, useEffect, useRef } from 'react';
import Context from '../../context';
import BarList from '../bar-list';
import BarThumbList from '../bar-thumb-list';
import Dependencies from '../dependencies';
import DragPresent from '../drag-present';
import Today from '../today';
import './index.scss';

const Chart: React.FC = () => {
  const { store, prefixCls } = useContext(Context);
  const { tableWidth, viewWidth, bodyScrollHeight, translateX, chartElementRef } = store;
  const minorList = store.getMinorList();

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.persist();
      store.handleMouseMove(event);

      if (store.isDragging) {
        const trasnlateX = store.dragStartX - event.clientX;
        store.setTranslateX(trasnlateX);
      }
    },
    [store],
  );

  const handleMouseLeave = useCallback(() => {
    store.handleMouseLeave();
  }, [store]);

  const handleMouseDown = (
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      const startX = event.clientX + store.translateX;
      store.startDrag(startX);
      
      window.addEventListener('mouseup', handleMouseUp);

      // const element = chartElementRef.current;
      // if (element) element.style.cursor = "grabbing";
    });

  const handleMouseUp = (() => {
    store.endDrag();
    window.removeEventListener('mouseup', handleMouseUp);

    // const element = chartElementRef.current;
    // if (element) element.style.cursor = "";
  });

  useEffect(() => {
    const element = chartElementRef.current;
    if (element) element.addEventListener('wheel', store.handleWheel)

    return () => {
      if (element) element.removeEventListener('wheel', store.handleWheel)
    };
  }, [chartElementRef, store]);

  return (
      <div
        ref={chartElementRef}
        className={`${prefixCls}-chart`}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          left: tableWidth,
          width: viewWidth,
          height: bodyScrollHeight,
        }}
      >
        <svg
          className={`${prefixCls}-chart-svg-renderer`}
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          width={viewWidth}
          height={bodyScrollHeight}
          viewBox={`${translateX} 0 ${viewWidth} ${bodyScrollHeight}`}
        >
          <defs>
            <pattern
              id='repeat'
              width='4.5'
              height='10'
              patternUnits='userSpaceOnUse'
              patternTransform='rotate(70 50 50)'
            >
              <line stroke='#c6c6c6' strokeWidth='1px' y2='10' />
            </pattern>
          </defs>
          {minorList.map(item =>
            item.isWeek ? (
              <g key={item.key} stroke='#f0f0f0'>
                <path d={`M${item.left},0 L${item.left},${bodyScrollHeight}`} />
                <rect
                  fill='url(#repeat)'
                  opacity='0.5'
                  strokeWidth='0'
                  x={item.left}
                  y={0}
                  width={item.width}
                  height={bodyScrollHeight}
                />
              </g>
            ) : (
              <g key={item.key} stroke='#f0f0f0'>
                <path d={`M${item.left},0 L${item.left},${bodyScrollHeight}`} />
              </g>
            ),
          )}
          <DragPresent />
        </svg>
        <svg
          className={`${prefixCls}-chart-dependencies-svg-renderer`}
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          width={viewWidth}
          height={bodyScrollHeight}
          viewBox={`${translateX} 0 ${viewWidth} ${bodyScrollHeight}`}
        >
          <Dependencies />
        </svg>
        <div
          className={`${prefixCls}-render-chunk`}
          style={{
            height: bodyScrollHeight,
            transform: `translateX(-${translateX}px`,
            zIndex: 0,
          }}
        >
          <BarThumbList />
          <BarList />
          <Today />
        </div>
      </div>
  );
};

export default memo(observer(Chart));
