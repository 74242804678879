import React from "react";
import "./SearchTagsInput.scss";
import Badge from './Badge'
import { MagnifierIcon } from "./icons";

type Props = React.ComponentPropsWithoutRef<'input'> & {
  tags: string[]
  onChangeTags?: (tags: string[]) => void
  className?: string
}
const SearchTagsInput: React.FC<Props> = ({ onChangeTags, tags = [], className, ...props }: Props) => {
  const onClose = (i: number) => {
    const newTags = [...tags]
    newTags.splice(i, 1)
    tags = newTags
    onChangeTags && onChangeTags(tags)
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.nativeEvent.isComposing) return


    const value = e.currentTarget.value
    if (!value.length && e.key == 'Backspace' && tags.length > 0) {
      onClose(tags.length - 1)
      return
    }

    if (e.key !== 'Enter' || !value.trim()) return
    const newTags = [...tags, value]
    onChangeTags && onChangeTags(newTags)
    e.currentTarget.value = ''
    e.preventDefault()
  }

  return (
    <div
      className={`flex flex-nowrap items-center leading-tight px-2 rounded bg-white h-[45px] max-w-[550px] overflow-x-auto overflow-y-hidden tags-container ${
        className ? className : ""
      }`}
    >
      <MagnifierIcon className="icon pr-2" />
      {tags.map((tag, i) => {
        return (
          <Badge
            key={i}
            onClose={() => onClose(i)}
            className={""}>
            {tag}
          </Badge>
        )
      })}
      <input
        type="text"
        className={'border-0 outline-none flex-grow'}
        onKeyDown={handleKeyDown}
        {...props}
      />
    </div>
  )
};

export default React.memo(SearchTagsInput);
