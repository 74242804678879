import { AlignLeftIcon, AlignRightIcon } from "src/excalidraw/components/icons";
import { ToolButton } from "src/excalidraw/components/ToolButton";
import { ExcalidrawElement } from "src/excalidraw/element/types";
import { t } from "src/excalidraw/i18n";
import { AppClassProperties, AppState } from "src/excalidraw/types";
import { arrayToMap } from "src/excalidraw/utils";
import { register } from "src/excalidraw/actions/register";
import { alignTaskElements } from "../alignTask";
import Calendar from "../calendar";
import { isTaskElement } from "../element/typeChecks";
import { ExcalidrawTaskElement } from "../element/types";

const alignSelectedTaskElement = (
  elements: readonly ExcalidrawElement[],
  appState: Readonly<AppState>,
  app: AppClassProperties,
  leftOrRight: "left" | "right",
) => {
  const selectedElement = app.scene.getSelectedElements({
    selectedElementIds: appState.selectedElementIds,
    includeBoundTextElement: false, //CHANGED:UPDATE 2023-02-15 #714
  })[0] as ExcalidrawTaskElement;

  const calendar = new Calendar(
    appState.gridSize,
    appState.projectStartDate,
    appState.holidays,
  );

  const updatedElements = alignTaskElements(
    selectedElement,
    app.scene.getElementsMapIncludingDeleted(),
    appState,
    calendar,
    leftOrRight,
  );

  const updatedElementsMap = arrayToMap(updatedElements);

  return elements.map(
    (element) => updatedElementsMap.get(element.id) || element,
  );
};

export const actionAlignLeftTask = register({
  name: "alignLeftTask",
  contextItemLabel: "labels.alignLeftTask",
  trackEvent: { category: "menu" },
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: false,
    });
    return (
      selectedElements.length === 1 &&
      isTaskElement(selectedElements[0]) &&
      !!selectedElements[0].nextDependencies &&
      selectedElements[0].nextDependencies.length > 0
    );
  },
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedTaskElement(elements, appState, app, "left"),
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: false,
    });
    const visible =
      selectedElements.length === 1 &&
      isTaskElement(selectedElements[0]) &&
      !!selectedElements[0].nextDependencies &&
      selectedElements[0].nextDependencies.length > 0;

    return (
      <ToolButton
        type="button"
        icon={AlignLeftIcon}
        title={t("labels.alignLeftTask")}
        aria-label={t("labels.alignLeftTask")}
        onClick={() => updateData(null)}
        visible={visible}
      />
    );
  },
});

export const actionAlignRightTask = register({
  name: "alignRightTask",
  contextItemLabel: "labels.alignRightTask",
  trackEvent: { category: "menu" },
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: false,
    });
    return (
      selectedElements.length === 1 &&
      isTaskElement(selectedElements[0]) &&
      !!selectedElements[0].prevDependencies &&
      selectedElements[0].prevDependencies.length > 0
    );
  },
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedTaskElement(elements, appState, app, "right"),
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: false,
    });
    const visible =
      selectedElements.length === 1 &&
      isTaskElement(selectedElements[0]) &&
      !!selectedElements[0].prevDependencies &&
      selectedElements[0].prevDependencies.length > 0;

    return (
      <ToolButton
        type="button"
        icon={AlignRightIcon}
        title={t("labels.alignRightTask")}
        aria-label={t("labels.alignRightTask")}
        onClick={() => updateData(null)}
        visible={visible}
      />
    );
  },
});
