import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
//components
import SettingsLayout from "src/conpath/components/settings/SettingLayout";
import SearchBar from "src/conpath/components/SearchBar";
import { Button } from "src/conpath/components/Button";
import { Menu, Transition } from "@headlessui/react";
import { RotatingLines } from "react-loader-spinner";
import { Dialog } from "@headlessui/react";
import { PiDotsThreeCircleLight } from "react-icons/pi";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";

//constants
import { Paths } from "../../constants/Routes";
import "./TeamsSettings.scss";
import Colors from "src/conpath/constants/Colors";
import { TeamInputForm } from "src/conpath/interfaces/Team";
import { Tooltip } from "src/excalidraw/components/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//utils

//types & interfaces
const TeamsSettings: React.FC = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [team, setTeams] = useState<TeamInputForm>({
    id: "",
    name: "",
    userIds: [],
    createdBy: "",
    createdAt: new Date(),
  });
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (state && state.message) {
      toast.success(state.message, {
        toastId: state.message,
      })
      navigate(window.location, { replace: true });
    }
  }, [state]);

  const onCreateTeamButtonPressed = useCallback(() => {
    navigate(`${Paths.settings}${Paths.teams}${Paths.create}`);
  }, [navigate]);

  const onEditTeamButtonPressed = useCallback((teamId: string) => {
    navigate(`${Paths.settings}${Paths.teams}/${teamId}${Paths.edit}`, {
      state: { teamId: teamId },
    });
  },
    [navigate],
  );

  function closeDeleteModal() {
    setModalErrorMessage("");
    setIsDeleteModalOpen(false);
  }

  function deleteTeam(team: TeamInputForm) {
    setTeams(team);
    setIsDeleteModalOpen(true);
  }

  useEffect(() => {
    selectedOrganization?.subscribeTeamsCollection();

    return () => {
      selectedOrganization?.unsubscribeTeamsCollection();
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  const onDeleteTeam = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;

    const _team = selectedOrganization.teams.find(
      (v) => v.id === team.id,
    );
    if (!_team) return;

    const result = await _team?.delete();

    if (result.error) {
      setModalErrorMessage(result.error);
      return;
    }

    for (const project of selectedOrganization.projects) {
      if (Object.keys(project.teams).find((key) => key === _team.id)) {
        const teams = { ...project.teams };
        delete teams[_team.id];
        await project.saveTeams(teams);
      }
    }

    setSuccessMessage("チームの削除が完了しました。");
    closeDeleteModal();
  }, [team, setErrorMessage, selectedOrganization, loginUser]);

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle="チーム管理"
        currentPath={Paths.teams}
      >
        {(loginUser?.isOrganizationOwner() || loginUser?.isOrganizationMember()) && (
          <Button
            style={{ width: "140px", fontSize: "14px" }}
            theme="secondary"
            onClick={onCreateTeamButtonPressed}
          >
            + 新規追加
          </Button>
        )}
        <div className="setting-body">
          <div className="section team-section">
            <div className="section-header">
              <h4>チーム一覧</h4>
            </div>
            <div className="section team-list-section">
              {selectedOrganization && loginUser ? (
                <div className="table-wrapper">
                  <SearchBar
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onEnterKeyPressed={() => null}
                    placeholder="チーム検索"
                  />
                  <table>
                    <thead>
                      <tr>
                        <th className="th teamname">名称</th>
                        <th className="th count">メンバー</th>
                        <th className="th users"></th>
                        <th className="th edit"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrganization
                        .teams
                        .filter((v) => !_.isEmpty(searchText)
                          ? v.name.toLowerCase().includes(searchText.toLowerCase())
                          : true)
                        .map((team, index) => {
                          return (
                            <tr
                              key={team.id}
                              className={index % 2 === 0 ? "td even" : "td"}
                            >
                              <td className="td teamname">{team.name}</td>
                              <td className="td count">
                                <p>{team.userIds.length}人のメンバー</p>
                              </td>
                              <td className="td users">
                                <div className="icon-wrapper">
                                  {
                                    team.userIds
                                      .slice(0, 10)
                                      .map((userId) => {
                                        const user = selectedOrganization?.findUserById(userId);

                                        return (
                                          <Tooltip key={userId} label={user?.username || ""}>
                                            {user && user.profileImageUrl
                                              ? <img src={user.profileImageUrl} />
                                              : <div>
                                                {user ? user.username.charAt(0) : ""}
                                              </div>
                                            }
                                          </Tooltip>
                                        )
                                      })
                                  }
                                </div>
                              </td>
                              <td className="td edit">
                                {loginUser?.isOrganizationOwner() && (
                                  <Menu
                                    as="div"
                                    className="relative flex items-center text-left"
                                  >
                                    <Menu.Button>
                                      <PiDotsThreeCircleLight />
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute top-[-8px] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${active
                                                ? "bg-[#57AEDF] text-white"
                                                : "text-gray-900"
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                              onClick={() =>
                                                onEditTeamButtonPressed(team.id)
                                              }
                                            >
                                              編集する
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={`${active
                                                ? "bg-[#57AEDF] text-white"
                                                : "text-gray-900"
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                              onClick={() =>
                                                deleteTeam(team)
                                              }
                                            >
                                              削除する
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <TeamsDeleteModal
                    isOpen={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    onSubmit={onDeleteTeam}
                    errorMessage={modalErrorMessage}
                  />
                </div>
              ) : (
                <div className="loader-wrapper">
                  <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="42"
                    visible={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
});

interface TeamsDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  errorMessage: string;
}

const TeamsDeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
  errorMessage,
}: TeamsDeleteModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  チームを削除しますか？
                </Dialog.Title>
                {errorMessage && (
                  <span className="text-sm text-red-500 mt-[2px]">
                    {errorMessage}
                  </span>
                )}
                <div className="account-setting--buttonRow p-0 mt-8">
                  <button onClick={onClose} className="button solid">
                    キャンセル
                  </button>
                  <button onClick={onSubmit} className="button info">
                    削除する
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TeamsSettings;
