import _ from "lodash";
import { runInAction, observable, makeObservable } from "mobx";
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
//models
import LoginUserModel from "src/conpath/models/LoginUserModel";
//interfaces
import LoginUser from "src/conpath/interfaces/LoginUser";
import { SignInForm, SignUpForm } from "src/conpath/interfaces/AuthForm";
import { AcceptInvitationRequestParam, DeclineInvitationRequestParam } from "src/conpath/interfaces/HttpRequests";
//helper
import UserStoreValidation from "src/conpath/helpers/validations/UserStoreValidation";
//firebase
import { auth, db, functions } from "src/configs/firebase";
//constant
import { FirestoreCollections } from "src/conpath/constants/FirestoreCollections";
import { FirebaseHttpsRequests } from "src/conpath/constants/FirebaseHttpsRequests";
import { 
  AcceptInvitationErrorTypes, 
  AcceptInvitationRequestErrors,
  DeclineOrganizationInvitationErrorType,
  DeclineOrganizationInvitationErrors
} from "src/conpath/constants/errors/OrganizationUserRequestErrors";
import { httpsCallable } from "firebase/functions";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

class UserStore extends UserStoreValidation {
  @observable
  loginUser: LoginUserModel | null = null;
  errorMessage: { [key: string]: string } = {
    "auth/invalid-email": "正しいメールアドレスを入力してください。",
    "auth/user-not-found": "ユーザーが見つかりません。メールアドレスとパスワードを確認してください。",
    "auth/wrong-password": "パスワードが間違っています。再度入力してください。",
    "auth/email-already-in-use": "このメールアドレスは既に登録されています。",
    "auth/weak-password": "パスワードは6文字以上にしてください",
    "auth/network-request-failed": "ネットワークに接続できません。インターネット接続を確認してください。",
  };

  @observable
  isInvitationProcess: boolean = false;

  constructor() {
    super();
    makeObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: [
        {
          key: "loginUser",
          serialize: (value) => {
            return value;
          },
          deserialize: (value) => {
            if (value) {
              return new LoginUserModel(value);
            }
            return null;
          },
        },
      ],
      storage: window.localStorage,
    });

    makePersistable(this, {
      name: 'InvitationState',
      properties: [{
        key: 'isInvitationProcess',
        'serialize': (value) => {
          return value ? "true" : "false";
        },
        'deserialize': (value) => {
          return value === "true";
        }
      }],
      storage: window.localStorage
    });
  }

  public async signUp(form: SignUpForm, isInvited: boolean): Promise<{ userId?: string, error?: string }> {

    const validationError = this.validateSignUpForm(form);
    if (validationError) {
      return { error: validationError };
    }
    if (isInvited) {
      runInAction(() => {
        this.isInvitationProcess = true;
      })
    }

    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password,
      );
      const user = result.user;
      if (!user) {
        throw new Error("Failed to create the user.");
      }
      await updateProfile(user, {
        displayName: form.username,
      });
      await this.createUserDocument({
        user: { ...form, uid: user.uid },
        isInvited,
      });

      return { userId: user.uid };
    } catch (err: any) {
      console.log(err);
      //Sentry
      return {
        error: this.errorMessage[err.code] || "Failed to create the user.",
      };
    }
  }

  public async signIn(form: SignInForm, isInvited: boolean): Promise<{ userId?: string, error?: string }> {

    const validationError = this.validateSignInForm(form);
    if (validationError) {
      return { error: validationError };
    }

    if (isInvited) {
      runInAction(() => {
        this.isInvitationProcess = true;
      });
    }
    
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        form.email,
        form.password,
      );
      const user = result.user;
      if (!user) {
        throw new Error("Failed to get the user.");
      }

      if (!isInvited) {
        await this.setUser(user.uid);
      }

      return { userId: user.uid };
    } catch (err: any) {
      console.log(err);
      //Sentry
      return { error: this.errorMessage[err.code] || "Failed to sign in." };
    }
  }

  public async signOut() {
    if (!this.loginUser) return;

    auth
      .signOut()
      .then(() => {
        runInAction(async () => {
          await clearPersistedStore(this);
          this.loginUser = null;
        });
      })
      .catch((error) => {
        console.log(error);
        //Sentry
      });
  }

  public async sendPasswordResetEmail(email: string): Promise<{ error?: string }> {

    if (!this.isEmailValid(email)) {
      return { error: "正しいメールアドレスを入力してください。" };
    }
    return new Promise((resolve) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log("Email sent successfully");
          resolve({});
        })
        .catch((error) => {
          const errorCode = error.code;
          const message = error.message;
          // Sentry here
          console.log(`[Error] error code: ${errorCode}, message: ${message}`);
          resolve({
            error: this.errorMessage[errorCode] || "パスワード再設定のメールを送信できませんでした。",
          });
      });
    })
  }

  /**
   * user modelがセットされてない時且つAuthが通っている時にUserModelを取得しModelを作成する。
   * ページ推移毎にAuthチェックが行われ、Usersドキュメントに組織の権限のフィールドがないため、Undefinedとなってしまい、ログインしたユーザーの組織における権限チェックが毎回機能しない為、以下のように不必要な時はUserModelを再生成しないようにしている。
   * 
  **/
  public async checkAlreadySignedIn(): Promise<boolean> {
    try {
      onAuthStateChanged(auth,
        async (user) => {
          if (user) {
            if (this.isInvitationProcess) {
              return false;
            }
            if (!this.loginUser) {
              await this.setUser(user.uid);
            }
            return true;
          }
          await clearPersistedStore(this);
        });
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  /**
   * 組織への招待をユーザーが受け入れた時に呼び出す
   * @param organizationId 
   * @param userId 
   * @param invitationId 
   * @returns 
   */
  public async acceptInvitation(
    organizationId: string,
    userId: string,
    invitationId: string,
  ): Promise<{ error?: string }> {

    const params: AcceptInvitationRequestParam = {
      organizationId: organizationId,
      userId: userId,
      invitationId: invitationId,
    };

    try {
      const request = httpsCallable(functions, FirebaseHttpsRequests.acceptOrganizationInvitation);
      await request(params); // 組織への参加リクエスト
      // setUserFromAcceptInvitationでuserModelを作成しページ推移する
      return {};
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      // Sentry here
      const message = AcceptInvitationRequestErrors[error.message as AcceptInvitationErrorTypes] || AcceptInvitationRequestErrors.General;
      return { error: message };
    }
  }

  public async setUserFromAcceptInvitation(userId: string) {
    await this.setUser(userId); //ここでユーザーモデルを作成
    runInAction(async () => {
      this.isInvitationProcess = false; //Privateルートへnavigateできるようにフラグを下げる
    });
  }

  /**
   * 組織への招待をユーザーが拒否した場合に呼び出す
   * @param organizationId 
   * @param invitationId 
   * @param userId 
   * @param email 
   * @param skipDeletingAccount 招待拒否によりユーザーのアカウント削除を行うかどうかのフラグ
   */
  public async declineInvitation(
    organizationId: string,
    invitationId: string,
    userId: string,
    email: string,
    skipDeletingAccount: boolean,
  ): Promise<{ error?: string }>  {

    const params: DeclineInvitationRequestParam = {
      organizationId,
      userId,
      invitationId,
      inviteeEmail: email,
      skipDeletingAccount,
    };

    try {
      const request = httpsCallable(functions, FirebaseHttpsRequests.declineOrganizationInvitation);
      await request(params); // 組織への参加リクエスト
      return {};
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      //Sentry here
      const message = DeclineOrganizationInvitationErrors[error.message as DeclineOrganizationInvitationErrorType] || DeclineOrganizationInvitationErrors.General;
      return { error: message };
    }
  }

  public async updateUserDocument() {
    if (!this.loginUser) return;

    try {
      const updateUserRef = doc(db, FirestoreCollections.users.this, this.loginUser.id);
      await updateDoc(updateUserRef, { ...this.loginUser.getFields() });
    } catch (error) {
      console.log(error);
      //Sentry
    }
  }

  public async setUser(uid: string) {
    try {
      const docRef = doc(db, FirestoreCollections.users.this, uid);
      const userDoc = await getDoc(docRef);
      if (!userDoc.exists()) return;
      const user = userDoc.data() as LoginUser;
      runInAction(() => {
        this.loginUser = new LoginUserModel(user);
      });

      auth.currentUser?.getIdTokenResult()
        .then((idTokenResult) => {
          if (!!idTokenResult.claims.admin) {
            runInAction(() => {
              if (this.loginUser) {
                this.loginUser.isSuperUser = true;
              }
            });
          }
        });
    } catch (error) {
      console.log(error);
      //Sentry
    }
  }

  private async createUserDocument({
    user,
    isInvited
  }: {
    user: SignUpForm & { uid: string },
    isInvited: boolean
  }) {

    try {
      const newUserRef = doc(db, FirestoreCollections.users.this, user.uid);
      const userDoc: LoginUser = {
        id: user.uid,
        username: user.username,
        profileImageUrl: "",
        email: user.email,
        phoneNumber: "",
        workType: [],
        selectedOrganizationId: "",
        appState: {},
        projectSortSelected: 0,
      };
      await setDoc(newUserRef, userDoc);
      if (isInvited) return; //招待の際にはuser modelを作成しない

      runInAction(() => {
        this.loginUser = new LoginUserModel(userDoc);
      });
    } catch (error) {
      console.log(error);
      //Sentry
    }
  }
}

export default UserStore;
