import React, { useState } from 'react';
import SettingsLayout from 'src/conpath/components/settings/SettingLayout';

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from 'src/conpath/hooks/useStore';
//others
import { Paths } from 'src/conpath/constants/Routes';
import "./BillingSettings.scss";

const BillingSettings: React.FC = observer(() => {

  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const currentPlan = selectedOrganization?.getCurrentPlan();
  const addUsers = selectedOrganization?.addUsers || 0;
  const addProjects = selectedOrganization?.addProjects || 0;

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle='現在のプラン'
        currentPath={Paths.billings}>
        <div className="setting-body">
          <div className="section">
            <div className="form__wrapper">
              <div className="form-container">
                <div className="selection-wrapper">
                  <div className="flex w-full pb-2 font-semibold">
                    <label>
                      {currentPlan?.name}
                    </label>
                  </div>
                  <div className="text-sm">
                    <div>
                      {`ユーザー数: 
                    ${!currentPlan || currentPlan.userLimit === Number.POSITIVE_INFINITY
                          ? "無制限"
                          : currentPlan.userLimit + addUsers
                        }`}
                    </div>
                    <div>
                      {`プロジェクト数: 
                    ${!currentPlan || currentPlan?.projectLimit === Number.POSITIVE_INFINITY
                          ? "無制限"
                        : currentPlan.projectLimit + addProjects
                        }`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  )
});

export default BillingSettings;