import {
  ElementsMap,
  ExcalidrawElement,
  NonDeleted,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { AppState } from "../types";
import { ExcalidrawNodeElement, ExcalidrawTaskElement } from "./element/types"; // UPDATED: REMOVE #1225
import { TaskElementEditor } from "./element/taskElementEditor";
import Scene from "../scene/Scene";
import {
  isBindableElementEx,
  isLinkElement,
  isMilestoneElement,
  isTaskElement,
} from "./element/typeChecks";
import { GRID_SIZE, JOB_ELEMENTS_WIDTH } from "src/excalidraw/constants";
import { mutateElement } from "src/excalidraw/element/mutateElement";
import Calendar from "./calendar";
import { updateBoundElementsEx } from "./element/binding";
import { deepCopyElement } from "src/excalidraw/element/newElement";
import { LinkElementEditor } from "./element/linkElementEditor";

export const moveTaskElement = (
  selectedElement: NonDeleted<ExcalidrawTaskElement>,
  elementsMap: ElementsMap,
  startDate: Date,
  endDate: Date,
  holidays: string[] | null,
  memo: string,
  appState: AppState,
  calendar: Calendar,
): ExcalidrawElement[] => {
  const updatedElements: NonDeletedExcalidrawElement[] = [];
  const copyElements: Map<string, NonDeleted<ExcalidrawElement>> = new Map();
  const prevDependencyElements: NonDeletedExcalidrawElement[] = [];
  const nextDependencyElements: NonDeletedExcalidrawElement[] = [];

  const prevStartDate = new Date(selectedElement.startDate);
  const prevEndDate = new Date(selectedElement.endDate);

  const element = deepCopyElement(selectedElement) as ExcalidrawTaskElement;
  copyElements.set(element.id, element);

  const x = calendar.getDatePoint(startDate);
  const width = calendar.getDatePoint(endDate) - x;
  const duration = calendar.getDuration(startDate, endDate, holidays);

  mutateElement(element, {
    x,
    points: [...element.points.slice(0, -1), [width, 0]],
    startDate,
    endDate,
    duration,
    holidays,
    memo,
  });

  // CHANGED:ADD 2023-2-21 #590
  if (startDate.getTime() > prevStartDate.getTime()) {
    const isLocked = element.prevDependencies?.some((dependElementId) => {
      const linkElement = Scene.getScene(selectedElement)!
        .getNonDeletedElements()
        .find(
          (el) =>
            isLinkElement(el) &&
            el.startBinding?.elementId === dependElementId &&
            el.endBinding?.elementId === selectedElement.id,
        );

      return isLinkElement(linkElement) && linkElement.locked;
    });

    if (isLocked) {
      return [];
    }
  }

  // CHANGED:ADD 2023-2-21 #590
  if (endDate.getTime() < prevEndDate.getTime()) {
    const isLocked = element.nextDependencies?.some((dependElementId) => {
      const linkElement = Scene.getScene(selectedElement)!
        .getNonDeletedElements()
        .find(
          (el) =>
            isLinkElement(el) &&
            el.startBinding?.elementId === selectedElement.id &&
            el.endBinding?.elementId === dependElementId,
        );

      return isLinkElement(linkElement) && linkElement.locked;
    });

    if (isLocked) {
      return [];
    }
  }

  if (startDate.getTime() < prevStartDate.getTime()) {
    prevDependencyElements.push(
      ...getDependencyElements(selectedElement, "left"),
    );

    prevDependencyElements.forEach((element) => {
      copyElements.set(element.id, deepCopyElement(element));
    });

    let limitX = JOB_ELEMENTS_WIDTH;
    let targetEndX = 0;

    element.prevDependencies?.forEach((dependElementId) => {
      const bindingElement = copyElements.get(dependElementId);
      if (bindingElement && isBindableElementEx(bindingElement, true)) {
        // CHANGED:ADD 2023-2-21 #590
        const linkElement = Scene.getScene(selectedElement)!
          .getNonDeletedElements()
          .find(
            (el) =>
              isLinkElement(el) &&
              el.startBinding?.elementId === dependElementId &&
              el.endBinding?.elementId === selectedElement.id,
          );

        if (isTaskElement(bindingElement) && bindingElement.locked) {
          targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
            bindingElement,
            -1,
            elementsMap,
          )[0];
          // CHANGED:ADD 2023-2-21 #590
          if (isLinkElement(linkElement) && linkElement.locked) {
            targetEndX = LinkElementEditor.getPointAtIndexGlobalCoordinates(
              linkElement,
              -1,
              elementsMap,
            )[0];
          }
        } else if (isMilestoneElement(bindingElement)) {
          targetEndX = bindingElement.x + bindingElement.width / 2;
          // CHANGED:ADD 2023-2-21 #590
          if (isLinkElement(linkElement) && linkElement.locked) {
            targetEndX = LinkElementEditor.getPointAtIndexGlobalCoordinates(
              linkElement,
              -1,
              elementsMap,
            )[0];
          }
        }

        if (limitX < targetEndX) {
          limitX = targetEndX;
        }
      }
    });

    if (limitX > element.x) {
      return [];
    }

    prevDependencyElements.forEach((dependencyElement) => {
      if (isTaskElement(dependencyElement) && !dependencyElement.locked) {
        moveDependencyTaskElement(
          dependencyElement,
          elementsMap,
          copyElements,
          "left",
          calendar,
          appState,
        );
      }
    });

    const isOutOfRange = prevDependencyElements.some((dependencyElement) => {
      const element = copyElements.get(dependencyElement.id);

      if (element && isTaskElement(element)) {
        let limitX = JOB_ELEMENTS_WIDTH;
        let targetEndX = 0;

        element.prevDependencies?.forEach((dependElementId) => {
          const bindingElement = copyElements.get(dependElementId);
          if (bindingElement && isBindableElementEx(bindingElement, true)) {
            if (isTaskElement(bindingElement) && bindingElement.locked) {
              targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
                bindingElement,
                -1,
                elementsMap,
              )[0];
            } else if (isMilestoneElement(bindingElement)) {
              targetEndX = bindingElement.x + bindingElement.width / 2;
            }

            if (limitX < targetEndX) {
              limitX = targetEndX;
            }
          }
        });

        if (limitX > element.x) {
          return true;
        }
      }

      return false;
    });

    if (isOutOfRange) {
      return [];
    }
  }

  if (endDate.getTime() > prevEndDate.getTime()) {
    nextDependencyElements.push(
      ...getDependencyElements(selectedElement, "right"),
    );

    nextDependencyElements.forEach((element) => {
      copyElements.set(element.id, deepCopyElement(element));
    });

    let limitX = JOB_ELEMENTS_WIDTH + appState.calendarWidth;
    let targetStartX = limitX;

    element.nextDependencies?.forEach((dependElementId) => {
      const bindingElement = copyElements.get(dependElementId);
      if (bindingElement && isBindableElementEx(bindingElement, true)) {
        // CHANGED:ADD 2023-2-21 #590
        const linkElement = Scene.getScene(selectedElement)!
          .getNonDeletedElements()
          .find(
            (el) =>
              isLinkElement(el) &&
              el.startBinding?.elementId === selectedElement.id &&
              el.endBinding?.elementId === dependElementId,
          );

        if (isTaskElement(bindingElement) && bindingElement.locked) {
          targetStartX = bindingElement.x;

          // CHANGED:ADD 2023-2-21 #590
          if (isLinkElement(linkElement) && linkElement.locked) {
            targetStartX = linkElement.x;
          }
        } else if (isMilestoneElement(bindingElement)) {
          targetStartX = bindingElement.x + bindingElement.width / 2;

          // CHANGED:ADD 2023-2-21 #590
          if (isLinkElement(linkElement) && linkElement.locked) {
            targetStartX = linkElement.x;
          }
        }

        if (limitX > targetStartX) {
          limitX = targetStartX;
        }
      }
    });

    if (limitX < element.x + element.width) {
      return [];
    }

    nextDependencyElements.forEach((dependencyElement) => {
      if (isTaskElement(dependencyElement) && !dependencyElement.locked) {
        moveDependencyTaskElement(
          dependencyElement,
          elementsMap,
          copyElements,
          "right",
          calendar,
          appState,
        );
      }
    });

    const isOutOfRange = nextDependencyElements.some((dependencyElement) => {
      const element = copyElements.get(dependencyElement.id);

      if (element && isTaskElement(element)) {
        let limitX = JOB_ELEMENTS_WIDTH + appState.calendarWidth;
        let targetStartX = limitX;

        element.nextDependencies?.forEach((dependElementId) => {
          const bindingElement = copyElements.get(dependElementId);
          if (bindingElement && isBindableElementEx(bindingElement, true)) {
            if (isTaskElement(bindingElement) && bindingElement.locked) {
              targetStartX = bindingElement.x;
            } else if (isMilestoneElement(bindingElement)) {
              targetStartX = bindingElement.x + bindingElement.width / 2;
            }

            if (limitX > targetStartX) {
              limitX = targetStartX;
            }
          }
        });

        if (limitX < element.x + element.width) {
          return true;
        }
      }

      return false;
    });

    if (isOutOfRange) {
      return [];
    }
  }

  mutateElement(selectedElement, {
    x: element.x,
    points: element.points,
    startDate: element.startDate,
    endDate: element.endDate,
    duration: element.duration,
    holidays: element.holidays,
  });

  updateBoundElementsEx(
    selectedElement,
    elementsMap,
    appState,
    calendar
  );
  updatedElements.push(element);

  const updatedDependencyElements = [
    ...prevDependencyElements,
    ...nextDependencyElements,
  ].map((el) => {
    const copyElement = copyElements.get(el.id);

    if (copyElement && isTaskElement(copyElement) && isTaskElement(el)) {
      mutateElement(el, {
        x: copyElement.x,
        points: copyElement.points,
        startDate: copyElement.startDate,
        endDate: copyElement.endDate,
      });

      updateBoundElementsEx(
        el,
        elementsMap,
        appState,
        calendar
      );

      return el;
    }

    return el;
  });

  updatedElements.push(...updatedDependencyElements);

  return updatedElements;
};

const moveDependencyTaskElement = (
  originElement: ExcalidrawElement,
  elementsMap: ElementsMap,
  copyElements: Map<string, NonDeleted<ExcalidrawElement>>,
  leftOrRight: "left" | "right",
  calendar: Calendar,
  appState: AppState,
) => {
  const gridSize = appState.gridSize ? appState.gridSize : GRID_SIZE;

  const element = copyElements.get(originElement.id);
  if (!isTaskElement(element)) {
    return;
  }

  const startX = element.x;
  const endX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
    element,
    -1,
    elementsMap,
  )[0];

  const minDate = new Date(appState.projectStartDate);
  const maxDate = new Date(appState.projectEndDate);
  const startDate = new Date(element.startDate);
  const endDate = new Date(element.endDate);

  let offsetX = 0;
  let offsetWidth = 0;

  const dependencies =
    leftOrRight === "left"
      ? element.nextDependencies
      : element.prevDependencies;

  const endXLimits: Set<number> = new Set();
  const startXLimits: Set<number> = new Set();

  dependencies?.forEach((dependElementId) => {
    const bindingElement = copyElements.get(dependElementId);
    if (bindingElement && isBindableElementEx(bindingElement, true)) {
      let targetStartX = 0;
      let targetEndX = 0;

      if (isTaskElement(bindingElement)) {
        targetStartX = bindingElement.x;
        targetEndX = TaskElementEditor.getPointAtIndexGlobalCoordinates(
          bindingElement,
          -1,
          elementsMap,
        )[0];
      } else if (isMilestoneElement(bindingElement)) {
        targetStartX = bindingElement.x + bindingElement.width / 2;
        targetEndX = bindingElement.x + bindingElement.width / 2;
      }

      // CHANGED:ADD 2023-2-21 #590
      const linkElement = Scene.getScene(originElement)!
        .getNonDeletedElements()
        .find(
          (el) =>
            isLinkElement(el) &&
            el[leftOrRight === "left" ? "startBinding" : "endBinding"]
              ?.elementId === originElement.id &&
            el[leftOrRight === "left" ? "endBinding" : "startBinding"]
              ?.elementId === dependElementId,
        );

      if (leftOrRight === "left") {
        // CHANGED:ADD 2023-2-21 #590
        if (isLinkElement(linkElement) && linkElement.locked) {
          const endDate = calendar.getPointDate(targetStartX);
          for (
            const startDate = new Date(endDate);
            startDate.getTime() >= minDate.getTime();
            startDate.setDate(startDate.getDate() - 1)
          ) {
            const isHoliday = appState.holidays?.some(
              (holiday) =>
                new Date(holiday).toDateString() === startDate.toDateString(),
            );

            const duration = calendar.getDuration(startDate, endDate);
            if (duration === linkElement.duration && !isHoliday) {
              break;
            }

            targetStartX -= gridSize;
          }
        }

        const distance = targetStartX - endX;
        startXLimits.add(distance);
      } else if (leftOrRight === "right") {
        // CHANGED:ADD 2023-2-21 #590
        if (isLinkElement(linkElement) && linkElement.locked) {
          const startDate = calendar.getPointDate(targetEndX);

          for (
            const endDate = new Date(startDate);
            endDate.getTime() <= maxDate.getTime();
            endDate.setDate(endDate.getDate() + 1)
          ) {
            const isHoliday = appState.holidays?.some(
              (holiday) =>
                new Date(holiday).toDateString() === endDate.toDateString(),
            );

            const duration = calendar.getDuration(startDate, endDate);
            if (duration === linkElement.duration && !isHoliday) {
              break;
            }

            targetEndX += gridSize;
          }
        }

        const distance = targetEndX - startX;
        endXLimits.add(distance);
      }
    }
  });

  if (leftOrRight === "left") {
    const distance = Math.min(...startXLimits);
    if (distance < 0) {
      offsetWidth = distance;
      const endDate = calendar.getPointDate(endX + distance);

      const date = new Date(endDate);
      date.setDate(date.getDate() - 1);

      for (
        ;
        startDate.getTime() >= minDate.getTime();
        startDate.setDate(startDate.getDate() - 1)
      ) {
        const isHoliday = (
          element.holidays ? element.holidays : appState.holidays
        )?.some(
          (holiday) =>
            new Date(holiday).toDateString() === startDate.toDateString(),
        );

        const duration = calendar.getDuration(
          startDate,
          endDate,
          element.holidays,
        );

        if (duration === element.duration && !isHoliday) {
          break;
        }

        offsetX -= gridSize;
        offsetWidth += gridSize;
      }

      for (
        ;
        date.getTime() > startDate.getTime();
        date.setDate(date.getDate() - 1)
      ) {
        const isHoliday = (
          element.holidays ? element.holidays : appState.holidays
        )?.some(
          (holiday) => new Date(holiday).toDateString() === date.toDateString(),
        );

        if (!isHoliday) {
          break;
        }

        offsetWidth -= gridSize;
        endDate.setDate(endDate.getDate() - 1);
      }

      mutateElement(element, {
        x: element.x + offsetX,
        points: [
          ...element.points.slice(0, -1),
          [element.width + offsetWidth, 0],
        ],
        startDate,
        endDate,
      });
    }
  } else if (leftOrRight === "right") {
    const distance = Math.max(...endXLimits);
    if (distance > 0) {
      offsetX = distance;
      offsetWidth = -distance;
      const startDate = calendar.getPointDate(startX + distance);

      const date = new Date(endDate);
      date.setDate(date.getDate() - 1);

      for (
        ;
        date.getTime() <= maxDate.getTime();
        date.setDate(date.getDate() + 1)
      ) {
        const isHoliday = (
          element.holidays ? element.holidays : appState.holidays
        )?.some(
          (holiday) => new Date(holiday).toDateString() === date.toDateString(),
        );

        const duration = calendar.getDuration(
          startDate,
          endDate,
          element.holidays,
        );

        if (duration === element.duration && !isHoliday) {
          break;
        }

        offsetWidth += gridSize;
        endDate.setDate(endDate.getDate() + 1);
      }

      for (
        ;
        startDate.getTime() < date.getTime();
        startDate.setDate(startDate.getDate() + 1)
      ) {
        const isHoliday = (
          element.holidays ? element.holidays : appState.holidays
        )?.some(
          (holiday) =>
            new Date(holiday).toDateString() === startDate.toDateString(),
        );

        if (!isHoliday) {
          break;
        }

        offsetX += gridSize;
        offsetWidth -= gridSize;
      }

      mutateElement(element, {
        x: element.x + offsetX,
        points: [
          ...element.points.slice(0, -1),
          [element.width + offsetWidth, 0],
        ],
        startDate,
        endDate,
      });
    }
  }
};

export const getDependencyElements = (
  selectedElement: NonDeleted<ExcalidrawNodeElement>,
  leftOrRight: "left" | "right",
): NonDeletedExcalidrawElement[] => {
  const dependencyElements: NonDeletedExcalidrawElement[] = [];
  const queue: ExcalidrawNodeElement[] = [];

  queue.push(selectedElement);

  while (queue.length > 0) {
    const v = queue.shift() as ExcalidrawNodeElement;

    const dependencies =
      leftOrRight === "left" ? v.prevDependencies : v.nextDependencies;

    dependencies?.forEach((e) => {
      const u = Scene.getScene(selectedElement)?.getNonDeletedElement(e);
      if (u && isBindableElementEx(u, true)) {
        dependencyElements.push(u);
        queue.push(u);
      }
    });
  }

  return dependencyElements;
};
