import { ExcalidrawElement } from "src/excalidraw/element/types";
import { isTextElement } from "src/excalidraw/element";
import { ExcalidrawTaskElement } from "src/excalidraw/extensions/element/types";
import _ from "lodash";
import { getTextWidth } from "src/excalidraw/element/textElement";
import { getFontString } from "src/excalidraw/utils";

export const fillDurationWhenMissing = (elements: ExcalidrawElement[]): ExcalidrawElement[] => {

  return elements.map((element) => {
    if (isTextElement(element) && _.isEmpty(element.text)) {
      const container = elements.find((el) => el.id === element.containerId) as ExcalidrawTaskElement;
      if (container) {
        const originalText = element.originalText;
        const text = element.originalText;// CHANGED:ADD 2023-08-22 #924
        const font = getFontString(element);
        const width = getTextWidth(text, font);
        return {
          ...element,
          text,
          originalText,
          width,
        }
      }
    }
    return element
  });
}