import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";

import { firebaseTimeToDate } from "src/utils/timeUtils";
import { RotatingLines } from "react-loader-spinner";
import ProjectForm from "src/conpath/components/projects/ProjectForm";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import { useNavigate, useParams } from "react-router-dom";

//styles
import "./CreateProject.scss";
import Colors, { DEFAULT_PROJECT_COLOR } from "src/conpath/constants/Colors";
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";
import { ProjectInputForm } from "src/conpath/interfaces/Project";
import { Paths } from "../../constants/Routes";
import { UpdateProjectRequestError } from "src/conpath/constants/errors/OrganizationRequestErrors";
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import { Tags } from "../../interfaces/Tag";

const EditProject: React.FC = observer(() => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  const [project, setProject] = useState<ProjectInputForm>({
    id: "",
    name: "",
    color: DEFAULT_PROJECT_COLOR,
    address: "",
    description: "",
    tags: {},
    startDate: null,
    endDate: null,
    holidays: [],
    jobsHeight: 0,
    backgroundColor: ColorsEx.backgroundColor.white,
    roles: {},
    teams: {},
    resources: {},
    isArchived: false,
    archivedAt: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (projectId && selectedOrganization) {
      const _project = selectedOrganization.projects.find((project) => project.id === projectId);
      if (!_project) return;

      setProject({
        id: _project.id,
        name: _project.name,
        color: _project.color,
        address: _project.address,
        description: _project.description,
        tags: _project.tags,
        startDate: firebaseTimeToDate(_project.startDate),
        endDate: firebaseTimeToDate(_project.endDate),
        holidays: _project.holidays,
        jobsHeight: _project.jobsHeight,
        backgroundColor: _project.backgroundColor,
        roles: _project.roles,
        teams: _project.teams,
        resources: _project.resources,
        isArchived: _project.isArchived,
        archivedAt: _project.archivedAt ? firebaseTimeToDate(_project.archivedAt) : null,
      });
      setIsLoading(false);
    }
  }, [projectId, selectedOrganization]);

  const onChangeState = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name) {
      setProject((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    setErrorText("");
  }, [setProject]);

  const onChangeAssignedUsers = useCallback((roles: any) => {
    setProject((prev) => ({
      ...prev,
      roles: roles,
    }));
    setErrorText("");
  }, [project.roles]);

  const onChangeAssignedTeams = useCallback(
    (teams: any) => {
      setProject((prev) => ({
        ...prev,
        teams: teams,
      }));
      setErrorText("");
    },
    [project.teams],
  );

  const onChangeAssignedResources = useCallback(
    (resources: any) => {
      setProject((prev) => ({
        ...prev,
        resources: resources,
      }));
      setErrorText("");
    },
    [project.resources],
  );

  const onChangeColor = useCallback(
    (value: string) => {
      setProject((prev) => ({
        ...prev,
        color: value,
      }));
      setErrorText("");
    },
    [setProject],
  );

  const onChangeTags = useCallback(
    (value: Tags) => {
      setProject((prev) => ({
        ...prev,
        tags: value,
      }));
      setErrorText("");
    },
    [setProject],
  );

  const onCancel = useCallback(() => {
    navigate(Paths.projects, { replace: true });
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;
    setIsProcessing(true);
    const result = await selectedOrganization.updateProject(project, loginUser, selectedOrganization);
    if (result?.error) {
      setErrorText(result.error);
      setIsProcessing(false);
      if (result.error === UpdateProjectRequestError.UserRemovedFromOrganization) {
        setTimeout(async () => {
          await userStore.signOut();
        }, TOAST_DEFAULT_DURATION + 1000);
      }
    } else {
      setIsProcessing(false);
      navigate(Paths.projects, { state: { message: "プロジェクトを更新しました" }});
    }
  }, [project, navigate, setErrorText, selectedOrganization, loginUser]);

  const onArchive = useCallback(async (isArchived: boolean) => {
    if (!selectedOrganization || !loginUser) return;
    setIsProcessing(true);

    const _project = selectedOrganization.projects.find((project) => project.id === projectId);
    if (!_project) return;

    _project.setOrganizationId(selectedOrganization.id);
    const result = await _project.updateIsArchived(isArchived);
    if (result?.error) {
      setErrorText(result.error);
      setIsProcessing(false);
      if (result.error === UpdateProjectRequestError.UserRemovedFromOrganization) {
        setTimeout(async () => {
          await userStore.signOut();
        }, TOAST_DEFAULT_DURATION + 1000);
      }
    } else {
      setIsProcessing(false);
      navigate(Paths.projects, { state: { message: "プロジェクトの状態を更新しました" } });
    }
  }, [project, navigate, setErrorText, selectedOrganization, loginUser]);

  return (
    <div className="project-create-container">
      {selectedOrganization && !isLoading ? (
        <div className="inner-container">
          <ProjectForm
            isCreating={false}
            isDuplicate={false}
            isProcessing={isProcessing}
            projectState={project}
            onChangeProjectState={onChangeState}
            errorText={errorText}
            setErrorText={setErrorText}
            organization={selectedOrganization}
            onChangeAssignedUsers={onChangeAssignedUsers}
            onChangeAssignedTeams={onChangeAssignedTeams}
            onChangeAssignedResources={onChangeAssignedResources}
            onChangeColor={onChangeColor}
            onChangeTags={onChangeTags}
            onArchive={onArchive}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </div>
      ) : (
        <div className="spinner-wrapper">
          <RotatingLines
            strokeColor={Colors.primary}
            strokeWidth="5"
            animationDuration="0.75"
            width="42"
            visible={true}
          />
        </div>
      )}
    </div>
  );
});

export default EditProject;
