import { t } from "src/excalidraw/i18n";
import { FaLayerGroup } from "react-icons/fa";
type LayerButtonProps = {
  name?: string;
  id?: string;
  onClick?(): void;
};

export const LayerButton = (props: LayerButtonProps) => (
  <button
    className="layer-icon icon-hover" //CHANGED:UPDATE 2023/09/07 #994
    onClick={props.onClick}
    type="button"
    title={`${t("layerSettingsDialog.title")} — L`}
    aria-label={t("layerSettingsDialog.title")}
  >
    <FaLayerGroup />
  </button>
);
