import { useLocation } from "react-router-dom";
import "./HeaderNav.scss";

const HeaderNav = (props: { path: string; name: string, count?: number }) => {
  const location = useLocation();

  const paths = props.path.split("/");
  const isSelected = location.pathname.match(`^/${paths[1]}`);
  const linkStyle = isSelected ? { borderTop: "4px solid #46AADF", marginBottom: "4px" } : {};
  const liStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

  return (
    <li style={{ ...liStyle, ...linkStyle }}>
      <a className={"header-nav"} href={props.path}>
        <span>{props.name}</span>
        {props.count &&
          <p>{props.count}</p>
        }
      </a>
    </li>
  );
};

export default HeaderNav;
