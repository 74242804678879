import { Timestamp } from "firebase/firestore";
import { Tags } from "./Tag";

export default interface Task {
  id: string;
  text: string;
  duration: number;
  startDate: Date;
  endDate: Date;
  projectId: string;
  prevDependencies: readonly string[] | null;
  nextDependencies: readonly string[] | null;
  freeFloats:
  | readonly Readonly<{
    id: string;
    type: string;
    duration: number;
  }>[]
  | null;
  memo: string;
  isClosed: boolean;
  isDeleted: boolean;
  created: number;
  createdBy: string;
  updated: number;
  updatedBy: string;
  taskCompletionRate?: number;
  assignUserIds?: string[];
  assignResourceIds?: string[];
  tags?: Tags;
};

export interface TaskResponse extends Omit<Task, "startDate" | "endDate"> {
  startDate: Timestamp;
  endDate: Timestamp;
};

export interface GanttChartTask extends Task {
  isTask: boolean;
  ratio: number;
};

export const TaskDocumentFields = {
  id: "id",
  text: "text",
  duration: "duration",
  startDate: "startDate",
  endDate: "endDate",
  projectId: "projectId",
  groupId: "groupId",
  prevDependencies: "prevDependencies",
  nextDependencies: "nextDependencies",
  freeFloats: "freeFloats",
  memo: "memo",
  isClosed: "isClosed",
  isDeleted: "isDeleted",
  created: "created",
  createdBy: "createdBy",
  updated: "updated",
  updatedBy: "updatedBy",
  taskCompletionRate: "taskCompletionRate",
  assignUserIds: "assignUserIds",
  assignResourceIds: "assignResourceIds",
  tags: "tags",
} as const;

export type TaskDocumentFields = typeof TaskDocumentFields[keyof typeof TaskDocumentFields];
