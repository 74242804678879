import clsx from "clsx";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ActionManager } from "../actions/manager";
import { CLASSES, COMMENT_AUTO_SCROLL_X_CENTER_OFFSET, DEFAULT_SIDEBAR_WIDTH, LIBRARY_SIDEBAR_WIDTH } from "../constants";
import { exportCanvas } from "src/excalidraw/data/";
import { isTextElement, showSelectedShapeActions } from "../element";
import { NonDeletedExcalidrawElement } from "../element/types";
import { Language, t } from "../i18n";
import { ExportType } from "../scene/types";
import { AppProps, AppState, ExcalidrawProps, BinaryFiles, AppClassProperties, UIAppState } from "../types";
import { isShallowEqual, muteFSAbortError } from "../utils";
import { SelectedShapeActions, ShapesSwitcher } from "./Actions";
import { ErrorDialog } from "./ErrorDialog";
import { ExportCB, ImageExportDialog } from "./ImageExportDialog";
import { PrintExportDialog } from "../extensions/components/PrintExportDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import { HintViewer } from "./HintViewer";
import { Island } from "./Island";
import { LoadingMessage } from "./LoadingMessage";
import { LockButton } from "./LockButton";
import { MobileMenu } from "./MobileMenu";
import { PasteChartDialog } from "./PasteChartDialog";
import { Section } from "./Section";
import { calculateScrollCenter } from "../scene";
// CHANGED:UPDATE 2023-1-10 #401
// import { HelpDialog } from "./HelpDialog";
import { HelpDialogEx } from "../extensions/components/HelpDialogEx"; // from extensions
import LayerSettingsDialog from "../extensions/components/LayerSettingsDialog";
import Stack from "./Stack";
import { UserList } from "./UserList";
import Library from "src/excalidraw/data/library";
import { JSONExportDialog } from "./JSONExportDialog";
import { LibraryButton } from "./LibraryButton";
import { isImageFileHandle } from "src/excalidraw/data/blob";
// CHANGED:UPDATE 2024-02-08 #1579
// import { LibraryMenu } from "./LibraryMenu";
import { LibraryMenuEx } from "src/excalidraw/extensions/components/LibraryMenuEx";
import Scroll from "src/excalidraw/extensions/scene/scroll";
import ShareLinkDialog from "src/excalidraw/extensions/components/ShareLinkDialog";
import ToolBarEx from "../extensions/components/ToolBar"; // from extensions
import { DefaultSidebar } from "../extensions/components/DefaultSidebar";
import DeleteProjectDialog from "../extensions/components/DeleteProjectDialog";
import { ThreadListSidebarPanel } from "../extensions/components/ThreadListSidebarPanel";
import Scene from "../scene/Scene";

import "./LayerUI.scss";
import "./Toolbar.scss";
import { PenModeButton } from "./PenModeButton";
import { trackEvent } from "../analytics";
import { useDevice } from "./App";
import { Stats } from "./Stats";
import { actionToggleStats } from "../actions/actionToggleStats";
// CHANGED:UPDATE 2022-11-10 #119
// import Footer from "./Footer";
import { hostSidebarCountersAtom } from "./Sidebar/Sidebar";
import { jotaiScope } from "../jotai";
import { Provider, useAtom } from "jotai";
import MainMenu from "./main-menu/MainMenu";
import { ActiveConfirmDialog } from "./ActiveConfirmDialog";
import { HandButton } from "./HandButton";
import { isHandToolActive } from "../appState";
import { TunnelsContext, useInitializeTunnels } from "./context/tunnels";
//conpath
import CommentModel from "src/conpath/models/CommentModel";
import { isCommentElement, isCommentableElement } from "../extensions/element/typeChecks";
import { CommentsSidebarPanel } from "../extensions/components/CommentsSidebarPanel";
import { ExcalidrawCommentElement, ExcalidrawCommentableElement } from "../extensions/element/types";

interface LayerUIProps {
  actionManager: ActionManager;
  appState: AppState;
  files: BinaryFiles;
  canvas: HTMLCanvasElement;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  onLockToggle: () => void;
  onHandToolToggle: () => void;
  onPenModeToggle: AppClassProperties["togglePenMode"];
  onInsertElements: (elements: readonly NonDeletedExcalidrawElement[]) => void;
  showExitZenModeBtn: boolean;
  langCode: Language["code"];
  renderTopRightUI?: ExcalidrawProps["renderTopRightUI"];
  renderCustomStats?: ExcalidrawProps["renderCustomStats"];
  renderCustomSidebar?: ExcalidrawProps["renderSidebar"];
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  UIOptions: AppProps["UIOptions"];
  onExportImage: AppClassProperties["onExportImage"];
  focusContainer: () => void;
  library: Library;
  id: string;
  renderWelcomeScreen: boolean;
  onDeleteProject?: ExcalidrawProps["onDeleteProject"]; // CHANGED:ADD 2023/09/13 #1023
  children?: React.ReactNode;
  app: AppClassProperties;
  scene: Scene;
}

const DefaultMainMenu: React.FC<{
  UIOptions: AppProps["UIOptions"];
}> = ({ UIOptions }) => {
  return (
    <MainMenu __fallback>
      <MainMenu.DefaultItems.LoadScene />
      <MainMenu.DefaultItems.SaveToActiveFile />
      {/* FIXME we should to test for this inside the item itself */}
      {UIOptions.canvasActions.export && <MainMenu.DefaultItems.Export />}
      {/* FIXME we should to test for this inside the item itself */}
      {UIOptions.canvasActions.saveAsImage && (
        <MainMenu.DefaultItems.SaveAsImage />
      )}
      <MainMenu.DefaultItems.Help />
      <MainMenu.DefaultItems.ClearCanvas />
      <MainMenu.Separator />
      <MainMenu.Group title="Excalidraw links">
        <MainMenu.DefaultItems.Socials />
      </MainMenu.Group>
      <MainMenu.Separator />
      <MainMenu.DefaultItems.ToggleTheme />
      <MainMenu.DefaultItems.ChangeCanvasBackground />
    </MainMenu>
  );
};

const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  elements,
  canvas,
  onLockToggle,
  onHandToolToggle,
  onPenModeToggle,
  onInsertElements,
  showExitZenModeBtn,
  renderTopRightUI,
  renderCustomStats,
  renderCustomSidebar,
  libraryReturnUrl,
  UIOptions,
  onExportImage,
  focusContainer,
  library,
  id,
  renderWelcomeScreen,
  onDeleteProject, // CHANGED:ADD 2023/09/13 #1023
  children,
  app,
  scene,
}: LayerUIProps) => {
  const device = useDevice();
  const tunnels = useInitializeTunnels();

  const [previousCommentableElement, setPreviousCommentableElement] = useState<ExcalidrawCommentableElement|null>(null);

  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    return (
      <JSONExportDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        exportOpts={UIOptions.canvasActions.export}
        canvas={canvas}
        setAppState={setAppState}
      />
    );
  };

  const renderImageExportDialog = () => {
    if (
      !UIOptions.canvasActions.saveAsImage ||
      appState.openDialog !== "imageExport"
    ) {
      return null;
    }

    return (
      <ImageExportDialog
        elements={scene.getRenderingElements()} // CHANGED:UPDATE 2023-02-28 #434
        appState={appState}
        files={files}
        actionManager={actionManager}
        onExportImage={onExportImage}
        onCloseRequest={() => setAppState({ openDialog: null })}
      />
    );
  };

  const renderPrintExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage) {
      return null;
    }

    return (
      <PrintExportDialog
        elements={scene.getRenderingElements()} // CHANGED:UPDATE 2023-02-28 #434
        appState={appState}
        setAppState={setAppState}
        files={files}
        actionManager={actionManager}
      />
    );
  };

  const renderCanvasActions = () => (
    <div style={{ position: "relative" }}>
      {/* wrapping to Fragment stops React from occasionally complaining
                about identical Keys */}
      <tunnels.mainMenuTunnel.Out />
      {renderWelcomeScreen && <tunnels.welcomeScreenMenuHintTunnel.Out />}
    </div>
  );

  const renderSelectedShapeActions = () => (
    <Section
      heading="selectedShapeActions"
      className={clsx("selected-shape-actions zen-mode-transition", {
        "transition-left": appState.zenModeEnabled,
      })}
    >
      <Island
        className={CLASSES.SHAPE_ACTIONS_MENU}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so subtracting the
          // approximate height of hamburgerMenu + footer
          maxHeight: `${appState.height - 166}px`,
        }}
      >
        <SelectedShapeActions
          appState={appState}
          elementsMap={app.scene.getNonDeletedElementsMap()}
          renderAction={actionManager.renderAction}
        />
      </Island>
    </Section>
  );

  const renderFixedSideContainer = () => {
    const shouldRenderSelectedShapeActions = showSelectedShapeActions(
      appState,
      elements,
    );

    // CHANGED:ADD 2023/08/26 #957
    if (
      shouldRenderSelectedShapeActions &&
      appState.openSidebar === null
    ) {
      setAppState({ openSidebar: "customSidebar" });
    }

    return (
      <FixedSideContainer side="top">
        <div className="App-menu App-menu_top">
          <Stack.Col
            gap={6}
            className={clsx("App-menu_top__left", {
              "disable-pointerEvents": appState.zenModeEnabled,
            })}
          >
            {renderCanvasActions()}
            {/* {shouldRenderSelectedShapeActions && renderSelectedShapeActions()} */ /* CHANGED:REMOVE #219  */}
          </Stack.Col>
          {!appState.viewModeEnabled ? (
            <Section heading="shapes" className="shapes-section">
              {(heading: React.ReactNode) => (
                <div style={{ position: "relative" }}>
                  {renderWelcomeScreen && (
                    <tunnels.welcomeScreenToolbarHintTunnel.Out />
                  )}
                  {/* CHANGED:ADD 2022-11-10 # */}
                  {false &&
                  <Stack.Col gap={4} align="start">
                    <Stack.Row
                      gap={1}
                      className={clsx("App-toolbar-container", {
                        "zen-mode": appState.zenModeEnabled,
                      })}
                    >
                      <Island
                        padding={1}
                        className={clsx("App-toolbar", {
                          "zen-mode": appState.zenModeEnabled,
                        })}
                      >
                        <HintViewer
                          appState={appState}
                          elements={elements}
                          isMobile={device.isMobile}
                          device={device}
                        />
                        {heading}
                        <Stack.Row gap={1}>
                          <PenModeButton
                            zenModeEnabled={appState.zenModeEnabled}
                            checked={appState.penMode}
                            onChange={() => onPenModeToggle(null)}
                            title={t("toolBar.penMode")}
                            penDetected={appState.penDetected}
                          />
                          <LockButton
                            checked={appState.activeTool.locked}
                            onChange={onLockToggle}
                            title={t("toolBar.lock")}
                          />

                          <div className="App-toolbar__divider" />

                          <HandButton
                            checked={isHandToolActive(appState)}
                            onChange={() => onHandToolToggle()}
                            title={t("toolBar.hand")}
                            isMobile
                          />

                          <ShapesSwitcher
                            appState={appState}
                            activeTool={appState.activeTool}
                            UIOptions={UIOptions}
                            app={app}
                          />
                        </Stack.Row>
                      </Island>
                    </Stack.Row>
                  </Stack.Col>
                  }
                </div>
              )}
            </Section>
          ): <div/>}
          {/* CHANGED:UPDATE 2022-12-01  */}
          {/* <div
            className={clsx(
              "layer-ui__wrapper__top-right zen-mode-transition",
              {
                "transition-right": appState.zenModeEnabled,
              },
            )}
          > */}
          <Stack.Col
            gap={6}
            className={clsx(
              "layer-ui__wrapper__top-right zen-mode-transition",
              {
                "transition-right": appState.zenModeEnabled,
              },
            )}
          >
            {/* CHANGED:ADD 2022-12-01 #219 */}
            <div
              className="layer-ui__wrapper__top-right__top-columns"
            >
            <UserList collaborators={appState.collaborators} />
            {renderTopRightUI?.(device.isMobile, appState)}
            {/* CHANGED:REMOVE 2023/09/01 #970 */}
            {/* {!appState.viewModeEnabled && (
              <div style={{ position: "fixed", top: 140, right: 0}}>
                <LibraryButton appState={appState} setAppState={setAppState} />
              </div>
            )} */}
            </div>
            {/* CHANGED:REMOVE 2023/08/26 #957 */}
            {/* {shouldRenderSelectedShapeActions && renderSelectedShapeActions()}CHANGED:ADD 2022-12-01 #219 */}
          {/* </div> */ /* CHANGED:UPDATE 2022-12-01 #219 */}
          </Stack.Col>
        </div>
      </FixedSideContainer>
    );
  };

  const openComments = (comment: CommentModel) => {
    const commentElement = elements.find((el) => el.id === comment.id) as ExcalidrawCommentElement;
    if (!commentElement) return;

    const { scrollX, scrollY } = calculateScrollCenter([commentElement], appState);

    const selectedCommentableElement = elements.find((el) => isCommentableElement(el) && el.id === commentElement.commentElementId);
    
    setAppState({
      scrollX: scrollX - COMMENT_AUTO_SCROLL_X_CENTER_OFFSET,
      scrollY,
      selectedElementIds: selectedCommentableElement ? {
        [commentElement.id]: true,
      } : {},
      openSidebar: selectedCommentableElement ? "comments" : null,
    });
  };

  const renderSidebars = useMemo(() => {
    switch (appState.openSidebar) {
      case "customSidebar":
        return renderCustomSidebar?.() || null
      case "library":
        // CHANGED:UPDATE 2024-02-08 #1579
        // return <LibraryMenu
        //   appState={appState}
        //   onInsertElements={onInsertElements}
        //   libraryReturnUrl={libraryReturnUrl}
        //   focusContainer={focusContainer}
        //   library={library}
        //   id={id}
        // />
        return <LibraryMenuEx
          appState={appState}
          onInsertElements={onInsertElements}
          focusContainer={focusContainer}
          id={id}
        />
      case "threadList":
        return <ThreadListSidebarPanel
          appState={appState}
          elements={elements.filter((el) => isCommentElement(el)) as ExcalidrawCommentElement[]}
          commentElementIds={elements.filter((el) => isCommentElement(el) && !el.isDeleted).map((el) => el.id)}
          openComments={openComments}
        />
      case "comments":
        const commentElement = elements.find((el) => isCommentElement(el) && appState.selectedElementIds[el.id]) as ExcalidrawCommentElement | undefined;
        const commentableElement = elements.find((el) => isCommentableElement(el) && (appState.selectedElementIds[el.id] || el.id === commentElement?.commentElementId)) as ExcalidrawCommentableElement | undefined ?? previousCommentableElement;

        if (commentableElement) {
          setPreviousCommentableElement(commentableElement);

          return <CommentsSidebarPanel
            appState={appState}
            actionManager={actionManager}
            commentElement={commentElement}
            commentableElement={commentableElement}
          />
        }
        return <DefaultSidebar />; // CHANGED:ADD 2023/09/01 #970
      default:
        return <DefaultSidebar />; // CHANGED:ADD 2023/09/01 #970
    }
  }, [appState.openSidebar, appState.selectedElementIds]);

  // CHANGED:ADD 2024-02-15 #1567
  const renderViewModeSidebars = useMemo(() => {
    switch (appState.openSidebar) {
      case "threadList":
        return <ThreadListSidebarPanel
          appState={appState}
          elements={elements.filter((el) => isCommentElement(el)) as ExcalidrawCommentElement[]}
          commentElementIds={elements.filter((el) => isCommentElement(el) && !el.isDeleted).map((el) => el.id)}
          openComments={openComments}
        />
      case "comments":
        const commentElement = elements.find((el) => isCommentElement(el) && appState.selectedElementIds[el.id]) as ExcalidrawCommentElement | undefined;
        const commentableElement = elements.find((el) => isCommentableElement(el) && (appState.selectedElementIds[el.id] || el.id === commentElement?.commentElementId)) as ExcalidrawCommentableElement | undefined ?? previousCommentableElement;

        if (commentableElement) {
          setPreviousCommentableElement(commentableElement);

          return <CommentsSidebarPanel
            appState={appState}
            actionManager={actionManager}
            commentElement={commentElement}
            commentableElement={commentableElement}
          />
        }
        return <DefaultSidebar />; // CHANGED:ADD 2023/09/01 #970
      default:
        return <DefaultSidebar />;
    }
  }, [appState.openSidebar, appState.selectedElementIds]);

  const [hostSidebarCounters] = useAtom(hostSidebarCountersAtom, jotaiScope);

  const layerUIJSX = (
    <>
      {/* ------------------------- tunneled UI ---------------------------- */}
      {/* make sure we render host app components first so that we can detect
          them first on initial render to optimize layout shift */}
      {children}
      {/* render component fallbacks. Can be rendered anywhere as they'll be
          tunneled away. We only render tunneled components that actually
          have defaults when host do not render anything. */}
      <DefaultMainMenu UIOptions={UIOptions} />
      {/* ------------------------------------------------------------------ */}

      {appState.isLoading && <LoadingMessage delay={250} />}
      {appState.errorMessage && (
        <ErrorDialog onClose={() => setAppState({ errorMessage: null })}>
          {appState.errorMessage}
        </ErrorDialog>
      )}
      {appState.openDialog === "help" && (
        // CHANGED:UPDATE 2023-1-10 #401
        // <HelpDialog
        <HelpDialogEx
          onClose={() => {
            setAppState({ openDialog: null });
          }}
        />
      )}
      {/* CHANGED:ADD 2024-10-5 #2114 */}
      {appState.openDialog === "layerSettings" && (
        <LayerSettingsDialog
          elements={elements}
          appState={appState}
          setAppState={setAppState}
        />
      )}
      {/* CHANGED:ADD 2023-1-26 #517 */}
      {appState.openDialog === "editTask" && (
        actionManager.renderAction("editTaskElement")
      )}
      {/* CHANGED:ADD 2024-04-04 #1847 */}
      {appState.openDialog === "editMultipleTask" && (
        actionManager.renderAction("editMultipleTaskElement")
      )}
      {/* CHANGED:ADD 2023-2-2 #552 */}
      {appState.openDialog === "datePicker" && (
        actionManager.renderAction("datePicker")
      )}
      {appState.openDialog === "settings" && (
        actionManager.renderAction("settings")
      )}
      {appState.openDialog === "addComment" && (
        actionManager.renderAction("addComment") // CHANGED:ADD 2023-12-04 #1327
      )}
      {appState.openDialog === "listComments" && (
        actionManager.renderAction("listComments") // CHANGED:ADD 2023-12-04 #1327
      )}
      {/* CHANGED:ADD 2024-02-06 #1579 */}
      {appState.openDialog === "addLibrary" && (
        actionManager.renderAction("addLibrary")
      )}
      {/* CHANGED:ADD 2023-2-24 #741 */}
      {appState.openDialog === "shareLink" && (
        <ShareLinkDialog
          appState={appState}
          setAppState={setAppState}
        />
      )}
      {/* CHANGED:ADD 2023/09/13 #1023 */}
      {appState.openDialog === "deleteProject" && (
        <DeleteProjectDialog
          appState={appState}
          setAppState={setAppState}
          onDeleteProject={onDeleteProject}
        />
      )}
      <ActiveConfirmDialog />
      {renderImageExportDialog()}
      {renderPrintExportDialog()}
      {renderJSONExportDialog()}
      {appState.pasteDialog.shown && (
        <PasteChartDialog
          setAppState={setAppState}
          appState={appState}
          onInsertChart={onInsertElements}
          onClose={() =>
            setAppState({
              pasteDialog: { shown: false, data: null },
            })
          }
        />
      )}
      {device.isMobile && (
        <MobileMenu
          app={app}
          appState={appState}
          elements={elements}
          actionManager={actionManager}
          renderJSONExportDialog={renderJSONExportDialog}
          renderImageExportDialog={renderImageExportDialog}
          renderPrintExportDialog={renderPrintExportDialog}
          setAppState={setAppState}
          onLockToggle={onLockToggle}
          onHandToolToggle={onHandToolToggle}
          onPenModeToggle={onPenModeToggle}
          renderTopRightUI={renderTopRightUI}
          renderCustomStats={renderCustomStats}
          renderSidebars={renderSidebars}
          device={device}
          renderWelcomeScreen={renderWelcomeScreen}
          UIOptions={UIOptions}
        />
      )}

      {!device.isMobile && (
        <>
          <div
            className={clsx("layer-ui__wrapper", {
              "disable-pointerEvents":
                appState.draggingElement ||
                appState.resizingElement ||
                (appState.editingElement &&
                  !isTextElement(appState.editingElement)),
            })}
            // CHANGED:UPDATE 2023/09/04 #986
            // style={
            //   ((appState.openSidebar === "library" &&
            //     appState.isSidebarDocked) ||
            //     hostSidebarCounters.docked) &&
            //   device.canDeviceFitSidebar
            //     ? { width: `calc(100% - ${LIBRARY_SIDEBAR_WIDTH}px)` }
            //     : {}
            // }
            style={
              ((appState.openSidebar === "library" &&
                appState.isSidebarDocked) ||
                hostSidebarCounters.docked) &&
                device.canDeviceFitSidebar
                ? { width: `calc(100% - ${LIBRARY_SIDEBAR_WIDTH + 60}px)` }
                : { width: `calc(100% - ${DEFAULT_SIDEBAR_WIDTH * 2}px)` }
            }
          >
            {renderWelcomeScreen && <tunnels.welcomeScreenCenterTunnel.Out />}
            {renderFixedSideContainer()}
            {/* CHANGED:UPDATE 2022-11-10 #119 */}
            {/* <Footer
              appState={appState}
              actionManager={actionManager}
              showExitZenModeBtn={showExitZenModeBtn}
              renderWelcomeScreen={renderWelcomeScreen}
            /> */}
            <ToolBarEx
              app={app}
              appState={appState}
              actionManager={actionManager}
              showExitZenModeBtn={showExitZenModeBtn}
              canvas={canvas}
              setAppState={setAppState}
              elements={elements}
              onPenModeToggle={onPenModeToggle}
              onLockToggle={onLockToggle}
              onHandToolToggle={onHandToolToggle}
              renderWelcomeScreen={renderWelcomeScreen}
              UIOptions={UIOptions}
            />
            {appState.showStats && (
              <Stats
                appState={appState}
                setAppState={setAppState}
                elements={elements}
                onClose={() => {
                  actionManager.executeAction(actionToggleStats);
                }}
                renderCustomStats={renderCustomStats}
              />
            )}
            {appState.scrolledOutside && (
              <button
                className="scroll-back-to-content"
                onClick={() => {
                  setAppState({
                    // CHANGED:UPDATE 2023-2-24 #744
                    // ...calculateScrollCenter(elements, appState, canvas),
                    ...Scroll.calculateScrollCenterEx(appState),
                  });
                }}
              >
                {t("buttons.scrollBackToContent")}
              </button>
            )}
          </div>
          {!appState.viewModeEnabled
            ? renderSidebars // CHANGED:UPDATE 2024-02-23 #1684
            : renderViewModeSidebars // CHANGED:ADD 2024-02-15 #1567
          }
        </>
      )}
    </>
  );

  return (
    <Provider scope={tunnels.jotaiScope}>
      <TunnelsContext.Provider value={tunnels}>
        {layerUIJSX}
      </TunnelsContext.Provider>
    </Provider>
  );
};

const stripIrrelevantAppStateProps = (appState: AppState): UIAppState => {
  const {
    suggestedBindings,
    startBoundElement,
    cursorButton,
    scrollX,
    scrollY,
    ...ret
  } = appState;
  return ret;
};

const areEqual = (prevProps: LayerUIProps, nextProps: LayerUIProps) => {
  // short-circuit early
  if (prevProps.children !== nextProps.children) {
    return false;
  }

  const { canvas: _pC, appState: prevAppState, ...prev } = prevProps;
  const { canvas: _nC, appState: nextAppState, ...next } = nextProps;

  return (
    isShallowEqual(
      // asserting AppState because we're being passed the whole AppState
      // but resolve to only the UI-relevant props
      stripIrrelevantAppStateProps(prevAppState as AppState),
      stripIrrelevantAppStateProps(nextAppState as AppState),
      {
        selectedElementIds: isShallowEqual,
        selectedGroupIds: isShallowEqual,
      },
    ) && isShallowEqual(prev, next)
  );
};

export default React.memo(LayerUI, areEqual);
