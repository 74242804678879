import { WorkType } from "src/conpath/constants/WorkType";
import { OrganizationRole } from "../constants/Role";
import { AppState } from "src/excalidraw/types";

export default interface LoginUser {
  id: string,
  username: string,
  profileImageUrl: string,
  email: string,
  phoneNumber: string,
  organizationRole?: OrganizationRole,
  workType: WorkType[],
  selectedOrganizationId?: string,
  appState: Partial<AppState>,
  projectSortSelected: number,
  isSuperUser?: boolean,
};

export const LoginUserDocumentFields = {
  id: "id",
  username: "username",
  phoneNumber: "phoneNumber",
  profileImageUrl: "profileImageUrl",
  email: "email",
  organizationRole: "organizationRole",
  workType: "workType",
  selectedOrganizationId: "selectedOrganizationId",
  appState: "appState",
  projectSortSelected: "projectSortSelected",
} as const;

export type LoginUserDocumentFields = typeof LoginUserDocumentFields[keyof typeof LoginUserDocumentFields];

export interface Image {
  url: string,
  file: File|null
} 
export interface LoginUserProfileForm extends Pick<LoginUser, "username"|"phoneNumber"> {
  profileImage: Image | null;
}

export interface LogingUserEmailForm {
  newEmail: string;
  confirmEmail: string;
}

export interface LogingUserPasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
