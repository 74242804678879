import React, { Fragment, useState } from "react";
import { Paths } from "src/conpath/constants/Routes";
import HeaderNav from "./HeaderNav";
import "./Header.scss";
import { Menu, Transition } from "@headlessui/react";

//constants
import { LocalStorage } from "src/conpath/constants/LocalStorage";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";

//css
import "./Header.scss";
import HoverToolTip from "../HoverToolTip";

const Header: React.FC = observer(() => {
  const { userStore, organizationStore } = useStore();
  const { selectedOrganization } = organizationStore;
  const { loginUser } = userStore;

  const handleSignOut = async () => {
    localStorage.removeItem(LocalStorage.RoadmapStore);
    localStorage.removeItem(LocalStorage.ResourcesStore);
    await organizationStore.clear();
    await userStore.signOut();
  };

  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="cp-header">
      <h1 className="cp-header__logo">
        <a
          href={Paths.dashboard}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ width: "26.83px", height: "30px" }}
        >
          <img src="/logo.svg" />
          <HoverToolTip
            isShow={isTooltipVisible}
            value="ダッシュボードへ戻る"
          />
        </a>
      </h1>
      <nav className="cp-header__nav">
        <ul style={{ height: "100%" }}>
          <HeaderNav
            path={Paths.projects}
            name={"プロジェクト"}
            count={
              selectedOrganization && selectedOrganization.projects.length > 0
                ? selectedOrganization.projects.length
                : undefined
            }
          />
          {
            (loginUser?.isOrganizationOwner() || loginUser?.isOrganizationMember())
              ?
              <>
                <HeaderNav path={Paths.roadmap} name={"ロードマップ"} />
                <HeaderNav path={Paths.resources} name={"リソース"} />
                <HeaderNav path={Paths.settings} name={"設定"} />
              </>
              :
              <HeaderNav path={Paths.settings + Paths.profile} name={"設定"} />
          }
          <li style={{ marginLeft: 'auto' }}>
            <Menu as="div" className="w-full h-full relative inline-block z-10">
              <Menu.Button className="h-full rounded-full px-2 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24px" height="24px"><path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z" /></svg>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`${active ? "bg-[#57AEDF] text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          href="https://docs.conpath.jp"
                          target="_blank"
                        >
                          マニュアル
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>
      </nav>
      <nav className="cp-header__setting">
        <ul>
          <li style={{ cursor: "pointer", width: "29px", height: "29px" }}>
            <Menu as="div" className="w-full h-full relative inline-block text-left z-10">
              <Menu.Button className="rounded-full px-2 py-2 text-sm font-medium text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="18" height="18" viewBox="0 0 13.281 15.422">
                  <g transform="translate(0.65 0.65)">
                    <path d="M12.492,7.094A3.047,3.047,0,1,0,9.445,4.047,3.047,3.047,0,0,0,12.492,7.094Z" transform="translate(-6.502 -1)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                    <path d="M6.99,24.566A5.832,5.832,0,0,0,1,30.226c0,.084.009.167.014.25H12.967c0-.083.014-.166.014-.25A5.832,5.832,0,0,0,6.99,24.566Z" transform="translate(-1 -16.354)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                  </g>
                </svg>

              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <a href={Paths.settings + Paths.profile}>
                        <label
                          className={"group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer"}
                        >
                          <div className="rounded-full px-3 py-3 text-sm font-medium text-white bg-[#46AADF] mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler" width="18" height="18" viewBox="0 0 13.281 15.422">
                              <g transform="translate(0.65 0.65)">
                                <path d="M12.492,7.094A3.047,3.047,0,1,0,9.445,4.047,3.047,3.047,0,0,0,12.492,7.094Z" transform="translate(-6.502 -1)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                                <path d="M6.99,24.566A5.832,5.832,0,0,0,1,30.226c0,.084.009.167.014.25H12.967c0-.083.014-.166.014-.25A5.832,5.832,0,0,0,6.99,24.566Z" transform="translate(-1 -16.354)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" />
                              </g>
                            </svg>
                          </div>

                          {loginUser?.username}
                        </label>
                      </a>
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? "bg-[#57AEDF] text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => handleSignOut()}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout mr-1 ml-1" width="24" height="24" viewBox="0 0 24 24">
                            <g transform="translate(0.5 0.5)">
                              <rect width="23" height="23" fill="#fff" stroke="#000" strokeWidth="1" opacity="0" />
                              <path d="M13.255,9.244H8.184V22.58h5.071" transform="translate(-3.906 -4.412)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                              <line x1="10.869" transform="translate(7.288 11.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                              <path d="M29.993,15.861l3.044,3.209-3.044,3.209" transform="translate(-14.315 -7.57)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            </g>
                          </svg>
                          ログアウト
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>
      </nav>
    </div>
  );
});

export default Header;
