import React from "react";
import "./TagCloud.scss";
import Badge from '../Badge'
import TaskModel from "src/conpath/models/TaskModel";
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

interface Props {
  task: TaskModel;
}

const TagCloud: React.FC<Props> = (props: Props) => {

  const { task } = props;
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const onClose = (tag: string) => {
    task.removeTag(tag);
    task.saveTags();
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.nativeEvent.isComposing) return;

    const tagCount = Object.keys(task.tags)?.length || 0;

    const value = e.currentTarget.value;
    if (!value.length && e.key == 'Backspace' && tagCount > 0) {
      const tag = Object.keys(task.tags).pop() || "";
      onClose(tag);
      return;
    }

    if (e.key !== 'Enter' || !value.trim()) return;
    task.addTag(value);
    task.saveTags();
    e.currentTarget.value = '';
    e.preventDefault();
  }

  if (!selectedOrganization || !loginUser) return null;

  return (
    <div
      className={'flex flex-nowrap items-center leading-tight px-2 rounded bg-white h-[45px] max-w-[550px] overflow-x-auto overflow-y-hidden tags-container'}
    >
      {Object.keys(task.tags).map((tag, i) => {
        return (
          <Badge
            key={i}
            onClose={() => onClose(tag)}
            className={""}>
            {tag}
          </Badge>
        )
      })}
      <input
        type="text"
        className={'border-0 outline-none flex-grow'}
        onKeyDown={handleKeyDown}
        {...props}
      />
    </div>
  )
};

export default observer(TagCloud);