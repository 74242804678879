import React, { Dispatch, Fragment, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { t } from "src/excalidraw/i18n";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import clsx from "clsx";
import { isEmpty } from "lodash";

//components
import { Menu, Popover, Transition } from "@headlessui/react";
import { FiDelete } from "react-icons/fi";
import { PiDotsThreeBold } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import Checkbox from "src/conpath/components/CheckBox";
import {Line} from "rc-progress";
import HoverToolTip from "src/conpath/components/HoverToolTip";

//hooks & utils
import { formatDate } from "src/utils/dateUtils";
import { useStore } from "src/conpath/hooks/useStore";
import useChecklist from "../hooks/useChecklist";

//styles
import "./EditTaskChecklist.scss";

//constants
import { DocumentUpdateState } from "src/conpath/constants/General";

//models
import TaskModel from "src/conpath/models/TaskModel";
import { addHttpIfNeeded, copyToClipboardString, isValidURL } from "src/excalidraw/utils";
import { MdOutlineLink } from "react-icons/md";
import ChecklistModel from "src/conpath/models/ChecklistModel";

type urlPopoverProps = {
  checklist: ChecklistModel,
  buttonSize?: string,
}

export const UrlPopover: React.FC<{ props: urlPopoverProps }> = ({ props }) => {
  const { checklist, buttonSize = "40px" } = props;
  const [checkUrl, setCheckUrl] = useState<string>("");
  const [urlEditModeEnabled, setUrlEditModeEnabled] = useState<boolean>(false);
  const triggerRef = React.useRef<any>(null);
  const timeOutRef = React.useRef<any>(null);
  const [isComposing, setComposing] = useState<boolean>(false);

  const handleEnter = ({ e, isOpen }: { e: React.MouseEvent<HTMLDivElement, MouseEvent>, isOpen: boolean }) => {
    const targetElement = e.target as EventTarget & HTMLDivElement
    if(targetElement.classList.contains("handleEnterTraget")) {
      clearTimeout(timeOutRef.current);
      !isOpen && triggerRef.current?.click();
    }
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, 100);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    item: ChecklistModel,
  ) => {
    if (e.key === "Enter" && !isComposing) {
      if (!isValidURL(checkUrl)) return;
      item.changeUrl(addHttpIfNeeded(checkUrl));
      setUrlEditModeEnabled(false);
      e.preventDefault();
    }
  };

  const linkOpen = (url: string) => {
    try {
      window.open(encodeURI(url), "_blank");
    } catch {
      window.alert("不正なURLためアクセスすることができません。")
    }
  }

  const PopoverButton: React.FC<{
    children: React.ReactNode;
    onClick: () => void;
    className?: String;
  }> = ({ children, onClick, className = "" }) => {
    return (
      <button
        className={clsx(
          "text-[12px] w-[50px] hover:opacity-70 transition-all ease-out duration-100",
          className,
        )}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };
  return <>
    {!checklist.url || urlEditModeEnabled ? (
      // insert URL
      <Popover>
        {({ open }) => {
          setUrlEditModeEnabled(open);
          return (
            <React.Fragment>
              <Popover.Button
                onClick={() => {
                  setUrlEditModeEnabled(false);
                  setCheckUrl(checklist.url);
                }}
                className={clsx(
                  `flex absolute right-0 top-1 cursor-pointer justify-center items-center`,
                )}
                style={{
                  width: buttonSize,
                  height: buttonSize,
                }}
              >
                <MdOutlineLink
                  className="hover:opacity-70 transition-all ease-out duration-100 bg-white "
                  size={"20px"}
                  fill="#DDDDDD"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={
                    "absolute right-0 top-[45px] p-[10px] bg-white shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-2px_rgba(0,0,0,0.05)] z-[2]"
                  }
                >
                  <div className="flex w-[400px] h-full">
                    <input
                      type="text"
                      className={clsx(
                        "w-[320px] h-[32px] leading-[32px] p-[5px]",
                        "text-[12px] bg-[var(--input-bg-color)] text-[var(--text-primary-color)] border-solid border-[1.5px] border-[var(--input-border-color)] p-3 whitespace-nowrap rounded-[var(--space-factor)]",
                      )}
                      value={checkUrl}
                      onChange={(e) => setCheckUrl(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, checklist)}
                      onCompositionStart={() => setComposing(true)}
                      onCompositionEnd={() => setComposing(false)}
                    />
                    <PopoverButton
                      className={clsx("ml-auto")}
                      onClick={() => {
                        if(!isValidURL(checkUrl)) return;
                        checklist.changeUrl(addHttpIfNeeded(checkUrl));
                        setUrlEditModeEnabled(false);
                      }}
                    >
                      適用
                    </PopoverButton>
                  </div>
                </Popover.Panel>
              </Transition>
            </React.Fragment>
          );
        }}
      </Popover>
    ) : (
      // update URL
      <Popover>
        {({ open }) => {
          return (
            <div
              className="bg-transparent"
              // className="bg-[#ff0000] w-[450px] h-[85px] top-0 z-[0] absolute right-0"
              onMouseEnter={(e) => handleEnter({ e, isOpen: open })}
              onMouseLeave={() => handleLeave(open)}
            >
              <Popover.Button hidden ref={triggerRef} />
              <a
                onClick={() => linkOpen(checklist.url)}
                className={clsx(
                  `handleEnterTraget flex absolute right-0 top-0 cursor-pointer justify-center items-center`,
                )}
                style={{
                  width: buttonSize,
                  height: buttonSize,
                }}
              >
                <MdOutlineLink
                  className="hover:opacity-70 transition-all ease-out duration-100 bg-white"
                  size={"20px"}
                  fill="var(--link-color)"
                />
              </a>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={
                    "absolute right-0 top-[45px] p-[10px] bg-white shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-2px_rgba(0,0,0,0.05)] z-[2]"
                  }
                >
                  <div 
                    className="absolute bg-transparent w-full h-[18px] top-[-12px] right-0"
                  />
                  <div className="flex gap-[8px] justify-start items-center w-[450px]">
                    <a
                      onClick={() => linkOpen(checklist.url)}
                      className={clsx(
                        "cursor-pointer text-[12px] text-[var(--link-color)] w-full overflow-hidden text-ellipsis whitespace-nowrap hover:underline",
                      )}
                    >
                      {checklist.url}
                    </a>
                    <PopoverButton
                      className={clsx("ml-auto")}
                      onClick={() => {
                        copyToClipboardString(checklist.url);
                      }}
                    >
                      コピー
                    </PopoverButton>
                    <PopoverButton
                      onClick={() => {
                        setUrlEditModeEnabled(true);
                        setCheckUrl(checklist.url);
                      }}
                    >
                      編集
                    </PopoverButton>
                    <PopoverButton
                      onClick={() => {
                        setCheckUrl("");
                        checklist.changeUrl("");
                        handleLeave(open);
                      }}
                    >
                      削除
                    </PopoverButton>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          );
        }}
      </Popover>
    )}
  </>
};

interface Props {
  taskModel: TaskModel;
  isClosed: boolean;
  setIsClosed: Dispatch<React.SetStateAction<boolean>>;
  titleRef: React.RefObject<HTMLTextAreaElement> | null;
};

const EditTaskCheckList: React.FC<Props> = (props: Props) => {
  const { taskModel, isClosed, titleRef, setIsClosed } = props;
  const { organizationStore, userStore } = useStore();
  const [isClosedBoxHighlighted, setIsClosedBoxHighlighted] = useState<boolean>(false);
  const { selectedOrganization } = organizationStore;
  const { loginUser } = userStore;

  const { allowPaste, setAllowPaste, handlePaste } = useChecklist({ loginUser, taskModel });
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (taskModel.allChecked) {
      setIsClosedBoxHighlighted(!isClosed);
    } else {
      setIsClosedBoxHighlighted(false);
    }
  }, [taskModel.allChecked, isClosed]);

  if (!selectedOrganization || !loginUser) return <></>

  return (
    <>
      <div
        onMouseEnter={() => {
          if (!taskModel.checklists.length) {
            timeoutRef.current = window.setTimeout(() => {
              setAllowPaste(true);
            }, 2000); // 2秒後にツールチップを表示
          };
        }}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setAllowPaste(false);
        }}
        className={taskModel.checklists.length ? "task-checklist no-border" : "task-checklist"}>
          
        <HoverToolTip 
          position="top"
          tailCenter={true}
          style={{
            width: 250,
            position: 'absolute',
            top: 'calc(50% - 80px)',
            left: 'calc(50% - 125px)',
          }}
          isShow={allowPaste} 
          value="プラスボタンもしくはエクセルのセルをコピー&ペーストで挿入できます。" />

        {taskModel.checklists
          ?.filter((item) => item.documentState !== DocumentUpdateState.DELETE)
          .sort((a, b) => a.createdAt!.valueOf() - b.createdAt!.valueOf())
          .map((item) => (
            <div className="task-checklist__item" key={item.id}>
              <Checkbox
                props={{
                  onClick: () => item.toggleCheckState(loginUser),
                  checked: item.isChecked,
                  value: item.id,
                  className: "task-checklist__item__checkbox",
                }}
              />
              <li className="task-checklist__item__taskname">
                <textarea
                  value={item.title}
                  onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => {
                    handlePaste(e as any, item);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    item.changeTitle(e.target.value)
                  }}
                  />
                <UrlPopover props={{ checklist: item }} />
                <div>
                  <span>
                    作成者:{" "}
                    {item.createdBy
                      ? selectedOrganization.findUserById(item.createdBy)
                          ?.username
                      : ""}{" "}
                    {formatDate(item.createdAt)}
                  </span>
                  <span>
                    確認者:{" "}
                    {item.checkedBy
                      ? selectedOrganization.findUserById(item.checkedBy)
                          ?.username
                      : ""}{" "}
                    {formatDate(item.checkedAt)}
                  </span>
                </div>
              </li>
              <div className="task-checklist__item__menu">
                <Menu as="menu">
                  <Menu.Button>
                    <PiDotsThreeBold
                      className="mx-0 h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="task-checklist__item__menu__options">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              taskModel.removeChecklist(item.getFields());
                            }}
                            className={`${
                              active
                                ? "bg-[#57AEDF] text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <FiDelete
                                className="mr-2 h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <FiDelete
                                className="mr-2 h-5 w-5 text-[#57AEDF]"
                                aria-hidden="true"
                              />
                            )}
                            削除
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          ))}
        <div className="task-checklist__button-add">
          <VscAdd
            onClick={() => {
              setAllowPaste(false);
              taskModel.createChecklist({
                id: nanoid(),
                title: "",
                url: "",
                isChecked: false,
                checkedBy: null,
                checkedAt: null,
                createdBy: loginUser.id,
                createdAt: new Date(),
              });
            }}
          />
        </div>
      </div>
      <div className="btn-checklist">
        {taskModel.checklists.length > 0 &&
          (() => {
            const progress = taskModel.getCheckedChecklist?.filter(
              (item) => item.documentState !== DocumentUpdateState.DELETE,
            ).length;
            const steps = taskModel.checklists?.filter(
              (item) => item.documentState !== DocumentUpdateState.DELETE,
            ).length;
            return (
              <>
                <div className="btn-checklist__progress">
                  <Line
                    strokeWidth={100}
                    trailWidth={100}
                    trailColor="#F5F5F5"
                    strokeLinecap={"butt"}
                    percent={(progress / steps) * 100}
                    style={{
                      height: "6px",
                    }}
                  />
                  <div className="leading-8">{`${progress} / ${steps}`}</div>
                </div>
              </>
            );
          })()}
        {/* <div
          className={
            isClosedBoxHighlighted
              ? "is-closed__wrapper highlighted"
              : "is-closed__wrapper"
          }
        >
          <Checkbox
            props={{
              id: "task-isClosed-check",
              onClick: () => setIsClosed(!isClosed),
              checked: isClosed,
              className: "rmdp-task-isClosed-check",
            }}
          />
          <label htmlFor="task-isClosed-check">
            {t("labels.editTask.isClosed")}
          </label>
        </div> */}
      </div>
    </>
  );
};

export default observer(EditTaskCheckList);