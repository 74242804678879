export const UpsertResourceRequestErrorType = {
  General: "General",
  ValidationFailed: "ValidationFailed",
} as const;

export type UpsertResourceRequestErrorType = typeof UpsertResourceRequestErrorType[keyof typeof UpsertResourceRequestErrorType];


export const CreateResourceRequestError: { [key in UpsertResourceRequestErrorType]: string } = {
  General: "リソースの作成に失敗しました。",
  ValidationFailed: "リソースの作成に失敗しました。入力欄をご確認ください。",
} as const;


export const UpdateResourceRequestError: { [key in UpsertResourceRequestErrorType]: string } = {
  General: "リソースの編集に失敗しました。",
  ValidationFailed: "リソースの編集に失敗しました。入力欄をご確認ください。",
} as const;