import { GeneralDocumentQueryErrorType } from ".";


export const GetOrganizationRequestError: { [key in GeneralDocumentQueryErrorType]: string } = {
  DocumentEmpty: "組織に関する情報が見つかりませんでした。",
  NetworkingError: "組織に関する情報の取得に失敗しました。",
} as const;


export const CreateOrganizationRequestErrorType = {
  General: "General",
  AuthFailed: "AuthFailed",
  ArgumentValidationFailed: "ArgumentValidationFailed",
  UserNotFound: "UserNotFound",
} as const;

export type CreateOrganizationRequestErrorType = typeof CreateOrganizationRequestErrorType[keyof typeof CreateOrganizationRequestErrorType];

export const CreateOrganizationRequestErrors: { [key in CreateOrganizationRequestErrorType]: string } = {
  General: "組織の作成に失敗しました。",
  AuthFailed: "ユーザー情報が認証できませんでした。再度ログインして下さい。",
  ArgumentValidationFailed: "組織の作成に失敗しました。",
  UserNotFound: "該当するユーザーが見つかりませんでした。",
} as const;


export const UpsertProjectRequestErrorType = {
  General: "General",
  ValidationFailed: "ValidationFailed",
  UserNotExists: "UserNotExists",
  UserRemovedFromOrganization: "UserRemovedFromOrganization",
  UserRoleDoesNotMatch: "UserRoleDoesNotMatch",
} as const;

export type UpsertProjectRequestErrorType = typeof UpsertProjectRequestErrorType[keyof typeof UpsertProjectRequestErrorType];


export const CreateProjectRequestError: { [key in UpsertProjectRequestErrorType]: string } = {
  General: "プロジェクトの作成に失敗しました。",
  ValidationFailed: "プロジェクトの作成に失敗しました。入力欄をご確認ください。",
  UserNotExists: "組織内にユーザーの情報が見つかりませんでした。再度ログインしてお試し下さい。",
  UserRemovedFromOrganization: "組織から退出されたようです。ログアウトします。",
  UserRoleDoesNotMatch: "プロジェクトの作成権限がありません。",
} as const;


export const UpdateProjectRequestError: { [key in UpsertProjectRequestErrorType]: string } = {
  General: "プロジェクトの編集に失敗しました。",
  ValidationFailed: "プロジェクトの編集に失敗しました。入力欄をご確認ください。",
  UserNotExists: "組織内にユーザーの情報が見つかりませんでした。再度ログインしてお試し下さい。",
  UserRemovedFromOrganization: "組織から退出されたようです。ログアウトします。",
  UserRoleDoesNotMatch: "プロジェクトの編集権限がありません。",
} as const;


export const DupulicateProjectRequestError: { [key in UpsertProjectRequestErrorType]: string } = {
  General: "プロジェクトの複製に失敗しました。",
  ValidationFailed: "プロジェクトの複製に失敗しました。入力欄をご確認ください。",
  UserNotExists: "組織内にユーザーの情報が見つかりませんでした。再度ログインしてお試し下さい。",
  UserRemovedFromOrganization: "組織から退出されたようです。ログアウトします。",
  UserRoleDoesNotMatch: "プロジェクトの複製権限がありません。",
} as const;