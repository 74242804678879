import { action, observable, runInAction, makeObservable, autorun, computed } from "mobx";
import _ from 'lodash';
import { nanoid } from "nanoid";

//types and interface
import Organization, { OrganizationResponse, OrganizationProfileForm } from "src/conpath/interfaces/Organization";
import { ProjectFields, ProjectInputForm, ProjectSearchField, ProjectResponse } from "src/conpath/interfaces/Project";
import Team, { TeamInputForm, TeamResponse } from "../interfaces/Team";
import { OrganizationUserResponse, CreateOrganizationUsersRequest } from "../interfaces/User";
import { AddOrganizationUserRequestParam, AddOrganizationUserResponse } from "src/conpath/interfaces/HttpRequests";
import { DefaultRecordType, Gantt } from "../components/gantt/types";
import Task, { TaskResponse, GanttChartTask, TaskDocumentFields } from "../interfaces/Task";
import Milestone, { MilestoneDocumentFields, MilestoneResponse, GanttChartMilestone } from "../interfaces/Milestone";
import { RoadmapFilter } from "src/conpath/interfaces/RoadmapFilter";
import { ResourcesFilter } from "src/conpath/interfaces/ResourcesFilter";
import { OrganizationActivityDocumentFields, OrganizationActivityResponse } from "src/conpath/interfaces/Activity";

//models
import ProjectModel from "src/conpath/models/ProjectModel";
import OrganizationUserModel from "src/conpath/models/OrganizationUserModel";
import TeamModel from "src/conpath/models/TeamModel";
import LoginUserModel from "src/conpath/models/LoginUserModel";

//firebase
import { db, QueryType, functions, storage } from "src/configs/firebase";
import { httpsCallable } from "firebase/functions";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Timestamp,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { FirestoreCollections } from "../constants/FirestoreCollections";

//helpers & utils
import OrganizationValidation from "../helpers/validations/OrganizationValidation";
import { dateToFirebaseTime, firebaseTimeToDate } from "src/utils/timeUtils";
import Roadmap from "../helpers/roadmap";
import Checklist, { ChecklistResponse } from "../interfaces/Checklist";

//constants
import { FirebaseHttpsRequests } from "src/conpath/constants/FirebaseHttpsRequests";
import {
  AddOrganizationUsersRequestError,
  AddOrganizationUsersRequestErrorType,
  GetOrganizationUserRequestError
} from "src/conpath/constants/errors/OrganizationUserRequestErrors";
import {
  GetOrganizationProjectsRequestError,
} from "src/conpath/constants/errors/OrganizationProjectRequestErrors";
import { OrganizationRole, ProjectRole } from "src/conpath/constants/Role";
import { OrganizationUserState } from "src/conpath/constants/OrganizationUserState";
import { CreateProjectRequestError, DupulicateProjectRequestError, UpdateProjectRequestError, UpsertProjectRequestErrorType } from "../constants/errors/OrganizationRequestErrors";
import { CreateResourceRequestError, UpdateResourceRequestError, UpsertResourceRequestErrorType } from "../constants/errors/ResourceRequestErrors";
import { CreateTeamRequestError, UpdateTeamRequestError, UpsertTeamRequestErrorType } from "../constants/errors/TeamRequestErrors";
import { GeneralDocumentQueryErrorType } from "src/conpath/constants/errors";
import { GetRoadmapDataRequestError } from "src/conpath/constants/errors/RoadmapRequestError";
import TaskModel from "./TaskModel";
import MilestoneModel from "./MilestoneModel";
import { ExcalidrawTaskElement } from "src/excalidraw/extensions/element/types";
import InternalError from "../interfaces/InternalError";
import CommentModel from "./CommentModel";
import Comment, { CommentDocumentFields, CommentResponse, UploadedFile } from "../interfaces/Comment";
import { GetResourcesDataRequestError } from "../constants/errors/ResourcesRequestError";
import Resource, {
  Image,
  ResourceResponse,
  ResourceForm,
} from "../interfaces/Resource";
import { LibraryResponse } from "../interfaces/Library";
import { FirebaseStorage } from "../constants/FirebaseStorage";
import { getFirebaseStorageErrorText } from "../constants/FirebaseErrors";

import { getSyncableElements } from "src/excalidraw/excalidraw-app/data";
import { isCommentElement, isLinkElement, isMilestoneElement, isNodeElement, isTaskElement } from "src/excalidraw/extensions/element/typeChecks";
import { duplicateElement } from "src/excalidraw/element/newElement";
import { bindTextToShapeAfterDuplication, getBoundTextElement } from "src/excalidraw/element/textElement";
import { fixBindingsAfterDuplication } from "src/excalidraw/element/binding";
import { fixBindingsAfterDuplicationEx } from "src/excalidraw/extensions/element/binding";
import { GRID_SIZE } from "src/excalidraw/constants";
import { arrayToMap } from "src/excalidraw/utils";
import ResourceModel from "./ResourceModel";
import LibraryModel from "./LibraryModel";
import { FloatLimit } from "src/conpath/constants/General";
import { OrganizationPlan, OrganizationPlanMap } from "src/conpath/constants/Plan";

export function isUploadedImage(path: string) {
  // 正規表現パターンにマッチするかどうかを判定
  const fullPathPattern = /^(?:http?:\/\/|https?:\/\/|blob?:)/;
  return fullPathPattern.test(path);
}
export default class OrganizationModel extends OrganizationValidation implements Organization {

  @observable
  id: string;
  @observable
  name: string;
  @observable
  iconImageUrl: string;
  @observable
  phoneNumber: string;
  @observable
  plan: number;
  @observable
  addUsers: number;
  @observable
  addProjects: number;
  @observable
  criticalPathColor: string;
  @observable
  floatAlarmLimit: number;
  @observable
  floatWarningLimit: number;
  @observable
  projects: ProjectModel[];
  @observable
  users: OrganizationUserModel[];
  @observable
  userIds: string[];
  @observable
  resources: ResourceModel[];
  @observable
  teams: TeamModel[];  
  @observable
  createdAt: Date;
  @observable
  updatedAt: Date;

  @observable
  isProjectLoading: boolean = false;
  @observable
  isNetworkProcessing: boolean = false;

  @observable
  isProjectTaskLoading: boolean = false;
  @observable
  isRemindTaskLoading: boolean = false;
  @observable
  isDashboardProjectLoading: boolean = false;
  @observable
  isDashboardActivityLoading: boolean = false;

  @observable
  roadmapFilter: RoadmapFilter
  @observable
  hasRoadmapFilterChanged: boolean = false;

  @observable
  resourcesFilter: ResourcesFilter
  @observable
  hasResourcesFilterChanged: boolean = false;

  @observable
  tasks: TaskModel[];
  @observable
  milestones: MilestoneModel[];

  @observable
  libraries: LibraryModel[] = [];

  @observable
  projectTasks: Gantt.Record[];
  @observable
  projectDependencies: Gantt.Dependence[];

  @observable
  isRoadmapDataReloadRequired: boolean = false; // TaskもしくはMilestoneが変更されたときに上げるフラグ
  @observable
  roadmapDataFetchError: string | null = null;

  @observable
  comments: CommentModel[] = [];
  @observable
  isResourcesDataReloadRequired: boolean = false; // TaskもしくはMilestoneが変更されたときに上げるフラグ
  @observable
  resourcesDataFetchError: string | null = null;

  organizationUserCollectionsListener: any | null = null;
  resourcesCollectionsListener: any | null = null;
  teamsCollectionsListener: any | null = null;

  projectCollectionListener: any | null = null;
  roadmapDataListener: any | null = null;
  roadmapFilterDataListener: any | null = null;
  isRoadmapFirstRender: boolean = true;

  resourcesDataListener: any | null = null;
  resourcesFilterDataListener: any | null = null;
  isResourcesFirstRender: boolean = true;

  constructor(organization: OrganizationResponse) {
    super();
    makeObservable(this);

    this.id = organization.id;
    this.name = organization.name;
    this.iconImageUrl = organization.iconImageUrl;
    this.createdAt = organization.createdAt;
    this.updatedAt = organization.updatedAt;
    this.phoneNumber = organization.phoneNumber;
    this.plan = organization.plan;
    this.addUsers = organization.addUsers || 0;
    this.addProjects = organization.addProjects || 0;
    this.criticalPathColor = organization.criticalPathColor;
    this.floatAlarmLimit = organization.floatAlarmLimit;
    this.floatWarningLimit = organization.floatWarningLimit;
    this.userIds = organization.userIds;

    this.projects = this.getProjectModels(organization);
    this.users = this.getUserModels(organization);
    this.resources = this.getResourceModels(organization);
    this.teams = this.getTeamModels(organization);

    this.roadmapFilter = {
      searchText: null,
      searchStatus: "open",
      searchAssignUser: null,
      searchAssignResource: null,
      searchProject: null,
      searchMilestone: null,
      showOnlyDelayedTasks: false,
    };

    this.resourcesFilter = {
      searchText: null,
      searchAssignUser: null,
      searchAssignResource: null,
      searchProject: null,
      searchMilestone: null,
      showOnlyTaskExists: true,
      showOnlyOverlapTasks: false,
      showOnlyBlankTasks: false,
    };

    this.tasks = this.getTasks(organization);
    this.milestones = this.getMilestones(organization);

    this.projectTasks = [];
    this.projectDependencies = [];
  }

  public getFields(): Organization {
    return {
      id: this.id,
      name: this.name,
      iconImageUrl: this.iconImageUrl,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      phoneNumber: this.phoneNumber,
      plan: this.plan,
      addUsers: this.addUsers,
      addProjects: this.addProjects,
      criticalPathColor: this.criticalPathColor,
      floatAlarmLimit: this.floatAlarmLimit,
      floatWarningLimit: this.floatWarningLimit,
      projects: this.projects,
      users: this.users,
      userIds: this.userIds,
      resources: this.resources,
      teams: this.teams,
    };
  }

  @action
  public async updateOrganizationProfile(form: OrganizationProfileForm): Promise<InternalError> {

    const validationError = this.validateOrganizationProfileInput(form);
    if (validationError) {
      return { error: validationError };
    }

    try {

      const _from: Partial<Organization> = {...form, iconImageUrl: form.iconImageFile.url};
      if (form.iconImageFile.file) {
        // カスタムアイコン画像のアップロード
        this.deleteOrganizationIconImage(this.iconImageUrl);
        const result = await this.uploadOrganizationIconImage(form.iconImageFile.file);
        _from.iconImageUrl = result.imageUrl;
      }

      const organizationCollectionParams: Partial<Organization> = {
        name: form.name,
        criticalPathColor: form.criticalPathColor,
        iconImageUrl: _from.iconImageUrl,
        floatAlarmLimit: form.floatAlarmLimit,
        floatWarningLimit: form.floatWarningLimit,
      };
      const documentRef = this.getOrganizationRef();

      await updateDoc(documentRef, { ...organizationCollectionParams })
        .then(() => { })
        .catch((error) => {
          console.log("[Error] Failed in organization profile transaction update. ", error.message);
          //sentry here.

          return { error: "組織の更新に失敗しました。" };
        });

      return { error: null };
    } catch (err) {
      const error = err as { message: string };
      console.log("[Error] Failed to save organization profile. ", error.message);
      // Sentry here.
      return { error: error.message };
    }
  }

  public async createProject(
    project: ProjectInputForm,
    loginUser: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<{
    project?: ProjectModel;
    error: string | null;
  }> {

    if (!this.validateProject(project)) {
      return { error: CreateProjectRequestError.ValidationFailed }
    }

    const data: Partial<ProjectResponse> = {
      id: "",
      name: project.name,
      color: project.color,
      address: project.address,
      description: project.description,
      tags: project.tags,
      startDate: dateToFirebaseTime(project.startDate!),
      endDate: dateToFirebaseTime(project.endDate!),
      holidays: project.holidays,
      jobsHeight: project.jobsHeight,
      backgroundColor: project.backgroundColor,
      roles: project.roles,
      teams: project.teams,
      resources: project.resources,
      isArchived: project.isArchived,
      updatedAt: Timestamp.now(),
    };
    const result = await this.upsertProject(data, loginUser, organization);
    if (result.error) {
      return {
        error: CreateProjectRequestError[result.error as UpsertProjectRequestErrorType] || CreateProjectRequestError.General
      };
    }

    return {
      project: new ProjectModel(result.project as ProjectResponse), // ここではPartialではなく、Fullが帰ってくる
      error: null,
    };
  }

  public async updateProject(
    project: ProjectInputForm,
    loginUser: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<{ error: string | null }> {

    if (!this.validateProject(project)) {
      return { error: UpdateProjectRequestError.ValidationFailed }
    }

    const data: Partial<ProjectResponse> = {
      id: project.id,
      name: project.name,
      color: project.color,
      address: project.address,
      description: project.description,
      tags: project.tags,
      startDate: dateToFirebaseTime(project.startDate!),
      endDate: dateToFirebaseTime(project.endDate!),
      holidays: project.holidays,
      jobsHeight: project.jobsHeight,
      backgroundColor: project.backgroundColor,
      roles: project.roles,
      teams: project.teams,
      resources: project.resources,
      isArchived: project.isArchived || false,
      archivedAt: project.archivedAt ? dateToFirebaseTime(project.archivedAt) : null,
      updatedAt: Timestamp.now(),
    };

    const result = await this.upsertProject(data, loginUser, organization);
    if (result.error) {
      return {
        error: UpdateProjectRequestError[result.error as UpsertProjectRequestErrorType] || UpdateProjectRequestError.General
      };
    }

    return { error: null };
  }

  public async duplicateProject(
    project: ProjectInputForm,
    loginUser: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<{ error: string | null }> {

    if (!this.validateProject(project)) {
      return { error: DupulicateProjectRequestError.ValidationFailed };
    }

    const sourceProject = await this.getProjectById(project.id);
    if (!sourceProject) {
      return { error: DupulicateProjectRequestError.ValidationFailed };
    }

    // 複製元プロジェクトをフェッチ
    const prefix = `${FirebaseStorage.projects}/${this.id}`;
    const prevData = await sourceProject?.loadElementsToStorage(prefix);
    const sourceElements = getSyncableElements(prevData);

    await sourceProject.getLayers();
    const sourceLayers = sourceProject.layers
      .filter((layer) => !layer.isDeleted)
      .map((layer) => layer.getFields());

    // 複製元プロジェクトの期間より短い場合は終了日を加算
    const sourceStartDate = firebaseTimeToDate(sourceProject.startDate);
    let sourceEndDate = firebaseTimeToDate(sourceProject.endDate);
    const destStartDate = project.startDate!;
    const destEndDate = project.endDate!;

    const lastElement = sourceElements.filter((element) => isNodeElement(element)).sort((a, b) => b.x - a.x)[0];
    if (isTaskElement(lastElement)) {
      sourceEndDate = new Date(lastElement.endDate);
    } else if (isMilestoneElement(lastElement)) {
      sourceEndDate = new Date(lastElement.date);
    }

    const sourcePeriod = (sourceEndDate.getTime() - sourceStartDate.getTime()) / (1000 * 60 * 60 * 24);
    const destPeriod = (destEndDate.getTime() - destStartDate.getTime()) / (1000 * 60 * 60 * 24);

    if (sourcePeriod > destPeriod) {
      destEndDate.setDate(destEndDate.getDate() + sourcePeriod - destPeriod);
    }

    // 複製先タスクの日付を更新する処理
    const offsetDay = (destStartDate.getTime() - sourceStartDate.getTime()) / (1000 * 60 * 60 * 24);
    const oldIdToDuplicatedId = new Map();
    const destElements = sourceElements
      .filter((element) => !isCommentElement(element))
      .map((element) => {
        if (isTaskElement(element)) {
          const startDate = new Date(element.startDate);
          const endDate = new Date(element.endDate);
          startDate.setDate(startDate.getDate() + offsetDay);
          endDate.setDate(endDate.getDate() + offsetDay);
          let duration = (endDate!.getTime() - startDate!.getTime()) / (1000 * 60 * 60 * 24);
          const destElement = duplicateElement(
            "",
            new Map(),
            element,
            {
              startDate,
              endDate,
              duration,
              isClosed: false,
            },
          );
          oldIdToDuplicatedId.set(element.id, destElement.id);
          return destElement;
        } else if (isMilestoneElement(element)) {
          const date = new Date(element.date);
          date.setDate(offsetDay);
          const destElement = duplicateElement(
            "",
            new Map(),
            element,
            {
              date: date,
              isClosed: false,
            },
          );
          oldIdToDuplicatedId.set(element.id, destElement.id);
          return destElement;
        } else if (isLinkElement(element)) {
          const destElement = duplicateElement(
            "",
            new Map(),
            element,
            {
              duration: element.width / GRID_SIZE,
              isClosed: false,
            },
          );
          oldIdToDuplicatedId.set(element.id, destElement.id);
          return destElement;
        } else {
          const destElement = duplicateElement(
            "",
            new Map(),
            element,
            {
              isClosed: false,
            }
          );
          oldIdToDuplicatedId.set(element.id, destElement.id);
          return destElement;
        }
      });
    bindTextToShapeAfterDuplication(destElements, sourceElements, oldIdToDuplicatedId);
    fixBindingsAfterDuplication(destElements, sourceElements, oldIdToDuplicatedId);
    fixBindingsAfterDuplicationEx(destElements, sourceElements, oldIdToDuplicatedId);

    // Firestoreにプロジェクトを保存
    const data: Partial<ProjectResponse> = {
      id: "",
      name: project.name,
      color: project.color,
      address: project.address,
      description: project.description,
      tags: project.tags,
      startDate: dateToFirebaseTime(destStartDate),
      endDate: dateToFirebaseTime(destEndDate),
      holidays: project.holidays,
      jobsHeight: project.jobsHeight,
      backgroundColor: project.backgroundColor,
      roles: project.roles,
      teams: project.teams,
      resources: project.resources,
      isArchived: project.isArchived,
      updatedAt: Timestamp.now(),
    };

    const result = await this.upsertProject(data, loginUser, organization);
    if (result.error) {
      return {
        error: DupulicateProjectRequestError[result.error as UpsertProjectRequestErrorType] || DupulicateProjectRequestError.General
      };
    }

    // Cloud Storageにシーンデータを保存
    const destProject = new ProjectModel(result.project as ProjectResponse);
    destProject.setOrganizationId(this.id);
    await destProject.saveElementsToStorage(prefix, destElements);

    // Firestoreにタスクとマイルストーンを保存
    const destElementsMap = arrayToMap(destElements);
    const addTasks: TaskModel[] = [];
    const addMilestones: MilestoneModel[] = [];

    destElements.forEach((el) => {
      if (isTaskElement(el)) {
        const task: Task = {
          ...el,
          projectId: destProject.id,
          text: getBoundTextElement(el, destElementsMap)?.originalText || "",
        };

        addTasks.push(new TaskModel(task));
      } else if (isMilestoneElement(el)) {
        const milestone: Milestone = {
          ...el,
          projectId: destProject.id,
          text: getBoundTextElement(el, destElementsMap)?.originalText || "",
        };

        addMilestones.push(new MilestoneModel(milestone));
      }
    });

    const taskRef = this.getProjectTasksRef();
    if (!_.isEmpty(taskRef)) {
      for (const chunkedTasks of _.chunk(addTasks, 500)) {
        const batch = writeBatch(db);
        chunkedTasks.forEach((task) => {
          batch.set(doc(taskRef, task.id), task.getUpdateTaskFields());
        });
        await batch.commit();
      }
    }

    const milestoneRef = this.getProjectMilestonesRef();
    if (!_.isEmpty(milestoneRef)) {
      for (const chunkedMilestones of _.chunk(addMilestones, 500)) {
        const batch = writeBatch(db);
        chunkedMilestones.forEach((milestone) => {
          batch.set(doc(milestoneRef, milestone.id), milestone.getFields());
        });
        await batch.commit();
      }
    }

    for (const el of sourceElements) {
      if (isTaskElement(el)) {
        const taskCollectionRef = this.getProjectTasksRef();
        const taskDocumentRef = doc(taskCollectionRef, el.id);
        const taskDocumentSnap = await getDoc(taskDocumentRef);
        if (taskDocumentSnap.exists()) {
          const data = taskDocumentSnap.data() as TaskResponse;
          const task = new TaskModel({
            ...data,
            id: oldIdToDuplicatedId.get(el.id),
            startDate: firebaseTimeToDate(data.startDate),
            endDate: firebaseTimeToDate(data.endDate),
          } as Task);

          task.setOrganizationId(this.id);

          // Firestoreにタスクのタグを保存
          if (Object.keys(task.tags)?.length) {
            await task.saveTags();
          }

          // Firestoreにチェックリストを保存
          const checklistsCollectionRef = collection(taskDocumentRef,
            FirestoreCollections.organizations.tasks.checklists.this);

          if (checklistsCollectionRef) {
            const checklistsSnapshot = await getDocs(checklistsCollectionRef);

            if (!checklistsSnapshot.empty) {
              checklistsSnapshot.forEach((checklistDoc) => {
                const data = checklistDoc.data() as ChecklistResponse;
                task.createChecklist({
                  id: nanoid(),
                  title: data.title,
                  url: data.url,
                  isChecked: false,
                  createdBy: data.createdBy,
                  createdAt: data.createdAt ? firebaseTimeToDate(data.createdAt) : new Date(),
                  checkedBy: null,
                  checkedAt: null,
                } as Checklist);
              });

              await task.saveChecklist();
            }
          }
        }
      }
    }

    // Firestoreにレイヤーを保存
    await destProject.insertLayers(sourceLayers);

    return { error: null };
  }

  @action
  public async getProjects(user: LoginUserModel, search?: ProjectSearchField): Promise<{ error: string | null }> {
    runInAction(() => {
      this.isProjectLoading = true;
    });

    let errorMessage: string | null = null;
    try {
      this.isProjectLoading = true;
      const docRef = this.getOrganizationRef();
      let projectRef: QueryType = query(collection(docRef, FirestoreCollections.organizations.projects.this));

      if (search) {
        if (search.name) {
          projectRef = query(projectRef,
            where(ProjectFields.name, '>=', search.name),
            where(ProjectFields.name, '<=', search.name + '\uf8ff'),
            orderBy(ProjectFields.name, "desc"));
        }

        if (search.startDate) {
          projectRef = query(projectRef,
            where(ProjectFields.startDate, '>=', search.startDate.from),
            where(ProjectFields.startDate, '>=', search.startDate.to),
            orderBy(ProjectFields.startDate, "desc"));
        }

        if (search.endDate) {
          projectRef = query(projectRef,
            where(ProjectFields.endDate, '>=', search.endDate.from),
            where(ProjectFields.endDate, '>=', search.endDate.to));
        }
      }

      const projectSnaps = await getDocs(
        query(projectRef, orderBy(ProjectFields.endDate, "desc"))
      );
      // if (projectSnaps.empty) {
      //   throw new Error(GetOrganizationProjectsRequestError.DocumentEmpty);
      // }

      const projects: ProjectModel[] = [];
      projectSnaps.forEach((project) => {
        const _project = project.data() as ProjectResponse;
        if (
          user.isOrganizationOwner() ||
          (_project.roles && _project.roles[user.id]) ||
          (_project.teams && Object.keys(_project.teams).some((key) =>
            this.teams.some((team) => key === team.id && team.userIds.includes(user.id))
          ))
        ) {
          const projectModel = new ProjectModel(_project);
          projectModel.setOrganizationId(this.id);
          projects.push(projectModel);
        }
      });

      runInAction(() => {
        this.projects = projects;
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      errorMessage = error.message || GetOrganizationProjectsRequestError.NetworkingError;
      // add Sentry

    } finally {
      runInAction(() => {
        this.isProjectLoading = false;
      });
      return { error: errorMessage };
    }
  }

  @action
  public async getOrganizationUsers(): Promise<{ error: string | null }> {

    const documentRef = this.getOrganizationRef();
    const organizationUserRef = collection(documentRef, FirestoreCollections.organizations.users);
    let errorMessage: string | null = null;
    try {
      const organizationUserSnap = await getDocs(organizationUserRef);
      if (organizationUserSnap.empty) {
        throw new Error(GetOrganizationUserRequestError.DocumentEmpty);
      }
      const users: OrganizationUserModel[] = [];
      organizationUserSnap.forEach((user) => {
        const userModel = new OrganizationUserModel(user.data() as OrganizationUserResponse);
        userModel.setOrganizationId(this.id);
        users.push(userModel);
      });
      runInAction(() => {
        this.users = users;
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      errorMessage = error.message || GetOrganizationUserRequestError.NetworkingError;
      // Sentry here

    } finally {
      return { error: errorMessage };
    }
  }

  @action
  public async getResources(): Promise<{ error: string | null }> {

    const documentRef = this.getOrganizationRef();
    const resourceRef = collection(documentRef, FirestoreCollections.organizations.resources);
    let errorMessage: string | null = null;
    try {
      const resourceSnap = await getDocs(resourceRef);
      const resources: ResourceModel[] = [];
      resourceSnap.forEach((resource) => {
        const resourceModel = new ResourceModel(resource.data() as ResourceResponse);
        resourceModel.setOrganizationId(this.id);
        resources.push(resourceModel);
      });
      runInAction(() => {
        this.resources = resources;
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      errorMessage = error.message || "";
      // Sentry here

    } finally {
      return { error: errorMessage };
    }
  }

  @action
  public async getTeams(): Promise<{ error: string | null }> {

    const documentRef = this.getOrganizationRef();
    const teamsRef = collection(documentRef, FirestoreCollections.organizations.teams);
    let errorMessage: string | null = null;
    try {
      const teamsSnap = await getDocs(teamsRef);
      const teams: TeamModel[] = [];
      teamsSnap.forEach((team) => {
        const teamModel = new TeamModel(team.data() as TeamResponse);
        teamModel.setOrganizationId(this.id);
        teams.push(teamModel);
      });
      runInAction(() => {
        this.teams = teams;
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      errorMessage = error.message || "";
      // Sentry here

    } finally {
      return { error: errorMessage };
    }
  }

  public async getProjectById(id: string): Promise<ProjectModel | undefined> {
    try {
      const docRef = this.getProjectRef();
      let projectRef = doc(docRef, id);

      const projectSnaps = await getDoc(projectRef);
      if (!projectSnaps.exists()) return;

      const _project = projectSnaps.data() as ProjectResponse;
      const projectModel = new ProjectModel(_project);
      projectModel.setOrganizationId(this.id);
      return projectModel;
    } catch (error) {
      console.log(error);
      // add Sentry
    }
  }

  /**
   * ダッシュボードに表示するためのリマインドタスクを取得する関数
   * @returns 
   */
  public async getRemindTasks(loginUser: LoginUserModel): Promise<TaskResponse[]> {
    runInAction(() => {
      this.isRemindTaskLoading = true;
    });

    const projectTaskRef = this.getProjectTasksRef();
    const joinedProjectIds = this.getActiveProjects()
      .map((project) => project.id);

    try {
      const data: TaskResponse[] = [];
      for (const chunkedProjectIds of _.chunk(joinedProjectIds, 20)) {
        const querySnapshot = await getDocs(
          query(projectTaskRef,
            where(TaskDocumentFields.projectId, "in", chunkedProjectIds),
            where(TaskDocumentFields.assignUserIds, "array-contains", loginUser.id),
            where(TaskDocumentFields.isClosed, "==", false),
            where(TaskDocumentFields.isDeleted, "==", false))
        );
        if (querySnapshot.empty) {
          throw new Error(GeneralDocumentQueryErrorType.DocumentEmpty);
        }
        querySnapshot.forEach((tasks) => {
          if (tasks.exists()) {
            const _data = tasks.data() as TaskResponse;
            data.push(_data);
          }
        });
      };

      return data.sort((a, b) => a.endDate.seconds - b.endDate.seconds).slice(0, 5);
    } catch (err) {
      console.log(err);
      // sentry here
      return [];
    } finally {
      runInAction(() => {
        this.isRemindTaskLoading = false;
      });
    }
  }

  /**
   * ダッシュボードに表示するための本日期限のタスク件数を取得する関数
   * @returns 
   */
  public async getDashboardProjects(): Promise<ProjectModel[]> {
    runInAction(() => {
      this.isDashboardProjectLoading = true;
    });

    const projectTaskRef = this.getProjectTasksRef();
    const projects = this.getActiveProjects()
      .map((project) => new ProjectModel(project))
      .sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds)
      .slice(0, 10);

    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0);
    const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 23, 59, 59);

    try {
      for (const project of projects) {
        let querySnapshot = await getCountFromServer(
          query(projectTaskRef,
            where(TaskDocumentFields.projectId, "==", project.id),
            where(TaskDocumentFields.endDate, '>=', dateToFirebaseTime(startOfToday)),
            where(TaskDocumentFields.endDate, '<=', dateToFirebaseTime(endOfToday)),
            where(TaskDocumentFields.isClosed, "==", false),
            where(TaskDocumentFields.isDeleted, "==", false),
          ));

        project.setTodayTaskCount(querySnapshot.data().count);

        querySnapshot = await getCountFromServer(
          query(projectTaskRef,
            where(TaskDocumentFields.projectId, "==", project.id),
            where(TaskDocumentFields.endDate, '<', dateToFirebaseTime(startOfToday)),
            where(TaskDocumentFields.isClosed, "==", false),
            where(TaskDocumentFields.isDeleted, "==", false),
          ));

        project.setOverdueTaskCount(querySnapshot.data().count);
      }

      return projects;
    } catch (err) {
      console.log(err);
      // sentry here
      return [];
    } finally {
      runInAction(() => {
        this.isDashboardProjectLoading = false;
      });
    }
  }

  /**
   * ダッシュボードに表示するための最新の更新を取得する関数
   * @returns 
   */
  public async getDashboardActivities(): Promise<OrganizationActivityResponse[]> {
    runInAction(() => {
      this.isDashboardActivityLoading = true;
    });

    const projectActivityRef = this.getProjectActivitiesRef();
    const joinedProjectIds = this.getActiveProjects()
      .map((project) => project.id);

    try {
      const data: OrganizationActivityResponse[] = [];
      for (const chunkedProjectIds of _.chunk(joinedProjectIds, 20)) {
        const querySnapshot = await getDocs(
          query(projectActivityRef,
            where(OrganizationActivityDocumentFields.projectId, "in", chunkedProjectIds))
        );
        if (querySnapshot.empty) {
          throw new Error(GeneralDocumentQueryErrorType.DocumentEmpty);
        }
        querySnapshot.forEach((activities) => {
          if (activities.exists()) {
            const _data = activities.data() as OrganizationActivityResponse;
            data.push(_data);
          }
        });
      };

      return data.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds).slice(0, 20);
    } catch (err) {
      console.log(err);
      // sentry here
      return [];
    } finally {
      runInAction(() => {
        this.isDashboardActivityLoading = false;
      });
    }
  }

  /**
   * 組織のアクティブなプロジェクト件数を取得する関数
   * @returns 
   */
  public async getActiveProjectCount(): Promise<number> {
    const projectRef = this.getProjectRef();

    try {
      const querySnapshot = await getCountFromServer(
        query(projectRef,
          where(ProjectFields.isArchived, "==", false),
        ));

      return querySnapshot.data().count;
    } catch (err) {
      console.log(err);
      // sentry here
      return 0;
    }
  }

  /**
   * ユーザーの一括招待処理を行う関数
   * CloudFunctionのcreate-organization-user/を呼び出す
   * @param invitees 
   * @returns 
   */
  public async inviteUsers(invitees: CreateOrganizationUsersRequest[]): Promise<{ error: string[] }> {
    runInAction(() => {
      this.isNetworkProcessing = true;
    });
    const params: AddOrganizationUserRequestParam = {
      organizationId: this.id,
      users: invitees,
    };
    let errorMessage: string[] = [];

    try {
      const request = httpsCallable(functions, FirebaseHttpsRequests.createOrganizationUsers);
      const result = await request(params);
      const data = result.data as AddOrganizationUserResponse;
      if (!data.failedEmails.length) return { error: errorMessage };
      data.failedEmails.forEach((failedEmail) => {
        // example.comの招待に失敗しました。もしくはexample.comは既に招待されています。
        const error = failedEmail.email + (AddOrganizationUsersRequestError[failedEmail.message as AddOrganizationUsersRequestErrorType] || AddOrganizationUsersRequestError.AddingOrganizationUserFailed)
        errorMessage.push(error);
      });
    } catch (err) {
      const error = err as { message?: string };

      const message = AddOrganizationUsersRequestError[error.message as AddOrganizationUsersRequestErrorType] || AddOrganizationUsersRequestError.General;
      errorMessage.push(message);
      // Sentry here

    } finally {
      runInAction(() => {
        this.isNetworkProcessing = false;
      });
      return { error: errorMessage };
    }
  }

  public findUserById(userId: string = ""): OrganizationUserModel | null {
    return this.getJoinedUsers().find((user) => user.id === userId) || null;
  }

  public findInvitedUserById(userId: string): OrganizationUserModel | null {
    return this.getInvitedUsers().find((user) => user.id === userId) || null;
  }

  public findTeamById(teamId: string = ""): TeamModel | null {
    return this.teams.find((team) => team.id === teamId) || null;
  }

  @action
  public subscribeOrganizationUsersCollection() {
    this.unsubscribeOrganizationUsersCollection();

    const documentRef = this.getOrganizationRef();
    const organizationUserCollectionRef = collection(documentRef, FirestoreCollections.organizations.users);
    this.organizationUserCollectionsListener = onSnapshot(organizationUserCollectionRef,
      (snapshot) => {
        if (!snapshot.empty) {
          const _organizationUsers: OrganizationUserModel[] = [];
          snapshot.forEach((user) => {
            const userModel = new OrganizationUserModel(user.data() as OrganizationUserResponse);
            userModel.setOrganizationId(this.id);
            _organizationUsers.push(userModel);
          });
          runInAction(() => {
            this.users = _organizationUsers;
          });
        }
      }, (error) => {
        console.log(error);
        // Sentry here
      });
  }

  public unsubscribeOrganizationUsersCollection() {
    if (this.organizationUserCollectionsListener) this.organizationUserCollectionsListener();
  }

  public getCurrentPlan() {
    return OrganizationPlanMap[this.plan as OrganizationPlan || OrganizationPlan.trial];
  }

  public getActiveProjects() {
    return this.projects.filter((project) => !project.isArchived);
  }

  public getJoinedUsers(): OrganizationUserModel[] {
    return this.users
      .filter((user) => user.isJoined())
      .sort((a, b) => a.username?.localeCompare(b.username, "ja"));
  }

  public getInvitedUsers(): OrganizationUserModel[] {
    return this.users
      .filter((user) => user.isInvited())
      .sort((a, b) => a.username?.localeCompare(b.username, "ja"));
  }

  public getJoinedAndInvitedUsers(): OrganizationUserModel[] {
    return this.users
      .filter((user) => user.isJoined() || user.isInvited())
      .sort((a, b) => a.username?.localeCompare(b.username, "ja"));
  }

  public getJoinedUserExceptMe(loginUser: LoginUserModel): OrganizationUserModel[] {
    return this.users
      .filter((user) => user.isJoined() && user.id !== loginUser.id)
      .sort((a, b) => a.username?.localeCompare(b.username, "ja"));
  }

  public getNonDeletedResources(): ResourceModel[] {
    return this.resources
      .filter((resource) => !resource.isDeleted)
      .sort((a, b) => a.index - b.index);
  }

  public getNonDeletedActiveResources(): ResourceModel[] {
    return this.resources
      .filter((resource) => !resource.isDeleted && resource.isActive)
      .sort((a, b) => a.index - b.index);
  }

  @action
  public subscribeResourcesCollection() {
    this.unsubscribeResourcesCollection();

    const documentRef = this.getOrganizationRef();
    const resourcesCollectionRef = collection(documentRef, FirestoreCollections.organizations.resources);
    this.resourcesCollectionsListener = onSnapshot(resourcesCollectionRef,
      (snapshot) => {
        if (!snapshot.empty) {
          const _resourcess: ResourceModel[] = [];
          snapshot.forEach((user) => {
            const resourceModel = new ResourceModel(user.data() as ResourceResponse);
            resourceModel.setOrganizationId(this.id);
            _resourcess.push(resourceModel);
          });
          runInAction(() => {
            this.resources = _resourcess;
          });
        }
      }, (error) => {
        console.log(error);
        // Sentry here
      });
  }

  public unsubscribeResourcesCollection() {
    if (this.resourcesCollectionsListener) this.resourcesCollectionsListener();
  }

  @action
  public subscribeTeamsCollection() {
    this.unsubscribeTeamsCollection();

    const documentRef = this.getOrganizationRef();
    const teamsCollectionRef = collection(documentRef, FirestoreCollections.organizations.teams);
    this.teamsCollectionsListener = onSnapshot(teamsCollectionRef,
      (snapshot) => {
        const _teams: TeamModel[] = [];
        if (!snapshot.empty) {
          snapshot.forEach((team) => {
            const teamModel = new TeamModel(team.data() as TeamResponse);
            teamModel.setOrganizationId(this.id);
            _teams.push(teamModel);
          });
        }

        runInAction(() => {
          this.teams = _teams;
        });
      }, (error) => {
        console.log(error);
        // Sentry here
      });
  }

  public unsubscribeTeamsCollection() {
    if (this.teamsCollectionsListener) this.teamsCollectionsListener();
  }

  /**
   * Roadmapの表示に必要なデータ（タスク、マイルストーン）の
   * リアルタイムアップデートを再現するため、プロジェクトデータを監視、
   * 変更があったプロジェクトのSceneVersionとストアに保存されているSceneVersionを比較し、
   * Sceneに更新があった場合Roadmapデータを取得するフラグを立て、後続のgetRoadmapDataを呼び出す処理
   */
  public async subscribeRoadmapData(
    user: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<void> {
    // 初回フェッチ
    if (this.isRoadmapFirstRender) {
      this.isRoadmapFirstRender = false;

      await this.getProjects(user);
      await this.getGanttChartData(Gantt.ChartType.Roadmap, organization);
      this.filteringProjectTasks();
    }

    // firestoreのデータに変更があった時に走る関数
    this.roadmapDataListener = autorun(async () => {
      if (this.isRoadmapDataReloadRequired) {
        await this.getGanttChartData(Gantt.ChartType.Roadmap, organization);
        this.filteringProjectTasks();

        runInAction(() => {
          this.isRoadmapDataReloadRequired = false;
        });
      }
    }, { name: "isRoadmapDataReloadRequired" });

    // 検索条件に変更があった時に走る関数
    this.roadmapFilterDataListener = autorun(async () => {
      if (this.hasRoadmapFilterChanged) {
        this.filteringProjectTasks();

        runInAction(() => {
          this.hasRoadmapFilterChanged = false;
        });
      }
    }, { name: "hasRoadmapFilterChanged" });

    this.subscribeProjectCollection(user, Gantt.ChartType.Roadmap);
  }

  public unsubscribeRoadmapData(): void {
    if (this.roadmapDataListener) this.roadmapDataListener();
    if (this.roadmapFilterDataListener) this.roadmapFilterDataListener();
    this.unsubscribeProjectCollection();
  }

  public removeRoadmapError() {
    if (!this.roadmapDataFetchError) return;

    runInAction(() => {
      this.roadmapDataFetchError = null;
    })
  }

  @action
  public setRoadmapFilter(update: Partial<RoadmapFilter>) {
    runInAction(() => {
      this.roadmapFilter = { ...this.roadmapFilter, ...update };
      this.hasRoadmapFilterChanged = true;
    });
  }

  /**
  * RoadmapのGanttチャートフィルタリングリセット
  */
  @action
  public resetRoadmapFilters() {
    runInAction(() => {
      this.roadmapFilter = {
        searchText: null,
        searchStatus: "open",
        searchAssignUser: null,
        searchAssignResource: null,
        searchProject: null,
        searchMilestone: null,
        showOnlyDelayedTasks: false,
      };
      this.hasRoadmapFilterChanged = true;
    });
  }

  /**
   * Resourcesの表示に必要なデータ（タスク、マイルストーン）の
   * リアルタイムアップデートを再現するため、プロジェクトデータを監視、
   * 変更があったプロジェクトのSceneVersionとストアに保存されているSceneVersionを比較し、
   * Sceneに更新があった場合Resourcesデータを取得するフラグを立て、後続のgetResourcesDataを呼び出す処理
   */
  public async subscribeResoucesData(
    user: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<void> {
    // 初回フェッチ
    if (this.isResourcesFirstRender) {
      this.isResourcesFirstRender = false;

      await this.getProjects(user);
      await this.getGanttChartData(Gantt.ChartType.Resources, organization);
      this.filteringUserTasks();
    }

    // firestoreのデータに変更があった時に走る関数
    this.resourcesDataListener = autorun(async () => {
      if (this.isResourcesDataReloadRequired) {
        await this.getGanttChartData(Gantt.ChartType.Resources, organization);
        this.filteringUserTasks();

        runInAction(() => {
          this.isResourcesDataReloadRequired = false;
        });
      }
    }, { name: "isResourcesDataReloadRequired" });

    // 検索条件に変更があった時に走る関数
    this.resourcesFilterDataListener = autorun(async () => {
      if (this.hasResourcesFilterChanged) {
        this.filteringUserTasks();

        runInAction(() => {
          this.hasResourcesFilterChanged = false;
        });
      }
    }, { name: "hasResourcesFilterChanged" });

    this.subscribeProjectCollection(user, Gantt.ChartType.Resources);
  }

  public unsubscribeResoucesData(): void {
    if (this.resourcesDataListener) this.resourcesDataListener();
    if (this.resourcesFilterDataListener) this.resourcesFilterDataListener();
    this.unsubscribeProjectCollection();
  }

  public removeResourcesError() {
    if (!this.resourcesDataFetchError) return;

    runInAction(() => {
      this.resourcesDataFetchError = null;
    })
  }

  @action
  public setResourcesFilter(update: Partial<ResourcesFilter>) {
    runInAction(() => {
      this.resourcesFilter = { ...this.resourcesFilter, ...update };
      this.hasResourcesFilterChanged = true;
    });
  }

  /**
  * ResoucesのGanttチャートフィルタリングリセット
  */
  @action
  public resetResourcesFilters() {
    runInAction(() => {
      this.resourcesFilter = {
        searchText: null,
        searchAssignUser: null,
        searchAssignResource: null,
        searchProject: null,
        searchMilestone: null,
        showOnlyTaskExists: true,
        showOnlyOverlapTasks: false,
        showOnlyBlankTasks: false,
      };
      this.hasResourcesFilterChanged = true;
    });
  }

  /**
  * Ganttチャートの行の展開、折りたたみ
  */
  @action
  public setRowCollapse(record: Gantt.Record<DefaultRecordType>, collapsed: boolean) {
    this.projectTasks.forEach((item) => {
      if (item.id === record.id) {
        item.collapsed = collapsed;
      }

      item.children?.forEach((child) => {
        if (child.id === record.id) {
          child.collapsed = collapsed;
        }
      });
    });
  }

  /**
  * Ganttチャートの全ての行の展開、折りたたみ
  */
  @action
  public setAllRowsCollapse(collapsed: boolean) {
    const items = this.projectTasks.map((item) => (
      {
        ...item,
        collapsed: collapsed,
        children: item.children?.map((child) => (
          {
            ...child,
            collapsed: collapsed,
          }
        )),
      }
    ));

    this.projectTasks = items;
  }

  /**
   * Excalidrawでのtaskエレメント編集時に選択されたタスクのモデルを返却する関数
   * 条件によって返却するTaskのモデルを変更する
   * Storeに存在する場合：Storeからタスクのモデルを返す
   * Storeに存在しない場合且つfirestoreにデータが存在する場合：firestoreからタスクを取得し、モデル化して返す
   * Storeに存在しない且つfirestoreにもデータがない場合（Excalidrawで新規作成の場合）：引数を元にtaskのモデルを作成して返す
   * 
   */
  public async getSelectedTaskOnCanvas(
    taskElement: ExcalidrawTaskElement,
    projectId: string,
    text?: string | null
  ): Promise<TaskModel> {
    const taskModel = this.tasks.find((task) => task.id === taskElement.id);
    if (taskModel) {
      const task: Task = { ...taskModel.getFields(), ...taskElement, text: text || "" }
      return new TaskModel(task);
    };

    const taskCollectionRef = this.getProjectTasksRef();
    const taskDocumentRef = doc(taskCollectionRef, taskElement.id);
    const taskDocumentSnap = await getDoc(taskDocumentRef);
    if (taskDocumentSnap.exists()) {
      const data = taskDocumentSnap.data() as TaskResponse;
      return new TaskModel({
        ...data,
        ...taskElement,
        text: text || "",
      } as Task);
    }

    const task: Task = { ...taskElement, projectId, text: text || "" };
    const newTask = new TaskModel(task);

    newTask.save();

    return newTask;
  }

  @action
  public updateTask(task: TaskModel) {
    this.tasks.forEach((t) => {
      if (t.id === task.id) {
        t.setState(task.getFields());
      }
    });
  }


  public getTaskById(taskId: string): TaskModel | undefined {
    return this.tasks.find((task) => task.id === taskId);
  }

  public replaceTaskModel(task: TaskModel) {
    this.tasks = this.tasks.map((t) => {
      if (t.id === task.id) {
        return task;
      }
      return t;
    });
  }

  /**
   * 以下の際にロードマップを強制リロードする：
   * - Taskのチェックリストをロードマップページから更新した際
   */
  @action
  public forceReloadRoadmapData(organization: OrganizationModel) {
    this.processProjectTasks(
      this.tasks.map((task) => task.getGanttChartTaskFields()),
      this.milestones.map((milestone) => milestone.getGanttChartMilestoneFields()),
      organization,
    );
    this.filteringProjectTasks();
  }

  /**
   * 組織が管理している全てのプロジェクトのコメントを取得する関数
   */
  public async getCommentsAcrossProjects(): Promise<InternalError> {

    const commentRef = this.getCommentCollectionRef();
    try {
      const commentsQuery = query(commentRef,
        where(CommentDocumentFields.isDeleted, "==", false),
        orderBy(CommentDocumentFields.createdAt, "desc")
      );
      const commentSnaps = await getDocs(commentsQuery);

      const comments: CommentModel[] = [];
      commentSnaps.forEach((doc) => {
        if (doc.exists()) {
          const c = doc.data() as CommentResponse;
          const comment = new CommentModel({
            ...c,
            createdAt: firebaseTimeToDate(c.createdAt),
            updatedAt: c.updatedAt ? firebaseTimeToDate(c.updatedAt) : null,
            uploadedFiles: c.uploadedFiles.map((file) => {
              return {
                ...file,
                hasUploaded: true,
              } as UploadedFile;
            })
          } as Comment);
          const user = this.findUserById(c.createdBy);
          comment.setOrganizationId(this.id);
          if (user) comment.setUserInfo(user.username, user.profileImageUrl || "");
          comments.push(comment);
        }
      });

      runInAction(() => {
        this.comments = comments;
      });

      return {}
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      // Sentry here
      return { error: error.message };
    }
  }

  /**
   * 以下の際にリソースを強制リロードする：
   * - Taskの担当者、関連リソースをリソースページから更新した際
   */
  @action
  public forceReloadResourcesData() {
    this.processUserTasks(
      this.tasks.map((task) => task.getGanttChartTaskFields()),
    );
    this.filteringUserTasks();
  }

  // private

  private subscribeProjectCollection(user: LoginUserModel, chartType: Gantt.ChartType): void {
    this.unsubscribeProjectCollection();

    const projectCollectionRef = this.getProjectRef();
    this.projectCollectionListener = onSnapshot(projectCollectionRef,
      (snapshot) => {
        const projects: ProjectModel[] = this.projects;
        let _isReloadRequired: boolean = false;
        if (!snapshot.empty) {
          snapshot.docChanges().forEach((change) => {
            const updatedProject = change.doc.data() as ProjectResponse;
            const index = projects.findIndex((project) => project.id === updatedProject.id);
            if (index !== -1) {
              if (change.type === "modified") {
                if (projects[index].sceneVersion || 0 < updatedProject.sceneVersion) {
                  projects[index].updateProjectSceneVersion(updatedProject.sceneVersion);
                  _isReloadRequired = true;
                }
              } else if (change.type === "removed") {
                projects.splice(index, 1);
                _isReloadRequired = true;
              }
            } else {
              if (change.type === "added") {
                if (
                  user.isOrganizationOwner() ||
                  Object.keys(updatedProject.roles || {}).find((key) => key === user.id)
                ) {
                  projects.push(new ProjectModel(updatedProject));
                  _isReloadRequired = true;
                }
              }
            }
          });
        };

        if (chartType === Gantt.ChartType.Roadmap) {
          runInAction(() => {
            this.projects = projects;
            this.isRoadmapDataReloadRequired = _isReloadRequired;
          });
        } else if (chartType === Gantt.ChartType.Resources) {
          runInAction(() => {
            this.projects = projects;
            this.isResourcesDataReloadRequired = _isReloadRequired;
          });
        }
      }, (error) => {
        console.log(error);
        // Sentry here
        if (chartType === Gantt.ChartType.Roadmap) {
          runInAction(() => {
            this.roadmapDataFetchError = GetRoadmapDataRequestError[GeneralDocumentQueryErrorType.NetworkingError];
          });
        } else if (chartType === Gantt.ChartType.Resources) {
          runInAction(() => {
            this.resourcesDataFetchError = GetResourcesDataRequestError[GeneralDocumentQueryErrorType.NetworkingError];
          });
        }
      });
  }

  private unsubscribeProjectCollection() {
    if (this.projectCollectionListener) this.projectCollectionListener();
  }

  /**
   * ガントチャートの表示に必要なデータ（タスク、マイルストーン）を取得し、
   * 前処理後、Stateに格納する処理を行う。
   * マイルストーンとタスクのデータに関連があるので、
   * データ取得の際にどちらかコケた場合はtry catch blockで全ての処理を中断し、エラーを返す処理
   */
  private async getGanttChartData(
    chartType: Gantt.ChartType,
    organization: OrganizationModel,
  ): Promise<void> {
    try {
      const [tasks, milestones] = await Promise.all([
        this.getProjectTasks(chartType),
        this.getProjectMilestones()
      ]);

      runInAction(() => {
        this.tasks = tasks;
        this.milestones = milestones;
        this.isProjectTaskLoading = false;
      });

      if (chartType === Gantt.ChartType.Roadmap) {
        this.processProjectTasks(
          tasks.map((task) => task.getGanttChartTaskFields()),
          milestones.map((milestone) => milestone.getGanttChartMilestoneFields()),
          organization,
        );
      } else if (chartType === Gantt.ChartType.Resources) {
        this.processUserTasks(
          tasks.map((task) => task.getGanttChartTaskFields()),
        );
      }
    } catch (err) {
      console.log(err);
      // sentry here
      const error = err as { message?: string, code?: number };

      if (chartType === Gantt.ChartType.Roadmap) {
        const message = GetRoadmapDataRequestError[error.message as GeneralDocumentQueryErrorType] || GetRoadmapDataRequestError[GeneralDocumentQueryErrorType.NetworkingError];

        runInAction(() => {
          this.roadmapDataFetchError = message;
        });
      } else if (chartType === Gantt.ChartType.Resources) {
        const message = GetResourcesDataRequestError[error.message as GeneralDocumentQueryErrorType] || GetRoadmapDataRequestError[GeneralDocumentQueryErrorType.NetworkingError];

        runInAction(() => {
          this.resourcesDataFetchError = message;
        });
      }
    }
  }

  /**
   * ユーザーが所属するプロジェクトに関するタスクを取得する関数
   * @returns 
   */
  private async getProjectTasks(chartType: Gantt.ChartType): Promise<TaskModel[]> {

    const projectTaskRef = this.getProjectTasksRef();
    const joinedProjectIds = this.getActiveProjects()
      .map((project) => project.id);

    const data: TaskModel[] = [];
    for (const chunkedProjectIds of _.chunk(joinedProjectIds, 20)) {
      const querySnapshot = await getDocs(
        chartType === Gantt.ChartType.Resources
          ? query(projectTaskRef,
            where(MilestoneDocumentFields.projectId, "in", chunkedProjectIds),
            where(MilestoneDocumentFields.isDeleted, "==", false),
            where(MilestoneDocumentFields.isClosed, "==", false))
          : query(projectTaskRef,
            where(MilestoneDocumentFields.projectId, "in", chunkedProjectIds),
            where(MilestoneDocumentFields.isDeleted, "==", false))
      );
      if (querySnapshot.empty) {
        throw new Error(GeneralDocumentQueryErrorType.DocumentEmpty);
      }
      querySnapshot.forEach((tasks) => {
        if (tasks.exists()) {
          const _data = tasks.data() as TaskResponse;
          const endDate = firebaseTimeToDate(_data.endDate);
          endDate.setDate(endDate.getDate() - 1);

          const taskModel = new TaskModel({
            ..._data,
            startDate: firebaseTimeToDate(_data.startDate),
            endDate,
          } as Task);
          taskModel.setOrganizationId(this.id);
          data.push(taskModel);
        }
      });
    };

    return data;
  }

  /**
   * 
   * ユーザーが所属するプロジェクトに関するマイルストーンを取得する関数
   * @returns 
   */
  @action
  private async getProjectMilestones(): Promise<MilestoneModel[]> {

    const groupRef = this.getProjectMilestonesRef();
    const joinedProjectIds = this.getActiveProjects()
      .map((project) => project.id);

    const data: MilestoneModel[] = [];
    for (const chunkedProjectIds of _.chunk(joinedProjectIds, 20)) {
      const querySnapshot = await getDocs(
        query(groupRef,
          where(TaskDocumentFields.projectId, "in", chunkedProjectIds),
          where(TaskDocumentFields.isClosed, "==", false),
          where(TaskDocumentFields.isDeleted, "==", false))
      );

      querySnapshot.forEach((milestones) => {
        if (milestones.exists()) {
          const _data = milestones.data() as MilestoneResponse;
          const date = firebaseTimeToDate(_data.date);
          date.setDate(date.getDate() - 1);
          const milestoneModel = new MilestoneModel({
            ..._data,
            date,
          } as Milestone);
          milestoneModel.setOrganizationId(this.id);
          data.push(milestoneModel);
        }
      });
    };
 
    return data;
  }

  /**
   * Firestoreから取得したTaskとMilestoneのデータを、
   * Projectに含まれる依存関係のタスクと
   * 非依存関係のタスクとしてリストにまとめ、
   * Ganttチャートに表示するプロパティを生成する関数
   * 
   * @param tasks
   * @param milestones
   */
  @action
  private processProjectTasks(
    tasks: GanttChartTask[],
    milestones: GanttChartMilestone[],
    organization: OrganizationModel,
  ) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const dependencies: Gantt.Dependence[] = [];
    const items: Gantt.Record[] = this.getActiveProjects()
      .sort((a, b) => a.startDate.seconds - b.startDate.seconds)
      .map((project) => {
        const milestonesInProject = milestones.filter((milestone) => milestone.projectId === project.id);
        const taskInProject = tasks.filter((task) => task.projectId === project.id);

        const roadmap = new Roadmap(taskInProject);

        const dependentChildren: Gantt.Record[] = [];
        const independentChildren: Gantt.Record[] = [];
        const independentTasks: Gantt.Record[] = [];

        // マイルストーンに紐づくグループ
        milestonesInProject.forEach((milestone) => {
          const milestoneGroup = roadmap.getDependentMilestoneGroup(milestone);
          if (milestoneGroup.length === 0) {
            return;
          }

          const taskMaps = new Map<string, GanttChartTask>();
          const taskList = milestoneGroup.sort((a, b) => {
            // 開始日が早い順に比較
            if (a.startDate < b.startDate) {
              return -1;
            } else if (a.startDate > b.startDate) {
              return 1;
            }
            // 開始日が同じ場合、終了日が早い順に比較
            if (a.endDate < b.endDate) {
              return -1;
            } else if (a.endDate > b.endDate) {
              return 1;
            }
            return 0; // 開始日と終了日が同じ場合は順序を変更しない
          });
          taskList.forEach((task) => taskMaps.set(task.id, task));

          taskList.forEach((task) => {
            task.nextDependencies?.forEach((dependence) => {
              if (taskMaps.has(dependence)) {
                dependencies.push({
                  from: `${task.id},${milestone.id}`,
                  to: `${dependence},${milestone.id}`,
                  type: 'finish_start',
                });
              }

              if (dependence === milestone.id) {
                const diff = (milestone.date.getTime() - task.endDate.getTime()) / (1000 * 3600 * 24);
                const duration = task.freeFloats?.find((float) => float.id === milestone.id)?.duration || diff;
                const color = duration <= (organization.floatAlarmLimit || FloatLimit.alarm)
                  ? "#DF4646"
                  : duration <= (organization.floatWarningLimit || FloatLimit.warning)
                    ? "#F49C45"
                    : "#707070";

                dependencies.push({
                  from: `${task.id},${milestone.id}`,
                  to: dependence,
                  type: 'float',
                  color,
                  duration,
                });
              }
            });
          });

          // task -> gantt chart items
          const children: Gantt.Record[] = taskList
            .map((task) => {
              const id = `${task.id},${milestone.id}`
              return {
                id,
                name: task.text,
                groupId: milestone.id,
                startDate: task.startDate,
                endDate: task.endDate,
                collapsed: false,
                visible: true,
                barType: Gantt.BarType.Task,
                isTask: true,
                ratio: task.ratio, //タスク完了率
                duration: task.duration,
                isClosed: task.isClosed,
                isDelayed: !task.isClosed && currentDate.getTime() > task.endDate.getTime(),
              } as Gantt.Record;
            });

          // milestone -> gantt chart items
          const firstTask = taskList[0];
          const startDate = firstTask.startDate;
          const endDate = milestone.date;
          const duration = this.getDuration(startDate, endDate, project.holidays);
          const completedTaskCountInMilestone = taskList.filter((task) => task.isClosed).length;
          const taskCountInMilestone = taskList.length;
          const countRatio = 100 * completedTaskCountInMilestone / taskCountInMilestone;
          const completedDaysInMilestone = taskList
            .filter((task) => task.isClosed)
            .reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
          const daysInMilestone = taskList.reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
          const daysRatio = 100 * completedDaysInMilestone / daysInMilestone;

          const record = {
            id: milestone.id,
            name: milestone.text || "マイルストーン",
            startDate, // マイルストーンは期間ではないので一番最初のタスクの開始日を期間の初めとする
            endDate,
            collapsed: false,
            visible: true,
            children: children,
            barType: Gantt.BarType.Milestone,
            isMilestone: true,
            completedTaskCount: completedTaskCountInMilestone,
            totalTaskCount: taskCountInMilestone,
            countRatio,
            completedDays: completedDaysInMilestone,
            totalDays: daysInMilestone,
            daysRatio,
            duration,
            // isDelayed: children.some((child) => child.isDelayed),
          } as Gantt.Record;

          dependentChildren.push(record);
        });

        // マイルストーンに紐づかないグループ
        const dependentGroups = roadmap.getDependentGroups();
        dependentGroups.forEach((group, index) => {
          const taskMaps = new Map<string, GanttChartTask>();
          const taskList = group.sort((a, b) => {
            // 開始日が早い順に比較
            if (a.startDate < b.startDate) {
              return -1;
            } else if (a.startDate > b.startDate) {
              return 1;
            }
            // 開始日が同じ場合、終了日が早い順に比較
            if (a.endDate < b.endDate) {
              return -1;
            } else if (a.endDate > b.endDate) {
              return 1;
            }
            return 0; // 開始日と終了日が同じ場合は順序を変更しない
          });
          taskList.forEach((task) => taskMaps.set(task.id, task));

          taskList.forEach((task) => {
            task.nextDependencies?.forEach((dependence) => {
              if (taskMaps.has(dependence)) {
                dependencies.push({
                  from: task.id,
                  to: dependence,
                  type: 'finish_start',
                });
              }
            });
          });

          if (taskList.length > 1) {
            // task -> gantt chart items
            const children: Gantt.Record[] = taskList
              .map((task) => {
                return {
                  id: task.id,
                  name: task.text,
                  groupId: index,
                  startDate: task.startDate,
                  endDate: task.endDate,
                  collapsed: false,
                  visible: true,
                  barType: Gantt.BarType.Task,
                  isTask: true,
                  ratio: task.ratio, //タスク完了率
                  duration: task.duration,
                  isClosed: task.isClosed,
                  isDelayed: !task.isClosed && currentDate.getTime() > task.endDate.getTime(),
                } as Gantt.Record;
              });

            // milestone -> gantt chart items
            const firstTask = taskList[0];
            const lastTask = taskList.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

            const startDate = firstTask.startDate;
            const endDate = lastTask.endDate;
            const duration = this.getDuration(startDate, endDate, project.holidays);
            const completedTaskCountInMilestone = taskList.filter((task) => task.isClosed).length;
            const taskCountInMilestone = taskList.length;
            const countRatio = 100 * completedTaskCountInMilestone / taskCountInMilestone;
            const completedDaysInMilestone = taskList
              .filter((task) => task.isClosed)
              .reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
            const daysInMilestone = taskList.reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
            const daysRatio = 100 * completedDaysInMilestone / daysInMilestone;

            const record = {
              id: `parent-${children[0].id}`,
              name: "依存関係グループ",
              startDate,
              endDate,
              collapsed: false,
              visible: true,
              children: children,
              barType: Gantt.BarType.Milestone,
              completedTaskCount: completedTaskCountInMilestone,
              totalTaskCount: taskCountInMilestone,
              countRatio,
              completedDays: completedDaysInMilestone,
              totalDays: daysInMilestone,
              daysRatio,
              duration,
              // isDelayed: children.some((child) => child.isDelayed),
            } as Gantt.Record;

            dependentChildren.push(record);
          } else {
            const record = {
              id: taskList[0].id,
              name: taskList[0].text,
              groupId: -1,
              startDate: taskList[0].startDate,
              endDate: taskList[0].endDate,
              collapsed: false,
              visible: true,
              barType: Gantt.BarType.Task,
              isTask: true,
              ratio: taskList[0].ratio, //タスク完了率
              duration: taskList[0].duration,
              isClosed: taskList[0].isClosed,
              isDelayed: !taskList[0].isClosed && currentDate.getTime() > taskList[0].endDate.getTime(),
            } as Gantt.Record;

            independentTasks.push(record);
          }
        });

        if (independentTasks.length > 0) {
          const startDate = independentTasks[0].startDate;
          const endDate = independentTasks.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0].endDate;
          const duration = this.getDuration(startDate, endDate, project.holidays);
          const completedTaskCountInMilestone = independentTasks.filter((task) => task.isClosed).length;
          const taskCountInMilestone = independentTasks.length;
          const countRatio = 100 * completedTaskCountInMilestone / taskCountInMilestone;
          const completedDaysInMilestone = independentTasks
            .filter((task) => task.isClosed)
            .reduce((previousValue, currentValue) => previousValue + (currentValue.duration || 0), 0);
          const daysInMilestone = independentTasks.reduce((previousValue, currentValue) => previousValue + (currentValue.duration || 0), 0);
          const daysRatio = 100 * completedDaysInMilestone / daysInMilestone;

          const record = {
            id: `parent-${independentTasks[0].id}`,
            name: "非依存関係グループ",
            startDate,
            endDate,
            collapsed: false,
            visible: true,
            children: independentTasks,
            barType: Gantt.BarType.Milestone,
            completedTaskCount: completedTaskCountInMilestone,
            totalTaskCount: taskCountInMilestone,
            countRatio,
            completedDays: completedDaysInMilestone,
            totalDays: daysInMilestone,
            daysRatio,
            duration,
            // isDelayed: independentTasks.some((child) => child.isDelayed),
          } as Gantt.Record;

          independentChildren.push(record);
        }

        dependentChildren.sort((a, b) => {
          if (a.isMilestone && !b.isMilestone) {
            return -1;
          } else if (!a.isMilestone && b.isMilestone) {
            return 1;
          }
          // 開始日が早い順に比較
          if (a.startDate < b.startDate) {
            return -1;
          } else if (a.startDate > b.startDate) {
            return 1;
          }
          // 開始日が同じ場合、終了日が早い順に比較
          if (a.endDate < b.endDate) {
            return -1;
          } else if (a.endDate > b.endDate) {
            return 1;
          }
          return 0; // 開始日と終了日が同じ場合は順序を変更しない
        });

        const startDate = firebaseTimeToDate(project.startDate);
        const endDate = firebaseTimeToDate(project.endDate);
        const duration = this.getDuration(startDate, endDate, project.holidays);
        const completedTaskCountInProject = taskInProject.filter((task) => task.isClosed).length;
        const taskCountInProject = taskInProject.length;
        const countRatio = 100 * completedTaskCountInProject / taskCountInProject;
        const completedDaysInProject = taskInProject
          .filter((task) => task.isClosed)
          .reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
        const daysInProject = taskInProject.reduce((previousValue, currentValue) => previousValue + currentValue.duration, 0);
        const daysRatio = 100 * completedDaysInProject / daysInProject;
        const children = [...dependentChildren, ...independentChildren];

        // project -> gantt chart items
        return {
          id: project.id,
          name: project.name,
          startDate,
          endDate,
          collapsed: false,
          visible: true,
          children,
          barType: Gantt.BarType.Project,
          completedTaskCount: completedTaskCountInProject,
          totalTaskCount: taskCountInProject,
          countRatio,
          completedDays: completedDaysInProject,
          totalDays: daysInProject,
          daysRatio,
          duration,
          // isDelayed: children.some((child) => child.isDelayed),
        } as Gantt.Record;
      });
    runInAction(() => {
      this.projectTasks = items;
      this.projectDependencies = dependencies;
    })
  }

  /**
   * Firestoreから取得したTaskとMilestoneのデータを、
   * アサインされているユーザーでリスト化し、
   * Ganttチャートに表示するプロパティを生成する関数
   * 
   * @param tasks
   * @param milestones
   */
  @action
  private processUserTasks(tasks: GanttChartTask[]) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const projectMaps = new Map<string, ProjectModel>();
    this.getActiveProjects()
      .forEach((project) => projectMaps.set(project.id, project));

    const dependencies: Gantt.Dependence[] = [];
    const items: Gantt.Record[] = [];

    // ユーザーでグループ化
    this.getJoinedUsers().forEach((user, index) => {
      const taskInUser = tasks.filter((task) => task.assignUserIds?.includes(user.id));

      if (taskInUser.length > 0) {
        const taskList = taskInUser
          .sort((a, b) => {
            if (a.isClosed && !b.isClosed) {
              return -1;
            } else if (!a.isClosed && b.isClosed) {
              return 1;
            }
            // 開始日が早い順に比較
            if (a.startDate < b.startDate) {
              return -1;
            } else if (a.startDate > b.startDate) {
              return 1;
            }
            // 開始日が同じ場合、終了日が早い順に比較
            if (a.endDate < b.endDate) {
              return -1;
            } else if (a.endDate > b.endDate) {
              return 1;
            }
            return 0; // 開始日と終了日が同じ場合は順序を変更しない
          });

        const openTaskList = taskList.filter((task) => !task.isClosed);
        if (openTaskList.length) {
          openTaskList.reduce((prev, current) => {
            if (prev) {
              const prevEndDate = new Date(prev.endDate);
              prevEndDate.setDate(prevEndDate.getDate() + 1);

              if (prevEndDate.getTime() > current.startDate.getTime()) {
                dependencies.push({
                  from: `${prev.id},${user.id}`,
                  to: `${current.id},${user.id}`,
                  type: 'overlap',
                  color: "#DF4646",
                });
              } else if (prevEndDate.getTime() < current.startDate.getTime()) {
                dependencies.push({
                  from: `${prev.id},${user.id}`,
                  to: `${current.id},${user.id}`,
                  type: 'gap',
                  color: "#46AADF",
                });
              }
            }
            return current;
          });
        }

        // task -> gantt chart items
        const children: Gantt.Record[] = taskList
          .map((task) => {
            const id = `${task.id},${user.id}`;
            const project = projectMaps.get(task.projectId);
            return {
              id,
              name: task.text,
              groupId: index,
              startDate: task.startDate,
              endDate: task.endDate,
              collapsed: false,
              visible: true,
              barType: Gantt.BarType.Task,
              isTask: true,
              ratio: task.ratio, //タスク完了率
              duration: task.duration,
              isClosed: task.isClosed,
              isOverlap: dependencies.some((v) => v.type === "overlap" && (v.to === id || v.from === id)),
              isGap: dependencies.some((v) => v.type === "gap" && (v.to === id || v.from === id)),
              projectId: task.projectId,
              projectName: project?.name,
              projectColor: project?.color,
            } as Gantt.Record;
          });

        // user -> gantt chart items
        const firstTask = taskList[0];
        const lastTask = taskList.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

        const startDate = firstTask.startDate;
        const endDate = lastTask.endDate;

        items.push({
          id: user.id,
          name: user.username || "ユーザー",
          startDate,
          endDate,
          collapsed: false,
          visible: true,
          children: children,
          barType: Gantt.BarType.User,
        } as Gantt.Record);
      } else {
        items.push({
          id: user.id,
          name: user.username || "ユーザー",
          startDate: new Date(),
          endDate: new Date(),
          collapsed: false,
          visible: true,
          children: [],
          barType: Gantt.BarType.User,
        } as Gantt.Record);     
      }
    });

    // リソースでグループ化
    this.getNonDeletedActiveResources().forEach((resource, index) => {
      const taskInResource = tasks.filter((task) => task.assignResourceIds?.includes(resource.id));

      if (taskInResource.length > 0) {
        const taskList = taskInResource.sort((a, b) => {
          if (a.isClosed && !b.isClosed) {
            return -1;
          } else if (!a.isClosed && b.isClosed) {
            return 1;
          }
          // 開始日が早い順に比較
          if (a.startDate < b.startDate) {
            return -1;
          } else if (a.startDate > b.startDate) {
            return 1;
          }
          // 開始日が同じ場合、終了日が早い順に比較
          if (a.endDate < b.endDate) {
            return -1;
          } else if (a.endDate > b.endDate) {
            return 1;
          }
          return 0; // 開始日と終了日が同じ場合は順序を変更しない
        });

        const openTaskList = taskList.filter((task) => !task.isClosed);
        if (openTaskList.length) {
          openTaskList.reduce((prev, current) => {
            if (prev) {
              const prevEndDate = new Date(prev.endDate);
              prevEndDate.setDate(prevEndDate.getDate() + 1);

              if (prevEndDate.getTime() > current.startDate.getTime()) {
                dependencies.push({
                  from: `${prev.id},${resource.id}`,
                  to: `${current.id},${resource.id}`,
                  type: 'overlap',
                  color: "#DF4646",
                });
              } else if (prevEndDate.getTime() < current.startDate.getTime()) {
                dependencies.push({
                  from: `${prev.id},${resource.id}`,
                  to: `${current.id},${resource.id}`,
                  type: 'gap',
                  color: "#46AADF",
                });
              }
            }
            return current;
          });
        }

        // task -> gantt chart items
        const children: Gantt.Record[] = taskList
          .map((task) => {
            const id = `${task.id},${resource.id}`;
            const project = projectMaps.get(task.projectId);
            return {
              id,
              name: task.text,
              groupId: index,
              startDate: task.startDate,
              endDate: task.endDate,
              collapsed: false,
              visible: true,
              barType: Gantt.BarType.Task,
              isTask: true,
              ratio: task.ratio, //タスク完了率
              duration: task.duration,
              isClosed: task.isClosed,
              isOverlap: dependencies.some((v) => v.type === "overlap" && (v.to === id || v.from === id)),
              isGap: dependencies.some((v) => v.type === "gap" && (v.to === id || v.from === id)),
              projectId: task.projectId,
              projectName: project?.name,
              projectColor: project?.color,
            } as Gantt.Record;
          });

        // resource -> gantt chart items
        const firstTask = taskList[0];
        const lastTask = taskList.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

        const startDate = firstTask.startDate;
        const endDate = lastTask.endDate;

        items.push({
          id: resource.id,
          name: resource.name || "リソース",
          startDate,
          endDate,
          collapsed: false,
          visible: true,
          children: children,
          barType: Gantt.BarType.Resource,
        } as Gantt.Record);
      } else {
        items.push({
          id: resource.id,
          name: resource.name || "リソース",
          startDate: new Date(),
          endDate: new Date(),
          collapsed: false,
          visible: true,
          children: [],
          barType: Gantt.BarType.Resource,
        } as Gantt.Record);
      }
    });

    // アサインされていないタスク
    const unassignedTasks = tasks.filter((task) =>
      task.assignUserIds?.length === 0 &&
      task.assignResourceIds?.length === 0
    );

    if (unassignedTasks.length > 0) {
      const taskList = unassignedTasks.sort((a, b) => {
        if (a.isClosed && !b.isClosed) {
          return -1;
        } else if (!a.isClosed && b.isClosed) {
          return 1;
        }
        // 開始日が早い順に比較
        if (a.startDate < b.startDate) {
          return -1;
        } else if (a.startDate > b.startDate) {
          return 1;
        }
        // 開始日が同じ場合、終了日が早い順に比較
        if (a.endDate < b.endDate) {
          return -1;
        } else if (a.endDate > b.endDate) {
          return 1;
        }
        return 0; // 開始日と終了日が同じ場合は順序を変更しない
      });

      // task -> gantt chart items
      const children: Gantt.Record[] = taskList
        .map((task) => {
          return {
            id: task.id,
            name: task.text,
            groupId: -1,
            startDate: task.startDate,
            endDate: task.endDate,
            collapsed: false,
            visible: true,
            barType: Gantt.BarType.Task,
            isTask: true,
            ratio: task.ratio, //タスク完了率
            duration: task.duration,
            isClosed: task.isClosed,
            projectId: task.projectId,
            projectName: projectMaps.get(task.projectId)?.name,
            projectColor: projectMaps.get(task.projectId)?.color,
          } as Gantt.Record;
        });

      // user -> gantt chart items
      const firstTask = taskList[0];
      const lastTask = taskList.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

      const startDate = firstTask.startDate;
      const endDate = lastTask.endDate;

      items.push({
        id: "unassigned",
        name: "非関連タスク",
        startDate,
        endDate,
        collapsed: false,
        visible: true,
        children: children,
        barType: Gantt.BarType.Nothing,
      } as Gantt.Record);
    }

    runInAction(() => {
      this.projectTasks = items;
      this.projectDependencies = dependencies;
    });
  }

  /**
   * Ganttチャートのフィルタ関数
   */
  @action
  private filteringProjectTasks() {
    const {
      searchText,
      searchAssignUser,
      searchAssignResource,
      searchProject,
      searchMilestone,
      searchStatus,
      showOnlyDelayedTasks,
    } = this.roadmapFilter; //roadmap filtering conditions
    const tasks = this.tasks;
    const milestones = this.milestones;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const filteredMilestoneIds: Set<string> = new Set(
      milestones
        .filter((milestone) => searchMilestone ? milestone.id === searchMilestone : false)
        .map((milestone) => milestone.id)
    );

    const filteredTaskIds: Set<string> = new Set(
      tasks
        .filter((task) => searchStatus
          ? searchStatus === "open"
            ? task.isClosed === false
            : task.isClosed === true
          : true)
        .filter((task) => searchText
          ? (
            task.text.toLowerCase().includes(searchText.toLowerCase()) ||
            Object.keys(task.tags).some((value => value.toLowerCase().includes(searchText.toLowerCase())))
          )
          : true)
        .filter((task) => searchAssignUser
          ? task.assignUserIds.includes(searchAssignUser)
          : true)
        .filter((task) => searchAssignResource
          ? task.assignResourceIds.includes(searchAssignResource)
          : true)
        .map((task) => task.id)
    );

    const items = this.projectTasks.map((item) => {
      const parentVisible = searchProject
        ? item.id === searchProject
        : true;

      return {
        ...item,
        visible: parentVisible,
        children: item.children?.map((child) => {
          const childVisible = !parentVisible
            ? false
            : filteredMilestoneIds.size !== 0
              ? filteredMilestoneIds.has(child.id)
              : true;

          return {
            ...child,
            visible: childVisible,
            children: child.children?.map((descendant) => (
              {
                ...descendant,
                visible: !parentVisible || !childVisible
                  ? false
                  : filteredTaskIds.has(descendant.id.split(",")[0]) &&
                  !(showOnlyDelayedTasks && !descendant.isDelayed),
              }
            )),
          }
        }),
      }
    });

    this.projectTasks = items;
  }

  /**
   * Ganttチャートのフィルタ関数
   */
  @action
  private filteringUserTasks() {
    const {
      searchText,
      searchAssignUser,
      searchAssignResource,
      searchProject,
      showOnlyTaskExists,
      showOnlyOverlapTasks,
      showOnlyBlankTasks,
    } = this.resourcesFilter; //roadmap filtering conditions
    const tasks = this.tasks;
    const resources = this.resources;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const filteredResourceIds: Set<string> = new Set(
      resources.filter((resource) => searchText
        ? (
          resource.name.toLowerCase().includes(searchText.toLowerCase()) ||
          resource.memo.toLowerCase().includes(searchText.toLowerCase()) ||
          Object.keys(resource.tags).some((value => value.toLowerCase().includes(searchText.toLowerCase())))
        )
        : false)
        .map((resource) => resource.id)
    );

    const filteredTaskIds: Set<string> = new Set(
      tasks
        .filter((task) => searchText
          ? (
            task.text.toLowerCase().includes(searchText.toLowerCase()) ||
            Object.keys(task.tags).some((value => value.toLowerCase().includes(searchText.toLowerCase())))
          )
          : true)
        .filter((task) => searchProject
          ? task.projectId === searchProject
          : true)
        .map((task) => task.id)
    );

    const items = this.projectTasks.map((item) => {
      return {
        ...item,
        visible: true,
        isAlwaysVisible: !showOnlyTaskExists,
        children: item.children?.map((descendant) => (
          {
            ...descendant,
            visible:
              (searchAssignUser || searchAssignResource
                ? item.id === searchAssignUser || item.id === searchAssignResource
                : true
              ) &&
              (showOnlyOverlapTasks && showOnlyBlankTasks
                ? (filteredTaskIds.has(descendant.id.split(",")[0]) || filteredResourceIds.has(descendant.id.split(",")[1])) &&
                (descendant.isOverlap || descendant.isGap)
                : (filteredTaskIds.has(descendant.id.split(",")[0]) || filteredResourceIds.has(descendant.id.split(",")[1])) &&
                !(showOnlyOverlapTasks && !descendant.isOverlap) &&
                !(showOnlyBlankTasks && !descendant.isGap)
              )
          }
        )),
      }
    });

    this.projectTasks = items;
  }

  /**
   * ログインしているユーザーの権限と状態(組織に属しているかどうか)をチェックし、
   * 問題なければプロジェクトの作成・編集を行う関数
   * @param data 
   * @param loginUser 
   * @param organization
   * @returns 
   */
  private async upsertProject(
    data: Partial<ProjectResponse>,
    loginUser: LoginUserModel,
    organization: OrganizationModel,
  ): Promise<{
    project?: ProjectResponse | Partial<ProjectResponse>;
    error: string | null;
  }> {
    try {
      return await runTransaction(db, async (transaction): Promise<{
        project?: ProjectResponse | Partial<ProjectResponse>;
        error: string | null;
      }> => {
        const currentOrganizationUserModel = this.users.find((user) => user.id === loginUser.id);
        if (!currentOrganizationUserModel) {
          return {
            error: UpsertProjectRequestErrorType.UserNotExists
          };
        }

        const projectRef = this.getProjectRef();
        const usersRef = this.getUsersRef();
        const userDocumentRef = doc(usersRef, currentOrganizationUserModel.docId);

        if (!data.id) {
          data.id = doc(projectRef).id;
        }

        const currentOrganizationUserDoc = await transaction.get(userDocumentRef);
        if (!currentOrganizationUserDoc.exists()) {
          return {
            error: UpsertProjectRequestErrorType.UserNotExists
          };
        }

        const currentLoggedInOrganizationUser = currentOrganizationUserDoc.data() as OrganizationUserResponse;

        if (currentLoggedInOrganizationUser.state !== OrganizationUserState.joined) {
          // Force logout from ConPath
          return {
            error: UpsertProjectRequestErrorType.UserRemovedFromOrganization
          }
        }

        let currentLoggedInProjectRole = ProjectRole.viewer as ProjectRole;
        if (data?.roles) {
          Object.keys(data.roles).forEach((key) => {
            if (key === currentLoggedInOrganizationUser.id) {
              currentLoggedInProjectRole = data.roles![key];
            }
          });
        }
        if (data?.teams) {
          Object.keys(data.teams).forEach((key) => {
            if (organization.teams.some((team) =>
              key === team.id && team.userIds.includes(currentLoggedInOrganizationUser.id))
            ) {
              currentLoggedInProjectRole = Math.min(currentLoggedInProjectRole, data.teams![key]) as ProjectRole;
            }
          });
        }

        if (
          currentLoggedInOrganizationUser.role === OrganizationRole.owner ||
          currentLoggedInProjectRole === ProjectRole.admin
        ) {
          transaction.set(doc(projectRef, data.id), data);

          return {
            project: data,
            error: null
          }
        } else {
          // Update user role
          currentOrganizationUserModel.setFields({
            ...currentOrganizationUserModel.getFields(),
            role: currentLoggedInOrganizationUser.role
          });
          const _users = this.users.map((user) => {
            if (user.id === currentOrganizationUserModel.id) {
              return currentOrganizationUserModel
            }
            return user
          });

          runInAction(() => {
            // Update entire user models
            this.users = [..._users];
            loginUser.setUserInfo({
              ...loginUser.getFields(),
              organizationRole: currentOrganizationUserModel.role,
            });
          });

          return {
            error: UpsertProjectRequestErrorType.UserRoleDoesNotMatch
          };
        }
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      //Sentry here
      return { error: UpsertProjectRequestErrorType.General };
    }
  }

  public async createResource(resource: ResourceForm, loginUser: LoginUserModel): Promise<{ error: string | null }> {
    if (_.isEmpty(resource.name.trim())) {
      return { error: CreateResourceRequestError.ValidationFailed }
    }

    const data: Partial<ResourceResponse> = {
      id: "",
      index: resource.index,
      name: resource.name,
      tags: resource.tags,
      memo: resource.memo,
      isActive: resource.isActive,
      isDeleted: resource.isDeleted,
      iconImageUrl: resource.iconImageFile.url,
      createdBy: loginUser.id,
      createdAt: Timestamp.now(),
    };

    if (resource.iconImageFile.file) {
      // カスタムアイコン画像のアップロード
      const result = await this.uploadResourceIconImage(resource.iconImageFile.file);
      data.iconImageUrl = result.imageUrl;
    }

    const result = await this.upsertResource(data);
    if (result.error) {
      return {
        error: CreateResourceRequestError[result.error as UpsertResourceRequestErrorType] || CreateResourceRequestError.General
      };
    }

    return { error: null };
  }

  public async updateResource(resource: ResourceForm): Promise<{ error: string | null }> {
    if (_.isEmpty(resource.name.trim())) {
      return { error: UpdateResourceRequestError.ValidationFailed }
    }

    const data: Partial<ResourceResponse> = {
      id: resource.id,
      index: resource.index,
      name: resource.name,
      tags: resource.tags,
      memo: resource.memo,
      isActive: resource.isActive,
      isDeleted: resource.isDeleted,
      iconImageUrl: resource.iconImageFile.url,
      createdBy: resource.createdBy,
      createdAt: resource.createdAt ? dateToFirebaseTime(resource.createdAt) : null,
    };

    if (resource.iconImageFile.file) {
      // カスタムアイコン画像のアップロード
      const result = await this.uploadResourceIconImage(resource.iconImageFile.file);
      data.iconImageUrl = result.imageUrl;
    }

    const result = await this.upsertResource(data);
    if (result.error) {
      return {
        error: UpdateResourceRequestError[result.error as UpsertResourceRequestErrorType] || UpdateResourceRequestError.General
      };
    }

    return { error: null };
  }

  public async updateResourceIndexes(resources: ResourceModel[]) {
    const updatedResources: ResourceModel[] = [];
    resources.forEach((resource, index) => {
      if (index !== resource.index) {
        resource.index = index;
        updatedResources.push(resource);
      }
    });

    const resourceRef = this.getResourcesRef();
    if (!_.isEmpty(resourceRef)) {
      for (const chunkedResources of _.chunk(updatedResources, 500)) {
        const batch = writeBatch(db);
        chunkedResources.forEach((resource) => {
          if (resource.isDeleted) {
            batch.delete(doc(resourceRef, resource.id));
          } else {
            batch.update(doc(resourceRef, resource.id), {
              index: resource.index,
            });
          }
        });
        await batch.commit();
      }
    }
  }

  public async deleteResourceIconImage(filePath: string): Promise<{ imageUrl: string }> {
    return new Promise(() => {
      try {
        if (isUploadedImage(filePath)) {
          const urlObject = new URL(filePath);
          const url = decodeURIComponent(urlObject.pathname);
          const paths = url.split("/");
          const fileName = paths[paths.length - 1];

          const prefix = `${FirebaseStorage.projects}/${this.id}/img/resource-icon`;
          const fileRef = ref(storage, `${prefix}/${fileName}`)
          deleteObject(fileRef);
        }
      } catch (err) {
        const error = err as { message: string };
        console.log("[Error] Failed to save email. ", error.message);
      }
    })
  }

  public async deleteOrganizationIconImage(filePath: string): Promise<{ imageUrl: string }> {
    return new Promise(() => {
      try {
        if (isUploadedImage(filePath)) {
          const urlObject = new URL(filePath);
          const url = decodeURIComponent(urlObject.pathname);
          const paths = url.split("/");
          const fileName = paths[paths.length - 1];

          const prefix = `${FirebaseStorage.projects}/${this.id}/img/organization`;
          const fileRef = ref(storage, `${prefix}/${fileName}`)
          deleteObject(fileRef);
        }
      } catch (err) {
        const error = err as { message: string };
        console.log("[Error] Failed to save email. ", error.message);
      }
    })
  }

  /**
   * @param data 
   * @returns 
   */
  private async upsertResource(
    data: Partial<ResourceResponse>,
  ): Promise<{
    resource?: ResourceResponse | Partial<ResourceResponse>;
    error: string | null;
  }> {
    try {
      return await runTransaction(db, async (transaction): Promise<{
        resource?: ResourceResponse | Partial<ResourceResponse>;
        error: string | null;
      }> => {
        const resourcesRef = this.getResourcesRef();

        if (!data.id) {
          data.id = doc(resourcesRef).id;
        } else {
          // 画像の更新がある場合は旧画像を削除
          await transaction.get(doc(resourcesRef, data.id))
            .then((before) => {
              const beforeData = before.data();
              if (beforeData && beforeData.iconImageUrl !== data.iconImageUrl) {
                this.deleteResourceIconImage(beforeData.iconImageUrl);
              }
            });
        }

        transaction.set(doc(resourcesRef, data.id), data);

        return {
          resource: data,
          error: null
        }
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      //Sentry here
      return { error: UpsertResourceRequestErrorType.General };
    }
  }

  @action
  public async upsertLibrary(data: LibraryResponse) {
    const librariesRef = this.getLibrariesRef();
    await setDoc(doc(librariesRef, data.id), data);

    const libraryModel = new LibraryModel(data);
    libraryModel.setOrganizationId(this.id);

    runInAction(() => {
      this.libraries = [
        ...this.libraries,
        libraryModel,
      ];
    });
  }

  @action
  public async getLibraries(): Promise<{ error: string | null }> {

    const documentRef = this.getOrganizationRef();
    const libraryRef = collection(documentRef, FirestoreCollections.organizations.libraries);
    let errorMessage: string | null = null;
    try {
      const librarySnap = await getDocs(libraryRef);

      const libraries: LibraryModel[] = [];
      librarySnap.forEach((resource) => {
        const libraryModel = new LibraryModel(resource.data() as LibraryResponse);
        libraryModel.setOrganizationId(this.id);
        libraries.push(libraryModel);
      });
      runInAction(() => {
        this.libraries = libraries;
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error);
      errorMessage = error.message || "";
      // Sentry here

    } finally {
      return { error: errorMessage };
    }
  }

  @action
  public async deleteLibraries(deleteIds: string[]): Promise<void> {

    const documentRef = this.getOrganizationRef();
    const libraryRef = collection(documentRef, FirestoreCollections.organizations.libraries);

    const batch = writeBatch(db);
    deleteIds.forEach((id) => {
      batch.delete(doc(libraryRef, id));
    });
    await batch.commit();

    runInAction(() => {
      this.libraries = this.libraries.filter(
        (library) => !deleteIds.includes(library.id)
      );
    });
  }

  @action
  public clearLibraries() {
    runInAction(() => {
      this.libraries = [];
    })
  }

  public async createTeam(team: TeamInputForm, loginUser: LoginUserModel): Promise<{ error: string | null }> {
    if (_.isEmpty(team.name.trim())) {
      return { error: CreateTeamRequestError.ValidationFailed }
    }

    const data: Partial<TeamResponse> = {
      id: "",
      name: team.name,
      userIds: team.userIds,
      createdBy: loginUser.id,
      createdAt: Timestamp.now(),
    };

    const result = await this.upsertTeam(data);
    if (result.error) {
      return {
        error: CreateTeamRequestError[result.error as UpsertTeamRequestErrorType] || CreateTeamRequestError.General
      };
    }

    return { error: null };
  }

  public async updateTeam(team: TeamInputForm): Promise<{ error: string | null }> {
    if (_.isEmpty(team.name.trim())) {
      return { error: UpdateTeamRequestError.ValidationFailed }
    }

    const data: Partial<TeamResponse> = {
      id: team.id,
      name: team.name,
      userIds: team.userIds,
      createdBy: team.createdBy,
      createdAt: dateToFirebaseTime(team.createdAt),
    };

    const result = await this.upsertTeam(data);
    if (result.error) {
      return {
        error: UpdateTeamRequestError[result.error as UpsertTeamRequestErrorType] || UpdateTeamRequestError.General
      };
    }

    return { error: null };
  }

  /**
   * @param data 
   * @returns 
   */
  private async upsertTeam(
    data: Partial<TeamResponse>,
  ): Promise<{
    Team?: TeamResponse | Partial<TeamResponse>;
    error: string | null;
  }> {
    try {
      return await runTransaction(db, async (transaction): Promise<{
        Team?: TeamResponse | Partial<TeamResponse>;
        error: string | null;
      }> => {
        const TeamsRef = this.getTeamsRef();

        if (!data.id) {
          data.id = doc(TeamsRef).id;
        }

        transaction.set(doc(TeamsRef, data.id), data);

        return {
          Team: data,
          error: null
        }
      });
    } catch (err) {
      const error = err as { message?: string };
      console.log(error.message);
      //Sentry here
      return { error: UpsertTeamRequestErrorType.General };
    }
  }


  @action
  private getProjectModels(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.projects)) return [];

    return organization.projects.map((project) => {
      const _project = new ProjectModel(project as ProjectResponse);
      _project.setOrganizationId(this.id);
      return _project;
    });
  }

  @action
  private getUserModels(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.users)) return [];

    return organization.users.map((user) => {
      const _user = new OrganizationUserModel(user as OrganizationUserResponse);
      _user.setOrganizationId(this.id);
      return _user;
    });
  }

  @action
  private getResourceModels(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.resources)) return [];

    return organization.resources.map((resources) => new ResourceModel(resources as ResourceResponse));
  }

  @action
  private getTeamModels(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.teams)) return [];

    return organization.teams.map((team) => new TeamModel(team as TeamResponse));
  }


  @action
  private getTasks(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.tasks)) return [];

    return organization.tasks.map((task) => {
      const t = new TaskModel({
        ...task,
        startDate: new Date(task.startDate),
        endDate: new Date(task.endDate),
      } as Task);
      t.setOrganizationId(this.id);
      return t;
    });
  }

  @action
  private getMilestones(organization: OrganizationResponse) {
    //　reloadの際はモデルがObjectに変わるため、Modelとして新しくインスタンスを作成する
    if (_.isEmpty(organization.milestones)) return [];

    return organization.milestones.map((milestone) => {
      const m = new MilestoneModel(milestone as Milestone);
      m.setOrganizationId(this.id);
      return m;
    });
  }

  private getDuration = (startDate: Date, endDate: Date, holidays?: string[] | null) => {
    let duration = 0;

    if (startDate.getTime() < endDate.getTime()) {
      for (
        let date = new Date(startDate);
        date.getTime() < endDate.getTime();
        date.setDate(date.getDate() + 1)
      ) {
        const isHoliday = holidays?.some(
          (holiday) => new Date(holiday).toDateString() === date.toDateString(),
        );

        if (!isHoliday) {
          duration++;
        }
      }
    }

    return duration;
  }

  private getOrganizationRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.organizations.this, this.id);
  }

  private getProjectRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.projects.this);
  }

  private getUsersRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.users);
  }

  private getResourcesRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.resources);
  }

  private getLibrariesRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.libraries);
  }

  private getTeamsRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.teams);
  }

  private getProjectTasksRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.tasks.this);
  }

  private getProjectMilestonesRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.milestones.this);
  }

  private getCommentCollectionRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.comments.this);
  }

  private getProjectActivitiesRef(): CollectionReference<DocumentData> {
    return collection(db, FirestoreCollections.organizations.this, this.id, FirestoreCollections.organizations.activities);
  }

  private async uploadOrganizationIconImage(imageFile: File): Promise<{ imageUrl: string }> {
    return new Promise((resolve) => {
      const prefix = `${FirebaseStorage.projects}/${this.id}/img/organization`;
      const fileRef = ref(storage, `${prefix}/${nanoid()}`)
      const uploadTask = uploadBytesResumable(fileRef, imageFile, {
        contentType: "image/jpeg"
      });

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        const errorMessage = getFirebaseStorageErrorText(error.code);
        console.log(`[Error] failed to upload user profile image ${error.code}: ${errorMessage}`);
        throw new Error(errorMessage);
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          console.log("[Log] File available at: ", downloadUrl);
          resolve({
            imageUrl: downloadUrl
          });
        })
      })

    })
  }
  private async uploadResourceIconImage(imageFile: File): Promise<{ imageUrl: string }> {
    return new Promise((resolve) => {
      const prefix = `${FirebaseStorage.projects}/${this.id}/img/resource-icon`;
      const fileRef = ref(storage, `${prefix}/${nanoid()}`)
      const uploadTask = uploadBytesResumable(fileRef, imageFile, {
        contentType: "image/jpeg"
      });

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        const errorMessage = getFirebaseStorageErrorText(error.code);
        console.log(`[Error] failed to upload user profile image ${error.code}: ${errorMessage}`);
        throw new Error(errorMessage);
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          console.log("[Log] File available at: ", downloadUrl);
          resolve({
            imageUrl: downloadUrl
          });
        })
      })
    })
  }
};