import { PRIORITY } from "src/excalidraw/constants";
import { mutateElement } from "src/excalidraw/element/mutateElement";
import { ElementsMap, ExcalidrawElement } from "src/excalidraw/element/types";
import {
  isGraphElement,
  isOriginTextElement,
  isTaskElement,
} from "./typeChecks";
import { getContainerElement } from "src/excalidraw/element/textElement";
import { arrayToMap } from "src/excalidraw/utils";

// CHANGED:ADD 2023-2-14 #704
export const resetPriority = (elements: readonly ExcalidrawElement[]) => {
  const elementsMap = arrayToMap(elements)

  elements.forEach((element) => {
    if (isGraphElement(element)) {
      if (element.isCriticalPath && element.priority !== PRIORITY[`${element.type}-cp`]) {
        mutateElement(element, {
          priority: PRIORITY[`${element.type}-cp`],
        });
      } else if (!element.isCriticalPath && element.priority !== PRIORITY[element.type]) {
        mutateElement(element, {
          priority: PRIORITY[element.type],
        });
      }
    } else if (isOriginTextElement(element) && element.containerId) {
      const containerElement = getContainerElement(element, elementsMap);
      if (isTaskElement(containerElement)) {
        if (containerElement.isCriticalPath && element.priority !== PRIORITY["text-cp"]) {
          mutateElement(element, {
            priority: PRIORITY["text-cp"],
          });
        } else if (!containerElement.isCriticalPath && element.priority !== PRIORITY[element.type]) {
          mutateElement(element, {
            priority: PRIORITY[element.type],
          });
        }
      } else if (element.priority !== PRIORITY[element.type]) {
        mutateElement(element, {
          priority: PRIORITY[element.type],
        });
      }
    } else if (element.priority !== PRIORITY[element.type]) {
      mutateElement(element, {
        priority: PRIORITY[element.type],
      });
    }
  });
};

