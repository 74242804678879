import clsx from "clsx";
import oc from "open-color";
import { useEffect, useRef, useState } from "react";
import { useDevice } from "src/excalidraw/components/App";
import { exportToCanvas, exportToSvg } from "src/excalidraw/packages/utils";
import { LibraryItem } from "src/excalidraw/types";
import "./LibraryUnitEx.scss";
import { CheckboxItem } from "src/excalidraw/components/CheckboxItem";
import { PlusIcon } from "src/excalidraw/components/icons";

export const LibraryUnitEx = ({
  id,
  name,
  category,
  elements,
  isBookmarked,
  isPending,
  onClick,
  selected,
  onToggle,
  onDrag,
  onToggleBookmark,
}: {
  id: LibraryItem["id"] | /** for pending item */ null;
  name: LibraryItem["name"] | null;
  category: LibraryItem["category"] | null;
  elements?: LibraryItem["elements"];
  isBookmarked?: LibraryItem["isBookmarked"] | null;
  isPending?: boolean;
  onClick: () => void;
  selected: boolean;
  onToggle: (id: string, event: React.MouseEvent) => void;
  onDrag: (id: string, event: React.DragEvent) => void;
  onToggleBookmark: (id: string) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const node = ref.current;
    if (!node) {
      return;
    }

    (async () => {
      if (!elements) {
        return;
      }
      exportToCanvas({
        elements,
        appState: {
          exportBackground: false,
          viewBackgroundColor: oc.white,
        },
        exportPadding: 20,
        backgroundHTML: false,
        files: null,
      }).then((canvas) => {
        node.replaceChildren(canvas);
      });
    })();

    return () => {
      node.innerHTML = "";
    };
  }, [elements]);

  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useDevice().isMobile;
  const adder = isPending && (
    <div
      className="library-unit__adder"
      onClick={
        !!elements || !!isPending
          ? (event) => {
            if (id && event.shiftKey) {
              onToggle(id, event);
            } else {
              onClick();
            }
          }
          : undefined
      }
    >{PlusIcon}</div>
  );

  return (
    <>
      <p className="library-name">
        {name}
      </p>
      <p className="library-category">
        {category}
      </p >
      <div
        className={clsx("library-unit", {
          "library-unit__active": elements,
          "library-unit--hover": elements && isHovered,
          "library-unit--selected": selected,
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={clsx("library-unit__dragger", {
            "library-unit__pulse": !!isPending,
          })}
          ref={ref}
          draggable={!!elements}
          onClick={
            !!elements || !!isPending
              ? (event) => {
                // if (id && event.shiftKey) {
                //   onToggle(id, event);
                // } else {
                //   onClick();
                // }
                if (id) {
                  onToggle(id, event);
                } else {
                  onClick();
                }
              }
              : undefined
          }
          onDragStart={(event) => {
            if (!id) {
              event.preventDefault();
              return;
            }
            setIsHovered(false);
            onDrag(id, event);
          }}
        />
        {adder}
        {id && elements && (
          isBookmarked === true ?
            <button className="library-unit__bookmark" onClick={() => onToggleBookmark(id)}>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M400 480a16 16 0 01-10.63-4L256 357.41 122.63 476A16 16 0 0196 464V96a64.07 64.07 0 0164-64h192a64.07 64.07 0 0164 64v368a16 16 0 01-16 16z"></path>
              </svg>
            </button>
            :
            <button className="library-unit__bookmark" onClick={() => onToggleBookmark(id)}>
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path fill="white" stroke-linecap="round" stroke-linejoin="round" strokeWidth="32" d="M352 48H160a48 48 0 00-48 48v368l144-128 144 128V96a48 48 0 00-48-48z"></path>
              </svg>
            </button>
        )}

        {id && elements && (isHovered || isMobile || selected) && (
          <CheckboxItem
            checked={selected}
            onChange={(checked, event) => onToggle(id, event)}
            className="library-unit__checkbox"
          />
        )}
      </div>
    </>
  );
};
