import { Timestamp } from "firebase/firestore";

export interface SelectedUsers {
  [userId: string]: boolean;
}

export interface VisibleUsers {
  [userId: string]: boolean;
}

export interface Layer {
  id: string;
  index: number;
  name: string;
  selectedUsers: SelectedUsers;
  visibleUsers: VisibleUsers;
  isDeleted: boolean;
  createdBy: string;
  createdAt: Date;
}

export interface LayerResponse extends Omit<Layer, "createdAt"> {
  createdAt: Timestamp;
};

export const LayerDocumentFields = {
  id: "id",
  index: "index",
  name: "name",
  selectedUsers: "selectedUsers",
  visibleUsers: "visibleUsers",
  isDeleted: "isDeleted",
  createdBy: "createdBy",
  createdAt: "createdAt",
} as const;

export type LayerDocumentFields = typeof LayerDocumentFields[keyof typeof LayerDocumentFields];
