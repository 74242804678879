import { register } from "src/excalidraw/actions/register";
import DatePicker from "src/excalidraw/extensions/components/DatePicker";
import { GRID_SIZE } from "src/excalidraw/constants";

export const actionDatePicker = register({
  name: "datePicker",
  trackEvent: { category: "canvas" },
  perform: (_, appState, value) => {
    // Generate the calendar data

    return {
      appState: {
        ...appState,
        holidays: value,
      },
      commitToHistory: false,
      resetHistory: true, // CHANGED:ADD 2023-1-26 #439
      updatedProjectHoliday: true, // CHANGED:ADD 2023-2-14 #676
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <DatePicker updateData={updateData} appState={appState} />
  ),
});
