import { getShortcutFromShortcutName } from "../../actions/shortcuts";
import { useI18n } from "src/excalidraw/i18n";
import { useNavigate } from "react-router-dom";
import {
  useExcalidrawAppState,
  useExcalidrawSetAppState,
  useExcalidrawActionManager,
} from "../App";
import {
  ExportIcon,
  ExportImageIcon,
  HelpIcon,
  LoadIcon,
  MoonIcon,
  save,
  SunIcon,
  TrashIcon,
  usersIcon,
  PrintImageIcon,
  gotoDashboard,
} from "../icons";
import { GithubIcon, DiscordIcon, TwitterIcon } from "../icons";
import DropdownMenuItem from "../dropdownMenu/DropdownMenuItem";
import DropdownMenuItemLink from "../dropdownMenu/DropdownMenuItemLink";
import {
  actionClearCanvas,
  actionLoadScene,
  actionSaveToActiveCloudFile,
  actionSaveToActiveFile,
  actionShortcuts,
  actionToggleTheme,
} from "../../actions";

import "./DefaultItems.scss";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import { activeConfirmDialogAtom } from "../ActiveConfirmDialog";
import { jotaiScope } from "../../jotai";
import { actionPrintImage } from "src/excalidraw/actions/actionExport";
import { Paths } from "src/conpath/constants/Routes";

export const LoadScene = () => {
  const { t } = useI18n();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionLoadScene)) {
    return null;
  }

  return (
    <DropdownMenuItem
      icon={LoadIcon}
      onSelect={() => actionManager.executeAction(actionLoadScene)}
      data-testid="load-button"
      shortcut={getShortcutFromShortcutName("loadScene")}
      aria-label={t("buttons.load")}
    >
      {t("buttons.load")}
    </DropdownMenuItem>
  );
};
LoadScene.displayName = "LoadScene";

export const SaveToActiveFile = () => {
  const { t } = useI18n();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionSaveToActiveFile)) {
    return null;
  }

  return (
    <DropdownMenuItem
      shortcut={getShortcutFromShortcutName("saveScene")}
      data-testid="save-button"
      onSelect={() => actionManager.executeAction(actionSaveToActiveFile)}
      icon={save}
      aria-label={`${t("buttons.save")}`}
    >{`${t("buttons.save")}`}</DropdownMenuItem>
  );
};
SaveToActiveFile.displayName = "SaveToActiveFile";

export const SaveAsImage = () => {
  const setAppState = useExcalidrawSetAppState();
  const { t } = useI18n();
  return (
    <DropdownMenuItem
      icon={ExportImageIcon}
      data-testid="image-export-button"
      onSelect={() => setAppState({ openDialog: "imageExport" })}
      shortcut={getShortcutFromShortcutName("imageExport")}
      aria-label={t("buttons.exportImage")}
    >
      {t("buttons.exportImage")}
    </DropdownMenuItem>
  );
};
SaveAsImage.displayName = "SaveAsImage";

export const PrintAsImage = () => {
  const setAppState = useExcalidrawSetAppState();
  const { t } = useI18n();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionPrintImage)) {
    return null;
  }

  return (
    <DropdownMenuItem
      icon={PrintImageIcon}
      data-testid="print-image-button"
      // onSelect={() => actionManager.executeAction(actionPrintImage)}
      // shortcut={getShortcutFromShortcutName("printImage")}
      onSelect={() => setAppState({ openDialog: "printExport" })}
      shortcut={getShortcutFromShortcutName("printExport")}
      aria-label={t("buttons.printImage")}
    >
      {t("buttons.printImage")}
    </DropdownMenuItem>
  );
};
PrintAsImage.displayName = "PrintAsImage";

export const Help = () => {
  const { t } = useI18n();

  const actionManager = useExcalidrawActionManager();

  return (
    <DropdownMenuItem
      data-testid="help-menu-item"
      icon={HelpIcon}
      onSelect={() => actionManager.executeAction(actionShortcuts)}
      shortcut="?"
      aria-label={t("helpDialog.title")}
    >
      {t("helpDialog.title")}
    </DropdownMenuItem>
  );
};
Help.displayName = "Help";

export const ClearCanvas = () => {
  const { t } = useI18n();

  const setActiveConfirmDialog = useSetAtom(
    activeConfirmDialogAtom,
    jotaiScope,
  );
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionClearCanvas)) {
    return null;
  }

  return (
    <DropdownMenuItem
      icon={TrashIcon}
      onSelect={() => setActiveConfirmDialog("clearCanvas")}
      data-testid="clear-canvas-button"
      aria-label={t("buttons.clearReset")}
    >
      {t("buttons.clearReset")}
    </DropdownMenuItem>
  );
};
ClearCanvas.displayName = "ClearCanvas";

// CHANGED:ADD 2023-3-6 #738
export const MenuSeparator = () => {
  const appState = useExcalidrawAppState();

  if (appState.viewModeEnabled) {
    return null;
  }
  return (
    <div
      style={{
        height: "1px",
        backgroundColor: "var(--default-border-color)",
        margin: ".5rem 0",
      }}
    />
  );
};
MenuSeparator.displayName = "DropdownMenuSeparator";

export const ToggleTheme = () => {
  const { t } = useI18n();
  const appState = useExcalidrawAppState();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionToggleTheme)) {
    return null;
  }

  return (
    <DropdownMenuItem
      onSelect={(event) => {
        // do not close the menu when changing theme
        event.preventDefault();
        return actionManager.executeAction(actionToggleTheme);
      }}
      icon={appState.theme === "dark" ? SunIcon : MoonIcon}
      data-testid="toggle-dark-mode"
      shortcut={getShortcutFromShortcutName("toggleTheme")}
      aria-label={
        appState.theme === "dark"
          ? t("buttons.lightMode")
          : t("buttons.darkMode")
      }
    >
      {appState.theme === "dark"
        ? t("buttons.lightMode")
        : t("buttons.darkMode")}
    </DropdownMenuItem>
  );
};
ToggleTheme.displayName = "ToggleTheme";

export const ChangeCanvasBackground = () => {
  const { t } = useI18n();
  const appState = useExcalidrawAppState();
  const actionManager = useExcalidrawActionManager();

  if (appState.viewModeEnabled) {
    return null;
  }
  return (
    <div style={{ marginTop: "1rem", backgroundColor: "#F5F6F7", padding: "10px" }}>
      <div style={{ fontSize: ".75rem", marginBottom: ".5rem" }}>
        {t("labels.canvasBackground")}
      </div>
      <div style={{ padding: "0 0 0.625rem 0" }}>
        {actionManager.renderAction("changeViewBackgroundColor")}
      </div>
    </div>
  );
};
ChangeCanvasBackground.displayName = "ChangeCanvasBackground";

export const Export = () => {
  const { t } = useI18n();
  const setAppState = useExcalidrawSetAppState();
  return (
    <DropdownMenuItem
      icon={ExportIcon}
      onSelect={() => {
        setAppState({ openDialog: "jsonExport" });
      }}
      data-testid="json-export-button"
      aria-label={t("buttons.export")}
    >
      {t("buttons.export")}
    </DropdownMenuItem>
  );
};
Export.displayName = "Export";

export const Socials = () => (
  <>
    <DropdownMenuItemLink
      icon={GithubIcon}
      href="https://github.com/excalidraw/excalidraw"
      aria-label="GitHub"
    >
      GitHub
    </DropdownMenuItemLink>
    <DropdownMenuItemLink
      icon={DiscordIcon}
      href="https://discord.gg/UexuTaE"
      aria-label="Discord"
    >
      Discord
    </DropdownMenuItemLink>
    <DropdownMenuItemLink
      icon={TwitterIcon}
      href="https://twitter.com/excalidraw"
      aria-label="Twitter"
    >
      Twitter
    </DropdownMenuItemLink>
  </>
);
Socials.displayName = "Socials";

export const LiveCollaborationTrigger = ({
  onSelect,
  isCollaborating,
}: {
  onSelect: () => void;
  isCollaborating: boolean;
}) => {
  const { t } = useI18n();
  return (
    <DropdownMenuItem
      data-testid="collab-button"
      icon={usersIcon}
      className={clsx({
        "active-collab": isCollaborating,
      })}
      onSelect={onSelect}
    >
      {t("labels.liveCollaboration")}
    </DropdownMenuItem>
  );
};
LiveCollaborationTrigger.displayName = "LiveCollaborationTrigger";

// CHANGED:ADD 2023-2-12 #512
export const SaveToActiveCloudFile = () => {
  const { t } = useI18n();
  const appState = useExcalidrawAppState();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionSaveToActiveCloudFile)) {
    return null;
  }

  return (
    <DropdownMenuItem
      icon={save}
      onSelect={() => actionManager.executeAction(actionSaveToActiveCloudFile)}
      data-testid=""
      aria-label={`${t("buttons.saveToCloud")}`}
    >
      {`${t("buttons.saveToCloud")}`}
    </DropdownMenuItem>
  );
};
SaveToActiveCloudFile.displayName = "SaveToActiveCloudFile";

// CHANGED:ADD 2024-8-27 #2174
export const GoToDashboard = () => {
  const { t } = useI18n();
  // const actionManager = useExcalidrawActionManager();
  const navigate = useNavigate();

  // if (!actionManager.isActionEnabled(actionSaveToActiveCloudFile)) {
  //   return null;
  // }

  return (
    <DropdownMenuItem
      icon={gotoDashboard}
      onSelect={() => navigate(Paths.dashboard, { replace: true }) }
      data-testid=""
      aria-label={`${t("buttons.gotoDashboard")}`}
    >
      {`${t("buttons.gotoDashboard")}`}
    </DropdownMenuItem>
  );
};
GoToDashboard.displayName = "GoToDashboard";