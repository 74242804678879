import clsx from "clsx";
import { useDevice, useExcalidrawAppState } from "../App";

const MenuTrigger = ({
  className = "",
  children,
  onToggle,
  title = "",
}: {
  className?: string;
  children: React.ReactNode;
  onToggle: () => void;
  title?: string;
}) => {
  const appState = useExcalidrawAppState();
  const device = useDevice();
  const classNames = clsx(
    `dropdown-menu-button ${className}`,
    "export_rm",
    "zen-mode-transition",
    {
      "transition-left": appState.zenModeEnabled,
      "dropdown-menu-button--mobile": device.isMobile,
    },
  ).trim();
  return (
    <>
      <div
        className={clsx(
          title ? `dropdown-menu-title-container` : ``,
          "ml-auto",
        )}
      >
        {title && <p>{title}</p>}
        <button
          data-prevent-outside-click
          className={classNames}
          onClick={onToggle}
          type="button"
          data-testid="dropdown-menu-button"
        >
          {children}
        </button>
      </div>
    </>
  );
};

export default MenuTrigger;
MenuTrigger.displayName = "DropdownMenuTrigger";
