import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IoChevronDown, IoCaretDownOutline } from "react-icons/io5";

export type SearchListOption = {
  value: string;
  label: string;
};

interface Props {
  title: string;
  options: SearchListOption[];
  selected: string;
  onChange: (selectedValue: string) => void;
  disabled?: boolean;
  customClass?: string;
}

export default function SearchListBox(
  {
    title,
    options,
    selected,
    onChange,
    disabled = false,
    customClass = "",
  }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="z-10">
      <Listbox value={selected} onChange={onChange} disabled={disabled}>
        <div
          className={`relative ${disabled ? "pointer-events-none" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Listbox.Button
            className={
              customClass === ""
                ? `relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`
                : customClass
            }
          >
            <span className="block truncate">{title}</span>
            <span className="absolute inset-y-0 right-5 flex items-center pr-2">
              <IoChevronDown
                className={`h-5 w-5 transition-opacity absolute ${isHovered ? 'opacity-0' : 'opacity-100'
                  }`}
                aria-hidden="true"
              />
              <IoCaretDownOutline
                className={`h-5 w-5 transition-opacity absolute ${isHovered ? 'opacity-100' : 'opacity-0'
                  }`}
                aria-hidden="true"
              />
            </span>

          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  onClick={() => setIsHovered(false)}
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-[#E3F2FA]" : ""
                    }`
                  }
                  value={option.value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                          }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#46AADF]">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
