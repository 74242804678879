import _ from "lodash";

//types and interface
import {
  Layer,
  LayerResponse,
  SelectedUsers,
  VisibleUsers,
} from "src/conpath/interfaces/Layer";

//firebase
import { db } from "src/configs/firebase";
import {
  DocumentData,
  DocumentReference,
  doc,
  runTransaction,
} from "firebase/firestore";
import { firebaseTimeToDate } from "src/utils/timeUtils";

import { FirestoreCollections } from "../constants/FirestoreCollections";
import { action, runInAction } from "mobx";
import InternalError from "../interfaces/InternalError";

export default class LayerModel implements Layer {
  id!: string;
  index!: number;
  name!: string;
  selectedUsers!: SelectedUsers;
  visibleUsers!: VisibleUsers;
  isDeleted!: boolean
  createdBy!: string;
  createdAt!: Date;

  organizationId: string | null = null;
  projectId: string | null = null;

  constructor(layer: LayerResponse) {
    this.setFields(layer);
  };

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public setProjectId(projectId: string) {
    this.projectId = projectId;
  }

  public getFields(): Layer {
    return {
      id: this.id,
      index: this.index,
      name: this.name,
      selectedUsers: this.selectedUsers,
      visibleUsers: this.visibleUsers,
      isDeleted: this.isDeleted,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    };
  }

  public setFields(layer: LayerResponse) {
    this.id = layer.id;
    this.index = layer.index;
    this.name = layer.name;
    this.selectedUsers = layer.selectedUsers;
    this.visibleUsers = layer.visibleUsers;
    this.isDeleted = layer.isDeleted;
    this.createdBy = layer.createdBy;
    this.createdAt = firebaseTimeToDate(layer.createdAt);
  }

  public setName(name: string) {
    this.name = name;
  }

  public setIsDeleted(isDeleted: boolean) {
    this.isDeleted = isDeleted;
  }

  public setSelectedUsers(userId: string, isSelected: boolean) {
    const nextSelectedUsers = this.selectedUsers;

    if (isSelected) {
      nextSelectedUsers[userId] = true;
    } else {
      delete nextSelectedUsers[userId];
    }

    this.selectedUsers = nextSelectedUsers;
  }

  @action
  public setVisibleUsers(userId: string, isSelected: boolean) {
    const nextVisibleUsers = this.visibleUsers;

    if (isSelected) {
      nextVisibleUsers[userId] = true;
    } else {
      delete nextVisibleUsers[userId];
    }

    runInAction(() => {
      this.visibleUsers = nextVisibleUsers;
    })
  }

  @action
  public async updateLayer() {

    const layerDocumentRef = this.getLayerDocumentRef();

    if (!layerDocumentRef) {
      throw new Error("No layer collection ref found.");
    }

    // Updating layer document
    const layerUpdatingField: Partial<LayerResponse> = {
      name: this.name,
      selectedUsers: this.selectedUsers,
      visibleUsers: this.visibleUsers,
      isDeleted: this.isDeleted,
    };

    await runTransaction(db, async (transaction) => {
      transaction.update(layerDocumentRef, layerUpdatingField);
    });
  }

  @action
  public async delete(): Promise<InternalError> {

    try {
      const layerDocumentRef = this.getLayerDocumentRef();
      if (_.isEmpty(layerDocumentRef)) {
        throw new Error("No layer collection ref found.");
      }

      await runTransaction(db, async (transaction) => {
        transaction.delete(layerDocumentRef);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "削除に失敗しました。" };
    }
  }

  private getLayerDocumentRef(): DocumentReference<DocumentData> {
    return doc(db,
      FirestoreCollections.organizations.this,
      this.organizationId!,
      FirestoreCollections.organizations.projects.this,
      this.projectId!,
      FirestoreCollections.organizations.projects.layers.this,
      this.id
    );
  }
};