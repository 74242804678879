import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from "@mui/material";

interface toggleValue {
  icon: JSX.Element;
  props: ToggleButtonProps;
}

const ToggleButton = ({
  props,
  value1,
  value2,
}: {
  props?: ToggleButtonGroupProps;
  value1: toggleValue;
  value2: toggleValue;
}) => {
  return (
    <ToggleButtonGroup {...props}>
      <MuiToggleButton {...value1.props}>{value1.icon}</MuiToggleButton>
      <MuiToggleButton {...value2.props}>{value2.icon}</MuiToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButton;
