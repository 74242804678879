import { observer } from "mobx-react-lite";

//components
import Badge from "src/conpath/components/Badge";

//hooks & utils
import { useStore } from "src/conpath/hooks/useStore";

//styles
import "./EditTaskChecklist.scss";

//models
import TaskModel from "src/conpath/models/TaskModel";

const EditTaskTags = observer((props: {
  taskModel: TaskModel;
}) => {
  const { taskModel } = props;
  const { organizationStore, userStore } = useStore();
  const { selectedOrganization } = organizationStore;
  const { loginUser } = userStore;

  const onClose = (tag: string) => {
    taskModel.removeTag(tag);
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.nativeEvent.isComposing) return

    const tagCount = Object.keys(taskModel.tags)?.length || 0;

    const value = e.currentTarget.value
    if (!value.length && e.key == 'Backspace' && tagCount > 0) {
      const tag = Object.keys(taskModel.tags).pop() || "";
      onClose(tag);
      return
    }

    if (e.key !== 'Enter' || !value.trim()) return
    taskModel.addTag(value);
    e.currentTarget.value = ''
    e.preventDefault()
  }

  if (!selectedOrganization || !loginUser) return <></>

  return (
    <div
      className={'border border-solid border-[var(--input-border-color)] flex flex-nowrap items-center leading-tight px-2 rounded bg-white h-[45px] max-w-[550px] overflow-x-auto overflow-y-hidden tags-container'}
    >
      {Object.keys(taskModel.tags).map((tag, i) => {
        return (
          <Badge
            key={i}
            onClose={() => onClose(tag)}
            className={""}>
            {tag}
          </Badge>
        )
      })}
      <input
        className={'border-0 outline-none flex-grow'}
        onKeyDown={handleKeyDown}
        {...props}
      />
    </div>
  )
});

export default EditTaskTags;