import _ from "lodash";

import { action, observable } from "mobx";

//types and interface
import Team, { TeamResponse } from "src/conpath/interfaces/Team";

//firebase
import { db } from "src/configs/firebase";
import {
  DocumentData,
  DocumentReference,
  doc,
  runTransaction,
} from "firebase/firestore";
import { FirestoreCollections } from "../constants/FirestoreCollections";
import InternalError from "../interfaces/InternalError";
import { firebaseTimeToDate } from "src/utils/timeUtils";

export default class TeamModel implements Team {

  @observable
  id!: string;
  @observable
  name!: string;
  @observable
  userIds!: string[];
  @observable
  createdBy!: string;
  @observable
  createdAt!: Date;

  organizationId: string | null = null;

  constructor(team: TeamResponse) {
    this.setFields(team);
  };

  //public

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public getFields(): Team {
    return {
      id: this.id,
      name: this.name,
      userIds: this.userIds,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    };
  }

  public setFields(team: TeamResponse) {
    this.id = team.id;
    this.name = team.name;
    this.userIds = team.userIds;
    this.createdBy = team.createdBy;
    this.createdAt = firebaseTimeToDate(team.createdAt);
  }

  @action
  public async delete(): Promise<InternalError> {

    try {
      const teamRef = this.getTeamDocumentRef();
      if (_.isEmpty(teamRef)) {
        throw new Error("No resource collection ref found.");
      }

      await runTransaction(db, async (transaction) => {
        transaction.delete(teamRef);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "削除に失敗しました。" };
    }
  }

  private getTeamDocumentRef(): DocumentReference<DocumentData> {
    return doc(db, FirestoreCollections.organizations.this, this.organizationId!, FirestoreCollections.organizations.teams, this.id);
  }
};