import _ from "lodash";
import { ElementsMap, ExcalidrawElement } from "src/excalidraw/element/types";
import {
  AppState,
  ExcalidrawAssignResources,
  ExcalidrawAssignUsers,
  ExcalidrawChecklist,
  ExcalidrawTags,
  TaskChildren,
} from "src/excalidraw/types";
import Task, { TaskDocumentFields, TaskResponse } from "src/conpath/interfaces/Task";
import { isTaskElement } from "./element/typeChecks";
import { getBoundTextElement } from "../element/textElement";

//firebase
import { db } from "src/configs/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { FirestoreCollections } from "src/conpath/constants/FirestoreCollections";
import { ChecklistResponse } from "src/conpath/interfaces/Checklist";
import { firebaseTimeToDate } from "src/utils/timeUtils";

export const getTaskChildren = async (
  taskIds: string[],
  appState: AppState,
  addAssignUsers: boolean,
  addAssignResources: boolean,
): Promise<TaskChildren> => {
  const assignUsers: ExcalidrawAssignUsers[] = [];
  const assignResources: ExcalidrawAssignResources[] = [];
  const tags: ExcalidrawTags[] = [];
  const checklists: ExcalidrawChecklist[] = [];

  for (const taskId of taskIds) {
    const taskCollectionRef = collection(db,
      FirestoreCollections.organizations.this,
      appState.organizationId,
      FirestoreCollections.organizations.tasks.this
    );

    const taskDocumentRef = doc(taskCollectionRef, taskId);

    if (!_.isEmpty(taskDocumentRef)) {
      const taskDocumentSnap = await getDoc(taskDocumentRef);
      if (taskDocumentSnap.exists()) {
        const data = taskDocumentSnap.data() as TaskResponse;
        if (addAssignUsers && data.assignUserIds?.length) {
          assignUsers.push({
            taskId: taskId,
            assignUserIds: data.assignUserIds || [],
          });
        }
        if (addAssignResources && data.assignResourceIds?.length) {
          assignResources.push({
            taskId: taskId,
            assignResourceIds: data.assignResourceIds || [],
          });
        }
        if (Object.keys(data.tags || {}).length) {
          tags.push({
            taskId: taskId,
            tags: data.tags || {},
          });
        }
      }

      const checklistsCollectionRef = collection(taskDocumentRef,
        FirestoreCollections.organizations.tasks.checklists.this);

      if (!_.isEmpty(checklistsCollectionRef)) {
        const checklistsSnapshot = await getDocs(checklistsCollectionRef);

        if (!checklistsSnapshot.empty) {
          checklistsSnapshot.forEach((checklistDoc) => {
            const data = checklistDoc.data() as ChecklistResponse;
            checklists.push({
              taskId: taskId,
              title: data.title,
              url: data.url,
              createdAt: data.createdAt ? firebaseTimeToDate(data.createdAt) : new Date(),
              createdBy: data.createdBy,
            });
          });
        }
      }
    }
  }

  return { assignUsers, assignResources, tags, checklists };
}

export const getProjectTaskChildren = async (
  appState: AppState,
  addAssignUsers: boolean,
  addAssignResources: boolean,
): Promise<TaskChildren> => {
  const assignUsers: ExcalidrawAssignUsers[] = [];
  const assignResources: ExcalidrawAssignResources[] = [];
  const tags: ExcalidrawTags[] = [];
  const checklists: ExcalidrawChecklist[] = [];

  const taskCollectionRef = collection(db,
    FirestoreCollections.organizations.this,
    appState.organizationId,
    FirestoreCollections.organizations.tasks.this
  );

  if (!_.isEmpty(taskCollectionRef)) {
    const taskDocumentSnaps = await getDocs(
      query(taskCollectionRef,
        where(TaskDocumentFields.projectId, "==", appState.projectId),
        where(TaskDocumentFields.isClosed, "==", false),
        where(TaskDocumentFields.isDeleted, "==", false),
      ));

    if (!taskDocumentSnaps.empty) {
      for (const taskDocumentSnap of taskDocumentSnaps.docs) {
        const task = taskDocumentSnap.data() as TaskResponse;
        if (addAssignUsers && task.assignUserIds?.length) {
          assignUsers.push({
            taskId: task.id,
            assignUserIds: task.assignUserIds || [],
          });
        }
        if (addAssignResources && task.assignResourceIds?.length) {
          assignResources.push({
            taskId: task.id,
            assignResourceIds: task.assignResourceIds || [],
          });
        }
        if (Object.keys(task.tags || {}).length) {
          tags.push({
            taskId: task.id,
            tags: task.tags || {},
          });
        }

        const checklistsCollectionRef = collection(taskDocumentSnap.ref,
          FirestoreCollections.organizations.tasks.checklists.this);

        if (!_.isEmpty(checklistsCollectionRef)) {
          const checklistsSnapshot = await getDocs(checklistsCollectionRef);
          if (!checklistsSnapshot.empty) {
            checklistsSnapshot.forEach((checklistDoc) => {
              const checklist = checklistDoc.data() as ChecklistResponse;
              checklists.push({
                taskId: task.id,
                title: checklist.title,
                url: checklist.url,
                createdAt: checklist.createdAt ? firebaseTimeToDate(checklist.createdAt) : new Date(),
                createdBy: checklist.createdBy,
              });
            });
          }
        }
      }
    }
  }

  return { assignUsers, assignResources, tags, checklists };
}

export const savePasteLibraryElements = async (
  elements: ExcalidrawElement[],
  elementsMap: ElementsMap,
  appState: AppState,
  taskChildren: TaskChildren,
) => {
  const taskRef = collection(db,
    FirestoreCollections.organizations.this,
    appState.organizationId,
    FirestoreCollections.organizations.tasks.this
  );

  try {
    const addTasks: Task[] = [];

    for (const el of elements) {
      if (isTaskElement(el)) {
        const task: Task = {
          id: el.id,
          text: getBoundTextElement(el, elementsMap)?.originalText || "",
          projectId: appState.projectId,
          startDate: el.startDate,
          endDate: el.endDate,
          duration: el.duration,
          prevDependencies: el.prevDependencies,
          nextDependencies: el.nextDependencies,
          freeFloats: el.freeFloats,
          memo: el.memo,
          isClosed: el.isClosed,
          isDeleted: el.isDeleted,
          created: el.created,
          createdBy: el.createdBy,
          updated: el.updated,
          updatedBy: el.updatedBy,
          assignUserIds: (taskChildren.assignUsers?.find((assignUser) => assignUser.taskId === el.id)?.assignUserIds || [])
            .filter((userId) => appState.projectMembers.includes(userId)),
          assignResourceIds: taskChildren.assignResources?.find((assignResource) => assignResource.taskId === el.id)?.assignResourceIds || [],
          tags: taskChildren.tags?.find((tag) => tag.taskId === el.id)?.tags || {},
        };

        addTasks.push(task);
      }
    }

    if (!_.isEmpty(taskRef)) {
      for (const chunkedTasks of _.chunk(addTasks, 500)) {
        const batch = writeBatch(db);
        chunkedTasks.forEach((task) => {
          batch.set(doc(taskRef, task.id), task);
        });
        await batch.commit();
      }
    }

    for (const task of addTasks) {
      const taskChecklists = taskChildren.checklists?.filter((c) => c.taskId === task.id);
      if (taskChecklists) {
        const checklistRef = collection(taskRef,
          task.id,
          FirestoreCollections.organizations.tasks.checklists.this
        );

        if (!_.isEmpty(checklistRef)) {
          for (const chunkedChecklists of _.chunk(taskChecklists, 500)) {
            const batch = writeBatch(db);
            chunkedChecklists.forEach((c) => {
              const checklistDoc = doc(checklistRef);
              setDoc(checklistDoc, {
                id: checklistDoc.id,
                title: c.title,
                url: c.url,
                isChecked: false,
                createdBy: c.createdBy,
                createdAt: c.createdAt ? new Date(c.createdAt) : new Date(),
                checkedBy: null,
                checkedAt: null,
                disableNotification: true, // ライブラリペースト時は通知OFF
              })
            });

            await batch.commit();
          }
        }
      }
    }
  } catch (err) {
    const error = err as { message?: string };
    console.log(error);
    // Sentry here
  }
};
