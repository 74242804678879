import { ElementsMap, ExcalidrawElement } from "../../element/types";
import { AppState } from "src/excalidraw/types";
import { centerPoint } from "src/excalidraw/math";
import { isLinkElement, isTaskElement } from "./typeChecks";
import { TaskElementEditor } from "./taskElementEditor";
import { LinkElementEditor } from "./linkElementEditor";

//CHANGED:ADD 2022/12/08 #225
export const getContainerCenterEx = (
  container: ExcalidrawElement,
  appState: AppState,
  elementsMap: ElementsMap,
) => {
  // CHANGED:UPDATE 2023/01/17 #390
  if (isTaskElement(container)) {
    const points = TaskElementEditor.getPointsGlobalCoordinates(container, elementsMap);

    let x = 0;
    let y = 0;

    const midPoint = centerPoint(points[0], points[1]);
    x = midPoint[0];
    y = midPoint[1];

    return { x, y };
  } else if (isLinkElement(container)) {
    const points = LinkElementEditor.getPointsGlobalCoordinates(container, elementsMap);

    let x = 0;
    let y = 0;

    const midPoint = centerPoint(points[0], points[1]);
    x = midPoint[0];
    y = midPoint[1];

    return { x, y };
  } else {
    return {
      x: container.x + container.width / 2,
      y: container.y + container.height / 2,
    };
  }
};
