export const OrganizationRole = {
  owner: 1, //admin role
  member: 2, // read and write
  guest: 3, // readonly
} as const;

export type OrganizationRole = typeof OrganizationRole[keyof typeof OrganizationRole];

export const OrganizationRoleMap: {[key in OrganizationRole]: string } = {
  1: "オーナー",
  2: "メンバー",
  3: "ゲスト",
};

export const ProjectRole = {
  admin: 1, //admin role
  writer: 2, // read and write
  viewer: 3, // readonly
} as const;

export type ProjectRole = typeof ProjectRole[keyof typeof ProjectRole];

export const ProjectRoleMap: { [key in ProjectRole]: string } = {
  1: "管理者",
  2: "編集者",
  3: "閲覧のみ",
};
