import { action, observable } from "mobx";

//types and interface
import Milestone, { GanttChartMilestone } from "src/conpath/interfaces/Milestone";

export default class MilestoneModel implements Milestone {

  @observable
  id: string;
  @observable
  text: string;
  @observable
  date: Date;
  @observable
  projectId: string;
  @observable
  prevDependencies: readonly string[] | null;
  @observable
  nextDependencies: readonly string[] | null;
  @observable
  isClosed: boolean;
  @observable
  isDeleted: boolean;
  @observable
  created: number;
  @observable
  createdBy: string;
  @observable
  updated: number;
  @observable
  updatedBy: string;

  @observable
  milestoneCompletionRate: number = 0;

  organizationId: string = "";
  
  constructor(milestone: Milestone) {
    this.id = milestone.id;
    this.text = milestone.text;
    this.projectId = milestone.projectId;
    this.date = milestone.date;
    this.prevDependencies = milestone.prevDependencies;
    this.nextDependencies = milestone.nextDependencies;
    this.isClosed = milestone.isClosed;
    this.isDeleted = milestone.isDeleted;
    this.created = milestone.created;
    this.createdBy = milestone.createdBy;
    this.updated = milestone.updated;
    this.updatedBy = milestone.updatedBy;
  };

  //public

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public getFields(): Milestone {
    return {
      id: this.id,
      text: this.text,
      projectId: this.projectId,
      date: this.date,
      prevDependencies: this.prevDependencies,
      nextDependencies: this.nextDependencies,
      isClosed: this.isClosed,
      isDeleted: this.isDeleted,
      created: this.created,
      createdBy: this.createdBy,
      updated: this.updated,
      updatedBy: this.updatedBy,
    };
  }

  public getGanttChartMilestoneFields(): GanttChartMilestone {
    return {
      ...this.getFields(),
      isTask: false,
      ratio: this.milestoneCompletionRate,
    }
  }

  //private
};