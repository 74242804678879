import React, { useState } from "react";
import "./SearchBar.scss";
import { MagnifierIcon } from "./icons";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onEnterKeyPressed: () => void;
}

const SearchBar = React.memo((props: Props) => {
  const { onEnterKeyPressed, ...rest } = props;

  const [isComposing, setComposing] = useState<boolean>(false);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isComposing) {
      onEnterKeyPressed();
    }
  };

  return (
    <div className="container">
      <MagnifierIcon className="icon" />
      <input
        {...rest}
        onCompositionStart={() => setComposing(true)}
        onCompositionEnd={() => setComposing(false)}
        onKeyDown={onKeyDown}
      />
    </div>
  );
});

export default SearchBar;
