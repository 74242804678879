import React, { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { CalenderIcon } from "src/conpath/components/icons";
import { NonDeletedExcalidrawElement } from "src/excalidraw//element/types";
import { t } from "src/excalidraw//i18n";
import { AppState, BinaryFiles, ProjectPrintHeader, ProjectPrintOption } from "src/excalidraw//types";
import { Dialog } from "src/excalidraw//components/Dialog";
import Stack from "src/excalidraw//components/Stack";
import "src/excalidraw/components/ExportDialog.scss";
import OpenColor from "open-color";
import isEqual from 'lodash/isEqual';
import { TbFile, TbFileHorizontal } from "react-icons/tb";

import {
  AFTER_PRINT_MONTH,
  APP_NAME,
  BEFORE_PRINT_MONTH,
  CANVAS_EXPORT_MAX_WIDTH_OR_HEIGHT,
  DEFAULT_EXPORT_PADDING,
  GRID_SIZE,
  JOB_ELEMENTS_WIDTH,
} from "src/excalidraw//constants";
import { nativeFileSystemSupported } from "src/excalidraw/data/filesystem";
import { ActionManager } from "src/excalidraw//actions/manager";
import { exportToCanvas } from "src/excalidraw//packages/utils";
import { DateRange, Range, RangeFocus } from "react-date-range";
import { Popover, Transition } from "@headlessui/react";
import { formatDate } from "src/utils/dateUtils";
import { PrintHeaderEditPopover, PrintHeaderElement } from "./PrintExportDialogHeader";
import RadioButton from "src/conpath/components/RadioButton";
import ToggleButton from "src/conpath/components/ToggleButton";

export const ErrorCanvasPreview = () => {
  return (
    <div>
      <h3>{t("canvasError.cannotShowPreview")}</h3>
      <p>
        <span>{t("canvasError.canvasTooBig")}</span>
      </p>
      <em>({t("canvasError.canvasTooBigTip")})</em>
    </div>
  );
};

const ExportButton: React.FC<{
  color: keyof OpenColor;
  onClick: () => void;
  title: string;
  shade?: number;
  children?: React.ReactNode;
}> = ({ children, title, onClick, color, shade = 6 }) => {
  return (
    <button
      className="ExportDialog-imageExportButton"
      style={{
        ["--button-color" as any]: OpenColor[color][shade],
        ["--button-color-darker" as any]: OpenColor[color][shade + 1],
        ["--button-color-darkest" as any]: OpenColor[color][shade + 2],
      }}
      title={title}
      aria-label={title}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const PrintExportModal = ({
  elements,
  appState,
  files,
  exportPadding = DEFAULT_EXPORT_PADDING,
  actionManager,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  files: BinaryFiles;
  exportPadding?: number;
  actionManager: ActionManager;
  onCloseRequest: () => void;
}) => {
  registerLocale("ja", ja);
  const currentDate = new Date();
  const currentAfterPrintDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + AFTER_PRINT_MONTH,
    0,
  );

  const previewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const [renderError, setRenderError] = useState<Error | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string>(
    t("printExportDialog.printLoading"),
  );
  const [pageStyle, setPageStyle] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(
    appState.projectEndDate.getTime() < currentDate.getTime()
      ? appState.projectStartDate
      : new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - BEFORE_PRINT_MONTH,
          1,
        ),
  );
  const [endDate, setEndDate] = useState<Date>(
    appState.projectEndDate.getTime() < currentDate.getTime() ||
      appState.projectEndDate.getTime() < currentAfterPrintDate.getTime()
      ? appState.projectEndDate
      : new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + AFTER_PRINT_MONTH,
          0,
        ),
  );
  const [ranges, setRanges] = useState<[Range]>([{}]);
  const [isRangeSelected, setIsRangeSelected] = useState<boolean>(false);
  const buttonRef = React.useRef<any>(null);
  const [printHeader, setPrintHeader] = useState<ProjectPrintHeader>({
    title: "",
    subTitle: "",
    remark: "",
    logo: {
      file: null,
      url: "",
    },
    organizationName: "",
    date: new Date(),
  });
  const [printOption, setPrintOption] = useState<ProjectPrintOption>({
    hasHeader: true,
    direction: "landscape",
    fitType: "none",
    fitDirection: "x",
  });
  useEffect(() => {
    if (!ranges[0]?.startDate || !ranges[0]?.endDate) {
      setRanges([{ startDate, endDate, key: "selection" }]);
    }
  }, [startDate, endDate]);

  startDate.setTime(Math.max(startDate.getTime(), appState.projectStartDate.getTime()));
  if (endDate.getTime() < startDate.getTime()) {
    endDate.setTime(appState.projectEndDate.getTime());
  }

  const exportedElements = elements;

  const updatePrintHeader = (param: ProjectPrintHeader) => {
    setPrintHeader(param);
  };
  const updatePrintOption = <K extends keyof ProjectPrintOption>(
    key: K,
    value: ProjectPrintOption[K],
  ) => {
    setPrintOption((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const PrintHeader = React.useMemo(() => {
    return (
      <PrintHeaderElement
        printHeader={printHeader}
        onUpdate={updatePrintHeader}
      />
    );
  }, [printHeader]);

  const PrintHeaderPopover = React.useMemo(() => {
    return (
      <PrintHeaderEditPopover
        printHeader={printHeader}
        onUpdate={updatePrintHeader}
        printOption={printOption}
        onUpdatePrintOption={updatePrintOption}
      />
    );
  }, [printHeader, printOption.hasHeader]);

  useEffect(() => {
    const previewNode = canvasRef.current;
    if (!previewNode) {
      return;
    }
    // CHANGED:UPDATE 2024-03-12 #1776
    // const maxWidth = previewNode.offsetWidth;
    // if (!maxWidth) {
    //   return;
    // }
    const gridSize = (appState.gridSize || GRID_SIZE);
    const diffDay = (startDate.getTime() - appState.projectStartDate.getTime()) / (1000 * 3600 * 24);
    const canvasWidth = JOB_ELEMENTS_WIDTH + (((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1) * gridSize + 10);

    const maxWidth = canvasWidth > CANVAS_EXPORT_MAX_WIDTH_OR_HEIGHT ? CANVAS_EXPORT_MAX_WIDTH_OR_HEIGHT : undefined;

    exportToCanvas({
      elements: exportedElements,
      appState,
      files,
      exportPadding,
      maxWidthOrHeight: maxWidth,
      scene: actionManager.app.scene,
      canvasWidth: canvasWidth,
      scrollX: -(diffDay * gridSize),
    })
      .then((canvas) => {
        setRenderError(null);
        // if converting to blob fails, there's some problem that will
        // likely prevent preview and export (e.g. canvas too big)
        // CHANGED:UPDATE 2024-03-12 #1776
        // return canvasToBlob(actionManager.app.canvas).then(() => {
        //   previewNode.replaceChildren(canvas);
        //   setloadingMessage(t("printExportDialog.printLoadingComp"))
        // });
        previewNode.replaceChildren(canvas);
        // setPageStyle(`
        //   @media print {

        //     body { 
        //       -webkit-print-color-adjust: exact; 
        //       overflow: visible !important;
        //     }

        //     @page {
        //       size: auto;
        //       margin: 0;
        //     }

        //     .print-parent {
        //       position: relative;
        //     }

        //     canvas {
        //       width: 100%;
        //       // height:calc(100vh - ${40}px);
        //       object-fit: contain!important;
        //       object-position: left top!important;
        //       position: absolute;
        //       top: 40px;

        //     }

        //   }
        // `);
        setLoadingMessage(t("printExportDialog.printLoadingComp"));
      })
      .catch((error) => {
        console.error(error);
        setRenderError(error);
      });
  }, [appState, files, exportedElements, exportPadding, startDate, endDate]);

  useEffect(() => {
    if (isRangeSelected) {
      const range = ranges[0];
      if (range && range.startDate && range.endDate) {
        setStartDate(range.startDate);
        setEndDate(range.endDate);
        buttonRef.current?.click(); //popoverを強制的に閉じる
      }
      setIsRangeSelected(false);
    }
  }, [isRangeSelected]);

  const onRangeSelect = (props: RangeFocus) => {
    // props:[0,0] → selected calender ranges;
    // props:[0,1] → not selected calender ranges;
    // startとendの選択が完了した時のみ、isRangeSelectedにtrueをセットして、useEffectを間接的に呼び出す
    setIsRangeSelected(isEqual(props, [0, 0]))
  }

  const onChange = React.useCallback(
    (value: [Range]) => {
      setRanges(value);
    },
    [ranges],
  );

  const reactToPrintContent = React.useCallback(() => {
    return previewRef.current;
  }, [previewRef.current]);

  useEffect(() => {
    const printHeaderHeight = printOption.hasHeader ? "40px" : "0px";
    const isReversePrint =
      printOption.fitType === "none" && printOption.fitDirection === "x";
    const printDirection =
      // x方向で複数ページ印刷する場合のみ、縦横を逆転させる
      isReversePrint
        ? printOption.direction === "landscape"
          ? "portrait"
          : "landscape"
        : printOption.direction;

    setPageStyle(`
      @media print {

        body { 
          -webkit-print-color-adjust: exact; 
          overflow: visible !important;
        }

        @page {
          size: ${printDirection};
          margin: 0;
        }

        .print-parent {
          position: relative;
          ${isReversePrint ? "transform: rotate(90deg) translateY(-100vw);" : ""}
          ${isReversePrint ? "transform-origin: left top;" : ""}
        }

       .print-header__wrapper {
          ${isReversePrint ? "width: 100vh;" : "width: 100vw;"}
        }

        .print-canvas {
          width: 0;
          height: 0;
          overflow: visible !important;
        }

        canvas {
          ${isReversePrint ? "width: auto;" : "width: 100vw;"}
          max-width: auto !important;
          ${
            isReversePrint
              ? `height: calc(100vw - ${printHeaderHeight});`
              : printOption.fitType === "contain"
              ? `height: calc(100vh - ${printHeaderHeight});`
              : `position: absolute; \n top: ${printHeaderHeight};`
          }
          ${!isReversePrint ? "object-fit: contain;" : ""}
          ${!isReversePrint ? "object-position: left top;" : ""}
          
        }
      }
    `);
  }, [printOption]);

  const handlePrint = useReactToPrint({
    pageStyle,
    content: reactToPrintContent, // 印刷エリアを指定
    documentTitle: `${appState.projectName} - ${APP_NAME}`,
    removeAfterPrint: false, // 印刷後に印刷用のiframeを削除する
  });

  return (
    <div className="ExportDialog">
      <h2 className="ExportDialog_loading-message">{loadingMessage}</h2>
      <div className="ExportDialog__container">
        <div className="ExportDialog__edit">
          <div className="ExportDialog__daterange">
            <Popover>
              <React.Fragment>
                <label className="task-content__label">選択範囲</label>
                <Popover.Button ref={buttonRef}>
                  <div className="flex items-center gap-[5px] cursor-pointer">
                    <label className="input flex justify-center items-center px-2 py-2 rounded-[4px]">
                      {formatDate(startDate)}
                      <CalenderIcon />
                    </label>
                    <span>〜</span>
                    <label className="input flex justify-center items-center px-2 py-2 rounded-[4px]">
                      {formatDate(endDate)}
                      <CalenderIcon />
                    </label>
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    className={
                      "absolute right-auto top-[70px] left-0 p-[10px] bg-white shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-2px_rgba(0,0,0,0.05)] z-[2]"
                    }
                  >
                    <DateRange
                      showDateDisplay={false}
                      ranges={ranges}
                      onChange={(item) => onChange([item.selection])}
                      onRangeFocusChange={(props) => onRangeSelect(props)}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      minDate={appState.projectStartDate}
                      maxDate={appState.projectEndDate}
                      dateDisplayFormat={"yyyy/MM/dd"}
                      monthDisplayFormat={"yyyy/MM"}
                      locale={ja}
                      direction="horizontal"
                      rangeColors={["#46AADF"]}
                    />
                  </Popover.Panel>
                </Transition>
              </React.Fragment>
            </Popover>
          </div>
          <div className="ExportDialog__header">{PrintHeaderPopover}</div>
          <div>
            <div className="text-[12px]">用紙の向き</div>
            <ToggleButton
              props={{
                value: printOption.direction,
                exclusive: true,
                onChange: (e, v) => {
                  if (v) updatePrintOption("direction", v);
                },
                sx: {
                  height: "42px",
                },
              }}
              value1={{
                icon: <TbFileHorizontal size={18} />,
                props: {
                  value: "landscape",
                  color: "primary",
                  sx: {
                    borderRadius: "6px",
                  },
                },
              }}
              value2={{
                icon: <TbFile size={18} />,
                props: {
                  value: "portrait",
                  color: "primary",
                  sx: {
                    borderRadius: "6px",
                  },
                },
              }}
            />
          </div>
          <div className="ExportDialog__print">
            <RadioButton
              groupProps={{
                defaultValue: printOption.fitType,
                onChange: (e, v) => {
                  if (v !== "contain" && v !== "none") return;
                  updatePrintOption("fitType", v);
                },
                sx: {
                  fontSize: "12px",
                },
              }}
              formControlProps={[
                {
                  label: "1ページに収めて印刷",
                  value: "contain",
                  sx: { marginBottom: "2px" },
                },
                {
                  label: "複数ページで印刷",
                  value: "none",
                  sx: { height: "24px" },
                },
              ]}
            />
            <RadioButton
              groupProps={{
                defaultValue: "x",
                onChange: (e, v) => {
                  if (v !== "x" && v !== "y") return;
                  updatePrintOption("fitDirection", v);
                },
                className: "ExportDialog__print-radio",
                sx: {
                  fontSize: "12px",
                  opacity: printOption.fitType === "none" ? 1 : 0.3,
                  pointerEvents:
                    printOption.fitType === "none" ? "auto" : "none",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                },
              }}
              formControlProps={[
                { label: "横方向", value: "x", sx: { height: "20px" } },
                { label: "縦方向", value: "y", sx: { height: "20px" } },
              ]}
            />
          </div>
        </div>
        <div className="ExportDialog__preview" ref={previewRef}>
          <div className="print-parent">
            {printOption.hasHeader && PrintHeader}
            <div className="print-canvas" ref={canvasRef}>
              {renderError && <ErrorCanvasPreview />}
            </div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(190px, 1fr))",
              // dunno why this is needed, but when the items wrap it creates
              // an overflow
              overflow: "hidden",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: ".6em 0",
          }}
        >
          {!nativeFileSystemSupported &&
            actionManager.renderAction("changeProjectName")}
        </div>
      </div>
      <Stack.Row gap={2} justifyContent="center" style={{ margin: "2em 0" }}>
        <ExportButton
          color="indigo"
          title={t("buttons.printImage")}
          aria-label={t("buttons.printImage")}
          onClick={() => handlePrint()}
        >
          印刷
        </ExportButton>
      </Stack.Row>
    </div>
  );
};

export const PrintExportDialog = ({
  elements,
  appState,
  setAppState,
  files,
  exportPadding = DEFAULT_EXPORT_PADDING,
  actionManager,
}: {
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  files: BinaryFiles;
  exportPadding?: number;
  actionManager: ActionManager;
}) => {
  const handleClose = React.useCallback(() => {
    setAppState({ openDialog: null });
  }, [setAppState]);

  return (
    <>
      {appState.openDialog === "printExport" && (
        <Dialog onCloseRequest={handleClose} title={t("printExportDialog.title")}>
          <PrintExportModal
            elements={elements}
            appState={appState}
            files={files}
            exportPadding={exportPadding}
            actionManager={actionManager}
            onCloseRequest={handleClose}
          />
        </Dialog>
      )}
    </>
  );
};
