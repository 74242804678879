import { Timestamp } from "firebase/firestore";
import { Tags } from "./Tag";

export default interface Resource {
  iconImageUrl: string | null,
  id: string;
  index: number;
  name: string;
  tags: Tags;
  memo: string;
  isActive: boolean;
  isDeleted: boolean;
  createdBy: string;
  createdAt: Date | null;
}

export interface Image {
  url: string;
  file: File | null;
}

export type ResourceForm = Omit<Resource, "iconImageUrl"> & {
  iconImageFile: Image;
};

export interface ResourceResponse extends Omit<Resource, "createdAt"> {
  createdAt: Timestamp | null;
}

export const ResourceDocumentFields = {
  iconImageUrl: "iconImageUrl",
  id: "id",
  index: "index",
  name: "name",
  tags: "tags",
  memo: "memo",
  isActive: "isActive",
  isDeleted: "isDeleted",
  createdBy: "createdBy",
  createdAt: "createdAt"
} as const;

export type ResourceDocumentFields = typeof ResourceDocumentFields[keyof typeof ResourceDocumentFields];

export interface ProjectResources {
  [resourceId: string]: boolean;
}