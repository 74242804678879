import React, { useMemo } from "react";

const DelayBubble = ({
  className,
  width,
  thickness,
}: {
  className: string;
  width: number;
  thickness: number;
}) => {
  const icon = useMemo(
    () => (
      <div
        className={className}
        style={{
          width: "180px",
          position: "absolute",
          top: -40,
          left: `${-180 + 20 + width / 2}px`, // 吹き出しの表示位置（-180は吹き出し幅、20は矢印の位置や！の位置などで微調整、そしてbarの中心に表示するために / 2 している）
          display: "flex",
          justifyContent: "center",
        }}
      >
        期限を過ぎています
      </div>
    ),
    [className, width, thickness],
  );

  return icon;
};

export default DelayBubble;
