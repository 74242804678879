import React, { useCallback, useState } from "react";
import { t } from "src/excalidraw/i18n";
import { AppState, LibraryItem, LibraryItems } from "src/excalidraw/types";
import {
  DotsIcon,
  EditIcon,
  TrashIcon,
} from "src/excalidraw/components/icons";
import { atom, useAtom } from "jotai";
import { jotaiScope } from "src/excalidraw/jotai";
import ConfirmDialog from "src/excalidraw/components/ConfirmDialog";

import DropdownMenu from "src/excalidraw/components/dropdownMenu/DropdownMenu";
import { LibraryItemsData } from "./LibraryMenuEx";
import EditLibrary from "./EditLibrary";

export const isLibraryMenuOpenAtom = atom(false);

const getSelectedItems = (
  libraryItems: LibraryItems,
  selectedItems: LibraryItem["id"][],
) => libraryItems.filter((item) => selectedItems.includes(item.id));

export const LibraryMenuHeaderEx: React.FC<{
  setAppState: React.Component<any, AppState>["setState"];
  selectedItems: LibraryItem["id"][];
  onRemoveFromLibrary: () => void;
  onUpdateFromLibrary: () => void;
  onSelectItems: (items: LibraryItem["id"][]) => void;
  appState: AppState;
  libraryItemsData: LibraryItemsData;
}> = ({
  setAppState,
  selectedItems,
  onRemoveFromLibrary,
  onUpdateFromLibrary,
  onSelectItems,
  appState,
  libraryItemsData,
}) => {
    const [isLibraryMenuOpen, setIsLibraryMenuOpen] = useAtom(
      isLibraryMenuOpenAtom,
      jotaiScope,
    );
    const renderRemoveLibAlert = useCallback(() => {
      const content = t("alerts.removeItemsFromsLibrary", { count: selectedItems.length });
      const title = t("confirmDialog.removeItemsFromLib");
      return (
        <ConfirmDialog
          onConfirm={() => {
            onRemoveFromLibrary();
            setShowRemoveLibAlert(false);
          }}
          onCancel={() => {
            setShowRemoveLibAlert(false);
          }}
          title={title}
        >
          <p>{content}</p>
        </ConfirmDialog>
      );
    }, [selectedItems, onRemoveFromLibrary]);

    const [showRemoveLibAlert, setShowRemoveLibAlert] = useState(false);
    const [showEditLibrary, setShowEditLibrary] = useState(false);

    const itemsSelected = !!selectedItems.length;
    const items = itemsSelected
      ? libraryItemsData.libraryItems.filter((item) =>
        selectedItems.includes(item.id),
      )
      : libraryItemsData.libraryItems;

    // const [showPublishLibraryDialog, setShowPublishLibraryDialog] =
    //   useState(false);
    // const [publishLibSuccess, setPublishLibSuccess] = useState<null | {
    //   url: string;
    //   authorName: string;
    // }>(null);
    // const renderPublishSuccess = useCallback(() => {
    //   return (
    //     <Dialog
    //       onCloseRequest={() => setPublishLibSuccess(null)}
    //       title={t("publishSuccessDialog.title")}
    //       className="publish-library-success"
    //       small={true}
    //     >
    //       <p>
    //         {t("publishSuccessDialog.content", {
    //           authorName: publishLibSuccess!.authorName,
    //         })}{" "}
    //         <a
    //           href={publishLibSuccess?.url}
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           {t("publishSuccessDialog.link")}
    //         </a>
    //       </p>
    //       <ToolButton
    //         type="button"
    //         title={t("buttons.close")}
    //         aria-label={t("buttons.close")}
    //         label={t("buttons.close")}
    //         onClick={() => setPublishLibSuccess(null)}
    //         data-testid="publish-library-success-close"
    //         className="publish-library-success-close"
    //       />
    //     </Dialog>
    //   );
    // }, [setPublishLibSuccess, publishLibSuccess]);

    // const onPublishLibSuccess = useCallback(
    //   (data: { url: string; authorName: string }, libraryItems: LibraryItems) => {
    //     setShowPublishLibraryDialog(false);
    //     setPublishLibSuccess({ url: data.url, authorName: data.authorName });
    //     const nextLibItems = libraryItems.slice();
    //     nextLibItems.forEach((libItem) => {
    //       if (selectedItems.includes(libItem.id)) {
    //         libItem.status = "published";
    //       }
    //     });
    //     library.setLibrary(nextLibItems);
    //   },
    //   [setShowPublishLibraryDialog, setPublishLibSuccess, selectedItems, library],
    // );

    // const onLibraryImport = async () => {
    //   try {
    //     await library.updateLibrary({
    //       libraryItems: fileOpen({
    //         description: "Excalidraw library files",
    //         // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    //         // gets resolved. Else, iOS users cannot open `.excalidraw` files.
    //         /*
    //           extensions: [".json", ".excalidrawlib"],
    //           */
    //       }),
    //       merge: true,
    //       openLibraryMenu: true,
    //     });
    //   } catch (error: any) {
    //     if (error?.name === "AbortError") {
    //       console.warn(error);
    //       return;
    //     }
    //     setAppState({ errorMessage: t("errors.importLibraryError") });
    //   }
    // };

    // const onLibraryExport = async () => {
    //   const libraryItems = itemsSelected
    //     ? items
    //     : await library.getLatestLibrary();
    //   saveLibraryAsJSON(libraryItems)
    //     .catch(muteFSAbortError)
    //     .catch((error) => {
    //       setAppState({ errorMessage: error.message });
    //     });
    // };

    const renderLibraryMenu = () => {
      return (
        <DropdownMenu open={isLibraryMenuOpen}>
          <DropdownMenu.Trigger
            className="Sidebar__dropdown-btn"
            onToggle={() => setIsLibraryMenuOpen(!isLibraryMenuOpen)}
            title={t("labels.personalLib")}
          >
            {DotsIcon}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            onClickOutside={() => setIsLibraryMenuOpen(false)}
            onSelect={() => setIsLibraryMenuOpen(false)}
            className="library-menu"
          >
            {/* {!itemsSelected && (
            <DropdownMenu.Item
              onSelect={onLibraryImport}
              icon={LoadIcon}
              data-testid="lib-dropdown--load"
            >
              {t("buttons.load")}
            </DropdownMenu.Item>
          )}
          {!!items.length && (
            <DropdownMenu.Item
              onSelect={onLibraryExport}
              icon={ExportIcon}
              data-testid="lib-dropdown--export"
            >
              {t("buttons.export")}
            </DropdownMenu.Item>
          )} */}
            {!!items.length && (
              <DropdownMenu.Item
                onSelect={() => setShowEditLibrary(true)}
                disabled={!itemsSelected}
                icon={EditIcon}
              >
                {t("buttons.edit")}
              </DropdownMenu.Item>
            )}
            {!!items.length && (
              <DropdownMenu.Item
                onSelect={() => setShowRemoveLibAlert(true)}
                disabled={!itemsSelected}
                icon={TrashIcon}
              >
                {t("buttons.remove")}
              </DropdownMenu.Item>
            )}
            {/* CHANGED:REMOVE 2022-10-19 #26
          {itemsSelected && (
            <DropdownMenu.Item
              icon={publishIcon}
              onSelect={() => setShowPublishLibraryDialog(true)}
              data-testid="lib-dropdown--remove"
            >
              {t("buttons.publishLibrary")}
            </DropdownMenu.Item>
          )*/}
          </DropdownMenu.Content>
        </DropdownMenu>
      );
    };
    return (
      <div style={{ position: "relative" }}>
        {libraryItemsData.libraryItems.length > 0 && renderLibraryMenu()}
        {selectedItems.length > 0 && (
          <div className="library-actions-counter">{selectedItems.length}</div>
        )}
        {showRemoveLibAlert && renderRemoveLibAlert()}
        {showEditLibrary &&
          <EditLibrary
            onConfirm={() => {
              onUpdateFromLibrary();
              setShowEditLibrary(false);
            }}
            onCancel={() => {
              setShowEditLibrary(false);
            }}
            libItem={libraryItemsData.libraryItems.filter((item) =>
              selectedItems.includes(item.id),
            )[0]}
            appState={appState}
          />
        }
        {/* {showPublishLibraryDialog && (
        <PublishLibrary
          onClose={() => setShowPublishLibraryDialog(false)}
          libraryItems={getSelectedItems(
            libraryItemsData.libraryItems,
            selectedItems,
          )}
          appState={appState}
          onSuccess={(data) =>
            onPublishLibSuccess(data, libraryItemsData.libraryItems)
          }
          onError={(error) => window.alert(error)}
          updateItemsInStorage={() =>
            library.setLibrary(libraryItemsData.libraryItems)
          }
          onRemove={(id: string) =>
            onSelectItems(selectedItems.filter((_id) => _id !== id))
          }
        />
      )}
      {publishLibSuccess && renderPublishSuccess()} */}
      </div>
    );
  };
