import { ExcalidrawRectangleElement } from "../../../element/types";
import { newCalendarBackgroundElement } from "../../element"; // from extensions
import { ExcalidrawMilestoneElement } from "../../element/types"; // from extensions
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx"; // from extensions
import { CANVAS_HEADER_HEIGHT, PRIORITY } from "src/excalidraw/constants";

// CHANGED:ADD 2023-2-11 #671
export const generateMilestoneLineElements = (
  milestoneElements: ExcalidrawMilestoneElement[],
  jobsHeight: number,
): ExcalidrawRectangleElement[] => {
  //milestone vertical line
  return milestoneElements.map((element) => {
    return newCalendarBackgroundElement({
      x: element.x + element.width / 2,
      y: CANVAS_HEADER_HEIGHT,
      strokeColor: ColorsEx.lineColor.milestone,
      fillStyle: "solid",
      strokeWidth: 1,
      width: 1,
      height: jobsHeight,
      boundElements: [{ type: "milestone", id: element.id }],
      locked: true,
      priority: PRIORITY.milestone,
    });
  });
};
