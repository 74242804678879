import "./UserList.scss";

import React from "react";
import clsx from "clsx";
import { Collaborator, SocketId } from "../types";
import { Tooltip } from "./Tooltip";
import { useExcalidrawActionManager } from "./App";

import { isShallowEqual } from "../utils";

/** collaborator user id or socket id (fallback) */
type ClientId = string & { _brand: "UserId" };

type UserListUserObject = Pick<
  Collaborator,
  "avatarUrl" | "id" | "socketId" | "username"
>;

type UserListProps = {
  className?: string;
  mobile?: boolean;
  collaborators: Map<SocketId, UserListUserObject>;
};

export const UserList = React.memo(
  ({ className, mobile, collaborators }: UserListProps) => {
    const actionManager = useExcalidrawActionManager();

    const uniqueCollaboratorsMap = new Map<ClientId, Collaborator>();

    collaborators.forEach((collaborator, socketId) => {
      const userId = (collaborator.id || socketId) as ClientId;
      uniqueCollaboratorsMap.set(
        // filter on user id, else fall back on unique socketId
        userId,
        { ...collaborator, socketId },
      );
    });

    const uniqueCollaboratorsArray = Array.from(uniqueCollaboratorsMap).filter(
      ([_, collaborator]) => collaborator.username?.trim(),
    );

    if (uniqueCollaboratorsArray.length === 0) {
      return null;
    }

    const avatars =
      uniqueCollaboratorsArray
        .map(([clientId, collaborator]) => {
          const avatarJSX = actionManager.renderAction("goToCollaborator", [
            clientId,
            collaborator,
          ]);

          return (
            <Tooltip
              label={collaborator.username || "Unknown user"}
              key={clientId}
            >
              {avatarJSX}
            </Tooltip>
          );
        });

    return (
      <div className={clsx("UserList", className, { UserList_mobile: mobile })}>
        {avatars}
      </div>
    );
  },
  (prev, next) => {
    if (
      prev.collaborators.size !== next.collaborators.size ||
      prev.mobile !== next.mobile ||
      prev.className !== next.className
    ) {
      return false;
    }

    for (const [socketId, collaborator] of prev.collaborators) {
      const nextCollaborator = next.collaborators.get(socketId);
      if (
        !nextCollaborator ||
        !isShallowEqual(
          collaborator,
          nextCollaborator,
          // collaboratorComparatorKeys,
        )
      ) {
        return false;
      }
    }
    return true;
  },
);
