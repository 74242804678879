import { DateObject } from "react-multi-date-picker";

export default {
  name: "gregorian_jp",
  months: [
    ["1月", "1月"],
    ["2月", "2月"],
    ["3月", "3月"],
    ["4月", "4月"],
    ["5月", "5月"],
    ["6月", "6月"],
    ["7月", "7月"],
    ["8月", "8月"],
    ["9月", "9月"],
    ["10月", "10月"],
    ["11月", "11月"],
    ["12月", "12月"]
  ],
  weekDays: [
    ["日", "日"],
    ["月", "月"],
    ["火", "火"],
    ["水", "水"],
    ["木", "木"],
    ["金", "金"],
    ["土", "土"]
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
} as DateObject["locale"];