import clsx from "clsx";
import { useContext } from "react";
import { t } from "src/excalidraw/i18n";
import { useDevice } from "../../../components/App";
import { SidebarExPropsContext } from "./common";
import { LibraryIcon, PinIcon } from "../../../components/icons";
import { withUpstreamOverride } from "../../../components/hoc/withUpstreamOverride";
import { Tooltip } from "../../../components/Tooltip";

export const SidebarDockButton = (props: {
  checked: boolean;
  onChange?(): void;
}) => {
  return (
    <div className="layer-ui__sidebar-dock-button" data-testid="sidebar-dock">
      <Tooltip label={t("labels.sidebarLock")}>
        <label
          className={clsx(
            "ToolIcon ToolIcon__lock ToolIcon_type_floating",
            `ToolIcon_size_medium`,
          )}
        >
          <input
            className="ToolIcon_type_checkbox"
            type="checkbox"
            onChange={props.onChange}
            checked={props.checked}
            aria-label={t("labels.sidebarLock")}
          />{" "}
          <div
            className={clsx("SidebarEx__pin-btn", {
              "SidebarEx__pin-btn--pinned": props.checked,
            })}
            tabIndex={0}
          >
            {PinIcon}
          </div>{" "}
        </label>{" "}
      </Tooltip>
    </div>
  );
};

const _SidebarHeader: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  const device = useDevice();
  const props = useContext(SidebarExPropsContext);

  const renderDockButton = !!(device.canDeviceFitSidebar && props.dockable);
  const renderLibraryButton = !!props.onLibrary;

  return (
    <div
      className={clsx("layer-ui__sidebar__header", className)}
      data-testid="sidebar-header"
    >
      {children}
      {(renderDockButton || renderLibraryButton) && (
        <div className="layer-ui__sidebar__header__buttons">
          {/* CHANGED:REMOVE 2023/09/06 #1017 */}
          {/* {renderDockButton && (
            <SidebarDockButton
              checked={!!props.docked}
              onChange={() => {
                props.onDock?.(!props.docked);
              }}
            />
          )} */}
          {renderLibraryButton && (
            <>
              <div />
              <label
                className={clsx(
                  "ToolIcon",
                  `ToolIcon_Library`,
                )}
              >
                <input
                  className="ToolIcon_type_checkbox"
                  type="checkbox"
                  onChange={props.onLibrary}
                  aria-label={t("labels.openLibrary")}
                />
                <div className="ToolIcon__icon"> {LibraryIcon}</div>
              </label>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const [Context, Component] = withUpstreamOverride(_SidebarHeader);

/** @private */
export const SidebarExHeaderComponents = { Context, Component };
