
export function isLocal() {
  return import.meta.env.VITE_APP_ENV === "local";
}

export function isDevelopmentEnv() {
  return import.meta.env.NODE_ENV === "development";
};

export function isTestEnv() {
  return import.meta.env.NODE_ENV === "test";
};

export function isDevelopment() {
  return !isProductionEnv();
};

export function isProductionEnv() {
  return import.meta.env.NODE_ENV === "production";
};