import React, { useMemo } from "react";
import { ActionManager } from "src/excalidraw/actions/manager";
import Scene from "src/excalidraw/scene/Scene";
import { AppState } from "src/excalidraw/types";
import "./JobLayerUI.scss"; // CHANGED:ADD 2023/02/03 #556
import Calendar from "src/excalidraw/extensions/calendar";
import Scroll from "src/excalidraw/extensions/scene/scroll";
import { CANVAS_HEADER_HEIGHT } from "src/excalidraw/constants";

interface Props {
  actionManager: ActionManager;
  appState: AppState;
  scene: Scene;
  calendar: Calendar;
  scroll: Scroll;
  setAppState: React.Component<any, AppState>["setState"];
}

/**
 * Job elementの追加:
 *  - Job Elementの追加（左側の四角とカレンダー上の横線）
 *  - calendar (Rectangle element)のheight調節
 * 
 * @param appState AppState
 * @param setAppState React.Component<any, AppState>["setState"]
 * @param scene Scene
 * @param calendar Calendar
 * @returns
 */
const JobLayerUI = ({ actionManager, appState }: Props) => {
  const ZoomInIconWidth = 14; //CHANGED:ADD 2023/09/02 #979 14という数値は「+」アイコンの幅分
  const renderAction = useMemo(
    () => actionManager.renderAction("addJobRow"),
    [actionManager],
  );

  return (
    <>
      {!appState.isLoading && !appState.viewModeEnabled && (
        <div
          className="job_button__wrapper"
          style={{
            "--padding": `${
              (appState.jobsHeight + CANVAS_HEADER_HEIGHT + appState.scrollY) *
              appState.zoom.value
            }px`,
            transformOrigin: "left top",
            transform: `scale(${appState.zoom.value})`,
            display: appState.isLoading ? "none" : "block", //CHANGED:ADD 2023/02/09 #621
            // paddingLeft: (JOB_ELEMENTS_WIDTH / 2 - ZoomInIconWidth) * appState.zoom.value, //CHANGED:ADD 2023/09/02 #979 > #994
          }}
        >
          {renderAction}
        </div>
      )}
    </>
  );
};

//CHANGED:ADD 2023/03/25 #708 Improving Performance
function areEqual(prevProps: Props, nextProps: Props) {
  if (
    prevProps.appState.jobsHeight !== nextProps.appState.jobsHeight ||
    prevProps.appState.scrollY !== nextProps.appState.scrollY ||
    prevProps.appState.zoom.value !== nextProps.appState.zoom.value ||
    prevProps.appState.isLoading !== nextProps.appState.isLoading ||
    prevProps.appState.viewModeEnabled !== nextProps.appState.viewModeEnabled
  ) {
    return false;
  }
  return true;
}

export default React.memo(JobLayerUI, areEqual);
