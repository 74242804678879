import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioProps,
} from "@mui/material";
import { RadioGroupProps } from "@mui/material/RadioGroup";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: { main: "#46AADF" },
  },
});
const RadioButton = ({
  theme = defaultTheme,
  groupProps,
  formControlProps,
  size = "small",
}: {
  groupProps?: RadioGroupProps;
  theme?: Theme;
  formControlProps: Omit<FormControlLabelProps, "control">[];
  size?: RadioProps["size"];
}) => {
  if (formControlProps.length === 0) return <></>;
  return (
    <ThemeProvider theme={theme}>
      <RadioGroup {...groupProps}>
        {formControlProps.map((props, i) => {
          return (
            <FormControlLabel
              {...props}
              key={i}
              control={<Radio className={props.className} size={size} />}
            />
          );
        })}
      </RadioGroup>
    </ThemeProvider>
  );
};
export default RadioButton;
