import { register } from "src/excalidraw/actions/register";
import { ExcalidrawCommentElement, ExcalidrawCommentableElement } from "../element/types";
import { isCommentElement, isCommentableElement } from "../element/typeChecks";
import CommentPopover from "../components/CommentPopover";
import { sceneCoordsToViewportCoords } from "src/excalidraw/utils";
import { CANVAS_HEADER_HEIGHT, GRID_SIZE, PRIORITY, TOOL_BAR_WIDTH } from "src/excalidraw/constants";
import { newCommentElement } from "../element/newElement";
import CommentListPopover from "../components/CommentListPopover";
import { newElementWith } from "../../element/mutateElement";
import { getSelectedElements } from "src/excalidraw/scene/selection";


export const actionAddComment = register({
  name: "addComment",
  viewMode: true,
  contextItemLabel: "labels.addComment",
  trackEvent: { category: "menu" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1 && isCommentableElement(selectedElements[0]);
  },
  perform: (_elements, appState, value, { focusContainer }) => {
    if (appState.openDialog === "addComment") {
      focusContainer();
    }

    if (value) {
      const { rootCommentId, commentableElement } = value as {
        rootCommentId: string,
        commentableElement: ExcalidrawCommentableElement,
      };

      const existingCommentOnElement = _elements.find((el) => 
        isCommentElement(el) &&
        el.commentElementId === commentableElement.id &&
        !el.isDeleted
      );

      const commentElement = newCommentElement({
        id: rootCommentId,
        x: commentableElement.x + commentableElement.width - 12,
        y: commentableElement.y + 12,
        strokeColor: appState.currentItemStrokeColor,
        strokeWidth: appState.currentItemStrokeWidth,
        width: GRID_SIZE,
        height: GRID_SIZE,
        priority: PRIORITY.comment,
        commentElementId: commentableElement.id,
        isVisible: existingCommentOnElement ? false : true,
        layer: commentableElement.layer, // CHANGED:ADD 2024-10-7 #2114
      });
      _elements = _elements.concat(commentElement);
    }
    return {
      appState: {
        ...appState,
        openDialog: appState.openDialog === "addComment" ? null : "addComment",
      },
      elements: _elements,
      commitToHistory: false,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {

    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    })

    let element: ExcalidrawCommentableElement | null = null;

    if (selectedElements.length <= 2) {
      element = selectedElements.find((el) =>
        isCommentableElement(el),
      ) as ExcalidrawCommentableElement | null;
    }

    if (!element) return null;

    const sceneX = element.x - TOOL_BAR_WIDTH;
    const sceneY = element.y + element.height - CANVAS_HEADER_HEIGHT + GRID_SIZE;

    const { x: left, y: top } = sceneCoordsToViewportCoords({
      sceneX,
      sceneY
    }, appState)
    
    return (
      <CommentPopover 
        appState={appState}
        element={element}
        top={top}
        left={left}
        updateData={updateData}
      />
    )
  },
});


export const actionListComments = register({
  name: "listComments",
  viewMode: true,
  trackEvent: { category: "canvas" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1 && isCommentableElement(selectedElements[0]);
  },
  perform: (_elements, appState, value, { focusContainer }) => {
    if (appState.openDialog === "listComments") {
      focusContainer();
    }
    if (value) {
      const { commentableElement } = value as {
        commentableElement: ExcalidrawCommentableElement,
      };

      _elements = _elements.map((el) => {
        if (isCommentElement(el) && el.commentElementId === commentableElement.id) {
          return newElementWith(el, { isDeleted: true });
        }
        return el;
      });
    }

    return {
      appState: {
        ...appState,
        openDialog: appState.openDialog === "listComments" ? null : "listComments",
      },
      elements: _elements,
      commitToHistory: false,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {

    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    })

    let commentElement: ExcalidrawCommentElement | null = null;


    if (selectedElements.length <= 2) {
      commentElement = selectedElements.find((el) =>
        isCommentElement(el),
      ) as ExcalidrawCommentElement | null;
    }
    
    if (!commentElement) return null;

    const commentableElement = elements.find((el) => el.id === commentElement!.commentElementId) as ExcalidrawCommentableElement | undefined;

    if (!commentableElement) return null;

    const sceneX = commentElement.x - TOOL_BAR_WIDTH;
    const sceneY = commentElement.y + commentElement.height - CANVAS_HEADER_HEIGHT + GRID_SIZE;

    const { x: left, y: top } = sceneCoordsToViewportCoords({
      sceneX,
      sceneY
    }, appState)
    
    return (
      <CommentListPopover 
        appState={appState}
        commentElement={commentElement}
        commentableElement={commentableElement}
        top={top}
        left={left}
        updateData={updateData}
      />
    )
  },
});


export interface CommentActionValue {
  type: "CREATE" | "DELETE" | "CLOSE",
  commentableElement: ExcalidrawCommentableElement,
  commentId?: string,
  isClosed?: boolean,
};

export const actionSendComment = register({
  name: "sendComment",
  viewMode: true,
  contextItemLabel: "labels.sendComment",
  trackEvent: { category: "menu" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1 && isCommentableElement(selectedElements[0]);
  },
  perform: (_elements, appState, _value) => {

    const value = _value as CommentActionValue;
    if (value) {
      if (value.type === "CREATE") {
        if (!value.commentableElement || !value.commentId) return false;
        const commentableElement = value.commentableElement as ExcalidrawCommentableElement;
  
        const existingCommentOnElement = _elements.find((el) => 
          isCommentElement(el) &&
          el.commentElementId === value.commentableElement.id &&
          !el.isDeleted
        );
        // コメントエレメントが存在しない場合のみコメントエレメントを作成し、canvasに表示
        if (!existingCommentOnElement) {
          const commentElement = newCommentElement({
            id: value.commentId,
            x: commentableElement.x + commentableElement.width - 12,
            y: commentableElement.y + 12,
            strokeColor: appState.currentItemStrokeColor,
            strokeWidth: appState.currentItemStrokeWidth,
            width: GRID_SIZE,
            height: GRID_SIZE,
            priority: PRIORITY.comment,
            commentElementId: commentableElement.id,
            isVisible: true,
            isClosed: commentableElement.isClosed,
            layer: commentableElement.layer, // CHANGED:ADD 2024-10-7 #2114
          });
          _elements = _elements.concat(commentElement);
        }
  
        return {
          elements: _elements,
          commitToHistory: false,
        };
      } else if (value.type === "DELETE") {
        if (!value.commentableElement) return false;
        const commentableElement = value.commentableElement as ExcalidrawCommentableElement;
  
        _elements = _elements.map((el) => {
          if (isCommentElement(el) && el.commentElementId === commentableElement.id) {
            return newElementWith(el, { isDeleted: true });
          }
          return el;
        });
  
        return {
          elements: _elements,
          commitToHistory: false,
        };
      }
    }

    return {
      appState: {
        ...appState,
        openSidebar: appState.openSidebar === "comments" ? null : "comments",
      },
      elements: _elements,
      commitToHistory: false,
    };
  },
});