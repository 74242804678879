import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import Context from '../../context'
import './index.scss'
import { Tab } from '@headlessui/react'

const TimeAxisScaleSelect: React.FC = () => {
  const { store, prefixCls } = useContext(Context);
  const { viewTypeList, tableWidth } = store;
  const handleSelect = useCallback(
    (index: number) => {
      store.switchSight(viewTypeList[index].type);
    },
    [store]
  );

  return (
    <div className={`${prefixCls}-time-axis-scale-select`} style={{ left: tableWidth }}>
      <Tab.Group
        onChange={(index) => handleSelect(index)}
        selectedIndex={viewTypeList.findIndex((t) => t.selected)}
      >
        <Tab.List className="flex space-x-1 rounded-full border bg-white p-1.5 h-[45px]">
          {viewTypeList.map((unit) => (
            <Tab
              key={unit.value}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-full text-sm leading-5 align-middle',
                  'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-[#E3F2FA]'
                    : 'hover:bg-white'
                )
              }
            >
              {unit.label}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

export default observer(TimeAxisScaleSelect);
