import { t } from "src/excalidraw/i18n";
import "./DeleteProjectDialog.scss";
import { AppState } from "src/excalidraw/types";
import { Dialog } from "src/excalidraw/components/Dialog";
import React, { useState } from "react";
import DialogActionButton from "./DialogActionButton";
import Checkbox from "src/conpath/components/CheckBox";
import Colors from "src/conpath/constants/Colors";

const DeleteProjectDialog = ({
  appState,
  setAppState,
  onDeleteProject,
}: {
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onDeleteProject?: () => void | Promise<any>;
}) => {
  type ItemData = {
    id: string;
    label: string;
  };

  const [checkedValues, setCheckedValues] = useState<ItemData[]>([]);
  const handleClose = React.useCallback(() => {
    setAppState({ openDialog: null });
  }, [setAppState]);

  const items: ItemData[] = [
    { id: "1", label: "自分だけではなく、メンバー全員のプロジェクト一覧から削除されます。" },
    { id: "2", label: "すべてのデータが削除されます。" },
    { id: "3", label: "削除されたデータは二度と元に戻すことはできません。" },
  ];

  const handleChange = React.useCallback((item: ItemData) => {
    const values = [...checkedValues];
    values.filter(v => v.id === item.id).length > 0
      ? values.splice(checkedValues.findIndex(v => v.id === item.id), 1)
      : values.push(item);

    setCheckedValues(values);
  }, [checkedValues]);

  return (
    <>
      <Dialog
        onCloseRequest={handleClose}
        title={t("projectDeleteDialog.title")}
        small={true}
        className={`confirm-dialog DeleteProjectDialog`}
      >
        <label className="delete-project__label">
          {`${t("alerts.deleteProject")}`}
        </label>
        <div className="delete-project__checkList">
          {items.map((item) => {
            return (
              <label key={item.id}>
                <Checkbox
                  props={{
                    onClick: () => handleChange(item),
                    checked: checkedValues.filter(e => e.id === item.id).length > 0,
                    value: item.id,
                    className: "delete-project__checkBox",
                    sx: {
                      color: "#DDDDDD",
                      backgroundImage: "linear-gradient(to right, white, white)",
                      borderRadius: 0,
                      height: 18,
                      width: 18,
                      '&.Mui-checked': {
                        color: Colors.danger,
                      },
                    },
                  }}
                />
                <span className="delete-project__checkLabel">{item.label}</span>
              </label>
            );
          })}
        </div>
        <div className="confirm-dialog-buttons mt-10">
          <DialogActionButton
            className="button modal-cancel"
            label={t("buttons.cancel")}
            onClick={() => {
              handleClose();
            }}
          />
          <DialogActionButton
            className="button cancel font-bold"
            label={t("buttons.delete")}
            disabled={checkedValues.length !== items.length}
            onClick={() => {
              handleClose();
              onDeleteProject?.();
            }}
            actionType="danger"
          />
        </div>
      </Dialog>
    </>
  );
};

export default DeleteProjectDialog;
