import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Flip, ToastContainer, toast } from 'react-toastify';
// import { nanoid } from 'nanoid';

import { RotatingLines } from "react-loader-spinner";

//components
import { Button } from "src/conpath/components/Button";
import { AddUserIcon, TrashIcon } from "src/conpath/components/icons";
import Checkbox from "src/conpath/components/CheckBox";
import { Menu } from "@headlessui/react";
import { RadioGroup, Transition } from '@headlessui/react';
import SettingsLayout from "src/conpath/components/settings/SettingLayout";

//constants
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import Colors from "src/conpath/constants/Colors";
import { OrganizationRole } from "src/conpath/constants/Role";

//interfaces
import { TeamInputForm } from "src/conpath/interfaces/Team";

//models

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import { useNavigate, useParams } from "react-router-dom";

//styles
import "./EditTeam.scss";
import { Paths } from "src/conpath/constants/Routes";

interface SelectingUser {
  id: string;
  email: string;
  username: string;
  profileImageUrl: string;
  role: OrganizationRole;
  isSelected: boolean;
  isJoined: boolean;
};

const EditTeam: React.FC = observer(() => {
  const navigate = useNavigate();
  const { teamId } = useParams();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [teamState, setTeamState] = useState<TeamInputForm>({
    id: "",
    name: "",
    userIds: [],
    createdBy: "",
    createdAt: new Date(),
  });
  const [errorText, setErrorText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [filteredUsers, setFilteredUsers] = useState<SelectingUser[]>([]);
  const [isUserSelectionPanelOpen, setIsUserSelectionPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (teamId && selectedOrganization) {
      const _team = selectedOrganization.teams.find((team) => team.id === teamId);
      if (!_team) return;

      setTeamState({
        id: _team.id,
        name: _team.name,
        userIds: _team.userIds,
        createdBy: _team.createdBy,
        createdAt: _team.createdAt,
      });
      setIsLoading(false);
    }
  }, [teamId, selectedOrganization]);

  useEffect(() => {
    setFilteredUsers(
      selectedOrganization?.getJoinedUsers()
        .filter(user => !teamState.userIds.some((userId) => userId === user.id))
        .map((user) => {
          return {
            id: user.id,
            email: user.email,
            username: user.username,
            role: user.role,
            profileImageUrl: user.profileImageUrl,
            isSelected: false,
            isJoined: user.isJoined(),
          } as SelectingUser
        }) || []
    )
  }, [selectedOrganization, teamState.userIds]);

  const onChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      if (name) {
        setTeamState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setErrorText("");
    },
    [setTeamState],
  );

  const onChangeAssignedUsers = useCallback(
    (userIds: string[]) => {
      setTeamState((prev) => ({
        ...prev,
        userIds: userIds,
      }));
      setErrorText("");
    },
    [setTeamState],
  );

  const teamUsers = useMemo(() => {
    return selectedOrganization?.getJoinedUsers()
      .filter((user) => teamState.userIds.includes(user.id)) || [];
  }, [selectedOrganization, teamState.userIds]);

  const onCancel = useCallback(() => {
    navigate(`${Paths.settings}${Paths.teams}`, { replace: true });
  }, [navigate]);

  const removeAssignedUser = (id: string) => {
    const newAssignUserIds = teamState.userIds.filter((userId) => userId !== id);
    onChangeAssignedUsers(newAssignUserIds);
  };

  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText, setErrorText]);

  const onSelectUserButtonPressed = useCallback(() => {
    if (isUserSelectionPanelOpen) {
      // Clear all selected users
      setFilteredUsers((prev) => (prev.map((user) => {
        return {
          ...user,
          isSelected: false
        }
      })));
    }

    setIsUserSelectionPanelOpen((prev) => !prev);
  }, [isUserSelectionPanelOpen]);

  const onAddMemberButtonPressed = useCallback(() => {
    const users = [...teamState.userIds];
    filteredUsers.forEach((user) => {
      if (user.isSelected) {
        users.push(user.id);
      }
    });
    onChangeAssignedUsers(users);
    setIsUserSelectionPanelOpen(false);
  }, [teamState.userIds, filteredUsers]);

  const onSubmit = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;
    setIsProcessing(true);
    const result = await selectedOrganization.updateTeam(teamState);
    if (result.error) {
      setErrorText(result.error);
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      navigate(`${Paths.settings}${Paths.teams}`, { state: { message: "チームを更新しました!" } });
    }
  }, [teamState, navigate, setErrorText, selectedOrganization, loginUser]);

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle='チーム編集'
        currentPath={Paths.teams}>
        <div className="team-create-container">
          <div className="inner-container">
            <div className="team-form-container">
              <ToastContainer
                position="top-center"
                transition={Flip}
                autoClose={TOAST_DEFAULT_DURATION}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {/* チーム名 */}
              <div className="form-section">
                <div className="v-stack">
                  <div className="team-organization">
                    <p className="team-organization__label">チーム名</p>
                    <p className="team__must">必須</p>
                  </div>
                  <div className="h-stack">
                    <input
                      name="name"
                      className="team-input"
                      placeholder="チーム名を入力する"
                      value={teamState.name}
                      onChange={onChangeState}
                    />
                  </div>

                  <div className="team-member v-stack" style={{ marginTop: 20 }}>
                    <div className="h-stack my-2">
                      <p className="team__label">
                        チームメンバー ({teamUsers.length}人)
                      </p>
                      {/* </p>{props.isCreating && <p className="team__must">必須</p>} */}
                    </div>
                    <div className="team-member__list">
                      <div className="w-full mb-[4px]">
                        {
                          teamUsers.map((user) => (
                            <div key={user.id} className="team-member__list row">
                              <div className="h-stack center">
                                {
                                  user.profileImageUrl ?
                                    <img src={user.profileImageUrl} className="profile-img" />
                                    :
                                    <div className="icon-label">
                                      <p>
                                        {user.username.charAt(0)}
                                      </p>
                                    </div>
                                }
                                <div className="v-stack ml-2">
                                  <p className="username-label">{user.isJoined() ? user.username : "招待中メンバー"}</p>
                                  <p className="email-label">{user.email}</p>
                                </div>
                              </div>
                              <div className="team-member__setting">
                                <button
                                  className="delete-button ml-2"
                                  onClick={() => removeAssignedUser(user.id)}>
                                  <TrashIcon />
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <Menu>
                      <div className="team-member__buttonBox">
                        <Menu.Button
                          onClick={onSelectUserButtonPressed}
                          disabled={!filteredUsers?.length}
                          className="button main">
                          <AddUserIcon className="team-member__add-user-icon" />
                          メンバーを選ぶ
                        </Menu.Button>
                      </div>
                      <Transition
                        show={isUserSelectionPanelOpen}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items>
                          <RadioGroup
                            className="team-member__list rounded-lg"
                            value={filteredUsers}
                            onChange={(value) => {
                              setFilteredUsers((prev) => (prev.map((u) => {
                                if (u.id === value[0].id) {
                                  return {
                                    ...u,
                                    isSelected: !u.isSelected,
                                  }
                                }
                                return u;
                              })))
                            }}>
                            {filteredUsers && filteredUsers.map((user) => (
                              <RadioGroup.Option
                                className={"mb-[4px]"}
                                value={[user]}>
                                {() => {
                                  return (
                                    <div className="team-member__list selection-row">
                                      <div className="h-stack center">
                                        <div className="mr-2">
                                          <Checkbox props={{
                                            checked: user.isSelected
                                          }} />
                                        </div>
                                        {
                                          user.profileImageUrl ?
                                            <img src={user.profileImageUrl} className="profile-img" />
                                            :
                                            <div className="icon-label">
                                              <p>
                                                {user.username.charAt(0)}
                                              </p>
                                            </div>
                                        }
                                        <div className="h-stack center ml-2">
                                          <p className="username-label w-[120px]">{user.isJoined ? user.username : "招待中メンバー"}</p>
                                          <p className="email-label ml-4">{user.email}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }}
                              </RadioGroup.Option>
                            ))}
                          </RadioGroup>
                          <div className="team-member__buttonBox">
                            <Menu.Button
                              onClick={onAddMemberButtonPressed}
                              disabled={!filteredUsers?.length}
                              className="button main">
                              + 選択したメンバーを追加する
                            </Menu.Button>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
              {
                (isProcessing || isLoading) ?
                  <div className="spinner-wrapper">
                    <RotatingLines
                      strokeColor={Colors.primary}
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="42"
                      visible={true}
                    />
                  </div>
                  :
                  <div className="team__buttonContainer">
                    <div className="team__buttonRow">
                      <Button
                        name="abort"
                        theme="solid"
                        onClick={onCancel}>
                        キャンセル
                      </Button>
                      <Button
                        name="submit"
                        theme="main"
                        onClick={onSubmit}>
                        変更する
                      </Button>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
});

export default EditTeam;
