export const getJapaneseDay = (d: Date): string => {
  switch (d.getDay()) {
    case 0:
      return "日";
    case 1:
      return "月";
    case 2:
      return "火";
    case 3:
      return "水";
    case 4:
      return "木";
    case 5:
      return "金";
    case 6:
      return "土";
    default:
      return "";
  }
};

export const daysInMonth = (month: number, year: number): number => {
  return new Date(year, month, 0).getDate();
};

/**
 * e.g.:
 * date1: 2022-12-01T00:00:000Z, date2: 2022-12-31T00:00:000Z
 * it returns 30
 * @param date1 Date
 * @param date2 Date
 * @returns number of dates
 */
export const getDifferenceInDates = (date1: Date, date2: Date): number => {
  return Math.ceil(
    Math.abs(date1.valueOf() - date2.valueOf()) / (24 * 60 * 60 * 1000),
  );
};

export const checkEqualDates = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getDate() == date2.getDate()
  );
};

export const checkIncludeDates = (dates: Date[], targetDate: Date) => {
  return dates.some((date) => checkEqualDates(date, targetDate));
};

export const generateMonthlyRange = (startDate: Date, endDate: Date) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const result = [];
  let current = new Date(start);

  while (current <= end) {
    const year = current.getFullYear();
    const month = current.getMonth();
    const date = new Date(year, month, 1);
    result.push(date);

    // 次の月の1日に進める
    current.setMonth(current.getMonth() + 1);
    current.setDate(1);
  }

  return result;
}
export const generateDateRange = (startDate: Date, endDate: Date) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const result = [];
  let current = new Date(start);

  while (current <= end) {
    result.push(new Date(current)); // 日付型のコピーを追加

    // 次の日に進める
    current.setDate(current.getDate() + 1);
  }

  return result;
}
