import React from "react";
import {
  moveOneLeft,
  moveOneRight,
  moveAllLeft,
  moveAllRight,
} from "src/excalidraw/zindex";
import { KEYS, CODES } from "src/excalidraw/keys";
import { t } from "src/excalidraw/i18n";
import { getShortcutKey } from "src/excalidraw/utils";
import { register } from "./register";
import {
  BringForwardIcon,
  BringToFrontIcon,
  SendBackwardIcon,
  SendToBackIcon,
} from "../components/icons";
import { isDarwin } from "src/excalidraw/constants";

import { getSelectedElements } from "src/excalidraw/scene"; //CHANGED:ADD 2023/01/24 #447
import {
  isGraphElement,
  isJobElement,
} from "src/excalidraw/extensions/element/typeChecks"; //CHANGED:ADD 2023/01/24 #447

export const actionSendBackward = register({
  name: "sendBackward",
  trackEvent: { category: "element" },
  //CHANGED:ADD 2023/01/24 #447
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    // CHANGED:UPDATE 2023-2-8 #608
    return !selectedElements.some(
      (element) => isGraphElement(element) || isJobElement(element),
    );
  },
  perform: (elements, appState) => {
    //CHANGED:UPDATE 2023/01/24 #447
    const selectedElements = getSelectedElements(elements, appState);
    return {
      // CHANGED:UPDATE 2023-2-8 #608
      elements: selectedElements.some(
        (element) => isGraphElement(element) || isJobElement(element),
      )
        ? elements
        : moveOneLeft(elements, appState),
      appState,
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.sendBackward",
  keyPriority: 40,
  keyTest: (event) =>
    event[KEYS.CTRL_OR_CMD] &&
    !event.shiftKey &&
    event.code === CODES.BRACKET_LEFT,
  PanelComponent: ({ updateData, appState }) => (
    <button
      type="button"
      className="zIndexButton"
      onClick={() => updateData(null)}
      title={`${t("labels.sendBackward")} — ${getShortcutKey("CtrlOrCmd+[")}`}
    >
      <div className="ToolIcon__icon" aria-hidden="true">
      {SendBackwardIcon}
      </div>
    </button>
  ),
});

export const actionBringForward = register({
  name: "bringForward",
  trackEvent: { category: "element" },
  //CHANGED:ADD 2023/01/24 #447
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    // CHANGED:UPDATE 2023-2-8 #608
    return !selectedElements.some(
      (element) => isGraphElement(element) || isJobElement(element),
    );
  },
  perform: (elements, appState) => {
    //CHANGED:UPDATE 2023/01/24 #447
    const selectedElements = getSelectedElements(elements, appState);
    return {
      // CHANGED:UPDATE 2023-2-8 #608
      elements: selectedElements.some(
        (element) => isGraphElement(element) || isJobElement(element),
      )
        ? elements
        : moveOneRight(elements, appState),
      appState,
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.bringForward",
  keyPriority: 40,
  keyTest: (event) =>
    event[KEYS.CTRL_OR_CMD] &&
    !event.shiftKey &&
    event.code === CODES.BRACKET_RIGHT,
  PanelComponent: ({ updateData, appState }) => (
    <button
      type="button"
      className="zIndexButton"
      onClick={() => updateData(null)}
      title={`${t("labels.bringForward")} — ${getShortcutKey("CtrlOrCmd+]")}`}
    >
      <div className="ToolIcon__icon" aria-hidden="true">
        {BringForwardIcon}
      </div>
    </button>
  ),
});

export const actionSendToBack = register({
  name: "sendToBack",
  trackEvent: { category: "element" },
  //CHANGED:ADD 2023/01/24 #447
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    // CHANGED:UPDATE 2023-2-8 #608
    return !selectedElements.some(
      (element) => isGraphElement(element) || isJobElement(element),
    );
  },
  perform: (elements, appState) => {
    //CHANGED:UPDATE 2023/01/24 #447
    const selectedElements = getSelectedElements(elements, appState);
    return {
      // CHANGED:UPDATE 2023-2-8 #608
      elements: selectedElements.some(
        (element) => isGraphElement(element) || isJobElement(element),
      )
        ? elements
        : moveAllLeft(elements, appState),
      appState,
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.sendToBack",
  keyTest: (event) =>
    isDarwin
      ? event[KEYS.CTRL_OR_CMD] &&
        event.altKey &&
        event.code === CODES.BRACKET_LEFT
      : event[KEYS.CTRL_OR_CMD] &&
        event.shiftKey &&
        event.code === CODES.BRACKET_LEFT,
  PanelComponent: ({ updateData, appState }) => (
    <button
      type="button"
      className="zIndexButton"
      onClick={() => updateData(null)}
      title={`${t("labels.sendToBack")} — ${
        isDarwin
          ? getShortcutKey("CtrlOrCmd+Alt+[")
          : getShortcutKey("CtrlOrCmd+Shift+[")
      }`}
    >
      <div className="ToolIcon__icon" aria-hidden="true">
        {SendToBackIcon}
      </div>
    </button>
  ),
});

export const actionBringToFront = register({
  name: "bringToFront",
  trackEvent: { category: "element" },

  //CHANGED:ADD 2023/01/24 #447
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    // CHANGED:UPDATE 2023-2-8 #608
    return !selectedElements.some(
      (element) => isGraphElement(element) || isJobElement(element),
    );
  },
  perform: (elements, appState) => {
    //CHANGED:UPDATE 2023/01/24 #447
    const selectedElements = getSelectedElements(elements, appState);
    return {
      // CHANGED:UPDATE 2023-2-8 #608
      elements: selectedElements.some(
        (element) => isGraphElement(element) || isJobElement(element),
      )
        ? elements
        : moveAllRight(elements, appState),
      appState,
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.bringToFront",
  keyTest: (event) =>
    isDarwin
      ? event[KEYS.CTRL_OR_CMD] &&
        event.altKey &&
        event.code === CODES.BRACKET_RIGHT
      : event[KEYS.CTRL_OR_CMD] &&
        event.shiftKey &&
        event.code === CODES.BRACKET_RIGHT,
  PanelComponent: ({ updateData, appState }) => (
    <button
      type="button"
      className="zIndexButton"
      onClick={(event) => updateData(null)}
      title={`${t("labels.bringToFront")} — ${
        isDarwin
          ? getShortcutKey("CtrlOrCmd+Alt+]")
          : getShortcutKey("CtrlOrCmd+Shift+]")
      }`}
    >
      <div className="ToolIcon__icon" aria-hidden="true">
        {BringToFrontIcon}
      </div>
    </button>
  ),
});
