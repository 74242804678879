import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

interface Props {
  isShow: boolean;
  className?: string;
  style?: React.CSSProperties;
  position?: "top" | "bottom";
  tailCenter?: boolean;
  value: string;
}

const HoverToolTip: React.FC<Props> = (props: Props) => {
  const { isShow, className, style, value, position, tailCenter } = props;

  let tipPosition = "-top-3.5";
  if (position && position === "top") {
    tipPosition = "bottom-[-16px] rotate-180";
  }
  const tailPosition = !!tailCenter ? "left-[50%]" : "left-[17px]"

  return (
    <div
      style={style}
      className={classNames(
        !!className ? `${className}` : "",
        "text-black absolute bg-[#E3F2FA] w-max rounded z-[999] text-[13px] opacity-0 transition-opacity duration-[0.1s] ease-[ease-in-out]  p-[5px] border-[none] left-[15px] top-10 pointer-events-none",
        isShow ? "visible opacity-100" : "invisible opacity-0",
      )}
    >
      <div
        className={`absolute w-0 h-0 -translate-x-2/4 border-solid border-[10px] border-[transparent_transparent_#E3F2FA_transparent] ${tailPosition} ${tipPosition}`}
      ></div>
      <div>
        <p className="text-[13px] whitespace-nowrap text-wrap">{value}</p>
      </div>
    </div>
  );
};

export default observer(HoverToolTip);
