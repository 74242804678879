import React from "react";

export type SidebarExProps<P = {}> = {
  children: React.ReactNode;
  /**
   * Called on sidebar close (either by user action or by the editor).
   */
  /** if not supplied, sidebar won't be dockable */
  onDock?: (docked: boolean) => void;
  onLibrary?: () => void | boolean;
  docked?: boolean;
  initialDockedState?: boolean;
  dockable?: boolean;
  className?: string;
} & P;

export type SidebarExPropsContextValue = Pick<
  SidebarExProps,
  "onLibrary" | "onDock" | "docked" | "dockable"
>;

export const SidebarExPropsContext =
  React.createContext<SidebarExPropsContextValue>({});
