

export interface ProjectFilter {
  searchText: string | null;
  searchStatus: string | null;
  searchTag: string | null;
};

export const ProjectFilterTags = {
  searchText: "テキスト",
  searchStatus: "ステータス",
  searchTag: "タグ",
};