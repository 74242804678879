import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "src/conpath/constants/Routes";
import { SessionKeys } from "src/conpath/constants/SessionKeys";

//interfaces
import { InvitationBody } from "src/conpath/interfaces/Invitation";

import { observer } from "mobx-react";
import { useStore } from "../../hooks/useStore";

const errorMessages: {[key: string]: string } = {
  invalid: "招待リンクが有効ではありません。",
  expired: "招待リンクの有効期限が切れています。",
  emailNotValid: "この招待リンクは別のユーザーに対して送信されています。一度ログアウトして再度お試しください。"
}

const Invitation: React.FC = observer(() => {

  const { userStore } = useStore();
  const { loginUser } = userStore;
  const { search } = useLocation();
  const navigate = useNavigate();
  
  const [invalidReason, setInvalidReason] = useState<string>("");


  useEffect(() => {

    const decodeInvitation = (): InvitationBody | null => {
      try {
        sessionStorage.removeItem(SessionKeys.InvitationToken);

        if (!search) {
          setInvalidReason(errorMessages.invalid);
          return null;
        }
    
        const token = search.split("token=").pop();
        if (!token) {
          setInvalidReason(errorMessages.invalid);
          return null;
        }
    
        const decoded = jwt_decode(token) as InvitationBody;
        if (!decoded) {
          setInvalidReason(errorMessages.invalid);
          return null;
        }
    
        if (decoded.exp < new Date().valueOf() / 1000) {
          setInvalidReason(errorMessages.expired);
          return null;
        }

        if (loginUser && loginUser.email !== decoded.email) {
          setInvalidReason(errorMessages.emailNotValid);
          return null;
        }
        
        sessionStorage.setItem(SessionKeys.InvitationToken, token);
        return decoded
      } catch (err) {
        console.log(err)
        // Sentry here
        setInvalidReason(errorMessages.invalid);
        return null;
      }
    }


    const decoded = decodeInvitation();
    if (!decoded) return;

    if (loginUser && decoded) {
      navigate(Paths.invite + Paths.accept, {
        state: {
          userId: loginUser.id,
          skipDeletingAccount: true, //すでにアカウントを持っていて、別の組織に属しているユーザー
        },
        replace: true,
      });
      return;
    }
    if (decoded.type === "organization") {
      navigate(Paths.login, { replace: true });
    } else {
      navigate(Paths.signup, { replace: true });
    }
  }, [search, loginUser]);

  useEffect(() => {
    if (invalidReason) {
      navigate(Paths.invite + Paths.invalid, { 
          state: { reason: invalidReason }, 
          replace: true 
        }
      );
    }
  }, [invalidReason, navigate]);


  return <></>
});

export default Invitation;