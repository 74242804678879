import { register } from "src/excalidraw/actions/register";
import { ShapeCache } from "src/excalidraw/scene/ShapeCache";
import { isTaskElement } from "src/excalidraw/extensions/element/typeChecks";
import dayjs from "dayjs";
import { getBoundTextElement } from "src/excalidraw/element/textElement";

export const actionToggleOverdueTaskMode = register({
  name: "overdueTaskMode",
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.overdueTaskModeEnabled,
  },
  perform(elements, appState, _, app) {
    elements.forEach((element) => {
      const elementsMap = app.scene.getNonDeletedElementsMap();
      if (
        isTaskElement(element) &&
        !element.isClosed &&
        element.endDate.valueOf() < dayjs().endOf("day").valueOf()
      ) {
        ShapeCache.delete(element);

        const boundTextElement = getBoundTextElement(element, elementsMap);
        if (boundTextElement) {
          ShapeCache.delete(boundTextElement);
        }
      }
    });

    return {
      appState: {
        ...appState,
        overdueTaskModeEnabled: !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.overdueTaskModeEnabled,
  contextItemLabel: "buttons.overdueTaskMode",
});
