export const OrganizationUserState = {
  sendingEmail: 10, // ユーザーにEmailを送信
  failedToSendEmail: 20, // Emailの招待の失敗 -> 再送信の処理を受け付ける
  waitingForAnswer: 30, // ユーザーの招待認証待ち　-> 再送信の処理を受け付ける
  invitationExpired: 40, // 招待日数の期限切れ -> 再送信の処理を受け付ける
  invitationCanceled: 50, //招待のキャンセル -> //TODO: リンクを踏んでも組織に入れない実装
  joined: 60, //ユーザーが組織に参加 -> userIdsにIDを追加し、ログインユーザーが組織を参照できるようになる
  invitationDeclined: 70, //ユーザーが組織の招待を辞退
  userRemoved: 999, // （joinedから変更）ユーザーを組織から退場する -> userIds からIDを削除し、ログインユーザーが組織を参照できない様にする
} as const;

export type OrganizationUserState = typeof OrganizationUserState[keyof typeof OrganizationUserState];

export const OrganizationUserStateMap: {[key in OrganizationUserState]: string }  = {
  10: "招待メール送信中",
  20: "招待メール送信失敗",
  30: "承認待ち",
  40: "招待有効期限切れ",
  50: "招待のキャンセル済み",
  60: "参加済み",
  70: "招待の辞退",
  999: "組織から退出済み",
};
