import React, { useMemo } from "react";
import { BsExclamationLg } from "react-icons/bs";

const DelayIcon = ({
  width,
  thickness,
  color,
  scale = 1.5,
}: {
  width: number;
  thickness: number;
  color: string;
  scale?: number;
}) => {
  const icon = useMemo(
    () => (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: `${width - thickness / 2}px`,
        }}
      >
        <BsExclamationLg
          fill="white"
          style={{
            width: thickness,
            height: thickness,
            background: color,
            borderRadius: "100%",
            transformOrigin: "center",
            transform: `scale(${scale})`,
          }}
        />
      </div>
    ),
    [width, thickness, color, scale],
  );

  return icon;
};

export default DelayIcon;
