import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner';
import jwt_decode from "jwt-decode";

//mobx
import { useStore } from "src/conpath/hooks/useStore";
import { observer } from "mobx-react-lite";

//interfaces
import { SignUpForm } from "src/conpath/interfaces/AuthForm";
import { InvitationBody } from "src/conpath/interfaces/Invitation";

//styles
import "./SignUp.scss";
import Colors from "src/conpath/constants/Colors";
import { Paths } from "src/conpath/constants/Routes";
import { SessionKeys } from "src/conpath/constants/SessionKeys";


const SignUp: React.FC = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [state, setState] = useState<SignUpForm>({
    username: "",
    email: "",
    password: "",
  });
  const [errorText, setErrorText] = useState<string>("");
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Check if invited
    const token = sessionStorage.getItem(SessionKeys.InvitationToken);
    if (token) {
      const decoded = jwt_decode(token) as InvitationBody;
      setState(prev => ({ ...prev, email: decoded.email }));
      setIsInvited(true);
    }
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value, name } = event.target;
    if (name) {
      setState((prev) => ({ 
        ...prev, 
        [name]: name === "email" ? value.replace(/\s/g, "") : value
      }));
    }
    setErrorText("");
  };

  const onSubmit = async () => {
    if (!userStore) return;

    setIsLoading(true);
    const result = await userStore.signUp(state, isInvited);

    setIsLoading(false);
    if (result.error) {
      setErrorText(result.error);
      return;
    }

    if (isInvited) {
      navigate(Paths.invite + Paths.accept, {
        state: {
          userId: result.userId!,
          skipDeletingAccount: false,
        },
        replace: true,
      });
    } else {
      navigate(Paths.dashboard, { replace: true });
    }
  };

  return (
    <div className="input__body">
      <div className="logo__conatiner">
        <img src="/fullLogo.svg" alt="conpath logo" />
      </div>
      <div className="input__card card--signup topSpacer">
        <h2 className="input__title">アカウント作成</h2>
        <div className="error-text-wrapper">
          <p>{errorText}</p>
        </div>
        <div className="input__wrapper minSpacer">
          <p className="input__label">ユーザー名</p>
          <input
            value={state.username}
            onChange={onChange}
            type="text"
            name="username"
            placeholder="ユーザー名"
          />
          <p className="input__label">メールアドレス</p>
          <input
            value={state.email}
            onChange={onChange}
            name="email"
            disabled={isInvited}
            placeholder="メールアドレス"
          />
          <p className="input__label">パスワード　６文字以上</p>
          <input
            value={state.password}
            onChange={onChange}
            type="password"
            name="password"
            placeholder="パスワード"
          />
        </div>
        {isLoading ? (
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="24"
              visible={true}
            />
          </div>
        ) : (
          <div className="input__buttonWrapper">
            <button onClick={onSubmit} className="button main">
              次へ
            </button>
          </div>
        )}
      </div>
      {!isInvited && (
        <div className="input__card topSpacer">
          <p>アカウントを既にお持ちの方</p>
          <button
            onClick={() => navigate(Paths.login)}
            style={{ color: "gray" }}
            className="text-button minTopSpacer"
          >
            ログイン画面へ
          </button>
        </div>
      )}
    </div>
  );
});

export default SignUp;
