import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Island } from "../../../components/Island";
import { atom, useAtom } from "jotai";
import { jotaiScope } from "../../../jotai";
import {
  SidebarExProps,
  SidebarExPropsContext,
  SidebarExPropsContextValue
} from "./common";

import { SidebarExHeaderComponents } from "./SidebarHeaderEx";

import "./SidebarEx.scss";
import clsx from "clsx";
import { useExcalidrawSetAppState } from "../../../components/App";
import { updateObject } from "src/excalidraw/utils";

/** using a counter instead of boolean to handle race conditions where
 * the host app may render (mount/unmount) multiple different sidebar */
export const hostSidebarCountersAtom = atom({ rendered: 0, docked: 0 });

export const SidebarEx = Object.assign(
  forwardRef(
    (
      {
        children,
        onLibrary,
        onDock,
        docked,
        /** Undocumented, may be removed later. Generally should either be
         * `props.docked` or `appState.isSidebarDocked`. Currently serves to
         *  prevent unwanted animation of the shadow if initially docked. */
        //
        // NOTE we'll want to remove this after we sort out how to subscribe to
        // individual appState properties
        initialDockedState = docked,
        dockable = true,
        className,
        __isInternal,
      }: SidebarExProps<{
        // NOTE sidebars we use internally inside the editor must have this flag set.
        // It indicates that this sidebar should have lower precedence over host
        // sidebars, if both are open.
        /** @private internal */
        __isInternal?: boolean;
      }>,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const [hostSidebarCounters, setHostSidebarCounters] = useAtom(
        hostSidebarCountersAtom,
        jotaiScope,
      );

      const setAppState = useExcalidrawSetAppState();

      // CHANGED:REMOVE 2023/09/06 #1017
      // const [isDockedFallback, setIsDockedFallback] = useState(
      //   docked ?? initialDockedState ?? false,
      // );

      // useLayoutEffect(() => {
      //   if (docked === undefined) {
      //     // ugly hack to get initial state out of AppState without subscribing
      //     // to it as a whole (once we have granular subscriptions, we'll move
      //     // to that)
      //     //
      //     // NOTE this means that is updated `state.isSidebarDocked` changes outside
      //     // of this compoent, it won't be reflected here. Currently doesn't happen.
      //     setAppState((state) => {
      //       setIsDockedFallback(state.isSidebarDocked);
      //       // bail from update
      //       return null;
      //     });
      //   }
      // }, [setAppState, docked]);

      // useLayoutEffect(() => {
      //   if (!__isInternal) {
      //     setHostSidebarCounters((s) => ({
      //       rendered: s.rendered + 1,
      //       docked: isDockedFallback ? s.docked + 1 : s.docked,
      //     }));
      //     return () => {
      //       setHostSidebarCounters((s) => ({
      //         rendered: s.rendered - 1,
      //         docked: isDockedFallback ? s.docked - 1 : s.docked,
      //       }));
      //     };
      //   }
      // }, [__isInternal, setHostSidebarCounters, isDockedFallback]);

      const onLibraryRef = useRef(onLibrary);
      onLibraryRef.current = onLibrary;

      useEffect(() => {
        return () => {
          onLibraryRef.current?.();
        };
      }, []);

      const headerPropsRef = useRef<SidebarExPropsContextValue>({});
      headerPropsRef.current.onLibrary = () => {
        setAppState({ openSidebar: "library" });
      };
      // CHANGED:REMOVE 2023/09/06 #1017
      // headerPropsRef.current.onDock = (isDocked) => {
      //   if (docked === undefined) {
      //     setAppState({ isSidebarDocked: isDocked });
      //     setIsDockedFallback(isDocked);
      //   }
      //   onDock?.(isDocked);
      // };
      // renew the ref object if the following props change since we want to
      // rerender. We can't pass down as component props manually because
      // the <Sidebar.Header/> can be rendered upsream.
      // headerPropsRef.current = updateObject(headerPropsRef.current, {
      //   docked: docked ?? isDockedFallback,
      //   dockable,
      // });

      if (hostSidebarCounters.rendered > 0 && __isInternal) {
        return null;
      }

      return (
        <Island
          className={clsx(
            "layer-ui__sidebar",
            // { "layer-ui__sidebar--docked": isDockedFallback }, // CHANGED:REMOVE 2023/09/06 #1017
            className,
          )}
          ref={ref}
        >
          <SidebarExPropsContext.Provider value={headerPropsRef.current}>
            <SidebarExHeaderComponents.Context>
              <SidebarExHeaderComponents.Component __isFallback />
              {children}
            </SidebarExHeaderComponents.Context>
          </SidebarExPropsContext.Provider>
        </Island>
      );
    },
  ),
  {
    Header: SidebarExHeaderComponents.Component,
  },
);
