import React, { useState, useEffect, useCallback, Fragment, useMemo, useRef } from "react";
import _ from "lodash";
import { Flip, ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { TwitterPicker } from 'react-color';

//components
import { Button } from "src/conpath/components/Button";
import Selection from 'src/conpath/components/Selection';
import { DateObject } from "react-multi-date-picker";
import Calendar from "src/conpath/components/Calendar";
import { AddUserIcon, TrashIcon } from "../icons";
import Checkbox from "../CheckBox";
import Badge from "../Badge";
import { Dialog, Menu, Tab } from "@headlessui/react";
import { RadioGroup, Transition } from '@headlessui/react'

//constants
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import Colors, { PROJECT_COLORS } from "src/conpath/constants/Colors";
import CalendarLocal from "src/conpath/constants/CalendarLocal";
import { projectRoleSelections } from "src/conpath/constants/Selections";
import { OrganizationRole, ProjectRole, ProjectRoleMap } from "src/conpath/constants/Role";
import { IoPersonAddOutline } from "react-icons/io5";

//interfaces
import { ProjectInputForm } from "src/conpath/interfaces/Project";

//models
import OrganizationModel from "src/conpath/models/OrganizationModel";

//styles
import "./ProjectForm.scss";

//utils
import { formatDate } from "src/utils/dateUtils";
import { Tags } from "../../interfaces/Tag";

interface SelectingUser {
  id: string;
  email: string;
  username: string;
  profileImageUrl: string;
  role: OrganizationRole;
  isSelected: boolean;
};

interface SelectingTeam {
  id: string;
  name: string;
  isSelected: boolean;
};

interface SelectingResource {
  id: string;
  name: string;
  tags: Tags;
  memo: string;
  iconImageUrl: string;
  isSelected: boolean;
};

interface Props {
  projectState: ProjectInputForm;
  organization: OrganizationModel;
  errorText: string;
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
  isCreating: boolean;
  isDuplicate: boolean;
  isProcessing: boolean;
  onChangeDuration?: (startDate: Date, endDate: Date | null) => void;
  onChangeProjectState: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAssignedUsers: (users: any) => void;
  onChangeAssignedTeams: (teams: any) => void;
  onChangeAssignedResources: (resources: any) => void;
  onChangeColor: (value: string) => void;
  onChangeTags: (value: Tags) => void;
  onArchive?: (isArchived: boolean) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

enum SelectableTabs {
  USERS,
  TEAMS,
}

const ProjectForm = React.memo((props: Props) => {
  const {
    projectState,
    organization,
    errorText,
    setErrorText,
    isProcessing,
    onChangeProjectState,
    onChangeDuration,
    onChangeAssignedUsers,
    onChangeAssignedTeams,
    onChangeAssignedResources,
    onChangeColor,
    onChangeTags,
    onArchive,
    onCancel,
    onSubmit,
  } = props;

  const [filteredUsers, setFilteredUsers] = useState<SelectingUser[]>([]);
  const [isUserSelectionPanelOpen, setIsUserSelectionPanelOpen] = useState<boolean>(false);

  const [filteredTeams, setFilteredTeams] = useState<SelectingTeam[]>([]);
  const [isTeamSelectionPanelOpen, setIsTeamSelectionPanelOpen] = useState<boolean>(false);

  const [filteredResources, setFilteredResources] = useState<SelectingResource[]>([]);
  const [isResourceSelectionPanelOpen, setIsResourceSelectionPanelOpen] = useState<boolean>(false);

  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
  const [isUpdateArchiveModalOpen, setIsUpdateArchiveModalOpen] = useState<boolean>(false);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<SelectableTabs>(SelectableTabs.USERS);

  const [projectLimitExceeded, setProjectLimitExceeded] = useState<boolean>(true);

  // 日付の設定
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  function closeUpdateArchiveModal() {
    setModalErrorMessage("");
    setIsUpdateArchiveModalOpen(false);
  }

  function openUpdateArchiveModal() {
    setIsUpdateArchiveModalOpen(true);
  }

  useEffect(() => {
    setFilteredUsers(
      organization.getJoinedUsers()
        .filter(user => !Object.keys(projectState.roles)
          .some((id: string) => id === user.id))
        .map((user) => {
          return {
            id: user.id,
            email: user.email,
            username: user.username,
            role: user.role,
            profileImageUrl: user.profileImageUrl,
            isSelected: false,
          } as SelectingUser
        }) || []
    )
  }, [organization, projectState.roles]);

  useEffect(() => {
    const getActiveProjectCount = async () => {
      const currentPlan = organization.getCurrentPlan();
      const addProjects = organization.addProjects;
      const activeProjects = await organization.getActiveProjectCount();

      setProjectLimitExceeded(activeProjects >= currentPlan!.projectLimit + addProjects);
    }

    getActiveProjectCount();
  }, [organization.projects]);

  const projectUsers = useMemo(() => {
    return organization.getJoinedUsers()
      .filter((user) => Object.keys(projectState.roles).includes(user.id))
      .map(user => {
        return user;
      });
  }, [organization.users, projectState.roles]);

  const removeAssignedUser = (id: string) => {
    const { [id]: tmp, ...rest } = projectState.roles;
    onChangeAssignedUsers(rest);
  };

  const onChangeProjectUserRole = useCallback((value: string, id: string) => {
    onChangeAssignedUsers({
      ...projectState.roles,
      [id]: Number(value) as ProjectRole,
    });
  }, [projectState.roles, onChangeAssignedUsers]);

  const onSelectUserButtonPressed = useCallback(() => {
    if (isUserSelectionPanelOpen) {
      // Clear all selected users
      setFilteredUsers((prev) => (prev.map((user) => {
        return {
          ...user,
          isSelected: false
        }
      })));
    }

    setIsUserSelectionPanelOpen((prev) => !prev);
  }, [isUserSelectionPanelOpen]);

  const onAddMemberButtonPressed = useCallback(() => {
    const users = { ...projectState.roles };
    filteredUsers.forEach((user) => {
      if (user.isSelected) {
        users[user.id] = user.role === OrganizationRole.guest
          ? ProjectRole.viewer
          : ProjectRole.writer
      }
    });
    onChangeAssignedUsers(users);
    setIsUserSelectionPanelOpen(false);
  }, [projectState.roles, filteredUsers]);

  useEffect(() => {
    setFilteredTeams(
      organization.teams
        .filter(team => !Object.keys(projectState.teams)
          .some((id: string) => id === team.id))
        .map((team) => {
          return {
            id: team.id,
            name: team.name,
            isSelected: false,
          } as SelectingTeam
        }) || []
    )
  }, [organization, projectState.teams]);

  const projectTeams = useMemo(() => {
    return organization.teams
      .filter((team) => Object.keys(projectState.teams).includes(team.id))
      .map(team => {
        return team;
      });
  }, [organization.teams, projectState.teams]);

  const removeAssignedTeam = (id: string) => {
    const { [id]: tmp, ...rest } = projectState.teams;
    onChangeAssignedTeams(rest);
  };

  const onChangeProjectTeamRole = useCallback((value: string, id: string) => {
    onChangeAssignedTeams({
      ...projectState.teams,
      [id]: Number(value) as ProjectRole,
    });
  }, [projectState.teams, onChangeAssignedTeams]);

  const onSelectTeamButtonPressed = useCallback(() => {
    if (isTeamSelectionPanelOpen) {
      // Clear all selected teams
      setFilteredTeams((prev) => (prev.map((team) => {
        return {
          ...team,
          isSelected: false
        }
      })));
    }

    setIsTeamSelectionPanelOpen((prev) => !prev);
  }, [isTeamSelectionPanelOpen]);

  const onAddTeamButtonPressed = useCallback(() => {
    const teams = { ...projectState.teams };
    filteredTeams.forEach((team) => {
      if (team.isSelected) {
        teams[team.id] = ProjectRole.writer
      }
    });
    onChangeAssignedTeams(teams);
    setIsTeamSelectionPanelOpen(false);
  }, [projectState.teams, filteredTeams]);


  useEffect(() => {
    setFilteredResources(
      organization.getNonDeletedResources()
        .filter(resource => !Object.keys(projectState.resources)
          .some((id: string) => id === resource.id))
        .map((resource) => {
          return {
            id: resource.id,
            name: resource.name,
            tags: resource.tags,
            memo: resource.memo,
            iconImageUrl: resource.iconImageUrl,
            isSelected: false,
          } as SelectingResource
        }) || []
    )
  }, [organization, projectState.resources]);

  const projectResources = useMemo(() => {
    return organization.getNonDeletedResources()
      .filter((resource) => Object.keys(projectState.resources).includes(resource.id))
      .map(resource => {
        return resource;
      });
  }, [organization.resources, projectState.resources]);

  const removeAssignedResource = (id: string) => {
    const { [id]: tmp, ...rest } = projectState.resources;
    onChangeAssignedResources(rest);
  };

  const onSelectResourceButtonPressed = useCallback(() => {
    if (isResourceSelectionPanelOpen) {
      // Clear all selected resources
      setFilteredResources((prev) => (prev.map((resource) => {
        return {
          ...resource,
          isSelected: false
        }
      })));
    }

    setIsResourceSelectionPanelOpen((prev) => !prev);
  }, [isResourceSelectionPanelOpen]);

  const onAddResourceButtonPressed = useCallback(() => {
    const resources = { ...projectState.resources };
    filteredResources.forEach((resource) => {
      if (resource.isSelected) {
        resources[resource.id] = true;
      }
    });
    onChangeAssignedResources(resources);
    setIsResourceSelectionPanelOpen(false);
  }, [projectState.resources, filteredResources]);

  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText, setErrorText]);

  const onCloseTags = (tag: string) => {
    const newTags = projectState.tags;
    delete newTags[tag];
    onChangeTags && onChangeTags(newTags);
  };

  function handleTagsKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.nativeEvent.isComposing) return;

    const tagCount = Object.keys(projectState.tags)?.length || 0;

    const value = e.currentTarget.value;
    if (!value.length && e.key == 'Backspace' && tagCount > 0) {
      const tag = Object.keys(projectState.tags).pop() || "";
      onCloseTags(tag);
      return;
    }

    if (e.key !== 'Enter' || !value.trim()) return;
    const newTags = {
      ...projectState.tags,
      [value]: true,
    }
    onChangeTags && onChangeTags(newTags);

    e.currentTarget.value = '';
    e.preventDefault();
  }

  const getNumberOfCandidates = (value: "users" | "teams"): number => {
    // if (!selectedOrganization) return 0;

    switch (value) {
      case "users":
        return Object.keys(projectState.roles).length;
      case "teams":
        return Object.keys(projectState.teams).length;
      default:
        return 0;
    }
  };

  return (
    <div className="project-form-container">
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={TOAST_DEFAULT_DURATION}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {projectState.isArchived && projectState.archivedAt &&
        <div className="project-archived-header">
          <p>
            {`このプロジェクトは${projectState.archivedAt.toLocaleDateString('ja-JP', dateOptions)}にアーカイブされました。現在は読み取り専用です。`}
          </p>
        </div>
      }
      {/* プロジェクト名・住所 */}
      <div className="form-section">
        <div className="v-stack">
          <div className="flex gap-2">
            <div className="grow">
              <div className="project-organization">
                <p className="project-organization__label">プロジェクト名</p>
                {(props.isCreating || props.isDuplicate) && <p className="project__must">必須</p>}
              </div>
              <div className="h-stack">
                <input
                  name="name"
                  className="project-input"
                  placeholder="プロジェクト名を入力する"
                  value={projectState.name}
                  disabled={projectState.isArchived}
                  onChange={onChangeProjectState}
                />
              </div>
            </div>
            <div>
              <div className="project-organization">
                <p className="project-organization__label">
                  カラー
                </p>
              </div>
              <div className="h-stack">
                <div
                  className={"relative w-[42px] h-[42px] border-[1px] rounded-[8px] border-gray-400"}
                  style={{ backgroundColor: projectState.color }}
                  onClick={() => !projectState.isArchived && setIsColorPickerOpen(!isColorPickerOpen)}
                >
                  {isColorPickerOpen && (
                    <div className="absolute top-[42px] right-0">
                      <TwitterPicker
                        colors={PROJECT_COLORS}
                        color={projectState.color}
                        onChangeComplete={(color) => {
                          onChangeColor(color.hex);
                          setIsColorPickerOpen(false);
                        }}
                        triangle={"top-right"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="h-stack mt-[40px]">
              <p className="project__label">
                住所
              </p>
            </div>
            <input
              name="address"
              className="project-input"
              placeholder="住所"
              value={projectState.address}
              disabled={projectState.isArchived}
              onChange={onChangeProjectState}
            />
          </div>
          <div>
            <div className="h-stack mt-[40px]">
              <p className="project__label">
                説明
              </p>
            </div>
            <input
              name="description"
              className="project-input"
              placeholder="プロジェクトの説明"
              value={projectState.description}
              disabled={projectState.isArchived}
              onChange={onChangeProjectState}
            />
          </div>
          <div>
            <div className="h-stack mt-[40px]">
              <p className="project__label">
                タグ
              </p>
            </div>
            <div
              className={
                "input rounded-[4px] flex flex-nowrap items-center leading-tight px-2 bg-white h-[45px] max-w-[560px] overflow-x-auto overflow-y-hidden"
              }
            >
              {Object.keys(projectState.tags).map((tag, i) => {
                return (
                  <Badge
                    key={i}
                    onClose={() => onCloseTags(tag)}
                    className={""}
                  >
                    {tag}
                  </Badge>
                );
              })}
              <input
                type="text"
                className={"border-0 outline-none flex-grow"}
                onKeyDown={handleTagsKeyDown}
                disabled={projectState.isArchived}
              />
            </div>
          </div>
          <div className="section member-section">
            <div className="section-header">
              <h4>プロジェクトメンバー / チーム</h4>
            </div>
            <div className="section member-list-section">
              <Tab.Group>
                <Tab.List className="tab-list">
                  <Tab className="tab">
                    {({ selected }) => (
                      <div
                        onClick={() => {
                          setSelectedTab(SelectableTabs.USERS);
                        }}
                        className={selected ? "button selected" : "button"}
                      >
                        <span>メンバー</span>
                        <p>{`${getNumberOfCandidates("users")}`}</p>
                      </div>
                    )}
                  </Tab>
                  <Tab className="tab">
                    {({ selected }) => (
                      <div
                        onClick={() => {
                          setSelectedTab(SelectableTabs.TEAMS);
                        }}
                        className={selected ? "button selected" : "button"}
                      >
                        <span>チーム</span>
                        <p>{`${getNumberOfCandidates("teams")}`}</p>
                      </div>
                    )}
                  </Tab>
                </Tab.List>
                <Tab.Panels className="tab-panels">
                  <Tab.Panel className="tab-panel">
                    <div className="project-member__list">
                      <div className="w-full mb-[4px]">
                        {
                          projectUsers.map((user) => (
                            <div key={user.id} className="project-member__list row">
                              <div className="h-stack center">
                                {
                                  user.profileImageUrl ?
                                    <img src={user.profileImageUrl} className="profile-img" />
                                    :
                                    <div className="icon-label">
                                      <p>
                                        {user.username.charAt(0)}
                                      </p>
                                    </div>
                                }
                                <div className="v-stack ml-2">
                                  <p className="username-label">{user.username}</p>
                                  <p className="email-label">{user.email}</p>
                                </div>
                              </div>
                              {!projectState.isArchived &&
                                <div className="project-member__setting">
                                  {user.role !== OrganizationRole.guest ? (
                                    <div>
                                      <Selection
                                        id="role-selection"
                                        value={projectState.roles[Object.keys(projectState.roles).find((u) => u === user.id) || "0"].toString()}
                                        options={projectRoleSelections}
                                        onChange={(value: string) => { onChangeProjectUserRole(value, user.id) }}
                                      />
                                    </div>
                                  ) : (
                                    ProjectRoleMap[OrganizationRole.guest]
                                  )}
                                  <button
                                    className="delete-button ml-2"
                                    onClick={() => removeAssignedUser(user.id)}>
                                    <TrashIcon />
                                  </button>
                                </div>
                              }
                            </div>
                          ))}
                      </div>
                    </div>
                    {!projectState.isArchived &&
                      <Menu>
                        <div className="project-member__buttonBox">
                          <Menu.Button
                            onClick={onSelectUserButtonPressed}
                            disabled={!filteredUsers?.length}
                            className="button main">
                            <AddUserIcon className="project-member__add-user-icon" />
                            メンバーを選ぶ
                          </Menu.Button>
                        </div>
                        <Transition
                          show={isUserSelectionPanelOpen}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items>
                            <RadioGroup
                              className="project-member__list rounded-lg"
                              value={filteredUsers}
                              onChange={(value) => {
                                setFilteredUsers((prev) => (prev.map((u) => {
                                  if (u.id === value[0].id) {
                                    return {
                                      ...u,
                                      isSelected: !u.isSelected,
                                    }
                                  }
                                  return u;
                                })))
                              }}>
                              {filteredUsers && filteredUsers.map((user) => (
                                <RadioGroup.Option
                                  key={user.id}
                                  className={"mb-[4px]"}
                                  value={[user]}
                                >
                                  {() => {
                                    return (
                                      <div className="project-member__list selection-row">
                                        <div className="h-stack center">
                                          <div className="mr-2">
                                            <Checkbox props={{
                                              checked: user.isSelected
                                            }} />
                                          </div>
                                          {
                                            user.profileImageUrl ?
                                              <img src={user.profileImageUrl} className="profile-img" alt={`${user.username}のプロファイル`} />
                                              :
                                              <div className="icon-label">
                                                <p>
                                                  {user.username.charAt(0)}
                                                </p>
                                              </div>
                                          }
                                          <div className="h-stack center ml-2">
                                            <p className="username-label w-[120px]">{user.username}</p>
                                            <p className="email-label ml-4">{user.email}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }}
                                </RadioGroup.Option>
                              ))}
                            </RadioGroup>
                            <div className="project-member__buttonBox">
                              <Menu.Button
                                onClick={onAddMemberButtonPressed}
                                disabled={!filteredUsers?.length}
                                className="button main">
                                + 選択したメンバーを追加する
                              </Menu.Button>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    }
                  </Tab.Panel>
                  <Tab.Panel className="tab-panel">
                    <div className="project-member__list">
                      <div className="w-full mb-[4px]">
                        {
                          projectTeams.map((team) => (
                            <div key={team.id} className="project-member__list row">
                              <div className="h-stack center">
                                {
                                  <div className="icon-label">
                                    <p>
                                      {team.name.charAt(0)}
                                    </p>
                                  </div>
                                }
                                <div className="v-stack ml-2">
                                  <p className="name-label">{team.name}</p>
                                </div>
                              </div>
                              {!projectState.isArchived &&
                                <div className="project-member__setting">
                                  <div>
                                    <Selection
                                      id="role-selection"
                                      value={projectState.teams[Object.keys(projectState.teams).find((u) => u === team.id) || "0"].toString()}
                                      options={projectRoleSelections}
                                      onChange={(value: string) => { onChangeProjectTeamRole(value, team.id) }}
                                    />
                                  </div>
                                  <button
                                    className="delete-button ml-2"
                                    onClick={() => removeAssignedTeam(team.id)}>
                                    <TrashIcon />
                                  </button>
                                </div>
                              }
                            </div>
                          ))}
                      </div>
                    </div>
                    {!projectState.isArchived &&
                      <Menu>
                        <div className="project-member__buttonBox">
                          <Menu.Button
                            onClick={onSelectTeamButtonPressed}
                            disabled={!filteredTeams?.length}
                            className="button main">
                            <AddUserIcon className="project-member__add-team-icon" />
                            チームを選ぶ
                          </Menu.Button>
                        </div>
                        <Transition
                          show={isTeamSelectionPanelOpen}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items>
                            <RadioGroup
                              className="project-member__list rounded-lg"
                              value={filteredTeams}
                              onChange={(value) => {
                                setFilteredTeams((prev) => (prev.map((u) => {
                                  if (u.id === value[0].id) {
                                    return {
                                      ...u,
                                      isSelected: !u.isSelected,
                                    }
                                  }
                                  return u;
                                })))
                              }}>
                              {filteredTeams && filteredTeams.map((team) => (
                                <RadioGroup.Option
                                  key={team.id}
                                  className={"mb-[4px]"}
                                  value={[team]}>
                                  {() => {
                                    return (
                                      <div className="project-member__list selection-row">
                                        <div className="h-stack center">
                                          <div className="mr-2">
                                            <Checkbox props={{
                                              checked: team.isSelected
                                            }} />
                                          </div>
                                          {
                                            <div className="icon-label">
                                              <p>
                                                {team.name.charAt(0)}
                                              </p>
                                            </div>
                                          }
                                          <div className="h-stack center ml-2">
                                            <p className="name-label w-[120px]">{team.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }}
                                </RadioGroup.Option>
                              ))}
                            </RadioGroup>
                            <div className="project-member__buttonBox">
                              <Menu.Button
                                onClick={onAddTeamButtonPressed}
                                disabled={!filteredTeams?.length}
                                className="button main">
                                + 選択したチームを追加する
                              </Menu.Button>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    }
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <div className="section member-section resource-section">
            <div className="section-header">
              <h4>プロジェクトリソース</h4>
            </div>
            <div className="section member-list-section">
              <div className="project-member__list">
                <div className="w-full mb-[4px]">
                  {
                    projectResources.map((resource) => (
                      <div key={resource.id} className="project-member__list row">
                        <div className="h-stack center">
                          {
                            resource.iconImageUrl ?
                              <img src={resource.iconImageUrl} className="profile-img" />
                              :
                              <div className="icon-label">
                                <p>
                                  {resource.name.charAt(0)}
                                </p>
                              </div>
                          }
                          <div className="v-stack ml-2">
                            <p className="username-label">{resource.name}</p>
                            <p className="email-label">{resource.memo}</p>
                          </div>
                        </div>
                        {!projectState.isArchived &&
                          <div className="project-member__setting">
                            <button
                              className="delete-button ml-2"
                              onClick={() => removeAssignedResource(resource.id)}>
                              <TrashIcon />
                            </button>
                          </div>
                        }
                      </div>
                    ))}
                </div>
              </div>
              {!projectState.isArchived &&
                <Menu>
                  <div className="project-member__buttonBox">
                    <Menu.Button
                      onClick={onSelectResourceButtonPressed}
                      disabled={!filteredResources?.length}
                      className="button main">
                      <IoPersonAddOutline className="project-member__add-resource-icon"></IoPersonAddOutline>
                      リソースを選ぶ
                    </Menu.Button>
                  </div>
                  <Transition
                    show={isResourceSelectionPanelOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items>
                      <RadioGroup
                        className="project-member__list rounded-lg"
                        value={filteredResources}
                        onChange={(value) => {
                          setFilteredResources((prev) => (prev.map((u) => {
                            if (u.id === value[0].id) {
                              return {
                                ...u,
                                isSelected: !u.isSelected,
                              }
                            }
                            return u;
                          })))
                        }}>
                        {filteredResources && filteredResources.map((resource) => (
                          <RadioGroup.Option
                            key={resource.id}
                            className={"mb-[4px]"}
                            value={[resource]}
                          >
                            {() => {
                              return (
                                <div className="project-member__list selection-row">
                                  <div className="h-stack center">
                                    <div className="mr-2">
                                      <Checkbox props={{
                                        checked: resource.isSelected
                                      }} />
                                    </div>
                                    {
                                      resource.iconImageUrl ?
                                        <img src={resource.iconImageUrl} className="profile-img" alt={`${resource.name}のプロファイル`} />
                                        :
                                        <div className="icon-label">
                                          <p>
                                            {resource.name.charAt(0)}
                                          </p>
                                        </div>
                                    }
                                    <div className="h-stack center ml-2">
                                      <p className="username-label w-[120px]">{resource.name}</p>
                                      <p className="email-label ml-4">{resource.memo}</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }}
                          </RadioGroup.Option>
                        ))}
                      </RadioGroup>
                      <div className="project-member__buttonBox">
                        <Menu.Button
                          onClick={onAddResourceButtonPressed}
                          disabled={!filteredResources?.length}
                          className="button main">
                          + 選択したリソースを追加する
                        </Menu.Button>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              }
            </div>
          </div>
        </div>

        {/* プロジェクトの期間 */}
        <div className="v-stack">
          <div className="h-stack mt-2 mb-4">
            <p className="project__label">
              プロジェクトの期間
            </p>
            {(props.isCreating || props.isDuplicate) && <p className="project__must">必須</p>}
          </div>
          <div className={(props.isCreating || props.isDuplicate) ? "v-stack" : "v-stack mb-[20px]"}>
            <div className={"h-stack mb-6"}>
              <div className="v-stack">
                <p className="project__label">開始日</p>
                <p className="project__date-label">
                  {formatDate(projectState.startDate)}
                </p>
              </div>
              <p className="project__label mt-[20px] ml-[12px] mr-[12px]">〜</p>
              <div className={(props.isCreating || props.isDuplicate) ? "v-stack" : "v-stack"}>
                <p className="project__label">完了日</p>
                <p className="project__date-label">{formatDate(projectState.endDate)}</p>
              </div>
            </div>
            {(props.isCreating || props.isDuplicate) && (
              <Calendar
                props={{
                  className: "range",
                  headerOrder: ["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"],
                  hideYear: true,
                  range: true,
                  rangeHover: true,
                  locale: CalendarLocal,
                  value: projectState.endDate
                    ? [
                      new DateObject(projectState.startDate!),
                      new DateObject(projectState.endDate),
                    ]
                    : projectState.startDate
                      ? [new DateObject(projectState.startDate)]
                      : [],
                  onChange: (dateObjects) => {
                    if (!dateObjects) return;
                    const dates = dateObjects.toString().split(",");
                    const start = new Date(dates[0]);
                    let end: Date | null = null;
                    if (dates[1]) {
                      end = new Date(dates[1]);
                    }
                    onChangeDuration?.(start, end);
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
      {!props.isCreating && !props.isDuplicate && (
        <div className="form-container danger-zone-container">
          <div className="danger-zone-container__list">
            <div className="danger-zone-container__subject-container">
              <p className="danger-zone-container__subject">
                プロジェクトをアーカイブする
              </p>
              <span>
                このプロジェクトをアーカイブ済みおよび読み取り専用とします。
              </span>
            </div>
            <div className="selection-wrapper">
              <Button
                name="submit"
                theme="cancel"
                style={{ height: "40px" }}
                onClick={openUpdateArchiveModal}
                disabled={projectState.isArchived && projectLimitExceeded}
              >
                {projectState.isArchived ? "プロジェクトのアーカイブを解除" : "プロジェクトをアーカイブ"}
              </Button>
              <Transition appear show={isUpdateArchiveModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeUpdateArchiveModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black/25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            {projectState.isArchived ? "プロジェクトのアーカイブを解除" : "プロジェクトをアーカイブ"}
                          </Dialog.Title>
                          <label
                            className="text-sm font-medium leading-6 text-gray-900"
                          >
                            {projectState.isArchived
                              ? "アーカイブを解除するとプロジェクトが編集できるようになります。"
                              : "このプロジェクトは読み取り専用になります。いつでもアーカイブを解除することができます。"}
                          </label>
                          {modalErrorMessage && (
                            <span className="text-sm text-red-500 mt-[2px]">{modalErrorMessage}</span>
                          )}
                          <div className="account-setting--buttonRow p-0 mt-8">
                            <button
                              onClick={closeUpdateArchiveModal}
                              className="button solid"
                            >
                              キャンセル
                            </button>
                            <button
                              onClick={() => onArchive?.(!projectState.isArchived)}
                              className="button info"
                            >
                              理解しました
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>

        </div>
      )}
      {
        isProcessing ?
          <div className="spinner-wrapper">
            <RotatingLines
              strokeColor={Colors.primary}
              strokeWidth="5"
              animationDuration="0.75"
              width="42"
              visible={true}
            />
          </div>
          :
          projectState.isArchived ?
            null
            :
            <div className="project__buttonContainer">
              <div className="project__buttonRow">
                <Button
                  name="submit"
                  theme="main"
                  style={{ width: "400px", height: "60px" }}
                  onClick={onSubmit}>
                  {props.isCreating
                    ? "作成する"
                    : props.isDuplicate
                      ? "複製する"
                      : "変更する"
                  }
                </Button>
              </div>
              <div className="project__buttonRow">
                <Button
                  name="abort"
                  theme="solid"
                  style={{ width: "120px" }}
                  onClick={onCancel}>
                  キャンセル
                </Button>
              </div>
            </div>
      }
    </div>
  )
});

export default ProjectForm;
